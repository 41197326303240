import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DelayJumpsDetails } from '../../../types/anomaliesTypes';
import DelayJumpsDetailsTableRow from './DelayJumpsDetailsTableRow';


const styles = {
    table: {
        display: 'block',
        maxHeight: '385px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    rows: DelayJumpsDetails[],
    selectedRow: DelayJumpsDetails | undefined;
    selectedRowHandler: (selectedRow: DelayJumpsDetails) => void;
}

const DelayJumpsDetailsTable: React.FC<Props> = ({ rows, selectedRow, selectedRowHandler }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Prev Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Next Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Prev Delay</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Next Delay</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Jump Magnitude</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <DelayJumpsDetailsTableRow key={index} row={row} selectedRow={selectedRow} selectedRowHandler={selectedRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default DelayJumpsDetailsTable;