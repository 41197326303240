import moment from 'moment';
import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import { getShapeData } from '../../../../actions/gtfsStaticActions';
import { getDelaysAnalysisCompareData, getDelaysAnalysisTableData } from '../../../../actions/otpActions';
import Api from '../../../../api/api';
import { AppState } from '../../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../../selectors';
import { PolylineProps } from '../../../../types/BingMapProps';
import { LineChartRefType } from '../../../../types/chartTypes';
import { DelaysAnalysisCompareData } from '../../../../types/delaysAnalysisTypes';
import { DelaysAnalysisTableData } from '../../../../types/otpTypes';
import BingMapQuerier from '../../../../utilities/BingMapQuerier';
import MapUtils from '../../../../utilities/map-utils';
import { getVehicleInfoboxHtml } from '../../../bushistory/VehicleInfobox';
import useTableSorting from '../../../hooks/useTableSorting';
import BingMap from '../../../shared/BingMap';
import PrettoSliderControl from '../../../shared/PrettoSliderControl';
import DelaysAnalysisChart from './DelaysAnalysisChart';
import DelaysAnalysisTable from './DelaysAnalysisTable';
import tableSortReducer from './tableSortReducer';

interface Props {
    agency: AgencyStateType | undefined;
}

export interface SelectedTripData {
    tripId: string;
    tripName: string;
    routeName: string;
    vehicleId: string;
    shapeId: string;
}

const styles = {
    map: {
        height: '478px',
        marginTop: '45px',
    } as React.CSSProperties,
    sliderContainer: {
        marginTop: '40px',
    } as React.CSSProperties,
    delaysAnalysis: {
        display: 'flex',
        flexDirection: 'row',
    } as React.CSSProperties,
    delaysAnalysisChart: {
        flexShrink: 0,
        flexBasis: 'fit-content',
    } as React.CSSProperties,
    delaysAnalysisMap: {
        width: '100%',
    } as React.CSSProperties,
};

const DelaysAnalysisForm: React.FC<Props> = ({ agency }) => {
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [delaysCompareDataState, setDelaysCompareDataState] = useState<DelaysAnalysisCompareData[]>([]);
    const [delaysAnalysisState, setDelaysAnalysisState] = useState<DelaysAnalysisTableData[]>([]);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [selectedTripDataState, setSelectedTripDataState] = useState<SelectedTripData | undefined>();
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = React.useState<Microsoft.Maps.LocationRect | undefined>();
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [sliderValueState, setSliderValueState] = useState<number>(-1);
    const { tableDataState, handleTableColumnSort } = useTableSorting(tableSortReducer, delaysAnalysisState);
    const mapRef = createRef<BingMapQuerier>();
    const chartRef = createRef<LineChartRefType>();

    useEffect(() => {
        setDelaysAnalysisState([]);
        setDelaysCompareDataState([]);
        if (agency === undefined) return;
        (async (agencyId: string) => {
            await updateTableData();
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        const infobox = updateBusPosition(sliderValueState, delaysCompareDataState);
        MapUtils.updateCrossHair(chartRef.current, sliderValueState);
        return () => {
            if (infobox) {
                infobox.setMap(null as any as Microsoft.Maps.Map);
            }
        };
    }, [sliderValueState]);

    const handleSelectedDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setSelectedDateState(value);
    };
    const tripClickHandler = async (selectedTripData: SelectedTripData) => {
        setSelectedTripDataState(selectedTripData);
        setSliderValueState(-1);
        const { tripId, vehicleId, shapeId } = selectedTripData;
        await updateDelaysState(tripId, vehicleId, shapeId);
    };
    const handleSliderChange = (_e: React.ChangeEvent<{}>, value: number | number[]) => {
        setSliderValueState(value as number);
    };
    const handleValueLabelFormat = (value: number): React.ReactNode => {
        const position = delaysCompareDataState[value];
        const formattedDate = position ?
            moment.parseZone(position.vehicleTime).format('hh:mm a') :
            '';
        return <div style={{ textAlign: 'center', fontSize: '9px' }}>{formattedDate}</div>;
    };
    const updateDelaysState = async (tripId: string, vehicleId: string, shapeId: string) => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            const selectedDate = moment(selectedDateState).format('YYYY-MM-DD');
            try {
                const [delaysCompareData, shapePoints] = await Promise.all(
                    [getDelaysAnalysisCompareData(agency.id, selectedDate, tripId, vehicleId),
                    getShapeData(agency.id, selectedDateState, shapeId)]);
                if (!delaysCompareData || delaysCompareData.length === 0) {
                    setDelaysCompareDataState([]);
                }
                const orderedDelaysData = delaysCompareData.sort((a, b) => new Date(a.vehicleTime).getTime() - new Date(b.vehicleTime).getTime());
                setDelaysCompareDataState(orderedDelaysData);
                setPolylineState({
                    locations: shapePoints.map(({ latitude, longitude }) => [latitude, longitude]),
                    options: {
                        strokeColor: 'red',
                        strokeThickness: 3,
                    },
                });
                const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
                const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
                setMapBoundsState(boundingBox);
                setSliderValueState(0);

            } catch {
                setDelaysCompareDataState([]);
                setSliderValueState(-1);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setDelaysCompareDataState([]);
            setSliderValueState(-1);
        }
    };
    const updateTableData = async () => {
        setDelaysAnalysisState([]);
        setDelaysCompareDataState([]);
        if (agency && agency.id) {
            try {
                setFormBlockingState(true);
                const tableData = (await getDelaysAnalysisTableData(agency.id, selectedDateState)).sort((a, b) => {
                    if (a.ourMeanDelayErrorSec === null)
                        return 1;
                    if (b.ourMeanDelayErrorSec === null)
                        return -1;
                    return b.ourMeanDelayErrorSec - a.ourMeanDelayErrorSec;
                }).slice(0, 200);
                setDelaysAnalysisState(tableData);
            } catch {
                setDelaysAnalysisState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setDelaysAnalysisState([]);
        }
    };
    const updateBusPosition = (sliderValue: number, delaysCompareData: DelaysAnalysisCompareData[]) => {
        const position = delaysCompareData[sliderValue];
        if (!position || !mapRef || !mapRef.current || !mapRef.current.map)
            return null;
        const description = `at ${moment.parseZone(position.vehicleTime).format('h:mm:ss A')}`;
        const infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(position.latitude, position.longitude),
            {
                htmlContent: getVehicleInfoboxHtml(selectedTripDataState ? selectedTripDataState.vehicleId : '', description),
            },
        );
        infobox.setMap(mapRef.current.map);
        return infobox;
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    {selectedTripDataState
                        ? `Delays Analysis for ${selectedTripDataState.routeName} + Trip: ${selectedTripDataState.tripName}`
                        : 'Delays Analysis'}
                </Header>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <label className="calendarInputLabel">Report Date:</label>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={updateTableData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                {tableDataState.data.length > 0 &&
                    <>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label className="categoryHeader">
                                    <div>Delays by Trip</div>
                                    <div className="categorySubtitle">Top 200 trips sorted by Avg Delay Error. Click on a row to display delay history.</div>
                                </label>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group className="rowGroup">
                            <Form.Field width={16}>
                                <DelaysAnalysisTable tableDataState={tableDataState} selectedTripId={selectedTripDataState?.tripId} tripRowHandler={tripClickHandler} columnSortHandler={handleTableColumnSort} />
                            </Form.Field>
                        </Form.Group>
                    </>}
                {delaysCompareDataState.length > 0 && selectedTripDataState && selectedTripDataState.tripName &&
                    <>
                        <Form.Group style={styles.delaysAnalysis}>
                            <Form.Field style={styles.delaysAnalysisChart}>
                                <label className="categoryHeader">Delays Analysis for trip {selectedTripDataState.tripName}</label>
                                <DelaysAnalysisChart delaysData={delaysCompareDataState} ref={chartRef} />
                            </Form.Field>
                            <Form.Field style={styles.delaysAnalysisMap}>
                                <BingMap
                                    ref={mapRef}
                                    div={{ style: styles.map }}
                                    map={{
                                        center,
                                        options: {
                                            bounds: mapBoundsState,
                                        },
                                    }}
                                    polylines={polylineState && [polylineState]}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16} style={styles.sliderContainer} >
                                <PrettoSliderControl
                                    valueLabelDisplay="on"
                                    name="delaysAnalysisSlider"
                                    aria-label="pretto slider"
                                    min={0}
                                    max={delaysCompareDataState.length > 0 ? delaysCompareDataState.length - 1 : 0}
                                    onChange={handleSliderChange}
                                    step={1}
                                    value={sliderValueState}
                                    valueLabelFormat={handleValueLabelFormat}
                                />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(DelaysAnalysisForm);
