import { RequestParameters } from '../types/apiTypes';
import { ConfirmNotification, PushNotificationRecipient } from '../types/pushNotificationTypes';
import api from './api';
import { buildUrlString } from './helpers';

class PushNotificationApi {
    static doAuthGetRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/pushNotification/${url}`);
    static doAuthPostRequestAsync = async <T, R>(url: string, data?: T, parameters?: RequestParameters) => await api.doAuthPostRequestAsync<T, R>('/PushNotification/' + url, data, parameters);

    static async sendConfirmNotification(data: ConfirmNotification): Promise<ConfirmNotification> {
        const url = buildUrlString(null, 'sendConfirm');
        return await this.doAuthPostRequestAsync<ConfirmNotification, ConfirmNotification>(url, data);
    }

    static async saveTestRecipients(data: PushNotificationRecipient[]): Promise<string> {
        const url = buildUrlString(null, 'saveTestRecipients');
        return await this.doAuthPostRequestAsync<PushNotificationRecipient[], string>(url, data);
    }

    static async approveNotificationSending(code: string, email: string): Promise<void> {
        const queryParams = {
            code,
            email,
        };
        const url = buildUrlString(queryParams, 'approveNotificationSending');
        return await this.doAuthPostRequestAsync(url);
    }

    static async getTestRecipients() {
        const url = buildUrlString(null, 'getTestRecipients');
        return await this.doAuthGetRequest<PushNotificationRecipient[]>(url);
    }
}

export default PushNotificationApi;