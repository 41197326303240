import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DelaysAnalysisTableData } from '../../../../types/otpTypes';
import { TableSortProperties } from '../../../../types/types';
import { SelectedTripData } from './DelaysAnalysisForm';
import DelaysAnalysisTableRow from './DelaysAnalysisTableRow';

const styles = {
    statisticsTable: {
        display: 'block',
        maxHeight: '385px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    tableDataState: TableSortProperties<DelaysAnalysisTableData>;
    selectedTripId: string | undefined;
    tripRowHandler: (tripData: SelectedTripData) => Promise<void>;
    columnSortHandler: (column: string) => void;
}

const DelaysAnalysisTable: React.FC<Props> = ({ tableDataState, selectedTripId, tripRowHandler, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.statisticsTable} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'tripSchedule' ? direction : undefined}
                            onClick={() => columnSortHandler('tripSchedule')}>
                            Trip
                            </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'routeName' ? direction : undefined}
                            onClick={() => columnSortHandler('routeName')}>
                            Route</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'vehicleId' ? direction : undefined}
                            onClick={() => columnSortHandler('vehicleId')}>
                            Bus ID
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'ourMeanDelayErrorSec' ? direction : undefined}
                            onClick={() => columnSortHandler('ourMeanDelayErrorSec')}>
                            Delay Diff (TRANSITiQ)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'ourMaxDelayErrorSec' ? direction : undefined}
                            onClick={() => columnSortHandler('ourMaxDelayErrorSec')}>
                            Max Delay Diff (TRANSITiQ)
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <DelaysAnalysisTableRow key={index} row={row} selectedTripId={selectedTripId} tripRowHandler={tripRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default DelaysAnalysisTable;