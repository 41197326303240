export enum WeekDaysSelectionType {
   /** this value is mapped to the corresponding enum value on the backend */
   BuisinessDays = 'BuisinessDays',
   /** this value is mapped to the corresponding enum value on the backend */
   WeekEndDays = 'WeekEndDays',
   /** this value is mapped to the corresponding enum value on the backend */
   AnyWeekDays = 'AnyWeekDays',
}

export interface AdjustmentReportRequest {
   agencyId: string;
   startDate: string;
   endDate: string;
   directionVariantId: string;
   weekDays: WeekDaysSelectionType;
}
export type AdjustmentReport = {
   actualOtp: number | null;
   actualMeanDelay: number | null;
   proposedOtp: number | null;
   proposedMeanDelay: number;
   columnHeaders: ColumnHeader[];
   rows: Row[];
};
export type TripStopAdjustment = {
   stopOrdinal: number;
   actualOtp: number | null;
   proposedOtp: number | null;
   improvement: number | null;
   actualDelay: number | null;
   proposedDelay: number | null;
   proposedAdjustment: number | null;
};
export type Row = {
   tripId: string;
   startTime: string;
   finishTime: string;
   actualOtp: number | null;
   actualMeanDelay: number | null;
   proposedOtp: number | null;
   proposedMeanDelay: number | null;
   adjustments: TripStopAdjustment[];
};
export type ColumnHeader = {
   stopOrdinal: number;
   stopId: string;
   actualOtp: number | null;
   actualMeanDelay: number | null;
   proposedOtp: number | null;
   proposedMeanDelay: number | null;
};

