import { OperationResult } from '../types/apiTypes';
import { BunchingStatisticsArrivals, BusBunching, BusBunchingArrival, BusBunchingOccuracy } from '../types/busBunchingTypes';
import { VehiclePositionDto } from '../types/busHistoryTypes';
import { DelaysAnalysisCompareData } from '../types/delaysAnalysisTypes';
import { RtDelaysNow, RtDelaysNowInterval } from '../types/delaysNowTypes';
import { DelayStatisticsData } from '../types/delayStatisticsTypes';
import { ArrivalForStop, LatLonKeyed, Stop, StopDelayData } from '../types/delaysTypes';
import { DaysStatiscsRequest, DaysStatistics, StopArrivalHeadway, StopArrivalHeadwayTrip, StopHeadwaysRequest } from '../types/headwaysTypes';
import { AgencyStats, DashboardRealtimeData, DelaysAnalysisTableData, TripDelayHistory, TwelveHoursStatusItem } from '../types/otpTypes';
import { KpiData } from '../types/trendsTypes';
import api from './api';
import { buildUrlString, SearchParams } from './helpers';

class OtpApi {
    static otpRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/OnTimePerformance/${url}`);

    static async getAgencyStats(agencyId: string) {
        return await this.otpRequest<AgencyStats>(`AgencyStats/${agencyId}`);
    }

    static async getDashboardRealTimeData(agencyId: string) {
        const url = buildUrlString(null, 'dashboardRealTimeData', agencyId);
        return await this.otpRequest<OperationResult<DashboardRealtimeData>>(url);
    }
    static async getTripDelayHistory(agencyId: string, tripInternalId: string, vehicleId: string) {
        const url = buildUrlString(null, 'tripDelayHistory', agencyId, 'trips', tripInternalId, 'vehicles', vehicleId);
        return await this.otpRequest<OperationResult<TripDelayHistory[]>>(url);
    }
    static async getStopDelays(agencyId: string, startDate: string, endDate: string) {
        return await this.otpRequest<OperationResult<LatLonKeyed<Stop[]>>>(`StopDelays/${agencyId}/${startDate}/${endDate}`);
    }
    static async getStopDelaysData(agencyId: string, startDate: string, endDate: string) {
        const url = buildUrlString(null, 'StopDelaysData', agencyId, startDate, endDate);
        return await this.otpRequest<OperationResult<StopDelayData[]>>(url);
    }
    static async getArrivalsForStop(agencyId: string, startDate: string, endDate: string, stopId: string) {
        const url = buildUrlString(null, 'ArrivalsForStop', agencyId, startDate, endDate, 'stops', stopId);
        return await this.otpRequest<OperationResult<ArrivalForStop[]>>(url);
    }
    static async getRtDelaysNowReportData(agencyId: string, interval: RtDelaysNowInterval) {
        return await this.otpRequest<OperationResult<RtDelaysNow[]>>(`rtDelaysNowData/${agencyId}/${interval}`);
    }

    static async getBusBunchingForPeriod(agencyId: string, fromDate: string, toDate: string) {
        return await this.otpRequest<OperationResult<BusBunching[]>>(`BusBunching/${agencyId}/${fromDate}/${toDate}`);
    }
    static async getBusBunchingForPeriodAndRoute(agencyId: string, fromDate: string, toDate: string, routeName: string) {
        return await this.otpRequest<OperationResult<BusBunching[]>>(`BusBunching/${agencyId}/${fromDate}/${toDate}?routeName=${routeName}`);
    }
    static async getBunchingReport(agencyId: string, fromDate: string, toDate: string) {
        const url = buildUrlString(null, 'busBunchingReport', agencyId, fromDate, toDate);
        return await this.otpRequest<OperationResult<BusBunchingOccuracy>>(url);
    }
    static async getBunchingArrivals(agencyId: string, fromDate: string, toDate: string) {
        const url = buildUrlString(null, 'bunchingArrivals', agencyId, fromDate, toDate);
        return await this.otpRequest<OperationResult<BunchingStatisticsArrivals[]>>(url);
    }
    static async getBusBunchingArrivalsChart(agencyId: string, reportDate: string, tripId1: string, tripId2: string, vehicleId1: string, vehicleId2: string) {
        const url = buildUrlString(null, 'busBunchingArrivalsChart', agencyId, reportDate, 'trips', tripId1, tripId2, 'vehicles', vehicleId1, vehicleId2);
        return await this.otpRequest<OperationResult<BusBunchingArrival[]>>(url);
    }
    static async getBusBunchingPositions(agencyId: string, reportDate: string, tripId: string, vehicleId: string) {
        const url = buildUrlString(null, 'busBunchingPositions', agencyId, reportDate, 'trip', tripId, 'vehicle', vehicleId);
        return await this.otpRequest<OperationResult<VehiclePositionDto[]>>(url);
    }

    static async getDaysStatistics({ agencyId, headwayCloseRule, headwayDistantRule, fromDate, toDate, routeName, cardinalDirection }: DaysStatiscsRequest) {
        return await this.otpRequest<OperationResult<DaysStatistics[]>>(`DaysStatistics/${agencyId}/${headwayCloseRule}/${headwayDistantRule}?fromServiceDate=${fromDate}&toServiceDate=${toDate}&routeName=${routeName}&cardinalDirection=${cardinalDirection}`);
    }

    static async getStopHeadwaysTrips(agencyId: string, reportDate: string, routeName: string, directionVariantInternalId: string): Promise<OperationResult<StopArrivalHeadwayTrip[]>> {
        const queryParams: SearchParams = {
            routeName,
            directionVariantInternalId,
        };
        const url = buildUrlString(queryParams, 'stopHeadwaysTrips', agencyId, reportDate);
        return await this.otpRequest<OperationResult<StopArrivalHeadwayTrip[]>>(url);
    }
    static async getStopHeadways({ agencyId, reportDate, routeName, directionVariantInternalId, stopId }: StopHeadwaysRequest): Promise<OperationResult<StopArrivalHeadway[]>> {
        const queryParams: SearchParams = {
            routeName,
            directionVariantInternalId,
            stopId,
        };
        const url = buildUrlString(queryParams, 'stopHeadways', agencyId, reportDate);
        return await this.otpRequest<OperationResult<StopArrivalHeadway[]>>(url);
    }

    static async getTwelveHoursStatus(agencyId: string) {
        const url = buildUrlString(null, 'TwelveHoursStatus', agencyId);
        return await this.otpRequest<OperationResult<TwelveHoursStatusItem[]>>(url);
    }

    static async getKpiData(agencyId: string) {
        const url = buildUrlString(null, 'TrendsReport', agencyId);
        return await this.otpRequest<OperationResult<KpiData[]>>(url);
    }

    static async getDelaysAnalysisTableData(agencyId: string, reportDate: string) {
        const url = buildUrlString(null, 'delaysAnalysisTableData', agencyId, reportDate);
        return await this.otpRequest<OperationResult<DelaysAnalysisTableData[]>>(url);
    }

    static async getDelaysAnalysisCompareData(agencyId: string, reportDate: string, tripId: string, vehicleId: string) {
        const url = buildUrlString(null, 'delaysAnalysisCompareData', agencyId, reportDate, 'trips', tripId, 'vehicles', vehicleId);
        return await this.otpRequest<OperationResult<DelaysAnalysisCompareData[]>>(url);
    }

    static async getDelayStatistics(agencyId: string, fromDate: string, toDate: string, routeName = '') {
        const queryParams: SearchParams | null = routeName ? { routeName } : null;
        const url = buildUrlString(queryParams, 'DelayStatistics', agencyId, fromDate, toDate);
        return await this.otpRequest<OperationResult<DelayStatisticsData>>(url);
    }

}

export default OtpApi;