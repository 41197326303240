import Utils from '../../utilities/utils';
import BusAnalysisForm from '../anomalies/bus-analysis/BusAnalysisForm';
import DelayJumpsForm from '../anomalies/delay-jumps-2/DelayJumpsForm';
import ReportingGapsForm from '../anomalies/reporting-gaps/ReportingGapsForm';
import TopPredictionRoutesForm from '../anomalies/TopPredictingRoutes/TopPredictionRoutesForm';
import WrongCoordinatesForm from '../anomalies/wrong-coordinates/WrongCoordinatesForm';
import BusHistoryPage from '../bushistory/BusHistoryPage';
import BusHistoryByBusNumberForm from '../bushistorybybusnumber/BusHistoryByBusNumberForm';
import GtfsContainerPage from '../gtfs/GtfsContainerPage';
import GtfsEditorForm from '../gtfs/gtfsEditor/GtfsEditorForm';
import LiveMapForm from '../live-map/LiveMapForm';
import BusBunchingForm from '../on-time-performance/bunching/bus/BusBunchingForm';
import BunchingStatisticsForm from '../on-time-performance/bunching/statistics/BunchingStatisticsForm';
import DashboardForm from '../on-time-performance/dashboard/DashboardForm';
import DelaysAnalysisForm from '../on-time-performance/delays/analysis/DelaysAnalysisForm';
import DelaysNowForm from '../on-time-performance/delays/now/RtDelaysNowForm';
import DelayStatisticsForm from '../on-time-performance/delays/statistics/DelayStatisticsForm';
import StopDelaysForm from '../on-time-performance/delays/stops/StopDelaysForm';
import StatsHeadwaysForm from '../on-time-performance/headways/Stats/StatsHeadwaysForm';
import StopHeadwaysForm from '../on-time-performance/headways/StopHeadways/StopHeadways';
import TrendsForm from '../on-time-performance/trends/TrendsForm';
import TwelveHoursStatusForm from '../on-time-performance/twelveHoursStatus/TwelveHoursStatusForm';
import PowerBIPage from '../powerbi/PowerBIForm';
import PredictionAccuracyForm from '../predictionAnalysis/predictionAccuracy/PredictionAccuracyForm';
import PredictedArrivalReportForm from '../predictionAnalysis/predictionArrival/PredictedArrivalReportForm';
import PredictionCoverageForm from '../predictionAnalysis/predictionCoverage/PredictionCoverageForm';
import ConfirmedPushNotificationForm from '../pushNotifications/ConfirmedPushNotificationForm';
import ManageTestRecipientsForm from '../pushNotifications/ManageTestRecipientsForm';
import SendMessageForm from '../pushNotifications/SendMessageForm';
import AnalysisByPercentilesForm from '../runningTimeAnalytics/analysisByPercentiles/AnalysisByPercentilesForm';
import OverallPerformanceForm from '../runningTimeAnalytics/performanceAnalysis/OverallPerformanceForm';
import RunningTimesComparisonForm from '../runningTimeAnalytics/runningTimesComparison/RunningTimesComparisonForm';
import ScheduleAdjustementForm from '../runningTimeAnalytics/scheduleAdjustment/ScheduleAdjustementForm';
import SegmentTransitPerformanceForm from '../runningTimeAnalytics/segmentsLevel/SegmentTransitPerformanceForm';
import TravelTimeBetweenStopsForm from '../runningTimeAnalytics/segmentsLevel/TravelTimeBetweenStopsForm';
import RouteExplorerForm from '../schedule/scheduleReport/RouteExplorerForm';
import AlertsForm from '../service-alerts/AlertsForm';
import ServiceStatusForm from '../service-page/ServiceStatusForm';
import SpeedMapPage from '../speed-map/SpeedMapPage';
import TransitAccessMapPage from '../transit-access-map/TransitAccessMapPage';
import TripPlannerForm from '../trip-planner/TripPlannerForm';
import VehiclesStatisticsForm from '../vehicle-history/statistics/VehiclesStatisticsForm';
import Special from './Special';
import { icon_alert, icon_anomalies, icon_bus, icon_map, icon_on_time, icon_pin, icon_report, icon_run_times, icon_schedule, icon_speed, icon_trip_planner } from './svg-consts';

export function toRoutePaths(
    route: { path: string | string[]; } | { extension: string; },
    parentPathOrPaths: string | string[],
): string[] {
    return 'path' in route
        ? Utils.ensureIsArray(route.path)
        : Utils.ensureIsArray(parentPathOrPaths)
            .map(parentPath => `${parentPath}${route.extension}`);
}

const transitAccessMapRoute = {
    icon: icon_pin,
    label: 'Transit Access Map',
    path: '/transit-access-map',
    roles: ['Admin', 'Ho414'],
    component: TransitAccessMapPage,
} as const;

const onTimePerformanceDashboardRoute = {
    label: 'Dashboard',
    extension: '/dashboard',
    component: DashboardForm,
} as const;

const onTimePerformanceRoute = {
    icon: icon_on_time,
    label: 'On-Time Performance',
    path: '/on-time-performance',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        onTimePerformanceDashboardRoute,
        {
            label: 'Delay Statistics',
            extension: '/delay-statistics',
            component: DelayStatisticsForm,
            exact: true,
        },
        {
            label: 'Delays Now',
            extension: '/realtime-delays',
            component: DelaysNowForm,
        },
        {
            label: 'Delays at Stops',
            extension: '/stops-delays',
            component: StopDelaysForm,
        },
        {
            label: 'Delays Analysis',
            extension: '/delays-analysis',
            component: DelaysAnalysisForm,
        },
        {
            label: '12 Hours Status',
            extension: '/historical',
            component: TwelveHoursStatusForm,
        },
        {
            label: 'Trends',
            extension: '/trends',
            component: TrendsForm,
        },
    ],
} as const;

const headwaysRoute = {
    icon: icon_report,
    label: 'Headways',
    path: '/headways',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        {
            label: 'Bunching Analysis',
            extension: '/bunching-analysis',
            component: BusBunchingForm,
        },
        {
            label: 'Bunching Statistics',
            extension: '/bunching-stats',
            component: BunchingStatisticsForm,
        },
        {
            label: 'Headways at Stops',
            extension: '/headways-stops',
            component: StopHeadwaysForm,
        },
        {
            label: 'Headways Statistics',
            extension: '/headways-stats',
            component: StatsHeadwaysForm,
        },
    ],
} as const;

const liveMapRoute = {
    icon: icon_map,
    label: 'Live Map',
    path: '/live',
    roles: ['Admin', 'Ho414'],
    component: LiveMapForm,
} as const;

const vehicleHistoryRoute = {
    icon: icon_bus,
    label: 'Vehicle History',
    path: '/vehicle-history',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        {
            label: 'Bus Position History',
            extension: '/bus-position',
            component: BusHistoryPage,
        },
        {
            label: 'Bus History By Bus Number',
            extension: '/bus-number-position',
            component: BusHistoryByBusNumberForm,
            exact: true,
        },
        {
            hidden: true,
            extension: '/bus-number-position/:selectedDate/:busId?/:tripId?',
            component: BusHistoryByBusNumberForm,
        },
        {
            label: 'Vehicle Utilization',
            extension: '/in-service',
            component: VehiclesStatisticsForm,
        },
    ],
} as const;

const runTimesRoute = {
    icon: icon_run_times,
    label: 'Run Times',
    path: '/running-time',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        {
            label: 'Overall',
            extension: '/overall',
            exact: true,
            component: OverallPerformanceForm,
        },
        {
            label: 'Segments Performance',
            extension: '/segment-transit-performance',
            component: SegmentTransitPerformanceForm,
        },
        {
            label: 'Segments Travel Time',
            extension: '/segments-travel-time',
            component: TravelTimeBetweenStopsForm,
            exact: true,
        },
        {
            hidden: true,
            extension: '/segments-travel-time-stops/:stopId1?/:stopId2?',
            component: TravelTimeBetweenStopsForm,
        },
        {
            label: 'Analysis by Percentiles',
            extension: '/percentiles',
            component: AnalysisByPercentilesForm,
        },
        {
            label: 'Running Times Comparison',
            extension: '/comparison',
            component: RunningTimesComparisonForm,
        },
        {
            label: 'Schedule Adjustment',
            extension: '/schedule-adjustment',
            component: ScheduleAdjustementForm,
        },
    ],
} as const;

const speedMapRoute = {
    icon: icon_speed,
    label: 'Speed Map',
    path: '/average-speed',
    roles: ['Admin', 'Ho414'],
    component: SpeedMapPage,
} as const;

const anomaliesRoute = {
    icon: icon_anomalies,
    label: 'Anomalies',
    path: '/anomalies',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        {
            label: 'Deviations From Routes',
            extension: '/wrong-coordinates',
            component: WrongCoordinatesForm,
        },
        {
            label: 'Delay Jumps',
            extension: '/delay-jumps',
            component: DelayJumpsForm,
        },
        {
            label: 'Bus Analysis',
            extension: '/bus-analysis',
            component: BusAnalysisForm,
        },
        {
            label: 'Reporting Gaps',
            extension: '/reporting-gaps',
            component: ReportingGapsForm,
        },
        {
            label: 'Low Prediction Routes',
            extension: '/top-predicting-routes',
            component: TopPredictionRoutesForm,
        },
    ],
} as const;

const scheduleRoute = {
    icon: icon_schedule,
    label: 'Schedule',
    path: '/schedule',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        {
            label: 'Upload',
            extension: '/upload',
            component: GtfsContainerPage,
        },
        {
            label: 'Viewer',
            extension: '/viewer',
            component: GtfsEditorForm,
        },
        {
            label: 'Route Explorer',
            extension: '/route-explorer',
            component: RouteExplorerForm,
        },
    ],
} as const;

const tripPlannerRoute = {
    icon: icon_trip_planner,
    label: 'Trip Planner',
    path: '/trip-planner',
    roles: ['Admin', 'Ho414'],
    component: TripPlannerForm,
} as const;

const predictionAnalysisRoute = {
    icon: icon_run_times,
    label: 'Prediction Analysis',
    path: '/prediction-analysis',
    roles: ['Admin', 'Ho414'],
    subroutes: [
        {
            label: 'Prediction Accuracy',
            extension: '/prediction-accuracy',
            component: PredictionAccuracyForm,
        },
        {
            label: 'Prediction of Arrivals',
            extension: '/prediction-arrival',
            component: PredictedArrivalReportForm,
        },
        {
            label: 'Prediction Coverage',
            extension: '/prediction-coverage',
            component: PredictionCoverageForm,
        },
    ],
} as const;

const serviceAlertsRoute = {
    icon: icon_alert,
    label: 'Service Alerts',
    path: '/service-alerts',
    roles: ['Admin', 'Ho414'],
    component: AlertsForm,
};

const powerBiRoute = {
    icon: icon_speed,
    label: 'Custom Reports',
    path: '/custom-bi',
    roles: ['Admin', 'Ho414'],
    component: PowerBIPage,
    hidden: true,
};

const pushNotificationsRoute = {
    icon: icon_speed,
    label: 'Push Notifications',
    path: '/push-notifications',
    roles: ['Ho414Notifications'],
    subroutes: [
        {
            label: 'Send Message',
            extension: '/send-message',
            component: SendMessageForm,
        },
        {
            label: 'Manage Test Recipients',
            extension: '/manage-test-recipients',
            component: ManageTestRecipientsForm,
        },
        {
            label: 'Confirmed Push NotificationForm',
            extension: '/confirmed-push-notification',
            component: ConfirmedPushNotificationForm,
            hidden: true,
        },
    ],
} as const;

const serviceStatusRoute = {
    icon: icon_alert,
    label: 'Service Status',
    path: '/service-status',
    roles: ['Admin', 'Ho414'],
    component: ServiceStatusForm,
};

/** used as default route when navigating to / */
const startupRouteCanonicalPath =
    toRoutePaths(serviceStatusRoute, serviceStatusRoute.path)[0];

export const defaultRoutePath = startupRouteCanonicalPath;

export const loginPathPrefix = '/login';
export const loggingInPathPrefix = '/logincallback';
export const logoutPathPrefix = '/logout';

export const userSettingsRoute = {
    label: 'Settings',
    path: '/user/settings',
    roles: ['Admin', 'Ho414', 'Ho414Notifications'],
} as const;

const busHistoryRoute = {
    label: 'Bus History by Bus Number',
    path: '/bushistorybybusnumber',
    roles: ['Admin', 'Ho414'],
    component: BusHistoryByBusNumberForm,
};

const specialToken = {
    label: 'Special',
    path: '/special/:token',
    roles: ['Admin', 'Ho414', 'Ho414Notifications'],
    component: Special,
};

export const groupedRoutes = [
    serviceStatusRoute,
    onTimePerformanceRoute,
    headwaysRoute,
    transitAccessMapRoute,
    liveMapRoute,
    vehicleHistoryRoute,
    runTimesRoute,
    speedMapRoute,
    anomaliesRoute,
    scheduleRoute,
    tripPlannerRoute,
    specialToken,
    busHistoryRoute,
    userSettingsRoute,
    predictionAnalysisRoute,
    serviceAlertsRoute,
    powerBiRoute,
    pushNotificationsRoute,
] as const;
