import './DelaysAnalysisStyles.css';
import { ChartData, ChartOptions } from 'chart.js';
import * as crosshair from 'chartjs-plugin-crosshair';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDataPoint, LineChartRefType } from '../../../../types/chartTypes';
import { DelaysAnalysisCompareData } from '../../../../types/delaysAnalysisTypes';

const styles = {
    chartContainer: {
        width: '630px',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'end',
    } as React.CSSProperties,
};

const data = {
    labels: [],
    datasets: [
        {
            label: 'TransitIQ Delay',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(34,139,34, 0.4)',
            borderColor: 'rgba(34,139,34, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(34,139,34, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(34,139,34, 1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: [] as LineChartDataPoint[],
        },
        {
            label: 'Actual Delay',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(255,25,25,0.4)',
            borderColor: 'rgba(255,25,25,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255,25,25,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(255,25,25,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options = {
    scales: {
        xAxes: [{
            type: 'linear',
            autoSkip: false,
            scaleLabel: {
                display: true,
                labelString: 'Local time',
            },
            distribution: 'linear',
            ticks: {
                stepSize: 600 * 1000,
                beginAtZero: false,
                callback: (value: number) => {
                    const date = moment(value);
                    return date.format('hh:mm A');
                },
            },
        }],
        yAxes: [{
            type: 'linear',
            autoSkip: false,
            ticks: {
                stepSize: 5,
            },
            scaleLabel: {
                display: true,
                labelString: 'Delay (minutes)',
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 110,
        callbacks: {
            title: function () {
                return '';
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: number, yLabel: number }) {
                const delaysSource = tooltipItem.datasetIndex === 0 ? 'TransitIQ Delay' : 'Actual Delay';
                tooltipItem.datasetIndex === 0 ? 'trip 1' : 'trip 2';
                const time = moment.parseZone(tooltipItem.xLabel).format('hh:mm A');
                const runTimesLabel = `${delaysSource} for time ${time}: ${tooltipItem.yLabel} min`;
                return runTimesLabel;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: 'rgba(34,139,34,1)',
                        backgroundColor: 'rgba(34,139,34,1)',
                    };
                } else {
                    return {
                        borderColor: 'rgba(255,25,25,1)',
                        backgroundColor: 'rgba(255,25,25,1)',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    //maintainAspectRatio: false
};


interface Props {
    delaysData: DelaysAnalysisCompareData[],
}

const DelaysAnalysisChart = React.forwardRef<LineChartRefType, Props>(({ delaysData }, ref) => {
    const [dataState, setDataState] = useState(data);

    useEffect(() => {
        const chartData = delaysData.map(d => {
            return {
                ...d,
                vehicleTime: moment.parseZone(d.vehicleTime).format('YYYY-MM-DD hh:mm A'),
            };
        });

        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: chartData.filter(d => d.transitIqDelaySec !== null && d.transitIqDelaySec !== undefined).map(d => {
                    return {
                        x: moment(moment.parseZone(d.vehicleTime).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Math.round(d.transitIqDelaySec as number / 60 * 10) / 10,
                    };
                }),
            }, {
                ...prevState.datasets[1], data: chartData.filter(d => d.realDelaySec !== null && d.realDelaySec !== undefined).map(d => {
                    return {
                        x: moment(moment.parseZone(d.vehicleTime).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Math.round(d.realDelaySec as number / 60 * 10) / 10,
                    };
                }),
            }],
        }));
    }, [delaysData]);


    return (
        <div className="delaysAnalysisChart" style={styles.chartContainer}>
            <Line
                data={dataState as ChartData}
                options={options as unknown as ChartOptions}
                height={235}
                plugins={[crosshair]}
                ref={ref}
            />
        </div>
    );
});

export default DelaysAnalysisChart;
