import * as React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { PolylineProps, PushpinProps } from '../../../../types/BingMapProps';
import { BunchingStatisticsArrivals } from '../../../../types/busBunchingTypes';
import { KeyValuePair, SliderOptions } from '../../../../types/types';
import BingMap from '../../../shared/BingMap';
import PrettoSliderControl from '../../../shared/PrettoSliderControl';
import { BunchingTableSelectedData, VehicleBunchSliderInfo } from './BunchingStatisticsForm';
import BunchingTable from './BunchingTable';
import BusBunchingSliderInfo from './BusBunchingSliderInfo';

interface Props {
    bunchingArrivalsTable: BunchingStatisticsArrivals[];
    selectedTableData: BunchingTableSelectedData | undefined;
    selectedRowHandler: (selectedRow: BunchingStatisticsArrivals) => Promise<void>;
    center: CoordinatePair | undefined;
    handleValueLabelFormat: (value: number) => React.ReactNode,
    handleTableSliderChange: (_e: React.ChangeEvent<{}>, value: number | number[]) => void;
    bunchingArrivalsSlider: KeyValuePair<string, BunchingStatisticsArrivals[]>[],
    tableSliderValue: number;
    tableSliderAutoPlay: boolean;
    tableMapPushpins: PushpinProps[];
    handleTableSliderAutoPlayClick: () => void;
    vehiclesSliderOptions: SliderOptions;
    handleVehiclesSliderChange: (_e: React.ChangeEvent<{}>, value: number | number[]) => void;
    vehiclesSliderStepValue: number;
    vehiclesMapBounds: Microsoft.Maps.LocationRect | undefined;
    vehiclesMapPolyline: PolylineProps | undefined;
    vehiclesMapPushpins: PushpinProps[];
    vehicleSliderInfo: VehicleBunchSliderInfo | undefined;
}

const styles = {
    map: {
        width: '100%',
        height: '522px',
        marginTop: '5px',
    } as React.CSSProperties,
    bunchingSliderContainer: {
        marginTop: '40px',
    } as React.CSSProperties,
};

const DetailsTabForm: React.FC<Props> = ({
    bunchingArrivalsTable,
    selectedTableData,
    selectedRowHandler,
    center,
    handleValueLabelFormat,
    handleTableSliderChange,
    bunchingArrivalsSlider,
    tableSliderValue,
    tableSliderAutoPlay,
    tableMapPushpins,
    handleTableSliderAutoPlayClick,
    vehiclesSliderOptions,
    handleVehiclesSliderChange,
    vehiclesSliderStepValue,
    vehiclesMapBounds,
    vehiclesMapPolyline,
    vehiclesMapPushpins,
    vehicleSliderInfo,
}) => {
    return (
        <>
            <Form.Group>
                <Form.Field width={16}>
                    <BingMap
                        div={{ style: styles.map }}
                        map={{ center }}
                        pushpins={tableMapPushpins}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group style={styles.bunchingSliderContainer}>
                <Form.Field width={15}>
                    <PrettoSliderControl
                        valueLabelDisplay="on"
                        name="busBunchingTableSlider"
                        aria-label="pretto slider"
                        min={0}
                        max={bunchingArrivalsSlider.length > 0 ? bunchingArrivalsSlider.length - 1 : 0}
                        onChange={handleTableSliderChange}
                        step={1}
                        value={tableSliderValue}
                        valueLabelFormat={handleValueLabelFormat}
                        noBalloon
                    />
                </Form.Field>
                <Form.Field width={1}>
                    <Button
                        content={tableSliderAutoPlay ? 'Stop' : 'Play'}
                        onClick={handleTableSliderAutoPlayClick}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={16}>
                    <label className="categoryHeader">Select bunching situations to see details:</label>
                    <BunchingTable {...{ rows: bunchingArrivalsTable, selectedData: selectedTableData, selectedRowHandler }} />
                </Form.Field>
            </Form.Group>
            {selectedTableData &&
                <>
                    <Form.Group>
                        <Form.Field width={4}>
                            <PrettoSliderControl
                                valueLabelDisplay="off"
                                name="busBunchingVehiclesSlider"
                                aria-label="pretto slider"
                                min={vehiclesSliderOptions.minValue}
                                max={vehiclesSliderOptions.maxValue}
                                onChange={handleVehiclesSliderChange}
                                step={vehiclesSliderStepValue}
                                value={vehiclesSliderOptions.currentValue}
                            />
                            {vehicleSliderInfo && vehicleSliderInfo.vehiclePosition && vehicleSliderInfo.bunchVehicleId &&
                                <BusBunchingSliderInfo vehiclePosition={vehicleSliderInfo.vehiclePosition} bunchVehicleId={vehicleSliderInfo.bunchVehicleId} />
                            }
                        </Form.Field>
                        <Form.Field width={12}>
                            <BingMap
                                div={{ style: styles.map }}
                                map={{
                                    center,
                                    options: {
                                        bounds: vehiclesMapBounds,
                                    },
                                }}
                                polylines={vehiclesMapPolyline && [vehiclesMapPolyline]}
                                pushpins={vehiclesMapPushpins}
                            />
                        </Form.Field>
                    </Form.Group>
                </>
            }
        </>
    );
};

export default DetailsTabForm;