import { OperationResult } from '../types/apiTypes';
import { ServiceStatusModel } from '../types/servicePageTypes';
import api from './api';
import { buildUrlString } from './helpers';

export default class ServiceApi {
    static serviceRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/System/${url}`);
    static serviceFileRequest = async (url: string) => await api.doAuthGetFileRequestAsync(`/System/${url}`);

    static async getServicePageData(agencyId: string) {
        const url = buildUrlString(null, 'GetServicePageData', agencyId);
        return await this.serviceRequest<OperationResult<ServiceStatusModel>>(url);
    }

    static async getAgencyVehiclesFeed(agencyId: string) {
        const url = buildUrlString(null, 'GetAgencyVehiclesFeed', agencyId);
        return await this.serviceFileRequest(url);
    }
    static async getAgencyTripsFeed(agencyId: string) {
        const url = buildUrlString(null, 'GetAgencyTripsFeed', agencyId);
        return await this.serviceFileRequest(url);
    }
    static async getAgencyAlertsFeed(agencyId: string) {
        const url = buildUrlString(null, 'GetAgencyAlertsFeed', agencyId);
        return await this.serviceFileRequest(url);
    }
}