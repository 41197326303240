import '../../shared/chart-js-chartBoxPlot/Chart.BoxPlot.esm.js';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import ChartComponent from 'react-chartjs-2';
import { AnalysisByPercentiles, BoxPlotItemType } from '../../../types/runningTimeAnalyticsTypes';
import { AggregatedTripsItem } from './AnalysisByPercentilesForm';

interface ChartRef {
    chartInstance: {
        scales: {
            'y-axis-0': {
                getValueForPixel: (coordValue: number) => any,
                getLabelForIndex: (index: number, dataset: number) => string,
            }
        }
    }
}

const chartSettings = {
    // define label tree
    labels: [] as string[],
    datasets: [
        {
            label: 'delays data',
            backgroundColor: 'rgba(255,0,0,0.5)',
            borderColor: 'red',
            borderWidth: 1,
            padding: 20,
            itemBackgroundColor: 'lightgreen',
            itemBorderColor: 'grey',
            itemRadius: 5,
            itemStyle: 'circle',
            outlierColor: 'lightgreen',
            outlierBorderColor: 'grey',
            oulierLineWidth: 2,
            outlierRadius: 5,
            data: [] as BoxPlotItemType[],
        },
    ],
};

const options = {
    responsive: true,
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                id: 'x-axis-0',
                position: 'top',
                ticks: {
                    minStats: 'min',
                    maxStats: 'max',
                },
            }],
        yAxes: [{
            ticks: {
                fontColor: '#4183c4',
            },
        }],
    },
    tooltips: {
        callbacks: {
            boxplotLabel: function (
                _item: unknown, 
                _data: unknown, 
                { tripInfo, outliers }: {
                    tripInfo: AnalysisByPercentiles;
                    outliers: number[];
                },
                hoveredOutlierIndex: number,
            ) {
                if (hoveredOutlierIndex < 0 || outliers.length <= hoveredOutlierIndex) return;

                const { tripId, scheduledDelayToNextTrip, scheduledRunTime } = tripInfo;
                const actualDelay: number = outliers[hoveredOutlierIndex];
                const observedRunTime: number = scheduledRunTime + actualDelay;
                return [
                    'Trip ' + tripId,
                    'Scheduled Run-Time, min: ' + scheduledRunTime,
                    'Next trip start in, min: ' + scheduledDelayToNextTrip,
                    'Observerd Run-Time, min: ' + observedRunTime.toFixed(2),
                    `${actualDelay < 0 ? 'Shorter' : 'Longer'} than scheduled by, min: ` + Math.abs(actualDelay).toFixed(2),
                ].join('; ');
            },
        },
    },
    maintainAspectRatio: false,
    plugins: {
        crosshair: false,
    },
};

const heightPerRow = 30;

const DistributionChart: React.FC<{ distributionChartData: AggregatedTripsItem[], handleChartTimeAxisClick: (timeLabel: string) => void }> = ({ distributionChartData, handleChartTimeAxisClick }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    const [chartStyleState, setChartStyleState] = useState({
        height: distributionChartData.length * heightPerRow + 'px',
    });
    const chartRef = useRef<ChartRef>(null);

    useEffect(() => {
        const chartData = distributionChartData.map(d => {
            const min: number = Math.min(0, d.delaysMinutes[0]);
            const max: number = Math.max(d.scheduledDelayToNextTrip, d.delaysMinutes[d.delaysMinutes.length - 1]);
            return { ...d, min, max };
        }).filter(d => d.delaysMinutes.length > 0);
        const chartHeight: number = chartData.length * heightPerRow;
        setChartStyleState({ height: chartHeight + 'px' });
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(p => p.scheduledStartTime),
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(p => {
                    return {
                        min: p.min - 1,
                        max: p.max + 1,
                        q1: 0,
                        median: 0,
                        q3: 0,
                        whiskerMin: 0,
                        whiskerMax: p.scheduledDelayToNextTrip,
                        outliers: p.delaysMinutes,
                        tripInfo: p,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            onClick: (e: {}) => {
                if (!chartRef || !chartRef.current)
                    return;
                const chartInstance = chartRef.current.chartInstance;
                if (!chartInstance)
                    return;
                const canvasPosition = Chart.helpers.getRelativePosition(e, chartInstance);
                if (!canvasPosition)
                    return;
                const scales = chartInstance.scales;
                if (!scales)
                    return;
                const yScale = scales['y-axis-0'];
                if (!yScale)
                    return;
                const timeLabel = yScale.getLabelForIndex(yScale.getValueForPixel(canvasPosition.y as number), 0);
                handleChartTimeAxisClick(timeLabel);
            },
        }));
    }, [distributionChartData]);

    return (
        <div style={chartStyleState}>
            {/* 
                // @ts-ignore */}
            <ChartComponent type="horizontalBoxplot" data={dataState} options={optionsState as unknown as ChartOptions} ref={chartRef} />
        </div>
    );
};

export default DistributionChart;