import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { StopArrivalHeadway } from '../../../../types/headwaysTypes';
import Utils from '../../../../utilities/utils';


interface TableProps {
    rows: StopArrivalHeadway[],
}

const StopHeadwaysTable: React.FC<TableProps> = ({ rows }) => {
    const styles = {
        table: {
            display: 'block',
            maxHeight: '590px',
            overflowY: 'scroll',
        } as React.CSSProperties,
    };
    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Trip</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle</Table.HeaderCell>
                        {rows.filter(r => r.actualTime).length > 0 &&
                            <Table.HeaderCell textAlign="left" width={1}>Arrival<br />Time</Table.HeaderCell>
                        }
                        {rows.filter(r => r.headwaySec).length > 0 &&
                            <Table.HeaderCell textAlign="left" width={1}>Headway<br />(min)</Table.HeaderCell>
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <StopHeadwaysTableRow key={index} row={row} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default StopHeadwaysTable;

interface RowProps {
    row: StopArrivalHeadway;
}

const StopHeadwaysTableRow: React.FC<RowProps> = ({ row }) => {
    return (
        <Table.Row>
            <Table.Cell>
                {row.tripId} ({moment.parseZone(row.tripScheduledStart).format('h:mm A')} - {moment.parseZone(row.tripScheduledFinish).format('h:mm A')})
            </Table.Cell>
            <Table.Cell>
                {row.vehicleId}
            </Table.Cell>
            {row.actualTime && <Table.Cell>
                {moment.parseZone(row.actualTime).format('h:mm A')}
            </Table.Cell>}
            {row.headwaySec !== undefined && <Table.Cell>
                {row.headwaySec ? row.headwaySec < 3600 ? Utils.convertSecondsToMinutes(row.headwaySec) : Utils.convertSecondsToHours({ seconds: row.headwaySec }) : ''}
            </Table.Cell>}
        </Table.Row>
    );
};