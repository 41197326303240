import { BusAnalysisReport, DelayJumpsMain, DelayJumpsVehiclePosition, PositionReportingGap, TopWrongPredictingRoute, TripReportingGap, VehicleReportingGap, WrongCoordinatesReport, WrongCoordinatesVehiclePosition } from '../types/anomaliesTypes';
import { OperationResult } from '../types/apiTypes';
import api from './api';
import { buildUrlString } from './helpers';

async function anomaliesRequest<T>(url: string): Promise<T> {
    return await api.doAuthGetRequestAsync<T>(`/AnomaliesReports/${url}`);
}

export default class AnomaliesApi {
    static async getWrongCoordinates(agencyId: string, reportDate: string) {
        const url = buildUrlString(null, 'WrongCoordinates', agencyId, reportDate);
        return await anomaliesRequest<OperationResult<WrongCoordinatesReport>>(url);
    }
    static async getWrongCoordinatesVehiclePositions(agencyId: string, reportDate: string, tripInternalId: string, vehicleId: string, actualStart: string, actualFinish: string) {
        const url = buildUrlString(null, 'WrongCoordinatesVehiclePositions', agencyId, reportDate, tripInternalId, vehicleId, actualStart, actualFinish);
        return await anomaliesRequest<OperationResult<WrongCoordinatesVehiclePosition[]>>(url);
    }

    static async getDelayJumpsMain(agencyId: string, reportDate: string): Promise<OperationResult<DelayJumpsMain[]>> {
        const url = buildUrlString(null, 'DelayJumpsMain', agencyId, reportDate);
        return await anomaliesRequest<OperationResult<DelayJumpsMain[]>>(url);
    }

    static async getDelayJumpsVehiclePositions(agencyId: string, reportDate: string, tripInternalId: string, vehicleId: string): Promise<OperationResult<DelayJumpsVehiclePosition[]>> {
        const url = buildUrlString(null, 'DelayJumpsVehiclePositions', agencyId, reportDate, tripInternalId, vehicleId);
        return await anomaliesRequest<OperationResult<DelayJumpsVehiclePosition[]>>(url);
    }
    static async getVehiclesReportingGaps(agencyId: string, serviceDate: string): Promise<OperationResult<VehicleReportingGap[]>> {
        const url = buildUrlString(null, 'VehiclesReportingGaps', agencyId, serviceDate);
        return await anomaliesRequest<OperationResult<VehicleReportingGap[]>>(url);
    }
    static async getTripsReportingGaps(agencyId: string, serviceDate: string, vehicleId: string): Promise<OperationResult<TripReportingGap[]>> {
        const url = buildUrlString(null, 'TripsReportingGaps', agencyId, serviceDate, vehicleId);
        return await anomaliesRequest<OperationResult<TripReportingGap[]>>(url);
    }
    static async getPositionsReportingGaps(agencyId: string, serviceDate: string, tripId: string, vehicleId: string): Promise<OperationResult<PositionReportingGap[]>> {
        const url = buildUrlString(null, 'PositionsReportingGaps', agencyId, serviceDate, tripId, vehicleId);
        return await anomaliesRequest<OperationResult<PositionReportingGap[]>>(url);
    }
    
    static async getVehicles(agencyId: string): Promise<OperationResult<string[]>> {
        const url = buildUrlString(null, 'Vehicles', agencyId);
        return await anomaliesRequest<OperationResult<string[]>>(url);
    }

    static async getBusAnalysisReports(agencyId: string, fromDate: string, toDate: string, vehicleId: string): Promise<OperationResult<BusAnalysisReport[]>> {
        const url = buildUrlString(null, 'BusAnalysisReports', agencyId, fromDate, toDate, vehicleId);
        return await anomaliesRequest<OperationResult<BusAnalysisReport[]>>(url);
    }

    static async getTopWrongPredictingRoutes(agencyId: string, reportDate: string): Promise<OperationResult<TopWrongPredictingRoute[]>> {
        const url = buildUrlString(null, 'TopWrongPredictingRoutes', agencyId, reportDate);
        return await anomaliesRequest<OperationResult<TopWrongPredictingRoute[]>>(url);
    }
}
