import moment from 'moment';
import { BusPositionByBusNumber } from '../../types/busHistoryTypes';
import { VehicleStopArrival } from '../../types/vehicleArrivalsTypes';
import Utils from '../../utilities/utils';

type ReportColumn<TRow> = {
    header: string;
    getter: (row: TRow) => string | number | null | undefined;
};

export function toReportRow<TRow>(reportDef: ReportColumn<TRow>[], row: TRow): object {
    const reportRow: { [column: string]: string | number | null | undefined } = {};
    for (const colDef of reportDef) {
        const v = colDef.getter(row);
        if (v !== undefined)
            reportRow[colDef.header] = v;
    }
    return reportRow;
}

export const VehicleArivalsReportDef: ReportColumn<VehicleStopArrival>[] = [
    {
        header: 'Start Time',
        getter: (e: VehicleStopArrival) => moment.parseZone(e.prevStopDepartureTime).format('YYYY-MM-DD hh:mm A'),
    }, {
        header: 'Travel Time',
        getter: (e: VehicleStopArrival) => moment.utc(moment(e.stopArrivalTime).diff(e.prevStopDepartureTime)).format('hh:mm:ss'),
    }, {
        header: 'Distance (Miles)',
        getter: (e: VehicleStopArrival) => Utils.kmToMiles(e.traveledKmFromPrevStop),
    }, {
        header: 'Stop Time',
        getter: (e: VehicleStopArrival) => moment.parseZone(e.stopArrivalTime).format('YYYY-MM-DD hh:mm A'),
    }, {
        header: 'Stop Duration',
        getter: (e: VehicleStopArrival) => moment.utc(e.stopDurationSec * 1000).format('HH:mm:ss'),
    }, {
        header: 'Trip',
        getter: (e: VehicleStopArrival) => `${e.routeName} - ${e.directionName}, starting at ${moment.parseZone(e.tripScheduledStart).format('h:mm A')}`,
    }, {
        header: 'Stop NN',
        getter: (e: VehicleStopArrival) => e.stopSequence,
    }, {
        header: 'Location',
        getter: (e: VehicleStopArrival) => e.stopName,
    },
];

export const VehicleArivalsReportHeaders = VehicleArivalsReportDef.map(column => column.header);


export const BusPositionsReportDef: ReportColumn<BusPositionByBusNumber>[] = [
    {
        header: 'Latitude',
        getter: (e: BusPositionByBusNumber) => e.latitude,
    }, {
        header: 'Longitude',
        getter: (e: BusPositionByBusNumber) => e.longitude,
    }, {
        header: 'Vehicle Time',
        getter: (e: BusPositionByBusNumber) => moment.parseZone(e.vehicleTime).format('YYYY-MM-DD hh:mm A'),
    },
];

export const BusPositionsReportHeaders = BusPositionsReportDef.map(column => column.header);