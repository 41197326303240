export const History = 'History';
export const Linear = 'Linear';
export const Siri = 'Siri';

export type PredictionModel = typeof History | typeof Linear | typeof Siri;

export interface DelaysAnalysisCompareData {
    reportDate: Date;
    tripId: string;
    vehicleTime: Date,
    transitIqDelaySec: number | null;
    realDelaySec: number | null;
    longitude: Longitude;
    latitude: Latitude
}
export interface PredictedArrivalTimeReportRequest {
    agencyId: string;
    serviceDate: string;
    tripId: string;
    modelName: PredictionModel;
}
export interface PredictedArrivalTimeReport {
    agencyId: string;
    predictionModel: string;
    businessDate: string;
    predictionTime: string;
    vehicleId: string;
    tripId: string;
    internalTripId: string;
    arrivalData: PredictionArrivalData[];
    accuracy: number;
}
export interface PredictionArrivalData {
    stopSequence: number;
    traveledKm: number;
    predictedTime: string;
    actualTime: string;
    scheduledTime: string;
    isAccurate: number;
    isAssessed: boolean
}
export interface PredictionArrivalChartData {
    tripId: string;
    arrivalData: PredictionArrivalData[];
    accuracy: number;
    predictionTime: string;
}

export interface PredictionCompletenessData {
    agency: string;
    period: string;
    startDate: Date;
    endDate: Date;
    predictions: PredictionItems;
    routes: PredictionCompletenessRoute[];
}
export interface PredictionItems {
    short: PredictionItem;
    mid1: PredictionItem;
    mid2: PredictionItem;
    long: PredictionItem;
}
export interface PredictionItem {
    targetCount: number;
    predictedCount: number;
}
export interface PredictionCompletenessRoute {
    route: string;
    predictions: PredictionItems;
    directions: PredictionCompletenessDirection[];
}
export interface PredictionCompletenessDirection {
    directionVariantInternalId: string;
    predictions: PredictionItems;
}

export interface PredictionsStatsForPeriodData {
    rangeCat: number;
    stopId: string;
    stopSequence: number;
    targetCount: number;
    predictedCount: number;
}

export interface PredictionAccuracyReportData {
    days: PredictionAccuracyKpi[];
    weeks: PredictionAccuracyKpi[];
    months: PredictionAccuracyKpi[];
}
export interface PredictionAccuracyKpi {
    id: string; // 'Ho414_History_Week_20210926',
    agency: string; // 'Ho414',
    period: 'Day' | 'Week' | 'Month';
    startDate: string; // "2021-10-25T00:00:00Z"
    endDate: string; // "2021-10-31T00:00:00Z"
    model: 'History' | 'Siri' | 'Linear';
    kpi: KpiSet;
    routes: RouteKpi[];
}
export interface KpiSet extends DistributionData {
    overallTotal: number;
    overallAccurate: number;
    overallHourly: OverallHourly;
}
export interface RouteKpi {
    route: string; // '002',
    kpi: KpiSet;
    directions: DirectionKpi[];
}
export interface DistributionData {
    shortTotal: number;
    shortAccurate: number;
    mid1Total: number;
    mid1Accurate: number;
    mid2Total: number;
    mid2Accurate: number;
    longTotal: number;
    longAccurate: number;
}
export interface OverallHourly {
    [hour: string]: number;
}
export interface DirectionKpi {
    directionVariantInternalId: string; // 'Ho414_002_MISSION_BEND_TC_31067006',
    tripHeadsign: string;
    otsTripShortName: string;
    kpi: KpiSet;
}