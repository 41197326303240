import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Header } from 'semantic-ui-react';
import { getTestRecipients, sendConfirmNotification } from '../../actions/pushNotificationActions';
import { ConfirmNotification } from '../../types/pushNotificationTypes';
import { ModalTextMessage } from './ModalTextMessage';

interface NotificationRecipient {
    key: string,
    name: string,
}

const SendMessageForm: React.FC = () => {
    const [recipientsState, setRecipientsState] = useState<NotificationRecipient[]>([{
        key: 'Send to All',
        name: 'Send to All Installed Apps',
    }]);
    const [selectRecipientState, setSelectRecipientState] = useState<string>('Send to All');
    const textAreaMessageRef = useRef<HTMLTextAreaElement>();
    const textAreaWebUrlRef = useRef<HTMLTextAreaElement>();
    const [modalVisibleState, setModalVisibleState] = useState<boolean>(false);
    const [modalTextState, setModalTextState] = useState<string>('');

    useEffect(() => {
        (async () => {
            const result = await getTestRecipients();
            if (result) {
                const recipients = [...recipientsState, ...result.map(item => ({
                    key: item.Id,
                    name: item.Name,
                } as NotificationRecipient))];
                setRecipientsState(recipients);
            }
        })();
    }, []);

    const sendPushNotificationHandler = async () => {
        const confirm: ConfirmNotification = {
            recipient: selectRecipientState,
            message: textAreaMessageRef.current?.value ?? '',
            webUrl: textAreaWebUrlRef.current?.value,
        };
        const result = await sendConfirmNotification(confirm);
        if (result) {
            setModalTextState(`Please confirm this action by using the link sent to ${result.recipient}`);
            setModalVisibleState(true);
        }
    };

    const onSelectItemHandle = (item: string) => {
        setSelectRecipientState(item);
    };

    const defaultText = '';
    return (
        <Fragment>
            <Header as="h1" className="reportHeader">
                Send Message
            </Header>
            <Form>
                <Form.Group>
                    <Form.Field width={7}>
                        <label>Message:</label>
                        <textarea ref={textAreaMessageRef as any} rows={4} defaultValue={defaultText}
                                  style={{
                                      fontSize: 16,
                                  }}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Recipients:</label>
                        <SelectRecipients recipients={recipientsState} onSelectItemHandle={onSelectItemHandle}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={7}>
                        <label>Web URL (optional):</label>
                        <textarea ref={textAreaWebUrlRef as any} rows={1}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Button
                            primary
                            content="Send"
                            onClick={sendPushNotificationHandler}
                            className="primaryButton"
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <ModalTextMessage key="modalView"
                                    text={modalTextState}
                                    open={modalVisibleState}
                                    setOpen={setModalVisibleState}
            />
        </Fragment>
    );
};


export default connect()(SendMessageForm);

interface RecipientsProps {
    recipients: NotificationRecipient[];
    onSelectItemHandle: (item: string) => void
}

const SelectRecipients: React.FC<RecipientsProps> = ({ recipients, onSelectItemHandle }) => {
    const dropdownRef = useRef();

    const options = recipients.map((recipient) => (
        {
            key: recipient.key,
            value: recipient.key,
            text: recipient.name,
        }
    ));
    return (
        <Dropdown ref={dropdownRef.current} className="font-size12" placeholder="Select device" selection
                  options={options}
                  defaultValue={recipients[0].key}
                  onChange={(_, data) => onSelectItemHandle(data.value?.toString() ?? '')}
        />
    );
};

