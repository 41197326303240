import moment from 'moment/moment';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { GtfsFileStateType } from '../../types/gtfsFilesTypes';

interface Props {
    fileId: string;
    gtfsFiles: GtfsFileStateType[];
    text: string
}

const GtfsDetails: React.FC<Props> = (props) => {
    const [gtfs, setGtfs] = useState<GtfsFileStateType>();

    useEffect(() => {
        if (props.fileId !== undefined)
            console.log(`load details file ID:${props.fileId}`);

        const selectGtfs = props.gtfsFiles.find(x => x.gtfsFileId === props.fileId);
        setGtfs(selectGtfs);
    }, [props.fileId]);

    return (
        <Fragment>
            <h3>Details for: {props.fileId}</h3>
            <h4>Info:</h4>
            <table>
                <tr>
                    <td>Status:</td>
                    <td>{gtfs?.status}</td>
                </tr>
                <tr>
                    <td>Date uploaded:</td>
                    <td>{gtfs?.uploadedUtc ? moment.parseZone(gtfs.uploadedUtc).format('YYYY-MM-DD h:mm A') : ''}</td>
                </tr>
                <tr>
                    <td>Date activated:</td>
                    <td>{gtfs?.activationDate ? moment.parseZone(gtfs.activationDate).format('YYYY-MM-DD h:mm A') : ''}</td>
                </tr>
                <tr>
                    <td>Is valid:</td>
                    <td>{gtfs?.valid ? 'true' : 'false'}</td>
                </tr>
            </table>
            {gtfs?.validationMessage && <Fragment>
                <h4> Validation message: </h4>
                {gtfs?.validationMessage}
            </Fragment>}
        </Fragment>
    );
};

export default GtfsDetails;