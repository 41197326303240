import { LOAD_BING_MAPS_SUCCESS, LoadBingMapsActionType } from '../actions/actionTypes';

export type BingMapsState = null | typeof Microsoft.Maps;

export default function bingMapsReducer(state: BingMapsState = null, action: LoadBingMapsActionType): BingMapsState {
    switch (action.type) {
        case LOAD_BING_MAPS_SUCCESS: return Microsoft.Maps;
        default: return state;
    }
}
