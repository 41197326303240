import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { AnalysisByPercentiles } from '../../../types/runningTimeAnalyticsTypes';


const styles = {
    table: {
        display: 'block',
        maxHeight: '294px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface TableProps {
    rows: AnalysisByPercentiles[];
}

const TripsListTable: React.FC<TableProps> = ({ rows }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Service Date</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Bus ID</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <ArrivalsForStopTableRow key={index} row={row} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

interface RowProps {
    row: AnalysisByPercentiles,
}

const ArrivalsForStopTableRow: React.FC<RowProps> = ({ row }) => {
    return (
        <Table.Row>
            <Table.Cell textAlign="left">
                {moment(row.serviceDate).format('YYYY-MM-DD')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                <Link to={`/vehicle-history/bus-number-position/${moment.parseZone(row.scheduledStartTime).format('YYYY-MM-DD')}/${row.vehicleId}/${row.tripId}`}>{row.vehicleId}</Link>
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.tripId} ({moment.parseZone(row.scheduledStartTime).format('h:mm A')} - {moment.parseZone(row.scheduledEndTime).format('h:mm A')})
            </Table.Cell>
        </Table.Row>
    );
};

export default TripsListTable;