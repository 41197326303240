import { AlertActionTypes, AlertStateType, HIDE_ALERT, POST_GTFS_ERROR, VALIDATE_GTFS_ERROR, VALIDATE_GTFS_SUCCESS } from '../actions/actionTypes';

const initialState: AlertStateType = {
    success: true,
    text: '',
    title: 'Success',
    show: false,
};


export default function alertReducer(state = initialState, action: AlertActionTypes): AlertStateType {
    if (action.type === VALIDATE_GTFS_SUCCESS) {
        return Object.assign({}, state, {
            title: action.isValid ? 'Success' : 'File is not valid!',
            text: 'File ' + action.gtfsFileId + (action.isValid ? ' is valid.' : ' is not valid.'),
            show: !action.isValid,
        } as AlertStateType);
    }
    else if (action.type === POST_GTFS_ERROR) {
        return Object.assign({}, state, {
            title: 'Something unexpected happened.',
            text: "Couldn't  save file.",
            show: true,
        });
    }
    else if (action.type === VALIDATE_GTFS_ERROR) {
        return Object.assign({}, state, {
            title: 'Something unexpected happened.',
            text: "Couldn't  validate file " + action.gtfsFileId + '.',
            show: true,
        });
    }
    else if (action.type === HIDE_ALERT) {
        return Object.assign({}, state, {
            title: '',
            text: '',
            show: false,
        });
    }
    else {
        return state;
    }
}