import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Header, Loader, Segment, Tab, TabProps } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getGtfsStopTimesByStopAndShape, getGtfsTripsAndStops, getShapeData, getSparkTripsByDirectionVariantIntertnalId } from '../../../actions/gtfsStaticActions';
import Api from '../../../api/api';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import busStopIcon from '../../../static/bus-stop-20.png';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../../types/BingMapProps';
import { GtfsTripAndStops } from '../../../types/gtfsTypes';
import { IDirectionModel, IRouteModel } from '../../../types/view-models-interfaces';
import Utils from '../../../utilities/utils';
import BingMap from '../../shared/BingMap';
import RouteVariantsSelector from '../../shared/RouteVariantsSelector';
import RouteExplorerTable from './RouteExplorerTable';


interface Props {
    agency: AgencyStateType | undefined;
}

export interface RouteExplorerTableData {
    tripId: string;
    tripScheduledStart: Date;
    tripScheduledFinish: Date;
    scheduledTime?: Date;
}

interface SelectionType {
    routeName: string;
    directionName: string;
    stopName?: string;
}

const styles = {
    mapContainer: {
        height: '730px',
    } as React.CSSProperties,
    routeSelection: {
        height: '674px',
    } as React.CSSProperties,
    tab: {
        border: 'none',
        padding: 0,
        marginBottom: 0,
    } as React.CSSProperties,
    speedMap: {
        paddingBottom: 0,
    } as React.CSSProperties,
};

const RouteExplorerForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [infoboxesState, setInfoboxesState] = useState<InfoboxProps[]>([]);
    const [stopsState, setStopsState] = useState<GtfsTripAndStops[]>([]);
    const [routesListState, setRoutesListState] = useState<IRouteModel[]>([]);
    const [tripsState, setTripsState] = useState<RouteExplorerTableData[]>([]);
    const [selectedTripState, setSelectedTripState] = useState<RouteExplorerTableData | undefined>();
    const [selectedItemsState, setSelectedItemsState] = useState<SelectionType | undefined>();
    const [tabIndexState, setTabIndexState] = useState<number>(0);

    useEffect(() => {
        clearStates();
        if (agency === undefined) return;
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
            await retrieveTripsData();
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        setTabIndexState(tripsState.length === 0 ? 0 : 1);
    }, [tripsState]);

    const showScheduledTime: boolean = selectedItemsState !== undefined && selectedItemsState.stopName !== undefined;

    const handleSelectedDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setSelectedDateState(value);
    };

    const handleRouteVariantChecked = async (route: IRouteModel, direction: IDirectionModel) => {
        routesListState.forEach(r => {
            r.directions.forEach(d => {
                d.selected = r.routeId === route.routeId && r.routeName === route.routeName && d.directionVariantId === direction.directionVariantId;
            });
        });
        setSelectedItemsState({
            routeName: route.routeName,
            directionName: direction.cardinalDirection,
        });
        if (agency && agency.id && direction.shapeId) {
            setFormBlockingState(true);
            try {
                const [trips, shapePoints] = await Promise.all(
                    [getSparkTripsByDirectionVariantIntertnalId(agency.id, selectedDateState, direction.directionVariantId),
                    getShapeData(agency.id, selectedDateState, direction.shapeId)]);
                setPolylineState({
                    locations: shapePoints.map(({ latitude, longitude }) => [latitude, longitude]),
                    options: {
                        strokeColor: 'red',
                        strokeThickness: 3,
                    },
                });
                const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
                const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
                setMapBoundsState(boundingBox);
                const stopsForSelectedDirection = stopsState.filter(s => s.directionVariantInternalId === direction.directionVariantId);
                drawPushpins(stopsForSelectedDirection);
                setTripsState(trips);
            } catch {
                setTripsState([]);
                setPushpinsState([]);
                setInfoboxesState([]);
            } finally {
                setFormBlockingState(false);
            }
        }
    };

    const handleTabChange = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, { activeIndex }: TabProps) => {
        setTabIndexState(activeIndex as number);
    };

    const drawPushpins = async (stopPositions: GtfsTripAndStops[]) => {
        if (stopPositions.length > 0) {
            setPushpinsState(
                stopPositions.map(({ latitude, longitude, stopName, shapeId, stopId }) => ({
                    location: [latitude, longitude],
                    options: {
                        icon: busStopIcon,
                        catId: 'bus-stop',
                    },
                    eventHandlers: [{
                        event: 'click',
                        callback: async () => {
                            setInfoboxesState(prevState => (
                                prevState.map(i => i.location[0] === latitude && i.location[1] === longitude ?
                                    { ...i, options: { ...i.options, visible: true } } :
                                    { ...i, options: { ...i.options, visible: false } })
                            ));
                            setSelectedItemsState(prevState => prevState ? ({ ...prevState, stopName }) : undefined);
                            await updateTripsDataForStop(shapeId, stopId);
                        },
                    }],
                })));
            setInfoboxesState(
                stopPositions.map(({ latitude, longitude, stopName }) => ({
                    location: [latitude, longitude],
                    options: {
                        description: stopName,
                        visible: false,
                    },
                })));
        } else {
            setPushpinsState([]);
            setInfoboxesState([]);
        }
    };

    const updateTripsDataForStop = async (shapeId: string, stopId: string) => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const trips = await getGtfsStopTimesByStopAndShape(agency.id, selectedDateState, shapeId, stopId);
                setTripsState(trips);
            } finally {
                setFormBlockingState(false);
            }
        }
    };

    const retrieveTripsData = async () => {
        clearStates();
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const stopsData = await getGtfsTripsAndStops(agency.id, selectedDateState);
                setStopsState(stopsData);
                updateRoutesList(stopsData);
            } catch {
                setStopsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setStopsState([]);
        }
    };

    const updateRoutesList = (stopsData: GtfsTripAndStops[]) => {
        const routesList = Object.entries(Utils.groupBy(stopsData, t => t.routeShortName))
            .map<IRouteModel>(([routeName, routeData]) => ({
                routeId: routeName,
                routeName: routeName,
                cssColor: 'rgb(0, 64, 128)',
                directions: Object.entries(Utils.groupBy(routeData, r => r.directionVariantInternalId)).map<IDirectionModel>(([directionVariantId, variantData]) => ({
                    directionVariantId,
                    distance: 0,
                    selected: false,
                    cardinalDirection: variantData[0].otsTripShortName,
                    cardinalDirectionShort: variantData[0].otsTripShortName,
                    fromStopName: variantData.reduce((prev: GtfsTripAndStops, curr: GtfsTripAndStops) => prev.stopSequence < curr.stopSequence ? prev : curr).stopName,
                    toStopName: variantData.reduce((prev: GtfsTripAndStops, curr: GtfsTripAndStops) => prev.stopSequence > curr.stopSequence ? prev : curr).stopName,
                    isMain: true,
                    shapeId: variantData[0].shapeId,
                })),
            }))
            .sort((a, b) => (a.routeName > b.routeName) as unknown as number - ((a.routeName < b.routeName) as unknown as number));
        setRoutesListState(routesList);
    };

    const getTableName = () => {
        if (!selectedItemsState)
            return '';
        let tableName = `Trips for Route ${selectedItemsState.routeName} (${selectedItemsState.directionName})`;
        if (selectedItemsState.stopName)
            tableName += ` and Stop ${selectedItemsState.stopName}`;
        return tableName;
    };

    const clearStates = () => {
        setStopsState([]);
        setPushpinsState([]);
        setInfoboxesState([]);
        setPolylineState(undefined);
        setRoutesListState([]);
        setSelectedItemsState(undefined);
        setTripsState([]);
    };

    const getTabPanes = () => {
        const tabPanes = [
            {
                menuItem: 'Routes',
                pane: (
                    <Tab.Pane key="routesTab" style={styles.tab} >
                        <div className="speedmap side" style={styles.speedMap}>
                            <Segment id="routeSelectionPanel" style={styles.routeSelection}>
                                {routesListState.length === 0 ? <Loader active>Loading active routes...</Loader> : null}
                                {routesListState.map((r, i) => (
                                    <RouteVariantsSelector key={i}
                                        route={r}
                                        onChecked={handleRouteVariantChecked}
                                    />
                                ))}
                            </Segment>
                        </div>
                    </Tab.Pane >
                ),
            },
        ];
        if (tripsState.length > 0) {
            tabPanes.push({
                menuItem: 'Trips',
                pane: (
                    <Tab.Pane key="tripsTab" style={styles.tab}>
                        <>
                            <label className="categoryHeader">{getTableName()}</label>
                            <RouteExplorerTable rows={tripsState} selectedRow={selectedTripState} setSelectedRow={setSelectedTripState} showScheduledTime={showScheduledTime} />
                        </>
                    </Tab.Pane >
                ),
            });
        }
        return tabPanes;
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Route Explorer
                </Header>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={retrieveTripsData}
                            className="primaryButton"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={16}>
                        <label className="categoryHeader">Pick a route direction and click on stop sign to see scheduled arrivals</label>
                    </Form.Field>
                </Form.Group>
                <Form.Group className="bottomGroup">
                    <Form.Field width={11} style={styles.mapContainer}>
                        <BingMap
                            map={{
                                center,
                                options: {
                                    bounds: mapBoundsState,
                                },
                            }}
                            polylines={polylineState && [polylineState]}
                            pushpins={pushpinsState}
                            infoboxes={infoboxesState}
                        />
                    </Form.Field>
                    <Form.Field width={5}>
                        <Tab menu={{ pointing: true }} panes={getTabPanes()} renderActiveOnly={false} activeIndex={tabIndexState} onTabChange={handleTabChange} />
                    </Form.Field>
                </Form.Group>
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({ agency: getSelectedOrDefaultAgency(state) }),
)(RouteExplorerForm);