import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { ArrivalForStop } from '../../../../types/delaysTypes';
import ArrivalsForStopTableRow from './ArrivalsForStopTableRow';


const styles = {
    table: {
        display: 'block',
        maxHeight: '294px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    rows: ArrivalForStop[];
    selectedRow: ArrivalForStop | undefined;
    selectedRowHandler: (selectedRow: ArrivalForStop) => void
}

const ArrivalsForStopTable: React.FC<Props> = ({ rows, selectedRow, selectedRowHandler }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Bus ID</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Route</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Delay (min)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Actual Arrival</Table.HeaderCell>
                        
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <ArrivalsForStopTableRow key={index} row={row} selectedRow={selectedRow} selectedRowHandler={selectedRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default ArrivalsForStopTable;