import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadBingMapsActionType } from '../../actions/actionTypes';

interface Props {
    setBingMapsIsLoaded: () => void;
}

const key = 'AnNjOQUEpx1-gkTx541SoEv8HE0LhTTaXW4EJJ0hng97oRQGSMsm7VVlfDwFs5SI' as const;
const callbackName = '__bing_maps_callback__' as const;

const BingMapsLoader: React.FC<Props> = ({ setBingMapsIsLoaded }) => {
    useEffect(
        () => {
            const globalHost = window as typeof window & { [callback: string]: (() => void) | undefined; };
            if (globalHost[callbackName] !== undefined) {
                throw new Error(`Chosen BingMaps callback name ${callbackName} conflicts with another global property.`);
            }
            globalHost[callbackName] = () => {
                setBingMapsIsLoaded();
            };

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = `https://www.bing.com/api/maps/mapcontrol?callback=${callbackName}&key=${key}`;
            document.body.appendChild(script);

            return () => {
                delete globalHost[callbackName];
                document.body.removeChild(script);
            };
        },
        [],
    );
    return null;
};

export default connect(
    null,
    dispatch => ({
        setBingMapsIsLoaded: () => dispatch(loadBingMapsActionType),
    }),
)(BingMapsLoader);
