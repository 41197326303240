import React from 'react';
import { Route, RouteProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Header, Menu } from 'semantic-ui-react';
import { toRoutePaths } from './routes';

interface TransitIqRoutePropsBase {
    readonly label: string;
    readonly exact?: boolean;
    readonly hidden?: boolean;
    readonly role?: string;
}

interface TransitIqRoutePropsPath {
    readonly path: string | string[];
}

interface TransitIqRoutePropsExtension {
    readonly extension: string;
}

type TransitIqRoutePropsPathOrExtension =
    | TransitIqRoutePropsPath
    | TransitIqRoutePropsExtension
    ;

type TransitIqRoutePropsComponentOrSubroutes =
    | Readonly<Pick<RouteProps, 'component'>>
    | {
        readonly subroutes: readonly TransitIqRouteProps[];
    }
    ;

type TransitIqRouteProps =
    & TransitIqRoutePropsBase
    & TransitIqRoutePropsPathOrExtension
    & TransitIqRoutePropsComponentOrSubroutes
    ;

/** used for the root, "first" layer */
export type TransitIqRootRouteProps =
    & TransitIqRoutePropsBase
    & TransitIqRoutePropsPath // are root, no parents, so nothing to extend
    & TransitIqRoutePropsComponentOrSubroutes
    ;

function isRouteForPathOver(pathname: string) {
    return function isRouteForPath(
        route: TransitIqRouteProps,
        parentPaths: string[],
    ): boolean {
        const pathOrPaths = toRoutePaths(route, parentPaths);

        if (pathOrPaths.includes(pathname)) return true;
        if (!('subroutes' in route)) return false;

        return  route.subroutes.some(
            subroute => isRouteForPath(subroute, pathOrPaths),
        );
    };
}

export function isRootRouteForPathOver(pathname: string) {
    const isRouteForPath = isRouteForPathOver(pathname);
    return function isRootRouteForPath(
        route: TransitIqRootRouteProps,
    ): boolean {
        return isRouteForPath(route, ['']);
    };
}

function throwFourthLayerError(label: string, subroutes: readonly TransitIqRouteProps[]): never {
    throw new Error(
        `Third-layer route ${label} has subroutes: ${subroutes.map(({ label }) => label).join(', ')}. Third-layer routes are not allowed to have subroutes.`,
    );
}

const TransitIqRoute: React.FC<{ route: TransitIqRouteProps; parentPaths: string | string[]; layer: 1 | 2 | 3; }> =
    ({ route, parentPaths, layer }) => {
        const paths = toRoutePaths(route, parentPaths);
        const { label, exact } = route;
        if ('subroutes' in route) {
            const subroutes = 'subroutes' in route ? route.subroutes : [];
            const nextLayer = (() => {
                switch (layer) {
                    case 1: return 2;
                    case 2: return 3;
                    case 3: return throwFourthLayerError(label, subroutes);
                }
            })();
            return (
                <Route key={label} {...{ path: paths, exact }}>
                    {layer === 2
                        ? <Menu fluid borderless className="navigation-third-layer">
                            {subroutes.map(subroute => (
                                <Menu.Item key={subroute.label} as={NavLink} to={toRoutePaths(subroute, paths)[0]} exact={subroute.exact}>
                                    {subroute.label}
                                </Menu.Item>
                            ))}
                        </Menu>
                        : null
                    }
                    {subroutes.map((subroute, i) => (
                        <TransitIqRoute key={i + subroute.label} route={subroute} parentPaths={paths} layer={nextLayer} />
                    ))}
                </Route>
            );
        }
        else {
            const component = 'component' in route && route.component
                ? route.component
                : toWorkInProgressStubFor(label);
            return (
                <Route key={label} {...{ path: paths, exact, component }} />
            );
        }
    };

const TransitIqRootRoute: React.FC<TransitIqRootRouteProps> = (route) => {
    return (
        <TransitIqRoute route={route} parentPaths="" layer={1} />
    );
};

export default TransitIqRootRoute;

const toWorkInProgressStubFor = (label: string): React.FC<ExactlyAnEmptyObject> => () => (
    <>
        <Header as="h2">{label}</Header>
        <p>Coming soon!</p>
    </>
);
