export interface SecondsToHoursOptions {
    seconds: number | null;
    hoursLabel?: string;
    minutesLabel?: string;
    secondsLabel?: string;
    delimiter?: string;
    displaySeconds?: boolean
}

export class SecondsToHoursValue implements SecondsToHoursOptions {
    constructor({ seconds, hoursLabel, minutesLabel, secondsLabel, delimiter, displaySeconds }: SecondsToHoursOptions) {
        this.seconds = seconds;
        this.hoursLabel = hoursLabel ?? '';
        this.minutesLabel = minutesLabel ?? '';
        this.secondsLabel = secondsLabel ?? '';
        this.delimiter = delimiter ?? ':';
        this.displaySeconds = displaySeconds ?? true;
    }
    seconds: number | null;
    hoursLabel?: string;
    minutesLabel?: string;
    secondsLabel?: string;
    delimiter?: string;
    displaySeconds?: boolean;
}