import { UserManager } from 'oidc-client';
import { loggingInPathPrefix, logoutPathPrefix } from '../components/shared/routes';

const userManagerSettings = {
    authority: config.StsUrl,
    client_id: config.ClientId,
    redirect_uri: window.origin + loggingInPathPrefix,
    post_logout_redirect_uri: window.origin + logoutPathPrefix,
    response_type: 'id_token token',
    scope: 'openid profile email ' + config.ApiResource,
    loadUserInfo: true,
    silent_redirect_uri: window.origin + '/silent-refresh.html',
    automaticSilentRenew: true,
} as const;

export default new UserManager(userManagerSettings);
