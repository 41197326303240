import { getRoutes } from '../../actions/gtfsStaticActions';
import Api from '../../api/api';
import { buildUrlString, makeErrorResult, makeSuccessResult, SearchParams } from '../../api/helpers';
import { ApiOpResult } from '../../types/apiTypes';
import { RouteStopData } from '../../types/gtfsTypes';
import { OrgLocation } from '../../types/types';
import { RouteModel } from '../../types/view-models';
import { DirectionAvgSpeeds, DirectionAvgSpeedsSet, StopDwellTimeDto } from './_dto';

export async function getAgencyLocation(agencyId: string): Promise<ApiOpResult<CoordinatePair>> {
    const queryParams: SearchParams = { orgId: agencyId };
    const url = buildUrlString(queryParams, 'Service.svc', 'GetOrgLocation');
    const oldApiResult = await Api.doAuthGetRequestAsync<OrgLocation | string>(url);
    if (oldApiResult === '')
        return makeErrorResult(400, 'Can not get agency location from server');

    const orgLocation = oldApiResult as OrgLocation;
    return makeSuccessResult([orgLocation.Latitude, orgLocation.Longitude]);
}

export async function getOrgLocation(agencyId: string): Promise<CoordinatePair> {
    const result = await Api.getOrgLocation(agencyId);
    return [result.Latitude, result.Longitude];
}

export async function loadRoutes(agencyId: string, serviceDate1: string) {
    // TODO: requesting from server all routes from service date range
    const routes = await getRoutes(agencyId, serviceDate1);
    return routes.map(src => new RouteModel(src));
}

export async function loadAverageSpeeds(agencyId: string, serviceDate: string) {
    const routes = await getRoutes(agencyId, serviceDate);
    return routes.map(src => new RouteModel(src));
}

const controller = 'SpeedMap' as const;

export async function getSpeedMapData(agencyId: string, directionVariantId: string, timeFrom: string, timeTo: string, serviceDateFrom: string, serviceDateTo: string, excludeWeekend: boolean, setId: number, avgScale?: number): Promise<DirectionAvgSpeedsSet> {
    const queryParams: SearchParams = {
        directionVariantId: directionVariantId,
        fromHour: String(new Date(timeFrom).getHours()),
        toHour: String(new Date(timeTo).getHours()),
        fromReportDate: serviceDateFrom,
        toReportDate: serviceDateTo,
        includeWeekend: (!excludeWeekend).toString(),
        scale: avgScale || 500,
    };
    const url = buildUrlString(queryParams, controller, 'SpeedMapData', agencyId);
    const apires = await Api.doAuthGetRequestAsync<ApiOpResult<DirectionAvgSpeeds[]>>(url);
    if (apires.isSuccess)
        return {
            id: setId,
            speedMapData: apires.data,
        };
    else
        return {
            id: setId,
            speedMapData: [],
        };
}

export async function loadDirectionVariantStops(
    directionVariantId: string,
    fromDate: string,
    toDate: string,
): Promise<ApiOpResult<RouteStopData[]>> {
    const url = buildUrlString(null, controller, 'DirectionVariantStops', directionVariantId, fromDate, toDate);
    return await Api.doAuthGetRequestAsync<ApiOpResult<RouteStopData[]>>(url);
}

export async function loadStopDwellTimes(
    directionVariantId: string,
    fromDate: string,
    toDate: string,
    fromDayTime: string,
    toDayTime: string,
): Promise<ApiOpResult<StopDwellTimeDto[]>> {
    const url = buildUrlString(null, controller, 'StopDwellTimes', directionVariantId, fromDate, toDate, fromDayTime, toDayTime);
    return await Api.doAuthGetRequestAsync<ApiOpResult<StopDwellTimeDto[]>>(url);
}