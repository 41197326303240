import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DelayJumpsMain } from '../../../types/anomaliesTypes';
import { TableDispatchAction, TableSortProperties } from '../../../types/types';
import DelayJumpsMainTableRow from './DelayJumpsMainTableRow';

const styles = {
    table: {
        display: 'block',
        maxHeight: '385px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    rows: DelayJumpsMain[],
    tableSortReducer: (state: TableSortProperties<DelayJumpsMain>, action: TableDispatchAction<DelayJumpsMain>) => TableSortProperties<DelayJumpsMain>,
    selectedTripJump: DelayJumpsMain | undefined,
    jumpRowHandler: (tripJump: DelayJumpsMain) => Promise<void>
}

const DelayJumpsMainTable: React.FC<Props> = ({ rows, tableSortReducer, selectedTripJump, jumpRowHandler }) => {
    const [state, dispatch] = React.useReducer(tableSortReducer, {
        column: '',
        data: rows,
        direction: undefined,
    });
    const { column, data, direction } = state;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'vehicleId' ? direction : undefined}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'vehicleId' })}>
                            Vehicle
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'routeName' ? direction : undefined}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'routeName' })}>
                            Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'tripSchedule' ? direction : undefined}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'tripSchedule' })}>
                            Trip
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'delayJumpsCount' ? direction : undefined}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'delayJumpsCount' })}>
                            Number of Jumps
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'avgJumpSpeed' ? direction : undefined}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'avgJumpSpeed' })}>
                            Average Jump Magnitude
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <DelayJumpsMainTableRow key={index} row={row} selectedTripJump={selectedTripJump} jumpRowHandler={jumpRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default DelayJumpsMainTable;
