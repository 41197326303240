import { TableDispatchAction, TableSortProperties } from '../../../../types/types';
import { StopDelayMetric } from './StopDelaysForm';

export default function tableSortReducer(state: TableSortProperties<StopDelayMetric>, action: TableDispatchAction<StopDelayMetric>): TableSortProperties<StopDelayMetric> {
    if (!action.data)
        return state;
    switch (action.type) {
        case 'CHANGE_SORT': {
            if (state.column === action.column) {
                return {
                    ...state,
                    data: action.data.reverse().slice(0, 500),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                };
            }
            let data = [];
            switch (action.column) {
                case 'stopId':
                case 'stopName':
                    data = action.data.sort((a, b) => {
                        const aVal = a[action.column] as string;
                        const bVal = b[action.column] as string;
                        if (aVal > bVal) {
                            return 1;
                        } else if (aVal < bVal) {
                            return -1;
                        }
                        return 0;
                    });
                    break;
                case 'totalArrivals':
                case 'meanDelay':
                case 'earlyPercentage':
                case 'onTimePercentage':
                case 'latePercentage':
                case 'veryLatePercentage':
                    data = action.data.sort((a, b) => {
                        const aVal = a[action.column] as number | null;
                        const bVal = b[action.column] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                default:
                    data = action.data;
            }
            return {
                column: action.column,
                data: data.slice(0, 500),
                direction: 'ascending',
            };
        }
        case 'UPDATE_DATA': {
            return {
                ...state,
                data: action.data.slice(0, 500) || [],
            };
        }
    }
}