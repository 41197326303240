import api from '../api/pushNotificationApi';
import { ConfirmNotification, PushNotificationRecipient } from '../types/pushNotificationTypes';

export async function sendConfirmNotification(data: ConfirmNotification) : Promise<ConfirmNotification> {
    return await api.sendConfirmNotification(data);
}
export async function approveNotificationSending(code: string, email: string): Promise<void> {
    return await api.approveNotificationSending(code, email);
}

export async function getTestRecipients(): Promise<PushNotificationRecipient[]> {
    return await api.getTestRecipients();
}

export async function saveTestRecipients(data: PushNotificationRecipient[]) : Promise<string> {
    return await api.saveTestRecipients(data);
}