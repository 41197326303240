import React from 'react';
import { Button, Form, StrictFormProps } from 'semantic-ui-react';
import { LogoIqOnly } from './logo';

export enum LoginKind {
    INITIAL = 'INITIAL',
    REGULAR = 'REGULAR'
}

let loginKind = LoginKind.INITIAL;

export function getLoginKind(): LoginKind {
    return loginKind;
}

export function setLoginKind(value: LoginKind) {
    loginKind = value;
}

export type ExternalLoginState = {
    kind: LoginKind;
    path: string;
};

export type LoginCallbackState = {
    state: ExternalLoginState;
};

const LoginForm: React.FC<StrictFormProps> = (props) => {
    return (
        <WithLoginSplashScreen>
            {loginKind === LoginKind.INITIAL
                ? (
                    <PaddedLoginContent>
                        <h2>Signing in to Transit IQ ...</h2>
                    </PaddedLoginContent>
                )
                : (
                    <Form {...props}>
                        <PaddedLoginContent>
                            <h2>Sign in to Transit IQ</h2>
                            <Button type="submit" color="blue">Single Sign On</Button>
                        </PaddedLoginContent>
                    </Form>
                )
            }
        </WithLoginSplashScreen>
    );
};

const PaddedLoginContent: React.FC<unknown> = ({ children }) => (
    <>
        <div className="flex-padding-top" />
        <LogoIqOnly />
        <div id="Content">{children}</div>
        <div className="flex-padding-bottom" />
    </>
);

const WithLoginSplashScreen: React.FC<unknown> = ({ children }) => (
    <div id="LoginSplash">
        <div className="art" />
        <div className="flex-padding-left" />
        <div className="flex-padding-center">
            {children}
        </div>
        <div className="flex-padding-right" />
    </div>
);

export default LoginForm;
