import moment from 'moment';
import { AgencyStateType } from '../../actions/actionTypes';
import { Dictionary, IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';
import Utils from '../../utilities/utils';
import { AvgSpeedsPoint, DirectionAvgSpeeds, StopDwellTimeDto } from './_dto';
import { spectrumColors } from './_spectrum-palette';
import { AllTreckDetailsLevels, TreckDetailsLevel, WithOptionalDetailsLeveled, WithRequiredDetailsLeveled } from './_treck-details-level';

export function timeUiToApi(timeUiString: string): string {
    return moment('01/01/2000 ' + timeUiString).format('YYYY-MM-DDTHH:mm');
}

export function timeApiToUi(timeApiString: string): string {
    return moment(timeApiString).format('hh:mm A');
}

function buildRouteVariantUniqueKey(routeName: string, routeId: string, cardinalDirection: string) {
    return `${routeName}::${routeId}::${cardinalDirection.replace(/ /g, '-')}`.toLowerCase();
}

export interface AgencyModel extends AgencyStateType {
    location?: Microsoft.Maps.Location | null;
}

export interface SpeedRangePolyline extends Microsoft.Maps.Polyline {
    /** speed range index */
    metadata: number;
}

export type DirectionDetSpeedsSet = {
    timePeriodKey: TimePeriodKey;
} & WithRequiredDetailsLeveled<DirectionAvgSpeeds[]>;

export class SpeedRangePolylineSet {
    private static emptyPolylines: SpeedRangePolyline[] = [];

    private constructor(
        public readonly id: number,
        private readonly polylines: WithRequiredDetailsLeveled<SpeedRangePolyline[]>) {
    }

    public getPolylines(treckDetailLevel: TreckDetailsLevel): SpeedRangePolyline[] {
        let beforeRequested = true;
        let presented = SpeedRangePolylineSet.emptyPolylines;
        for (const tdl of AllTreckDetailsLevels) {
            const tdlPolyline = this.polylines[tdl];
            if (tdlPolyline?.length > 0) {
                presented = tdlPolyline;
            }
            if (tdl === treckDetailLevel) {
                if (presented.length > 0) {
                    break;
                }
                beforeRequested = false;
            }
            else if (!beforeRequested && presented.length > 0) {
                break;
            }
        }
        return presented;
    }

    public static make(
        id: number,
        polylines: SpeedRangePolyline[],
        treckDetailLevel: TreckDetailsLevel,
        source: SpeedRangePolylineSet | null,
    ): SpeedRangePolylineSet {
        const treckPolylines: WithOptionalDetailsLeveled<SpeedRangePolyline[]> = {};
        polylines = polylines && polylines.length > 0 ? polylines : this.emptyPolylines;
        AllTreckDetailsLevels.forEach(tdl => {
            let value: SpeedRangePolyline[];
            if (tdl == treckDetailLevel)
                value = polylines;
            else if (source)
                value = source.polylines[tdl];
            else
                value = this.emptyPolylines;
            treckPolylines[tdl] = value;
        });
        return new SpeedRangePolylineSet(id, treckPolylines as WithRequiredDetailsLeveled<SpeedRangePolyline[]>);
    }
}

//-- SpeedRangeSet --//

const storageKey_RangeSet = 'TIQ-SpeedMap--Ranges';

type SpeedRangeStorageItem = {
    /** max speed */
    m: number;
    /** color in hex format #RRGGBB */
    c: string;
};

export const defaultRanges: SpeedRangeDesc[] = [
    {
        // 'route',
        stroke: { color: spectrumColors.set2[1], width: 6 },
        maxValue: 0,
    }, {
        // 'red',
        stroke: { color: spectrumColors.set2[5], width: 6 },
        maxValue: 5.0,
    }, {
        // 'yellow',
        stroke: { color: spectrumColors.set2[7], width: 6 },
        maxValue: 9.0,
    }, {
        // 'green',
        stroke: { color: spectrumColors.set2[10], width: 6 },
        maxValue: 15.0,
    }, {
        // 'blue',
        stroke: { color: spectrumColors.set2[13], width: 6 },
        maxValue: 21.0,
    },
];

export type SpeedRangeDesc = {
    stroke: {
        color: string;
        width: number;
    };
    maxValue: number;
};

export class SpeedRangeSet {
    private readonly _items: SpeedRangeDesc[] = [];

    public static readonly Default = new SpeedRangeSet();

    constructor(source?: SpeedRangeDesc[]) {
        this._items = [...(source || defaultRanges)];
        this._items.sort((a, b) => a.maxValue - b.maxValue);
    }

    public get lenght(): number {
        return this._items.length;
    }

    public inv(index: number): SpeedRangeDesc {
        index = this._items.length - 1 - index;
        return this._items[index];
    }

    public dir(index: number): SpeedRangeDesc {
        return this._items[index];
    }

    public get(index: number, reverse?: boolean): SpeedRangeDesc {
        if (reverse === true)
            index = this._items.length - 1 - index;
        return this._items[index];
    }

    public save() {
        const data: SpeedRangeStorageItem[] = this._items.map(e => ({ m: e.maxValue, c: e.stroke.color }));
        localStorage.setItem(storageKey_RangeSet, JSON.stringify(data));
    }

    public static load() {
        const data = JSON.parse(localStorage?.getItem(storageKey_RangeSet) || 'null') as (SpeedRangeStorageItem[] | null);
        const valid = data?.filter(e => typeof e.m === 'number' && typeof e.c === 'string').length === defaultRanges.length;
        if (data && valid) {
            return new SpeedRangeSet(data.map<SpeedRangeDesc>((e, i) => ({
                key: `key-${i}`,
                maxValue: e.m,
                stroke: {
                    color: e.c,
                    width: defaultRanges[i].stroke.width,
                },
            })));
        }
        return new SpeedRangeSet();
    }

    public copyColorsFrom(source: SpeedRangeSet) {
        for (let i = 0; i < defaultRanges.length; ++i) {
            this._items[i].stroke.color = source._items[i].stroke.color;
        }
    }

    public getSpeedRangeDesc(speedSum: number, speedCount: number): number {
        if (!speedCount)
            return 0; // route

        const speed = Utils.kmToMiles(speedSum) / speedCount;

        let rangeIndex = this._items.length - 1;
        while (rangeIndex > 1) {
            const bound = this._items[--rangeIndex];
            if (speed > bound.maxValue)
                return rangeIndex;
        }
        return rangeIndex;
    }
}

function addPolyline(
    polylines: SpeedRangePolyline[],
    bounds: Microsoft.Maps.LocationRect | null,
    index: number,
    ranges: SpeedRangeSet,
    segment: Microsoft.Maps.Location[],
): Microsoft.Maps.LocationRect {
    const srd = ranges.get(index);
    const speedLine: SpeedRangePolyline = new Microsoft.Maps.Polyline(
        segment,
        {
            strokeColor: srd.stroke.color,
            strokeThickness: srd.stroke.width,
        });
    speedLine.metadata = index;

    polylines.push(speedLine);
    const b = Microsoft.Maps.LocationRect.fromLocations(segment);
    if (bounds === null)
        return b;
    else
        return Microsoft.Maps.LocationRect.merge(bounds, b);
}

function addPoint(segment: Microsoft.Maps.Location[], latitude: number, logitude: number) {
    const point = new Microsoft.Maps.Location(latitude, logitude);
    segment.push(point);
}

export class TimePeriodKey {
    private readonly _value: number;

    constructor(setId: number) {
        this._value = setId;
    }

    public get value(): number {
        return this._value;
    }
}

export class RouteVariantEntry {
    private _polylineSets?: SpeedRangePolylineSet[];
    private _speedDataSets?: DirectionDetSpeedsSet[];

    constructor(
        public readonly route: IRouteModel,
        public readonly direction: IDirectionModel,
    ) {
    }

    public clearPolylineSets(): void {
        this._polylineSets = undefined;
    }

    public hasPolylineSets(_timePeriodKey: TimePeriodKey): boolean {
        return !!this._polylineSets;
    }

    public getPolylineSets(): SpeedRangePolylineSet[] | undefined {
        return this._polylineSets;
    }

    private addPolylineSet(timePeriodKey: TimePeriodKey, treckDetailsLevel: TreckDetailsLevel, polylines: SpeedRangePolyline[]) {
        if (!this._polylineSets)
            this._polylineSets = [];

        const existingPolyline = this._polylineSets.find(p => p.id === timePeriodKey.value);
        if (existingPolyline) {
            this._polylineSets = this._polylineSets.map(p => p.id === timePeriodKey.value
                ? SpeedRangePolylineSet.make(existingPolyline.id, polylines, treckDetailsLevel, p)
                : p);
        }
        else {
            this._polylineSets.push(SpeedRangePolylineSet.make(timePeriodKey.value, polylines, treckDetailsLevel, null));
        }
    }

    public getPolylines(timePeriodKey: TimePeriodKey, treckDetailsLevel: TreckDetailsLevel) {
        return this._polylineSets
            ?.find(p => p.id === timePeriodKey.value)
            ?.getPolylines(treckDetailsLevel);
    }

    public hasSpeedDataSets(_timePeriodKey: TimePeriodKey): boolean {
        return !!this._speedDataSets;
    }

    private addSpeedDataSet(_timePeriodKey: TimePeriodKey, _treckDetailsLevel: TreckDetailsLevel, rawSpeedData: DirectionDetSpeedsSet[]) {
        this._speedDataSets = rawSpeedData;
    }

    public getSpeedDataSets(_timePeriodKey: TimePeriodKey): DirectionDetSpeedsSet[] | undefined {
        return this._speedDataSets;
    }

    public stops?: StopInfo[];
    public bounds?: Microsoft.Maps.LocationRect | null;

    public setData(
        timePeriodKey: TimePeriodKey,
        treckDetailsLevel: TreckDetailsLevel,
        bounds: Microsoft.Maps.LocationRect | null,
        polylines: SpeedRangePolyline[],
        rawSpeedData: DirectionDetSpeedsSet[],
    ) {
        this.addPolylineSet(timePeriodKey, treckDetailsLevel, polylines);
        this.addSpeedDataSet(timePeriodKey, treckDetailsLevel, rawSpeedData);
        this.bounds = bounds;
    }
}

export interface StopInfo {
    id: string;
    name: string;
    pushpin: Microsoft.Maps.Pushpin;
    dwellTime?: StopDwellTimeDto;
}

export type DirectionCallback = (
    routeVariantKey: RouteVariantKey,
    polylines: SpeedRangePolyline[],
    bounds: Microsoft.Maps.LocationRect | null,
    timePeriodKey: TimePeriodKey,
    detailsLevel: TreckDetailsLevel
) => void;

export function buildRoute(
    speedSet: DirectionDetSpeedsSet,
    ranges: SpeedRangeSet,
    excludeStops: boolean,
    directionCallback: DirectionCallback,
) {
    AllTreckDetailsLevels.forEach(tdl => {
        buildRouteSingleScale(speedSet, ranges, excludeStops, directionCallback, tdl);
    });
}

function buildRouteSingleScale(
    speedSet: DirectionDetSpeedsSet,
    ranges: SpeedRangeSet,
    excludeStops: boolean,
    directionCallback: DirectionCallback,
    treckDetailsLevel: TreckDetailsLevel,
) {
    const speedMapData = speedSet[treckDetailsLevel];
    for (let i = 0; i < speedMapData.length; i++) {
        const ddata = speedMapData[i];

        const routeVariantKey = new RouteVariantKey(ddata.routeName, ddata.routeId, ddata.cardinalDirection);

        const routePolylines: SpeedRangePolyline[] = [];

        let sdPrev: number | null = null;
        let spPrev: AvgSpeedsPoint | null = null;
        let segment: Microsoft.Maps.Location[] = [];
        let bounds: Microsoft.Maps.LocationRect | null = null;

        for (let j = 0; j < ddata.speedPoints.length; j++) {
            const spCurr = ddata.speedPoints[j];
            const sdCurr = ranges.getSpeedRangeDesc(excludeStops ? spCurr.speedSumNoStops : spCurr.speedSum, spCurr.speedCount);
            if (spPrev === null || sdPrev === null) {
                addPoint(segment, spCurr.lat1, spCurr.lon1);
            }
            else {
                const brokeSegment =
                    spPrev?.lat2 !== spCurr.lat1 ||
                    spPrev?.lon2 !== spCurr.lon1 ||
                    sdPrev !== sdCurr;

                if (brokeSegment) {
                    addPoint(segment, spPrev.lat2, spPrev.lon2);
                    bounds = addPolyline(routePolylines, bounds, sdPrev, ranges, segment);
                    segment = [];
                }
                addPoint(segment, spCurr.lat1, spCurr.lon1);
            }
            spPrev = spCurr;
            sdPrev = sdCurr;
        }
        if (spPrev && sdPrev !== null) {
            addPoint(segment, spPrev.lat2, spPrev.lon2);
            bounds = addPolyline(routePolylines, bounds, sdPrev, ranges, segment);
        }
        directionCallback(routeVariantKey, routePolylines, bounds, speedSet.timePeriodKey, treckDetailsLevel);
    }
}

export class RouteVariantKey {
    private readonly _value: string;

    constructor(routeName: string, routeId: string, cardinalDirection: string) {
        this._value = buildRouteVariantUniqueKey(routeName, routeId, cardinalDirection);
    }

    public get value(): string {
        return this._value;
    }
}

export class RouteVariantsMap {
    private readonly _impl: Dictionary<RouteVariantEntry> = {};

    public forEach(callbackfn: (entry: RouteVariantEntry) => void) {
        for (const key in this._impl) {
            callbackfn(this._impl[key]);
        }
    }

    public get(key: RouteVariantKey): RouteVariantEntry {
        return this._impl[key.value];
    }

    public set(key: RouteVariantKey, entry: RouteVariantEntry) {
        this._impl[key.value] = entry;
    }

    public clear(): void {
        for (const dn in this._impl) {
            delete this._impl[dn];
        }
    }
}

export class RouteDataCacheMap {
    private readonly _impl: Dictionary<RouteVariantEntry> = {};

    public forEach(callbackfn: (entry: RouteVariantEntry) => void) {
        for (const key in this._impl) {
            callbackfn(this._impl[key]);
        }
    }

    public get(key: string): RouteVariantEntry {
        return this._impl[key];
    }

    public set(key: string, entry: RouteVariantEntry) {
        this._impl[key] = entry;
    }

    public clear(): void {
        for (const dn in this._impl) {
            delete this._impl[dn];
        }
    }
}
