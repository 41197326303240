import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { RtDelaysNow } from '../../../../types/delaysNowTypes';
import Utils from '../../../../utilities/utils';
import { delayStatusColors, getDelaySort } from './models';

const styles = {
    table: {
        display: 'block',
        maxHeight: 500,
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    serviceDate: string,
    data: RtDelaysNow[] | null | undefined,
    selectedRow: RtDelaysNow | null | undefined,
    onSelectVehicle: (data: RtDelaysNow) => void;
    columnSortHandler?: (column: string) => void;
}

const VehicleDelaysTable: React.FC<Props> = (props) => {
    const sortedData = props.data
        ?.filter(i => typeof i.delaySec === 'number')
        ?.sort((a, b) => -(a.delaySec! - b.delaySec!));

    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>
                            Vehicle<br />ID
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>
                            Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={1}>
                            Trip<br />Schedule
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" width={1}>
                            Delay<br />(min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" width={1}>
                            Report<br />Time
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedData?.map((row, index) => (
                        <VehicleDelaysTableRow
                            key={index}
                            serviceDate={props.serviceDate}
                            row={row}
                            selectedRow={props.selectedRow}
                            onSelectVehicle={props.onSelectVehicle}
                        />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default VehicleDelaysTable;

interface RowProps {
    serviceDate: string,
    row: RtDelaysNow,
    selectedRow?: RtDelaysNow | null,
    selectedRowHandler?: (selectedRow: RtDelaysNow) => void;
    onSelectVehicle: (selectedRow: RtDelaysNow) => void;
}

const VehicleDelaysTableRow: React.FC<RowProps> = (props) => {
    const handleRowClick = () => {
        if (props.selectedRowHandler)
            props.selectedRowHandler(props.row);
    };
    const isActive = (selectedRow: RtDelaysNow | null | undefined) => {
        return selectedRow === props.row;
    };
    const back = delayStatusColors[getDelaySort(props.row.delaySec!)].fill;
    return (
        <Table.Row
            onClick={handleRowClick}
            active={isActive(props.selectedRow)}
        >
            <Table.Cell textAlign="left">
                <a href="" onClick={(e) => {
                    e.preventDefault();
                    if (props.onSelectVehicle)
                        props.onSelectVehicle(props.row);
                }}>
                    {props.row.vehicleId}
                </a>
            </Table.Cell>
            <Table.Cell textAlign="left">
                {props.row.routeShortName}
            </Table.Cell>
            <Table.Cell textAlign="center">
                {moment(props.row.tripScheduledStart.substr(0, 19)).format('h:mm a')}
                &mdash;
                {moment(props.row.tripScheduledFinish.substr(0, 19)).format('h:mm a')}
            </Table.Cell>
            <Table.Cell textAlign="right"
                style={{ backgroundColor: back }}
            >
                {Utils.convertSecondsToMinutes(props.row.delaySec!, 'm', 's')}
            </Table.Cell>
            <Table.Cell textAlign="right">
                {moment(props.row.vehicleTime.substr(0, 19)).format('h:mm a')}
            </Table.Cell>
        </Table.Row>
    );
};