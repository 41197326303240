import './TripPlannerStyles-Colors.css';
import './TripPlannerStyles.css';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DropdownOnSearchChangeData, DropdownProps, Icon, Message, Segment } from 'semantic-ui-react';
import { AgencyStateType } from '../../actions/actionTypes';
import { autoCompleteAddress, buildTripStartingAt, findAddress } from '../../actions/tripPlannerActions';
import Api from '../../api/api';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../types/BingMapProps';
import { DropDownStateType } from '../../types/types';
import BingMap from '../shared/BingMap';
import Itineraries, { GetNormalTimeString, ItineraryData } from './ItineraryItem';
import { MapPoint } from './MapPoint';
import TripPlannerInputs from './TripPlannelInputs';
import { TripPlannerContext } from './TripPlannerContext';

declare let $tiq_TripPlannerHelper: {
    /**
     * @param cid - TripPlannerContext.uid
     * @param pid - MapPoint.id clicked point
     */
    click: (e: Event, cid: number, pid: string, choice: 'origin' | 'target') => void,
    hide: (elem: HTMLElement, event: Event) => void,
    show: (elem: HTMLElement, event: Event) => void,
    map: { [id: string]: TripPlannerContext }
};

export interface TrackPolylineProps extends PolylineProps {
    options: Microsoft.Maps.IPolylineOptions;
}

enum MapPointKind { unknown, from, to }

function callClickHandler(_event: Event, cid: number, _pid: string, choice: 'origin' | 'target') {
    const context = $tiq_TripPlannerHelper.map[cid];
    if (context) {
        if (!context.onPointChoice)
            console.error(`TripPlanner: onPointChoice is not defined!`);
        else
            context.onPointChoice(choice);
    } else {
        console.error(`TripPlanner: unknown context id (${cid})`);
    }
}

function queryParentElement(el: HTMLElement | null, selector = 'body') {
    const isIDSelector = selector.indexOf('#') === 0;
    if (selector.indexOf('.') === 0 || selector.indexOf('#') === 0) {
        selector = selector.slice(1);
    }
    while (el) {
        if (isIDSelector) {
            if (el.id === selector) {
                return el;
            }
        }
        else if (el.classList.contains(selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

(function init() {
    (window as any).$tiq_TripPlannerHelper = {
        click: callClickHandler,
        hide: (elem: HTMLElement, event: Event) => {
            queryParentElement(elem, '.planner-pushpin')?.classList.add('closed');
            event.stopPropagation();
        },
        show: (elem: HTMLElement, event: Event) => {
            elem.classList.remove('closed');
            event.stopPropagation();
        },
        map: {},
    };
})();

type Props = {
    agency: AgencyStateType | undefined;
};

function getCurrentTime() {
    const r = GetNormalTimeString(new Date());
    return r;
}

export interface AddressSearchCriteria {
    searchQuery: string;
    searchTime: number;
    setAddressesState: React.Dispatch<React.SetStateAction<typeof initialAddressesState>>;
    addressLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialAddressesState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

const TripPlannerForm: React.FC<Props> = ({ agency }) => {
    const [gstate, setGstate] = useState<TripPlannerContext>(TripPlannerContext.EMPTY);
    const [infoboxes, setInfoboxes] = useState<InfoboxProps[]>([]);
    const [pushpins, setPushpins] = useState<PushpinProps[]>([]);
    const [polylines, setPolylines] = useState<PolylineProps[]>([]);

    const [time, setTime] = useState<string>('');
    const [date, setDate] = useState<string>(moment(new Date().setMinutes(0, 0, 0)).format('YYYY-MM-DD'));
    const [itineraries, setItineraries] = useState<ItineraryData[] | null>(null);
    const [selectedItinerary, setSelectedItinerary] = useState<ItineraryData | null>(null);
    const [loadingItineraries, setLoadingItineraries] = useState(false);
    const [fromAddressessState, setFromAddressesState] = useState(initialAddressesState);
    const [toAddressessState, setToAddressesState] = useState(initialAddressesState);
    const [addressSearchCriteriaState, setAddressSearchCriteriaState] = useState<AddressSearchCriteria>();
    const [addressesNoResultMessageState, setAddressesNoResultMessageState] = useState<string>('');
    const [fromAddressLoadingState, setFromAddressLoadingState] = useState(false);
    const [toAddressLoadingState, setToAddressLoadingState] = useState(false);

    const [center, setCenter] = useState<CoordinatePair>();

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (addressSearchCriteriaState && addressSearchCriteriaState.searchTime) {
                const currentTime = new Date().getTime();
                if ((currentTime - addressSearchCriteriaState.searchTime) / 1000 > 1) {
                    await updateInputAddress(addressSearchCriteriaState);
                }
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [addressSearchCriteriaState]);

    const handleExchangeClick = () => {
        if (gstate.exchangePoints()) {
            //setOriginPoint(gstate.origin);
            setFromAddressesState(toAddressessState);
            setToAddressesState(fromAddressessState);

            //setTargetPoint(gstate.target);

            setPushpins(gstate.getPushpins());
            setInfoboxes(gstate.getInfoboxes());
        }
    };
    const handleResetClick = () => {
        setPolylines(gstate.updateItinerary(null));
        setSelectedItinerary(null);
        setInfoboxes(gstate.getInfoboxes(true));
        setItineraries(null);

        if (gstate.resetPoints()) {
            //setOriginPoint(gstate.origin);
            setFromAddressesState(initialAddressesState);

            //setTargetPoint(gstate.target);
            setToAddressesState(initialAddressesState);

            setPushpins(gstate.getPushpins());
            setInfoboxes(gstate.getInfoboxes());
        }
    };
    const handleGoClick = async () => {
        setLoadingItineraries(true);
        setSelectedItinerary(null);
        gstate.storeEndpointPushpins();
        setPushpins(gstate.getPushpins(true));
        setInfoboxes(gstate.getInfoboxes(true));
        setItineraries(null);
        try {
            let result: ItineraryData[] = [];
            if (!!gstate.origin && !!gstate.target) {
                const data = await buildTripStartingAt(
                    gstate.origin?.location.latitude,
                    gstate.origin?.location.longitude,
                    gstate.target?.location.latitude,
                    gstate.target?.location.longitude,
                    time,
                    date);
                result = data?.map(source => new ItineraryData(source, false)) || [];
            }

            setItineraries(result);
            const itinerary = (result && result[0]) || null;
            setSelectedItinerary(itinerary);

            setLoadingItineraries(false);
        }
        catch (e) {
            console.error('TripPlanner', e);
            setLoadingItineraries(false);
        }
    };

    useEffect(() => {
        setPolylines(gstate.updateItinerary(selectedItinerary));
        setInfoboxes(gstate.getInfoboxes());
    }, [selectedItinerary]);

    const handleItineraryClick = (itinerary: ItineraryData) => {
        setSelectedItinerary(itinerary);
    };

    const handleFromAddressChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value: addressId }: DropdownProps) => {
        setFromAddressesState(prevState => ({
            ...prevState,
            selectedValue: addressId as string,
        }));
        const addressData = fromAddressessState.options.find(o => o.value === addressId);
        if (!addressData?.value)
            return;
        const coordsStr = addressData.value as string;
        const [lat, lon] = coordsStr.split(':');
        const location = new Microsoft.Maps.Location(lat, lon);
        drawPointOnTheMap(location, addressData.text as string);
        onPointChoice('origin');
    };

    const handleToAddressChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value: addressId }: DropdownProps) => {
        setToAddressesState(prevState => ({
            ...prevState,
            selectedValue: addressId as string,
        }));
        const addressData = toAddressessState.options.find(o => o.value === addressId);
        if (!addressData?.value)
            return;
        const coordsStr = addressData.value as string;
        const [lat, lon] = coordsStr.split(':');
        const location = new Microsoft.Maps.Location(lat, lon);
        drawPointOnTheMap(location, addressData.text as string);
        onPointChoice('target');
    };

    const handleFromSearchChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { searchQuery }: DropdownOnSearchChangeData) => {
        const searchTime = new Date().getTime();
        setAddressSearchCriteriaState({
            searchQuery,
            searchTime,
            setAddressesState: setFromAddressesState,
            addressLoading: setFromAddressLoadingState,
        });
    };
    const handleToSearchChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { searchQuery }: DropdownOnSearchChangeData) => {
        const searchTime = new Date().getTime();
        setAddressSearchCriteriaState({
            searchQuery,
            searchTime,
            setAddressesState: setToAddressesState,
            addressLoading: setToAddressLoadingState,
        });
    };

    const updateInputAddress = async ({ searchQuery, setAddressesState, addressLoading }: AddressSearchCriteria) => {
        if (agency === undefined) return;
        try {
            addressLoading(true);
            const addresses = await autoCompleteAddress(agency.id, searchQuery);
            if (addresses.length > 0) {
                setAddressesState({
                    options: addresses.map(a => { return { value: `${a.lat}:${a.lon}`, text: a.fullAddress }; }),
                    selectedValue: '',
                });
            } else {
                setAddressesState(initialAddressesState);
                setAddressesNoResultMessageState('No addresses were found');
            }
        }
        catch {
            setAddressesState(initialAddressesState);
        }
        finally {
            addressLoading(false);
        }
    };

    const onPointChoice = (choice: 'origin' | 'target') => {
        let point: MapPoint | null;
        switch (choice) {
            case 'origin':
                point = gstate.setInputChoice(MapPointKind.from);
                if (point) {
                    setFromAddressesState({
                        options: [{ value: `${point.location.latitude}:${point.location.longitude}`, text: point.address }],
                        selectedValue: `${point.location.latitude}:${point.location.longitude}`,
                    });
                } else {
                    setFromAddressesState(initialAddressesState);
                }
                break;
            case 'target':
                point = gstate.setInputChoice(MapPointKind.to);
                if (point) {
                    setToAddressesState({
                        options: [{ value: `${point.location.latitude}:${point.location.longitude}`, text: point.address }],
                        selectedValue: `${point.location.latitude}:${point.location.longitude}`,
                    });
                } else {
                    setToAddressesState(initialAddressesState);
                }
                break;
            default:
                console.error(`TripPlanner: invalid choice value (${choice})!`);
                return;
        }
        if (point) {
            setPushpins(gstate.getPushpins());
            setInfoboxes(gstate.getInfoboxes());
        }
    };
    gstate.onPointChoice = onPointChoice;

    const handleMapClick = async (e: Microsoft.Maps.IMouseEventArgs) => {
        const map = e.target as Microsoft.Maps.Map;
        const eventPoint = new Microsoft.Maps.Point(e.getX(), e.getY());
        const loc = map.tryPixelToLocation(eventPoint);
        if (!loc) return;

        const location = Array.isArray(loc) ? loc[0] : loc;
        const addressStr = await findAddress(location.latitude, location.longitude);
        drawPointOnTheMap(location, addressStr);
    };
    const drawPointOnTheMap = (location: Microsoft.Maps.Location, addressStr: string) => {
        const point = gstate.setInputPoint(location);
        setPushpins(gstate.getPushpins());
        setInfoboxes(gstate.getInfoboxes());
        if (addressStr) {
            point.setAddress(gstate, addressStr);
            setPushpins(gstate.getPushpins(true));
            setInfoboxes(gstate.getInfoboxes(true));
        }
    };

    useEffect(() => {
        if (agency === undefined) return;
        (async (agencyId: string) => {
            handleResetClick();
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
            setTime(getCurrentTime());
        })(agency.id);
    }, [agency]);
    useEffect(() => {
        if (gstate === TripPlannerContext.EMPTY) {
            const actualContext = new TripPlannerContext();
            actualContext.register();
            setGstate(actualContext);
        }
        return function cleanup() {
            if (gstate !== TripPlannerContext.EMPTY) {
                gstate.unregister();
            }
        };
    }, [gstate]);
    return (
        <div className="tp-container">
            <div className="tp-pane map">
                <Segment.Group className="trip-planner">
                    <Segment className="trip-planner-form">
                        <TripPlannerInputs
                            time={time} onTimeChanged={v => setTime(v)}
                            date={date} onDateChanged={v => setDate(v)}
                            onExchange={handleExchangeClick}
                            onReset={handleResetClick}
                            onGo={handleGoClick}
                            fromAddressState={fromAddressessState}
                            toAddressState={toAddressessState}
                            noResultAddressMessage={addressesNoResultMessageState}
                            handleFromSearchChange={handleFromSearchChange}
                            handleToSearchChange={handleToSearchChange}
                            fromAddressLoadingState={fromAddressLoadingState}
                            toAddressLoadingState={toAddressLoadingState}
                            handleFromAddressChange={handleFromAddressChange}
                            handleToAddressChange={handleToAddressChange}
                        />
                    </Segment>
                    <Segment className="trip-planner-map">
                        <BingMap
                            //div={{ style: styles.map }}
                            map={{
                                center,
                                options: {
                                    zoom: 14,
                                },
                                eventHandlers: [
                                    {
                                        event: 'click',
                                        callback: handleMapClick,
                                    },
                                ],
                            }}
                            infoboxes={infoboxes}
                            pushpins={pushpins}
                            polylines={polylines}
                        />
                    </Segment>
                </Segment.Group>
            </div>
            <div className="tp-pane inf">
                {loadingItineraries
                    ? (<Message info icon>
                        <Icon name="circle notched" loading />
                        <Message.Content>
                            <Message.Header>Loading itineraries</Message.Header>
                        </Message.Content>
                    </Message>)
                    : !itineraries
                        ? (<Message info>
                            <Message.Content>
                                <Message.Header>How to Use</Message.Header>
                                <p>Click or double-click on the map to select your destination and starting points.</p>
                                <p>Or type them in the boxes above and press “GO”</p>
                            </Message.Content>
                        </Message>)
                        : itineraries.length === 0
                            ? (<Message info>
                                <Message.Content>
                                    <Message.Header>No Itineraries Found</Message.Header>
                                </Message.Content>
                            </Message>)
                            : (<Segment>
                                <Itineraries
                                    itinerares={itineraries}
                                    selected={selectedItinerary}
                                    onClick={handleItineraryClick} />
                            </Segment>)
                }
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(TripPlannerForm);
