import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { BingMapsState } from '../../reducers/bingMapsReducer';
import BingMapProps from '../../types/BingMapProps';
import BingMapQuerier from '../../utilities/BingMapQuerier';

type Props =
    & BingMapProps
    & {
        Microsoft: { Maps: typeof Microsoft.Maps; };
    };

const BingMap = React.forwardRef<BingMapQuerier, Props>(function BingMap(
    {
        Microsoft,
        div: divProps,
        map: mapProps,
        infoboxes: infoboxProps,
        pushpins: pushpinProps,
        polylines: polylineProps,
        polygons: polygonProps,
    },
    ref,
) {
    const [map, setMap] = useState<Microsoft.Maps.Map>();
    const mapDiv = useRef<HTMLDivElement | null>(null);
    useEffect(
        () => {
            if (mapDiv.current === null) return;
            setMap(
                new Microsoft.Maps.Map(mapDiv.current, mapProps?.options || {}),
            );
        },
        [mapDiv],
    );

    useEffect(
        () => {
            if (!ref) return;
            const querier = map ? new BingMapQuerier(map) : null;
            if (typeof ref === 'function') {
                ref(querier);
            }
            else {
                ref.current = querier;
            }
        },
        [map, ref],
    );

    useEffect(
        () => {
            if (!map) return;

            const viewOptions: Microsoft.Maps.IViewOptions = {};
            if (mapProps?.options?.zoom) {
                viewOptions.zoom = mapProps.options.zoom;
            }
            if (mapProps?.options?.bounds) {
                viewOptions.bounds = mapProps?.options?.bounds;
            }
            else if (mapProps?.center) {
                const [lat, lon] = mapProps.center;
                viewOptions.center = new Microsoft.Maps.Location(lat, lon);
            }
            else {
                return;
            }
            map.setView(viewOptions);
        },
        [map, mapProps?.options?.bounds, mapProps?.center, mapProps?.options?.zoom],
    );

    useEffect(
        () => {
            if (!map || !mapProps?.eventHandlers) return;
            const handlerIds = mapProps.eventHandlers.map(({ event, callback }) =>
                Microsoft.Maps.Events.addHandler(map, event, callback),
            );

            return () => handlerIds.forEach(handlerId => {
                Microsoft.Maps.Events.removeHandler(handlerId);
            });
        },
        [map, mapProps?.eventHandlers],
    );

    useEffect(
        () => {
            if (!map || !infoboxProps) return;
            const infoboxes = infoboxProps
                .map(({ location: [lat, lon], options, eventHandlers }) => {
                    const infobox = new Microsoft.Maps.Infobox(
                        new Microsoft.Maps.Location(lat, lon),
                        options,
                    );

                    const handlerIds = eventHandlers?.map(({ event, callback }) =>
                        Microsoft.Maps.Events.addHandler(infobox, event, callback),
                    ) || [];

                    infobox.setMap(map);

                    return { infobox, handlerIds };
                });

            return () => infoboxes.forEach(({ infobox, handlerIds }) => {
                handlerIds.forEach(handlerId =>
                    Microsoft.Maps.Events.removeHandler(handlerId),
                );
                // HACK: there is a bug in Microsoft.Maps.d.ts!
                // The signature for `setMap` does not include `null`,
                // but `null` is how you delete an infobox.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                infobox.setMap(null!);
            });
        },
        [map, infoboxProps],
    );

    useEffect(
        () => {
            if (!map || !pushpinProps) return;
            const pushpins = pushpinProps
                .map(({ location: [lat, lon], options, eventHandlers }) => {
                    const pushpin = new Microsoft.Maps.Pushpin(
                        new Microsoft.Maps.Location(lat, lon),
                        options,
                    );

                    const handlerIds = eventHandlers?.map(({ event, callback }) =>
                        Microsoft.Maps.Events.addHandler(pushpin, event, callback),
                    ) || [];

                    return { pushpin, handlerIds };
                });
            map.entities.push(pushpins.map(({ pushpin }) => pushpin));

            return () => pushpins.forEach(({ pushpin, handlerIds }) => {
                handlerIds.forEach(handlerId =>
                    Microsoft.Maps.Events.removeHandler(handlerId),
                );
                map.entities.remove(pushpin);
            });
        },
        [map, pushpinProps],
    );

    useEffect(
        () => {
            if (!map || !polylineProps) return;
            const polylines = polylineProps
                .map(({ locations, options, eventHandlers }) => {
                    const polyline = new Microsoft.Maps.Polyline(
                        locations.map(([lat, lon]) => new Microsoft.Maps.Location(lat, lon)),
                        options,
                    );

                    const handlerIds = eventHandlers?.map(({ event, callback }) =>
                        Microsoft.Maps.Events.addHandler(polyline, event, callback),
                    ) || [];

                    return { polyline, handlerIds };
                });
            map.entities.push(polylines.map(({ polyline }) => polyline));

            return () => polylines.forEach(({ polyline, handlerIds }) => {
                handlerIds.forEach(handlerId =>
                    Microsoft.Maps.Events.removeHandler(handlerId),
                );
                map.entities.remove(polyline);
            });
        },
        [map, polylineProps],
    );

    useEffect(
        () => {
            if (!map || !polygonProps) return;
            const polygons = polygonProps
                .map(({ rings, options, eventHandlers }) => {
                    const polygon = new Microsoft.Maps.Polygon(
                        rings.map(ring => ring.map(([lat, lon]) => new Microsoft.Maps.Location(lat, lon))),
                        options,
                    );

                    const handlerIds = eventHandlers?.map(({ event, callback }) =>
                        Microsoft.Maps.Events.addHandler(polygon, event, callback),
                    ) || [];

                    return { polygon, handlerIds };
                });
            map.entities.push(polygons.map(({ polygon }) => polygon));

            return () => polygons.forEach(({ polygon, handlerIds }) => {
                handlerIds.forEach(handlerId =>
                    Microsoft.Maps.Events.removeHandler(handlerId),
                );
                map.entities.remove(polygon);
            });
        },
        [map, polygonProps],
    );

    return (
        <div ref={mapDiv} {...divProps} />
    );
});

type PropsWithUncertainBingMaps =
    & BingMapProps
    & {
        Microsoft: { Maps: BingMapsState; };
    };
function isBingMapsLoaded(props: PropsWithUncertainBingMaps): props is Props {
    return props.Microsoft.Maps !== null;
}
const EnsureLoadedBingMap = React.forwardRef<BingMapQuerier, PropsWithUncertainBingMaps>(
    function EnsureLoadedBingMap(props, ref) {
        return isBingMapsLoaded(props) ? <BingMap ref={ref} {...props} /> : <div {...props.div} />;
    },
);

export default connect(
    ({ bingMaps }: AppState) => ({ Microsoft: { Maps: bingMaps } }),
    null,
    null,
    { forwardRef: true },
)(EnsureLoadedBingMap);
