import { DropDownStateType } from '../../../../types/types';
import { ColorsScaleRowData } from './StopDelaysForm';

export const delaysMetricDropDownState: DropDownStateType = {
    options: [
        {
            value: 'MeanDelay',
            text: 'Average Delay',
        },
        {
            value: 'Early',
            text: 'Early',
        },
        {
            value: 'OnTime',
            text: 'On Time',
        },
        {
            value: 'Late',
            text: 'Late',
        },
        {
            value: 'VeryLate',
            text: 'Very Late',
        },
    ],
    selectedValue: 'MeanDelay',
};

export const initialStopsState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

export const colorsScaleValuesMeanDelay: ColorsScaleRowData[] = [
    {
        color: 'rgba(77, 0, 75, 0.5)',
        description: '20',
    },
    {
        color: 'rgba(129, 15, 124, 0.5)',
        description: '15',
    },
    {
        color: 'rgba(136, 65, 157, 0.5)',
        description: '10',
    },
    {
        color: 'rgba(140, 107, 177, 0.5)',
        description: '6',
    },
    {
        color: 'rgba(140, 150, 198, 0.5)',
        description: '4',
    },
    {
        color: 'rgba(158, 188, 218, 0.5)',
        description: '2',
    },
    {
        color: 'rgba(191, 211, 230, 0.5)',
        description: '1',
    },
    {
        color: 'rgba(224, 236, 244, 0.5)',
        description: '0',
    },
    {
        color: 'rgba(247, 252, 253, 0.5)',
        description: '-1',
    },
];

export const colorsScaleValuesPercentage: ColorsScaleRowData[] = [
    {
        color: 'rgba(77, 0, 75, 0.5)',
        description: '90',
    },
    {
        color: 'rgba(129, 15, 124, 0.5)',
        description: '80',
    },
    {
        color: 'rgba(136, 65, 157, 0.5)',
        description: '70',
    },
    {
        color: 'rgba(140, 107, 177, 0.5)',
        description: '60',
    },
    {
        color: 'rgba(140, 150, 198, 0.5)',
        description: '50',
    },
    {
        color: 'rgba(158, 188, 218, 0.5)',
        description: '40',
    },
    {
        color: 'rgba(191, 211, 230, 0.5)',
        description: '30',
    },
    {
        color: 'rgba(224, 236, 244, 0.5)',
        description: '20',
    },
    {
        color: 'rgba(247, 252, 253, 0.5)',
        description: '10',
    },
];