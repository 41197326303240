import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { ArrivalForStop } from '../../../../types/delaysTypes';

interface Props {
    row: ArrivalForStop,
    selectedRow: ArrivalForStop | undefined,
    selectedRowHandler: (selectedTrip: ArrivalForStop) => void;
}

const ArrivalsForStopTableRow: React.FC<Props> = ({ row, selectedRow, selectedRowHandler }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedRow !== undefined && selectedRow.tripInternalId === row.tripInternalId && selectedRow.actualArrivalTime == row.actualArrivalTime}>
            <Table.Cell textAlign="left">
                <Link to={`/vehicle-history/bus-number-position/${moment.parseZone(row.tripScheduledStart).format('YYYY-MM-DD')}/${row.vehicleId}/${row.tripId}`}>{row.vehicleId}</Link>
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.tripId} ({moment.parseZone(row.tripScheduledStart).format('h:mm A')} - {moment.parseZone(row.tripScheduledFinish).format('h:mm A')})
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.routeName} - {row.directionName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {Math.round(row.delaySec / 60 * 10) / 10}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {moment.parseZone(row.actualArrivalTime).format('h:mm A')}
            </Table.Cell>
        </Table.Row>
    );
};

export default ArrivalsForStopTableRow;