import { useEffect, useReducer } from 'react';
import { TableDispatchAction, TableSortProperties } from '../../types/types';

export default function useTableSorting<T>
    (
        tableSortReducer: (state: TableSortProperties<T>, action: TableDispatchAction<T>) => TableSortProperties<T>,
        tableData: T[],
        initialState?: TableSortProperties<T> | undefined,
    ) {
    const [tableDataState, tableSortDispatch] = useReducer(tableSortReducer, initialState ?? {
        column: '',
        data: tableData,
        direction: undefined,
    });

    const handleTableColumnSort = (column: string) => {
        tableSortDispatch({ type: 'CHANGE_SORT', column });
    };

    useEffect(() => {
        if (tableDataState.data.length === 0 && tableData.length === 0)
            return;
        tableSortDispatch(({ type: 'UPDATE_DATA', data: tableData, column: '' }));
    }, [tableData]);

    return { tableDataState, handleTableColumnSort };
}
