import { getSpeedMapData } from './_apiActions';
import { DirectionAvgSpeeds, DirectionAvgSpeedsSet } from './_dto';
import { DirectionDetSpeedsSet, TimePeriodKey } from './_models';
import { AllTreckDetailsLevels, TreckDetailsLevel, TreckDetailsLevels } from './_treck-details-level';

export function loadSpeedMapData(
    agencyId: string,
    setId: number,
    directionVariantId: string,
    lowerBoundDate: string,
    lowerBoundTime: string,
    upperBoundDate: string,
    upperBoundTime: string,
    excludeWeekends: boolean,
) {
    const cacheDataKey = makeCacheDataKey(directionVariantId, lowerBoundDate, lowerBoundTime, upperBoundDate, upperBoundTime, excludeWeekends);
    return getOrLoadRawData(cacheDataKey, async () => {
        const timePeriodKey = new TimePeriodKey(setId);
        return _loadSpeedMapData(agencyId || '', directionVariantId,
            lowerBoundTime, upperBoundTime,
            lowerBoundDate, upperBoundDate,
            excludeWeekends, timePeriodKey);
    });
}

function makeCacheDataKey(directionVariantId: string, date1: string, time1: string, date2: string, time2: string, exclWeekends: boolean) {
    const lower = date1 + time1.substr(10);
    const upper = date2 + time2.substr(10);
    const wends = exclWeekends ? '-' : '+';
    return `${ directionVariantId };${ lower };${ upper };${ wends }`;
}

async function _loadSpeedMapData(agencyId: string, directionVariantId: string, timeFrom: string, timeTo: string, serviceDateFrom: string, serviceDateTo: string, excludeWeekend: boolean, timePeriodKey: TimePeriodKey): Promise<DirectionDetSpeedsSet> {
    const loadedData: DirectionAvgSpeedsSet[] = await Promise.all(
        AllTreckDetailsLevels.map(tdl => {
            const desc = TreckDetailsLevels[tdl];
            return getSpeedMapData(agencyId, directionVariantId, timeFrom, timeTo, serviceDateFrom, serviceDateTo, excludeWeekend, timePeriodKey.value, desc.apiValue);
        }),
    );
    const result: { timePeriodKey: TimePeriodKey } &
        { [key in TreckDetailsLevel]?: DirectionAvgSpeeds[] } = { timePeriodKey };

    AllTreckDetailsLevels.forEach((tdl, index) => {
        result[tdl] = loadedData[index].speedMapData;
    });
    return result as DirectionDetSpeedsSet;
}

type RawDataCasheEntry = {
    key: string;
    data: DirectionDetSpeedsSet;
    expired: Date;
};

const minute = 60 * 1000;
const cachingDuration = 10 * minute;
const checkupInterval = 5 *  minute;
const rawDataCache: { [cacheKey: string]: RawDataCasheEntry | undefined } = {
};

async function getOrLoadRawData(key: string, loader: () => Promise<DirectionDetSpeedsSet>) {
    let entry = rawDataCache[key];
    const now = new Date();
    if (!entry) {
        entry = {
            key: key,
            expired: now,
            data: await loader(),
        };
        rawDataCache[key] = entry;
    }
    entry.expired = new Date(now.valueOf() + cachingDuration); // slide expiration for 10 min
    return entry?.data;
}

function clearCache() {
    const nowValue = new Date().valueOf();
    for (const key in rawDataCache) {
        const entity = rawDataCache[key]!;
        if (entity.expired.valueOf() < nowValue) {
            delete rawDataCache[key];
        }
    }
}

setInterval(clearCache, checkupInterval);