import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TableSortProperties } from '../../../types/types';
import { VehicleStatisticsData } from '../../../types/vehicleStatisticsTypes';
import Utils from '../../../utilities/utils';

const styles = {
    table: {
        display: 'block',
        maxHeight: '423px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

type TableProps = {
    selectedVehicleId?: string;
    selectedVehicleHandler: (vehicleId: string) => void;
    tableDataState: TableSortProperties<VehicleStatisticsData>;
    columnSortHandler: (column: string) => void;
};

const VehiclesStatisticsTable: React.FC<TableProps> = ({ selectedVehicleId, selectedVehicleHandler, tableDataState, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable reportingGapsTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan="2" textAlign="left" width={1}
                            sorted={column === 'vehicleId' ? direction : undefined}
                            onClick={() => columnSortHandler('vehicleId')}>
                            Vehicle ID
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan="2" textAlign="left" width={1}
                            sorted={column === 'tripsCount' ? direction : undefined}
                            onClick={() => columnSortHandler('tripsCount')}>
                            Number<br />of Trips
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="4" textAlign="left" width={1}>Non-Revenue Service</Table.HeaderCell>
                        <Table.HeaderCell colSpan="4" textAlign="left" width={1}>Revenue Service</Table.HeaderCell>
                        <Table.HeaderCell rowSpan="2" textAlign="right" width={1}
                            sorted={column === 'serviceTimeIndicators.travelKm' ? direction : undefined}
                            onClick={() => columnSortHandler('serviceTimeIndicators.travelKm')}>
                            Revenue<br />Service, miles
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan="2" textAlign="right" width={1}
                            sorted={column === 'nonServiceTimeIndicators.travelKm' ? direction : undefined}
                            onClick={() => columnSortHandler('nonServiceTimeIndicators.travelKm')}>
                            Non-Revenue<br />Service, miles
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'nonServiceTimeIndicators.notReportingSec' ? direction : undefined}
                            onClick={() => columnSortHandler('nonServiceTimeIndicators.notReportingSec')}>
                            Not Reporting
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'nonServiceTimeIndicators.inGarageSec' ? direction : undefined}
                            onClick={() => columnSortHandler('nonServiceTimeIndicators.inGarageSec')}>
                            In Garage
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'nonServiceTimeIndicators.moveSec' ? direction : undefined}
                            onClick={() => columnSortHandler('nonServiceTimeIndicators.moveSec')}>
                            On the Move
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'nonServiceTimeIndicators.staySec' ? direction : undefined}
                            onClick={() => columnSortHandler('nonServiceTimeIndicators.staySec')}>
                            Stationary
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'serviceTimeIndicators.notReportingSec' ? direction : undefined}
                            onClick={() => columnSortHandler('serviceTimeIndicators.notReportingSec')}>
                            Not Reporting
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'serviceTimeIndicators.moveSec' ? direction : undefined}
                            onClick={() => columnSortHandler('serviceTimeIndicators.moveSec')}>
                            On the Move
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'serviceTimeIndicators.stayOnStopSec' ? direction : undefined}
                            onClick={() => columnSortHandler('serviceTimeIndicators.stayOnStopSec')}>
                            Stationary<br /> at Stop
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'serviceTimeIndicators.stayOnRoadSec' ? direction : undefined}
                            onClick={() => columnSortHandler('serviceTimeIndicators.stayOnRoadSec')}>
                            Stationary<br /> on Road
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((item, index) => (
                        <VehiclesStatisticsTableRow key={index} item={item} selectedVehicleId={selectedVehicleId} selectedVehicleHandler={selectedVehicleHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default VehiclesStatisticsTable;

type RowProps = {
    item: VehicleStatisticsData;
    selectedVehicleId?: string;
    selectedVehicleHandler: (vehicleId: string) => void;
};

const VehiclesStatisticsTableRow: React.FC<RowProps> = ({ item, selectedVehicleId, selectedVehicleHandler }) => {
    const handleRowClick = () => {
        if (selectedVehicleHandler)
            selectedVehicleHandler(item.vehicleId);
    };

    return (
        <Table.Row active={selectedVehicleId !== undefined && selectedVehicleId === item.vehicleId} onClick={handleRowClick}>
            <Table.Cell>{item.vehicleId}</Table.Cell>
            <Table.Cell>{item.tripsCount}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.nonServiceTimeIndicators.notReportingSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.nonServiceTimeIndicators.inGarageSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.nonServiceTimeIndicators.moveSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.nonServiceTimeIndicators.staySec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.serviceTimeIndicators.notReportingSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.serviceTimeIndicators.moveSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.serviceTimeIndicators.stayOnStopSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToHours({ seconds: item.serviceTimeIndicators.stayOnRoadSec, hoursLabel: 'h', minutesLabel: 'm', displaySeconds: false })}</Table.Cell>
            <Table.Cell textAlign="right">{Math.round(Utils.kmToMiles(item.serviceTimeIndicators.travelKm)).toLocaleString('en-US')}</Table.Cell>
            <Table.Cell textAlign="right">{Math.round(Utils.kmToMiles(item.nonServiceTimeIndicators.travelKm)).toLocaleString('en-US')}</Table.Cell>
        </Table.Row>
    );
};
