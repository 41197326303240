import api from '../api/servicePageApi';
import { ServiceStatusModel } from '../types/servicePageTypes';

export async function getServicePageData(agencyId: string): Promise<ServiceStatusModel> {
    const result = await api.getServicePageData(agencyId);
    return result.data;
}

export async function getAgencyVehiclesFeed(agencyId: string): Promise<Blob> {
    return await api.getAgencyVehiclesFeed(agencyId);
}
export async function getAgencyTripsFeed(agencyId: string): Promise<Blob> {
    return await api.getAgencyTripsFeed(agencyId);
}
export async function getAgencyAlertsFeed(agencyId: string): Promise<Blob> {
    return await api.getAgencyAlertsFeed(agencyId);
}