import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { DropDownInfo } from '../../types/types';


interface Props {
    modalOpen: boolean;
    onSubmit: () => void;
    onCancel: () => void;
    onDelete: () => void;
    onClose: () => void;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    handleStartDateChange: (e: SyntheticEvent<HTMLElement, Event>, data: any) => void;
    handleEndDateChange: (e: SyntheticEvent<HTMLElement, Event>, data: any) => void;
    handleStartTimeChange: (e: SyntheticEvent<HTMLElement, Event>, data: any) => void;
    handleEndTimeChange: (e: SyntheticEvent<HTMLElement, Event>, data: any) => void;
    routesData: DropDownInfo;
    tripsData: DropDownInfo;
    stopsData: DropDownInfo;
    severityLevelData: DropDownInfo;
    causesData: DropDownInfo;
    effectsData: DropDownInfo;
    alertHeader: string;
    setAlertHeader: (newAlertHeader: string) => void;
    alertDescription: string;
    setAlertDescription: (newAlertDescription: string) => void;
    alertUrl: string;
    setAlertUrl: (newAlertUrl: string) => void;
    isCreate: boolean;
    isModalFormValid: boolean;
    validationText: string;
}

const styles = {
    modalForm: {
        height: 'auto',
        top: 'auto',
    } as React.CSSProperties,
    calendarDateContainer: {
        paddingRight: '0px',
    } as React.CSSProperties,
};

const AlertsModalForm: React.FC<Props> = ({
    modalOpen,
    onSubmit,
    onCancel,
    onDelete,
    onClose,
    startDate,
    startTime,
    endDate,
    endTime,
    handleStartDateChange,
    handleEndDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    routesData,
    tripsData,
    stopsData,
    severityLevelData,
    causesData,
    effectsData,
    alertHeader,
    setAlertHeader,
    alertDescription,
    setAlertDescription,
    alertUrl,
    setAlertUrl,
    isCreate,
    isModalFormValid,
    validationText,
}) => {

    return (
        <Modal dimmer="inverted" open={modalOpen} onClose={onClose} style={styles.modalForm}>
            {!isModalFormValid && <Message negative attached>
                <Message.Header>{validationText}</Message.Header>
            </Message>}
            <Modal.Header>Add Service Alert</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group inline>
                        <Form.Field style={styles.calendarDateContainer}>
                            <label>Start Date</label>
                            <DateInput
                                name="startDate"
                                dateFormat="YYYY-MM-DD"
                                placeholder="Select Start Date"
                                value={startDate}
                                iconPosition="left"
                                popupPosition="bottom center"
                                closable={true}
                                startMode="day"
                                preserveViewMode={false}
                                animation="fade"
                                disableMinute={false}
                                onChange={handleStartDateChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Start Time</label>
                            <TimeInput
                                name="startTime"
                                timeFormat="AMPM"
                                placeholder="Select Start Time"
                                value={startTime}
                                onChange={handleStartTimeChange}
                                iconPosition="left"
                                popupPosition="bottom center"
                                closable={true}
                                animation="fade"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group inline>
                        <Form.Field style={styles.calendarDateContainer}>
                            <label>End Date</label>
                            <DateInput
                                name="endDate"
                                dateFormat="YYYY-MM-DD"
                                placeholder="Select End Date"
                                value={endDate}
                                iconPosition="left"
                                popupPosition="bottom center"
                                closable={true}
                                startMode="day"
                                preserveViewMode={false}
                                animation="fade"
                                disableMinute={false}
                                onChange={handleEndDateChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>End Time</label>
                            <TimeInput
                                name="endTime"
                                timeFormat="AMPM"
                                placeholder="Select End Time"
                                value={endTime}
                                onChange={handleEndTimeChange}
                                iconPosition="left"
                                popupPosition="bottom center"
                                closable={true}
                                animation="fade"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field required>
                        <label>Header</label>
                        <input placeholder="Alert Header" value={alertHeader} onChange={e => setAlertHeader(e.target.value)} />
                    </Form.Field>
                    <Form.Field>
                        <label>Description</label>
                        <input placeholder="Alert Description" value={alertDescription} onChange={e => setAlertDescription(e.target.value)} />
                    </Form.Field>
                    <Form.Field>
                        <label>Url</label>
                        <input placeholder="Alert Url" value={alertUrl} onChange={e => setAlertUrl(e.target.value)} />
                    </Form.Field>
                    <Form.Select
                        placeholder="Select Route"
                        label="Route"
                        fluid
                        search
                        selection
                        clearable
                        icon={routesData.state.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesData.state.options}
                        value={routesData.state.selectedValue}
                        onChange={routesData.changeHandler}
                    />
                    <Form.Select
                        placeholder="Select Trip"
                        label="Trip"
                        fluid
                        search
                        selection
                        clearable
                        icon={tripsData.state.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={tripsData.state.options}
                        value={tripsData.state.selectedValue}
                        onChange={tripsData.changeHandler}
                    />
                    <Form.Select
                        placeholder="Select Stop"
                        label="Stop"
                        fluid
                        search
                        selection
                        clearable
                        icon={stopsData.state.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={stopsData.state.options}
                        value={stopsData.state.selectedValue}
                        onChange={stopsData.changeHandler}
                    />
                    <Form.Select
                        required
                        placeholder="Select Severity Level"
                        label="Severity Level"
                        fluid
                        search
                        selection
                        options={severityLevelData.state.options}
                        value={severityLevelData.state.selectedValue}
                        onChange={severityLevelData.changeHandler}
                    />
                    <Form.Select
                        required
                        placeholder="Select Cause"
                        label="Cause"
                        fluid
                        search
                        selection
                        options={causesData.state.options}
                        value={causesData.state.selectedValue}
                        onChange={causesData.changeHandler}
                    />
                    <Form.Select
                        required
                        placeholder="Select Effect"
                        label="Effect"
                        fluid
                        search
                        selection
                        options={effectsData.state.options}
                        value={effectsData.state.selectedValue}
                        onChange={effectsData.changeHandler}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {!isCreate && <Button negative floated="left" onClick={onDelete}>Delete Alert</Button>}
                <Button onClick={onCancel}>Cancel</Button>
                <Button positive onClick={onSubmit}>{isCreate ? 'Add Alert' : 'Update Alert'}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AlertsModalForm;