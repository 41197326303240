import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { CurrentTrip } from '../../../types/otpTypes';
import Utils from '../../../utilities/utils';

const DelayedTripsTableRow: React.FC<{ row: CurrentTrip, selectedTrip: CurrentTrip | undefined, delayedTripRowHandler: (selectedTrip: CurrentTrip) => Promise<void> }> = ({ row, selectedTrip, delayedTripRowHandler }) => {
    const handleRowClick = () => {
        if (delayedTripRowHandler)
            delayedTripRowHandler(row);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedTrip && selectedTrip.tripInternalId === row.tripInternalId && selectedTrip.vehicleId === row.vehicleId}>
            <Table.Cell>
                {row.delaySeconds !== null ? Utils.convertSecondsToMinutes(row.delaySeconds, 'm', 's') : 'No Info'}
            </Table.Cell>
            <Table.Cell>
                {row.routeName}
            </Table.Cell>
            <Table.Cell>
                {row.tripId} ({moment.parseZone(row.tripStartTime).format('h:mm A')} - {moment.parseZone(row.tripEndTime).format('h:mm A')})
            </Table.Cell>
            <Table.Cell>
                {row.vehicleId}
            </Table.Cell>
            <Table.Cell>
                {row.directionVariantName}
            </Table.Cell>
        </Table.Row>
    );
};

export default DelayedTripsTableRow;