import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TopWrongPredictingRoute } from '../../../types/anomaliesTypes';
import TopPredictionRoutesTableRow from './TopPredictionRoutesTableRow';

const styles = {
    table: {
        display: 'block',
        height: '100%',
        overflowY: 'scroll',
        margin: '0px 0px 100px 0px',
    } as React.CSSProperties,
};

interface Props {
    rows: TopWrongPredictingRoute[]
    rowHandler: (rowData: TopWrongPredictingRoute) => void
}

const TopPredictionRoutesTable: React.FC<Props> = ({ rows, rowHandler }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Route</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Direction</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Accuracy, %</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <TopPredictionRoutesTableRow key={index} row={row} rowHandler={rowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TopPredictionRoutesTable;