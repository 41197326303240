import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { AgencyStorage } from '../utilities/agencyStorage';
import { isValidUser } from '../utilities/user';

export const getAgencies = (state: AppState) => state.agencies;
export const getUser = (state: AppState) => state.user;
const getSelectedAgencyId = (state: AppState) => state && state.agencyId;

export const isLogged = createSelector([getUser], isValidUser);

export const getSelectedOrDefaultAgency = createSelector(
    [getSelectedAgencyId, getAgencies],
    (agencyId, agencies) =>
        agencies.find(({ id }) => id === (agencyId || AgencyStorage.agencyId)) || agencies[0],
);

