import './TransitAccessMapStyles.css';
import React from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { AgencyStateType } from '../../actions/actionTypes';
import { buildUrlString } from '../../api/helpers';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { getOrgLocation } from '../live-map/_apiActions';

interface Props {
    agency: AgencyStateType | undefined;
}

function TransitAccessMapPage(props: Props) {
    const [frameUrl, setFrameUrl] = React.useState('');
    React.useEffect(() => {
        setFrameUrl('');
        if (!props.agency?.id) return;
        getOrgLocation(props.agency.id)
            .then(c => {
                if (!props.agency?.id) return;
                const sp = {
                    agency: props.agency.id,
                    lat: c[0].toFixed(9),
                    lng: c[1].toFixed(9),
                };
                const url = buildUrlString(sp, 'area-coverage', 'index.html');
                setFrameUrl(url);
            });
    }, [props.agency?.id]);

    React.useLayoutEffect(() => {
        const frm = document.getElementById('transit-access-map') as HTMLIFrameElement;
        if (frm && frameUrl !== '') {
            frm.src = frameUrl;
        }
    }, [frameUrl]);

    return (
        <BlockUi blocking={!frameUrl} className="tam-content">
            <Segment>
                {!frameUrl ? null : <iframe id="transit-access-map" scrolling="no"></iframe> }
            </Segment>
        </BlockUi>
    );
}

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(TransitAccessMapPage);
