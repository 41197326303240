import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { PositionReportingGap } from '../../../types/anomaliesTypes';

const styles = {
    table: {
        display: 'block',
        maxHeight: '405px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

type TableProps = {
    items: PositionReportingGap[];
};

const PositionsReportingGapsTable: React.FC<TableProps> = ({ items }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>NN</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Delta<br />(minutes)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Report Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Delay<br/>(minutes)</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item, index) => (
                        <PositionsReportingGapsTableRow key={index} data={item} />
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colspan="9" textAlign="left">total items: {items?.length || 0}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

export default PositionsReportingGapsTable;

type RowProps = {
    data: PositionReportingGap;
};

const PositionsReportingGapsTableRow: React.FC<RowProps> = ({ data }) => {
    return (
        <Table.Row>
            <Table.Cell>{data.recordOrdinal}</Table.Cell>
            <Table.Cell negative={data.deltaSec >= 300}>{Math.floor(data.deltaSec / 60)}</Table.Cell>
            <Table.Cell>{data.reportTime}</Table.Cell>
            <Table.Cell textAlign="left">{data.delaySec ? Math.floor(data.delaySec / 60): ''}</Table.Cell>
        </Table.Row>
    );
};
