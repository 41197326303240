import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DelaysAnalysisTableData } from '../../../../types/otpTypes';
import Utils from '../../../../utilities/utils';
import { SelectedTripData } from './DelaysAnalysisForm';

interface Props {
    row: DelaysAnalysisTableData;
    selectedTripId: string | undefined;
    tripRowHandler: (tripData: SelectedTripData) => Promise<void>;
}

const DelaysAnalysisTableRow: React.FC<Props> = ({ row, selectedTripId, tripRowHandler }) => {

    const tripName = `${row.tripId} (${moment.parseZone(row.tripScheduleStart).format('h:mm A')} - ${moment.parseZone(row.tripScheduleFinish).format('h:mm A')})`;
    const routeAndDirection = `${row.routeName} - ${row.directionName}`;

    const handleRowClick = () => {
        if (tripRowHandler) {
            tripRowHandler({
                tripId: row.tripInternalId,
                tripName,
                routeName: routeAndDirection,
                vehicleId: row.vehicleId,
                shapeId: row.shapeId,
            });
        }
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedTripId !== undefined && selectedTripId === row.tripInternalId}>
            <Table.Cell textAlign="left">
                {tripName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {routeAndDirection}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.vehicleId}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.ourMeanDelayErrorSec !== null ? Utils.roundNumber(row.ourMeanDelayErrorSec / 60, 1) : ''}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.ourMaxDelayErrorSec !== null ? Utils.roundNumber(row.ourMaxDelayErrorSec / 60, 1) : ''}
            </Table.Cell>
        </Table.Row>
    );
};

export default DelaysAnalysisTableRow;