import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { PredictionsAnalysisData } from './PredictionCoverageForm';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: '00-03',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#31A6DC',
            borderColor: '#31A6DC',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#31A6DC',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#31A6DC',
            pointHoverBorderColor: '#31A6DC',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: '03-06',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#FFC061',
            borderColor: '#FFC061',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FFC061',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#FFC061',
            pointHoverBorderColor: '#FFC061',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: '06-12',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#82E180',
            borderColor: '#82E180',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#82E180',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#82E180',
            pointHoverBorderColor: '#82E180',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: '12-30',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#FF8368',
            borderColor: '#FF8368',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FF8368',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#FF8368',
            pointHoverBorderColor: '#FF8368',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
    },
    scales: {
        xAxes: [{
            autoSkip: false,
            maxRotation: 0,
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                autoSkip: false,
                beginAtZero: true,
                stepSize: 1,
                maxTicksLimit: 10,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            scaleLabel: {
                display: true,
                labelString: '%',
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        backgroundColor: '#31A6DC',
                        borderColor: '#31A6DC',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        backgroundColor: '#FFC061',
                        borderColor: '#FFC061',
                    };
                } else if (tooltipItem.datasetIndex === 2) {
                    return {
                        backgroundColor: '#82E180',
                        borderColor: '#82E180',
                    };
                } else {
                    return {
                        backgroundColor: '#FF8368',
                        borderColor: '#FF8368',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
};

interface Props {
    chartData: PredictionsAnalysisData[];
    showTooltipXLabel?: boolean;
    scaleXLabel?: string;
}

const PredictionsPercentageForPeriodChart: React.FC<Props> = ({ chartData, showTooltipXLabel, scaleXLabel }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.indicator.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(d => {
                    return {
                        x: d.indicator,
                        y: d.short,
                    };
                }),
            },
            {
                ...prevState.datasets[1], data: chartData.map(d => {
                    return {
                        x: d.indicator,
                        y: d.mid1,
                    };
                }),
            },
            {
                ...prevState.datasets[2], data: chartData.map(d => {
                    return {
                        x: d.indicator,
                        y: d.mid2,
                    };
                }),
            },
            {
                ...prevState.datasets[3], data: chartData.map(d => {
                    return {
                        x: d.indicator,
                        y: d.long,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                xAxes: [{
                    ...prevState.scales.xAxes[0],
                    scaleLabel: {
                        display: scaleXLabel !== undefined,
                        labelString: scaleXLabel,
                    },
                }],
                yAxes: [{
                    ...prevState.scales.yAxes[0],
                    ticks: {
                        ...prevState.scales.yAxes[0].ticks,
                        callback: function (value: string) {
                            return value;
                        },
                    },
                }],
            },
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    ...prevState.tooltips.callbacks,
                    label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number, index: number }) {
                        let lineType = '00-03';
                        let value = chartData.map(v => v.short)[tooltipItem.index];
                        if (tooltipItem.datasetIndex === 1) {
                            lineType = '03-06';
                            value = chartData.map(v => v.mid1)[tooltipItem.index];
                        } else if (tooltipItem.datasetIndex === 2) {
                            lineType = '06-12';
                            value = chartData.map(v => v.mid2)[tooltipItem.index];
                        } else if (tooltipItem.datasetIndex === 3) {
                            lineType = '12-30';
                            value = chartData.map(v => v.long)[tooltipItem.index];
                        }
                        let tooltipLabel = `${lineType}: ${value} %`;
                        if (showTooltipXLabel)
                            tooltipLabel = `${tooltipItem.xLabel}: ` + tooltipLabel;
                        return tooltipLabel;
                    },
                },
            },
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={170}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default PredictionsPercentageForPeriodChart;
