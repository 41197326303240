import './BunchingStatisticsStyles.css';
import moment from 'moment';
import * as React from 'react';
import { MouseEvent, useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItemProps, Form, Header, Tab } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import { getShapeData } from '../../../../actions/gtfsStaticActions';
import { getBunchingArrivals, getBunchingReportForPeriod, getBusBunchingPositions } from '../../../../actions/otpActions';
import Api from '../../../../api/api';
import { AppState } from '../../../../reducers/index';
import { getSelectedOrDefaultAgency } from '../../../../selectors/index';
import busIconRed from '../../../../static/bus-icon-red.png';
import busIconBlue from '../../../../static/bus-icon.png';
import { PolylineProps, PushpinProps } from '../../../../types/BingMapProps';
import { BunchesByDatesChartProp, BunchesByDayPart, BunchesByKey, BunchingReport, BunchingRouteStat, BunchingStatisticsArrivals, BunchingStopStat, BunchingTimeStat } from '../../../../types/busBunchingTypes';
import { VehiclePositionDto } from '../../../../types/busHistoryTypes';
import { KeyValuePair, SliderOptions } from '../../../../types/types';
import { _1px } from '../../../../utilities/constants';
import Utils from '../../../../utilities/utils';
import StartAndEndDatesField from '../../../shared/StartAndEndDatesField';
import DetailsTabForm from './DetailsTabForm';
import StatisticsTabForm from './StatisticsTabForm';

export interface BunchingTableSelectedData {
    tripId1: string;
    tripId2: string;
    vehicleId1: string;
    vehicleId2: string;
    bunchTime: Date;
}
interface VehiclePosition {
    vehicleId: string;
    positions: VehiclePositionDto[],
}
export interface BunchingRouteInfo {
    routeName: string;
    directionName: string;
}
export interface VehicleBunchSliderInfo {
    vehiclePosition: VehiclePositionDto | undefined,
    bunchVehicleId: string | undefined;
}

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    monthFilterLabel: {
        display: 'block',
    } as React.CSSProperties,
    bunchigTab: {
        paddingBottom: '14px',
    } as React.CSSProperties,
    dropdownHeaderLeft: {
        float: 'left',
        marginBottom: '10px',
    } as React.CSSProperties,
    dropdownHeaderRight: {
        float: 'right',
        marginBottom: '10px',
    } as React.CSSProperties,
};

let tableSliderAutoPlayIntervalId: number | null = null;
const vehiclesSliderStepValue = 60000;
const initialSliderOptionsState: SliderOptions = {
    currentValue: 0,
    minValue: 0,
    maxValue: 0,
};

const BunchingStatisticsForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [fromDateState, setFromDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-2, 'days').format('YYYY-MM-DD'));
    const [toDateState, setToDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [center, setCenter] = useState<CoordinatePair>();
    const [minutesToAtzState, setMinutesToAtzState] = useState<number>(0);
    const [bunchesReportsState, setBunchesReportsState] = useState<BunchingReport[]>([]);
    const [bunchesByRoutesState, setBunchesByRoutesState] = useState<BunchesByKey[]>([]);
    const [stopsStatsState, setStopsStatsState] = useState<BunchingStopStat[]>([]);
    const [bunchesByDatesChartState, setBunchesByDatesChartState] = useState<BunchesByDatesChartProp | null>(null);
    const [bunchesByWeekDayState, setBunchesByWeekDayState] = useState<BunchesByKey[]>([]);
    const [bunchesByDayPartState, setBunchesByDayPartState] = useState<BunchesByDayPart[]>([]);
    const [monthsFilterState, setMonthsFilterState] = useState<string[]>([]);
    const [selectedRouteInfoState, setSelectedRouteInfoState] = useState<BunchingRouteInfo | undefined>(undefined);
    const [bunchingArrivalsState, setBunchingArrivalsState] = useState<BunchingStatisticsArrivals[]>([]);
    const [bunchingArrivalsTableState, setBunchingArrivalsTableState] = useState<BunchingStatisticsArrivals[]>([]);
    const [bunchingArrivalsSliderState, setBunchingArrivalsSliderState] = useState<KeyValuePair<string, BunchingStatisticsArrivals[]>[]>([]);
    const [tableSliderValueState, setTableSliderValueState] = useState(0);
    const [tableMapPushpinsState, setTableMapPushpinsState] = useState<PushpinProps[]>([]);
    const [tableSliderAutoPlayState, setTableSliderAutoPlayState] = useState<boolean>(false);
    const [selectedTableDataState, setSelectedTableDataState] = useState<BunchingTableSelectedData | undefined>(undefined);
    const [vehiclesMapBoundsState, setVehiclesMapBoundsState] = React.useState<Microsoft.Maps.LocationRect | undefined>();
    const [vehiclesMapPolylineState, setVehiclesMapPolylineState] = useState<PolylineProps>();
    const [vehiclesMapPushpinsState, setVehiclesMapPushpinsState] = useState<PushpinProps[]>([]);
    const [vehiclesSliderOptionsState, setVehiclesSliderOptionsState] = useState<SliderOptions>(initialSliderOptionsState);
    const [vehiclesPositionsState, setVehiclesPositionsState] = useState<VehiclePosition[]>([]);
    const [vehicleSliderInfoState, setVehicleSliderInfoState] = useState<VehicleBunchSliderInfo | undefined>(undefined);


    const tabPanes = [
        {
            menuItem: 'Statistics',
            render: () => <StatisticsTabForm {...{
                bunchesByRoutes: bunchesByRoutesState,
                stopsStats: stopsStatsState,
                bunchesByDatesChartProps: bunchesByDatesChartState,
                bunchesByWeekDay: bunchesByWeekDayState,
                bunchesByDayPart: bunchesByDayPartState,
                center,
            }}
            />,
        },
        {
            menuItem: 'Bunching History',
            render: () => <DetailsTabForm {...{
                bunchingArrivalsTable: bunchingArrivalsTableState,
                selectedTableData: selectedTableDataState,
                selectedRowHandler,
                center: center,
                handleValueLabelFormat,
                handleTableSliderChange,
                bunchingArrivalsSlider: bunchingArrivalsSliderState,
                tableSliderValue: tableSliderValueState,
                tableSliderAutoPlay: tableSliderAutoPlayState,
                tableMapPushpins: tableMapPushpinsState,
                handleTableSliderAutoPlayClick,
                vehiclesSliderOptions: vehiclesSliderOptionsState,
                handleVehiclesSliderChange,
                vehiclesSliderStepValue,
                vehiclesMapBounds: vehiclesMapBoundsState,
                vehiclesMapPolyline: vehiclesMapPolylineState,
                vehiclesMapPushpins: vehiclesMapPushpinsState,
                vehicleSliderInfo: vehicleSliderInfoState,
            }} />,
        },
    ];

    const handleRefreshButtonClick = async () => {
        await retrieveBusBunchings(fromDateState, toDateState);
    };
    const retrieveBusBunchings = async (fromDate: string, toDate: string) => {
        if (!agency)
            return;
        setFormBlockingState(true);
        try {
            const [{ busBunchingReports, minutesToAtz }, bunchingArrivals] = await Promise.all(
                [getBunchingReportForPeriod(agency.id, fromDate, toDate), getBunchingArrivals(agency.id, fromDate, toDate)]);
            setBunchingArrivalsState(bunchingArrivals);
            const bunchingArrivalsGroups = Object.entries(Utils.groupBy(bunchingArrivals, bunchings => moment.parseZone(bunchings.timeWindowStart).format('YYYY-MM-DD HH:mm:ss')))
                .map(([key, value]) => { return { key, value }; })
                .sort(({ key: a }, { key: b }) => moment(a).valueOf() - moment(b).valueOf());
            setBunchingArrivalsSliderState(bunchingArrivalsGroups);
            setBunchesReportsState(busBunchingReports);
            onTableSliderChange(0, bunchingArrivalsGroups);
            setMinutesToAtzState(minutesToAtz);
            const routesStats = busBunchingReports.flatMap(r => r.stat.routes);
            const stopsStats = Object.entries(Utils.groupBy(busBunchingReports.flatMap(r => r.stat.topStops), s => s.id))
                .map(s => {
                    return {
                        id: s[0],
                        name: s[1][0].name,
                        lat: s[1][0].lat,
                        lon: s[1][0].lon,
                        count: s[1].reduce((sum: number, stopStat: BunchingStopStat) => stopStat.count + sum, 0),
                    };
                });
            setStopsStatsState(stopsStats);
            const bunchesByRoutes: BunchesByKey[] = Object.entries(Utils.groupBy(routesStats, b => `${b.routeShortName} - ${b.otStripShortName}`))
                .map(g => { return { key: g[0], bunchesCount: g[1].reduce((sum: number, routeStat: BunchingRouteStat) => routeStat.count + sum, 0) }; })
                .sort((a, b) => (a.bunchesCount < b.bunchesCount) ? 1 : -1);
            setBunchesByRoutesState(bunchesByRoutes);
            const timeStats = busBunchingReports.flatMap(r => r.stat.hours);
            updateChartsData(timeStats, fromDate, toDate, minutesToAtz);
        } catch {
            setBunchesByRoutesState([]);
            setBunchesByDatesChartState(null);
        } finally {
            setFormBlockingState(false);
        }
    };

    const updateChartsData = (timeStats: BunchingTimeStat[], fromDate: string, toDate: string, minutesToAtz: number) => {
        const daysDiff = moment(toDate).diff(fromDate, 'days');
        const bunchesByDates: BunchesByKey[] = Object.entries(Utils.groupBy(timeStats, t => {
            return daysDiff > 1 ? moment.parseZone(t.time).utcOffset(minutesToAtz).format('M/D/YYYY') : moment.parseZone(t.time).utcOffset(minutesToAtz).format('M/D/YYYY h:00 a');
        }))
            .map(g => { return { key: g[0], bunchesCount: g[1].reduce((sum: number, stat: BunchingTimeStat) => stat.count + sum, 0) }; });
        const updatedBunchesByDates = setMissingHoursToBunchesByDates(bunchesByDates, daysDiff > 1 ? 'days' : 'hours', daysDiff > 1 ? 'M/D/YYYY' : 'M/D/YYYY h:00 a');
        setBunchesByDatesChartState({ fromDate, toDate, daysDiff, bunchesByDates: updatedBunchesByDates });

        const bunchesByWeekDay: BunchesByKey[] = Object.entries(Utils.groupBy(timeStats.sort((a, b) => moment.parseZone(a.time).utcOffset(minutesToAtz).weekday() - moment.parseZone(b.time).utcOffset(minutesToAtz).weekday()), t => moment.parseZone(t.time).utcOffset(minutesToAtz).format('dddd')))
            .map(g => { return { key: g[0], bunchesCount: g[1].reduce((sum: number, stat: BunchingTimeStat) => stat.count + sum, 0) }; });
        setBunchesByWeekDayState(bunchesByWeekDay);

        const bunchesByDayPart: BunchesByDayPart[] = Object.entries(Utils.groupBy(timeStats, t => {
            const hour: number = moment(moment.parseZone(t.time).utcOffset(minutesToAtz).format('YYYY-MM-DD hh:mm A')).hour();
            if (hour >= 19 || hour < 6)
                return 'Night';
            else if (hour >= 6 && hour < 9)
                return 'Morning';
            else if (hour >= 9 && hour < 17)
                return 'Day';
            else
                return 'Evening';
        }))
            .map(g => {
                let desc = '';
                let dayPartOrder = 0;
                const part = g[0];
                if (part === 'Night')
                    desc = '7pm - 6am';
                else if (part === 'Morning') {
                    desc = '6am - 9am';
                    dayPartOrder = 1;
                }
                else if (part === 'Day') {
                    desc = '9am - 5pm';
                    dayPartOrder = 2;
                }
                else {
                    desc = '5pm - 7pm';
                    dayPartOrder = 3;
                }
                return {
                    dayPart: part,
                    dayPartDesc: desc,
                    dayPartOrder,
                    bunchesCount: g[1].reduce((sum: number, stat: BunchingTimeStat) => stat.count + sum, 0),
                };
            })
            .sort((a, b) => a.dayPartOrder - b.dayPartOrder);
        setBunchesByDayPartState(bunchesByDayPart);
    };
    const setMissingHoursToBunchesByDates = (bunchesByDates: BunchesByKey[], datePart: string, format: string) => {
        const updatedBunches: BunchesByKey[] = [];
        const orderedBunchesByDates = bunchesByDates.sort((a, b) => moment(a.key).valueOf() - moment(b.key).valueOf());
        for (let i = 0; i < orderedBunchesByDates.length - 1; i++) {
            const currentBunch = orderedBunchesByDates[i];
            const nextBunch = orderedBunchesByDates[i + 1];
            updatedBunches.push(currentBunch);
            let currentTime = moment(currentBunch.key);
            const endTime = moment(nextBunch.key).add(-1 as moment.DurationInputArg1, datePart as moment.DurationInputArg2);
            while (currentTime.isBefore(endTime)) {
                currentTime = currentTime.add(1 as moment.DurationInputArg1, datePart as moment.DurationInputArg2);
                updatedBunches.push({ key: currentTime.format(format), bunchesCount: 0 });
            }
        }
        updatedBunches.push(orderedBunchesByDates[orderedBunchesByDates.length - 1]);
        return updatedBunches;
    };
    const handleMonthFilterClick = async (date: string) => {
        const fromDate = moment(date).startOf('month').format('YYYY-MM-DD');
        const toDate = moment(date).endOf('month').format('YYYY-MM-DD');
        setFromDateState(fromDate);
        setToDateState(toDate);
        await retrieveBusBunchings(fromDate, toDate);
    };
    const setMonthsFilter = () => {
        const months: string[] = [];
        const endDate = moment().startOf('day');
        let startDate = moment().add(-5, 'months').startOf('day');
        while (startDate.isSameOrBefore(endDate)) {
            const date = startDate.format('YYYY-MM-DD');
            months.push(date);
            startDate = startDate.add(1, 'months');
        }
        setMonthsFilterState(months);
    };
    const handleClearSelectedRoute = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        setSelectedRouteInfoState(undefined);
    };
    const selectedRowHandler = async (selectedRow: BunchingStatisticsArrivals) => {
        setVehicleSliderInfoState(undefined);
        const { tripId1, tripId2, vehicleId1, vehicleId2, bunchTime, serviceDate: tripDate, shapeId } = selectedRow;
        if (!agency)
            return;
        setSelectedTableDataState({ tripId1, tripId2, vehicleId1, vehicleId2, bunchTime });
        setFormBlockingState(true);
        try {
            const [vehiclesPositions1, vehiclesPositions2, shapePoints] = await Promise.all(
                [getBusBunchingPositions(agency.id, moment.parseZone(tripDate).format('YYYY-MM-DD'), tripId1, vehicleId1),
                getBusBunchingPositions(agency.id, moment.parseZone(tripDate).format('YYYY-MM-DD'), tripId2, vehicleId2),
                getShapeData(agency.id, moment.parseZone(tripDate).format('YYYY-MM-DD'), shapeId)]);

            const vehiclePositions: VehiclePosition[] = [{
                vehicleId: vehicleId1,
                positions: vehiclesPositions1,
            }, {
                vehicleId: vehicleId2,
                positions: vehiclesPositions2,
            }];
            setVehiclesPositionsState(vehiclePositions);

            setVehiclesMapPolylineState({
                locations: shapePoints.map(({ latitude, longitude }) => [latitude, longitude]),
                options: {
                    strokeColor: 'red',
                    strokeThickness: 3,
                },
            });
            const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
            const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
            setVehiclesMapBoundsState(boundingBox);

            const orderedPositions = vehiclePositions[1].positions
                .sort((a, b) => new Date(a.vehicleTime).getTime() - new Date(b.vehicleTime).getTime());
            const sliderMinValue = Utils.utcToLocal(orderedPositions[0].vehicleTime, minutesToAtzState).getTime();
            const sliderMaxValue = Utils.utcToLocal(orderedPositions[orderedPositions.length - 1].vehicleTime, minutesToAtzState).getTime();
            const sliderBunchPosition = orderedPositions.find(p => moment.parseZone(p.vehicleTime).utcOffset(minutesToAtzState).seconds(0).isSame(moment.parseZone(bunchTime).seconds(0)));
            const sliderBunchValue = sliderBunchPosition ? Utils.utcToLocal(sliderBunchPosition.vehicleTime, minutesToAtzState).getTime() : sliderMinValue;
            const sliderOptions: SliderOptions = {
                currentValue: sliderBunchValue,
                minValue: sliderMinValue,
                maxValue: sliderMaxValue,
            };
            setVehiclesSliderOptionsState(sliderOptions);
        }
        catch {
            setVehiclesPositionsState([]);
            setSelectedTableDataState(undefined);
        } finally {
            setFormBlockingState(false);
        }
    };
    const updateTableMap = (bunchingArrivals: BunchingStatisticsArrivals[]) => {
        setTableMapPushpinsState(
            bunchingArrivals.filter(({ latitude, longitude }) => latitude && longitude).map(bunchingArrival => {
                const { latitude, longitude } = bunchingArrival;
                return {
                    location: [latitude, longitude],
                    options: {
                        catId: 'bus-stop',
                    },
                    eventHandlers: [{
                        event: 'click',
                        callback: () => {
                            selectedRowHandler(bunchingArrival);
                        },
                    }],
                };
            }));
    };
    const handleValueLabelFormat = (value: number): React.ReactNode => {
        const bunchingArrivals = bunchingArrivalsSliderState[value];
        const formattedDate = bunchingArrivals
            ? moment.parseZone(bunchingArrivals.key).format('YYYY-MM-DD')
            : '';
        const formattedTime = bunchingArrivals
            ? moment.parseZone(bunchingArrivals.key).format('hh:mm a')
            : '';
        return (
            <>
                <div style={{ textAlign: 'center', marginTop: '40px', width: '70px' }}>{formattedTime}</div>
                <div style={{ textAlign: 'center', position: 'relative', top: '35px' }}>{formattedDate}</div>
            </>
        );
    };
    const getAutoPlaySliderValue = (prevValue: number): number => {
        const maxValue = bunchingArrivalsSliderState.length - 1;
        return prevValue <= maxValue - 1 ? prevValue + 1 : 0;
    };
    const handleTableSliderChange = (_e: React.ChangeEvent<{}>, value: number | number[]) => {
        setTableSliderValueState(value as number);
    };
    const onTableSliderChange = (sliderValue: number, bunchingArrivalsSlider: KeyValuePair<string, BunchingStatisticsArrivals[]>[]) => {
        const bunchingArrivals = bunchingArrivalsSlider[sliderValue];
        if (!bunchingArrivals)
            return;
        setBunchingArrivalsTableState(bunchingArrivals.value);
        updateTableMap(bunchingArrivals.value);
    };
    const handleTableSliderAutoPlayClick = () => {
        setTableSliderAutoPlayState(prevState => !prevState);
    };
    const handleVehiclesSliderChange = (_e: React.ChangeEvent<{}>, value: number | number[]) => {
        const sliderIndex = value as number;
        setVehiclesSliderOptionsState(prevState => ({
            ...prevState,
            currentValue: sliderIndex,
        }));
    };
    const updateVehiclesMap = (sliderValue: number, vehiclePositions: VehiclePosition[]) => {
        if (!sliderValue || !selectedTableDataState)
            return;
        const positionsForTime: { busIcon: string, lat: Latitude, lon: Longitude }[] = [];
        const { vehicleId1 } = selectedTableDataState;
        for (const vehicle of vehiclePositions) {
            const position = vehicle.positions.find(p => moment(Utils.utcToLocal(p.vehicleTime, minutesToAtzState)).seconds(0).isSame(moment(sliderValue).seconds(0)));
            if (position) {
                positionsForTime.push({ lat: position.latitude, lon: position.longitude, busIcon: vehicle.vehicleId === vehicleId1 ? busIconBlue : busIconRed });
            }
        }
        if (positionsForTime.length > 0) {
            setVehiclesMapPushpinsState(
                positionsForTime.map(({ lat, lon, busIcon }) => ({
                    location: [lat, lon],
                    options: {
                        icon: busIcon,
                        anchor: new Microsoft.Maps.Point(_1px, _1px),
                        catId: 'bus-stop',
                    },
                })));
        } else {
            setVehiclesMapPushpinsState([]);
        }
    };

    const renderRoutesDropdownItems = (bunch: BunchesByKey) => {
        const option = {
            key: bunch.key,
            text: bunch.key,
            value: bunch.key,
            description: bunch.bunchesCount,
            onClick: handleRouteChange,
        };
        return (
            <Dropdown.Item {...option} />
        );
    };
    const handleRouteChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownItemProps) => {
        const [routeName, directionName] = (value as string).split(' - ');
        setSelectedRouteInfoState({ routeName, directionName });
    };

    useEffect(() => {
        const bunchingArrivals = selectedRouteInfoState
            ? bunchingArrivalsState.filter(bunchings => bunchings.routeName === selectedRouteInfoState.routeName && bunchings.directionName === selectedRouteInfoState.directionName)
            : bunchingArrivalsState;
        const bunchingArrivalsGroups = Object.entries(Utils.groupBy(bunchingArrivals, bunchings => moment.parseZone(bunchings.timeWindowStart).format('YYYY-MM-DD HH:mm:ss')))
            .map(([key, value]) => { return { key, value }; })
            .sort(({ key: a }, { key: b }) => moment(a).valueOf() - moment(b).valueOf());
        setBunchingArrivalsSliderState(bunchingArrivalsGroups);
        setTableSliderValueState(0);
        onTableSliderChange(0, bunchingArrivalsGroups);
        if (!minutesToAtzState)
            return;
        if (selectedRouteInfoState && bunchesReportsState.length > 0) {
            const timeStats = bunchesReportsState
                .flatMap(r => r.stat.routes)
                .filter(r => r.routeShortName === selectedRouteInfoState.routeName && r.otStripShortName == selectedRouteInfoState.directionName)
                .flatMap(r => r.hours);
            updateChartsData(timeStats, fromDateState, toDateState, minutesToAtzState);
        } else {
            const timeStats = bunchesReportsState.flatMap(r => r.stat.hours);
            updateChartsData(timeStats, fromDateState, toDateState, minutesToAtzState);
        }
    }, [selectedRouteInfoState]);

    useEffect(() => {
        const sliderValue = vehiclesSliderOptionsState.currentValue;
        if (vehiclesSliderOptionsState && vehiclesPositionsState.length > 0 && sliderValue) {
            updateVehiclesMap(sliderValue, vehiclesPositionsState);
            const bunchVehicleId = vehiclesPositionsState[0].vehicleId;
            const vehiclePositions = vehiclesPositionsState[1].positions;
            const position = vehiclePositions.find(p => moment(Utils.utcToLocal(p.vehicleTime, minutesToAtzState)).seconds(0).isSame(moment(sliderValue).seconds(0)));
            setVehicleSliderInfoState({ bunchVehicleId, vehiclePosition: position });
        }
    }, [vehiclesSliderOptionsState]);

    useEffect(() => {
        onTableSliderChange(tableSliderValueState, bunchingArrivalsSliderState);
    }, [tableSliderValueState]);

    useEffect(() => {
        if (tableSliderAutoPlayState && tableSliderAutoPlayIntervalId === null) {
            tableSliderAutoPlayIntervalId = window.setInterval(() => setTableSliderValueState(prevState => getAutoPlaySliderValue(prevState)), 250);
        } else if (!tableSliderAutoPlayState && tableSliderAutoPlayIntervalId !== null) {
            clearInterval(tableSliderAutoPlayIntervalId);
            tableSliderAutoPlayIntervalId = null;
        }
    }, [tableSliderAutoPlayState]);

    useEffect(() => {
        if (agency === undefined) return;
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
            await retrieveBusBunchings(fromDateState, toDateState);
            setMonthsFilter();
        })(agency.id);
    }, [agency?.id]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Bunching Statistics
                </Header>
                <Form.Group inline className="inputGroup">
                    <StartAndEndDatesField {...{
                        startDate: fromDateState,
                        setStartDate: setFromDateState,
                        endDate: toDateState,
                        setEndDate: setToDateState,
                    }} />
                    <Form.Field>
                        <Button
                            primary
                            content="Refresh"
                            onClick={handleRefreshButtonClick}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                    {monthsFilterState.length > 0 &&
                        <>
                            <Form.Field inline={false} className="fieldControl">
                                <label style={styles.monthFilterLabel}> Or choose month:</label>
                                {monthsFilterState.map((date, index) => (
                                    <Link key={index} to="/" onClick={(e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
                                        e.preventDefault();
                                        handleMonthFilterClick(date);
                                    }}>
                                        {moment(date).format('MMM').toUpperCase()} </Link>
                                ))}
                            </Form.Field>
                        </>
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        {selectedRouteInfoState && 'Selected '}
                        <Dropdown
                            text={selectedRouteInfoState ? `${selectedRouteInfoState.routeName} - ${selectedRouteInfoState.directionName}` : 'Select route'}
                            floating
                            openOnFocus={false}
                            selectOnBlur={false}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Header>
                                    <span style={styles.dropdownHeaderLeft}>Route</span>
                                    <span style={styles.dropdownHeaderRight}>Bunches</span>
                                </Dropdown.Header>
                                <Dropdown.Menu scrolling>
                                    {bunchesByRoutesState.map(renderRoutesDropdownItems)}
                                </Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>
                        {selectedRouteInfoState && <Link to="/" onClick={handleClearSelectedRoute}> (clear)</Link>}
                    </Form.Field>
                </Form.Group>
                <Tab panes={tabPanes} style={styles.bunchigTab} />
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(BunchingStatisticsForm);