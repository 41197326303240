import { TransportTypeEnum } from '../../types/tripPlannelTypes';

export class Color {
    private readonly packed: number;

    private get a(): number { return (this.packed >> 24) & 0xFF; }
    private get r(): number { return (this.packed >> 16) & 0xFF; }
    private get g(): number { return (this.packed >> 8) & 0xFF; }
    private get b(): number { return (this.packed >> 0) & 0xFF; }

    private _hex3?: string;
    public get hex3(): string {
        if (!this._hex3) this._hex3 = `#${('000000' + (this.packed & 0xFFFFFF).toString(16)).substr(-6)}`;
        return this._hex3;
    }

    private _mapColor?: Microsoft.Maps.Color;
    public get mapColor(): Microsoft.Maps.Color {
        if (!this._mapColor) this._mapColor = new Microsoft.Maps.Color(this.a, this.r, this.g, this.b);
        return this._mapColor;
    }

    constructor(
        a: number,
        r: number,
        g: number,
        b: number,
    ) {
        this.packed = ((a & 0xFF) << 24) | ((r & 0xFF) << 16) | ((g & 0xFF) << 8) | ((b & 0xFF) << 0);
    }
}

interface ColorSet1 {
    baseColor: Color;
    alternative?: Color;
    subColors?: {
        [subtype: string]: Color;
    }
}

interface ColorSet2 {
    [type: string]: ColorSet1 | null | undefined;
}

const unknown: ColorSet1 = {
    baseColor: new Color(255, 0, 0, 0),
};

const colors: ColorSet2 = {
    [TransportTypeEnum.Bus]: {
        baseColor: new Color(255, 0, 179, 0), //"red", "rgba(179,0,0,1)",//"#0000B3",
        alternative: new Color(255, 179, 0, 0), //"rgba(179,0,0,1)" //"#300000",
    },
    [TransportTypeEnum.Metro]: {
        baseColor: new Color(255, 0, 179, 0),
        subColors: {
            Red: new Color(255, 255, 0, 0),
            Orange: new Color(255, 255, 165, 0),
            Silver: new Color(255, 161, 163, 161),
            Blue: new Color(255, 50, 162, 232),
            Yellow: new Color(255, 255, 255, 0),
            Green: new Color(255, 0, 128, 0),
        },
    },
    [TransportTypeEnum.Walk]: {
        baseColor: new Color(255, 96, 96, 96), //new Color(255, 152, 152, 153)
    },
    [TransportTypeEnum.Bicycle]: {
        baseColor: new Color(255, 152, 152, 153),
    },
    [TransportTypeEnum.Car]: {
        baseColor: new Color(255, 152, 152, 153),
    },
};


// export function getTransportTypeColor(transportType: TransportTypeEnum, subtype?: string) {
//     const cset = colors[transportType] || colors.default;
//     return subtype && cset.subColors && cset.subColors[subtype] || cset.baseColor;
// }

export function getLegColor(transportType: TransportTypeEnum, legName: string | null, useAternative: boolean) {
    const cset = colors[transportType] || unknown;
    let result = legName && cset.subColors ? cset.subColors[legName] : undefined;
    if (!result && useAternative)
        result = cset.alternative;
    if (!result)
        result = cset.baseColor;
    return result;
}