import api from '../api/anomaliesApi';
import { BusAnalysisReport,
    DelayJumpsMain,
    DelayJumpsVehiclePosition,
    PositionReportingGap,
    TopWrongPredictingRoute,
    TripReportingGap,
    VehicleReportingGap,
    WrongCoordinatesReport,
    WrongCoordinatesVehiclePosition } from '../types/anomaliesTypes';

export async function getWrongCoordinates(agencyId: string, serviceDate: string): Promise<WrongCoordinatesReport> {
    const result = await api.getWrongCoordinates(agencyId, serviceDate);
    return result.data;
}
export async function getWrongCoordinatesVehiclePositions(agencyId: string, reportDate: string, tripInternalId: string, vehicleId: string, actualStart: string, actualFinish: string): Promise<WrongCoordinatesVehiclePosition[]> {
    const result = await api.getWrongCoordinatesVehiclePositions(agencyId, reportDate, tripInternalId, vehicleId, actualStart, actualFinish);
    return result.data || [];
}

export async function getDelayJumpsMain(agencyId: string, reportDate: string): Promise<DelayJumpsMain[]> {
    const result = await api.getDelayJumpsMain(agencyId, reportDate);
    return result.data || [];
}
export async function getDelayJumpsVehiclePositions(agencyId: string, reportDate: string, tripInternalId: string, vehicleId: string): Promise<DelayJumpsVehiclePosition[]> {
    const result = await api.getDelayJumpsVehiclePositions(agencyId, reportDate, tripInternalId, vehicleId);
    return result.data || [];
}
export async function getVehiclesReportingGaps(agencyId: string, serviceDate: string): Promise<VehicleReportingGap[]> {
    const result = await api.getVehiclesReportingGaps(agencyId, serviceDate);
    return result.data || [];
}
export async function getTripsReportingGaps(agencyId: string, serviceDate: string, vehicleId: string): Promise<TripReportingGap[]> {
    const result = await api.getTripsReportingGaps(agencyId, serviceDate, vehicleId);
    return result.data || [];
}
export async function getPositionsReportingGaps(agencyId: string, serviceDate: string, tripId: string, vehicleId: string): Promise<PositionReportingGap[]> {
    const result = await api.getPositionsReportingGaps(agencyId, serviceDate, tripId, vehicleId);
    return result.data || [];
}

export async function getVehicles(agencyId: string): Promise<string[]> {
    const result = await api.getVehicles(agencyId);
    return result.data || [];
}

export async function getBusAnalysisReports(agencyId: string, fromDate: string, toDate: string, vehicleId: string): Promise<BusAnalysisReport[]> {
    const result = await api.getBusAnalysisReports(agencyId, fromDate, toDate, vehicleId);
    return result.data || [];
}

export async function getTopWrongPredictingRoutes(agencyId: string, reportDate: string): Promise<TopWrongPredictingRoute[]> {
    const result = await api.getTopWrongPredictingRoutes(agencyId, reportDate);
    return result.data || [];
}
