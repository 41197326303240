import { WrongCoordinatesTrip } from '../../../types/anomaliesTypes';
import { TableDispatchAction, TableSortProperties } from '../../../types/types';

export default function tableSortReducer(state: TableSortProperties<WrongCoordinatesTrip>, action: TableDispatchAction<WrongCoordinatesTrip>): TableSortProperties<WrongCoordinatesTrip> {
    if (!action.data)
        return state;

    switch (action.type) {
        case 'CHANGE_SORT': {
            if (state.column === action.column) {
                return {
                    ...state,
                    data: action.data.reverse(),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                };
            }

            let data = [];
            switch (action.column) {
                case 'start':
                    data = state.data.sort((a, b) => new Date(a[action.column]).getTime() - new Date(b[action.column]).getTime());
                    break;
                case 'vehicle':
                    data = state.data.sort((a, b) => {
                        const aVal = a[action.column] as number | null;
                        const bVal = b[action.column] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                case 'route':
                    data = state.data.sort((a, b) => {
                        const aVal = a[action.column] as number | null;
                        const bVal = b[action.column] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                case 'onShape':
                    data = state.data.sort((a, b) => {
                        const aVal = a[action.column] as number | null;
                        const bVal = b[action.column] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                case 'outShape':
                    data = state.data.sort((a, b) => {
                        const aVal = a[action.column] as number | null;
                        const bVal = b[action.column] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                case 'outShapePcnt':
                    data = state.data.sort((a, b) => {
                        const aVal = a[action.column] as number | null;
                        const bVal = b[action.column] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                default:
                    data = action.data;
            }
            return {
                column: action.column,
                data: data,
                direction: 'ascending',
            };
        }
        case 'UPDATE_DATA': {
            return {
                ...state,
                data: action.data || [],
            };
        }
    }
}
