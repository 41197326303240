import { ApiOpResult } from '../types/apiTypes';

export interface SearchParams {
    [name: string]: string | number | null | undefined;
}

export function buildUrlString(searchParams: SearchParams | null | undefined, ...urlSegments: string[]): string {
    let result = '';
    let flag = 0;
    if (urlSegments.length > 0) {
        for (const segment of urlSegments) {
            if (!segment) throw new Error(`Invalid segment value '${segment}'`);
            if (flag === 0) ++flag; else result += '/';
            result += segment;
        }
    } else {
        flag++;
    }
    result = encodeURI(result).replace(/%5B/g, '[').replace(/%5D/g, ']');
    if (searchParams) {
        for (const name in searchParams) {
            const paramValue = searchParams[name];
            if (paramValue === null || searchParams === undefined)
                continue;
            const separator = flag === 1 ? (++flag, '?') : '&';
            result += separator;
            result += encodeURIComponent(name);
            result += '=';
            if (paramValue !== undefined)
                result += encodeURIComponent(paramValue);
        }
    }
    return result;
}

export function makeErrorResult<T>(statusCode: number, errorMessage: string): ApiOpResult<T> {
    const r: ApiOpResult<T> = {
        isSuccess: false,
        error: {
            statusCode: statusCode,
            message: errorMessage,
        },
    };
    return r;
}

export function makeSuccessResult<T>(resultData: T): ApiOpResult<T> {
    const r: ApiOpResult<T> = {
        isSuccess: true,
        data: resultData,
    };
    return r;
}
