import * as React from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import { RoutesDelayStatisticsTableData } from '../../../../types/delayStatisticsTypes';
import { TableSortProperties } from '../../../../types/types';
import Utils from '../../../../utilities/utils';

const styles = {
    table: {
        display: 'block',
        maxHeight: '358px',
        overflowY: 'scroll',
    } as React.CSSProperties,
    avgDelayIcon: {
        paddingLeft: '8px',
    } as React.CSSProperties,
};

interface TableProps {
    tableDataState: TableSortProperties<RoutesDelayStatisticsTableData>;
    selectedRouteName?: string;
    selectedRouteHandler: (routeName: string) => Promise<void>;
    columnSortHandler: (column: string) => void;
}

const RoutesStatisticsTable: React.FC<TableProps> = ({ tableDataState, selectedRouteName, selectedRouteHandler, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'routeName' ? direction : undefined}
                            onClick={() => columnSortHandler('routeName')}>
                            Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'totalArrivals' ? direction : undefined}
                            onClick={() => columnSortHandler('totalArrivals')}>
                            # Arrivals
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'avgDelaySec' ? direction : undefined}
                            onClick={() => columnSortHandler('avgDelaySec')}>
                            Avg Deviation
                            <Popup content="Average deviation for selected time period" trigger={<Icon name="question circle" color="blue" size="large" style={styles.avgDelayIcon} />} />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'earlyPercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('earlyPercentage')}>
                            % Early
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'onTimePercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('onTimePercentage')}>
                            % On Time
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'latePercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('latePercentage')}>
                            % Late
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'veryLatePercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('veryLatePercentage')}>
                            % Very Late
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <RoutesStatisticsTableRow key={index} row={row} selectedRouteName={selectedRouteName} selectedRouteHandler={selectedRouteHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default RoutesStatisticsTable;

interface RowProps {
    row: RoutesDelayStatisticsTableData;
    selectedRouteName?: string;
    selectedRouteHandler: (routeName: string) => Promise<void>;
}

const RoutesStatisticsTableRow: React.FC<RowProps> = ({ row, selectedRouteName, selectedRouteHandler }) => {
    const handleRowClick = () => {
        if (selectedRouteHandler)
            selectedRouteHandler(row.routeName);
    };

    return (
        <Table.Row active={selectedRouteName !== undefined && selectedRouteName === row.routeName} onClick={handleRowClick}>
            <Table.Cell>
                {row.routeName}
            </Table.Cell>
            <Table.Cell>{row.totalArrivals.toLocaleString('en-US')}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(row.avgDelaySec)}</Table.Cell>
            <Table.Cell>{row.earlyPercentage} %</Table.Cell>
            <Table.Cell>{row.onTimePercentage} %</Table.Cell>
            <Table.Cell>{row.latePercentage} %</Table.Cell>
            <Table.Cell>{row.veryLatePercentage} %</Table.Cell>
        </Table.Row>
    );
};