export interface ShapeItemDto {
  lat: number,
  lon: number
}

export interface BeaconDto {
  /** example: "1008_12308" */
  iBeaconMajorMinor: string,
  /** example: "29A4227D3227" */
  eddystoneInstanceId: string,
  /** example: "169205589556632733" */
  sBeaconId: string,
  /** example: "W BELLFORT AVE @ STELLA LINK RD" */
  desc: string
}

export enum TransportTypeEnum {
  Unknown = 'Unknown',
  Walk = 'Walk',
  Bus = 'Bus',
  Metro = 'Metro',
  LightTrain = 'LightTrain',
  Train = 'Train',
  Arrived = 'Arrived',
  Bicycle = 'Bicycle',
  Car = 'Car'
}

export type TransportType = keyof typeof TransportTypeEnum;

export interface LegDto {
  /** example: "2020-12-04T06:22:10.6189888Z" */
  created: string,
  ordinal: number,
  transportType: TransportType,
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  duration: number,
  lengthKm: number,
  /** example: "Ho414" */
  agencyId: string | null,
  /** example: "RideMetro" */
  agencyAbbr: string | null,
  /** example: "Houston" */
  agencyName: string | null,
  /** example: "EASTBOUND" */
  tripHeadsign: string | null,
  /** example: "EASTBOUND" */
  tripCardinalDirection: string | null,
  /** example: "EASTBOUND" */
  routeDestinationName: string | null,
  /** example: "Ho414_4620_8774508" */
  tripId: string | null,
  /** example: "Ho414_4620_39787" */
  routeId: string | null,
  /** example: "008" */
  routeName: string | null,
  /** example: "Ho414_4620_39787_0" */
  directionId: string | null,
  /** example: "0" */
  directionNum: string | null,
  /** example: "Ho414_4620_39787_0_0" */
  directionVariantId: string | null,
  /** example: "W Bellfort Ave @ Stella Link Rd" */
  stop1Name: string | null,
  /** example: "Fannin South TC" */
  stop2Name: string | null,
  /* example: "Ho414_4620_12308" */
  stop1ID: string | null,
  /** example: "Ho414_4620_11029" */
  stop2ID: string | null,
  /** example: "2020-12-03T18:39:07Z" */
  startTimeUTC: string,
  /** example: "13:39 PM" */
  startLocalTimeFormatted: string,
  /** example: "2020-12-03T18:44:42Z" */
  endTimeUTC: string,
  /** example: "13:44 PM" */
  endLocalTimeFormatted: string,
  delayFromScheduleInSeconds: number | null,
  stop1EntranceName: string | null,
  stop2EntranceName: string | null,
  /** example: "Every 30 min" */
  frequency: string | null,
  legText: null,
  beacon1: BeaconDto | null,
  beacon2: BeaconDto | null,
  shape: ShapeItemDto[]
}

export interface ItineraryDto {
  /** example: "4066c115-ee58-4a6f-90ed-760ea08ae942" */
  itineraryId: string,
  /** example: "2020-12-04T06:22:10.6190231Z" */
  created: string,
  /** example: "2020-12-03T18:40:07Z" */
  startTimeUTC: string,
  /** example: "2020-12-03T20:05:34Z" */
  endTimeUTC: string,
  dataKey: null,
  localTimeOffset: number,
  legs: LegDto[]
}

export interface AutocompleteResult {
    fullAddress: string;
    lat: Latitude;
    lon: Longitude;
}
