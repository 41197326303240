import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { loginError, loginSuccess } from '../../actions/actions';
import { AppState } from '../../reducers';
import { isLogged } from '../../selectors';
import { isValidUser, isValidUserWithRole } from '../../utilities/user';
import userManager from '../../utilities/userManager';
import LoginForm, { ExternalLoginState, LoginKind, setLoginKind } from './LoginForm';
import { loginPathPrefix, logoutPathPrefix } from './routes';


const LoggingIn: React.FC<{
    isLogged: boolean;
    login: (user: User) => void;
}> = ({ isLogged, login }) => {
    const [loginInProgress, setLoginInProgress] = useState(true);
    const [redirectPath, setRedirectPath] = useState(loginPathPrefix);

    useEffect(() => {
        if (!loginInProgress) return;
        userManager.signinRedirectCallback()
            .then(user => {
                const state = user.state as ExternalLoginState;
                setRedirectPath(state.path);
                login(user);
                if (isValidUser(user) && !isValidUserWithRole(user)) {
                    alert('You do not have permissions to access TransitIQ');
                }
            })
            .catch(e => {
                console.error(e);
                setRedirectPath(logoutPathPrefix);
            })
            .finally(() => {
                setLoginInProgress(false);
                setLoginKind(LoginKind.REGULAR);
            });
    }, []);

    if (isLogged || !loginInProgress) return (
        <Redirect to={redirectPath} />
    );

    return (
        <LoginForm loading={true} />
    );
};

export default connect(
    (state: AppState) => ({
        isLogged: isLogged(state),
    }),
    (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => ({
        login: (user: User) => {
            isValidUserWithRole(user)
                ? dispatch(loginSuccess(user))
                : dispatch(loginError());
        },
    }),
)(LoggingIn);
