import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { BunchingStatisticsArrivals } from '../../../../types/busBunchingTypes';
import { BunchingTableSelectedData } from './BunchingStatisticsForm';
import BunchingTableRow from './BunchingTableRow';


const styles = {
    table: {
        display: 'block',
        minHeight: '300px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    rows: BunchingStatisticsArrivals[],
    selectedData: BunchingTableSelectedData | undefined,
    selectedRowHandler: (selectedRow: BunchingStatisticsArrivals) => Promise<void>
}

const BunchingTable: React.FC<Props> = ({ rows, selectedData, selectedRowHandler }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Bunch Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Route</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip #1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip #2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle #1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle #2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Scheduled Headway (sec)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Actual Headway (sec)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Headway Decrease %</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <BunchingTableRow key={index} row={row} selectedData={selectedData} selectedRowHandler={selectedRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default BunchingTable;