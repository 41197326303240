import { getRoutes } from '../../actions/gtfsStaticActions';
import Api from '../../api/api';
import * as liveMapApi from '../../api/liveMapApi';
import { RouteModel } from '../../types/view-models';
import { VehiclesNowDto } from './_dto';
import { convertObjectNames } from './_utils';

export async function getOrgLocation(agencyId: string): Promise<CoordinatePair>
{
    const result = await Api.getOrgLocation(agencyId);
    return [result.Latitude, result.Longitude];
}

export async function loadRoutes(agencyId: string, serviceDate: string) {
    const routes = await getRoutes(agencyId, serviceDate);
    return routes.map(src => new RouteModel(src));
}

export async function getCurrentVehiclesOnDirections(directionsIds: string, includingScheduled = false): Promise<VehiclesNowDto> {
    const apiRes = await liveMapApi.getCurrentVehiclesOnDirections(directionsIds, includingScheduled);
    return convertObjectNames(apiRes.data) as VehiclesNowDto;

}

export async function getCurrentRealtimeVehicles(agendyId: string, includingNotOnTrip = false): Promise<VehiclesNowDto> {
    const apiRes = await liveMapApi.getCurrentRealtimeVehicles(agendyId, includingNotOnTrip);
    return convertObjectNames(apiRes.data) as VehiclesNowDto;

}
