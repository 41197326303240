import './ScheduleAdjustmentStyles.css';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DateInput, DateInputProps } from 'semantic-ui-calendar-react';
import { Button, CheckboxProps, DropdownItemProps, DropdownProps, Form, Header, Message, Popup, Radio, Segment, Statistic } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getGtfsDirectionVariants } from '../../../actions/gtfsStaticActions';
import { getScheduledAdjustment } from '../../../actions/runningTimeAnalyticsActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { GtfsDirectionVariantModel } from '../../../types/gtfsTypes';
import { AdjustmentReport, WeekDaysSelectionType } from '../../../types/scheduleAdjustmentTypes';
import { DropDownStateType } from '../../../types/types';
import Utils from '../../../utilities/utils';
import { dateRangeToString as dateRangeToDisplayString } from '../../../utilities/view-utils';
import ScheduleAdjustmentTable from './ScheduleAdjustmentTable';

const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

interface DateRangeFieldProps {
    startDate: string;
    endDate: string;
    onDateRangeChanged: (lowerDate: string, upperDate: string) => void;
}

const DateRangeField: React.FC<DateRangeFieldProps> = (props) => {
    const [date1, setDate1] = React.useState(props.startDate);
    const [date2, setDate2] = React.useState(props.endDate);

    const handleDate1Change: DateInputProps['onChange'] = (_, { value }) => {
        setDate1(value);
        if (value > date2)
            setDate2(value);
    };
    const handleDate2Change: DateInputProps['onChange'] = (_, { value }) => {
        setDate2(value);
        if (value < date1)
            setDate1(value);
    };

    React.useEffect(() => {
        if (props.startDate !== date1 || props.endDate !== date2)
            props.onDateRangeChanged(date1, date2);
    }, [date1, date2]);

    return (
        <>
            <DateInput
                className="date"
                label="Start Date:"
                name="startDateCalendar"
                dateFormat="YYYY-MM-DD"
                placeholder="Select date"
                value={date1}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                onChange={handleDate1Change}
            />
            <DateInput
                className="date"
                label="End Date:"
                name="endDateCalendar"
                dateFormat="YYYY-MM-DD"
                placeholder="Select date"
                value={date2}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                onChange={handleDate2Change}
            />
        </>
    );
};

function daysNumberToString(daysNumber: number): string {
    return `${daysNumber} day${daysNumber !== 1 ? 's' : ''}`;
}

type WsdRadioProps = {
    dateRange: DateRangeType;
    value: WeekDaysSelectionType;
    selected: WeekDaysSelectionType;
    onChange: (value: WeekDaysSelectionType) => void;
};

const WsdRadio: React.FC<WsdRadioProps> = (props) => {
    const daysNumber = props.dateRange[props.value];
    const daysText = daysNumberToString(daysNumber);
    let labelText: string;
    switch (props.value) {
        case WeekDaysSelectionType.WeekEndDays:
            labelText = `Saturday-Sunday (${daysText})`;
            break;
        case WeekDaysSelectionType.BuisinessDays:
            labelText = `Monday-Friday (${daysText})`;
            break;
        default:
            labelText = `Any days of week (${daysText})`;
            break;
    }
    const handleWeekDaysChange = (_event: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
        props.onChange(value as WeekDaysSelectionType);
    };
    return (
        <Radio
            label={labelText}
            name="radioGroup"
            value={props.value}
            checked={props.value === props.selected}
            disabled={daysNumber === 0}
            onChange={handleWeekDaysChange}
        />);
};

class ReportData {
    public readonly titleString: string;
    public readonly subtitleString: string;
    constructor(
        public readonly dateRange: DateRangeType,
        public readonly wds: WeekDaysSelectionType,
        public readonly route: DropdownItemProps,
        public readonly direction: DropdownItemProps,
        public readonly data: AdjustmentReport,
    ) {
        this.wds = getActualWdsFromDateRange(this.dateRange, this.wds);

        const dates = dateRangeToDisplayString(this.dateRange.date1, this.dateRange.date2);
        this.titleString = `${dates} / ${this.route.text} / ${this.direction.text}`;

        const daysNumber = this.dateRange[this.wds];
        const daysText = daysNumberToString(daysNumber);
        switch (this.wds) {
            case WeekDaysSelectionType.BuisinessDays:
                this.subtitleString = `(Monday-Friday, ${daysText})`;
                break;
            case WeekDaysSelectionType.WeekEndDays:
                this.subtitleString = `(Satuday-Sunday, ${daysText})`;
                break;
            default:
                this.subtitleString = `(${daysText})`;
                break;
        }
    }
}

type DateRangeType = {
    date1: string;
    date2: string;
} & {
        [wdays in WeekDaysSelectionType]: number
    };

function prepareDateRange(date1: string, date2: string): DateRangeType {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    let totalServiceDates = 0, totalWeekEndDates = 0;
    for (let d = new Date(d1); d <= d2; d.setDate(d.getDate() + 1)) {
        ++totalServiceDates;
        if (((d.getDay() + 6) % 7) > 4)
            ++totalWeekEndDates;
    }
    return {
        date1,
        date2,
        [WeekDaysSelectionType.AnyWeekDays]: totalServiceDates,
        [WeekDaysSelectionType.WeekEndDays]: totalWeekEndDates,
        [WeekDaysSelectionType.BuisinessDays]: totalServiceDates - totalWeekEndDates,
    };
}

function initialDateRange(): DateRangeType {
    const date1 = moment(new Date().setMinutes(0, 0, 0)).add(-1, 'month').format('YYYY-MM-DD');
    const date2 = moment(new Date().setMinutes(0, 0, 0)).format('YYYY-MM-DD');
    return prepareDateRange(date1, date2);
}

function getActualWdsFromDateRange(dateRange: DateRangeType, selectedWds: WeekDaysSelectionType) {
    if (selectedWds !== WeekDaysSelectionType.AnyWeekDays) {
        if (dateRange[selectedWds] === dateRange[WeekDaysSelectionType.AnyWeekDays])
            return WeekDaysSelectionType.AnyWeekDays;
    }
    return selectedWds;
}

interface Props {
    agency: AgencyStateType | undefined;
}

const ScheduleAdjustmentForm: React.FC<Props> = ({ agency }) => {
    const [dateRangeState, setDateRangeState] = useState(initialDateRange());
    const [routesLoadingState, setRoutesLoadingState] = useState(false);
    const [routesState, setRoutesState] = useState(initialDropDownState);
    const [directionsState, setDirectionsState] = useState(initialDropDownState);
    const allDirectionsRef = React.useRef<GtfsDirectionVariantModel[]>([]);

    const [weekDaysSelection, setWeekDaysSelection] = useState<WeekDaysSelectionType>(WeekDaysSelectionType.AnyWeekDays);
    const [reportState, setReportState] = useState<ReportData | null>(null);
    const [formBlockingState, setFormBlockingState] = useState(false);

    const handleDateRangeChanged = (lowerDate: string, upperDate: string): void => {
        const newDateRange = prepareDateRange(lowerDate, upperDate);
        setDateRangeState(newDateRange);
        if (weekDaysSelection !== WeekDaysSelectionType.AnyWeekDays) {
            const resetRequired =
                weekDaysSelection === WeekDaysSelectionType.WeekEndDays && 0 === newDateRange[WeekDaysSelectionType.WeekEndDays] ||
                weekDaysSelection === WeekDaysSelectionType.BuisinessDays && 0 === newDateRange[WeekDaysSelectionType.BuisinessDays];
            if (resetRequired)
                setWeekDaysSelection(WeekDaysSelectionType.AnyWeekDays);
        }
    };
    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeName }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeName as string,
        }));
        if (allDirectionsRef && allDirectionsRef.current.length > 0) {
            const directionsForRoute = Utils.distinctValuesByKey(allDirectionsRef.current.filter(d => d.routeShortName === routeName), 'directionVariantName');
            setDirectionsState({
                options: directionsForRoute.map(d => ({ value: d.directionVariantInternalId, text: d.directionVariantName })),
                selectedValue: '',
            });
        } else {
            setDirectionsState(initialDropDownState);
        }
    };
    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantName }: DropdownProps) => {
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantName as string,
        }));
    };
    const updateRoutesAndDirections = async (dateRange: DateRangeType) => {
        setRoutesState(initialDropDownState);
        allDirectionsRef.current = [];
        if (!(agency?.id)) {
            return;
        }
        try {
            setRoutesLoadingState(true);
            setRoutesState(initialDropDownState);
            const directions = await getGtfsDirectionVariants(agency.id, dateRange.date1, dateRange.date2);
            const routes = [...new Set(directions.map(d => d.routeShortName))];
            setRoutesState(prevState => ({
                options: routes.map(r => ({ value: r, text: r })),
                selectedValue: prevState.selectedValue && routes.includes(prevState.selectedValue) ? prevState.selectedValue : '',
            }));
            if (routesState.selectedValue) {
                const directionsForRoute = Utils.distinctValuesByKey(directions.filter(d => d.routeShortName === routesState.selectedValue), 'directionVariantName');
                setDirectionsState({
                    options: directionsForRoute.map(d => ({ value: d.directionVariantName, text: d.directionVariantName })),
                    selectedValue: '',
                });
            }
            allDirectionsRef.current = directions;
        }
        finally {
            setRoutesLoadingState(false);
        }
    };

    useEffect(() => {
        updateRoutesAndDirections(dateRangeState);
    }, [agency?.id]);

    useEffect(() => {
        updateRoutesAndDirections(dateRangeState);
    }, [dateRangeState]);

    const isApplyDisabled = () => {
        if (!reportState) return false;
        if (reportState.dateRange.date1 !== dateRangeState.date1) return false;
        if (reportState.dateRange.date2 !== dateRangeState.date2) return false;
        const rWds = getActualWdsFromDateRange(reportState.dateRange, reportState.wds);
        const cWds = getActualWdsFromDateRange(dateRangeState, weekDaysSelection);
        if (rWds !== cWds) return false;
        if (reportState.route.value !== routesState.selectedValue && routesState.selectedValue) return false;
        if (reportState.direction.value !== directionsState.selectedValue && directionsState.selectedValue) return false;
        return true;
    };
    const handleApplyClick = async () => {
        if (!agency || !agency.id)
            return;
        const directionVariantId = directionsState.selectedValue;
        if (!directionVariantId)
            return;
        setFormBlockingState(true);
        setReportState(null);
        try {
            const wds = getActualWdsFromDateRange(dateRangeState, weekDaysSelection);
            const adjustmentReport = await getScheduledAdjustment({
                agencyId: agency.id,
                directionVariantId,
                startDate: dateRangeState.date1,
                endDate: dateRangeState.date2,
                weekDays: wds,
            });
            setReportState(new ReportData(dateRangeState, weekDaysSelection,
                routesState.options.find(e => e.value == routesState.selectedValue)!,
                directionsState.options.find(e => e.value == directionVariantId)!,
                adjustmentReport));
        }
        finally {
            setFormBlockingState(false);
        }
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <div className="schedule-adjustment">
                <Header as="h1" className="reportHeader">Schedule Adjustment</Header>
                <Segment vertical>
                    OTP (On Time Performance) is the percentage of vehicles arrivals at stops
                    no earlier than 1 minute and no later than 5 minutes of the scheduled time.
                    Often, OTP can be improved by adjusting the schedule (the actual vs proposed).
                </Segment>
                <Segment vertical>
                    <Form>
                        <Form.Group>
                            <DateRangeField
                                startDate={dateRangeState.date1}
                                endDate={dateRangeState.date2}
                                onDateRangeChanged={handleDateRangeChanged}
                            />
                            <Form.Field className="week-days">
                                <WsdRadio
                                    dateRange={dateRangeState}
                                    value={WeekDaysSelectionType.AnyWeekDays}
                                    selected={weekDaysSelection}
                                    onChange={setWeekDaysSelection}
                                />
                                <WsdRadio
                                    dateRange={dateRangeState}
                                    value={WeekDaysSelectionType.BuisinessDays}
                                    selected={weekDaysSelection}
                                    onChange={setWeekDaysSelection}
                                />
                                <WsdRadio
                                    dateRange={dateRangeState}
                                    value={WeekDaysSelectionType.WeekEndDays}
                                    selected={weekDaysSelection}
                                    onChange={setWeekDaysSelection}
                                />
                            </Form.Field>
                            <Form.Select
                                className="routes"
                                label="Route:"
                                loading={routesLoadingState}
                                placeholder={routesLoadingState ? 'Loading...' : 'Select route'}
                                search
                                selection
                                openOnFocus={false}
                                selectOnBlur={false}
                                options={routesState.options}
                                value={routesState.selectedValue}
                                onChange={handleRouteChange}
                            />
                            {!!routesState.selectedValue && <>
                                <Form.Select
                                    className="directions"
                                    label="Direction:"
                                    loading={routesLoadingState}
                                    placeholder="Select Direction"
                                    search
                                    selection
                                    openOnFocus={false}
                                    selectOnBlur={false}
                                    options={directionsState.options}
                                    value={directionsState.selectedValue}
                                    onChange={handleDirectionChange}
                                />
                                {!!directionsState.selectedValue &&
                                    <Form.Field className="command">
                                        <Button
                                            content="Apply"
                                            disabled={isApplyDisabled()}
                                            onClick={handleApplyClick}
                                            className="primaryButton"
                                        />
                                    </Form.Field>
                                }
                            </>
                            }
                        </Form.Group>
                    </Form>
                </Segment>
                {reportState !== null &&
                    <Segment vertical>
                        <Header as="h2">{reportState.titleString} <small>{reportState.subtitleString}</small></Header>
                        {!reportState.data.rows || reportState.data.rows.length === 0
                            ? <Message visible>no report data</Message>
                            : <Form>
                                {reportState.data?.actualOtp &&
                                    <Form.Group>
                                        <Popup
                                            content="A performance criteria of less than one minute early and less than five minutes late"
                                            trigger={
                                                <Statistic label="Actual OTP" value={reportState.data.actualOtp + '%'} />
                                            }
                                        />
                                        <Popup
                                            content="A performance criteria of less than one minute early and less than five minutes late"
                                            trigger={
                                                <Statistic className="proposedStatistic" label="Proposed OTP" value={reportState.data.proposedOtp + '%'} />
                                            }
                                        />
                                    </Form.Group>
                                }
                                <Form.Group>
                                    <p>The table below shows the average delays at stops for each trip (<span className="v-black">black</span>) and
                                        the adjustment time (<span className="v-blue">blue</span>) that should be added to the schedule to maximize OTP.</p>
                                    <ScheduleAdjustmentTable columnHeaders={reportState.data.columnHeaders} rows={reportState.data.rows} />
                                </Form.Group>
                            </Form>
                        }
                    </Segment>
                }
            </div>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(ScheduleAdjustmentForm);