import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WrongCoordinatesShape } from '../../../types/anomaliesTypes';

interface Props {
    row: WrongCoordinatesShape,
    selectedShapeId: string | undefined,
    selectedRowHandler: (selectedShapeId: string) => void;
}

const WrongCoordinatesShapesTableRow: React.FC<Props> = ({ row, selectedShapeId, selectedRowHandler }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row.id);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedShapeId !== undefined && selectedShapeId === row.id}>
            <Table.Cell textAlign="left">
                {row.routeId} - {row.otsTripShortName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.id}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.onShape}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShape}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShapePcnt}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.onShapeTrips}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShapeTrips}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShapePcntTrips.toFixed(0)}
            </Table.Cell>
        </Table.Row>
    );
};

export default WrongCoordinatesShapesTableRow;