import moment from 'moment';

function toSentenceCase(value: string): string {
    const firstLetterMatch = value.match(/\w/);
    if (!firstLetterMatch) return value;
    const firstLetter = firstLetterMatch[0];
    const i = value.indexOf(firstLetter);
    return value.slice(0, i) + (firstLetter.toUpperCase() + value.slice(i+1).toLowerCase()).replace(/\b[NS][EW]\b/gi, (match) => { return match.toUpperCase(); });
}

export function toTitleCase(value: string) {
    return value.split(/\b/).map(word => toSentenceCase(word)).join('');
}

function pad(number: number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export function toIsoDate(date: Date) {
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
}

export function toIsoDateTime(date: Date) {
    return `${toIsoDate(date)}Т${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

/** Converts date range to display string
 * 
 * @param lowerDateString lower date of range in ISO format '2021-01-23'
 * @param upperDateString upper date od range in ISO format '2021-01-23'
 */

export function dateRangeToString(lowerDateString: string, upperDateString: string): string {
    const dash = '\u2013';

    const mLower = moment(lowerDateString);
    const dLower = mLower.toDate();
    const mUpper = moment(upperDateString);
    const dUpper = mUpper.toDate();

    if (dLower.getFullYear() !== dUpper.getFullYear()) {
        return `${mLower.format('D MMM YYYY')} ${dash} ${mUpper.format('D MMM YYYY')}`;
    }
    if (dLower.getMonth() !== dUpper.getMonth()) {
        return `${mLower.format('D MMM')} ${dash} ${mUpper.format('D MMM')}`;
    }
    else if (dLower.getDate() !== dUpper.getDate()) {  
        return `${mLower.format('D')}${dash}${mUpper.format('D MMM')}`;
    }
    return mLower.format('D MMM');
}
