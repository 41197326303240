import { OperationResult } from '../types/apiTypes';
import { PredictedArrivalTimeReport, PredictedArrivalTimeReportRequest, PredictionAccuracyReportData, PredictionCompletenessData, PredictionsStatsForPeriodData } from '../types/delaysAnalysisTypes';
import { PredictionAccuracyValue } from '../types/predictionAccuracyTypes';
import { KeyValuePair } from '../types/types';
import api from './api';
import { buildUrlString, SearchParams } from './helpers';

class DelaysAnalysisApi {

    static doAuthGetRequestAsync = async <T>(url: string) => await api.doAuthGetRequestAsync<T>('/predictions/' + url);

    static async getPredictionArrivalTimeReport({ agencyId, serviceDate, tripId, modelName }: PredictedArrivalTimeReportRequest) {
        const queryParams: SearchParams = {
            businessDate: serviceDate,
            tripId,
            modelName,
        };
        const url = buildUrlString(queryParams, 'arrivals', agencyId);
        return await this.doAuthGetRequestAsync<OperationResult<PredictedArrivalTimeReport[]>>(url);
    }
    static async getPredictionAccuracyForRoute(agencyId: string, predictionDate: string, modelName: string, routeName?: string, directionVariantId?: string) {
        const queryParams: SearchParams = {
            predictionDate,
            routeName,
            directionVariantId,
            modelName,
        };
        const url = buildUrlString(queryParams, 'accuracy', 'chart', agencyId);
        return await this.doAuthGetRequestAsync<OperationResult<KeyValuePair<string, PredictionAccuracyValue>[]>>(url);
    }
    static async getPredictionAccuracyKpi(agencyId: string) {
        const url = buildUrlString(null, 'accuracy', 'kpi', agencyId);
        return await this.doAuthGetRequestAsync<OperationResult<PredictionAccuracyReportData>>(url);
    }
    static async getPredictionAccuracyKpiForCustomPeriod(agencyId: string, fromDate: string, toDate: string) {
        const queryParams: SearchParams = {
            fromDate,
            toDate,
        };
        const url = buildUrlString(queryParams, 'accuracy', 'kpiForPeriod', agencyId);
        return await this.doAuthGetRequestAsync<OperationResult<PredictionAccuracyReportData>>(url);
    }
    static async getRtVsScheduledPredictionsReport(agencyId: string) {
        const url = buildUrlString(null, 'coverage', 'overall', agencyId);
        return await this.doAuthGetRequestAsync<OperationResult<PredictionCompletenessData[]>>(url);
    }
    static async getPredictionsStatsForPeriod(agencyId: string, period: string, fromDate: string, toDate: string, routeName: string, directionVariantId: string) {
        const queryParams: SearchParams = {
            period,
            fromDate,
            toDate,
            routeName,
            directionVariantId,
        };
        const url = buildUrlString(queryParams, 'coverage', 'stats', agencyId);
        return await this.doAuthGetRequestAsync<OperationResult<PredictionsStatsForPeriodData[]>>(url);
    }
}

export default DelaysAnalysisApi;