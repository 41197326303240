import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DropdownProps, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getPredictionsStatsForPeriod, getRtVsScheduledPredictionsReport } from '../../../actions/delaysAnalysisActions';
import { getRoutes } from '../../../actions/gtfsStaticActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { PredictionCompletenessData, PredictionCompletenessDirection, PredictionCompletenessRoute, PredictionItems } from '../../../types/delaysAnalysisTypes';
import { DirectionVariantSimple, GtfsRoute } from '../../../types/gtfsTypes';
import { DropDownStateType } from '../../../types/types';
import Utils from '../../../utilities/utils';
import PredictionsAnalysisTable from './PredictionsAnalysisTable';
import PredictionsPercentageForPeriodChart from './PredictionsPercentageForPeriodChart';

const displayModeDropDownState: DropDownStateType = {
    options: [
        {
            value: 'Day',
            text: 'Days',
        },
        {
            value: 'Week',
            text: 'Weeks',
        },
        {
            value: 'Month',
            text: 'Months',
        },
        {
            value: 'Year',
            text: 'Years',
        },
    ],
    selectedValue: 'Week',
};

const predictionItemsInitialValue: PredictionItems = {
    short: {
        targetCount: 0,
        predictedCount: 0,
    },
    mid1: {
        targetCount: 0,
        predictedCount: 0,
    },
    mid2: {
        targetCount: 0,
        predictedCount: 0,
    },
    long: {
        targetCount: 0,
        predictedCount: 0,
    },
};

const styles = {
    headerSubtitle: {
        marginBottom: '30px',
    } as React.CSSProperties,
};

export interface PredictionsAnalysisData {
    key?: string;
    indicator: string;
    short: number;
    mid1: number;
    mid2: number;
    long: number;
}

interface Props {
    agency: AgencyStateType | undefined;
}

const PredictionCoverageForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [displayModeState, setDisplayModeState] = useState(displayModeDropDownState);
    const [predictionsState, setPredictionsState] = useState<PredictionCompletenessData[]>([]);
    const [routesState, setRoutesState] = useState<GtfsRoute[]>([]);
    const [overallChartDataState, setOverallChartDataState] = useState<PredictionsAnalysisData[]>([]);
    const [overallTableDataState, setOverallTableDataState] = useState<PredictionsAnalysisData[]>([]);
    const [routeChartDataState, setRouteChartDataState] = useState<PredictionsAnalysisData[]>([]);
    const [routeTableDataState, setRouteTableDataState] = useState<PredictionsAnalysisData[]>([]);
    const [directionChartDataState, setDirectionChartDataState] = useState<PredictionsAnalysisData[]>([]);
    const [stopsChartDataState, setStopsChartDataState] = useState<PredictionsAnalysisData[]>([]);
    const [selectedRouteNameState, setSelectedRouteNameState] = useState<string | undefined>(undefined);
    const [selectedDirectionVariantIdState, setSelectedDirectionVariantIdState] = useState<string | undefined>(undefined);
    const [directionNameState, setDirectionNameState] = useState<string | undefined>(undefined);


    const handleModeChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const displayMode = value as string;
        setDisplayModeState(prevState => ({
            ...prevState,
            selectedValue: displayMode,
        }));
        updateOverallData(predictionsState, displayMode);
        if (selectedRouteNameState)
            updateRouteData(selectedRouteNameState as string, displayMode);
        if (selectedDirectionVariantIdState && selectedRouteNameState) {
            updateDirectionVariantData(selectedDirectionVariantIdState as string, displayMode);
            await updateStopsChartData(selectedDirectionVariantIdState as string, displayMode);
        }
    };

    const handleRouteRowChange = (selectedRouteName: string) => {
        setSelectedRouteNameState(selectedRouteName);
        setDirectionChartDataState([]);
        setStopsChartDataState([]);
        setSelectedDirectionVariantIdState(undefined);
        setDirectionNameState(undefined);
        const displayMode = displayModeState.selectedValue as string;
        updateRouteData(selectedRouteName, displayMode);
    };

    const handleDirectionVariantIdChange = async (selectedDirectionVariantId: string) => {
        setSelectedDirectionVariantIdState(selectedDirectionVariantId);
        const directions = routesState.find(r => r.routeName === selectedRouteNameState as string)?.directionVariants || [];
        const directionName = getDirectionVariantName(selectedDirectionVariantId, directions);
        setDirectionNameState(directionName);
        const displayMode = displayModeState.selectedValue as string;
        updateDirectionVariantData(selectedDirectionVariantId, displayMode);
        await updateStopsChartData(selectedDirectionVariantId, displayMode);
    };

    const toPredictionsAnalysisData = (predictions: PredictionItems, indicator: string, key?: string): PredictionsAnalysisData => {
        return {
            key: key,
            indicator: indicator,
            short: Math.round(predictions.short.predictedCount / predictions.short.targetCount * 100 * 10) / 10,
            mid1: Math.round(predictions.mid1.predictedCount / predictions.mid1.targetCount * 100 * 10) / 10,
            mid2: Math.round(predictions.mid2.predictedCount / predictions.mid2.targetCount * 100 * 10) / 10,
            long: Math.round(predictions.long.predictedCount / predictions.long.targetCount * 100 * 10) / 10,
        };
    };

    const getDirectionVariantName = (directionVariantInternalId: string, directions: DirectionVariantSimple[]): string => {
        const direction = directions.find(d => d.internalDirectionVariantId === directionVariantInternalId);
        if (direction) {
            return direction.directionVariantName;
        } else {
            return directionVariantInternalId;
        }
    };

    const updateOverallData = (predictionsData: PredictionCompletenessData[], displayMode: string) => {
        const dataForPeriod = predictionsData
            .filter(d => d.period == displayMode);
        const overallChartData = dataForPeriod
            .map<PredictionsAnalysisData>(d => toPredictionsAnalysisData(d.predictions, getPeriodValue(d.startDate, d.endDate, displayMode)));
        setOverallChartDataState(overallChartData);
        const overallTableData = Object.entries(Utils.groupBy(dataForPeriod.flatMap(d => d.routes), r => r.route))
            .map(d => {
                const routeName = d[0];
                const predictionitems: PredictionItems = d[1].reduce((total: PredictionItems, routeData: PredictionCompletenessRoute) => ({
                    short: {
                        targetCount: routeData.predictions.short.targetCount + total.short.targetCount,
                        predictedCount: routeData.predictions.short.predictedCount + total.short.predictedCount,
                    },
                    mid1: {
                        targetCount: routeData.predictions.mid1.targetCount + total.mid1.targetCount,
                        predictedCount: routeData.predictions.mid1.predictedCount + total.mid1.predictedCount,
                    },
                    mid2: {
                        targetCount: routeData.predictions.mid2.targetCount + total.mid2.targetCount,
                        predictedCount: routeData.predictions.mid2.predictedCount + total.mid2.predictedCount,
                    },
                    long: {
                        targetCount: routeData.predictions.long.targetCount + total.long.targetCount,
                        predictedCount: routeData.predictions.long.predictedCount + total.long.predictedCount,
                    },
                }), predictionItemsInitialValue);
                return toPredictionsAnalysisData(predictionitems, routeName, routeName);
            }).sort((a, b) => {
                if (a.indicator > b.indicator) {
                    return 1;
                } else if (a.indicator < b.indicator) {
                    return -1;
                }
                return 0;
            });
        setOverallTableDataState(overallTableData);
    };

    const updateRouteData = (selectedRouteName: string, displayMode: string) => {
        const dataForPeriod = predictionsState
            .filter(d => d.period == displayMode);
        const routeChartData: PredictionsAnalysisData[] = [];
        for (const data of dataForPeriod) {
            const periodValue = getPeriodValue(data.startDate, data.endDate, displayMode);
            const routeData = data.routes.find(r => r.route === selectedRouteName);
            if (routeData) {
                const chartData: PredictionsAnalysisData = toPredictionsAnalysisData(routeData.predictions, periodValue);
                routeChartData.push(chartData);
            }
        }
        setRouteChartDataState(routeChartData);
        const directions = routesState.find(r => r.routeName === selectedRouteName)?.directionVariants || [];
        const routeTableData = Object.entries(Utils.groupBy(dataForPeriod.flatMap(d => d.routes).filter(d => d.route === selectedRouteName).flatMap(d => d.directions), r => r.directionVariantInternalId))
            .map(d => {
                const directionVariantInternalId = d[0];
                const directionName = getDirectionVariantName(directionVariantInternalId, directions);
                const predictionitems: PredictionItems = d[1].reduce((total: PredictionItems, directionData: PredictionCompletenessDirection) => ({
                    short: {
                        targetCount: directionData.predictions.short.targetCount + total.short.targetCount,
                        predictedCount: directionData.predictions.short.predictedCount + total.short.predictedCount,
                    },
                    mid1: {
                        targetCount: directionData.predictions.mid1.targetCount + total.mid1.targetCount,
                        predictedCount: directionData.predictions.mid1.predictedCount + total.mid1.predictedCount,
                    },
                    mid2: {
                        targetCount: directionData.predictions.mid2.targetCount + total.mid2.targetCount,
                        predictedCount: directionData.predictions.mid2.predictedCount + total.mid2.predictedCount,
                    },
                    long: {
                        targetCount: directionData.predictions.long.targetCount + total.long.targetCount,
                        predictedCount: directionData.predictions.long.predictedCount + total.long.predictedCount,
                    },
                }), predictionItemsInitialValue);
                return toPredictionsAnalysisData(predictionitems, directionName, directionVariantInternalId);
            });
        setRouteTableDataState(routeTableData);
    };

    const updateDirectionVariantData = (selectedDirectionVariantId: string, displayMode: string) => {
        const dataForPeriod = predictionsState
            .filter(d => d.period == displayMode);
        const directionChartData: PredictionsAnalysisData[] = [];
        for (const data of dataForPeriod) {
            const periodValue = getPeriodValue(data.startDate, data.endDate, displayMode);
            const directionData = data.routes.find(r => r.route === selectedRouteNameState)?.directions.find(d => d.directionVariantInternalId === selectedDirectionVariantId);
            if (directionData) {
                const chartData: PredictionsAnalysisData = toPredictionsAnalysisData(directionData.predictions, periodValue);
                directionChartData.push(chartData);
            }
        }
        setDirectionChartDataState(directionChartData);
    };

    const updateStopsChartData = async (selectedDirectionVariantId: string, displayMode: string) => {
        if (agency && agency.id) {
            const dataForPeriod = predictionsState
                .filter(d => d.period == displayMode);
            const fromDate = moment(new Date(Math.min(...dataForPeriod.map(d => new Date(d.startDate).getTime())))).format('YYYY-MM-DD');
            const toDate = moment(new Date(Math.max(...dataForPeriod.map(d => new Date(d.endDate).getTime())))).format('YYYY-MM-DD');
            setFormBlockingState(true);
            try {
                const predictionsStats = await getPredictionsStatsForPeriod(agency.id, displayMode, fromDate, toDate, selectedRouteNameState as string, selectedDirectionVariantId);
                const stopsChartData: PredictionsAnalysisData[] = Object.entries(Utils.groupBy(predictionsStats, r => r.stopSequence))
                    .map(d => {
                        const stopSequence = d[0];
                        const shortStats = d[1].find(stats => stats.rangeCat === 0);
                        const mid1Stats = d[1].find(stats => stats.rangeCat === 1);
                        const mid2Stats = d[1].find(stats => stats.rangeCat === 2);
                        const longStats = d[1].find(stats => stats.rangeCat === 3);
                        return {
                            key: stopSequence,
                            indicator: stopSequence,
                            short: shortStats?.targetCount && shortStats?.predictedCount ? Math.round(shortStats.predictedCount / shortStats.targetCount * 100 * 10) / 10 : 0,
                            mid1: mid1Stats?.targetCount && mid1Stats?.predictedCount ? Math.round(mid1Stats.predictedCount / mid1Stats.targetCount * 100 * 10) / 10 : 0,
                            mid2: mid2Stats?.targetCount && mid2Stats?.predictedCount ? Math.round(mid2Stats.predictedCount / mid2Stats.targetCount * 100 * 10) / 10 : 0,
                            long: longStats?.targetCount && longStats?.predictedCount ? Math.round(longStats.predictedCount / longStats.targetCount * 100 * 10) / 10 : 0,
                        };
                    }).sort((a, b) => Number(a.key) > Number(b.key) ? 1 : -1);
                setStopsChartDataState(stopsChartData);
            } catch {
                setStopsChartDataState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setStopsChartDataState([]);
        }
    };

    const retrieveReportData = async () => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const predictionsData = await getRtVsScheduledPredictionsReport(agency.id);
                setPredictionsState(predictionsData);
                const displayMode = displayModeState.selectedValue as string;
                updateOverallData(predictionsData, displayMode);
            } catch {
                setPredictionsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setPredictionsState([]);
        }
    };

    const getPeriodValue = (startDate: Date, endDate: Date, selectedMode: string): string => {
        switch (selectedMode) {
            case 'Day':
                return moment(startDate).format('MMM DD, YYYY');
            case 'Week':
                return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`;
            case 'Month':
                return moment(startDate).format('MMM, YYYY');
            case 'Year':
                return moment(startDate).format('YYYY');
            default:
                return '';
        }
    };

    const clearStates = () => {
        setRoutesState([]);
        setPredictionsState([]);
        setOverallTableDataState([]);
        setOverallChartDataState([]);
        setRouteTableDataState([]);
        setRouteChartDataState([]);
        setRouteTableDataState([]);
        setDirectionChartDataState([]);
        setStopsChartDataState([]);
        setSelectedRouteNameState(undefined);
        setSelectedDirectionVariantIdState(undefined);
        setDirectionNameState(undefined);
    };

    useEffect(() => {
        clearStates();
        if (agency === undefined) return;
        const fetchRoutes = async () => {
            const date = moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD');
            try {
                setFormBlockingState(true);
                const routes = await getRoutes(agency.id, date);
                setRoutesState(routes);
            } catch {
                setRoutesState([]);
            } finally {
                setFormBlockingState(false);
            }
            await retrieveReportData();
        };
        fetchRoutes();
    }, [agency?.id]);


    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Prediction Coverage
                </Header>
                <div style={styles.headerSubtitle}>
                    The report shows the coverage of the forecast. The coverage is measured as a percentage and indicates the proportion of forecasts made based on the real vehicle positions compared to the forecasts based on the schedule solely. 100% is an ideal situation. Coverage is given in 4 ranges: 0-3 minutes, 3-6 minutes, 6-12 minutes, and 12-30 minutes upon actual arrival.
                </div>
                <Form.Group className="inputGroup">
                    <Form.Select
                        placeholder="Choose display mode"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={displayModeState.options}
                        value={displayModeState.selectedValue}
                        onChange={handleModeChange}
                        width={3}
                    />
                </Form.Group>
                {predictionsState.length > 0 &&
                    <>
                        <Form.Group className="rowGroup">
                            {overallChartDataState.length > 0 && <Form.Field width={8}>
                                <label className="categoryHeader">All Routes Coverage Trends</label>
                                <PredictionsPercentageForPeriodChart chartData={overallChartDataState} />
                            </Form.Field>
                            }
                            {overallTableDataState.length > 0 && <Form.Field width={8}>
                                <label className="categoryHeader">
                                    <div>Average Coverage by Route</div>
                                    <div className="categorySubtitle">Click on a row to display details</div>
                                </label>
                                <PredictionsAnalysisTable indicatorCaption="Route" rows={overallTableDataState} selectedRow={selectedRouteNameState} selectedRowHandler={handleRouteRowChange} />
                            </Form.Field>
                            }
                        </Form.Group>
                        <Form.Group className="rowGroup">
                            {routeChartDataState.length > 0 && selectedRouteNameState && <Form.Field width={8}>
                                <label className="categoryHeader">Route {selectedRouteNameState} Coverage Trends</label>
                                <PredictionsPercentageForPeriodChart chartData={routeChartDataState} />
                            </Form.Field>
                            }
                            {routeTableDataState.length > 0 && <Form.Field width={8}>
                                <label className="categoryHeader">
                                    <div>Average Coverage by Direction</div>
                                    <div className="categorySubtitle">Click on a row to display details</div>
                                </label>
                                <PredictionsAnalysisTable indicatorCaption="Direction" rows={routeTableDataState} selectedRow={selectedDirectionVariantIdState} selectedRowHandler={handleDirectionVariantIdChange} />
                            </Form.Field>
                            }
                        </Form.Group>
                        <Form.Group className="rowGroup">
                            {directionChartDataState.length > 0 && directionNameState && < Form.Field width={8}>
                                <label className="categoryHeader">Direction {directionNameState} Coverage Trends</label>
                                <PredictionsPercentageForPeriodChart chartData={directionChartDataState} />
                            </Form.Field>
                            }
                            {stopsChartDataState.length > 0 && <Form.Field width={8}>
                                <label className="categoryHeader">Average Coverage by Stop Sequence</label>
                                <PredictionsPercentageForPeriodChart chartData={stopsChartDataState} showTooltipXLabel scaleXLabel="Stop Sequence #" />
                            </Form.Field>
                            }
                        </Form.Group>
                    </>
                }
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(PredictionCoverageForm);