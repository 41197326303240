import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../../types/chartTypes';
import { DeviationForPeriod } from '../../../../types/delayStatisticsTypes';
import Utils from '../../../../utilities/utils';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Average Deviation (min)',
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointBackgroundColor: '#42DCC6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'day',
                unitStepSize: 1,
            },
            ticks: {
                autoSkip: true,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                callback: function (value: string) {
                    return `${Utils.roundNumber((value as unknown as number) / 60, 1)} min`;
                },
                stepSize: 30,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return moment(tooltipItem[0].xLabel).format('YYYY-MM-DD');
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                return `Avg Deviation: ${Utils.convertSecondsToMinutes(tooltipItem.yLabel, 'm', 's')}`;
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
};

const DeviationHistoryChart: React.FC<{ chartData: DeviationForPeriod[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: chartData.sort((a, b) => moment(a.period).valueOf() - moment(b.period).valueOf()).map(d => {
                    return {
                        t: d.period,
                        y: d.deviation,
                    };
                }),
            }],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={200}
            options={options as unknown as ChartOptions}
        />
    );
};

export default DeviationHistoryChart;
