import moment from 'moment';
import { DelayStatisticsDispatchAction, DelayStatisticsState, DeviationByDayPart, DeviationForHour, DeviationForPeriod, KpiDeviationEntity, KpiRoutesDelayStatistics, RoutesDelayStatisticsTableData } from '../../../../types/delayStatisticsTypes';
import Utils from '../../../../utilities/utils';

export const delayStatisticsInitialState: DelayStatisticsState = {
    delaysByDays: [],
    delaysByDayPart: [],
    delaysByWeekDay: [],
    routesDelayStatistics: [],
    routesDelaysStatisticsForTable: [],
    delaysDistributionForRoute: undefined,
    delaysByTimeForRoute: [],
    delaysByWeekDayForRoute: [],
    selectedRouteName: undefined,
};

export default function delayStatisticsReducer(state: DelayStatisticsState, action: DelayStatisticsDispatchAction): DelayStatisticsState {
    switch (action.type) {
        case 'SET_OVERALL_STATISTICS': {
            const { overallStatistics } = action;
            if (!overallStatistics)
                return delayStatisticsInitialState;
            const { agencyOffset, deviationHistory, deviationByDayPart, routesDelayStatistics } = overallStatistics;
            return {
                delaysByDays: getDelaysByDays(deviationHistory),
                delaysByDayPart: getDelaysByDayPart(deviationByDayPart, agencyOffset),
                delaysByWeekDay: getDelaysByWeekDay(deviationHistory),
                routesDelayStatistics,
                routesDelaysStatisticsForTable: getRoutesDelaysStatisticsForTable(routesDelayStatistics),
                delaysDistributionForRoute: undefined,
                delaysByTimeForRoute: [],
                delaysByWeekDayForRoute: [],
                selectedRouteName: undefined,
            };
        }
        case 'SET_SELECTED_ROUTE': {
            const { selectedRouteName } = action;
            if (!selectedRouteName)
                return {
                    ...state,
                    selectedRouteName: undefined,
                    delaysDistributionForRoute: undefined,
                    delaysByTimeForRoute: [],
                    delaysByWeekDayForRoute: [],
                };
            return {
                ...state,
                selectedRouteName,
                delaysDistributionForRoute: state.routesDelayStatistics.find(r => r.routeShortName === selectedRouteName),
                delaysByTimeForRoute: [],
                delaysByWeekDayForRoute: [],
            };
        }
        case 'SET_ROUTE_STATISTICS': {
            const { routeStatistics } = action;
            if (!routeStatistics)
                return {
                    ...state,
                    delaysByTimeForRoute: [],
                    delaysByWeekDayForRoute: [],
                };
            const { agencyOffset, deviationHistory, deviationByDayPart } = routeStatistics;
            return {
                ...state,
                delaysByTimeForRoute: getDelaysByTimeForVehicle(deviationByDayPart, agencyOffset),
                delaysByWeekDayForRoute: getDelaysByWeekDay(deviationHistory),
            };
        }
    }
}

function getDelaysByDays(deviationHistory: KpiDeviationEntity[]): DeviationForPeriod[] {
    return deviationHistory.map(d => ({ period: moment(d.periodStart).format('YYYY-MM-DD'), deviation: d.totalArrivals ? d.sumDelaySec / d.totalArrivals : 0 }));
}
function getDelaysByDayPart(deviationByDayPart: KpiDeviationEntity[], agencyOffset: number): DeviationByDayPart[] {
    const delaysByDayPart: DeviationByDayPart[] = Object.entries(Utils.groupBy(deviationByDayPart, d => {
        const localHour = getLocalHourFromUtcHour(d.utcHour, agencyOffset);
        if (localHour >= 19 || localHour < 6)
            return 'Night';
        else if (localHour >= 6 && localHour < 9)
            return 'Morning';
        else if (localHour >= 9 && localHour < 17)
            return 'Day';
        else
            return 'Evening';
    }))
        .map(g => {
            const [dayPart, deviations] = g;
            let desc = '';
            let dayPartOrder = 0;
            if (dayPart === 'Night')
                desc = '7pm - 6am';
            else if (dayPart === 'Morning') {
                desc = '6am - 9am';
                dayPartOrder = 1;
            }
            else if (dayPart === 'Day') {
                desc = '9am - 5pm';
                dayPartOrder = 2;
            }
            else {
                desc = '5pm - 7pm';
                dayPartOrder = 3;
            }
            const sumDelaySec = deviations.reduce((sum: number, deviationEntity: KpiDeviationEntity) => deviationEntity.sumDelaySec + sum, 0);
            const totalArrivals = deviations.reduce((sum: number, deviationEntity: KpiDeviationEntity) => deviationEntity.totalArrivals + sum, 0);
            return {
                dayPart: dayPart,
                dayPartDesc: desc,
                dayPartOrder,
                deviation: totalArrivals ? sumDelaySec / totalArrivals : 0,
            };
        })
        .sort((a, b) => a.dayPartOrder - b.dayPartOrder);
    return delaysByDayPart;
}
function getDelaysByWeekDay(deviationHistory: KpiDeviationEntity[]): DeviationForPeriod[] {
    const delaysByWeekDay = Object.entries(Utils.groupBy(deviationHistory.sort((a, b) => moment(a.periodStart).weekday() - moment(b.periodStart).weekday()), d => moment(d.periodStart).format('ddd')))
        .map(g => {
            const [weekday, deviations] = g;
            const sumDelaySec = deviations.reduce((sum: number, deviationEntity: KpiDeviationEntity) => deviationEntity.sumDelaySec + sum, 0);
            const totalArrivals = deviations.reduce((sum: number, deviationEntity: KpiDeviationEntity) => deviationEntity.totalArrivals + sum, 0);
            return {
                period: weekday,
                deviation: totalArrivals ? sumDelaySec / totalArrivals : 0,
            };
        });
    return delaysByWeekDay;
}
function getRoutesDelaysStatisticsForTable(routesDelaysStatistics: KpiRoutesDelayStatistics[]): RoutesDelayStatisticsTableData[] {
    return routesDelaysStatistics.sort((a, b) => {
        if (a.routeShortName > b.routeShortName) {
            return 1;
        } else if (a.routeShortName < b.routeShortName) {
            return -1;
        }
        return 0;
    }).map(s => ({
        routeName: s.routeShortName,
        totalArrivals: s.totalArrivals,
        avgDelaySec: s.avgDelaySec,
        earlyPercentage: s.totalArrivals ? Math.round(s.earlyArrivals / s.totalArrivals * 100 * 10) / 10 : 0,
        onTimePercentage: s.totalArrivals ? Math.round(s.onTimeArrivals / s.totalArrivals * 100 * 10) / 10 : 0,
        latePercentage: s.totalArrivals ? Math.round(s.lateArrivals / s.totalArrivals * 100 * 10) / 10 : 0,
        veryLatePercentage: s.totalArrivals ? Math.round(s.veryLateArrivals / s.totalArrivals * 100 * 10) / 10 : 0,
    }));
}
function getDelaysByTimeForVehicle(deviationByDayPart: KpiDeviationEntity[], agencyOffset: number): DeviationForHour[] {
    return deviationByDayPart
        .sort((a, b) => a.utcHour - b.utcHour)
        .map(d => ({ hour: getLocalHourFromUtcHour(d.utcHour, agencyOffset), deviation: d.totalArrivals ? d.sumDelaySec / d.totalArrivals : 0 }));
}
function getLocalHourFromUtcHour(utcHour: number, agencyOffset: number): number {
    const sum = utcHour + agencyOffset;
    if (sum > 24)
        return 24 - sum;
    else if (sum < 0)
        return 24 + sum;
    else
        return sum;
}