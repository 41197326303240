import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import TimeRangeSlider, { TimeRangeValueType } from 'react-time-range-slider';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, DropdownProps, Form, Header, Label } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getRoutes } from '../../../actions/gtfsStaticActions';
import { getRunningTimesComparison } from '../../../actions/runningTimeAnalyticsActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { GtfsRoute } from '../../../types/gtfsTypes';
import { RunningTimesComparison } from '../../../types/runningTimeAnalyticsTypes';
import { DropDownStateType } from '../../../types/types';
import RunningTimesComparisonChart from './RunningTimesComparisonChart';


const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

const styles = {
    applyButtonField: {
        textAlign: 'right',
    } as React.CSSProperties,
};

interface Props {
    agency: AgencyStateType | undefined;
}

const RunningTimesComparisonForm: React.FC<Props> = ({ agency }) => {
    const [runningTimesComparisonState, setRunningTimesComparisonState] = useState<RunningTimesComparison | undefined>(undefined);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [startDate1State, setStartDate1State] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [startDate2State, setStartDate2State] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [timeRange1State, setTimeRange1State] = useState<TimeRangeValueType>({ start: '12:00 AM', end: '12:00 PM' });
    const [timeRange2State, setTimeRange2State] = useState<TimeRangeValueType>({ start: '12:01 PM', end: '11:59 PM' });
    const [routesState, setRoutesState] = useState(initialDropDownState);
    const [directionsState, setDirectionsState] = useState(initialDropDownState);
    const [timeLabel1State, setTimeLabel1State] = useState('');
    const [timeLabel2State, setTimeLabel2State] = useState('');
    const allRoutesRef = useRef<GtfsRoute[]>([]);

    useEffect(() => {
        if (agency) {
            const fetchRoutes = async () => {
                try {
                    const routes = await getRoutes(agency.id);
                    setRoutesState({
                        options: routes.map(r => ({ value: r.routeName, text: r.routeName })),
                        selectedValue: '',
                    });
                    allRoutesRef.current = routes;
                } catch {
                    setRoutesState(initialDropDownState);
                    allRoutesRef.current = [];
                }
            };
            fetchRoutes();
        } else {
            setRoutesState(initialDropDownState);
            allRoutesRef.current = [];
        }
    }, [agency?.id]);

    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeName }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeName as string,
        }));
        const directions = allRoutesRef?.current?.find(r => r.routeName === routeName)?.directionVariants;
        if (directions && directions.length > 0) {
            setDirectionsState({
                options: directions.map(d => ({ value: d.internalDirectionVariantId, text: d.directionVariantName })),
                selectedValue: '',
            });
        } else {
            setDirectionsState(initialDropDownState);
        }
    };
    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantInternalId }: DropdownProps) => {
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantInternalId as string,
        }));
    };
    const handleStartDate1Change = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setStartDate1State(value);
    };
    const handleStartDate2Change = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setStartDate2State(value);
    };
    const timeRange1Change = (time: TimeRangeValueType) => {
        setTimeRange1State(time);
    };
    const timeRange2Change = (time: TimeRangeValueType) => {
        setTimeRange2State(time);
    };
    const handleApplyClick = async () => {
        const directionVariantInternalId = directionsState.selectedValue;
        if (agency && agency.id && routesState.selectedValue && directionVariantInternalId) {
            const startDate1 = moment(startDate1State + ' ' + timeRange1State.start).format('YYYY-MM-DDTHH:mm:ss');
            const endDate1 = moment(startDate1State + ' ' + timeRange1State.end).format('YYYY-MM-DDTHH:mm:ss');
            const startDate2 = moment(startDate2State + ' ' + timeRange2State.start).format('YYYY-MM-DDTHH:mm:ss');
            const endDate2 = moment(startDate2State + ' ' + timeRange2State.end).format('YYYY-MM-DDTHH:mm:ss');
            setTimeLabel1State(startDate1State + ' ' + timeRange1State.start + ' - ' + timeRange1State.end);
            setTimeLabel2State(startDate2State + ' ' + timeRange2State.start + ' - ' + timeRange2State.end);
            setFormBlockingState(true);
            try {
                const runningTimesComparison = await getRunningTimesComparison({
                    agencyId: agency.id,
                    directionVariantInternalId,
                    startDate1,
                    endDate1,
                    startDate2,
                    endDate2,
                });
                setRunningTimesComparisonState(runningTimesComparison);
            } catch {
                setRunningTimesComparisonState(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setRunningTimesComparisonState(undefined);
        }
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Running Times Comparison
                </Header>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Select
                        placeholder="Select route"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesState.options}
                        value={routesState.selectedValue}
                        onChange={handleRouteChange}
                    />
                    <Form.Select
                        placeholder="Direction"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={directionsState.options}
                        value={directionsState.selectedValue}
                        onChange={handleDirectionChange}
                    />
                    <DateInput
                        name="startDate1Calendar"
                        fluid
                        dateFormat="YYYY-MM-DD"
                        placeholder="Select date"
                        value={startDate1State}
                        iconPosition="left"
                        popupPosition="bottom center"
                        closable={true}
                        animation="fade"
                        onChange={handleStartDate1Change}
                    />
                    <Form.Field>
                        <Label pointing="below" circular color="blue">{timeRange1State.start} - {timeRange1State.end}</Label>
                        <TimeRangeSlider
                            disabled={false}
                            format={12}
                            maxValue={'11:59 PM'}
                            minValue={'12:00 AM'}
                            name="timeRange1"
                            onChange={timeRange1Change}
                            step={30}
                            value={timeRange1State}
                        />
                    </Form.Field>
                    <DateInput
                        name="startDate2Calendar"
                        fluid
                        dateFormat="YYYY-MM-DD"
                        placeholder="Select date"
                        value={startDate2State}
                        iconPosition="left"
                        popupPosition="bottom center"
                        closable={true}
                        animation="fade"
                        onChange={handleStartDate2Change}
                    />
                    <Form.Field>
                        <Label pointing="below" circular color="green">{timeRange2State.start} - {timeRange2State.end}</Label>
                        <TimeRangeSlider
                            disabled={false}
                            format={12}
                            maxValue={'11:59 PM'}
                            minValue={'12:00 AM'}
                            name="timeRange2"
                            onChange={timeRange2Change}
                            step={30}
                            value={timeRange2State}
                        />
                    </Form.Field>
                    <Form.Field style={styles.applyButtonField}>
                        <Button
                            content="Apply"
                            onClick={handleApplyClick}
                            className="primaryButton"
                        />
                    </Form.Field>
                </Form.Group>
                {runningTimesComparisonState && <Form.Group className="rowGroup">
                    <Form.Field width={16}>
                        <label className="categoryHeader">
                            <div className="categorySubtitle">
                                Allows user to compare, side-by-side, observed run times of two different time rages for the same route.
                                Specific dates and time ranges and week day types ( Working day vs Weekend) can have drastic impact on observed run-times. Their analysis can help pinpoint certain stops and time periods on a route that could benefit from  schedule changes to improve run-times.
                            </div>
                        </label>
                        <RunningTimesComparisonChart
                            runningTimesComparisonData={runningTimesComparisonState}
                            line1Label={timeLabel1State}
                            line2Label={timeLabel2State}
                        />
                    </Form.Field>
                </Form.Group>}
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(RunningTimesComparisonForm);