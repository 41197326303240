import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import Utils from '../../utilities/utils';

interface Props {
    readonly label: string;
    readonly path: string | string[];
}

export type NavigationSecondLayerElementProps = React.PropsWithChildren<Props>;

export const NavigationSecondLayerElement: React.FunctionComponent<Props> = (
    { path, label },
) => (
    <Menu.Item key={label} as={NavLink} className="navigation-second-layer navigation-item" to={Utils.ensureIsArray(path)[0]}>
        {label}
    </Menu.Item>
);
