import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const PrettoSliderControl = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: (props: { noBalloon?: boolean }) => ({
        left: 'calc(-50% + 4px)',
        '& *': props.noBalloon ?
            {
                background: 'transparent',
                color: '#000',
            } :
            {},
    }),
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

export default PrettoSliderControl;