export type ReportColumn<TRow> = {
    header: string;
    getter: (row: TRow) => string | number | null | undefined;
};

export function toReportRow<TRow>(reportDef: ReportColumn<TRow>[], row: TRow): object {
    const reportRow: { [column: string]: string | number | null | undefined } = {};
    for (const colDef of reportDef) {
        const v = colDef.getter(row);
        if (v !== undefined)
            reportRow[colDef.header] = v;
    }
    return reportRow;
}