import { DropdownItemProps } from 'semantic-ui-react';
import { RtDelaysNow, RtDelaysNowInterval } from '../../../../types/delaysNowTypes';
import { GtfsDirectionVariantModel } from '../../../../types/gtfsTypes';
import { DropDownStateType } from '../../../../types/types';

export interface DropDownState<TValue> {
    options: DropdownItemProps[];
    selectedValue: TValue | undefined;
}

export interface RoutesDropDownStateType extends DropDownStateType {
    directions: GtfsDirectionVariantModel[],
}

export interface DelayDataForPeriod {
    period: string;
    min: number | null;
    avg: number | null;
    max: number | null;
}

export interface DeviationDistribution {
    period: string,
    early: number | null;
    onTime: number | null;
    late: number | null;
    veryLate: number | null;
}

export enum DelaySort {
    Early = 0,    
    OnTime = 10,    
    Late = 20,
    VeryLate = 30,
}

export type DelayStatusColors = { [sort in DelaySort]: string };

export type DelayColorSet = {
    base: string;
    lite: string;
    dark: string;
    fill: string;
};

export interface DelaysTimePoint {
    time: string;
    data: RtDelaysNow[];
}

export const delayStatusColors: { [sort in DelaySort]: DelayColorSet } = {
    [DelaySort.Early]: {
        base: 'rgb(130, 225, 128)',
        lite: 'rgb(130, 225, 128)',
        dark: 'rgb(55, 208, 53)',
        fill: 'rgba(130, 225, 128, 0.5)',
    },
    [DelaySort.OnTime]: {
        base: 'rgb(49, 220, 195)',
        lite: 'rgb(49, 220, 195)',
        dark: 'rgb(32, 188, 164)',
        fill: 'rgba(49, 220, 195, 0.5)',
    },
    [DelaySort.Late]: {
        base: 'rgb(255, 192, 97)',
        lite: 'rgb(255, 192, 97)',
        dark: 'rgb(251, 160, 14)',
        fill: 'rgba(255, 192, 97, 0.5)',
    },
    [DelaySort.VeryLate]: {
        base: 'rgb(255, 131, 104)',
        lite: 'rgb(255, 131, 104)',
        dark: 'rgb(248, 60, 18)',
        fill: 'rgba(255, 131, 104, 0.5)',
    },
};

export function getDelaySort(delaySeconds: number): DelaySort {
    if (delaySeconds < -60)
        return DelaySort.Early;
    if (delaySeconds < 4 * 60)
        return DelaySort.OnTime;
    if (delaySeconds < 10 * 60)
        return DelaySort.Late;
    else
        return DelaySort.VeryLate;
}

export type DelayModeType = {
    intervalDescription: string;
    period: string;
};

export const delayModeTypes: { [key in RtDelaysNowInterval]: DelayModeType } = {
    [RtDelaysNowInterval.m05]: {
        intervalDescription: '5 min',
        period: 'hour',
    },
    [RtDelaysNowInterval.m10]: {
        intervalDescription: '10 min',
        period: '2 hours',
    },
    [RtDelaysNowInterval.m30]: {
        intervalDescription: '30 min',
        period: '6 hours',
    },
    [RtDelaysNowInterval.m60]: {
        intervalDescription: '1 hour',
        period: 'service day',
    },
};
