import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { PredictionsAnalysisData } from './PredictionCoverageForm';
import PredictionsAnalysisTableRow from './PredictionsAnalysisTableRow';

const styles = {
    table: {
        display: 'block',
        maxHeight: '324px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    indicatorCaption: string;
    rows: PredictionsAnalysisData[];
    selectedRow: string | undefined;
    selectedRowHandler: (selectedIndicator: string) => void
}

const PredictionsAnalysisTable: React.FC<Props> = ({ indicatorCaption, rows, selectedRow, selectedRowHandler }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>{indicatorCaption}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>00 - 03 min, %</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>03 - 06 min, %</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>06 - 12 min, %</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>12 - 30 min, %</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <PredictionsAnalysisTableRow key={index} row={row} selectedRow={selectedRow} selectedRowHandler={selectedRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default PredictionsAnalysisTable;