import { PushpinProps } from '../types/BingMapProps';

/**
 * A wrapper around a `Microsoft.Maps.Map` that gives controlled
 * access to certain methods that are important for getting info
 * out of the map. Does not expose any direct methods of editing
 * the map.
 */
class BingMapQuerier {
    constructor(public map: Microsoft.Maps.Map) {}

    public tryLocationToPixel(
        [lat, lon]: CoordinatePair,
        pixelReference = Microsoft.Maps.PixelReference.control,
    ): Point | null {
        const point = this.map.tryLocationToPixel(
            new Microsoft.Maps.Location(lat, lon),
            pixelReference,
        ) as Microsoft.Maps.Point || null;
        if (point === null) return null;
        const { x, y } = point;
        return [x as Pixels, y as Pixels];
    }

    public tryPixelToLocation(
        [x, y]: Point,
        pixelReference = Microsoft.Maps.PixelReference.control,
    ): CoordinatePair | null {
        const location = this.map.tryPixelToLocation(
            new Microsoft.Maps.Point(x, y),
            pixelReference,
        ) as Microsoft.Maps.Location | null;
        if (location === null) return null;
        const { latitude, longitude } = location;
        return [latitude as Latitude, longitude as Longitude];
    }


    private existingPushpins: {
        pushpin: Microsoft.Maps.Pushpin;
        handlerIds: Microsoft.Maps.IHandlerId[];
    }[] = [];

    public updatePushpins(pushpinProps: PushpinProps[]) {
        if (this.existingPushpins) {
            this.existingPushpins.forEach(({ pushpin, handlerIds }) => {
                handlerIds.forEach(handlerId =>
                    Microsoft.Maps.Events.removeHandler(handlerId),
                );
                this.map.entities.remove(pushpin);
            });
            this.existingPushpins = [];
        }
        const pushpins = pushpinProps
            .map(({ location: [lat, lon], options, eventHandlers }) => {
                const pushpin = new Microsoft.Maps.Pushpin(
                    new Microsoft.Maps.Location(lat, lon),
                    options,
                );

                const handlerIds = eventHandlers?.map(({ event, callback }) =>
                    Microsoft.Maps.Events.addHandler(pushpin, event, callback),
                ) || [];

                return { pushpin, handlerIds };
            });
        this.map.entities.push(pushpins.map(({ pushpin }) => pushpin));
        this.existingPushpins.push(...pushpins);
    }

    public removeBusPushpins() {
        this.map.entities.getPrimitives().filter(p => (p as unknown as { catId: string }).catId === 'bus-pushpin').forEach(pushpin => {
            this.map.entities.remove(pushpin);
        });
    }
}

export default BingMapQuerier;
