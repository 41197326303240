import { ChartData, ChartOptions } from 'chart.js';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../types/chartTypes';
import { HourlyReportData, ModelType, modelTypeColors } from './_data';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Adaptive',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.HISTORY].fill,
            borderColor: modelTypeColors[ModelType.HISTORY].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.HISTORY].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Linear',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.LINEAR].fill,
            borderColor: modelTypeColors[ModelType.LINEAR].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.LINEAR].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Legacy',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.SIRI].fill,
            borderColor: modelTypeColors[ModelType.SIRI].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.SIRI].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                stepSize: 1,
            },
            scaleLabel: {
                display: true,
                labelString: 'Time',
            },
            distribution: 'linear',
        }],
        yAxes: [{
            scaleLabel: {
                display: true,
                labelString: 'Accuracy, %',
            },
            ticks: {
                beginAtZero: true,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: 'rgba(65,105,225,1)',
                        backgroundColor: 'rgba(65,105,225,1)',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        borderColor: 'rgb(255, 165, 0)',
                        backgroundColor: 'rgb(255, 165, 0)',
                    };
                } else {
                    return {
                        borderColor: 'rgb(60, 179, 113)',
                        backgroundColor: 'rgb(60, 179, 113)',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        crosshair: {
            line: {
                color: '#F66',  // crosshair line color
                width: 1,       // crosshair line width
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: true,                                      // enable zooming
                zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
                zoomboxBorderColor: '#48F',                         // border color of zoom box
                zoomButtonText: 'RESET ZOOM',                       // reset zoom button text
                zoomButtonClass: 'ui button grey mini reset-zoom',      // reset zoom button class
            },
            snap: {
                enabled: true,
            },
        },
    },
};

type DataPoint = { dateTime: Moment } & { [key in ModelType]?: number | undefined };

type AccuracyByTimeProps = {
    data: HourlyReportData
};

function processModelData(
    dataPoints: DataPoint[],
    labelMap: { [hour: string]: DataPoint },
    reportData: HourlyReportData,
    model: ModelType,
): void {
    const data = reportData[model];
    if (!data) return;

    for (const n in data) {
        const value = data[n];
        let dataPoint: DataPoint = labelMap[n];
        if (!dataPoint) {
            const hours = parseInt(n);
            dataPoint = { dateTime: moment().add(hours, 'h') };
            labelMap[n] = dataPoint;
            dataPoints.push(dataPoint);
        }
        dataPoint[model] = value || undefined;
    }
}

function mapDataPoints(dataPoints: DataPoint[], model: ModelType): LineChartDatePoint[] {
    return dataPoints.map(p => ({ t: p.dateTime, y: p[model] || null }));
}

const AccuracyByTime: React.FC<AccuracyByTimeProps> = (props) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const labelMap: { [hour: string]: DataPoint } = {};
        const dataPoints: DataPoint[] = [];

        processModelData(dataPoints, labelMap, props.data, ModelType.HISTORY);
        processModelData(dataPoints, labelMap, props.data, ModelType.LINEAR);
        processModelData(dataPoints, labelMap, props.data, ModelType.SIRI);

        dataPoints.sort((a, b) => a.dateTime.valueOf() - b.dateTime.valueOf());
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: mapDataPoints(dataPoints, ModelType.HISTORY),
            }, {
                ...prevState.datasets[1],
                data: mapDataPoints(dataPoints, ModelType.LINEAR),
            }, {
                ...prevState.datasets[2],
                data: mapDataPoints(dataPoints, ModelType.SIRI),
            }],
        }));
    }, [props]);

    return (
        <div className="chart-wrapper">
            <Line
                data={dataState as ChartData}
                options={options as unknown as ChartOptions}
                height={70}
            />
        </div>
    );
};

export default AccuracyByTime;
