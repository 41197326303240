import { VehiclesNowDto } from '../components/live-map/_dto';
import { OperationResult } from '../types/apiTypes';
import Api from './api';
import { buildUrlString, SearchParams } from './helpers';

async function apiGet<T>(url: string): Promise<T> {
    return await Api.doAuthGetRequestAsync<T>(url);
}

export async function getCurrentVehiclesOnDirections(directionsIds: string, includingScheduled = false): Promise<OperationResult<VehiclesNowDto>> {
    const queryParams: SearchParams = {
        directionsIds: directionsIds,
        includingScheduled: (!!includingScheduled).toString(),
        //traditional: true
    };
    queryParams.format = 'json';
    queryParams.traditional = 'true';
    const url = buildUrlString(queryParams, 'LiveMap', 'CurrentVehiclesOnDirections');
    return await apiGet<OperationResult<VehiclesNowDto>>(url);
}

export async function getCurrentRealtimeVehicles(agencyId: string, includingNotOnTrip = false): Promise<OperationResult<VehiclesNowDto>> {
    const queryParams: SearchParams = {
        includingNotOnTrip: (!!includingNotOnTrip).toString(),
    };
    queryParams.format = 'json';
    const url = buildUrlString(queryParams, 'LiveMap', 'CurrentRealtimeVehicles', agencyId);
    return await apiGet<OperationResult<VehiclesNowDto>>(url);
}
