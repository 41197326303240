import moment from 'moment';
import React from 'react';
import busStopHoverIcon from '../../static/bus-stop-20-hover.png';
import busStopIcon from '../../static/bus-stop-20.png';
import { DropDownStateType } from '../../types/types';
import { RouteModel } from '../../types/view-models';
import { Dictionary, IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';
import { AgencyModel } from '../bushistory/_models';
import { loadDirectionVariantStops, loadRoutes, loadStopDwellTimes } from './_apiActions';
import { DirectionAvgSpeeds, StopDwellTimeDto } from './_dto';
import { loadSpeedMapData } from './_loadData';
import { buildRoute, DirectionCallback, DirectionDetSpeedsSet, RouteVariantEntry, RouteVariantKey, SpeedRangePolyline, SpeedRangeSet, StopInfo, timeApiToUi, TimePeriodKey, timeUiToApi } from './_models';
import { StopsDetailsLevel } from './_stops-details-level';
import { mapZoomLevelToTreckDetailLevel, TreckDetailsLevel } from './_treck-details-level';

function mapZoomLevelToStopsDetailsLevel(mapZoomLevel: number): StopsDetailsLevel {
    if (mapZoomLevel <= 13) return StopsDetailsLevel.L0;
    return StopsDetailsLevel.L1;
}

/*-- actions --*/

enum ActionType {
    DEFAULT,

    SET_MAP_READY = 'SET_MAP_READY',
    SET_AGENCY = 'SET_AGENCY',
    SET_SPEED_RANGES = 'SET_SPEED_RANGES',
    SET_TRECK_DETAILS_LEVEL = 'SET_TRECK_DETAILS_LEVEL',
    SET_STOPS_DETAILS_LEVEL = 'SET_STOPS_DETAILS_LEVEL',

    SET_IS_BUSY = 'SET_IS_BUSY',
    SET_ROUTES_LIST = 'SET_ROUTES_LIST',
    SET_LOWER_BOUND_DATE = 'SET_LOWER_BOUND_DATE',
    SET_UPPER_BOUND_DATE = 'SET_UPPER_BOUND_DATE',
    SET_LOWER_BOUND_TIME = 'SET_LOWER_BOUND_TIME',
    SET_UPPER_BOUND_TIME = 'SET_UPPER_BOUND_TIME',
    SET_EXCLUDE_WEKENDS = 'SET_EXCLUDE_WEKENDS',
    SET_EXCLUDE_STOPS = 'SET_EXCLUDE_STOPS',
    SET_STOPS_STATE = 'SET_STOPS_STATE',
    SET_SELECTED_DIRECTION_STATE = 'SET_SELECTED_DIRECTION_STATE',
    SET_SELECTED_ROUTE_STATE = 'SET_SELECTED_ROUTE_STATE',
    SET_PERIODS_STATE = 'SET_PERIODS_STATE',
    SET_SHOW_COMPARE_PERIODS_STATE = 'SET_SHOW_COMPARE_PERIODS_STATE',
    SET_DATA_PERIODS_NUMBER_STATE = 'SET_DATA_PERIODS_NUMBER_STATE',
}

/*-- types --*/

interface SpeedMapRequestParams {
    inputDates: InputDates[];
    excludeWeekends: boolean;
    excludeStops: boolean;
    periodsState: DropDownStateType;
    showComparePeriods: boolean;
}

interface InputDates {
    id: number;
    lowerBoundDate: string;
    upperBoundDate: string;
    lowerBoundTime: string;
    upperBoundTime: string;
}

interface StopsState {
    items: StopInfo[] | null;
    selected: StopInfo | null;
}

interface SpeedMapStateType extends SpeedMapRequestParams {
    helper: PublicInstanceHelper;

    mapReady: boolean;
    agency: AgencyModel | null;
    speedRangeSet: SpeedRangeSet;
    treckDetailsLevel?: TreckDetailsLevel;
    stopsDetailsLevel?: StopsDetailsLevel;

    isBusy: boolean;
    routesList: RouteModel[] | null;

    stops: StopsState;
    selectedDirection: IDirectionModel | null;
    selectedRoute: IRouteModel | null;
    dataPeriodsNumber: number;
}

const initialState: SpeedMapStateType = {
    helper: (undefined as unknown) as PublicInstanceHelper,

    mapReady: false,
    agency: null,
    speedRangeSet: SpeedRangeSet.Default,

    isBusy: false,
    routesList: null,

    inputDates: [],
    excludeWeekends: false,
    excludeStops: false,

    stops: {
        items: null,
        selected: null,
    },
    selectedDirection: null,
    selectedRoute: null,
    periodsState: {
        options: [
            {
                value: '1',
                text: '',
            },
            {
                value: '2',
                text: '',
            }],
        selectedValue: '1',
    },
    showComparePeriods: false,
    dataPeriodsNumber: 0,
};

export interface SpeedMapContextType extends SpeedMapStateType {
    onMapReady: (div: HTMLDivElement) => void;
    setAgency: (agency: AgencyModel | null) => void,
    getSelectedRouteVariants: () => RouteVariantEntry[],
    selectRouteDirection: (route: IRouteModel, direction: IDirectionModel) => void,

    setLowerBoundDate(value: string, id: number): boolean;
    setUpperBoundDate(value: string, id: number): boolean;
    setLowerBoundTime(value: string, id: number): boolean;
    setUpperBoundTime(value: string, id: number): boolean;
    setExcludeWekends(value: boolean): boolean;
    setExcludeStops(value: boolean): Promise<boolean>;
    setSelectedPeriod(value: string): void;
    setShowComparePeriods(value: boolean): void;
    canLoadNewData: () => boolean;
    handleGoClick: () => Promise<void>;
    updateSpeedRanges: () => void;
    directionHasData(routeName: string, routeId: string, cardinalDirection: string): number;
}

interface PublicInstanceHelper {
    dispatch: React.Dispatch<SpeedMapAction>;
}

interface InternalInstanceHelper {
    dispatch: React.Dispatch<SpeedMapAction>;

    selectedRouteVariants: RouteVariantEntry[];

    setLowerBoundDate(state: SpeedMapStateType, value: string, id: number): boolean;
    setUpperBoundDate(state: SpeedMapStateType, value: string, id: number): boolean;
    setLowerBoundTime(state: SpeedMapStateType, value: string, id: number): boolean;
    setUpperBoundTime(state: SpeedMapStateType, value: string, id: number): boolean;
    setExcludeWekends(state: SpeedMapStateType, value: boolean): boolean;
    setExcludeStops(state: SpeedMapStateType, value: boolean): Promise<boolean>;
    setSelectedPeriod(state: SpeedMapStateType, value: string): void;
    setShowComparePeriods(state: SpeedMapStateType, value: boolean): void;

    canLoadNewData(state: SpeedMapStateType): boolean;

    onMapReady(div: HTMLDivElement): void;
    doSetAgency(state: SpeedMapStateType, agency: AgencyModel | null): void;
    doSetupMap(state: SpeedMapStateType): void;
    doReloadSpeedRange(): void;
    onSpeedRangesChanged(state: SpeedMapStateType): void;

    doSelectRouteDirection(state: SpeedMapStateType, route: IRouteModel, direction: IDirectionModel, excludeWeekends: boolean, excludeStops: boolean, setId: number, focusOnRoute: boolean): Promise<void>;

    directionHasData(state: SpeedMapStateType, routeName: string, routeId: string, cardinalDirection: string): number;
    doSetSelectedDirection(state: SpeedMapStateType, direction: IDirectionModel | null): void;
    doSetSelectedRoute(state: SpeedMapStateType, route: IRouteModel | null): void;
    handleGoClick(state: SpeedMapStateType): Promise<void>;
}

/*-- internal helper --*/

class InstanceHelper implements PublicInstanceHelper, InternalInstanceHelper {
    static nextUid = 0;

    public readonly uid: number;

    public dispatch: React.Dispatch<SpeedMapAction>;

    public routeVariants: Dictionary<RouteVariantEntry> = {};
    public selectedRouteVariants: RouteVariantEntry[];
    private reloadData = false;
    private directionChanged = false;

    constructor() {
        this.uid = ++InstanceHelper.nextUid;

        this.dispatch = (undefined as unknown) as React.Dispatch<SpeedMapAction>;
        this.selectedRouteVariants = [];

        this._mapZoomLevel = 12;
        this._stopsDetailsLevel = StopsDetailsLevel.L0;
        this._treckDetailsLevel = TreckDetailsLevel.L0;
    }

    private _agencyId: string | null = initialState.agency?.id || null;
    private _isBusy: boolean = initialState.isBusy;

    private setBusy(value: boolean): boolean {
        if (this._isBusy === value) return false;
        this._isBusy = value;
        this.dispatch({ type: ActionType.SET_IS_BUSY, payload: value });
        return true;
    }

    private _mapReady = initialState.mapReady;
    public _mapDiv?: HTMLDivElement;
    public _map?: Microsoft.Maps.Map;
    private _mapZoomLevel: number;
    private _stopsDetailsLevel: StopsDetailsLevel;
    private _treckDetailsLevel: TreckDetailsLevel;
    private _selectedTreck?: RouteVariantEntry;

    public onMapReady(div: HTMLDivElement): void {
        this._mapReady = !!div;
        this._mapDiv = div;

        this.dispatch({ type: ActionType.SET_MAP_READY, payload: this._mapReady });
        this.updateTreckDetailLevel(this._mapZoomLevel);
    }

    public doSetAgency(state: SpeedMapStateType, agency: AgencyModel | null): void {
        if (state.agency === agency) return;

        this.dispatch({ type: ActionType.SET_AGENCY, payload: agency });
        this._agencyId = agency?.id || null;

        this.loadRoutesList(state);
    }

    public doSetupMap(state: SpeedMapStateType): void {
        const agencyLocation = state.agency?.location || new Microsoft.Maps.Location(38.87757365244203, -77.10571938437442);
        if (this._map) {
            this._map.setView({
                center: agencyLocation,
            });
        }
        else {
            if (!this._mapDiv) throw new Error('SpeedMap: map div is not ready');
            this._mapZoomLevel = 1;
            this._map = new Microsoft.Maps.Map(this._mapDiv, {
                center: agencyLocation,
                zoom: 12,
            });
            Microsoft.Maps.Events.addHandler(this._map, 'viewchangeend', () => {
                const newZoomLevel = this._map!.getZoom();
                if (this._mapZoomLevel !== newZoomLevel) {
                    this._mapZoomLevel = newZoomLevel;

                    if (this.updateStopDetailLevel(this._mapZoomLevel)) {
                        this.updateStops();
                    }

                    const prevTreckDetailsLevel = this._treckDetailsLevel;
                    if (this.updateTreckDetailLevel(this._mapZoomLevel)) {
                        this.updateTrecks(prevTreckDetailsLevel);
                    }
                }
            });
        }
    }

    private updateStopDetailLevel(newMapZoomLevel: number): boolean {
        const stopsDetailsLevel = mapZoomLevelToStopsDetailsLevel(newMapZoomLevel);
        if (this._stopsDetailsLevel != stopsDetailsLevel) {
            this.dispatch({ type: ActionType.SET_STOPS_DETAILS_LEVEL, payload: stopsDetailsLevel });
            this._stopsDetailsLevel = stopsDetailsLevel;
            return true;
        }
        return false;
    }

    private updateTreckDetailLevel(newMapZoomLevel: number): boolean {
        const pathDetailLevel = mapZoomLevelToTreckDetailLevel(newMapZoomLevel);
        if (this._treckDetailsLevel != pathDetailLevel) {
            this.dispatch({ type: ActionType.SET_TRECK_DETAILS_LEVEL, payload: pathDetailLevel });
            this._treckDetailsLevel = pathDetailLevel;
            return true;
        }
        return false;
    }

    public doReloadSpeedRange(): void {
        const ranges = SpeedRangeSet.load();
        this.dispatch({ type: ActionType.SET_SPEED_RANGES, payload: ranges });
    }

    private updateStops(): void {
        const stops = this._selectedTreck?.stops;
        if (!stops || !this._map) return;
        if (this._stopsDetailsLevel === StopsDetailsLevel.L0) {
            this.hideStopInfobox();
            for (const s of stops) {
                this._map.entities.remove(s.pushpin);
            }
        }
        else {
            for (const s of stops) {
                this._map.entities.push(s.pushpin);
            }
        }
    }

    private updateTrecks(prevTreckDetailsLevel: TreckDetailsLevel | null): void {
        const treck = this._selectedTreck?.getPolylineSets();
        if (!treck || !this._map) return;

        if (prevTreckDetailsLevel !== null) {
            for (const pSet of treck) {
                for (const p of pSet.getPolylines(prevTreckDetailsLevel)) {
                    this._map.entities.remove(p);
                }
            }
        }
        {
            for (const pSet of treck) {
                for (const p of pSet.getPolylines(this._treckDetailsLevel)) {
                    this._map?.entities.push(p);
                }
            }
        }
    }

    public onSpeedRangesChanged(state: SpeedMapStateType): void {
        for (const key in this.routeVariants) {
            const rve = this.routeVariants[key];
            const polylineSets = rve.getPolylineSets();
            if (polylineSets) {
                for (const pSet of polylineSets) {
                    for (const p of pSet.getPolylines(this._treckDetailsLevel)) {
                        const srd = state.speedRangeSet.get(p.metadata);
                        p.setOptions({ strokeColor: srd.stroke.color });
                    }
                }
            }
        }
    }

    private async loadRoutesList(state: SpeedMapStateType): Promise<void> {
        const primaryInput = state.inputDates.find(d => d.id === 1);
        if (!primaryInput || !this._agencyId)
            return;
        this.clearRoutes();
        let selectedRouteFound = false;
        try {
            const routesList = await loadRoutes(this._agencyId, primaryInput.lowerBoundDate);
            const { routeId, routeName } = state.selectedRoute ?? {};
            const { directionVariantId } = state.selectedDirection ?? {};
            routesList.forEach(route => {
                route.directions.forEach(direction => {
                    if (routeId && routeName && directionVariantId) {
                        direction.selected = route.routeId === routeId && route.routeName === routeName && direction.directionVariantId === directionVariantId;
                        if (!selectedRouteFound && direction.selected)
                            selectedRouteFound = true;
                    }
                    const entry: RouteVariantEntry = new RouteVariantEntry(route, direction);
                    const key = new RouteVariantKey(route.routeName, route.routeId, direction.cardinalDirection);
                    this.routeVariants[key.value] = entry;
                });
            });
            this.dispatch({ type: ActionType.SET_ROUTES_LIST, payload: routesList });
        } catch (error) {
            console.error('SpeedMap: locad data error', error);
        }
        if (!selectedRouteFound) {
            if (this._map)
                this._map.entities.clear();
            if (state.selectedRoute)
                this.doSetSelectedRoute(state, null);
            if (state.selectedDirection)
                this.doSetSelectedDirection(state, null);
        }
    }

    private clearRoutes(): void {
        // cleanup this.routeVariants
        for (const dn in this.routeVariants) {
            delete this.routeVariants[dn];
        }
        // cleanup this.selectedRouteVariants
        this.selectedRouteVariants = [];
        this.dispatch({ type: ActionType.SET_ROUTES_LIST, payload: null });
    }

    private async getSpeedData(state: SpeedMapStateType, directionVariantId: string, excludeWeekends: boolean) {
        if (!this._agencyId) return [];
        const agencyId = this._agencyId;
        const speedMapData = await Promise.all(state.inputDates
            .filter(d => state.showComparePeriods ? d : d.id === 1)
            .map(d => {
                return loadSpeedMapData(agencyId, d.id, directionVariantId,
                    d.lowerBoundDate, d.lowerBoundTime,
                    d.upperBoundDate, d.upperBoundTime,
                    excludeWeekends);
            }));
        return speedMapData;
    }

    public doSetSelectedDirection(state: SpeedMapStateType, selectedDirection: IDirectionModel | null): void {
        if (state.selectedDirection === selectedDirection)
            return;
        this.dispatch({ type: ActionType.SET_SELECTED_DIRECTION_STATE, payload: selectedDirection });
        this.directionChanged = true;
    }

    public doSetSelectedRoute(state: SpeedMapStateType, selectedRoute: IRouteModel | null): void {
        if (state.selectedRoute === selectedRoute)
            return;
        this.dispatch({ type: ActionType.SET_SELECTED_ROUTE_STATE, payload: selectedRoute });
    }

    private processRawSpeedData(rawSpeedData: DirectionDetSpeedsSet[], ranges: SpeedRangeSet, excludeStops: boolean) {
        const callback: DirectionCallback = (
            key: RouteVariantKey,
            polylines: SpeedRangePolyline[],
            bounds: Microsoft.Maps.LocationRect | null,
            timePeriodKey: TimePeriodKey,
            treckDetailsLevel: TreckDetailsLevel,
        ) => {
            const rve = this.routeVariants[key.value];
            if (!rve)
                console.error(`SpeedMap: key [${key}] not found.`);
            else if (polylines) {
                rve.setData(timePeriodKey, treckDetailsLevel, bounds, polylines, rawSpeedData);
            }
            else
                rve.clearPolylineSets();
        };
        for (const speedData of rawSpeedData)
            buildRoute(speedData, ranges, excludeStops, callback);
    }

    private _handleMapObjectCliecked?: (eventArg?: Microsoft.Maps.IMouseEventArgs) => void;
    private _stopInfobox?: Microsoft.Maps.Infobox;
    private _stopPushpin: Microsoft.Maps.Pushpin | null = null;

    private hideStopInfobox(): void {
        this._stopInfobox?.setOptions({ visible: false });
        this._stopPushpin?.setOptions({ icon: busStopIcon });
        this._stopPushpin = null;
    }

    private onMapObjectClicked(target: any, targetType: string) {
        if (target === this._stopInfobox) {
            this._stopInfobox?.setOptions({ visible: false });
            this._stopPushpin?.setOptions({ icon: busStopIcon });
            this._stopPushpin = null;
        }
        else if (targetType == 'pushpin') {
            const pushpin = target as Microsoft.Maps.Pushpin;
            if (this._stopPushpin === pushpin) {
                this._stopInfobox?.setOptions({ visible: false });
                this._stopPushpin?.setOptions({ icon: busStopIcon });
                this._stopPushpin = null;
                return;
            }
            if (this._stopPushpin) {
                this._stopPushpin?.setOptions({ icon: busStopIcon });
            }
            pushpin.setOptions({ icon: busStopHoverIcon });
            this._stopPushpin = pushpin;

            const stopInfo = pushpin.metadata as StopInfo;
            let desc = '';
            if (stopInfo.dwellTime) {
                if (stopInfo.dwellTime.stopCount > 0) {
                    const avg = stopInfo.dwellTime.totalSecs / stopInfo.dwellTime.stopCount;
                    desc += `Average dwell time: ${avg.toFixed(1)} s.`;
                }
                if (stopInfo.dwellTime.skipCount > 0) {
                    const value = stopInfo.dwellTime.skipCount;
                    if (desc.length > 0) desc += '<br/>';
                    desc += `Skipped ${value} time(s).`;
                }
            }
            this._stopInfobox?.setOptions({
                location: pushpin.getLocation(),
                offset: new Microsoft.Maps.Point(0, 50),
                title: stopInfo.name,
                description: desc,
                visible: true,
            });
        } else {
            console.log('SpeedMap: MapObjectClicked', targetType, target);
        }
    }

    public async doSelectRouteDirection(
        state: SpeedMapStateType,
        route: IRouteModel,
        direction: IDirectionModel,
        excludeWeekends: boolean,
        excludeStops: boolean,
        setId: number,
        focusOnRoute = true,
    ): Promise<void> {

        this.selectedRouteVariants = [];
        if (this._map) {
            this._map.entities.clear();
        }
        this.directionChanged = false;
        if (!route || !direction) {
            delete this._selectedTreck;
            return;
        }
        const timePeriodKey = new TimePeriodKey(setId);
        const rveKey = new RouteVariantKey(route.routeName, route.routeId, direction.cardinalDirection);
        let routeVariantEntry = this.routeVariants[rveKey.value];
        if (!routeVariantEntry) {
            console.error(`SpeedMap: route variant ${rveKey} not found`);
            return;
        }
        if (!routeVariantEntry.bounds ||
            !routeVariantEntry.hasPolylineSets(timePeriodKey) ||
            !routeVariantEntry.hasSpeedDataSets(timePeriodKey)
        ) {
            if (!this._agencyId)
                return;
            this.setBusy(true);
            try {
                const speedDataSets = await this.getSpeedData(state, direction.directionVariantId, excludeWeekends);
                this.processRawSpeedData(speedDataSets, state.speedRangeSet, excludeStops);
                this.updateSelectedPeriodOptions(state, String(setId));
                this.setDataPeriodsNumber(state, speedDataSets.length);
                focusOnRoute = true;
            } finally {
                this.setBusy(false);
            }
            routeVariantEntry = this.routeVariants[rveKey.value];
        } else {
            const storedRawSpeedDataSets = routeVariantEntry.getSpeedDataSets(timePeriodKey)!;
            this.processRawSpeedData(storedRawSpeedDataSets, state.speedRangeSet, excludeStops);
        }
        this.selectedRouteVariants.push(routeVariantEntry);
        this.selectedRouteVariants.sort((a, b) => {
            return a.direction.directionVariantId.localeCompare(b.direction.directionVariantId);
        });

        this._selectedTreck = routeVariantEntry;

        if (this._map) {
            const polylines = routeVariantEntry.getPolylines(timePeriodKey, this._treckDetailsLevel);
            if (polylines && polylines.length > 0) {
                for (const srp of polylines) {
                    this._map.entities.push(srp);
                }
                if (focusOnRoute && routeVariantEntry.bounds)
                    this._map.setView({ bounds: routeVariantEntry.bounds });
            } else {
                this._map.entities.clear();
            }
        }
        const selectedDates = state.inputDates.find(d => d.id === setId);
        if (!routeVariantEntry.stops && selectedDates) {
            const results = await Promise.all([
                loadDirectionVariantStops(direction.directionVariantId, selectedDates.lowerBoundDate, selectedDates.upperBoundDate),
                loadStopDwellTimes(direction.directionVariantId, selectedDates.lowerBoundDate, selectedDates.upperBoundDate,
                    moment(selectedDates.lowerBoundTime).format('HH:mm:ss'),
                    moment(selectedDates.upperBoundTime).format('HH:mm:ss')),
            ]);

            if (results[0].isSuccess && results[1].isSuccess) {
                const dwells: Dictionary<StopDwellTimeDto> = {};

                results[1].data.forEach(e => {
                    dwells[e.stopId] = e;
                });

                const res = results[0];
                if (this._map && !this._handleMapObjectCliecked) {
                    this._stopInfobox = new Microsoft.Maps.Infobox(this._map.getCenter(), {
                        visible: false,
                    });
                    this._stopInfobox.setMap(this._map);

                    this._handleMapObjectCliecked = (eventArg?: Microsoft.Maps.IMouseEventArgs) => {
                        if (eventArg)
                            this.onMapObjectClicked(eventArg.target, eventArg.targetType);
                        else
                            this.onMapObjectClicked(eventArg, '-event-');
                    };
                }

                routeVariantEntry.stops = res.data.map<StopInfo>(s => {
                    const pushpin = new Microsoft.Maps.Pushpin(
                        new Microsoft.Maps.Location(s.lat, s.lon), {
                        icon: busStopIcon,
                    });
                    const result: StopInfo = {
                        id: s.stopId,
                        name: s.stopName,
                        pushpin: pushpin,
                        dwellTime: dwells[s.stopCode] || { stopId: s.stopId, skipCount: 0, stopCount: 0, totalSecs: 0 },
                    };
                    pushpin.metadata = result;
                    if (this._handleMapObjectCliecked) {
                        Microsoft.Maps.Events.addHandler(this._stopInfobox, 'click', this._handleMapObjectCliecked);
                        Microsoft.Maps.Events.addHandler(pushpin, 'click', this._handleMapObjectCliecked);
                    }
                    return result;
                });

                this.dispatch({
                    type: ActionType.SET_STOPS_STATE, payload: {
                        items: routeVariantEntry.stops,
                        selected: null,
                    },
                });
                this.hideStopInfobox();
            }
        }
        // if direction variant is still selected then show bus stops
        const stillSelected = this.selectedRouteVariants.findIndex(i => i.route === route && i.direction && direction) >= 0;
        if (stillSelected && this._map && routeVariantEntry.stops) {
            if (this._stopsDetailsLevel !== StopsDetailsLevel.L0)
                this._map.entities.add(routeVariantEntry.stops?.map(s => s.pushpin));
        }
    }

    public directionHasData(state: SpeedMapStateType, routeName: string, routeId: string, cardinalDirection: string): number {
        const rveKey = new RouteVariantKey(routeName, routeId, cardinalDirection);
        const polylineSets = this.routeVariants[rveKey.value]?.getPolylineSets();
        let result = 0;
        if (polylineSets) {
            if (state.showComparePeriods) {
                if (polylineSets[0] != undefined) result += 1;
                if (polylineSets[1] != undefined) result += 1;
            } else {
                if (polylineSets[0] != undefined) result += 2;
            }
        }
        return result;
    }

    public canLoadNewData(state: SpeedMapStateType): boolean {
        return (!!this._agencyId) && Boolean(state.selectedRoute) && Boolean(state.selectedDirection);
    }

    public setLowerBoundDate(state: SpeedMapStateType, value: string, id: number): boolean {
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (!selectedDates || selectedDates?.lowerBoundDate === value) return false;
        this.dispatch({ type: ActionType.SET_LOWER_BOUND_DATE, payload: { id, value } });
        if (id === 1)
            this.loadRoutesList(state);
        else
            this.reloadData = true;
        return true;
    }

    public setUpperBoundDate(state: SpeedMapStateType, value: string, id: number): boolean {
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (!selectedDates || selectedDates.upperBoundDate === value) return false;
        this.dispatch({ type: ActionType.SET_UPPER_BOUND_DATE, payload: { id, value } });
        this.reloadData = true;
        return true;
    }

    public setLowerBoundTime(state: SpeedMapStateType, value: string, id: number): boolean {
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (!selectedDates || selectedDates?.lowerBoundTime === value) return false;
        this.dispatch({ type: ActionType.SET_LOWER_BOUND_TIME, payload: { id, value } });
        this.reloadData = true;
        return true;
    }

    public setUpperBoundTime(state: SpeedMapStateType, value: string, id: number): boolean {
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (!selectedDates || selectedDates?.upperBoundTime === value) return false;
        this.dispatch({ type: ActionType.SET_UPPER_BOUND_TIME, payload: { id, value } });
        this.reloadData = true;
        return true;
    }

    public async handleGoClick(state: SpeedMapStateType): Promise<void> {
        if (this.reloadData) {
            for (const key in this.routeVariants) {
                const rve = this.routeVariants[key];
                rve.clearPolylineSets();
                rve.bounds = undefined;
            }
        }
        this.reloadData = false;
        const { selectedRoute: route, selectedDirection: direction, excludeWeekends, excludeStops, periodsState } = state;
        if (route && direction)
            await this.doSelectRouteDirection(state, route, direction, excludeWeekends, excludeStops, state.showComparePeriods ? Number(periodsState.selectedValue) : 1);
    }

    public setExcludeWekends(state: SpeedMapStateType, value: boolean): boolean {
        if (state.excludeWeekends === value) return false;
        this.dispatch({ type: ActionType.SET_EXCLUDE_WEKENDS, payload: value });
        for (const key in this.routeVariants) {
            const rve = this.routeVariants[key];
            rve.clearPolylineSets();
            rve.bounds = undefined;
        }
        return true;
    }

    public async setExcludeStops(state: SpeedMapStateType, value: boolean): Promise<boolean> {
        if (state.excludeStops === value) return false;
        this.dispatch({ type: ActionType.SET_EXCLUDE_STOPS, payload: value });
        return true;
    }

    public async setSelectedPeriod(state: SpeedMapStateType, value: string): Promise<void> {
        if (state.periodsState.selectedValue === value)
            return;
        this.dispatch({
            type: ActionType.SET_PERIODS_STATE, payload: {
                ...state.periodsState,
                selectedValue: value,
            } as DropDownStateType,
        });
        const { selectedRoute: route, selectedDirection: direction, excludeStops, excludeWeekends } = state;
        if (route && direction) {
            await this.doSelectRouteDirection(state, route, direction, excludeWeekends, excludeStops, Number(value), this.directionChanged);
        }
    }

    private updateSelectedPeriodOptions(state: SpeedMapStateType, periodId: string): void {
        const periodsState = {
            selectedValue: periodId,
            options: state.inputDates.map(selectedDates => {
                const { id, lowerBoundDate, lowerBoundTime, upperBoundDate, upperBoundTime } = selectedDates;
                return {
                    value: String(id),
                    text: `${lowerBoundDate} ${timeApiToUi(lowerBoundTime)} to ${upperBoundDate} ${timeApiToUi(upperBoundTime)}`,
                };
            }),
        };
        this.dispatch({
            type: ActionType.SET_PERIODS_STATE, payload: periodsState as DropDownStateType,
        });
    }

    public setShowComparePeriods(state: SpeedMapStateType, value: boolean): void {
        if (state.showComparePeriods === value)
            return;
        this.dispatch({ type: ActionType.SET_SHOW_COMPARE_PERIODS_STATE, payload: value });
        this.reloadData = true;
    }

    private setDataPeriodsNumber(state: SpeedMapStateType, value: number): void {
        if (state.dataPeriodsNumber === value)
            return;
        this.dispatch({ type: ActionType.SET_DATA_PERIODS_NUMBER_STATE, payload: value });
    }
}

/*-- context --*/

export const SpeedMapContext = (React as any).createContext() as React.Context<SpeedMapContextType>;

/*-- reducer --*/

type SpeedMapAction = {
    type: ActionType,
    payload?: null | boolean | string | number | AgencyModel | SpeedRangeSet
    | CoordinatePair | RouteModel[] | StopsState | DirectionAvgSpeeds[] | IDirectionModel | IRouteModel | DropDownStateType | { id: number; value: string }
};

type ActionMap = {
    [actionType in ActionType]: (state: SpeedMapStateType, action: SpeedMapAction) => SpeedMapStateType
};

const handlers: ActionMap = {
    [ActionType.DEFAULT]: (state: SpeedMapStateType) => state,
    [ActionType.SET_MAP_READY]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            mapReady: !!action.payload,
        };
    },
    [ActionType.SET_IS_BUSY]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const value = action.payload === true;
        if (value === state.isBusy) {
            return state;
        }
        return {
            ...state,
            isBusy: value,
        };
    },
    [ActionType.SET_AGENCY]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const value = action.payload as (AgencyModel | null);
        if (value === state.agency) {
            return state;
        }
        return {
            ...state,
            agency: value,
            routesList: null,
        };
    },
    [ActionType.SET_SPEED_RANGES]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const value = action.payload as SpeedRangeSet;
        return {
            ...state,
            speedRangeSet: value,
        };
    },
    [ActionType.SET_TRECK_DETAILS_LEVEL]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const value = action.payload as TreckDetailsLevel;
        return {
            ...state,
            treckDetailsLevel: value,
        };
    },
    [ActionType.SET_STOPS_DETAILS_LEVEL]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const value = action.payload as StopsDetailsLevel;
        return {
            ...state,
            stopsDetailsLevel: value,
        };
    },
    [ActionType.SET_ROUTES_LIST]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const routesList = action.payload as (RouteModel[] | null);
        return {
            ...state,
            routesList,
        };
    },
    [ActionType.SET_SELECTED_DIRECTION_STATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const selectedDirection = action.payload as (IDirectionModel | null);
        return {
            ...state,
            selectedDirection,
        };
    },
    [ActionType.SET_SELECTED_ROUTE_STATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const selectedRoute = action.payload as (IRouteModel | null);
        return {
            ...state,
            selectedRoute,
        };
    },
    [ActionType.SET_LOWER_BOUND_DATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const { id, value } = action.payload as { id: number; value: string };
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (selectedDates)
            return {
                ...state,
                inputDates: state.inputDates.map(d => d.id === id ? { ...selectedDates, lowerBoundDate: value } : d),
            };
        else {
            return {
                ...state,
                inputDates: [...state.inputDates, {
                    id,
                    lowerBoundDate: value,
                    upperBoundDate: '',
                    lowerBoundTime: '',
                    upperBoundTime: '',
                }],
            };
        }
    },
    [ActionType.SET_UPPER_BOUND_DATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const { id, value } = action.payload as { id: number; value: string };
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (selectedDates)
            return {
                ...state,
                inputDates: state.inputDates.map(d => d.id === id ? { ...selectedDates, upperBoundDate: value } : d),
            };
        else {
            return {
                ...state,
                inputDates: [...state.inputDates, {
                    id,
                    lowerBoundDate: '',
                    upperBoundDate: value,
                    lowerBoundTime: '',
                    upperBoundTime: '',
                }],
            };
        }
    },
    [ActionType.SET_LOWER_BOUND_TIME]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const { id, value } = action.payload as { id: number; value: string };
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (selectedDates)
            return {
                ...state,
                inputDates: state.inputDates.map(d => d.id === id ? { ...selectedDates, lowerBoundTime: value } : d),
            };
        else {
            return {
                ...state,
                inputDates: [...state.inputDates, {
                    id,
                    lowerBoundDate: '',
                    upperBoundDate: '',
                    lowerBoundTime: value,
                    upperBoundTime: '',
                }],
            };
        }
    },
    [ActionType.SET_UPPER_BOUND_TIME]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        const { id, value } = action.payload as { id: number; value: string };
        const selectedDates = state.inputDates.find(d => d.id === id);
        if (selectedDates)
            return {
                ...state,
                inputDates: state.inputDates.map(d => d.id === id ? { ...selectedDates, upperBoundTime: value } : d),
            };
        else {
            return {
                ...state,
                inputDates: [...state.inputDates, {
                    id,
                    lowerBoundDate: '',
                    upperBoundDate: '',
                    lowerBoundTime: '',
                    upperBoundTime: value,
                }],
            };
        }
    },
    [ActionType.SET_EXCLUDE_WEKENDS]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            excludeWeekends: !!action.payload,
        };
    },
    [ActionType.SET_EXCLUDE_STOPS]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            excludeStops: !!action.payload,
        };
    },
    [ActionType.SET_STOPS_STATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            stops: action.payload as StopsState,
        };
    },
    [ActionType.SET_PERIODS_STATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            periodsState: action.payload as DropDownStateType,
        };
    },
    [ActionType.SET_SHOW_COMPARE_PERIODS_STATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            showComparePeriods: !!action.payload,
        };
    },
    [ActionType.SET_DATA_PERIODS_NUMBER_STATE]: (state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType => {
        return {
            ...state,
            dataPeriodsNumber: action.payload as number,
        };
    },
};

function speedMapReducer(state: SpeedMapStateType, action: SpeedMapAction): SpeedMapStateType {
    const handler = (handlers as any)[action.type] || handlers[ActionType.DEFAULT];
    return handler(state, action);
}

/*-- state component --*/

function initializeState(src: SpeedMapStateType): SpeedMapStateType {
    if (!src.helper) {
        const lowerBoundDate1 = moment(new Date().setMinutes(0, 0, 0)).add(-2, 'days').format('YYYY-MM-DD') as string;
        const upperBoundDate1 = moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD') as string;
        const lowerBoundDate2 = moment(new Date().setMinutes(0, 0, 0)).add(-3, 'days').format('YYYY-MM-DD') as string;
        const upperBoundDate2 = moment(new Date().setMinutes(0, 0, 0)).add(-2, 'days').format('YYYY-MM-DD') as string;
        const lowerBoundTime = timeUiToApi('09:00 AM');
        const upperBoundTime = timeUiToApi('12:00 PM');
        const result = {
            ...src,
            inputDates: [
                {
                    id: 1,
                    lowerBoundDate: lowerBoundDate1,
                    upperBoundDate: upperBoundDate1,
                    lowerBoundTime,
                    upperBoundTime,
                },
                {
                    id: 2,
                    lowerBoundDate: lowerBoundDate2,
                    upperBoundDate: upperBoundDate2,
                    lowerBoundTime: lowerBoundTime,
                    upperBoundTime: upperBoundTime,
                }],
            periodsState: {
                options: [
                    {
                        value: '1',
                        text: `${lowerBoundDate1} ${timeApiToUi(lowerBoundTime)} to ${upperBoundDate1} ${timeApiToUi(upperBoundTime)}`,
                    },
                    {
                        value: '2',
                        text: `${lowerBoundDate2} ${timeApiToUi(lowerBoundTime)} to ${upperBoundDate2} ${timeApiToUi(upperBoundTime)}`,
                    }],
                selectedValue: '1',
            },
            speedRangeSet: SpeedRangeSet.load(),
            helper: new InstanceHelper(),
        };
        return result;
    }
    return src;
}

function cast(state: SpeedMapStateType): InternalInstanceHelper {
    if (state.helper instanceof InstanceHelper)
        return state.helper;

    console.error('SpeedMapContext: invalid state.internals');
    return (state.helper as unknown) as InternalInstanceHelper;
}

export const SpeedMapState = (props: React.Props<{}>) => {
    const [state, dispatch] = React.useReducer(speedMapReducer, initialState, initializeState);
    cast(state).dispatch = dispatch;

    const onMapReady = (div: HTMLDivElement): void => {
        cast(state).onMapReady(div);
    };
    const setAgency = (agency: AgencyModel | null) => {
        cast(state).doSetAgency(state, agency);
    };
    const updateSpeedRanges = (): void => {
        cast(state).doReloadSpeedRange();
    };

    const selectRouteDirection = (route: IRouteModel, direction: IDirectionModel) => {
        cast(state).doSetSelectedDirection(state, direction);
        cast(state).doSetSelectedRoute(state, route);
    };
    const getSelectedRouteVariants = () => {
        return cast(state).selectedRouteVariants;
    };
    const setLowerBoundDate = (value: string, id: number): boolean => {
        return cast(state).setLowerBoundDate(state, value, id);
    };
    const setUpperBoundDate = (value: string, id: number): boolean => {
        return cast(state).setUpperBoundDate(state, value, id);
    };
    const setLowerBoundTime = (value: string, id: number): boolean => {
        return cast(state).setLowerBoundTime(state, value, id);
    };
    const setUpperBoundTime = (value: string, id: number): boolean => {
        return cast(state).setUpperBoundTime(state, value, id);
    };
    const setExcludeWekends = (value: boolean): boolean => {
        return cast(state).setExcludeWekends(state, value);
    };
    const setExcludeStops = (value: boolean): Promise<boolean> => {
        return cast(state).setExcludeStops(state, value);
    };
    const setSelectedPeriod = (value: string): void => {
        cast(state).setSelectedPeriod(state, value);
    };
    const canLoadNewData = (): boolean => {
        return cast(state).canLoadNewData(state);
    };
    const handleGoClick = () => {
        return cast(state).handleGoClick(state);
    };
    const directionHasData = (routeName: string, routeId: string, cardinalDirection: string): number => {
        return cast(state).directionHasData(state, routeName, routeId, cardinalDirection);
    };
    const setShowComparePeriods = (value: boolean): void => {
        return cast(state).setShowComparePeriods(state, value);
    };

    React.useEffect(() => {
        if (state.agency && state.mapReady)
            cast(state).doSetupMap(state);
    }, [state.agency, state.mapReady]);

    React.useEffect(() => {
        cast(state).onSpeedRangesChanged(state);
    }, [state.speedRangeSet]);

    return (
        <SpeedMapContext.Provider value={{
            ...state,
            onMapReady,
            setAgency,
            updateSpeedRanges,
            getSelectedRouteVariants,
            selectRouteDirection,
            setLowerBoundDate,
            setUpperBoundDate,
            setLowerBoundTime,
            setUpperBoundTime,
            setExcludeWekends,
            setExcludeStops,
            setSelectedPeriod,
            setShowComparePeriods,
            canLoadNewData,
            handleGoClick,
            directionHasData,
        }}
        >
            {props.children}
        </SpeedMapContext.Provider>
    );
};
