import '../../shared/chart-js-chartBoxPlot/Chart.BoxPlot.esm.js';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import ChartComponent from 'react-chartjs-2';
import { BoxPlotItemType } from '../../../types/runningTimeAnalyticsTypes';
import Utils from '../../../utilities/utils';
import { AggregatedTripsItem } from './AnalysisByPercentilesForm';

interface AnalysisByPercentilesTripStats {
    tripInfo: AggregatedTripsItem;
    percentileMin: number;
    percentileMax: number;
    percentileMedian: number;
    q1: number;
    q3: number;
}

interface ChartRef {
    chartInstance: {
        scales: {
            'y-axis-0': {
                getValueForPixel: (coordValue: number) => any,
                getLabelForIndex: (index: number, dataset: number) => string,
            }
        }
    }
}

const LeftBoundary = 'leftBoundary';
const RightBoundary = 'rightBoundary';
const MedianBoundary = 'medianBoundary';

const getIndexByPercentile = (percentile: number, elementsCount: number, roundFunc: (n: number) => number): number | null => {
    const percentileValue = roundFunc(percentile * elementsCount / 100);
    const arrayIndex = percentileValue === 0 ? null : percentileValue - 1;
    return arrayIndex;
};

const getBoundaryByPercentile = (percentile: number, delaysArr: number[],
    boundary: typeof LeftBoundary | typeof RightBoundary | typeof MedianBoundary): number | null => {
    if (delaysArr.length === 0)
        return null;
    const roundFunc: (n: number) => number = boundary === RightBoundary ? n => Math.ceil(n) : n => Math.floor(n);
    const arrIndex = getIndexByPercentile(percentile, delaysArr.length, roundFunc);
    if (arrIndex === null)
        return delaysArr[0];
    const nextIndex = arrIndex + 1;
    switch (boundary) {
        case RightBoundary:
            return delaysArr[arrIndex];
        case LeftBoundary:
            if (nextIndex >= delaysArr.length)
                return null;
            return delaysArr[nextIndex];
        case MedianBoundary:
            if (delaysArr.length % 2 === 0) {
                if (nextIndex >= delaysArr.length)
                    return delaysArr[arrIndex];
                return (delaysArr[arrIndex] + delaysArr[nextIndex]) / 2;
            } else {
                return delaysArr[arrIndex];
            }
        default:
            return null;
    }
};

const chartSettings = {
    // define label tree
    labels: [] as string[],
    datasets: [
        {
            label: 'delays data',
            backgroundColor: 'rgba(255,0,0,0.5)',
            borderColor: 'red',
            borderWidth: 1,
            outlierColor: 'lightgreen',
            outlierBorderColor: 'grey',
            oulierLineWidth: 2,
            lowerColor: 'lightblue',
            medianColor: 'green',
            padding: 20,
            itemRadius: 10,
            itemStyle: 'circle',
            outlierRadius: 5,
            data: [] as BoxPlotItemType[],
        },
    ],
};

const options = {
    responsive: true,
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                id: 'x-axis-0',
                position: 'top',
                ticks: {
                    minStats: 'min',
                    maxStats: 'max',
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Minutes',
                },
            }],
        yAxes: [{
            ticks: {
                fontColor: '#4183c4',
            },
        }],
    },
    tooltips: {
        callbacks: {
            boxplotLabel: function (
                _item: unknown,
                _data: unknown,
                stats: AnalysisByPercentilesTripStats,
            ) {
                const tripInfo = stats.tripInfo;
                const tripId = tripInfo.tripId;
                const numberOfTrips = tripInfo.delaysMinutes.length;
                const nextTripDelay = tripInfo.scheduledDelayToNextTrip;
                const scheduledRunTime = tripInfo.scheduledRunTime;
                const percentileMin = stats.percentileMin;
                const percentileMax = stats.percentileMax;
                return [
                    `Trip ${tripId}`,
                    `${numberOfTrips} trips`,
                    `Scheduled Run-Time, min: ${scheduledRunTime}`,
                    `Next trip start, min: ${nextTripDelay}`,
                    `${percentileMin}th percentile: ${stats.q1 > 0 ? '+' : ''}${Utils.roundNumber(stats.q1, 0)} min`,
                    `${percentileMax}th percentile: ${stats.q3 > 0 ? '+' : ''}${Utils.roundNumber(stats.q3, 0)} min`,
                ].join('; ');
            },
        },
    },
    maintainAspectRatio: false,
    plugins: {
        crosshair: false,
    },
};

const heightPerRow = 30;

const AnalysisByPercentilesChart: React.FC<{
    analysisByPercentilesData: AggregatedTripsItem[];
    percentileMin: number;
    percentileMax: number;
    percentileMedian?: number;
    handleChartTimeAxisClick: (timeLabel: string) => void;
}> = ({ analysisByPercentilesData, percentileMin, percentileMax, percentileMedian = 50, handleChartTimeAxisClick }) => {

    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    const [chartStyleState, setChartStyleState] = useState({
        height: analysisByPercentilesData.length * heightPerRow +'px',
    });
    const chartRef = useRef<ChartRef>(null);

    useEffect(() => {
        const chartData = analysisByPercentilesData.map(d => {
            const leftBoundary: number | null = getBoundaryByPercentile(percentileMin, d.delaysMinutes, LeftBoundary);
            const rightBoundary: number | null = getBoundaryByPercentile(percentileMax, d.delaysMinutes, RightBoundary);
            const medianBoundary: number | null = getBoundaryByPercentile(percentileMedian, d.delaysMinutes, MedianBoundary);
            const min: number = Math.min(0, d.delaysMinutes[0]);
            const max: number = Math.max(d.scheduledDelayToNextTrip, d.delaysMinutes[d.delaysMinutes.length - 1]);
            return { ...d, leftBoundary, rightBoundary, medianBoundary, min, max };
        }).filter(d => d.leftBoundary !== null && d.rightBoundary !== null && d.medianBoundary !== null);
        const chartHeight: number = chartData.length * heightPerRow;
        setChartStyleState({ height: chartHeight + 'px' });
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(p => p.scheduledStartTime),
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(p => {
                    return {
                        min: p.min,
                        max: p.max,
                        whiskerMin: p.delaysMinutes[0],
                        q1: p.leftBoundary as number,
                        median: p.medianBoundary as number,
                        q3: p.rightBoundary as number,
                        whiskerMax: p.delaysMinutes[p.delaysMinutes.length - 1],
                        outliers: [0, p.scheduledDelayToNextTrip],
                        tripInfo: p,
                        percentileMin: percentileMin,
                        percentileMax: percentileMax,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            onClick: (e: {}) => {
                if (!chartRef || !chartRef.current)
                    return;
                const chartInstance = chartRef.current.chartInstance;
                if (!chartInstance)
                    return;
                const canvasPosition = Chart.helpers.getRelativePosition(e, chartInstance);
                if (!canvasPosition)
                    return;
                const scales = chartInstance.scales;
                if (!scales)
                    return;
                const yScale = scales['y-axis-0'];
                if (!yScale)
                    return;
                const timeLabel = yScale.getLabelForIndex(yScale.getValueForPixel(canvasPosition.y as number), 0);
                handleChartTimeAxisClick(timeLabel);
            },
        }));
    }, [analysisByPercentilesData, percentileMin, percentileMax, percentileMedian]);


    return (
        <div style={chartStyleState}>
            {/* 
                // @ts-ignore */}
            <ChartComponent type="horizontalBoxplot" data={dataState} options={optionsState as unknown as ChartOptions} ref={chartRef} />
        </div>
    );
};

export default AnalysisByPercentilesChart;
