import React, { useEffect, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { BphSliderState } from './_models';
import BusHistorySliderControl from './BusHistorySliderControl';

const styles = {
    form: {
        paddingRight: '3px',
    },
};

interface Props extends BphSliderState {
    handleSliderChange: (value: number) => void;
}

const BusHistorySlider = ({ maxValue, sliderValue, handleSliderChange }: Props) => {

    const [sliderValueState, setSliderValueState] = useState<number>(sliderValue);

    useEffect(() => {
        setSliderValueState(sliderValue);
    }, [sliderValue]);

    const handleChange = (_event: React.ChangeEvent<{}>, value: number | number[]) => {
        if (Array.isArray(value))
            console.error('BusHistory: array value is not supported');
        else {
            setSliderValueState(value);
            if (handleSliderChange)
                handleSliderChange(value);
        }
    };

    const handleLeft = () => {
        setSliderValueState(prevState => {
            if (prevState > 0) {
                const sliderValue = prevState - 1;
                if (handleSliderChange)
                    handleSliderChange(sliderValue);
                return sliderValue;
            } else {
                return prevState;
            }
        });
    };

    const handleRight = () => {
        setSliderValueState(prevState => {
            if (prevState < maxValue) {
                const sliderValue = prevState + 1;
                if (handleSliderChange)
                    handleSliderChange(sliderValue);
                return sliderValue;
            } else {
                return prevState;
            }
        });
    };

    return (
        <Form>
            <BusHistorySliderControl
                valueLabelDisplay="off"
                aria-label="pretto slider"
                min={0}
                max={maxValue || 0}
                onChange={handleChange}
                step={1}
                value={sliderValueState || 0}
            />
            <Form.Group inline style={styles.form} >
                <Button icon="caret square left" onClick={handleLeft} />
                <Button icon="caret square right" onClick={handleRight} />
            </Form.Group>
        </Form>
    );
};

export default BusHistorySlider;