import * as React from 'react';
import { useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import { BunchesByDatesChartProp, BunchesByDayPart, BunchesByKey, BunchingStopStat } from '../../../../types/busBunchingTypes';
import BingMapQuerier from '../../../../utilities/BingMapQuerier';
import BingMapWithOverlay from '../../../shared/BingMapWithOverlay';
import BunchesByDatesChart from './BunchesByDatesChart';
import BunchesByDayPartChart from './BunchesByDayPartChart';
import BunchesByRoutesChart from './BunchesByRoutesChart';
import BunchesByWeekDayChart from './BunchesByWeekDayChart';
import { drawBunches } from './heatMapDrawing';


const styles = {
    mapContainer: {
        height: '673px',
    } as React.CSSProperties,
};

interface Props {
    bunchesByRoutes: BunchesByKey[],
    stopsStats: BunchingStopStat[],
    bunchesByDatesChartProps: BunchesByDatesChartProp | null,
    bunchesByWeekDay: BunchesByKey[],
    bunchesByDayPart: BunchesByDayPart[],
    center: CoordinatePair | undefined;
}

const StatisticsTabForm: React.FC<Props> = ({ bunchesByRoutes, stopsStats, bunchesByDatesChartProps, bunchesByWeekDay, bunchesByDayPart, center }) => {

    const drawOverlay = useCallback(
        async (map: BingMapQuerier, canvas: HTMLCanvasElement) =>
            drawBunches(
                stopsStats,
                canvas,
                ({ lat, lon }) => {
                    const point = map.tryLocationToPixel([lat, lon]);
                    if (point === null) throw new Error(`Could not find pixel for coordinate (${lat}, ${lon}).`);
                    return point;
                },
            ),
        [stopsStats],
    );

    return (
        <>
            <Form.Group>
                {
                    bunchesByDatesChartProps && <Form.Field width={16}>
                        <label className="categoryHeader">Bunches by {bunchesByDatesChartProps.daysDiff > 1 ? 'days' : 'hours'} from {bunchesByDatesChartProps.fromDate} to {bunchesByDatesChartProps.toDate}</label>
                        <BunchesByDatesChart bunchesByDates={bunchesByDatesChartProps.bunchesByDates} daysDiff={bunchesByDatesChartProps.daysDiff} />
                    </Form.Field>
                }
            </Form.Group>
            <Form.Group>
                <Form.Field width={16} style={styles.mapContainer}>
                    <BingMapWithOverlay {...{ mapProps: { map: { center } }, drawOverlay }} />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={8}>
                    <label className="categoryHeader">Bunches by Weekday</label>
                    <BunchesByWeekDayChart chartData={bunchesByWeekDay} />
                </Form.Field>
                <Form.Field width={8}>
                    <label className="categoryHeader">Bunches by Day Part</label>
                    <BunchesByDayPartChart chartData={bunchesByDayPart} />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={16}>
                    <label className="categoryHeader">Bunches by Routes</label>
                    <BunchesByRoutesChart chartData={bunchesByRoutes} />
                </Form.Field>
            </Form.Group>
        </>
    );
};

export default StatisticsTabForm;