import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DelayJumpsDetails } from '../../../types/anomaliesTypes';
import Utils from '../../../utilities/utils';

const DelayJumpsDetailsTableRow: React.FC<{ row: DelayJumpsDetails, selectedRow: DelayJumpsDetails | undefined, selectedRowHandler: (selectedRow: DelayJumpsDetails) => void }> = ({ row, selectedRow, selectedRowHandler }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedRow !== undefined && selectedRow.prevTime === row.prevTime && selectedRow.nextTime === row.nextTime}>
            <Table.Cell textAlign="left">
                {moment.parseZone(row.prevTime).format('h:mm:ss A')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {moment.parseZone(row.nextTime).format('h:mm:ss A')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {Utils.convertSecondsToMinutes(row.prevDelaySec, 'm', 's')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {Utils.convertSecondsToMinutes(row.nextDelaySec, 'm', 's')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.jumpSpeed}
            </Table.Cell>
        </Table.Row>
    );
};

export default DelayJumpsDetailsTableRow;