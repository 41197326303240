import * as React from 'react';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Form, Icon, Popup } from 'semantic-ui-react';
import { WrongCoordinatesShape } from '../../../types/anomaliesTypes';
import BingMap from '../../shared/BingMap';
import { toReportRow } from '../../shared/csvReport';
import PrettoSliderControl from '../../shared/PrettoSliderControl';
import { RouteShapesReportDef, RouteShapesReportHeader } from './csvReports';
import { WrongCoordinatesTabForm } from './WrongCoordinatesForm';
import WrongCoordinatesShapesTable from './WrongCoordinatesShapesTable';
import WrongCoordinatesShapesTableSortReducer from './WrongCoordinatesShapesTableSortReducer';
import WrongCoordinatesTripsTable from './WrongCoordinatesTripsTable';
import WrongCoordinatesTripTableSortReducer from './WrongCoordinatesTripTableSortReducer';


const styles = {
    map: {
        width: '100%',
        height: '522px',
        marginTop: '5px',
    } as React.CSSProperties,
    sliderContainer: {
        marginTop: '40px',
    } as React.CSSProperties,
    exportLink: {
        float: 'right',
    } as React.CSSProperties,
};

interface Props extends WrongCoordinatesTabForm {
    shapesData: WrongCoordinatesShape[],
    shapesRowHandler: (shapeId: string) => void;
    selectedShapeId: string | undefined;
}

const WrongCoordinatesShapesForm: React.FC<Props> = ({
    shapesData,
    shapesRowHandler,
    selectedShapeId,
    tripsData,
    selectedTrip,
    tripsRowHandler,
    vehiclePositions,
    agencyOffset,
    center,
    mapBounds,
    polylines,
    pushpins,
    handleSliderChange,
    sliderValue,
    handleSliderValueLabelFormat,
    infoboxes,
    mapRef,
    mapEventHandlers,
}) => {

    const [shapeTableState, shapeTableDispatch] = React.useReducer(WrongCoordinatesShapesTableSortReducer, {
        column: '',
        data: [],
        direction: undefined,
    });

    const [tripTableState, tripTableDispatch] = React.useReducer(WrongCoordinatesTripTableSortReducer, {
        column: '',
        data: [],
        direction: undefined,
    });
    const handleTripTableColumnSort = (column: string) => {
        tripTableDispatch({ type: 'CHANGE_SORT', column, data: tripsData });
    };

    const handleShapesTableColumnSort = (column: string) => {
        shapeTableDispatch({ type: 'CHANGE_SORT', column, data: shapesData });
    };

    useEffect(() => {
        shapeTableDispatch(({ type: 'UPDATE_DATA', data: shapesData.sort((a, b) => b.outShape - a.outShape), column: '' }));
    }, [shapesData]);

    useEffect(() => {
        tripTableDispatch(({ type: 'UPDATE_DATA', data: tripsData.sort((a, b) => b.outShape - a.outShape), column: '' }));
    }, [tripsData]);

    return (
        <>
            <Form.Group>
                <Form.Field width={16}>
                    <label className="categoryHeader">
                        <span className="categorySubtitle">Click on a row to display trips table</span>
                        <Popup
                            content="Download Route Shapes Report"
                            trigger={
                                <CSVLink
                                    headers={RouteShapesReportHeader}
                                    data={shapesData.map(shape => toReportRow(RouteShapesReportDef, shape))}
                                    filename={`Route Shapes Report.csv`}
                                    target="_blank"
                                    className="ui button basic"
                                    style={styles.exportLink}
                                >
                                    <Icon name="download" />
                                    Route Shapes CSV
                                </CSVLink>
                            }
                        />
                    </label>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={16}>
                    <WrongCoordinatesShapesTable tableDataState={shapeTableState} rows={shapesData} selectedShapeId={selectedShapeId} selectedRowHandler={shapesRowHandler} columnSortHandler={handleShapesTableColumnSort} />
                </Form.Field>
            </Form.Group>
            {tripsData.length > 0 &&
                <>
                    <Form.Group>
                        <Form.Field width={16}>
                            <label className="categoryHeader">
                                <div className="categorySubtitle">Click on a row to display map</div>
                            </label>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group className="rowGroup">
                        <Form.Field width={16}>
                            <WrongCoordinatesTripsTable tableDataState={tripTableState} rows={tripsData} selectedRow={selectedTrip} selectedRowHandler={tripsRowHandler} agencyOffset={agencyOffset} columnSortHandler={handleTripTableColumnSort} />
                        </Form.Field>
                    </Form.Group>
                    {selectedTrip && vehiclePositions.length > 0 &&
                        <>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <label className="categoryHeader">
                                        <div className="categorySubtitle">Red lines designate deviation from the original route shape. Zoom in to see the stop icons.</div>
                                    </label>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <BingMap
                                        ref={mapRef}
                                        div={{ style: styles.map }}
                                        map={{
                                            center,
                                            options: {
                                                bounds: mapBounds,
                                            },
                                            eventHandlers: mapEventHandlers,
                                        }}
                                        polylines={polylines}
                                        pushpins={pushpins}
                                        infoboxes={infoboxes}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16} style={styles.sliderContainer} >
                                    <PrettoSliderControl
                                        valueLabelDisplay="on"
                                        name="delayJumpsSlider"
                                        aria-label="pretto slider"
                                        min={0}
                                        max={vehiclePositions.length > 0 ? vehiclePositions.length - 1 : 0}
                                        onChange={handleSliderChange}
                                        step={1}
                                        value={sliderValue}
                                        valueLabelFormat={handleSliderValueLabelFormat}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </>
                    }
                </>
            }
        </>
    );
};

export default WrongCoordinatesShapesForm;