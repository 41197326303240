import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../types/chartTypes';
import { TwelveHoursStatusItem } from '../../../types/otpTypes';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Not Reporting',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 131, 104, 0.8)',
            borderColor: 'rgba(255, 131, 104, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Reporting',
            lineTension: 0.1,
            backgroundColor: 'rgba(130, 225, 128, 0.8)',
            borderColor: 'rgba(130, 225, 128, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
        text: '12 Hour Reporting Status',
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    elements: {
        line: {
            tension: 0.4,
        },
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'hour',
                unitStepSize: 1,
            },
            ticks: {
                autoSkip: true,
            },
            scaleLabel: {
                display: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
            },
            scaleLabel: {
                display: false,
            },
            stacked: true,
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 110,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return moment(tooltipItem[0].xLabel).format('hh:mm A');
            },
            label: function (tooltipItem: { datasetIndex: number, index: number, xLabel: string, yLabel: number }) {
                const lineType = tooltipItem.datasetIndex === 0 ? 'Not Reporting' : 'Reporting';
                return `${lineType}: ${tooltipItem.yLabel}`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: 'rgba(255, 131, 104, 0.8)',
                        backgroundColor: 'rgba(255, 131, 104, 0.8)',
                    };
                } else {
                    return {
                        borderColor: 'rgba(130, 225, 128, 0.8)',
                        backgroundColor: 'rgba(130, 225, 128, 0.8)',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        filler: {
            propagate: true,
        },
        'samples-filler-analyser': {
            target: 'chart-analyser',
        },
        crosshair: false,
    },
    spanGaps: false,
};

interface Props {
    statusItems: TwelveHoursStatusItem[]
}

const HistoricalDataStatusChart: React.FC<Props> = ({ statusItems }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const orderedChartData = statusItems.sort((a, b) => new Date(a.reportTime).getTime() - new Date(b.reportTime).getTime());
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: orderedChartData.map(d => {
                    return {
                        t: moment.parseZone(d.reportTime).format('YYYY-MM-DD hh:mm A'),
                        y: d.notReportedCount,
                    };
                }),
            },
            {
                ...prevState.datasets[1], data: orderedChartData.map(d => {
                    return {
                        t: moment.parseZone(d.reportTime).format('YYYY-MM-DD hh:mm A'),
                        y: d.reportedCount,
                    };
                }),
            },
            ],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    ...prevState.tooltips.callbacks,
                    label: function (tooltipItem: { datasetIndex: number, index: number, xLabel: string, yLabel: number }) {
                        const total = statusItems[tooltipItem.index].notReportedCount + statusItems[tooltipItem.index].reportedCount;
                        const lineType = tooltipItem.datasetIndex === 0 ? 'Not Reporting' : 'Reporting';
                        return `${lineType}: ${tooltipItem.yLabel} (${Math.round(tooltipItem.yLabel * 100 / total)}%)`;
                    },
                },
            },
         
        }));
    }, [statusItems]);


    return (
        <Line
            data={dataState as ChartData}
            height={80}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default HistoricalDataStatusChart;
