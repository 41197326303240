import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { loginSuccess } from './actions/actions';
import BingMapsLoader from './components/shared/BingMapsLoader';
import LoggingIn from './components/shared/LoggingIn';
import Login from './components/shared/Login';
import LogOut from './components/shared/LogOut';
import PrivateRoute from './components/shared/PrivateRoute';
import { defaultRoutePath, groupedRoutes, loggingInPathPrefix, loginPathPrefix, logoutPathPrefix } from './components/shared/routes';
import { TransitIqSidebar } from './components/shared/sidebar';
import TransitIqRootRoute from './components/shared/TransitIqRootRoute';
import UserMenu from './components/shared/UserMenu';
import { AppState } from './reducers';
import configureStore from './store/configureStore';
import { Store } from './types/redux-thunk-extension';
import { isValidUser, saveCurrentUser } from './utilities/user';
import userManager from './utilities/userManager';

Chart.plugins.unregister(ChartDataLabels);

const store: Store<AppState> = configureStore();
store.subscribe(() => {
    const { user } = store.getState();
    saveCurrentUser(user);
});

const { user } = store.getState();
if (isValidUser(user)) {
    store.dispatch(loginSuccess(user));
}

class AppInternal extends Component {
    state = {
        currentUser: null,
    };

    componentDidMount(): void {
        store.subscribe(() => {
            const { user } = store.getState();
            this.setState({
                currentUser: user,
            });
            this.forceUpdate();
        });
        this.subscribeToUserManagerEvents();
    }

    render(): JSX.Element {
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path={loginPathPrefix} component={Login} />
                        <Route path={loggingInPathPrefix} component={LoggingIn} />
                        <Route path={logoutPathPrefix} component={LogOut} />
                        <PrivateRoute>
                            <Container fluid>
                                <TransitIqSidebar user={this.state.currentUser} />
                                <Container fluid className="main">
                                    <Container fluid className="toolbar">
                                        <UserMenu />
                                    </Container>
                                    <Container fluid>
                                        <Route exact path="/">
                                            <Redirect to={defaultRoutePath} />
                                        </Route>                                                                               
                                        {groupedRoutes.map(route => (
                                            <TransitIqRootRoute key={route.label} {...route} />
                                        ))}                               
                                    </Container>
                                </Container>
                            </Container>
                        </PrivateRoute>
                        <Route path="*">
                            <h3>404</h3>
                        </Route>
                    </Switch>
                </Router>
                <BingMapsLoader />
            </Provider>
        );
    }

    private subscribeToUserManagerEvents() {
        userManager.events.addAccessTokenExpiring(() => {
            console.log('Token expired!');
            this.renewToken()
                .then(user => {
                    if (!user) {
                        console.error('APP: Access token renew fail (3)');
                        window.location.href = '/login';
                    }
                });
        });
    }

    private async renewToken() {
        try {
            console.error('APP: renewToken (1)');
            const user = await userManager.signinSilent();
            if (isValidUser(user)) {
                store.dispatch(loginSuccess(user, false));
                //store.dispatch(tokenRenewSuccess(user));
            }
            return user;
        }
        catch (error1) {
            console.error('APP: Access token renew fail (1)', error1);
        }
        //Work around to handle to IFRAME window timeout errors on browsers
        try {
            const user = await userManager.getUser();
            return user;
        }
        catch (error2) {
            console.error('APP: Access token renew fail (2)', error2);
            return;
        }
    }
}

function App() {
    return (
        <AppInternal />
    );
}

export default App;
