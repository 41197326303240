import React from 'react';
import { Table } from 'semantic-ui-react';
import { ColumnHeader, Row } from '../../../types/scheduleAdjustmentTypes';
import ScheduleAdjustmentRow from './ScheduleAdjustmentRow';

const ScheduleAdjustmentTable: React.FC<{ columnHeaders: ColumnHeader[]; rows: Row[] }> = ({ columnHeaders, rows }) => {
    return (
        <div>
            <Table celled striped compact singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3"></Table.HeaderCell>
                        <Table.HeaderCell colSpan={String(columnHeaders.length)}>Stop sequence/ Stop ID</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">Trip Scheduled<br />Times</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Actual<br />OTP</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Proposed<br />OTP</Table.HeaderCell>
                        {columnHeaders.map((column, index) => (
                            <Table.HeaderCell key={index} textAlign="center">
                                <span className="v-order">{column.stopOrdinal}</span>
                                {<br />}
                                {column.stopId}
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <ScheduleAdjustmentRow key={index} row={row} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default React.memo(ScheduleAdjustmentTable);