import { combineReducers } from 'redux';
import { agenciesReducer, agencyReducer } from './agencyReducer';
import alert from './alertReducer';
import bingMaps from './bingMapsReducer';
import blocking from './blockingReducer';
import gtfsFiles from './gtfsFilesReducer';
import user from './userReducer';

const rootReducer = combineReducers({
    // short hand property names
    agencyId: agencyReducer,
    agencies: agenciesReducer,
    gtfsFiles, alert, blocking, user, bingMaps,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;