import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../types/chartTypes';
import { ModelType, modelTypeColors, OverallDataPoint } from './_data';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Adaptive',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.HISTORY].fill,
            borderColor: modelTypeColors[ModelType.HISTORY].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.HISTORY].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Linear',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.LINEAR].fill,
            borderColor: modelTypeColors[ModelType.LINEAR].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.LINEAR].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Legacy',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.SIRI].fill,
            borderColor: modelTypeColors[ModelType.SIRI].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.SIRI].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'day',
                unitStepSize: 1,
            },
            scaleLabel: {
                display: true,
                labelString: 'Service Date',
            },
            distribution: 'linear',
        }],
        yAxes: [{
            scaleLabel: {
                display: true,
                labelString: 'Accuracy, %',
            },
            ticks: {
                beginAtZero: true,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: 'rgba(65,105,225,1)',
                        backgroundColor: 'rgba(65,105,225,1)',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        borderColor: 'rgb(255, 165, 0)',
                        backgroundColor: 'rgb(255, 165, 0)',
                    };
                } else {
                    return {
                        borderColor: 'rgb(60, 179, 113)',
                        backgroundColor: 'rgb(60, 179, 113)',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        crosshair: {
            line: {
                color: '#F66',  // crosshair line color
                width: 1,       // crosshair line width
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: false,                                      // enable zooming
                zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
                zoomboxBorderColor: '#48F',                         // border color of zoom box
                zoomButtonText: 'RESET ZOOM',                       // reset zoom button text
                zoomButtonClass: 'ui button grey mini reset-zoom',      // reset zoom button class
            },
            snap: {
                enabled: true,
            },
        },
    },
};

function mapPoints(data: OverallDataPoint[], model: ModelType): LineChartDatePoint[] {
    return data.map(p => {
        return {
            t: moment(p.date1).format('YYYY-MM-DD'),
            y: p[model] || null,
        };
    });
}

type AccuracyLineChartProps = {
    mode: string | undefined;
    data: OverallDataPoint[]
};

type TypeUnitsMap = { [mode: string]: { unit: string; unitStepSize: number } };

const TimeUnits: TypeUnitsMap = {
    Day: { unit: 'day', unitStepSize: 1 },
    Week: { unit: 'day', unitStepSize: 7 },
    Month: { unit: 'month', unitStepSize: 1 },
};

const AccuracyLineChart: React.FC<AccuracyLineChartProps> = (props) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [chartOptions, setChartOptions] = useState(options);

    useEffect(() => {
        setChartOptions(prevState => {
            const newChartOptions = { ...prevState };
            newChartOptions.scales.xAxes[0].time = TimeUnits[props.mode || 'Day'] || TimeUnits.Day;
            return newChartOptions;
        });
    }, [props.mode]);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [
                { ...prevState.datasets[0], data: mapPoints(props.data, ModelType.HISTORY) },
                { ...prevState.datasets[1], data: mapPoints(props.data, ModelType.LINEAR) },
                { ...prevState.datasets[2], data: mapPoints(props.data, ModelType.SIRI) },
            ],
        }));
    }, [props.data]);

    return (
        <div className="chart-wrapper">
            <Line
                data={dataState as ChartData}
                options={chartOptions as unknown as ChartOptions}
                height={80}
            />
        </div>
    );
};

export default AccuracyLineChart;
