import moment from 'moment';
import * as React from 'react';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { TableSortProperties } from '../../../types/types';
import Utils from '../../../utilities/utils';
import { TripAggregatedPerformance } from './OverallPerformanceForm';

const styles = {
    table: {
        display: 'block',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface TableProps {
    tableDataState: TableSortProperties<TripAggregatedPerformance>;
    routeName: string;
    displayDetailsTripsTable: (tripInternalId: string, tripId: string) => void;
    columnSortHandler: (column: string) => void;
}

const AggregatedTripsTable: React.FC<TableProps> = ({ tableDataState, routeName, displayDetailsTripsTable, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'tripScheduledStart' ? direction : undefined}
                            onClick={() => columnSortHandler('tripScheduledStart')}>
                            Trip for route {routeName}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'performance' ? direction : undefined}
                            onClick={() => columnSortHandler('performance')}>
                            Performance (%)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'fastest' ? direction : undefined}
                            onClick={() => columnSortHandler('fastest')}>
                            Fastest Trip Duration (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'scheduledTripTime' ? direction : undefined}
                            onClick={() => columnSortHandler('scheduledTripTime')}>
                            Scheduled Trip Duration (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'avgDwell' ? direction : undefined}
                            onClick={() => columnSortHandler('avgDwell')}>
                            Avg Dwell (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'inMotion' ? direction : undefined}
                            onClick={() => columnSortHandler('inMotion')}>
                            In Motion (min)
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((tripPerformance, index) => (
                        <AggregatedTripsTableRow key={index} tripInfo={tripPerformance} displayDetailsTripsTable={displayDetailsTripsTable} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default AggregatedTripsTable;

interface RowProps {
    tripInfo: TripAggregatedPerformance;
    displayDetailsTripsTable: (tripInternalId: string, tripId: string) => void;
}

const AggregatedTripsTableRow: React.FC<RowProps> = ({ tripInfo, displayDetailsTripsTable }) => {

    const tripLinkClickHandler = async (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        if (displayDetailsTripsTable)
            displayDetailsTripsTable(tripInfo.tripInternalId, tripInfo.tripId);
    };

    return (
        <Table.Row>
            <Table.Cell>
                <Link to="/" onClick={tripLinkClickHandler}> {tripInfo.tripId} ({moment.parseZone(tripInfo.tripScheduledStart).format('h:mm A')} - {moment.parseZone(tripInfo.tripScheduledFinish).format('h:mm A')})</Link>
            </Table.Cell>
            <Table.Cell>{tripInfo.performance ? Utils.roundNumber(tripInfo.performance, 0) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.fastest ? Utils.convertSecondsToMinutes(tripInfo.fastest) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.scheduledTripTime ? Utils.convertSecondsToMinutes(tripInfo.scheduledTripTime) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.avgDwell ? Utils.convertSecondsToMinutes(tripInfo.avgDwell) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.inMotion ? Utils.convertSecondsToMinutes(tripInfo.inMotion) : ''}</Table.Cell>
        </Table.Row>
    );
};