import { User } from 'oidc-client';
import { GtfsFileStateType } from '../types/gtfsFilesTypes';

export interface AgencyStateType {
    id: string;
    name: string;
    timeZoneName: string;
}
export interface AlertStateType {
    success: boolean;
    text: string;
    title: string;
    show: boolean;
}

export const LOAD_BING_MAPS_SUCCESS = 'LOAD_BING_MAPS_SUCCESS' as const;

export const LOAD_GTFS_SUCCESS = 'LOAD_GTFS_SUCCESS' as const;
export const LOAD_GTFS_ERROR = 'LOAD_GTFS_ERROR' as const;
export const LOAD_AGENCIES_SUCCESS = 'LOAD_AGENCIES_SUCCESS' as const;
export const SELECT_AGENCY = 'SELECT_AGENCY' as const;
export const UPLOAD_CLICK = 'UPLOAD_CLICK' as const;

export const POST_GTFS_SUCCESS = 'POST_GTFS_SUCCESS' as const;
export const POST_GTFS_ERROR = 'POST_GTFS_ERROR' as const;

export const PUBLISH_GTFS = 'PUBLISH_GTFS' as const;
export const PUBLISH_GTFS_SUCCESS = 'PUBLISH_GTFS_SUCCESS' as const;
export const PUBLISH_GTFS_ERROR = 'PUBLISH_GTFS_ERROR' as const;

export const UNPUBLISH_GTFS = 'UNPUBLISH_GTFS' as const;
export const UNPUBLISH_GTFS_SUCCESS = 'UNPUBLISH_GTFS_SUCCESS' as const;
export const UNPUBLISH_GTFS_ERROR = 'UNPUBLISH_GTFS_ERROR' as const;

export const VALIDATE_GTFS = 'VALIDATE_GTFS' as const;
export const VALIDATE_GTFS_SUCCESS = 'VALIDATE_GTFS_SUCCESS' as const;
export const VALIDATE_GTFS_ERROR = 'VALIDATE_GTFS_ERROR' as const;

export const DELETE_GTFS = 'DELETE_GTFS' as const;
export const DELETE_GTFS_SUCCESS = 'DELETE_GTFS_SUCCESS' as const;
export const DELETE_GTFS_ERROR = 'DELETE_GTFS_ERROR' as const;

export const HIDE_ALERT = 'HIDE_ALERT' as const;
export const BEGIN_API_CALL = 'BEGIN_API_CALL' as const;
export const API_CALL_ERROR = 'API_CALL_ERROR' as const;
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS' as const;

export const LOGIN = 'LOGIN' as const;
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS' as const;
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS' as const;
export const LOGIN_ERROR = 'LOGIN_ERROR' as const;
export const LOGOUT = 'LOGOUT' as const;
export const TOKEN_CLEAR = 'TOKEN_CLEAR' as const;

export const SET_USER_AGENCIES = 'SET_USER_AGENCIES' as const;

export const loadBingMapsActionType = {
    type: LOAD_BING_MAPS_SUCCESS,
} as const;
export type LoadBingMapsActionType = typeof loadBingMapsActionType;

interface BeginApiCallActionType {
    type: typeof BEGIN_API_CALL;
}
interface SuccessApiCallActionType {
    type: typeof API_CALL_SUCCESS;
}
interface ErrorApiCallActionType {
    type: typeof API_CALL_ERROR;
}

interface LoadAgenciesSuccessActionType {
    type: typeof LOAD_AGENCIES_SUCCESS;
    agencies: AgencyStateType[];
}
interface SetAgencyIdActionType {
    type: typeof SELECT_AGENCY;
    agencyId: string;
}

interface LoadGtfsFilesSuccessActionType {
    type: typeof LOAD_GTFS_SUCCESS;
    gtfsFiles: GtfsFileStateType[];
}
interface LoadGtfsFilesErrorActionType {
    type: typeof LOAD_GTFS_ERROR;
}
interface PostGtfsFileSuccessActionType {
    type: typeof POST_GTFS_SUCCESS;
}
interface PostGtfsFileErrorActionType {
    type: typeof POST_GTFS_ERROR;
}
interface ValidateGtfsFileSuccessActionType {
    type: typeof VALIDATE_GTFS_SUCCESS;
    gtfsFileId: string;
    isValid: boolean;
}
interface ValidateGtfsFileErrorActionType {
    type: typeof VALIDATE_GTFS_ERROR;
    gtfsFileId: string;
}

export interface HideAlertActionType {
    type: typeof HIDE_ALERT;
}

export interface LoginSuccessActionType {
    type: typeof LOGIN_SUCCESS;
    user: User;
}

export interface LoginErrorActionType {
    type: typeof LOGIN_ERROR;
}

export interface SuccessActionType {
    type: string;
}

export const logoutActionType = {
    type: LOGOUT,
} as const;
export type LogoutActionType = typeof logoutActionType;

export type GtfsFilesActionTypes =
    | LoadGtfsFilesSuccessActionType
    | LoadGtfsFilesErrorActionType
    | PostGtfsFileSuccessActionType
    | PostGtfsFileErrorActionType
    | ValidateGtfsFileSuccessActionType
    | ValidateGtfsFileErrorActionType;
export type AgenciesActionTypes = LoadAgenciesSuccessActionType | SetAgencyIdActionType;
export type ApiCallActionTypes =
    | BeginApiCallActionType
    | SuccessApiCallActionType
    | ErrorApiCallActionType;
export type AlertActionTypes = GtfsFilesActionTypes | HideAlertActionType;
export type BlockingActionTypes =
    | BeginApiCallActionType
    | ValidateGtfsFileErrorActionType
    | PostGtfsFileErrorActionType
    | SuccessActionType;
export type UserActionTypes = LoginSuccessActionType | LoginErrorActionType | LogoutActionType;
