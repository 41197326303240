import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login, loginSuccess } from '../../actions/actions';
import { isLogged } from '../../selectors';

class Special extends React.Component {
    constructor(props) {
        super(props);
        console.log('Special -constructor');
    }
    
    render() {
        return(<Redirect to="/gtfsFiles" />);
    }
}

const mapStateToSpecialProps = (state) => (
    {
        isLogged: isLogged(state),
    }
);

export default connect(
    mapStateToSpecialProps,
)(Special);
