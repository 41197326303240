import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: 'GTFS',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(66, 220, 198)',
            pointBackgroundColor: 'rgb(66, 220, 198)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198)',
            pointHoverBorderColor: 'rgba(66, 220, 198)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'TAIP',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#C0E139',
            borderColor: '#C0E139',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#C0E139',
            pointBackgroundColor: '#C0E139',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#C0E139',
            pointHoverBorderColor: '#C0E139',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            autoSkip: false,
            maxRotation: 0,
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                autoSkip: false,
                beginAtZero: true,
                stepSize: 1,
                maxTicksLimit: 10,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                let lineType = 'GTFS';
                if (tooltipItem.datasetIndex === 1) {
                    lineType = 'TAIP';
                }
                return `${lineType}: ${tooltipItem.yLabel} %`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        backgroundColor: '#42DCC6',
                        borderColor: '#42DCC6',
                    };
                } else {
                    return {
                        backgroundColor: '#C0E139',
                        borderColor: '#C0E139',
                    };
                }
            },
        },
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
};

interface Props {
    chartData: {
        gtfsValue: number;
        taipValue: number;
        period: string
    }[];
    axesLabelSign?: string;
}

const TrendsReportingBusesChart: React.FC<Props> = ({ chartData, axesLabelSign }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.gtfsValue,
                    };
                }),
            },
            {
                ...prevState.datasets[1], data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.taipValue,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                yAxes: [{
                    ...prevState.scales.yAxes[0],
                    ticks: {
                        ...prevState.scales.yAxes[0].ticks,
                        callback: function (value: string) {
                            return axesLabelSign ? `${value} ${axesLabelSign}` : value;
                        },
                    },
                }],
            },          

        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default TrendsReportingBusesChart;
