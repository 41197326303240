import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { Table } from 'semantic-ui-react';
import GtfsControls from './GtfsControls';
import { ModalSchedulerGtfs } from './ModalSchedulerGtfs';

const isAdmin = (roles) => {
    if (roles === undefined)
        return false;
    if (typeof (roles) === 'string')
        return roles.toLowerCase() === 'admin';
    if (Array.isArray(roles))
        return roles
            .map(x => x.toLowerCase())
            .includes('admin');
    return false;
};

const validGtfsStatus = (gtfs) => {
    return (gtfs.status === 'Scheduled' || gtfs.status === 'New' || gtfs.status === 'Unpublished');
};

const isDisabled = (roles, gtfs) => {
    return !isAdmin(roles) || !gtfs.valid || !validGtfsStatus(gtfs);
};

const isClearable = (roles, gtfs) => {
    return !isDisabled(roles, gtfs) && gtfs.activationDate && gtfs.status != 'Scheduled';
};

let GtfsRow = ({
    gtfs,
    roles,
    updateActivationDate,
    onGtfsViewClick,
    onDetailsClick,
    onScheduleClick,
}) =>
    <tr>
        <Table.Cell><a onClick={(e) => { e.preventDefault(); onGtfsViewClick(gtfs.gtfsFileId); }} style={{ minWidth: 320 }} href="" >{gtfs.gtfsFileId}</a> </Table.Cell>
        <Table.Cell>{gtfs.originalName}</Table.Cell>
        <Table.Cell>{gtfs.status}</Table.Cell>
        <Table.Cell>{gtfs.valid === true ? 'Valid' : gtfs.valid === false ? 'Invalid' : ''}</Table.Cell>
        <Table.Cell className="td-nowrap" >{moment(gtfs.uploadedUtc).format('lll')}</Table.Cell>
        <Table.Cell className="td-nowrap" >{gtfs.calendarFrom ? moment(gtfs.calendarFrom).format('ll') : ''}</Table.Cell>
        <Table.Cell className="td-nowrap" >{gtfs.calendarTo ? moment(gtfs.calendarTo).format('ll') : ''}</Table.Cell>
        <Table.Cell style={{ paddingTop: 0, paddingBottom: 0 }}>
            <DateTimeInput
                name="activationDateCalendar"
                fluid
                clearable={isClearable(roles, gtfs)}
                onClear={(event, { value }) => updateActivationDate(gtfs.gtfsFileId, value)}
                dateFormat="YYYY-MM-DD"
                timeFormat="AMPM"
                value={gtfs.activationDate ? moment.parseZone(gtfs.activationDate).format('YYYY-MM-DD h:mm A') : 'Immediately'}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                disabled={isDisabled(roles, gtfs)}
                onChange={(event, { value }) => updateActivationDate(gtfs.gtfsFileId, value)}
                style={{ width: '190px' }}
            />
        </Table.Cell>
        {isAdmin(roles) ?
            <Table.Cell className="td-nowrap">
                <GtfsControls gtfsFile={gtfs} onDetailsClick={() => onDetailsClick(gtfs.gtfsFileId)} onScheduleClick={() => onScheduleClick(gtfs)} />
            </Table.Cell>
            : null}
    </tr>;


class GtfsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gtfs: null,
            modalVisibleState: false,
        };
    }

    setModalVisibleState = (gtfs, value) => {
        this.setState({
            gtfs: gtfs,
            modalVisibleState: value,
        });
    };

    onSchedulerClick = (gtfs) => {
        this.setModalVisibleState(gtfs, true);
    };

    onUpdateDate = (gtfsId, value) => {
        this.setState({
            modalVisibleState: false,
        });
        this.props.updateActivationDate(gtfsId, value);
    };

    render() {
        const gtfsFiles = this.props.gtfsFiles.length > 0
            ? this.props.gtfsFiles.map((gtfs, index) => (
                <GtfsRow key={index} gtfs={gtfs} roles={this.props.role}
                    updateActivationDate={this.props.updateActivationDate}
                    onDetailsClick={this.props.onDetailsClick}
                    onGtfsViewClick={this.props.onGtfsViewClick}
                    onScheduleClick={this.onSchedulerClick}
                />
            ))
            : <tr></tr>;

        return (
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Cloud Name</Table.HeaderCell>
                            <Table.HeaderCell>Original Name</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Validity</Table.HeaderCell>
                            <Table.HeaderCell>Uploaded Utc</Table.HeaderCell>
                            <Table.HeaderCell className="td-nowrap">Calendar From</Table.HeaderCell>
                            <Table.HeaderCell>Calendar To</Table.HeaderCell>
                            <Table.HeaderCell>Activation Date</Table.HeaderCell>
                            {isAdmin(this.props.role) ? <Table.HeaderCell></Table.HeaderCell> : null}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {gtfsFiles}
                    </Table.Body>
                </Table>
        );
    }
}

export default GtfsTable;