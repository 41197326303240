import { getPredictionAccuracyKpi, getPredictionAccuracyKpiForCustomPeriod } from '../../../actions/delaysAnalysisActions';
import { DistributionData, KpiSet, OverallHourly, PredictionAccuracyKpi } from '../../../types/delaysAnalysisTypes';

export enum ModelType { HISTORY, LINEAR, SIRI }

export type HourlyReportData = { [key in ModelType]?: OverallHourly };

export type KpiBunch = { [key in ModelType]?: DistributionData };

type KpiPoint = { [key in ModelType]?: KpiSet } & {
    id: string;
    date1: string;
    date2: string;
};

export type ReportKpiData = {
    Day: ModeEntry;
    Week: ModeEntry;
    Month: ModeEntry;
} & {
    [mode: string]: ModeEntry;
};

export type BaseKpiSet = {
    id: string;
    bunchData: KpiBunch;
    pointData: KpiPoint[];
};

export type ModeEntry = BaseKpiSet & {
    routes: { [is: string]: RouteKpiSet };
};

export type RouteKpiSet = BaseKpiSet & {
    directions: { [is: string]: DirectionKpiSet };
};

export type DirectionKpiSet = BaseKpiSet & {
    tripHeadsign: string;
    otsTripShortName: string;
};

type KpiPointMap = { [date: string]: KpiPoint };

function addKpiPoint(pointsMap: KpiPointMap,
    uniquePointKeyM: string,
    points: KpiPoint[], id: string,
    model: ModelType, date1: string, date2: string, kpi: KpiSet,
) {
    let point = pointsMap[uniquePointKeyM];
    if (!point) {
        point = { id, date1, date2 };
        pointsMap[uniquePointKeyM] = point;
        points.push(point);
    }
    point[model] = kpi;
}

function addKpiBunch(kpiSet: BaseKpiSet, model: ModelType, kpi: KpiSet) {
    let dd = kpiSet.bunchData[model];
    if (!dd) {
        dd = {
            shortTotal: 0,
            shortAccurate: 0,
            mid1Total: 0,
            mid1Accurate: 0,
            mid2Total: 0,
            mid2Accurate: 0,
            longTotal: 0,
            longAccurate: 0,
        };
        kpiSet.bunchData[model] = kpi;
    }
    dd.shortTotal += kpi.shortTotal;
    dd.shortAccurate += kpi.shortAccurate,
        dd.mid1Total += kpi.mid1Total;
    dd.mid1Accurate += kpi.mid1Accurate;
    dd.mid2Total += kpi.mid2Total;
    dd.mid2Accurate += kpi.mid2Accurate;
    dd.longTotal += kpi.longTotal;
    dd.longAccurate += kpi.longAccurate;
}

export async function getPredictionAccuracyReportData(agencyId: string): Promise<ReportKpiData> {
    const report = await getPredictionAccuracyKpi(agencyId);
    const robj: ReportKpiData = {
        Day: { id: 'Day', bunchData: {}, pointData: [], routes: {} },
        Week: { id: 'Week', bunchData: {}, pointData: [], routes: {} },
        Month: { id: 'Month', bunchData: {}, pointData: [], routes: {} },
    };
    if (report) {
        buildModeEntry(robj.Day, report.days);
        buildModeEntry(robj.Week, report.weeks);
        buildModeEntry(robj.Month, report.months);
    }
    return robj;
}

export async function getPredictionAccuracyCustomPeriodReportData(agencyId: string, fromDate: string, toDate: string): Promise<ReportKpiData> {
    const report = await getPredictionAccuracyKpiForCustomPeriod(agencyId, fromDate, toDate);
    const robj: ReportKpiData = {
        Day: { id: 'Day', bunchData: {}, pointData: [], routes: {} },
        Week: { id: 'Week', bunchData: {}, pointData: [], routes: {} },
        Month: { id: 'Month', bunchData: {}, pointData: [], routes: {} },
        Custom: { id: 'Day', bunchData: {}, pointData: [], routes: {} },
    };
    if (report) {
        buildModeEntry(robj.Custom, report.days);
    }
    return robj;
}

function buildModeEntry(modeEntry: ModeEntry, sourceData: PredictionAccuracyKpi[]) {
    const pointMap: KpiPointMap = {};
    for (const srcM of sourceData) {
        const date1 = srcM.startDate.substr(0, 10);
        const date2 = srcM.endDate.substr(0, 10);
        const model = srcM.model === 'History'
            ? ModelType.HISTORY
            : srcM.model === 'Linear'
                ? ModelType.LINEAR
                : srcM.model === 'Siri'
                    ? ModelType.SIRI
                    : (-1) as ModelType;

        addKpiBunch(modeEntry, model, srcM.kpi);

        const uniquePointKeyM = `${date1}-${modeEntry.id}`;
        addKpiPoint(pointMap, uniquePointKeyM, modeEntry.pointData, modeEntry.id, model, date1, date2, srcM.kpi);

        for (const srcR of srcM.routes) {
            let kpiSetR = modeEntry.routes[srcR.route];
            if (!kpiSetR) {
                kpiSetR = {
                    id: srcR.route,
                    bunchData: {},
                    pointData: [],
                    directions: {},
                };
                modeEntry.routes[srcR.route] = kpiSetR;
            }
            addKpiBunch(kpiSetR, model, srcR.kpi);

            const uniquePointKeyR = `${uniquePointKeyM}:${kpiSetR.id}`;
            addKpiPoint(pointMap, uniquePointKeyR, kpiSetR.pointData, kpiSetR.id, model, date1, date2, srcR.kpi);

            for (const srcD of srcR.directions) {
                let kpiSetD = kpiSetR.directions[srcD.directionVariantInternalId];
                if (!kpiSetD) {
                    kpiSetD = {
                        id: srcD.directionVariantInternalId,
                        tripHeadsign: srcD.tripHeadsign,
                        otsTripShortName: srcD.otsTripShortName,
                        bunchData: {},
                        pointData: [],
                    };
                    kpiSetR.directions[kpiSetD.id] = kpiSetD;
                }
                addKpiBunch(kpiSetD, model, srcD.kpi);

                const uniquePointKeyD = `${uniquePointKeyR}:${kpiSetD.id}`;
                addKpiPoint(pointMap, uniquePointKeyD, kpiSetD.pointData, kpiSetD.id, model, date1, date2, srcD.kpi);
            }
        }
    }
    modeEntry.pointData.sort((a, b) => a.date1.localeCompare(b.date1));
}

export type OverallDataPoint = { [key in ModelType]?: number | null; } & {
    date1: string;
    date2: string;
};

function setOverallValue(dst: OverallDataPoint, src: KpiPoint, model: ModelType) {
    const kpi = src[model];
    if (kpi) {
        const value = getPercentageValue(kpi.overallAccurate, kpi.overallTotal);
        if (value != null)
            dst[model] = value;
    }
}

export function collectOverallData(kpiPoints: KpiPoint[]) {
    const array: OverallDataPoint[] = [];
    for (const s of kpiPoints) {
        const p: OverallDataPoint = {
            date1: s.date1,
            date2: s.date2,
        };
        array.push(p);
        setOverallValue(p, s, ModelType.HISTORY);
        setOverallValue(p, s, ModelType.LINEAR);
        setOverallValue(p, s, ModelType.SIRI);
    }
    array.sort((a, b) => a.date1.localeCompare(b.date1));
    return array;
}

const precisionScaler = 1000;

export function getPercentageValue(accurate: number | null | undefined, total: number | null | undefined) {
    if (typeof accurate !== 'number' || typeof total !== 'number') return null;
    if (accurate === 0) return 0;
    return Math.round((100.0 * precisionScaler * accurate) / total) / precisionScaler;
}

export type ModelTypeSet = {
    base: string;
    lite: string;
    dark: string;
    fill: string;
};

export const modelTypeColors: { [sort in ModelType]: ModelTypeSet } = {
    [ModelType.HISTORY]: {
        fill: 'rgba(65,105,225, 0.6)',
        base: 'rgba(65,105,225, 0.8)',
        lite: 'rgba(65,105,225, 0.8)',
        dark: 'rgba(65,105,225, 1.0)',
    },
    [ModelType.LINEAR]: {
        fill: 'rgba(255,165,0, 0.6)',
        base: 'rgba(255,165,0, 0.8)',
        lite: 'rgba(255,165,0, 0.8)',
        dark: 'rgba(255,165,0, 1.0)',
    },
    [ModelType.SIRI]: {
        fill: 'rgba(60,179,113, 0.6)',
        base: 'rgba(60,179,113, 0.8)',
        lite: 'rgba(60,179,113, 0.8)',
        dark: 'rgba(60,179,113, 1.0)',
    },
};
