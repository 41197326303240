import { OperationResult, RequestParameters } from '../types/apiTypes';
import { ServiceAlert } from '../types/serviceAlertsTypes';
import api from './api';
import { buildUrlString } from './helpers';

class AlertsApi {
    static alertsGetRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/serviceAlerts/${url}`);
    static doAuthPostRequestAsync = async <T, R>(url: string, data?: T, parameters?: RequestParameters) => await api.doAuthPostRequestAsync<T, R>('/serviceAlerts/' + url, data, parameters);

    static async getAlertsForPeriod(agencyId: string, startDate: string, endDate: string) {
        const url = buildUrlString(null, 'getServiceAlerts', agencyId, 'daterange', startDate, endDate);
        return await this.alertsGetRequest<OperationResult<ServiceAlert[]>>(url);
    }
    static async saveServiceAlert(alert: ServiceAlert) {
        const url = buildUrlString(null, 'saveServiceAlert');
        return await this.doAuthPostRequestAsync<ServiceAlert, OperationResult<ServiceAlert>>(url, alert);
    }
    static async deleteServiceAlert(alertId: number) {
        const url = buildUrlString(null, 'deleteServiceAlert', String(alertId));
        return await this.doAuthPostRequestAsync<ServiceAlert, string>(url);
    }
}

export default AlertsApi;