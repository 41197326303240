import moment from 'moment';
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { MINUTE } from './_models';
import { BusPositionHistoryContext } from './BusHistoryContext';
import StopPredictionsView from './StopPredictions';

const lo = -5 * MINUTE;

export default function BusHistorySchedule() {
    const context = React.useContext(BusPositionHistoryContext);
    const loading = context.stopsState.loadingSchedule;
    const schedule = context?.scheduleDates;
    if (!schedule && !loading) {
        return null;
    }

    const checkTime = context.sliderInfo?.vehiclePosition && moment.parseZone(context.sliderInfo?.vehiclePosition.reportTimeAtz).valueOf() || -1;
    const notFound = schedule && schedule.length === 0;
    return (
        <div className="schedule-view">
            <div className="head">
                Schedule
                {notFound ? <small><br />not found</small> : null}
            </div>
            {loading
                ? <Loader active inline="centered" />
                : notFound
                    ? null :
                    <div className="body">
                        {schedule?.map((date, index) => {
                            let cn = 'time';
                            const delta = date.value - checkTime;
                            if (delta < lo) { cn += ' passed'; }
                            //else if (delta > hi) { }
                            else cn += ' in-range';
                            return (
                                <div className={cn} key={index}>{date.format('LT')}</div>
                            );
                        })}
                    </div>
            }
            <StopPredictionsView />
        </div>
    );
}
