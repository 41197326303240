import { ChartData, ChartOptions } from 'chart.js';
import * as crosshair from 'chartjs-plugin-crosshair';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDataPoint } from '../../../types/chartTypes';
import { RunningTimesComparisonChartType } from '../../../types/runningTimeAnalyticsTypes';
import Utils from '../../../utilities/utils';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: '',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#2185d0',
            borderColor: '#2185d0',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#2185d0',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#2185d0',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
        {
            label: '',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#21ba45',
            borderColor: '#21ba45',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#21ba45',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#21ba45',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
    },
    scales: {
        xAxes: [{
            type: 'linear',
            autoSkip: false,
            ticks: {
                stepSize: 5,
            },
            scaleLabel: {
                display: true,
                labelString: 'Average Run-Times (minutes)',
            },
        }],
        yAxes: [{
            type: 'linear',
            autoSkip: false,
            ticks: {
                stepSize: 1,
            },
            scaleLabel: {
                display: true,
                labelString: 'Distance (miles)',
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 110,
        callbacks: {
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: '#2185d0',
                        backgroundColor: '#2185d0',
                    };
                } else {
                    return {
                        borderColor: '#21ba45',
                        backgroundColor: '#21ba45',
                    };
                }
            },
            title: function () {
                return '';
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
};


const RunningTimesComparisonChart: React.FC<RunningTimesComparisonChartType> = ({ runningTimesComparisonData, line1Label, line2Label }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    useEffect(() => {
        const tripStops1 = runningTimesComparisonData && runningTimesComparisonData.tripsData1
            ? runningTimesComparisonData.tripsData1.sort((a, b) => a.stopSequence - b.stopSequence)
            : [];
        const tripStops2 = runningTimesComparisonData && runningTimesComparisonData.tripsData2
            ? runningTimesComparisonData.tripsData2.sort((a, b) => a.stopSequence - b.stopSequence)
            : [];
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], label: line1Label, data: tripStops1.map(s => {
                    return {
                        x: Utils.roundNumber(s.travelSec / 60, 0),
                        y: Utils.kmToMiles(s.traveledKm),
                    };
                }),
            }, {
                ...prevState.datasets[1], label: line2Label, data: tripStops2.map(s => {
                    return {
                        x: Utils.roundNumber(s.travelSec / 60, 0),
                        y: Utils.kmToMiles(s.traveledKm),
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips, callbacks: {
                    ...prevState.tooltips.callbacks, label: function ({ datasetIndex, index, xLabel, yLabel } : { datasetIndex: number, index: number, xLabel: number, yLabel: number }) {
                        const stopsData = datasetIndex === 0 ? tripStops1 : tripStops2;
                        const stop = stopsData[index];
                        return stop ?
                            `Run-Time: ${Utils.convertSecondsToMinutes(stop.travelSec, 'm', 's')} @ ${yLabel} mil; Stop: ${stop.stopName} (${stop.stopId}); Sequence: ${stop.stopSequence}` :
                            `Run-Time: ${Utils.convertSecondsToMinutes(xLabel * 60, 'm', 's')} @ ${yLabel.toFixed(2)} mil`;
                    },
                },
            },
        }));
    }, [runningTimesComparisonData]);

    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={optionsState as unknown as ChartOptions}
            plugins={[crosshair]}
        />
    );
};

export default RunningTimesComparisonChart;