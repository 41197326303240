import moment from 'moment';
import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Button, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getVehiclesStatistics } from '../../../actions/vehiclesHistoryActions';
import { AppState } from '../../../reducers/index';
import { getSelectedOrDefaultAgency } from '../../../selectors/index';
import useTableSorting from '../../hooks/useTableSorting';
import StartAndEndDatesField from '../../shared/StartAndEndDatesField';
import ServiceTimeBreakdownChart from './ServiceTimeBreakdownChart';
import tableSortReducer from './tableSortReducer';
import TotalServiceTimeBreakdownChart from './TotalServiceTimeBreakdownChart';
import VehiclesCountChart from './VehiclesCountChart';
import VehiclesStatisticsTable from './VehiclesStatisticsTable';
import vehicleStatisticsReducer, { vehicleStatisticsDefaultValue } from './vehicleStatisticsReducer';

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    bottomContainer: {
        paddingBottom: '14px',
    } as React.CSSProperties,
};

const VehiclesStatisticsForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [fromDateState, setFromDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-8, 'days').format('YYYY-MM-DD'));
    const [toDateState, setToDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [vehicleStatistics, vehicleStatisticsDispatch] = useReducer(vehicleStatisticsReducer, vehicleStatisticsDefaultValue);
    const { tableDataState, handleTableColumnSort } = useTableSorting(tableSortReducer, vehicleStatistics.vehiclesAggregatedItems);

    useEffect(() => {
        (async () => await retrieveVehiclesStatistics(fromDateState, toDateState))();
    }, [agency?.id]);

    const retrieveVehiclesStatistics = async (fromDate: string, toDate: string) => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const vehiclesData = await getVehiclesStatistics(agency.id, fromDate, toDate);
                vehicleStatisticsDispatch({ type: 'SET_STATISTICS', items: vehiclesData });
            } catch {
                vehicleStatisticsDispatch({ type: 'SET_STATISTICS', items: [] });
            } finally {
                setFormBlockingState(false);
            }
        } else {
            vehicleStatisticsDispatch({ type: 'SET_STATISTICS', items: [] });
        }
    };

    const handleVehicleClick = (selectedVehicleId: string) => {
        vehicleStatisticsDispatch({ type: 'SET_SELECTED_VEHICLE', selectedVehicleId });
    };
    const handleApplyButtonClick = async () => {
        await retrieveVehiclesStatistics(fromDateState, toDateState);
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Vehicle Utilization
                </Header>
                <Form.Group inline className="inputGroup">
                    <StartAndEndDatesField {...{
                        startDate: fromDateState,
                        setStartDate: setFromDateState,
                        endDate: toDateState,
                        setEndDate: setToDateState,
                    }} />
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={handleApplyButtonClick}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <label className="categoryHeader">
                            <div>Detected Vehicles</div>
                            <div className="categorySubtitle">Vehicles reporting at least one position during a service day. Some vehicles may report data from garages, where trips are not assigned (yet)</div>
                        </label>
                        <VehiclesCountChart chartData={vehicleStatistics.vehiclesCountChartItems} />
                    </Form.Field>
                    <Form.Field width={8}>
                        <label className="categoryHeader">
                            <div>Vehicles Service Time Breakdown</div>
                            <div className="categorySubtitle">Vehicles reporting at least one position during a service day.<br />Time is averaged.</div>
                        </label>
                        <ServiceTimeBreakdownChart chartData={vehicleStatistics.serviceAvgItems} />
                    </Form.Field>
                </Form.Group>
                {tableDataState.data.length > 0 && <Form.Group style={styles.bottomContainer}>
                    <Form.Field width={16}>
                        <label className="categoryHeader">
                            <div>Vehicle Stats</div>
                            <div className="categorySubtitle">{`Total time and distance traveled for the period from ${moment(fromDateState).format('MMMM DD, YYYY')} to ${moment(toDateState).format('MMMM DD, YYYY')}. Click on a row to display details.`}</div>
                        </label>
                        <VehiclesStatisticsTable tableDataState={tableDataState} selectedVehicleId={vehicleStatistics.selectedVehicleId} selectedVehicleHandler={handleVehicleClick} columnSortHandler={handleTableColumnSort} />
                    </Form.Field>
                </Form.Group>}
                {vehicleStatistics.selectedVehicleId && <Form.Group style={styles.bottomContainer} >
                    <Form.Field width={8}>
                        <label className="categoryHeader">
                            <div>{`Vehicle #${vehicleStatistics.selectedVehicleId} Service Time Breakdown`}</div>
                            <div className="categorySubtitle">Vehicles reporting at least one position during a service day.<br />Time is averaged.</div>
                        </label>
                        <ServiceTimeBreakdownChart chartData={vehicleStatistics.vehicleServiceItems} />
                    </Form.Field>
                    {vehicleStatistics.comparisonValues && <Form.Field width={8}>
                        <label className="categoryHeader">
                            <div>{`Vehicle #${vehicleStatistics.selectedVehicleId} Total Service Time Breakdown`}</div>
                            <div className="categorySubtitle">Comparing averages for selected vehicle (blue) against averages for the entire fleet (red)</div>
                        </label>
                        <TotalServiceTimeBreakdownChart chartData={vehicleStatistics.comparisonValues} />
                    </Form.Field>}
                </Form.Group>}
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(VehiclesStatisticsForm);