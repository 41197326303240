import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DeviationForPeriod } from '../../../../types/delayStatisticsTypes';
import Utils from '../../../../utilities/utils';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Average Deviation (min)',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
        text: 'Deviation by WeekDay',
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            gridLines: {
                drawOnChartArea: false,
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function (value: string) {
                    return `${Math.round((value as unknown as number) / 60 * 10) / 10} min`;
                },
                stepSize: 60,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
    },
    responsive: true,
    plugins: {
        crosshair: false,
    },
};

const DeviationByWeekDayChart: React.FC<{ chartData: DeviationForPeriod[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = chartData.map(d => d.period);
        const values = chartData.map(d => d.deviation);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0], data: values,
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    label: function ({ index }: { index: number }) {
                        return `Avg Deviation: ${Utils.convertSecondsToMinutes(values[index], 'm', 's')}`;
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState as ChartData}
            height={200}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default DeviationByWeekDayChart;