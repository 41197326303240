export const LOCAL_STORAGE_KEY_FOR_AGENCY = 'SELECTED_AGENCY' as const;

export class AgencyStorage {
    static get agencyId(): string | null {
        return localStorage.getItem(LOCAL_STORAGE_KEY_FOR_AGENCY);
    }
    static set agencyId(agencyId: string | null) {
        if (agencyId)
            localStorage.setItem(LOCAL_STORAGE_KEY_FOR_AGENCY, agencyId);
    }
}

