export interface GtfsFileStateType {
    gtfsFileId: string;
    originalName: string;
    status: string;
    valid: boolean;
    uploadedUtc: Date;
    calendarFrom: Date;
    calendarTo: Date;
    activationDate: Date;
    validationMessage: string;
}

export interface UpdateGtfsModel {
    agencyId: string;
    activationDate?: string | null;
    operation: GtfsFileOperations,
}
export interface CreateGtfsModel {
    agencyId: string;
    merge?: boolean;
    file: File;
}

export enum GtfsFileOperations {
    Publish = 0,
    Unpublish = 1,
    Validate = 2,
    Schedule = 3,
    UpdateActivationDate = 4
}

export interface TripsTimesType {
    tripId: string;
    startTime: string;
    endTime: string;
}