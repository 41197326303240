import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { BunchingStatisticsArrivals } from '../../../../types/busBunchingTypes';
import { BunchingTableSelectedData } from './BunchingStatisticsForm';

interface Props {
    row: BunchingStatisticsArrivals,
    selectedData: BunchingTableSelectedData | undefined,
    selectedRowHandler: (selectedRow: BunchingStatisticsArrivals) => Promise<void>
}

const BunchingTableRow: React.FC<Props> = ({ row, selectedData, selectedRowHandler }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedData !== undefined && selectedData.tripId1 === row.tripId1 && selectedData.tripId2 === row.tripId2 && selectedData.bunchTime === row.bunchTime}>
            <Table.Cell textAlign="left">{
                moment.parseZone(row.bunchTime).format('M/D/YYYY h:mm A')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.routeName} - {row.directionName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.tripId1} ({moment.parseZone(row.tripScheduledStart1).format('h:mm A')} - {moment.parseZone(row.tripScheduledFinish1).format('h:mm A')})
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.tripId2} ({moment.parseZone(row.tripScheduledStart2).format('h:mm A')} - {moment.parseZone(row.tripScheduledFinish2).format('h:mm A')})
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.vehicleId1}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.vehicleId2}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.scheduledDiffSec}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.actualDiffSec}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.actualScheduledDeltaPcnt}
            </Table.Cell>
        </Table.Row>
    );
};

export default BunchingTableRow;