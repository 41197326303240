import React from 'react';
import { Header } from 'semantic-ui-react';
import { logo_iq, logo_transit_iq } from './svg-consts';

export const LogoFullTransitIq: React.FC<ExactlyAnEmptyObject> = () => (
    <Header as="h1" className="transit-iq-logo">
        <img src={logo_transit_iq.data} alt="Transit IQ" />
    </Header>
);

export const LogoIqOnly: React.FC<ExactlyAnEmptyObject> = () => (
    <Header as="h1" className="iq-logo">
        <img src={logo_iq.data} alt="IQ" width={logo_iq.width} height={logo_iq.height} />
    </Header>
);
