import { RtDelaysNow } from '../../../types/delaysNowTypes';


export interface DeviationDistribution {
    period: string,
    early: number | null;
    onTime: number | null;
    late: number | null;
    veryLate: number | null;
    notReporting: number | null;
}

export enum DelaySort {
    Early = 0,    
    OnTime = 10,    
    Late = 20,
    VeryLate = 30,
    NotReporting = 40,
}

export type DelayStatusColors = { [sort in DelaySort]: string };

export type DelayColorSet = {
    base: string;
    lite: string;
    dark: string;
    fill: string;
};

export interface DelaysTimePoint {
    time: string;
    data: RtDelaysNow[];
}

export const delayStatusColors: { [sort in DelaySort]: DelayColorSet } = {
    [DelaySort.Early]: {
        base: 'rgb(130, 225, 128)',
        lite: 'rgb(130, 225, 128)',
        dark: 'rgb(55, 208, 53)',
        fill: 'rgba(130, 225, 128, 0.5)',
    },
    [DelaySort.OnTime]: {
        base: 'rgb(49, 220, 195)',
        lite: 'rgb(49, 220, 195)',
        dark: 'rgb(32, 188, 164)',
        fill: 'rgba(49, 220, 195, 0.5)',
    },
    [DelaySort.Late]: {
        base: 'rgb(255, 192, 97)',
        lite: 'rgb(255, 192, 97)',
        dark: 'rgb(251, 160, 14)',
        fill: 'rgba(255, 192, 97, 0.5)',
    },
    [DelaySort.VeryLate]: {
        base: 'rgb(255, 131, 104)',
        lite: 'rgb(255, 131, 104)',
        dark: 'rgb(248, 60, 18)',
        fill: 'rgba(255, 131, 104, 0.5)',
    },
    [DelaySort.NotReporting]: {
        base: 'rgb(85, 37, 37)',
        lite: 'rgb(85, 37, 37)',
        dark: 'rgb(85, 37, 37)',
        fill: 'rgb(85, 37, 37)',
    },
};

