import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Thresholds } from './_interfaces';
import { defaultThresholds } from './_thresholds';

const useStyles = makeStyles({
    space: {
        height: 6,
        margin: '0 0.25em 6px 0.25em',
        display: 'inline-block',
        minWidth: 30,
        verticalAlign: 'bottom',
        opacity: 0.5,
    },
    danger: {
        backgroundColor: 'red',
    },
    warning: {
        backgroundColor: '#ffa500',
    },
    success: {
        backgroundColor: '#00b315',
    },
});

const TimeRangeSlider = withStyles({
    root: {
        color: '#2185d0',
        height: 3,
        padding: '13px 0',
    },
})(Slider);

interface ThresholdsSelectorProps {
    data: Thresholds;
    onChange: (data: Thresholds) => void;
}

function fmt(value: number): string {
    return ('00' + value).substr(-2);
}

function valueLabelFormat(value: number, _index: number): React.ReactNode {
    const seconds = value % 60;
    const minutes = Math.floor(value / 60);
    return `${fmt(minutes)}:${fmt(seconds)}`;
}

export default function ThresholdsSelector(props: ThresholdsSelectorProps) {
    const css = useStyles();

    const [open, setOpen] = React.useState(false);
    const [BE, setBE] = React.useState(defaultThresholds.bunchedError);
    const [BW, setBW] = React.useState(defaultThresholds.bunchedWarning);
    const [SE, setSE] = React.useState(defaultThresholds.spreadError);
    const [SW, setSW] = React.useState(defaultThresholds.spreadWarning);

    React.useEffect(() => {
        setBE(props.data.bunchedError);
        setBW(props.data.bunchedWarning);
        setSE(props.data.spreadError);
        setSW(props.data.spreadWarning);
    }, [props.data.bunchedError, props.data.bunchedWarning, props.data.spreadError, props.data.spreadWarning]);

    const notOriginal =
        props.data.bunchedError !== BE ||
        props.data.bunchedWarning !== BW ||
        props.data.spreadError !== SE ||
        props.data.spreadWarning !== SW;

    const notDefault =
        defaultThresholds.bunchedError !== BE ||
        defaultThresholds.bunchedWarning !== BW ||
        defaultThresholds.spreadError !== SE ||
        defaultThresholds.spreadWarning !== SW;

    const handleShowCheckbox = () => {
        setOpen(!open);
    };
    const handleDefaultClick = () => {
        setBE(defaultThresholds.bunchedError);
        setBW(defaultThresholds.bunchedWarning);
        setSE(defaultThresholds.spreadError);
        setSW(defaultThresholds.spreadWarning);
    };
    const handleResetClick = () => {
        setBE(props.data.bunchedError);
        setBW(props.data.bunchedWarning);
        setSE(props.data.spreadError);
        setSW(props.data.spreadWarning);
    };
    const handleApplyClick = () => {
        setOpen(false);
        if (notOriginal) {
            props.onChange({
                bunchedError: BE,
                bunchedWarning: BW,
                spreadError: SE,
                spreadWarning: SW,
            });
        }
    };

    return (
        <React.Fragment>
            <div className="ui floating dropdown" style={{ float: 'left' }}>
                <Button basic size="small" icon="bars" onClick={handleShowCheckbox} />
                <div className="menu" style={{ padding: '0.5em', display: open ? 'block' : 'none', zIndex: 110 }}>
                    <h3>Headway Thresholds</h3>
                    <Grid style={{ textAlign: 'center', borderTop: '1px solid #ccc', marginTop: '-0.5em', padding: '0.5em' }}>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <h5>Bunched</h5>
                                <div>
                                    <span className={`${css.space} ${css.danger}`}>&nbsp;</span>
                                    {valueLabelFormat(BE, 0)}
                                    <span className={`${css.space} ${css.warning}`}>&nbsp;</span>
                                    {valueLabelFormat(BW, 1)}
                                    <span className={`${css.space} ${css.success}`}>&nbsp;</span>
                                </div>
                                <TimeRangeSlider
                                    getAriaLabel={(index: any) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                    valueLabelFormat={valueLabelFormat}
                                    min={0}
                                    max={9 * 60 + 45}
                                    step={15}
                                    defaultValue={[props.data.bunchedError, props.data.bunchedWarning]}
                                    value={[BE, BW]}
                                    onChange={(_event: React.ChangeEvent<{}>, value: number | number[]) => {
                                        if (Array.isArray(value)) {
                                            setBE(value[0]);
                                            setBW(value[1]);
                                        }
                                    }}
                                />
                                <div style={{ textAlign: 'left' }}>
                                    <Button size="small" basic onClick={handleDefaultClick} disabled={!notDefault}>Default</Button>
                                    <Button size="small" onClick={handleResetClick} disabled={!notOriginal}>Reset</Button>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <h5>Spread Out</h5>
                                <div>
                                    <span className={`${css.space} ${css.success}`}>&nbsp;</span>
                                    {valueLabelFormat(SE, 0)}
                                    <span className={`${css.space} ${css.warning}`}>&nbsp;</span>
                                    {valueLabelFormat(SW, 1)}
                                    <span className={`${css.space} ${css.danger}`}>&nbsp;</span>
                                </div>
                                <TimeRangeSlider
                                    getAriaLabel={(index: any) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                    valueLabelFormat={valueLabelFormat}
                                    min={10 * 60 + 15}
                                    max={20 * 60}
                                    step={15}
                                    defaultValue={[props.data.spreadError, props.data.spreadWarning]}
                                    value={[SE, SW]}
                                    onChange={(_event: React.ChangeEvent<{}>, value: number | number[]) => {
                                        if (Array.isArray(value)) {
                                            setSE(value[0]);
                                            setSW(value[1]);
                                        }
                                    }}
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <Button size="small" primary onClick={handleApplyClick}>Apply</Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    );
}