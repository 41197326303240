import React, { useCallback, useEffect, useRef, useState } from 'react';
import BingMapProps, { InfoboxProps, MapEventHandlerProps, PushpinProps } from '../../types/BingMapProps';
import BingMapQuerier from '../../utilities/BingMapQuerier';
import { _0px, _1px } from '../../utilities/constants';
import BingMap from './BingMap';

export interface BingMapWithOverlayProps {
    /**
     * The properties to pass on to the map itself.
     * An additional map handler and pushpin will be
     * added to implement the drawing and overlay.
     */
    mapProps: BingMapProps;
    /**
     * A callback invoked whenever the map needs to draw
     * or redraw the overlay: on initial render,
     * or when the map is panned or moved,
     * or when the drawing function itself changes.
     * (Create the callback with `useCallback`.)
     */
    drawOverlay: (
        /**
         * A wrapper around the current, valid map.
         * Useful for things like `map.tryLocationToPixel`.
         */
        map: BingMapQuerier,
        /**
         * An `HTMLCanvasElement` positioned behind the map,
         * with the same dimensions. This element will be
         * destroyed after drawing is complete. Use it to
         * draw whatever is needed for the overlay, then
         * return `canvas.toDataURL()` (or otherwise come
         * up with a `DataUri` to use for the overlay).
         */
        drawingBoard: HTMLCanvasElement,
    ) => Promise<DataUri | undefined>;
}

const styles = {
    drawingBoard: {
        position: 'absolute',
        width: '100%', height: '100%',
        zIndex: -1,
    },
} as const;

/**
 * A version of `BingMapForm` that supports drawing an overlay.
 * The overlay is implemented as a pushpin positioned at the
 * upper-left corner of the map and anchored by its own upper-left
 * corner, so if it has the same width and height as the map,
 * it will cover the map.
 * 
 * To support drawing the overlay, creates a temporary
 * `HTMLCanvasElement` and handles the timing of calling the
 * drawing function. The overlay is drawn on initial render,
 * as well as any time the map is moved or zoomed. Changing
 * the drawing callback itself will also trigger a rerender.
 */
const BingMapWithOverlay: React.FC<BingMapWithOverlayProps> = ({
    mapProps: {
        map: externalMapProps,
        pushpins: externalPushpinOptions,
        infoboxes: externalInfoboxOptions,
        ...restProps
    },
    drawOverlay,
}) => {
    const externalMapHandlers = externalMapProps?.eventHandlers;
    const map = useRef<BingMapQuerier | null>(null);
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [overlay, setOverlay] = useState<PushpinProps>();
    const [overlayInfoboxes, setOverlayInfoboxes] = useState<InfoboxProps>();

    const [pushpins, setPushpins] = useState(externalPushpinOptions);
    const [infoboxes, setInfoboxes] = useState(externalInfoboxOptions);
    const [mapHandlers, setMapHandlers] = useState(externalMapHandlers);

    // Drawing is a two-pass process:
    // First, we `setIsDrawing(true)` to produce
    // the `HTMLCanvasElement` "drawing board,"
    // and then once the drawing board is ready,
    // we call the `drawOverlay` callback,
    // save the result, and use `setIsDrawing(false)`
    // to remove the drawing board.
    // The saved result is included in the map
    // as a pushpin.

    /**
     * Callback to begin the drawing process. It is invoked
     *  1. Immediately, on initial render.
     *  2. On the map's `viewchangeend` event.
     *  3. If the `drawOverlay` callback or `map` reference changes.
     */
    const startDrawing = useCallback(
        () => {
            if (map.current && drawOverlay) {
                setIsDrawing(true);
            }
        },
        [map, drawOverlay],
    );

    // Initial call of `startDrawing` when first rendering
    // This is also called if `startDrawing` itself changes,
    // i.e. if `map` or `drawOverlay` changes.
    useEffect(startDrawing, [startDrawing]);

    // Update `mapHandlers` to include our handlers that redraw
    // on panning, zooming, and resizing.
    useEffect(
        () => {
            /**
             * The `mapHandler` we are using to call `startDrawing`
             * whenever the map is panned or zoomed.
             */
            const redrawOnChange: MapEventHandlerProps = {
                event: 'viewchangeend',
                callback: startDrawing,
            };
            /**
             * The `mapHandler` we are using to call `startDrawing`
             * whenever the map is resized.
             */
            const redrawOnResize: MapEventHandlerProps = {
                event: 'mapresize',
                callback: startDrawing, // TODO: add throttling here
            };

            setMapHandlers([...(externalMapHandlers || []), redrawOnChange, redrawOnResize]);
        },
        [externalMapHandlers, startDrawing],
    );

    // This is part two of the process,
    // once the drawing board is ready.
    // Calls `drawOverlay`, makes it a pushpin,
    // and destroys the drawing board.
    useEffect(
        () => {
            function cancelDrawing() {
                setIsDrawing(false);
                setOverlay(undefined);
                setOverlayInfoboxes(undefined);
            }
            (async () => {
                if (!isDrawing) return;
                if (!canvas.current) return;
                if (!map.current) return cancelDrawing();
                if (!drawOverlay) return cancelDrawing();

                canvas.current.width = canvas.current.offsetWidth;
                canvas.current.height = canvas.current.offsetHeight;

                const icon = await drawOverlay(map.current, canvas.current);
                if (!map.current || !icon) return cancelDrawing();

                const location = map.current.tryPixelToLocation([_0px, _0px]);
                if (location === null) return cancelDrawing();
                setOverlay({
                    location,
                    options: {
                        icon,
                        // should be [0, 0]? old code used [1, 1], so leaving it alone
                        anchor: new Microsoft.Maps.Point(_1px, _1px),
                    },
                });
                setOverlayInfoboxes({
                    location,
                    options: {},
                });
                setIsDrawing(false);
            })();
        },
        [drawOverlay, isDrawing, map, canvas],
    );

    // Update `pushpinOptions` to include `overlay`
    useEffect(
        () => {
            setPushpins(overlay === undefined
                ? externalPushpinOptions
                : [
                    ...(externalPushpinOptions || []),
                    overlay,
                ],
            );
        },
        [externalPushpinOptions, overlay],
    );

    // Update `infoboxOptions` to include `overlay`
    useEffect(
        () => {
            setInfoboxes(overlayInfoboxes === undefined
                ? externalInfoboxOptions
                : [
                    ...(externalInfoboxOptions || []),
                    overlayInfoboxes,
                ],
            );
        },
        [externalInfoboxOptions, overlayInfoboxes],
    );

    return <>
        {isDrawing ? <canvas ref={canvas} style={styles.drawingBoard} /> : null}
        <BingMap ref={map} {...restProps}
            map={{
                ...externalMapProps,
                eventHandlers: mapHandlers,
            }}
            pushpins={pushpins}
            infoboxes={infoboxes}
        />
    </>;
};

export default BingMapWithOverlay;
