import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DelayDataForPeriod } from './models';

const chartSettings = {
    labels: [] as string[][],
    datasets: [{
        label: 'Average Delay',
        lineTension: 0.1,
        backgroundColor: 'transparent',
        borderColor: '#bee1cc',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#52af77',
        pointBorderColor: 'rgb(61, 133, 90)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#bee1cc',
        pointHoverBorderColor: 'rgb(61, 133, 90)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        data: [] as { x: string; y: number }[],
    }],
};

function tooltipsLabel(tooltipItem: { datasetIndex: number, index: number, xLabel: string, yLabel: number }) {
    const lineType = chartSettings.datasets[tooltipItem.datasetIndex].label;
    return `${lineType}: ${tooltipItem.yLabel} min`;
}

function tooltipsAfterBody(tooltipItems: { datasetIndex: number, index: number }[], data: ChartData) {
    const ds = tooltipItems[0].datasetIndex;
    const di = tooltipItems[0].index;
    const source = (data.datasets![ds].data![di] as any).ref as DelayDataForPeriod;
    return [
        '',
        `Minimum: ${source.min} min`,
        `Maximum: ${source.max} min`,
    ];
}

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            autoSkip: false,
            maxRotation: 0,
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                autoSkip: false,
                beginAtZero: true,
                stepSize: 1,
                maxTicksLimit: 10,
            },
        }],
    },
    tooltips: {
        intersect: false,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return tooltipItem[0].xLabel;
            },
            label: tooltipsLabel,
            afterBody: tooltipsAfterBody,
        },
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
};

interface Props {
    chartData: DelayDataForPeriod[];
}

const DelaysSimpleLineChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0],
                spanGaps: false,
                data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.avg as number,
                        ref: d,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                yAxes: [{
                    ...prevState.scales.yAxes[0],
                    ticks: {
                        ...prevState.scales.yAxes[0].ticks,
                        callback: function (value: string) {
                            return `${value} min`;
                        },
                    },
                }],
            },
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    ...prevState.tooltips.callbacks,
                },
            },

        }));
    }, [chartData]);

    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default DelaysSimpleLineChart;
