import React from 'react';
import { Button, Dropdown, Modal, Table } from 'semantic-ui-react';
import { defaultRanges, SpeedRangeSet } from './_models';
import { spectrumColors } from './_spectrum-palette';
import { SpeedMapContext } from './SpeedMapContext';

function ColorButton(props: { color: string, default?: boolean, current?: boolean, onClick: () => void }) {
    const isCurrent = props.current === true;
    const isDefault = props.default === true;
    let statusText = '';
    {
        if (isCurrent) {
            statusText += 'Current';
        }
        if (isDefault) {
            if (statusText.length > 0) {
                statusText += ', ';
            }
            statusText += 'Default';
        }
        if (statusText.length > 0) {
            statusText = ` (${statusText})`;
        }
    }
    return (
        <Button
            basic
            primary={isCurrent}
            color={!isCurrent && isDefault ? 'green' : undefined}
            onClick={props.onClick}
            title={`${props.color}${statusText}`}
            style={{
                width: '1.5em', height: '1.5em', margin: '0.125em', padding: 2,
                borderRadius: 4,
            }}
        >
            <div style={{
                backgroundColor: props.color,
                width: '100%',
                height: '100%',
                borderRadius: 3,
            }}></div>
        </Button>
    );
}

interface ColorSelectorProps {
    bottom?: boolean;
    color: string;
    defaultColor: string;
    onColorChanged: (color: string) => void;
}

function ColorSelector(props: ColorSelectorProps) {
    return (
        <Dropdown
            inline
            icon={null}
            trigger={
                <Button basic style={{ padding: '.78571429em' }}>
                    <div style={{ backgroundColor: props.color, height: '1em', width: '3em' }}></div>
                </Button>
            }
            direction="right"
            pointing={!props.bottom ? 'bottom right' : 'top right'}
        >
            <Dropdown.Menu style={{ padding: '0.5em', backgroundColor: '#f9fafb' }}>
                <div>
                    {spectrumColors.set1.map(sc => (<ColorButton color={sc}
                        current={sc === props.color}
                        default={sc ===props.defaultColor}
                        onClick={() => props.onColorChanged(sc)} />))}
                </div>
                <div>
                    {spectrumColors.set2.map(sc => (<ColorButton color={sc}
                        current={sc === props.color}
                        default={sc === props.defaultColor}
                        onClick={() => props.onColorChanged(sc)} />))}
                </div>
                <div>
                    {spectrumColors.set3.map(sc => (<ColorButton color={sc}
                        current={sc === props.color}
                        default={sc === props.defaultColor}
                        onClick={() => props.onColorChanged(sc)} />))}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function TableRows(ranges: SpeedRangeSet, onColorChanged: () => void) {
    const result = [];
    const count = ranges.lenght;
    for (let ix = 0; ix < count; ++ix) {
        const sr = ranges.dir(ix);
        const last = ix === count - 1;
        const nextMax: number = last ? 0 : ranges.dir(ix + 1).maxValue;
        result.push(
            <Table.Row key={ix}>
                <Table.Cell>
                    {nextMax > 0
                        ? (<>{sr.maxValue} &ndash; {nextMax}</>)
                        : `${ sr.maxValue } +`
                    }
                </Table.Cell>
                <Table.Cell>
                    <ColorSelector
                        bottom={false}
                        color={sr.stroke.color.toUpperCase()}
                        defaultColor={defaultRanges[ix].stroke.color.toUpperCase()}
                        onColorChanged={c => {
                            sr.stroke.color = c;
                            onColorChanged();
                        }} />
                </Table.Cell>
            </Table.Row>,
        );
    }
    return result.reverse();
}

export default function ColorsEditorModal() {
    const context = React.useContext(SpeedMapContext);
    const [ranges] = React.useState(SpeedRangeSet.load());
    const [state, setState] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleCancelClick = () => {
        setOpen(false);
    };
    const handleSaveClick = () => {
        setOpen(false);
        ranges.save();
        context.updateSpeedRanges();
    };

    return (
        <Modal
            size="mini"
            className="speedmap-range-editor" // fix styles of icon buttons
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Button basic icon="paint brush" size="tiny" />}
            style={{ top: 'unset', alignSelf: 'center' }}
        >
            <Modal.Header>Speed Range Colors</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Table celled striped selectable style={{ margin: '0 auto' }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Speed range</Table.HeaderCell>
                                <Table.HeaderCell>Color</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {TableRows(ranges, () => setState((state + 65) % 64))}
                        </Table.Body>
                    </Table>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel"
                    onClick={handleCancelClick} basic
                />
                <Button content="Save"
                    icon="save"
                    labelPosition="left"
                    onClick={handleSaveClick} primary
                />
            </Modal.Actions>
        </Modal>
    );
}
