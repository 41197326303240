import api from '../api/runningTimeAnalyticsApi';
import { OperationResult } from '../types/apiTypes';
import { AnalysisByPercentiles, AnalysisByPercentilesRequest, BasePerformanceRequest, OverallPerformance, RouteLevelPerformance, RunningTimesComparison, RunningTimesComparisonRequest, TransitMeansOverall, TravelTimeBetweenStops, TripDetailsPerformance, TripLevelPerformanceRequest } from '../types/runningTimeAnalyticsTypes';
import { AdjustmentReportRequest } from '../types/scheduleAdjustmentTypes';
import { KeyValuePair } from '../types/types';

export async function getStops(agencyId: string, filter: string, exactMatch = false) {
    const result: OperationResult<KeyValuePair<string, string>[]> = await api.getStops(agencyId, filter, exactMatch);
    return result.data || [];
}
export async function getDirectionsByRoute(agencyId: string, routeName: string) {
    const result = await api.getDirectionsByRoute(agencyId, routeName);
    return result.data || [];
}
export async function getDirectionVariantsByCardinalDirection(
    agencyId: string,
    routeName: string,
    cardinalDirection: string,
) {
    const result = await api.getDirectionVariantsByCardinalDirection(agencyId, routeName, cardinalDirection);
    return result.data || [];
}
export async function getTripsLevelPerformance(request: TripLevelPerformanceRequest): Promise<TripDetailsPerformance[]> {
    const result = await api.getTripLevelPerformance(request);
    return result.data || [];
}
export async function getRouteLevelPerformance(request: BasePerformanceRequest): Promise<RouteLevelPerformance[]> {
    const result = await api.getRouteLevelPerformance(request);
    return result.data || [];
}
export async function getOverallPerformance(request: BasePerformanceRequest): Promise<OverallPerformance> {
    const result = await api.getOverallPerformance(request);
    return result.data;
}
export async function getAnalysisByPercentiles(request: AnalysisByPercentilesRequest): Promise<AnalysisByPercentiles[]> {
    const result = await api.getAnalysisByPercentiles(request);
    return result.data || [];
}
export async function getRunningTimesComparison(request: RunningTimesComparisonRequest): Promise<RunningTimesComparison | undefined> {
    const result = await api.getRunningTimesComparison(request);
    return result.data;
}
export async function getScheduledAdjustment(request: AdjustmentReportRequest) {
    const result = await api.getScheduledAdjustment(request);
    return result.data;
}
export async function getTravelTimeBetweenStops(agencyId: string, stopId1: string, stopId2: string): Promise<TravelTimeBetweenStops[]> {
    const result = await api.getTravelTimeBetweenStops(agencyId, stopId1, stopId2);
    return result.data || [];
}
export async function getTransitMeansOverall(agencyId: string, reportDate: string, directionVariantInternalId: string): Promise<TransitMeansOverall[]> {
    const result = await api.getTransitMeansOverall(agencyId, reportDate, directionVariantInternalId);
    return result.data || [];
}
