import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../types/chartTypes';
import { PredictionAccuracyValue } from '../../../types/predictionAccuracyTypes';
import { KeyValuePair } from '../../../types/types';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Adaptive',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(65,105,225,1)',
            borderColor: 'rgba(65,105,225,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(65,105,225,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(65,105,225,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                stepSize: 1,
            },
            scaleLabel: {
                display: true,
                labelString: 'Start',
            },
            distribution: 'linear',
        }],
        yAxes: [{
            scaleLabel: {
                display: true,
                labelString: 'Accuracy',
            },
            ticks: {
                beginAtZero: true,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            label: function (tooltipItem: { datasetIndex: number, xLabel: number, yLabel: number }) {
                return `Accuracy ${tooltipItem.yLabel} %`;
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
};

function getChartColor(modelName: string): string {
    switch (modelName.toLowerCase()) {
        case 'adaptive':
            return 'rgba(65,105,225,1)';
        case 'linear':
            return 'rgb(255, 165, 0)';
        case 'legacy':
            return 'rgb(60, 179, 113)';
        default:
            return 'rgba(65,105,225,1)';
    }
}

const AccuracyForRouteChart: React.FC<{
    chartData: KeyValuePair<string, PredictionAccuracyValue>[],
    modelName: string,
}> = ({ chartData, modelName }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const accuracyDataByTime = chartData.sort((a, b) => moment(a.key).isSameOrAfter(b.key) ? 1 : -1);
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: accuracyDataByTime.map(s => {
                    return {
                        t: moment(s.key),
                        y: s.value.percentage,
                    };
                }),
                label: modelName,
                backgroundColor: getChartColor(modelName),
                borderColor: getChartColor(modelName),
                pointBorderColor: getChartColor(modelName),
                pointHoverBackgroundColor: getChartColor(modelName),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    ...prevState.tooltips.callbacks,
                    labelColor: function () {
                        const chartColor = getChartColor(modelName);
                        return {
                            borderColor: chartColor,
                            backgroundColor: chartColor,
                        };
                    },
                },
            },
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            options={optionsState as unknown as ChartOptions}
            height={150}
        />
    );
};

export default AccuracyForRouteChart;
