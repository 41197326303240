
import { RequestParameters } from '../types/apiTypes';
import { PBIReport } from '../types/pbiTypes';
import api from './api';


class PbiApi {

    static doAuthGetRequestAsync = async <T>(url: string) => await api.doAuthGetRequestAsync<T>('/powerbi/' + url);
    static doAuthPostRequestAsync = async <T, R>(url: string, data?: T, parameters?: RequestParameters) => await api.doAuthPostRequestAsync<T, R>('/powerbi/' + url, data, parameters);
  
    static async getPowerBIToken(reportId = '', agancyId = '') {
        const s = await this.doAuthGetRequestAsync<object>('token?reportId=' + reportId + '&agencyId=' + agancyId);
        return JSON.stringify(s);
    }

    static async getPowerBITokenEdit() {
        const s = await this.doAuthGetRequestAsync<object>('tokenedit/');
        return JSON.stringify(s);
    }

    static async getListOfReports() {
        const s = await this.doAuthGetRequestAsync<PBIReport[]>('getlistreports/');
        return s;
    }
    static async postPowerBIStartCapacity() {
        const s = await this.doAuthPostRequestAsync<object, null>('startcapacity/');
        return JSON.stringify(s);
    }

    static async getPowerBIStatusCapacity() {
        const s = await this.doAuthGetRequestAsync<object>('statuscapacity/');
        return JSON.stringify(s);
    }   
    
}

export default PbiApi;