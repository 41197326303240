import 'chartjs-plugin-annotation';
import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { VehicleComparisonData } from '../../../types/vehicleStatisticsTypes';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: '',
            data: [] as number[],
            backgroundColor: '#223f7b',
            barPercentage: 0.6,
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            ticks: {
                beginAtZero: true,
                suggestedMin: 0,
                stepSize: 3,
                callback: function (value: string) {
                    return `${value}h`;
                },
            },
        }],
        yAxes: [{
            ticks: {
                display: true,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            label: function (tooltipItem: { xLabel: number }) {
                return `${tooltipItem.xLabel} ${tooltipItem.xLabel === 1 ? 'hour': 'hours'}`;
            },
        },
    },
    hover: {
        mode: 'dataset',
    },
    annotation: {},
    responsive: true,
    plugins: {
        crosshair: false,
    },
};

const TotalServiceTimeBreakdownChart: React.FC<{ chartData: VehicleComparisonData }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const { serviceTimeIndicators, nonServiceTimeIndicators } = chartData;
        const labels = ['Stay on Road', 'Stay on Stop', 'Move', 'Not Reporting', 'Stay', 'Move', 'In Garage', 'Not Reporting'];
        const values = [
            Math.round(serviceTimeIndicators.stayOnRoad.vehicle / 3600 * 10) / 10,
            Math.round(serviceTimeIndicators.stayOnStop.vehicle / 3600 * 10) / 10,
            Math.round(serviceTimeIndicators.move.vehicle / 3600 * 10) / 10,
            Math.round(serviceTimeIndicators.notReporting.vehicle / 3600 * 10) / 10,
            Math.round(nonServiceTimeIndicators.stay.vehicle / 3600 * 10) / 10,
            Math.round(nonServiceTimeIndicators.move.vehicle / 3600 * 10) / 10,
            Math.round(nonServiceTimeIndicators.inGarage.vehicle / 3600 * 10) / 10,
            Math.round(nonServiceTimeIndicators.notReporting.vehicle / 3600 * 10) / 10,
        ];

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0], data: values,
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            annotation: {
                annotations: [{
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(serviceTimeIndicators.stayOnRoad.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(serviceTimeIndicators.stayOnRoad.average / 3600 * 10) / 10) + 0.2,
                    yMin: -0.4,
                    yMax: 0.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    mode: 'vertical',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(serviceTimeIndicators.stayOnStop.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(serviceTimeIndicators.stayOnStop.average / 3600 * 10) / 10) + 0.2,
                    yMin: 0.6,
                    yMax: 1.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(serviceTimeIndicators.move.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(serviceTimeIndicators.move.average / 3600 * 10) / 10) + 0.2,
                    yMin: 1.6,
                    yMax: 2.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(serviceTimeIndicators.notReporting.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(serviceTimeIndicators.notReporting.average / 3600 * 10) / 10) + 0.2,
                    yMin: 2.6,
                    yMax: 3.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(nonServiceTimeIndicators.stay.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(nonServiceTimeIndicators.stay.average / 3600 * 10) / 10) + 0.2,
                    yMin: 3.6,
                    yMax: 4.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(nonServiceTimeIndicators.move.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(nonServiceTimeIndicators.move.average / 3600 * 10) / 10) + 0.2,
                    yMin: 4.6,
                    yMax: 5.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(nonServiceTimeIndicators.inGarage.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(nonServiceTimeIndicators.inGarage.average / 3600 * 10) / 10) + 0.2,
                    yMin: 5.6,
                    yMax: 6.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    type: 'box',
                    xScaleID: 'x-axis-0',
                    yScaleID: 'y-axis-0',
                    borderColor: 'red',
                    backgroundColor: 'red',
                    borderWidth: 1,
                    xMin: (Math.round(nonServiceTimeIndicators.notReporting.average / 3600 * 10) / 10) - 0.2,
                    xMax: (Math.round(nonServiceTimeIndicators.notReporting.average / 3600 * 10) / 10) + 0.2,
                    yMin: 6.6,
                    yMax: 7.4,
                }, {
                    drawTime: 'afterDatasetsDraw', // overrides annotation.drawTime if set
                    display: true,
                    mode: 'horizontal',
                    type: 'line',
                    value: '3.5',
                    scaleID: 'y-axis-0',
                    borderColor: 'black',
                    borderWidth: 1,
                }],
            },
        }));
    }, [chartData]);

    return (
        <HorizontalBar
            data={dataState as ChartData}
            height={120}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default TotalServiceTimeBreakdownChart;