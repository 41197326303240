import moment from 'moment';
import { List, Menu } from 'semantic-ui-react';
import { BusPositionByBusNumber } from '../../types/busHistoryTypes';
import Utils from '../../utilities/utils';
import { SecondsIntervalOptions, secondsIntervalToString } from '../bushistory/_models';


function ValueItem(props: { label?: string, header?: string, value?: string | null }) {
    return (
        <List.Item>
            <List.Content>
                <div className="value">{props.value ? props.value : <>&mdash;</>}</div>
                {!props.header
                    ? null :
                    <List.Header>{props.header}</List.Header>
                }
                {!props.label
                    ? null :
                    <List.Description>{props.label}</List.Description>
                }
            </List.Content>
        </List.Item>
    );
}

const headwayIntervalOptions: SecondsIntervalOptions = {
    negativeSign: ' ahead',
    positiveSign: ' behind',
    position: 'after',
};

export default function BusHistorySliderInfo({ vehicleTime, speedKmH, delaySec, headwaySec, behindSec, nextVehicleId, prevVehicleId }: BusPositionByBusNumber) {
    const delayValue = typeof delaySec !== 'number'
        ? null
        : secondsIntervalToString(delaySec);
    const headwayValue = typeof headwaySec !== 'number'
        ? null
        : secondsIntervalToString(headwaySec, headwayIntervalOptions);
    const behindValue = typeof behindSec !== 'number'
        ? null
        : secondsIntervalToString(-behindSec, headwayIntervalOptions);
    const displayHeadways = Boolean(headwayValue || behindValue || nextVehicleId || prevVehicleId);
    return (
        <>
            <div className="sliderTime">
                <div>Time: <b>{moment.parseZone(vehicleTime).format('h:mm A')}</b></div>
            </div>
            <Menu>
                <List divided size="large" className="bunchingSliderInfo">
                    <ValueItem label="Delay from schedule"
                        value={delayValue}
                    />
                    <ValueItem label="Speed"
                        value={speedKmH !== null ? `${Utils.kmToMiles(speedKmH, 1)} mph` : undefined}
                    />
                    {displayHeadways && <>
                        <List.Item>
                            <List.Content>
                                <List.Header>Headways</List.Header>
                            </List.Content>
                        </List.Item>
                        <ValueItem header={nextVehicleId}
                            value={nextVehicleId && headwayValue !== null ? headwayValue : undefined}
                        />
                        <ValueItem header={prevVehicleId}
                            value={prevVehicleId && behindValue !== null ? behindValue : undefined}
                        />
                    </>}
                </List>
            </Menu>
        </>
    );
}
