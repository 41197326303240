import { API_CALL_ERROR, BEGIN_API_CALL, BlockingActionTypes, LOAD_GTFS_ERROR, POST_GTFS_ERROR, VALIDATE_GTFS_ERROR } from '../actions/actionTypes';

function actionTypeEndsInSuccess(type: string) {
    return type.substring(type.length - 8) === '_SUCCESS';
}

export default function blockingReducer(state = false, action: BlockingActionTypes): boolean {
    if (action.type === BEGIN_API_CALL) {
        return true;
    }
    if (action.type === VALIDATE_GTFS_ERROR
        || action.type === POST_GTFS_ERROR
        || action.type === LOAD_GTFS_ERROR
        || action.type === API_CALL_ERROR) {
        return false;
    }
    else if (actionTypeEndsInSuccess(action.type)) {
        return false;
    }
    else {
        return state;
    }
}