import { IMapLocalion as _IMapLocalion } from './map-types-interfaces';

export type IMapLocalion = _IMapLocalion;

export class MapLocalion implements IMapLocalion {
    constructor(public latitude: Latitude, public longitude: Longitude) {
    }

    public set(latitude: Latitude, longitude: Longitude) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    public static fromCoordinatePair(source: CoordinatePair): MapLocalion {
        return new MapLocalion(source[0], source[1]);
    }

    public toCoordinatePair(): CoordinatePair {
        return [this.latitude, this.longitude];
    }

    public toBingMapLocation() {
        return new Microsoft.Maps.Location(this.latitude, this.longitude);
    }
}
