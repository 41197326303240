import moment from 'moment';

export function parseAsNetDate(value: string, offset?: number): Date {
    offset = offset || 0;
    const localDate = new Date(moment.parseZone(value).add(offset, 'hours').format('YYYY-MM-DD HH:mm:ss'));
    return localDate;
}

export function secondsToTimeString(sec: number, displayHours?: number): string {
    const secAbs = Math.abs(sec);
    const sign = sec < 0 ? '&minus;' : '';
    const minutes = Math.floor(secAbs / 60);
    const seconds = (secAbs % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
    return sign + (displayHours ? secondsToTimeString(minutes) : minutes).toLocaleString('en-US', { minimumIntegerDigits: 2 })
        + ':' + seconds;
}

export function GetDurationString(start: Date, end: Date) {
    const totalSeconds = (end.getTime() - start.getTime()) / 1000;
    const minutes = totalSeconds % (60 * 60);
    const hours = totalSeconds - minutes;
    return (hours / (60 * 60)) + 'h:' + Math.floor(minutes / 60) + 'm';
}

export function GetDurationStringFromSeconds(totalSeconds: number) {
    const minutes = totalSeconds % (60 * 60);
    const hours = (totalSeconds - minutes) / (60 * 60);
    const result =  hours > 0 ? hours + 'h:' : '';
    return result + Math.floor(minutes / 60) + 'm';
}

function toSentenceCase(value: string): string {
    const firstLetterMatch = value.match(/\w/);
    if (!firstLetterMatch) return value;
    const firstLetter = firstLetterMatch[0];
    const i = value.indexOf(firstLetter);
    return value.slice(0, i) + (firstLetter.toUpperCase() + value.slice(i+1).toLowerCase()).replace(/\b[NS][EW]\b/gi, (match) => { return match.toUpperCase(); });
}

export function toTitleCase(value: string) {
    return value.split(/\b/).map(word => toSentenceCase(word)).join('');
}

export function toIsoDate(date: Date) {
    function pad(number: number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
}

/*
function _lit(c: number, amount: number) { return 255 - Math.floor((255 - c) * (1 - amount)); }
function _drk(c: number, amount: number) { return Math.floor(c * (1 - amount)); }

export function colorLighter(color: Microsoft.Maps.Color, amount: number): Microsoft.Maps.Color {
    return new Microsoft.Maps.Color(color.a, _lit(color.r, amount), _lit(color.g, amount), _lit(color.b, amount));
}

export function colorDarker(color: Microsoft.Maps.Color, amount: number): Microsoft.Maps.Color {
    return new Microsoft.Maps.Color(color.a, _drk(color.r, amount), _drk(color.g, amount), _drk(color.b, amount));
}
*/

/** coverts objects retuned by old API (UpperCamelCase) to form retuned by new API (lowerCamelCase)
 */
export function convertObjectNames(o: any) {
    if (typeof o === 'object') {
        for (const fn in o) {
            const fv: any = convertObjectNames(o[fn]);
            const nf = fn.substr(0, 1).toLowerCase() + fn.substr(1);
            if (fn === nf)
                o[fn] = fv;
            else {
                delete o[fn];
                o[nf] = fv;
            }
        }
    } else if (Array.isArray(o)) {
        o.forEach(e => convertObjectNames(e));
    }
    return o;
}
