import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BunchesByKey } from '../../../../types/busBunchingTypes';
import { LineChartDatePoint } from '../../../../types/chartTypes';


const chartSettings = {
    labels: [],
    datasets: [
        {
            label: '',
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#42DCC6',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#42DCC6',
            pointHoverBorderColor: '#42DCC6',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
    },
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'hour',
                unitStepSize: 1,
                displayFormats: {
                    minute: 'h:mm a',
                },
            },
            ticks: {
                autoSkip: true,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
            },
            scaleLabel: {
                display: true,
                labelString: 'Number of buched vehicles',
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 50,
        callbacks: {
            title: function () {
                return '';
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: false,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
    //maintainAspectRatio: false
};

interface Props {
    bunchesByDates: BunchesByKey[],
    daysDiff: number;
}

const BunchesByDatesChart: React.FC<Props> = ({ bunchesByDates, daysDiff }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const chartData = bunchesByDates
            .sort((a, b) => moment(a.key).isSameOrAfter(b.key) ? 1 : -1);


        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(d => {
                    return {
                        t: moment(d.key).toDate(),
                        y: d.bunchesCount,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales, xAxes: [{
                    ...prevState.scales.xAxes[0], time: {
                        unit: daysDiff > 1 ? 'day' : 'hour',
                        unitStepSize: 1,
                        displayFormats: {
                            minute: 'h:mm a',
                            day: 'ddd MM-DD',
                        },
                    },
                }],
            },
        }));
    }, [bunchesByDates, daysDiff]);


    return (
        <div>
            <Line
                data={dataState as ChartData}
                height={100}
                options={optionsState as unknown as ChartOptions}
            />
        </div>
    );
};

export default BunchesByDatesChart;
