import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Utils from '../../../../utilities/utils';
import { HeadwayDistribution } from './StatsHeadwaysForm';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: 'Very Short (< 5 min)',
            lineTension: 0.1,
            backgroundColor: 'rgba(130, 225, 128, 0.8)',
            borderColor: '#82E180',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#82E180',
            pointBackgroundColor: '#82E180',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#82E180',
            pointHoverBorderColor: '#82E180',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Short (5-10 min)',
            lineTension: 0.1,
            backgroundColor: 'rgba(49, 220, 195, 0.8)',
            borderColor: '#31DCC3',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#31DCC3',
            pointBackgroundColor: '#31DCC3',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#31DCC3',
            pointHoverBorderColor: '#31DCC3',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Medium (10-20 min)',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 192, 97, 0.8)',
            borderColor: '#FFC061',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FFC061',
            pointBackgroundColor: '#FFC061',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#FFC061',
            pointHoverBorderColor: '#FFC061',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Long (> 20 min)',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 131, 104, 0.8)',
            borderColor: '#FF8368',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FF8368',
            pointBackgroundColor: '#FF8368',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#FF8368',
            pointHoverBorderColor: '#FF8368',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    elements: {
        line: {
            tension: 0.000001,
        },
    },
    scales: {
        xAxes: [{
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                autoSkip: false,
                callback: function (value: string) {
                    return value + ' %';
                },
                stepSize: 1,
                maxTicksLimit: 10,
            },
            stacked: true,
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return tooltipItem[0].xLabel;
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                let lineType = 'Very Short (< 5 min)';
                if (tooltipItem.datasetIndex === 1)
                    lineType = 'Short (5-10 min)';
                else if (tooltipItem.datasetIndex === 2)
                    lineType = 'Medium (10-20 min)';
                else if (tooltipItem.datasetIndex === 3)
                    lineType = 'Long (> 20 min)';
                return `${lineType}: ${Utils.roundNumber(tooltipItem.yLabel, 1)} %`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: '#82E180',
                        backgroundColor: '#82E180',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        borderColor: '#31DCC3',
                        backgroundColor: '#31DCC3',
                    };
                } else if (tooltipItem.datasetIndex === 2) {
                    return {
                        borderColor: '#FFC061',
                        backgroundColor: '#FFC061',
                    };
                } else {
                    return {
                        borderColor: '#FF8368',
                        backgroundColor: '#FF8368',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        filler: {
            propagate: true,
        },
        'samples-filler-analyser': {
            target: 'chart-analyser',
        },
        crosshair: false,
    },
};

const HeadwayDistributionChart: React.FC<{ chartData: HeadwayDistribution[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [
                {
                    ...prevState.datasets[0], data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.veryShort,
                        };
                    }),
                },
                {
                    ...prevState.datasets[1], data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.short,
                        };
                    }),
                },
                {
                    ...prevState.datasets[2], data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.medium,
                        };
                    }),
                },
                {
                    ...prevState.datasets[3], data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.long,
                        };
                    }),
                },
            ],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={options as unknown as ChartOptions}
        />
    );
};

export default HeadwayDistributionChart;
