import React from 'react';
import { Table } from 'semantic-ui-react';
import { Row } from '../../../types/scheduleAdjustmentTypes';
import Utils from '../../../utilities/utils';

const ScheduleAdjustmentRow: React.FC<{ row: Row }> = ({ row }) => {
    return (
        <Table.Row>
            <Table.Cell textAlign="center">{row.startTime} &ndash; {row.finishTime}</Table.Cell>
            <Table.Cell textAlign="right">{row.actualOtp !== null ? <span className="v-black">{row.actualOtp}%</span> : ''}</Table.Cell>
            <Table.Cell textAlign="right">{row.proposedOtp !== null ? <span className="v-blue">{row.proposedOtp}%</span> : ''}</Table.Cell>
            {row.adjustments.map((adjustment, index) => (
                <Table.Cell key={index}>
                    <span className="v-cell v-black">{Utils.convertSecondsToMinutes(adjustment.actualDelay)}</span>&nbsp;<span className="v-cell v-blue">{Utils.convertSecondsToMinutes(adjustment.proposedAdjustment)}</span>
                </Table.Cell>
            ))}
        </Table.Row>
    );
};

export default ScheduleAdjustmentRow;