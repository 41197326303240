import { ChartData, ChartOptions } from 'chart.js';
import * as crosshair from 'chartjs-plugin-crosshair';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDataPoint } from '../../../types/chartTypes';
import { PredictionArrivalChartData } from '../../../types/delaysAnalysisTypes';
import Utils from '../../../utilities/utils';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Actual',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#ba3021',
            borderColor: '#ba3021',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ba3021',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ba3021',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
        {
            label: 'Predicted',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#2185d0',
            borderColor: '#2185d0',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#2185d0',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#2185d0',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
        {
            label: 'Scheduled',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#696463',
            borderColor: '#696463',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#696463',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#696463',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options = {
    title: {
        display: true,
        text: '',
        fontSize: 16,
        fontColor: '#ba3021',
    },
    scales: {
        xAxes: [{
            type: 'linear',
            autoSkip: false,
            ticks: {
                stepSize: 5,
            },
            scaleLabel: {
                display: true,
                labelString: 'Distance (miles)',
            },
        }],
        yAxes: [{
            type: 'linear',
            autoSkip: false,
            scaleLabel: {
                display: true,
                labelString: 'Agency time',
            },
            distribution: 'linear',
            ticks: {
                stepSize: 600 * 1000,
                beginAtZero: false,
                callback: (value: number) => {
                    const date = moment(value);
                    return date.format('hh:mm A');
                },
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: number, yLabel: number }) {
                let lineType = 'actual';
                if (tooltipItem.datasetIndex === 1)
                    lineType = 'predicted';
                else if (tooltipItem.datasetIndex === 2)
                    lineType = 'scheduled';
                const time = moment.parseZone(tooltipItem.yLabel).format('hh:mm A');
                const runTimesLabel = `${lineType} arrival time: ${time} @ ${tooltipItem.xLabel.toFixed(2)} mil`;
                return runTimesLabel;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: '#ba3021',
                        backgroundColor: '#ba3021',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        borderColor: '#2185d0',
                        backgroundColor: '#2185d0',
                    };
                } else {
                    return {
                        borderColor: '#696463',
                        backgroundColor: '#696463',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    //maintainAspectRatio: false
};


const PredictedArrivalReportChart: React.FC<{ chartData: PredictionArrivalChartData }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [chartOptionsState, setChartOptionsState] = useState(options);

    useEffect(() => {
        const arrivalData = chartData.arrivalData;
        const orderedChartData = arrivalData.sort((a, b) => a.traveledKm > b.traveledKm ? 1 : -1).map(a => {
            return {
                traveledMiles: Utils.kmToMiles(a.traveledKm),
                actualTime: moment.parseZone(a.actualTime).format('YYYY-MM-DD hh:mm A'),
                predictedTime: moment.parseZone(a.predictedTime).format('YYYY-MM-DD hh:mm A'),
                scheduledTime: moment.parseZone(a.scheduledTime).format('YYYY-MM-DD hh:mm A'),
            };
        });
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: orderedChartData.map(d => {
                    return {
                        x: d.traveledMiles,
                        y: moment(d.actualTime).valueOf(),
                    };
                }),
            }, {
                ...prevState.datasets[1], data: orderedChartData.map(d => {
                    return {
                        x: d.traveledMiles,
                        y: moment(d.predictedTime).valueOf(),
                    };
                }),
            }, {
                ...prevState.datasets[2], data: orderedChartData.map(d => {
                    return {
                        x: d.traveledMiles,
                        y: moment(d.scheduledTime).valueOf(),
                    };
                }),
            }],
        }));
        setChartOptionsState(prevState => ({
            ...prevState,
            title: {
                ...prevState.title, text: `30 min prediction accuracy (as of ${chartData.predictionTime}) ${chartData.accuracy}%`,
            },
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={chartOptionsState as unknown as ChartOptions}
            plugins={[crosshair]}
        />
    );
};

export default PredictedArrivalReportChart;