import React from 'react';
import { Form } from 'semantic-ui-react';

const styles = {
    fileInput: {
        width: '400px',
        whiteSpace: 'normal',
    },
};

class GtfsUpload extends React.Component {
    handleFileUpload = (evt) => {
        evt.preventDefault();
        const file = this.refs.gtfsfile.files[0];
        this.props.postGtfs({ file });
    };

    render() {
        return (
            <Form onSubmit={this.handleFileUpload}>
                <Form.Group inline>
                    <Form.Field>
                        <input name="file" ref="gtfsfile" type="file" style={styles.fileInput}/>
                    </Form.Field>
                    <Form.Button basic>Upload</Form.Button>
                </Form.Group>
            </Form>
        );


    }
}

export default GtfsUpload;