import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import * as actions from '../../actions/actions';

class GtfsControls extends React.Component {
    constructor(props) {
        super(props);
    }

    renderPublishBtn = () => {
        if((this.props.gtfsFile.status === 'Unpublished'
                || this.props.gtfsFile.status === 'New')
             && this.props.gtfsFile.valid) {
            return(
                <Button className="bordered" icon="check square" title="Publish" inverted
                    onClick={() => { this.props.onPublishClick(this.props.gtfsFile.gtfsFileId); }} />
            );
        }
    };
    renderDeleteBtn = () => {
        if(this.props.gtfsFile.status !== 'Published') {
            return(
                <Button className="bordered" icon="trash" title="Delete" onClick={() => this.props.onDeleteClick(this.props.gtfsFile.gtfsFileId)} />
            );
        }
    };
    renderUnpublishBtn = () => {
        let gtfsFileId = this.props.gtfsFile.gtfsFileId;
        if(this.props.gtfsFile.status === 'Published') {
            return(
                <Button icon="remove" onClick={() => this.props.onUnpublishClick(gtfsFileId)} />
            );
        }
    };
    renderValidateBtn = () => {
        let gtfsFileId = this.props.gtfsFile.gtfsFileId;
        if ((this.props.gtfsFile.status === 'New' || this.props.gtfsFile.status === 'Unpublished') && this.props.gtfsFile.valid === null) {
            return(
                <Button className="bordered" icon="check" title="Validate" onClick={() => this.props.onValidateClick(gtfsFileId)} />
            );
        }
    };
    renderScheduleBtn = () => {
        let calendarFrom = this.props.gtfsFile.calendarFrom;
        let autoPublish = this.props.gtfsFile.autoPublish;
        let icon = 'calendar';
        let title = 'Schedule';
        if (autoPublish){
            icon = 'calendar times';
            title = 'Unschedule';
        }
        if(this.props.gtfsFile.status !== 'Published' && 
            this.props.gtfsFile.valid &&
            calendarFrom &&
            new Date(calendarFrom).getTime() > new Date().getTime()) {
            return(
                <Button className="bordered" icon={icon} title= {title} inverted 
                    onClick={() => this.props.onScheduleClick(this.props.gtfsFile)} />
            );
        }
    };
    renderDetailsBtn = () => {
        let gtfsFileId = this.props.gtfsFile.gtfsFileId;
            return(
                <Button className="bordered" icon="info" title="Details" onClick={() => this.props.onDetailsClick(gtfsFileId)} />
            );
    };
    render() {
        return (
            <Button.Group basic>
                {this.renderDetailsBtn()}
                {this.renderValidateBtn()}
                {this.renderUnpublishBtn()}
                {this.renderScheduleBtn()}
                {this.renderPublishBtn()}
                {this.renderDeleteBtn()}
            </Button.Group>
        );
    }
}

const mapDispatchToGtfsControlsProps = (dispatch) => (
    {
        onDeleteClick: (gtfsId) => (
            dispatch(actions.deleteGtfs(gtfsId))
        ),
        onPublishClick: (gtfsId) => (
            dispatch(actions.publishGtfs(gtfsId))
        ),
        onUnpublishClick: (gtfsId) => (
            dispatch(actions.unpublishGtfs(gtfsId))
        ),
        onValidateClick: (gtfsId) => (
            dispatch(actions.validateGtfs(gtfsId))
        ),

        dispatch: dispatch,
    }
);

export default connect(null, mapDispatchToGtfsControlsProps) (GtfsControls);