import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Header, Tab, TabProps } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getWrongCoordinates, getWrongCoordinatesVehiclePositions } from '../../../actions/anomaliesActions';
import { getFullRouteInfoByRouteName } from '../../../actions/gtfsStaticActions';
import Api from '../../../api/api';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import busStopIcon from '../../../static/bus-stop-20.png';
import { WrongCoordinatesReport, WrongCoordinatesShape, WrongCoordinatesTrip, WrongCoordinatesVehiclePosition } from '../../../types/anomaliesTypes';
import { InfoboxProps, MapEventHandlerProps, PolylineProps, PushpinProps } from '../../../types/BingMapProps';
import { FullRouteDirectionVariant, FullRouteInfo } from '../../../types/gtfsTypes';
import BingMapQuerier from '../../../utilities/BingMapQuerier';
import Utils from '../../../utilities/utils';
import { getVehicleInfoboxHtml } from '../../bushistory/VehicleInfobox';
import WrongCoordinatesShapesForm from './WrongCoordinatesShapesForm';
import WrongCoordinatesVehiclesForm from './WrongCoordinatesVehiclesForm';


interface Props {
    agency: AgencyStateType | undefined;
}

export interface TripsAggregated {
    shapeId: string;
    onShapeTrips: number;
    outShapeTrips: number;
    outShapePcntTrips: number;
}
export interface WrongCoordinatesTabForm {
    tripsData: WrongCoordinatesTrip[];
    tripsRowHandler: (selectedTrip: WrongCoordinatesTrip) => Promise<void>;
    selectedTrip: WrongCoordinatesTrip | undefined;
    vehiclePositions: WrongCoordinatesVehiclePosition[];
    agencyOffset: number;
    center: CoordinatePair | undefined;
    mapBounds: Microsoft.Maps.LocationRect | undefined;
    polylines: PolylineProps[];
    pushpins: PushpinProps[];
    handleSliderChange: (_e: React.ChangeEvent<{}>, value: number | number[]) => void;
    sliderValue: number;
    handleSliderValueLabelFormat: (value: number) => React.ReactNode;
    infoboxes: InfoboxProps[];
    mapRef: React.RefObject<BingMapQuerier>;
    mapEventHandlers: MapEventHandlerProps[];
}
interface MapData {
    zoom: number;
    bounds: Microsoft.Maps.LocationRect;
}
const styles = {
    reportDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
};
const WrongCoordinatesForm: React.FC<Props> = ({ agency }) => {
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [reportState, setReportState] = useState<WrongCoordinatesReport | undefined>(undefined);
    const [shapesEnrichedState, setShapesEnrichedState] = useState<WrongCoordinatesShape[] | undefined>(undefined);
    const [tripsDataState, setTripsDataState] = useState<WrongCoordinatesTrip[]>([]);
    const [positionsState, setPositionsState] = useState<WrongCoordinatesVehiclePosition[]>([]);
    const [selectedTripState, setSelectedTripState] = useState<WrongCoordinatesTrip | undefined>(undefined);
    const [selectedVehicleIdState, setSelectedVehicleIdState] = useState<string | undefined>(undefined);
    const [selectedShapeIdState, setSelectedShapeIdState] = useState<string | undefined>(undefined);
    const [polylinesState, setPolylineState] = useState<PolylineProps[]>([]);
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [sliderValueState, setSliderValueState] = useState<number>(-1);
    const [routeInfoState, setRouteInfoState] = useState<FullRouteInfo | undefined>(undefined);
    const [center, setCenter] = useState<CoordinatePair>();
    const [infoboxesState, setInfoboxesState] = useState<InfoboxProps[]>([]);
    const mapRef = React.createRef<BingMapQuerier>();
    const [mapEventHandlers, setMapEventHandlers] = useState<MapEventHandlerProps[]>([]);
    const [mapDataState, setMapDataState] = useState<MapData | undefined>(undefined);

    useEffect(() => {
        if (sliderValueState < 0 || positionsState.length === 0)
            return;
        const position = positionsState[sliderValueState];
        const infobox = updateBusPosition(position);
        return () => {
            if (infobox) {
                infobox.setMap(null as any as Microsoft.Maps.Map);
            }
        };
    }, [sliderValueState]);

    useEffect(() => {
        if (!mapRef || !mapRef.current || positionsState.length === 0 || sliderValueState !== -1)
            return;
        setSliderValueState(0);
    }, [mapRef]);

    useEffect(() => {
        if (!routeInfoState || !selectedTripState || positionsState.length === 0)
            return;
        const variantInfo = getSelectedVariantInfo();
        if (!variantInfo)
            return;
        const { shape: { points } } = variantInfo;
        const outOfShapeLocations: Array<[Latitude, Longitude][]> = [[]];
        let index = 0;
        for (const position of positionsState) {
            if (position.traveledKm === null) {
                if (outOfShapeLocations.length - 1 !== index)
                    outOfShapeLocations.push([]);
                outOfShapeLocations[index].push([position.latitude, position.longitude]);
            } else if (position.traveledKm !== null && outOfShapeLocations[index]?.length > 0) {
                index++;
            }
        }
        const polylines: PolylineProps[] = [{
            locations: points.map(({ lat, lon }) => [lat, lon]),
            options: {
                strokeColor: 'rgb(0, 64, 128)',
                strokeThickness: 3,
                visible: true,
            },
        }];
        for (const location of outOfShapeLocations) {
            polylines.push({
                locations: location.map(coords => coords),
                options: {
                    strokeColor: '#c40000',
                    strokeThickness: 3,
                    strokeDashArray: [4, 4],
                    visible: true,
                },
            });
        }
        setPolylineState(polylines);
        const locations = points.map(p => new Microsoft.Maps.Location(p.lat, p.lon));
        const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
        setMapBoundsState(boundingBox);
        drawStops(variantInfo);
        setMapEventHandlers([{
            event: 'viewchangeend', callback: updateMapData,
        }]);

        return () => {
            setPushpinsState([]);
            setInfoboxesState([]);
            setMapEventHandlers([]);
        };
    }, [routeInfoState, selectedTripState, positionsState]);

    useEffect(() => {
        setReportState(undefined);
        setTripsDataState([]);
        if (agency === undefined) return;
        (async (agencyId: string) => {
            await updateReportData();
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    useEffect(
        () => {
            const variantInfo = getSelectedVariantInfo();
            drawStops(variantInfo);
        }, [mapDataState]);

    const tabPanes = [
        {
            menuItem: 'Vehicles',
            render: () => <WrongCoordinatesVehiclesForm {...{
                vehiclesData: reportState ? reportState.wrongCoordinates.vehicles : [],
                vehiclesRowHandler: vehiclesRowHandler,
                selectedVehicleId: selectedVehicleIdState,
                tripsData: tripsDataState,
                tripsRowHandler: tripsRowHandler,
                selectedTrip: selectedTripState,
                vehiclePositions: positionsState,
                agencyOffset: reportState ? reportState.agencyOffset : 0,
                center: center,
                mapBounds: mapBoundsState,
                polylines: polylinesState,
                pushpins: pushpinsState,
                handleSliderChange: handleSliderChange,
                sliderValue: sliderValueState,
                handleSliderValueLabelFormat: handleValueLabelFormat,
                infoboxes: infoboxesState,
                mapRef,
                mapEventHandlers,
            }} />,
        },
        {
            menuItem: 'Route Shapes',
            render: () => <WrongCoordinatesShapesForm {...{
                shapesData: shapesEnrichedState ? shapesEnrichedState : [],
                shapesRowHandler: shapesRowHandler,
                selectedShapeId: selectedShapeIdState,
                tripsData: tripsDataState,
                tripsRowHandler: tripsRowHandler,
                selectedTrip: selectedTripState,
                vehiclePositions: positionsState,
                agencyOffset: reportState ? reportState.agencyOffset : 0,
                center: center,
                mapBounds: mapBoundsState,
                polylines: polylinesState,
                pushpins: pushpinsState,
                handleSliderChange: handleSliderChange,
                sliderValue: sliderValueState,
                handleSliderValueLabelFormat: handleValueLabelFormat,
                infoboxes: infoboxesState,
                mapRef,
                mapEventHandlers,
            }} />,
        },
    ];

    const handleSelectedDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setSelectedDateState(value);
    };
    const handleTabChange = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, _data: TabProps) => {
        setTripsDataState([]);
        setSelectedTripState(undefined);
        setSelectedVehicleIdState(undefined);
        setSelectedShapeIdState(undefined);
        setRouteInfoState(undefined);
        setPositionsState([]);
        setPushpinsState([]);
        setPolylineState([]);
        setSliderValueState(-1);
    };
    const vehiclesRowHandler = (vehicleId: string) => {
        setSelectedVehicleIdState(vehicleId);
        const trips = reportState ? reportState.wrongCoordinates.trips.filter(t => t.vehicle == vehicleId).sort((a, b) => b.outShapePcnt - a.outShapePcnt) : [];
        setTripsDataState(trips);
        setPositionsState([]);
        setSliderValueState(-1);
    };
    const shapesRowHandler = (shapeId: string) => {
        setSelectedShapeIdState(shapeId);
        const trips = reportState ? reportState.wrongCoordinates.trips.filter(t => t.shapeId == shapeId).sort((a, b) => b.outShapePcnt - a.outShapePcnt) : [];
        setTripsDataState(trips);
        setPositionsState([]);
        setSliderValueState(-1);
    };
    const tripsRowHandler = async (selectedTrip: WrongCoordinatesTrip) => {
        setSelectedTripState(selectedTrip);
        setPositionsState([]);
        await updateRouteInfo(selectedTrip.route);
        await updateVehiclePositions(selectedTrip);
        setSliderValueState(-1);
    };
    const updateVehiclePositions = async (selectedTrip: WrongCoordinatesTrip) => {
        if (agency && agency.id) {
            const {
                vehicle,
                internalId,
                start,
                finish,
                actualStart,
                actualFinish,
} = selectedTrip;
            try {
                setFormBlockingState(true);
                const vehiclePositions = await getWrongCoordinatesVehiclePositions(
                    agency.id,
                    selectedDateState,
                    internalId,
                    vehicle,
                    moment.min(moment.parseZone(start).add(-1, 'minutes'), moment.parseZone(actualStart)).format('YYYY-MM-DD HH:mm'),
                    moment.max(moment.parseZone(finish).add(30, 'minutes'), moment.parseZone(actualFinish)).format('YYYY-MM-DD HH:mm'));
                setPositionsState(vehiclePositions);
            } catch {
                setPositionsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setPositionsState([]);
        }
    };
    const updateRouteInfo = async (routeName: string) => {
        if (agency && agency.id) {
            if (routeInfoState && routeInfoState.routeName === routeName)
                return;
            try {
                setFormBlockingState(true);
                const routeInfo = await getFullRouteInfoByRouteName(agency.id, routeName);
                setRouteInfoState(routeInfo);
            } catch {
                setRouteInfoState(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setRouteInfoState(undefined);
        }
    };

    const handleValueLabelFormat = (value: number): React.ReactNode => {
        const position = positionsState[value];
        const formattedDate = position ?
            moment.parseZone(position.vehicleTime).format('hh:mm a') :
            '';
        return <div style={{ textAlign: 'center', fontSize: '9px' }}>{formattedDate}</div>;
    };

    const updateReportData = async () => {
        if (agency && agency.id) {
            setReportState(undefined);
            setShapesEnrichedState(undefined);
            try {
                setFormBlockingState(true);
                const report = await getWrongCoordinates(agency.id, selectedDateState);
                setReportState(report);

                const tripsEnt = report.wrongCoordinates.trips;
                const tripsAggregated = Object.entries(Utils.groupBy(tripsEnt, t => t.shapeId))
                    .map<TripsAggregated>(([shapeId, tripsData]) => {
                        const tripForShape = tripsData.find(trip => trip.shapeId === shapeId);
                        return {
                            routeId: tripForShape ? tripForShape.route : '',
                            otsTripShortName: tripForShape ? tripForShape.otsTripShortName: '',
                            shapeId: shapeId,
                            onShapeTrips: tripsData.reduce((sum: number, currentTrip: WrongCoordinatesTrip) => currentTrip.onShape + sum, 0),
                            outShapeTrips: tripsData.reduce((sum: number, currentTrip: WrongCoordinatesTrip) => currentTrip.outShape + sum, 0),
                            outShapePcntTrips: 0,
                        };
                    });
                const shapesEnt = report.wrongCoordinates.shapes;
                const shapesEntEnriched = [];

                for (let i = 0; i < shapesEnt.length; i++) {
                    shapesEntEnriched.push({
                        ...shapesEnt[i],
                        ...(tripsAggregated.find((itmInner) => itmInner.shapeId === shapesEnt[i].id)),
                    },
                    );
                }
                const shapesEnrichedTotal: WrongCoordinatesShape[] = [];

                shapesEnrichedTotal.push(...shapesEntEnriched.map(d => {
                    return {
                        id: d.id,
                        routeId: d.routeId,
                        otsTripShortName: d.otsTripShortName,
                        onShapeTrips: d.onShapeTrips,
                        outShapeTrips: d.outShapeTrips,
                        outShapePcntTrips: ((d.outShapeTrips / (d.outShapeTrips + d.onShapeTrips)) * 100),
                        onShape: d.onShape,
                        outShape: d.outShape,
                        outShapePcnt: d.outShapePcnt,
                    };
                }));
                setShapesEnrichedState(shapesEnrichedTotal);
            } catch {
                setReportState(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setReportState(undefined);
            setShapesEnrichedState(undefined);
        }
    };

    const handleSliderChange = (_e: React.ChangeEvent<{}>, value: number | number[]) => {
        const sliderIndex = value as number;
        setSliderValueState(sliderIndex);
    };

    const updateBusPosition = (position: WrongCoordinatesVehiclePosition | undefined) => {
        if (!position || !mapRef || !mapRef.current || !mapRef.current.map)
            return;
        const description = `at ${moment.parseZone(position.vehicleTime).format('h:mm:ss A')}`;
        const infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(position.latitude, position.longitude),
            {
                htmlContent: getVehicleInfoboxHtml(position.vehicleId, description, position.traveledKm === null ? '#c40000' : undefined),
            },
        );
        infobox.setMap(mapRef.current.map);
        return infobox;
    };
    const updateMapData = (e: Microsoft.Maps.IMouseEventArgs) => {
        const map = e.target as Microsoft.Maps.Map;
        const mapData = map ? { zoom: map.getZoom(), bounds: map.getBounds() } : undefined;
        setMapDataState(mapData);
    };
    const getStops = (variantInfo: FullRouteDirectionVariant | undefined) => {
        if (!variantInfo || !mapDataState)
            return [];
        const { zoom, bounds } = mapDataState;
        if (zoom < 15)
            return [];
        const stops = variantInfo.stops
            .map(s => s.stop)
            .filter(stop => bounds.contains(new Microsoft.Maps.Location(stop.lat, stop.lon)));
        return stops;
    };
    const drawStops = (variantInfo: FullRouteDirectionVariant | undefined) => {
        const stops = getStops(variantInfo);
        if (stops.length > 0) {
            const stopPushpins: PushpinProps[] = stops.map(({ lat, lon }) => ({
                location: [lat, lon],
                options: {
                    icon: busStopIcon,
                    catId: 'bus-stop',
                },
                eventHandlers: [{
                    event: 'click',
                    callback: () => {
                        setInfoboxesState(prevState => (
                            prevState.map(i => i.location[0] === lat && i.location[1] === lon ?
                                { ...i, options: { ...i.options, visible: true } } :
                                { ...i, options: { ...i.options, visible: false } })
                        ));
                    },
                }],
            }));
            setPushpinsState(stopPushpins);
            setInfoboxesState(
                stops.map(({ lat, lon, stopCode, stopName }) => ({
                    location: [lat, lon],
                    options: {
                        description: `<div>${stopCode}</div>` + `<div>${stopName}</div>`,
                        visible: false,
                    },
                })));
        } else {
            setPushpinsState([]);
            setInfoboxesState([]);
        }
    };
    const getSelectedVariantInfo = () => {
        if (!routeInfoState || !selectedTripState)
            return undefined;
        const variantInfo = routeInfoState.directions.flatMap(d => d.directionVariants).find(dv => dv.shape.shapeId === selectedTripState.shapeId);
        return variantInfo;
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Deviations From Routes
                </Header>
                <div style={styles.reportDescription}>
                    Below are the lists of vehicles and shapes exhibiting unusual behavior in reporting coordinates, which may require an additional investigation. This report is showing occurrences, when TRANSITiQ observed a substantial deviation of a vehicle location from the designated/anticipated route.
                    <p style={{ marginTop: '10px' }}>
                        Wrong Position is defined as coordinates reported 45 meters away from the expected route.
                    </p>
                </div>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <label className="calendarInputLabel">Report Date:</label>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={updateReportData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                {reportState &&
                    <Form.Group className="rowGroup">
                        <Form.Field width={16}>
                            <Tab panes={tabPanes} onTabChange={handleTabChange} />
                        </Form.Field>
                    </Form.Group>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(WrongCoordinatesForm);