import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { AppState } from '../../reducers';
import { isLogged } from '../../selectors';
import userManager from '../../utilities/userManager';
import LoginForm, { getLoginKind, LoginCallbackState, LoginKind } from './LoginForm';
import { loginPathPrefix } from './routes';

function doLogin(loginKind: LoginKind) {
    const ext: LoginCallbackState = {
        state: {
            kind: loginKind,
            path: window.location.pathname.replace(loginPathPrefix, ''),
        },
    };
    return userManager.signinRedirect(ext);
}

const Login: React.FC<{ isLogged: boolean; }> = ({ isLogged }) => {
    const [loginInProgress, setLoginInProgress] = useState(false);
    const loginKind = getLoginKind();

    React.useEffect(() => {
        if (isLogged || loginKind !== LoginKind.INITIAL) return;
        setLoginInProgress(true);
        doLogin(loginKind)
            .catch(e => {
                console.error(e);
                setLoginInProgress(false);
            });
    }, [isLogged, loginKind]);

    if (isLogged) return (
        <Redirect to={window.location.pathname.replace(loginPathPrefix, '')} />
    );

    const handleLogin: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setLoginInProgress(true);
        doLogin(LoginKind.REGULAR)
            .catch(e => {
                console.error(e);
                setLoginInProgress(false);
            });
    };

    return (
        <LoginForm loading={loginInProgress} onSubmit={handleLogin} />
    );
};

export default connect(
    (state: AppState) => ({
        isLogged: isLogged(state),
    }),
)(Login);
