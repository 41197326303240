import './DelayJumpsForm.css';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getDelayJumpsMain, getDelayJumpsVehiclePositions } from '../../../actions/anomaliesActions';
import { getFullRouteInfoByRouteName } from '../../../actions/gtfsStaticActions';
import Api from '../../../api/api';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import busIcon from '../../../static/bus-icon.png';
import busStopIcon from '../../../static/bus-stop-20.png';
import { DelayJumpsDetails, DelayJumpsMain, DelayJumpsVehiclePosition } from '../../../types/anomaliesTypes';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../../types/BingMapProps';
import { FullRouteInfo, FullRouteStopInfo } from '../../../types/gtfsTypes';
import { TableDispatchAction, TableSortProperties } from '../../../types/types';
import BingMapQuerier from '../../../utilities/BingMapQuerier';
import { _1px } from '../../../utilities/constants';
import BingMap from '../../shared/BingMap';
import PrettoSliderControl from '../../shared/PrettoSliderControl';
import DelayJumpsDetailsTable from './DelayJumpsDetailsTable';
import DelayJumpsMainTable from './DelayJumpsMainTable';


interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    map: {
        width: '100%',
        height: '522px',
        marginTop: '5px',
    } as React.CSSProperties,
    sliderContainer: {
        marginTop: '40px',
    } as React.CSSProperties,
    chartDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
};


const DelayJumpsForm: React.FC<Props> = ({ agency }) => {
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [mainState, setMainState] = useState<DelayJumpsMain[]>([]);
    const [detailsState, setDetailsState] = useState<DelayJumpsDetails[]>([]);
    const [selectedJumpState, setSelectedJumpState] = useState<DelayJumpsMain | undefined>(undefined);
    const [selectedDetailsState, setSelectedDetailsState] = useState<DelayJumpsDetails | undefined>(undefined);
    const [positionsState, setPositionsState] = useState<DelayJumpsVehiclePosition[]>([]);
    const [routeInfoState, setRouteInfoState] = useState<FullRouteInfo | undefined>(undefined);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [infoboxesState, setInfoboxesState] = useState<InfoboxProps[]>([]);
    const [sliderValueState, setSliderValueState] = useState<number>(0);
    const [selectedStopStat, setSelectedStopStat] = useState<FullRouteStopInfo | undefined>(undefined);
    const mapRef = React.createRef<BingMapQuerier>();

    const handleSelectedDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setSelectedDateState(value);
    };
    const jumpRowHandler = async (tripJump: DelayJumpsMain) => {
        setSelectedJumpState(tripJump);
        setDetailsState(tripJump.details);
        await updateRouteInfo(tripJump.routeName);
        await updateVehiclePositions(tripJump);        
    };
    const jumpDetailsRowHandler = (jumpDetails: DelayJumpsDetails) => {
        setSelectedDetailsState(jumpDetails);
        const positionIndex = positionsState.findIndex(p => p.vehicleTime === jumpDetails.prevTime);
        setSliderValueState(positionIndex);
    };

    const tableSortReducer = (state: TableSortProperties<DelayJumpsMain>, action: TableDispatchAction<DelayJumpsMain>): TableSortProperties<DelayJumpsMain> => {
        switch (action.type) {
            case 'CHANGE_SORT':
                if (state.column === action.column) {
                    return {
                        ...state,
                        data: state.data.slice().reverse(),
                        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                    };
                }
        }
        let data = [];
        switch (action.column) {
            case 'vehicleId':
            case 'routeName':
                data = state.data.sort((a, b) => {
                    const aVal = a[action.column] as string;
                    const bVal = b[action.column] as string;
                    if (aVal > bVal) {
                        return 1;
                    } else if (aVal < bVal) {
                        return -1;
                    }
                    return 0;
                });
                break;
            case 'tripSchedule':
                data = state.data.sort((a, b) => new Date(a[action.column]).getTime() - new Date(b[action.column]).getTime());
                break;
            case 'delayJumpsCount':
            case 'avgJumpSpeed':
                data = state.data.sort((a, b) => {
                    const aVal = a[action.column] as number | null;
                    const bVal = b[action.column] as number | null;
                    if (aVal === null)
                        return -1;
                    if (bVal === null)
                        return 1;
                    return aVal - bVal;
                });
                break;
            default:
                data = state.data;
        }
        return {
            column: action.column,
            data: data,
            direction: 'ascending',
        };
    };

    const updateMainTableData = async () => {
        setMainState([]);
        setPositionsState([]);
        if (agency && agency.id) {
            try {
                setFormBlockingState(true);
                const delayJumpsMain = await getDelayJumpsMain(agency.id, selectedDateState);
                setMainState(delayJumpsMain);
            } catch {
                setMainState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setMainState([]);
        }
    };

    const updateVehiclePositions = async (tripJump: DelayJumpsMain) => {
        if (agency && agency.id) {
            try {
                const { tripInternalId, vehicleId } = tripJump;
                setFormBlockingState(true);
                const positions = await getDelayJumpsVehiclePositions(agency.id, selectedDateState, tripInternalId, vehicleId);
                setPositionsState(positions);
            } catch {
                setPositionsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setPositionsState([]);
        }
    };

    const updateRouteInfo = async (routeName: string) => {
        if (!agency || !agency.id)
            return;
        if (routeInfoState && routeInfoState.routeName === routeName)
            return;
        try {
            setFormBlockingState(true);
            const routeInfo = await getFullRouteInfoByRouteName(agency.id, routeName);
            setRouteInfoState(routeInfo);
        } catch {
            setRouteInfoState(undefined);
            setSelectedStopStat(undefined);
        } finally {
            setFormBlockingState(false);
        }
    };

    const handleValueLabelFormat = (value: number): React.ReactNode => {
        const position = positionsState[value];
        const formattedDate = position ?
            moment.parseZone(position.vehicleTime).format('hh:mm a') :
            '';
        return <div style={{ textAlign: 'center', fontSize: '9px' }}>{formattedDate}</div>;
    };

    const handleSliderChange = (_e: React.ChangeEvent<{}>, value: number | number[]) => {
        const sliderIndex = value as number;
        setSliderValueState(sliderIndex);
    };

    const updateBusPosition = (position: DelayJumpsVehiclePosition | undefined) => {
        if (!position || !selectedJumpState || !mapRef.current)
            return;
        const busPushpin: PushpinProps = {
            location: [position.latitude, position.longitude],
            options: {
                icon: busIcon,
                anchor: new Microsoft.Maps.Point(_1px, _1px),
                catId: 'bus-pushpin',
            },
        };

        let desc = `<div>${selectedJumpState.vehicleId}</div>`;
        if (position.delaySec)
            desc += `<div>Delay ${Math.round(position.delaySec / 60 * 10) / 10} min</div>`;
        const busInfobox: InfoboxProps = {
            location: [position.latitude, position.longitude],
            options: {
                description: desc,
                visible: true,
            },
        };
        mapRef.current.updatePushpins([busPushpin]);
        setInfoboxesState([busInfobox]);       
    };

    useEffect(() => {
        if (sliderValueState < 0)
            return;
        const position = positionsState[sliderValueState];
        updateBusPosition(position);

        return () => {
            if (mapRef.current)
                mapRef.current.removeBusPushpins();
            setInfoboxesState([]);
        };
    }, [sliderValueState]);

    useEffect(() => {
        if (!routeInfoState || !selectedJumpState)
            return;
        const variantInfo = routeInfoState.directions.flatMap(d => d.directionVariants).find(dv => dv.shape.shapeInternalId === selectedJumpState.shapeInternalId);
        if (!variantInfo)
            return;
        const { shape: { points }, stops } = variantInfo;
        setPolylineState({
            locations: points.map(({ lat, lon }) => [lat, lon]),
            options: {
                strokeColor: routeInfoState.routeColor,
                strokeThickness: 3,
            },
        });        
        const stopPushpins: PushpinProps[] = stops.map(({ stop: { lat, lon } }) => ({
            location: [lat, lon],
            options: {
                icon: busStopIcon,
                catId: 'bus-stop',
            },
            eventHandlers: [{
                event: 'click',
                callback: () => {
                    setInfoboxesState(
                        stops.map(({ stop: { lat, lon }, stop: { stopCode, stopName } }) => ({
                            location: [lat, lon],
                            options: {
                                description: `<div>${stopCode}</div>` + `<div>${stopName}</div>`,
                                visible: Boolean(selectedStopStat && selectedStopStat.stop.lat === lat && selectedStopStat.stop.lon === lon),
                            },
                        })));
                    setInfoboxesState(prevState => (
                        prevState.map(i => i.location[0] === lat && i.location[1] === lon ?
                            { ...i, options: { ...i.options, visible: true } } :
                            { ...i, options: { ...i.options, visible: false } })
                    ));                                    
                },
            }],
        }));
        setPushpinsState(prevState => [...prevState, ...stopPushpins]);
        const locations = points.map(p => new Microsoft.Maps.Location(p.lat, p.lon));
        const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
        setMapBoundsState(boundingBox);

        return () => {
            setPushpinsState([]);
            setInfoboxesState([]);
        };
    }, [routeInfoState, selectedJumpState]);

    useEffect(() => {
        setMainState([]);
        setDetailsState([]);
        setPositionsState([]);
        if (agency === undefined) return;
        (async (agencyId: string) => {
            await updateMainTableData();
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Delay Jumps
                </Header>
                <div style={styles.chartDescription}>
                    Delay Jumps are unrealistic events when reported vehicle delay changes abruptly
                </div>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <label className="calendarInputLabel">Report Date:</label>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={updateMainTableData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                {mainState.length > 0 &&
                    <>
                        <Form.Group className="rowGroup">
                            <Form.Field width={16}>
                                <label className="categoryHeader">
                                    <div>Delays Jumps</div>
                                <div className="categorySubtitle">Click on a row to display details table and map. Jump Magnitude is a ratio between change in delay and time between delay measurements. For example, if in 10 seconds we measured change in delay from 10 to 100 seconds, the magnitude will be equal to (100-10)/10 = 9 delay seconds per second.</div>
                                </label>
                                <DelayJumpsMainTable rows={mainState} tableSortReducer={tableSortReducer} selectedTripJump={selectedJumpState} jumpRowHandler={jumpRowHandler} />
                            </Form.Field>
                        </Form.Group>
                        {detailsState.length > 0 && selectedJumpState &&
                            <>
                                <Form.Group className="rowGroup">
                                    <Form.Field width={16}>
                                        <label className="categoryHeader">
                                            <div>List of Delay Jump occurrences for vehicle # {selectedJumpState.vehicleId}</div>
                                            <div className="categorySubtitle">Select a row to highlight it on the map</div>
                                        </label>
                                        <DelayJumpsDetailsTable rows={detailsState} selectedRow={selectedDetailsState} selectedRowHandler={jumpDetailsRowHandler} />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={16} className="map-wrapper">
                                        <BingMap
                                            ref={mapRef}
                                            div={{ style: styles.map }}
                                            map={{
                                                center,
                                                options: {
                                                    bounds: mapBoundsState,
                                                },
                                            }}
                                            polylines={polylineState && [polylineState]}
                                            pushpins={pushpinsState}
                                            infoboxes={infoboxesState}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={16} style={styles.sliderContainer} >
                                        <PrettoSliderControl
                                            valueLabelDisplay="on"
                                            name="delayJumpsSlider"
                                            aria-label="pretto slider"
                                            min={0}
                                            max={positionsState.length > 0 ? positionsState.length - 1 : 0}
                                            onChange={handleSliderChange}
                                            step={1}
                                            value={sliderValueState}
                                            valueLabelFormat={handleValueLabelFormat}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </>
                        }
                    </>}
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(DelayJumpsForm);