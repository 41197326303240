import moment from 'moment';
import { User } from 'oidc-client';

export const LOCAL_STORAGE_KEY_FOR_USER = 'USER' as const;

/**
 * The `As<'validated'>` bit is just to ensure that a `false` result
 * isn't interpreted as `user is null | undefined` since we check more than just nullity here.
 */ 
export function isValidUser(user: User | undefined | null): user is User & As<'validated'> {
    if (!user) return false;
    if (!user.access_token) return false;
    if (!user.profile) return false;
    return moment().isBefore(moment.unix(user.expires_at));
}

export function saveCurrentUser(user: User | undefined | null): void {
    if (user === undefined || user === null) {
        localStorage.removeItem(LOCAL_STORAGE_KEY_FOR_USER);
        return;
    }
    if (!isValidUser(user)) {
        console.error('Try to save invalid user object', user);
        return;
    }
    try {
        localStorage.setItem(LOCAL_STORAGE_KEY_FOR_USER, JSON.stringify(user));
    }
    catch (e) {
        console.error('Error while saving user to local storage,', e);
    }
}

export function isValidUserWithRole(user: User | undefined | null): user is User & As<'validated'> {

    return isValidUser(user) && user.profile.roles;
}

export function getValidUserFromLocalStorage(): User | null {
    const userJson = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_USER);
    if (userJson === null) return null;

    try {
        const user: User = JSON.parse(userJson);
        if (isValidUserWithRole(user)) {
            return user;
        }
    }
    catch (e) {
        console.error('Error parsing user in local storage,', e, userJson);
    }
    
    localStorage.removeItem(LOCAL_STORAGE_KEY_FOR_USER);
    return null;
}

export function isUserExpired(): boolean {
    const userJson = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_USER);
    if (userJson === null) return false;
    const user: User = User.fromStorageString(userJson);
    console.log(`User expired status: ${user.expired}`);
    return user.expires_at !== undefined && user.expired;
}