import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DropdownProps, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getKpiData } from '../../../actions/otpActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { GtfsDirectionVariantModel } from '../../../types/gtfsTypes';
import { KpiData, KpiDetails } from '../../../types/trendsTypes';
import { DropDownStateType } from '../../../types/types';
import Utils from '../../../utilities/utils';
import TrendsReportingBusesChart from './TrendsReportingBusesChart';
import TrendsSimpleLineChart from './TrendsSimpleLineChart';
import TrendsStackedLineChart from './TrendsStackedLineChart';

interface RoutesDropDownStateType extends DropDownStateType {
    directions: GtfsDirectionVariantModel[],
}

interface KpiDetailsForPeriod {
    period: string,
    kpi: KpiDetails,
}

export interface DeviationDistribution {
    period: string,
    early: number;
    onTime: number;
    late: number;
    veryLate: number;
}

const directionDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};
const routesDropDownState: RoutesDropDownStateType = {
    options: [],
    selectedValue: '',
    directions: [],
};
const displayModeDropDownState: DropDownStateType = {
    options: [
        {
            value: 'Day',
            text: 'Days',
        },
        {
            value: 'Week',
            text: 'Weeks',
        },
        {
            value: 'Month',
            text: 'Months',
        },
    ],
    selectedValue: 'Week',
};
const styles = {
    chartDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
};

interface Props {
    agency: AgencyStateType | undefined;
}

const TrendsForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [routesState, setRoutesState] = useState(routesDropDownState);
    const [directionsState, setDirectionsState] = useState(directionDropDownState);
    const [displayModeState, setDisplayModeState] = useState(displayModeDropDownState);
    const [kpiDataState, setKpiDataState] = useState<KpiData[]>([]);
    const [filteredKpiDetailsState, setFilteredKpiDetailsState] = useState<KpiDetailsForPeriod[]>([]);
    const averageDeviationChartData = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.avgDelaySec !== undefined)
        .map(kpiDetails => {
            return {
                period: kpiDetails?.period as string,
                value: Math.round((kpiDetails?.kpi.avgDelaySec) as number / 60 * 10) / 10,
            };
        });
    const bunchingChartData = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.bunching !== undefined)
        .map(kpiDetails => {
            return {
                period: kpiDetails?.period as string,
                value: Math.round((kpiDetails?.kpi.bunching) as number * 10) / 10,
            };
        });
    const reportingBusesChartData = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.gtfsCoverage !== undefined && d.kpi.taipCoverage !== undefined)
        .map(kpiDetails => {
            return {
                period: kpiDetails?.period as string,
                gtfsValue: Math.round((kpiDetails?.kpi.gtfsCoverage) as number * 10) / 10,
                taipValue: Math.round((kpiDetails?.kpi.taipCoverage) as number * 10) / 10,
            };
        });
    const distributionSummaryChartData: DeviationDistribution[] = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.early !== undefined && d.kpi.onTime !== undefined && d.kpi.late !== undefined && d.kpi.veryLate !== undefined)
        .map(kpiDetails => {
            return {
                period: kpiDetails?.period as string,
                early: kpiDetails?.kpi.early as number,
                onTime: kpiDetails?.kpi.onTime as number,
                late: kpiDetails?.kpi.late as number,
                veryLate: kpiDetails?.kpi.veryLate as number,
            };
        });

    const handleRouteChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeName }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeName as string,
        }));
        if (routesState.directions.length === 0)
            return;
        const directions = routesState.directions.filter(r => r.routeShortName === routeName);
        if (!directions || directions.length === 0) {
            setDirectionsState(directionDropDownState);
        } else {
            setDirectionsState(prevState => ({
                ...prevState,
                selectedValue: '',
                options: directions
                    .sort((a, b) => a.directionVariantName.localeCompare(b.directionVariantName))
                    .map(d => ({ value: d.directionVariantInternalId, text: d.directionVariantName })),
            }));
        }

        const kpiDataForPeriod = kpiDataState.filter(d => d.period === displayModeState.selectedValue as string);
        const kpiDetails: KpiDetailsForPeriod[] = [];
        if (routeName) {
            for (const periodKpi of kpiDataForPeriod) {
                const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, displayModeState.selectedValue as string);
                const kpiForRoute = periodKpi.routes
                    .find(r => r.routeName == routeName)?.kpi;
                if (kpiForRoute)
                    kpiDetails.push({ period: periodValue, kpi: kpiForRoute });
            }
        } else {
            kpiDetails.push(...kpiDataForPeriod.map(d => {
                return {
                    period: getPeriodValue(d.startDate, d.endDate, displayModeState.selectedValue as string),
                    kpi: d.kpi,
                };
            }));
        }
        setFilteredKpiDetailsState(kpiDetails);
    };

    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantInternalId }: DropdownProps) => {
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantInternalId as string,
        }));
        const kpiDataForPeriod = kpiDataState.filter(d => d.period === displayModeState.selectedValue as string);
        const kpiDetails: KpiDetailsForPeriod[] = [];
        if (directionVariantInternalId) {
            for (const periodKpi of kpiDataForPeriod) {
                const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, displayModeState.selectedValue as string);
                const kpiForDirection = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.directions
                    .find(d => d.directionVariantInternalId == directionVariantInternalId)?.kpi;
                if (kpiForDirection)
                    kpiDetails.push({ period: periodValue, kpi: kpiForDirection });
            }
        } else if (routesState.selectedValue) {
            for (const periodKpi of kpiDataForPeriod) {
                const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, displayModeState.selectedValue as string);
                const kpiForRoute = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.kpi;
                if (kpiForRoute)
                    kpiDetails.push({ period: periodValue, kpi: kpiForRoute });
            }
        } else {
            kpiDetails.push(...kpiDataForPeriod.map(d => {
                return {
                    period: getPeriodValue(d.startDate, d.endDate, displayModeState.selectedValue as string),
                    kpi: d.kpi,
                };
            }));
        }
        setFilteredKpiDetailsState(kpiDetails);
    };
    const handleModeChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: displayMode }: DropdownProps) => {
        setDisplayModeState(prevState => ({
            ...prevState,
            selectedValue: displayMode as string,
        }));
        updateKpiDetailsByMode(kpiDataState, displayMode as string);
        updateRoutesAndDirections(kpiDataState, displayMode as string);
    };

    const updateKpiDetailsByMode = (kpiData: KpiData[], selectedMode: string) => {
        const kpiDataForPeriod = kpiData.filter(d => d.period === selectedMode);
        const kpiDetails: KpiDetailsForPeriod[] = [];
        for (const periodKpi of kpiDataForPeriod) {
            const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, selectedMode);
            if (routesState.selectedValue && directionsState.selectedValue) {
                const kpiForDirection = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.directions
                    .find(d => d.directionVariantInternalId == directionsState.selectedValue)?.kpi;
                if (kpiForDirection)
                    kpiDetails.push({ period: periodValue, kpi: kpiForDirection });
            } else if (routesState.selectedValue) {
                const kpiForRoute = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.kpi;
                if (kpiForRoute)
                    kpiDetails.push({ period: periodValue, kpi: kpiForRoute });
            } else if (periodKpi.kpi) {
                kpiDetails.push({ period: periodValue, kpi: periodKpi.kpi });
            }
        }
        setFilteredKpiDetailsState(kpiDetails);
    };

    const getPeriodValue = (startDate: Date, endDate: Date, selectedMode: string): string => {
        switch (selectedMode) {
            case 'Day':
                return moment(startDate).format('MMM DD, YYYY');
            case 'Week':
                return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`;
            case 'Month':
                return moment(startDate).format('MMM, YYYY');
            default:
                return '';
        }
    };

    const retrieveReportData = async () => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const kpiData = await getKpiData(agency.id);
                setKpiDataState(kpiData);
                updateRoutesAndDirections(kpiData, displayModeState.selectedValue as string);
                updateKpiDetailsByMode(kpiData, displayModeState.selectedValue as string);
            } catch {
                setKpiDataState([]);
                setFilteredKpiDetailsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setKpiDataState([]);
            setFilteredKpiDetailsState([]);
        }
    };

    const updateRoutesAndDirections = (kpiData: KpiData[], selectedMode: string) => {
        const kpiDataForPeriod = kpiData.filter(d => d.period === selectedMode);
        const directions: GtfsDirectionVariantModel[] = Utils.groupByEntries(kpiDataForPeriod.flatMap(d => d.routes), d => d.routeName)
            .flatMap(([routeShortName, routes]) =>
                Utils.groupByEntries(routes.flatMap(r => r.directions), d => d.directionVariantInternalId).map(([directionVariantInternalId, directionsGroup]) => ({
                    routeShortName,
                    directionVariantInternalId,
                    directionVariantName: Utils.getDirectionVariantName(directionsGroup[0].otsTripShortName, directionsGroup[0].tripHeadsign),
                })))
            .sort((a, b) => a.directionVariantInternalId.localeCompare(b.directionVariantInternalId));
        const routes = [...new Set(directions.map(d => d.routeShortName))];
        const directionVariantIds = [...new Set(directions.map(d => d.directionVariantInternalId))];
        if (routes.length === 0) {
            setRoutesState(routesDropDownState);
            setDirectionsState(directionDropDownState);
            return;
        }
        setRoutesState(prevState => ({
            options: routes.map(r => ({ value: r, text: r })),
            selectedValue: prevState.selectedValue && routes.includes(prevState.selectedValue) ? prevState.selectedValue : '',
            directions,
        }));
        setDirectionsState(prevState => {
            const selectedValue = prevState.selectedValue && directionVariantIds.includes(prevState.selectedValue) ? prevState.selectedValue : '';
            const directionsByRoute = routesState.selectedValue ? directions.filter(r => r.routeShortName === routesState.selectedValue) : [];
            return {
                selectedValue,
                options: directionsByRoute
                    .sort((a, b) => a.directionVariantName.localeCompare(b.directionVariantName))
                    .map(d => ({ value: d.directionVariantInternalId, text: d.directionVariantName })),
            };
        });
    };

    useEffect(() => {
        if (agency === undefined) return;
        (async () => await retrieveReportData())();
    }, [agency?.id]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Trends
                </Header>
                <div style={styles.chartDescription}>
                    The history of transit KPIs: delays at stops and their distribution, bunching, the completeness of TAIP and GTFS-Realtime devices. Select the aggregation period, route, and direction you are interested in. </div>
                <Form.Group className="inputGroup">
                    <Form.Select
                        placeholder="Choose display mode"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={displayModeState.options}
                        value={displayModeState.selectedValue}
                        onChange={handleModeChange}
                        width={3}
                    />
                    <Form.Select
                        placeholder="Select route"
                        fluid
                        search
                        selection
                        clearable
                        icon={routesState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesState.options}
                        value={routesState.selectedValue}
                        onChange={handleRouteChange}
                        width={3}
                    />
                    {routesState.selectedValue && <Form.Select
                        placeholder="Direction"
                        fluid
                        search
                        selection
                        clearable
                        icon={directionsState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={directionsState.options}
                        value={directionsState.selectedValue}
                        onChange={handleDirectionChange}
                        width={4}
                    />}
                </Form.Group>
                {kpiDataState.length > 0 &&
                    <>
                        <Form.Group className="rowGroup">
                            <Form.Field width={8}>
                                <label className="categoryHeader">Average Arrival Delay</label>
                                <TrendsSimpleLineChart chartData={averageDeviationChartData} axesLabelSign="min" dataLabel="Average Arrival Delay" />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label className="categoryHeader">Arrivals Distribution</label>
                                <TrendsStackedLineChart chartData={distributionSummaryChartData} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={8}>
                                <label className="categoryHeader">Bunching
                                    <div className="categorySubtitle"> Bunching is a situation when two buses following the same route in the same direction arrive at the stop almost simultaneously (25% of anticipated headway) instead of arriving at the scheduled interval. The lower the percentage of bunching is the better. 0% is an ideal situation.</div>
                                </label>
                                <TrendsSimpleLineChart chartData={bunchingChartData} axesLabelSign="%" dataLabel="Bunching" />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label className="categoryHeader">Reporting Buses
                                    <div className="categorySubtitle"> Not all vehicles have TAIP and GTFS-Realtime devices in working condition. There are also possible situations when the driver forgets to put the bus on the trip. So, it is impossible to display the bus in the application and make predictions. The higher the percentage of reporting buses is the better. 100% is an ideal situation.</div>
                                </label>
                                <TrendsReportingBusesChart chartData={reportingBusesChartData} axesLabelSign="%" />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(TrendsForm);