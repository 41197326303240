import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { logoutActionType } from '../../actions/actionTypes';
import userManager from '../../utilities/userManager';
import { LoginKind, setLoginKind } from './LoginForm';
import { logoutPathPrefix } from './routes';

const LogOut: React.FC<{ logout: () => void; }> = ({ logout }) => {
    useEffect(() => {
        logout();
        setLoginKind(LoginKind.REGULAR);
    }, []);

    return (
        <Redirect to={window.location.pathname.replace(logoutPathPrefix, '')} />
    );
};

export default connect(
    null,
    dispatch => ({
        logout: () => {
            userManager.signoutRedirectCallback()
                .then(() => {
                    userManager.removeUser();
                })
                .catch(e => {
                    console.error(e);
                });
            dispatch(logoutActionType);
        },
    }),
)(LogOut);
