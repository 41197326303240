import { ChartData, ChartOptions } from 'chart.js';
import * as crosshair from 'chartjs-plugin-crosshair';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BunchingVehicleData, BusBunchingArrival } from '../../../../types/busBunchingTypes';
import { ChartRef, LineChartDataPoint } from '../../../../types/chartTypes';
import Utils from '../../../../utilities/utils';

const styles = {
    chartContainer: {
        maxHeight: '560px',
        maxWidth: '597px',
        display: 'flex',
    } as React.CSSProperties,
};

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Trip 1',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#ba3021',
            borderColor: '#ba3021',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ba3021',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ba3021',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
        {
            label: 'Trip 2',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#2185d0',
            borderColor: '#2185d0',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#2185d0',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#2185d0',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options = {
    title: {
        display: true,
        text: '',
        fontSize: 16,
        fontColor: '#ba3021',
    },
    scales: {
        xAxes: [{
            type: 'linear',
            autoSkip: false,
            scaleLabel: {
                display: true,
                labelString: 'Local time',
            },
            distribution: 'linear',
            ticks: {
                stepSize: 600 * 1000,
                beginAtZero: false,
                callback: (value: number) => {
                    const date = moment(value);
                    return date.format('hh:mm A');
                },
            },
        }],
        yAxes: [{
            type: 'linear',
            autoSkip: false,
            ticks: {
                stepSize: 5,
            },
            scaleLabel: {
                display: true,
                labelString: 'Distance (miles)',
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 110,
        callbacks: {
            title: function () {
                return '';
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: '#ba3021',
                        backgroundColor: '#ba3021',
                    };
                } else {
                    return {
                        borderColor: '#2185d0',
                        backgroundColor: '#2185d0',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    //maintainAspectRatio: false
};

interface Props {
    arrivalData: BusBunchingArrival[],
    vehiclesData: BunchingVehicleData
}


export type BunchingArrivalTimeRef = Line & ChartRef;


const BunchingArrivalTimes = React.forwardRef<BunchingArrivalTimeRef, Props>(({ arrivalData, vehiclesData }, ref) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const orderedChartData = arrivalData.sort((a, b) => a.traveledKm > b.traveledKm ? 1 : -1);
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: orderedChartData.map(d => {
                    return {
                        x: moment(moment.parseZone(d.actualArrivalTime1).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Utils.kmToMiles(d.traveledKm),
                    };
                }),
            }, {
                ...prevState.datasets[1], data: orderedChartData.map(d => {
                    return {
                        x: moment(moment.parseZone(d.actualArrivalTime2).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Utils.kmToMiles(d.traveledKm),
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips, callbacks: {
                    ...prevState.tooltips.callbacks, label: function (tooltipItem: { datasetIndex: number, xLabel: number, yLabel: number, index: number }) {
                        const arrival = orderedChartData[tooltipItem.index];
                        const delay = tooltipItem.datasetIndex === 0 ? arrival.delaySec1 : arrival.delaySec2;
                        const time = moment.parseZone(tooltipItem.xLabel).format('hh:mm A');
                        const runTimesLabel = `arrival time: ${time} @ ${tooltipItem.yLabel.toFixed(2)} mil @ delay: ${Utils.convertSecondsToMinutes(delay, 'm', 's')}`;
                        return runTimesLabel;
                    },
                },
            },
        }));
    }, [arrivalData, vehiclesData]);

    return (
        <div style={styles.chartContainer}>
            <Line
                data={dataState as ChartData}
                height={250}
                options={optionsState as unknown as ChartOptions}
                plugins={[crosshair]}
                ref={ref}
            />
        </div>
    );
});

export default BunchingArrivalTimes;