import moment from 'moment';
import React from 'react';
import { List, Menu } from 'semantic-ui-react';
import { VehiclePositionState } from '../../types/busHistoryTypes';
import { SecondsIntervalOptions, secondsIntervalToString } from './_models';

export interface SliderInfoProps {
    routeName: string;
    stopName: string;
    vehiclePosition: VehiclePositionState;
    aheadPosition: VehiclePositionState | null;
}

function ValueItem(props: { label?: string, header?: string, value?: string | null }) {
    return (
        <List.Item>
            <List.Content>
                <div className="value">{props.value ? props.value : <>&mdash;</>}</div>
                {!props.header
                    ? null :
                    <List.Header>{props.header}</List.Header>
                }
                {!props.label
                    ? null :
                    <List.Description>{props.label}</List.Description>
                }
            </List.Content>
        </List.Item>
    );
}

const headwayIntervalOptions: SecondsIntervalOptions = {
    negativeSign: ' ahead',
    positiveSign: ' behind',
    position: 'after',
};

export default function BusHistorySliderInfo(props: SliderInfoProps) {
    const delayValue = typeof props.vehiclePosition.delaySec !== 'number'
        ? null
        : secondsIntervalToString(props.vehiclePosition.delaySec);
    const speedValue = typeof props.vehiclePosition.speed !== 'number'
        ? null
        : (props.vehiclePosition.speed * 0.621371).toFixed(1) + ' mph';
    const displayHeadways = Boolean(props.vehiclePosition.next || props.vehiclePosition.prev);
    return (
        <React.Fragment>
            <div className="sliderTime">
                <div>Time: <b>{moment.parseZone(props.vehiclePosition.reportTimeAtz).format('h:mm:ss A')}</b></div>
            </div>
            <Menu>
                <List divided size="large" className="celledMenuList">
                    <ValueItem label="Route" value={props.routeName} />
                    <ValueItem label="Vehicle" value={props.vehiclePosition.vehicleId} />
                    <ValueItem label="Block ID" value={props.vehiclePosition.blockId} />
                    <List.Item>
                        <List.Content>
                            <List.Header>{props.stopName}</List.Header>
                        </List.Content>
                    </List.Item>
                    <ValueItem label="Delay from schedule"
                        value={delayValue}
                    />
                    <ValueItem label="Speed"
                        value={speedValue}
                    />
                    {displayHeadways && <> <List.Item>
                        <List.Content>
                            <List.Header>Headways</List.Header>
                        </List.Content>
                    </List.Item>
                        <ValueItem header={props.vehiclePosition.next ? props.vehiclePosition.next.vehicleId : undefined}
                            value={props.vehiclePosition.next ? secondsIntervalToString(props.vehiclePosition.next.seconds, headwayIntervalOptions) : undefined}
                        />
                        <ValueItem header={props.vehiclePosition.prev ? props.vehiclePosition.prev.vehicleId : undefined}
                            value={props.vehiclePosition.prev ? secondsIntervalToString(-props.vehiclePosition.prev.seconds, headwayIntervalOptions) : undefined}
                        />
                    </>}
                </List>
            </Menu>
        </React.Fragment>
    );
}
