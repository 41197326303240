import * as React from 'react';
import { Icon, Segment, SemanticCOLORS, SemanticICONS, Table } from 'semantic-ui-react';
import { IRouteHeadwayEntry, IRouteVehicle, Thresholds } from './_interfaces';
import TimeInterval from './TimeInteval';

interface HeadwayListProps {
    items: IRouteHeadwayEntry[];
    thresholds: Thresholds;
    map: boolean;
    onShowVehicle: (vehicle: IRouteVehicle, map: boolean) => void;
}

const icons: { name: SemanticICONS, color: SemanticCOLORS }[] = [
    { name: 'circle', color: 'red' },
    { name: 'circle', color: 'yellow' },
    { name: 'circle outline', color: 'grey' },
];

export default function HeadwayList(props: HeadwayListProps) {
    return (
        <Segment
            id="headway-list"
            clearing
            style={{ display: 'inline-block', border: 'none', padding: 0, marginTop: 0 }}
        >
            <Table size="small" compact collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Route</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Dir</Table.HeaderCell>
                        <Table.HeaderCell>Headway</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body style={{ fontWeight: 500 }}>
                    {props.items.map((item, index) => {
                        const error =
                            item.secondsReal < props.thresholds.bunchedError ||
                            item.secondsReal > props.thresholds.spreadError;

                        const warning = !error && (
                            item.secondsReal < props.thresholds.bunchedWarning ||
                            item.secondsReal > props.thresholds.spreadWarning);

                        const icon = icons[error ? 0 : warning ? 1 : 2];

                        const handleClick = () => {
                            const vehicle = item.routeVariant.vehicles?.dict[item.vehicleId];
                            if (vehicle && props.onShowVehicle) {
                                props.onShowVehicle(vehicle, props.map);
                            }
                        };

                        return (
                            <Table.Row key={index} error={error} warning={warning} onClick={handleClick}>
                                <Table.Cell style={{ whiteSpace: 'nowrap' }}>
                                    <Icon name={icon.name} color={icon.color} />
                                    {item.routeVariant.name}
                                </Table.Cell>
                                <Table.Cell>{item.vehicleId}</Table.Cell>
                                <Table.Cell textAlign="center">{item.cardinalDirection}</Table.Cell>
                                <Table.Cell textAlign="right"><TimeInterval seconds={item.secondsReal}/></Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </Segment>
    );
}
