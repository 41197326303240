import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { DSVRowArray } from 'd3';
import * as React from 'react';
import { useImperativeHandle, useRef, useState } from 'react';
import { ColumnDefinition, ReactTabulator, ReactTabulatorOptions } from 'react-tabulator';
import ColumnsDefinition from './ColumnsDefinition';
import { RowData } from './GtfsEditorForm';
import GtfsTableSettings from './GtfsTableSettings';

export interface GtfsTableHandle {
    setHeaderFilterValue: (fieldName: string, data: string) => void;
    setMultiplyValueFilter: (fieldName: string, data: string[]) => void;
    clearHeaderFilter: () => void;
    reRender: () => void;
    fileName: string;
}

export interface Props {
    fileName: string;
    data: DSVRowArray<string>;
    onCellClick: (rowData: RowData, fieldName: string, value: string) => void
}

interface ReactTabulatorRef {
    getColumn: (fieldName: string) => ColumnDefinition;
    setHeaderFilterValue: (fieldName: string, data: string) => void;
    clearFilter: () => void;
    clearHeaderFilter: () => void;
    addFilter: (filterFunc: (rowData: RowData) => boolean) => void;
}

const GtfsTable = React.forwardRef<GtfsTableHandle, Props>(({
                                                                fileName,
                                                                data,
                                                                onCellClick,
                                                            }, ref) => {
    const tableRef = useRef<ReactTabulatorRef>();
    const [toggle, setToggle] = useState(true);

    const handleToggleClick = () => {
        setToggle(!toggle);
    };

    useImperativeHandle(ref, () => ({
        setHeaderFilterValue(fieldName, data) {
            // todo: maybe need optimize
            if (tableRef && tableRef.current && tableRef.current.getColumn(fieldName)) {
                tableRef.current.setHeaderFilterValue(fieldName, data);
            }
        },
        setMultiplyValueFilter(fieldName: string, data: string[]) {
            const filterFunc = (rowData: RowData) => {
                return data.includes(rowData[fieldName]);
            };
            if (tableRef && tableRef.current && tableRef.current.getColumn(fieldName)) {
                tableRef.current.clearFilter();
                tableRef.current.addFilter(filterFunc);
            }
        },
        clearHeaderFilter() {
            if (tableRef && tableRef.current) {
                tableRef.current.clearFilter();
                tableRef.current.clearHeaderFilter();
            }
        },
        reRender() {
            if (tableRef && tableRef.current) {
                // HACK: force update for table
                setToggle(false);
                setToggle(true);
            }
        },
        fileName,
    }));

    const searchAnywhere = (rowData: RowData, fieldName: string, value: any) => {
        onCellClick(rowData, fieldName, value);
    };
    const columns = ColumnsDefinition(fileName, searchAnywhere, data[0]);
    const options = GtfsTableSettings(fileName);

    return (
        <div>
            <p style={{
                padding: '0px',
                margin: '16px 0px -4px 0px',
                fontSize: '18px',
                fontFamily: 'Monospace bold',
            }}>{fileName}</p>
            <button style={{ margin: '4px 0px -4px 0px' }}
                    onClick={handleToggleClick}>
                display {toggle ? '-' : '+'}
            </button>
            {toggle &&
                <ReactTabulator
                    onRef={r => tableRef.current = r.current}
                    data={data}
                    options={options}
                    names={fileName}
                    columns={columns}
                    height={300}
                    importFormat={'csv'}
                    layout={'fitColumns'}
                />
            }
        </div>
    );
});

export default GtfsTable;

export interface GtfsTableOptions {
    [k: string]: ReactTabulatorOptions;
}