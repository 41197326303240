import * as React from 'react';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { TableSortProperties } from '../../../types/types';
import Utils from '../../../utilities/utils';
import { RouteLevelPerformanceTableType } from './OverallPerformanceForm';

const styles = {
    table: {
        display: 'block',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface TableProps {
    tableDataState: TableSortProperties<RouteLevelPerformanceTableType>;
    displayAggregatedTripsTable: (routeName: string, directionVariantInternalId: string) => Promise<void>;
    columnSortHandler: (column: string) => void;
}

const RouteLevelTable: React.FC<TableProps> = ({ tableDataState, displayAggregatedTripsTable, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'routeName' ? direction : undefined}
                            onClick={() => columnSortHandler('routeName')}>
                            Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'directionVariantName' ? direction : undefined}
                            onClick={() => columnSortHandler('directionVariantName')}>
                            Direction
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'performance' ? direction : undefined}
                            onClick={() => columnSortHandler('performance')}>
                            Performance (%)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'fastestTripTime' ? direction : undefined}
                            onClick={() => columnSortHandler('fastestTripTime')}>
                            Fastest Trip Duration (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'scheduledTripTime' ? direction : undefined}
                            onClick={() => columnSortHandler('scheduledTripTime')}>
                            Scheduled Trip Duration (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'dwellTime' ? direction : undefined}
                            onClick={() => columnSortHandler('dwellTime')}>
                            Avg Trip Dwell (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'inMotion' ? direction : undefined}
                            onClick={() => columnSortHandler('inMotion')}>
                            In Motion (min)
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((routePerformance, index) => (
                        <RouteLevelTableRow key={index} routePerformance={routePerformance} displayAggregatedTripsTable={displayAggregatedTripsTable} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default RouteLevelTable;

interface RowProps {
    routePerformance: RouteLevelPerformanceTableType;
    displayAggregatedTripsTable: (routeName: string, directionVariantInternalId: string) => Promise<void>;
}

const RouteLevelTableRow: React.FC<RowProps> = ({
    routePerformance: {
        routeName,
        directionVariantInternalId,
        directionVariantName,
        performance,
        fastestTripTime,
        dwellTime,
        inMotion,
        scheduledTripTime,
    },
    displayAggregatedTripsTable,
}) => {

    const routeLinkClickHandler = async (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        if (displayAggregatedTripsTable)
            await displayAggregatedTripsTable(routeName, directionVariantInternalId);
    };

    return (
        <Table.Row>
            <Table.Cell>
                <Link to="/" onClick={routeLinkClickHandler}> {routeName}</Link>
            </Table.Cell>
            <Table.Cell>{directionVariantName}</Table.Cell>
            <Table.Cell>{performance}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(fastestTripTime)}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(scheduledTripTime)}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(dwellTime)}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(inMotion)}</Table.Cell>
        </Table.Row>
    );
};