import { ReactTabulatorOptions } from 'react-tabulator';

const optionsTripTable: ReactTabulatorOptions = {
    initialSort: [{
        column: 'trip_id',
        dir: 'asc',
    }],
};

const tableOptions: ReactTabulatorOptions = {
    'trips.txt': optionsTripTable,
};

export default function GtfsTableSettings(tableName: string) {
    return tableOptions[tableName] ?? {};
}