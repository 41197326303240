import { InfoboxProps, PushpinProps } from '../../types/BingMapProps';
import { MapPointKind } from './MapPointKind';
import { TripPlannerContext } from './TripPlannerContext';
import { colorInput, colorOrigin, colorTarget, renderInfoboxContent, toCoords } from './TripPLannerUtils';

export class MapPoint {
    constructor(
        context: TripPlannerContext,
        public location: Microsoft.Maps.Location,
    ) {
        this.id = `tp-${new Date().valueOf()}`;
        this._address = null;
        this.pushpin = {
            location: toCoords(location),
            options: {
                color: Microsoft.Maps.Color.fromHex(colorInput),
            },
        };
        this.infobox = {
            location: this.pushpin.location,
            options: {
                showPointer: true,
                showCloseButton: false,
            },
        };
        this._kind = (-1 as MapPointKind);
        this.setKind(context, MapPointKind.unknown);
    }

    private _kind: MapPointKind;
    private _address: string | null;

    public readonly id: string;
    public readonly pushpin: PushpinProps;
    public readonly infobox: InfoboxProps;

    public get kind(): MapPointKind { return this._kind; }
    public get address(): string | null { return this._address; }

    public get showInfobox(): boolean {
        return !!this.address;
    }

    public setLocation(location: Microsoft.Maps.Location): boolean {
        if (this.location.latitude === location.latitude &&
            this.location.longitude === location.longitude)
            return false;

        this.location = location;
        this.pushpin.location = toCoords(location);
        this.infobox.location = this.pushpin.location;

        return true;
    }

    public updateInfoboxOptions(context: TripPlannerContext) {
        if (this.infobox?.options) {
            this.infobox.options.htmlContent = renderInfoboxContent(context.uid, this.id, this._kind, this.address);
            return true;
        }
        return false;
    }

    public setAddress(context: TripPlannerContext, newValue: string | null): boolean {
        const oldValue = this._address;
        if (oldValue === newValue) return false;

        this._address = newValue;
        if (this.infobox.options) {
            this.updateInfoboxOptions(context);
            // if (!newValue) {
            //     delete this.infobox.options.title;
            // } else {
            //     this.infobox.options.title = newValue;
            // }
        }
        return true;
    }

    public setKind(context: TripPlannerContext, newValue: MapPointKind): boolean {
        if (this._kind === newValue) return false;
        this._kind = newValue;
        if (this.pushpin.options) {
            switch (this._kind) {
                case MapPointKind.unknown:
                    this.pushpin.options.color = Microsoft.Maps.Color.fromHex(colorInput);
                    break;
                case MapPointKind.from:
                    this.pushpin.options.color = Microsoft.Maps.Color.fromHex(colorOrigin);
                    break;
                case MapPointKind.to:
                    this.pushpin.options.color = Microsoft.Maps.Color.fromHex(colorTarget);
                    break;
            }
        }
        return this.updateInfoboxOptions(context);
    }
}