import './LiveMapStyles.css';
import './BusMarkerStyles.css';
import './LinearViewStyles.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';
import { AgencyStateType } from '../../actions/actionTypes';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';
import BingMap from '../shared/BingMap';
import RouteVariantsSelector from '../shared/RouteVariantsSelector';
import { IRouteStop, IRouteVehicle } from './_interfaces';
import HeadwayList from './HeadwayList';
import LinearView from './LinearView';
import { LiveMapContext, LiveMapState } from './LiveMapContext';
import ThresholdsSelector from './ThresholdsSelector';

enum LiveMapViewMode {
    MAP = 'Map',
    LINEAR = 'Linear'
}

type LiveMapProps = {
    agency: AgencyStateType | undefined;
};

const LiveMapFormContent: React.FC<LiveMapProps> = ({ agency }) => {
    const context = React.useContext(LiveMapContext);
    
    context.helper.onStopClick = (stop: IRouteStop) => {
        context.setSelectedStop(context.selectedStop === stop ? null : stop);
    };
    
    React.useEffect(() => {
        console.log('LiveMapFormContent.activateTracking');
        context.activateTracking();
        return () => {
            console.log('LiveMapFormContent.deactivateTracking');
            context.deactivateTracking();
        };
    }, []);

    const [viewMode, setViewMode] = React.useState<LiveMapViewMode>(LiveMapViewMode.MAP);
    const [showSidePane, setShowSidePane] = useState(true);
    const [mapBounds, setMapBounds] = React.useState<Microsoft.Maps.LocationRect | undefined>();

    useEffect(() => {
        context.setAgencyId(agency?.id || null);
    }, [agency?.id || null]);

    const handleShowCheckbox = () => {
        setShowSidePane(!showSidePane);
    };

    const handleRouteVariantChecked = (route: IRouteModel, direction: IDirectionModel, checked: boolean) => {
        if (checked)
            context.selectRouteDirection(route, direction);
        else
            context.deselectRouteDirection(route, direction);
    };

    const handleShowVehicle = (vehicle: IRouteVehicle, map: boolean) => {
        context.setSelectedVehicle(context.selectedVehicle === vehicle ? null : vehicle);
        if (map) {
            const delta = (1.0 / 60.0) / 3; // 1/3 of an arc minute
            const boundingBox = Microsoft.Maps.LocationRect.fromEdges(
                vehicle.location.latitude - delta,
                vehicle.location.longitude - delta,
                vehicle.location.latitude + delta,
                vehicle.location.longitude + delta,
            );
            setMapBounds(boundingBox);
        }
    };

    if (!agency)
        return null;

    return (
        <div className="livemap-container">

            <div className="livemap map">
                <Segment.Group className="content">
                    <Segment className="ctl">
                        <div>
                            <div style={{ float: 'right' }}>
                                <Button basic circular size="small" icon={showSidePane ? 'chevron right' : 'chevron left'} onClick={handleShowCheckbox} />
                            </div>
                            <ThresholdsSelector data={context.headwayThresholds} onChange={context.setHeadwayThresholds} />
                            <Button.Group basic size="small" >
                                <Button active={viewMode === LiveMapViewMode.MAP} onClick={() => setViewMode(LiveMapViewMode.MAP)}>{LiveMapViewMode.MAP}</Button>
                                <Button active={viewMode === LiveMapViewMode.LINEAR} onClick={() => setViewMode(LiveMapViewMode.LINEAR)}>{LiveMapViewMode.LINEAR}</Button>
                            </Button.Group>
                            {viewMode !== LiveMapViewMode.MAP
                                ? null :
                                <Button  basic size="small" active={context.nonTripVisible} onClick={() => context.setNonTripVisible(!context.nonTripVisible)}>
                                    {context.nonTripVisible ? 'Hide ' : 'Show '} Non-Trip Vehicles
                                </Button>
                            }
                        </div>
                    </Segment>
                    <Segment className="map" style={{ position: 'relative', borderColor: 'inherit' }}>
                        <div className="wrapper-map"
                            style={{
                                display: viewMode === LiveMapViewMode.MAP ? 'block' : 'none',
                                position: 'absolute',
                                top: 0, left: 0, right: 0, bottom: 0,
                                borderColor: 'inherit',
                            }} >
                            {!context.mapCenter
                                ? null :
                                <BingMap
                                    map={{
                                        center: context.mapCenter,
                                        options: {
                                            zoom: 11,
                                            bounds: mapBounds,
                                        },
                                    }}
                                    polylines={context.mapPolylines}
                                    pushpins={context.mapPushpins}
                                    infoboxes={context.mapInfoboxes}
                                />
                            }
                        </div>
                        {viewMode === LiveMapViewMode.MAP
                            ? <HeadwayList
                                map={true}
                                items={context.headwaysList}
                                thresholds={context.headwayThresholds}
                                onShowVehicle={handleShowVehicle}
                            />
                            : (
                                <div className="wrapper-linear"
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        top: 0, left: 0, right: 0, bottom: 0,
                                        borderColor: 'inherit',
                                        overflow: 'auto',
                                    }}
                                >
                                    <div style={{
                                        minWidth: 200,
                                        height: '100%',
                                        overflow: 'auto',
                                        marginRight: '0.5em',
                                        borderRight: '1px solid',
                                        borderColor: 'inherit',
                                        padding: '1rem',
                                    }}
                                    >
                                        <HeadwayList
                                            map={false}
                                            items={context.headwaysList}
                                            thresholds={context.headwayThresholds}
                                            onShowVehicle={handleShowVehicle}
                                        />
                                    </div>
                                    <LinearView />
                                </div>
                            )
                        }
                    </Segment>
                </Segment.Group>
            </div>
            { !showSidePane
                ? null :
                <div className="livemap side">
                    <Segment id="routeSelectionPanel">
                        {!context.routesList || context.routeListLoading ? <p>Loading active routes...</p> : null}
                        {context.routesList?.map((r, i) => (<RouteVariantsSelector key={i} route={r} onChecked={handleRouteVariantChecked} />))}
                    </Segment>
                </div>
            }
        </div>
    );
};

const LiveMapForm: React.FC<LiveMapProps> = ({ agency }) => {
    return (
        <LiveMapState>
            <LiveMapFormContent agency={agency} />
        </LiveMapState>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(LiveMapForm);
