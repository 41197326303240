import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Dropdown, IconProps, Image, ImageProps, SemanticShorthandItem } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/src/modules/Dropdown/DropdownItem';
import { setAgencyId } from '../../actions/actions';
import { AgenciesActionTypes, AgencyStateType, LogoutActionType } from '../../actions/actionTypes';
import { AppState } from '../../reducers';
import { getAgencies, getSelectedOrDefaultAgency } from '../../selectors';
import { AgencyStorage } from '../../utilities/agencyStorage';
import userManager from '../../utilities/userManager';
import { userSettingsRoute } from './routes';
import { icon_log_out, icon_settings, icon_user } from './svg-consts';

const userMenuIconShorthandProps: SemanticShorthandItem<IconProps> = {
    as: Image,
    src: icon_user.data,
    width: icon_user.width, height: icon_user.height,
    avatar: true,
    size: 'mini',
    spaced: true,
    circular: true,
    bordered: true,
    alt: 'Account Menu',
};

const userSettingsIconShorthandProps: SemanticShorthandItem<IconProps> = {
    as: Image,
    src: icon_settings.data,
    width: icon_settings.width, height: icon_settings.height,
    alt: '',
};

const userLogOutIconShorthandProps: SemanticShorthandItem<ImageProps> = {
    src: icon_log_out.data,
    width: icon_log_out.width, height: icon_log_out.height,
    alt: '',
};

interface UserMenuProps {
    displayName: string | undefined;
    selectedAgency: AgencyStateType | undefined;
    agencies: AgencyStateType[] | undefined;
    onChangeAgency: React.ChangeEventHandler<HTMLSelectElement>;
    onClickLogOut: (idToken: string) => void;
    idToken: string | undefined;
}

const UserMenu: React.FC<UserMenuProps> = ({ displayName, selectedAgency, agencies, idToken, onChangeAgency, onClickLogOut }) => {
    const logoutHandler = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, _data: DropdownItemProps) => {
        if (idToken) {
            onClickLogOut(idToken);
        }
    };
    return (
        <Dropdown className="user-menu" text={selectedAgency?.name} icon={userMenuIconShorthandProps} direction="left">
            <Dropdown.Menu>
                <Dropdown.Header>{displayName}</Dropdown.Header>
                <Dropdown.Header className="user-agency-select">
                    <select value={selectedAgency?.id} onClick={e => e.stopPropagation()} onChange={onChangeAgency}>
                        {agencies?.map(({ id, name }) => (
                            <option key={id} value={id}>{name}</option>
                        ))}
                    </select>
                </Dropdown.Header>
                <Dropdown.Item text="Settings" image={userSettingsIconShorthandProps}
                    as={Link} to={userSettingsRoute.path}
                />
                <Dropdown.Divider />
                <Dropdown.Item text="Log Out" image={userLogOutIconShorthandProps}
                    onClick={logoutHandler}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

function useUser(state: AppState): User | null {
    return state.user;
}

export default connect(
    (state: AppState) => ({
        displayName: useUser(state)?.profile?.name,
        selectedAgency: getSelectedOrDefaultAgency(state),
        agencies: getAgencies(state),
        idToken: useUser(state)?.id_token,
    }),
    (
        dispatch: ThunkDispatch<AppState, unknown, AgenciesActionTypes | LogoutActionType>,
    ): Pick<UserMenuProps, 'onChangeAgency' | 'onClickLogOut'> => ({
        onChangeAgency: e => {
            const agencyId = e.currentTarget.value;
            if (agencyId)
                AgencyStorage.agencyId = agencyId;
            dispatch(setAgencyId(agencyId));
        },
        onClickLogOut: (id_token: string) => {
            userManager.signoutRedirect({ 'id_token_hint': id_token });
        },
    }),
)(UserMenu);
