export const overallChartsFormType = 'overallChatsForm';
export const routesChartsFormType = 'routesChartsForm';

export interface AgencyStats {
    numberOfRoutes: number;
    numberOfStops: number;
}
export interface DashboardRealtimeData {
    tripDelaysDistribution: TripDelaysDistribution[],
    currentTrips: CurrentTrip[],
}
export interface TripDelaysDistribution {
    category: DashboardDelayCategory;
    value: number;
}

export enum DashboardDelayCategory {
    notReporting,
    gtTen,
    fourToTen,
    mOneToFour,
    ltMOne
}

export interface CurrentTrip {
    tripId: string;
    tripInternalId: string;
    delaySeconds: number | null;
    routeName: string;
    routeNumber: string;
    tripStartTime: string;
    tripEndTime: string;
    vehicleId: string;
    direction: string;
    directionVariantName: string;
    [key: string]: any;
}
export interface TripDelayHistory {
    delaySeconds: number;
    localTime: string;
    latitude: Latitude;
    longitude: Longitude;
}
export interface TwelveHoursStatusItem {
    reportTime: Date;
    notReportedCount: number;
    reportedCount: number;
}
export interface DelaysAnalysisTableData {
    vehicleId: string;
    routeName: string;
    directionName: string;
    tripId: string;
    tripInternalId: string;
    shapeId: string;
    tripScheduleStart: Date;
    tripScheduleFinish: Date;
    ourMeanDelayErrorSec: number | null;
    ourMaxDelayErrorSec: number | null;
    [key: string]: any;
}