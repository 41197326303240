import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DashboardDelayCategory, TripDelaysDistribution } from '../../../types/otpTypes';
import Utils from '../../../utilities/utils';
import { DelaySort, delayStatusColors } from './models';

const styles = {
    chartContainer: {
        width: '660px',
        display: 'flex',
        marginTop: '50px',
    } as React.CSSProperties,
};

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            data: [] as number[],
            backgroundColor: [] as string[],
            borderWidth: 0,
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    responsive: false,
    plugins: {
        crosshair: false,
    },
    legend: {
        display: false,
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                return `${tooltipItem.yLabel} %`;
            },
        },
    },
};

const DelaysStatusChart: React.FC<{ chartData: TripDelaysDistribution[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const colors: string[] = [];
        const labels: string[] = [];
        for (const data of chartData) {
            switch (data.category) {
                case DashboardDelayCategory.notReporting:
                    labels.push('Not Reporting *');
                    colors.push(delayStatusColors[DelaySort.NotReporting].fill);
                    break;
                case DashboardDelayCategory.gtTen:
                    labels.push('Very Late (> 10 min)');
                    colors.push(delayStatusColors[DelaySort.VeryLate].fill);
                    break;
                case DashboardDelayCategory.fourToTen:
                    labels.push('Late (4 - 10 min)');
                    colors.push(delayStatusColors[DelaySort.Late].fill);
                    break;
                case DashboardDelayCategory.mOneToFour:
                    labels.push('On Time (-1 - 4 min)');
                    colors.push(delayStatusColors[DelaySort.OnTime].fill);
                    break;
                case DashboardDelayCategory.ltMOne:
                    labels.push('Early (< -1 min)');
                    colors.push(delayStatusColors[DelaySort.Early].fill);
                    break;
                default:
                    labels.push('-');
                    colors.push('#FFFFFF');
            }
        }
        const totalValues = chartData.map(d => d.value).reduce((sum, val) => sum + val, 0);
        const values = chartData.map(d => Utils.roundNumber(d.value / totalValues * 100, 0));
        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0], data: values, backgroundColor: colors,
            }],
        }));
    }, [chartData]);

    return (
        <div style={styles.chartContainer}>
            <Bar
                data={dataState as ChartData}
                height={350}
                width={400}
                options={options as unknown as ChartOptions}
            />
        </div>
    );
};

export default DelaysStatusChart;