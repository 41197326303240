import api from '../api/alertsApi';
import { ServiceAlert } from '../types/serviceAlertsTypes';

export async function getAlertsForPeriod(agencyId: string, startDate: string, endDate: string): Promise<ServiceAlert[]> {
    const result = await api.getAlertsForPeriod(agencyId, startDate, endDate);
    return result.data || [];
}
export async function saveServiceAlert(alert: ServiceAlert): Promise<ServiceAlert> {
    const result = await api.saveServiceAlert(alert);
    return result.data;
}
export async function deleteServiceAlert(alertId: number) {
    await api.deleteServiceAlert(alertId);
}