import * as React from 'react';
import { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { WrongCoordinatesVehicle } from '../../../types/anomaliesTypes';
import BingMap from '../../shared/BingMap';
import PrettoSliderControl from '../../shared/PrettoSliderControl';
import { WrongCoordinatesTabForm } from './WrongCoordinatesForm';
import WrongCoordinatesTripsTable from './WrongCoordinatesTripsTable';
import WrongCoordinatesTripTableSortReducer from './WrongCoordinatesTripTableSortReducer';
import WrongCoordinatesVehiclesTable from './WrongCoordinatesVehiclesTable';
import WrongCoordinatesVehiclesTableSortReducer from './WrongCoordinatesVehiclesTableSortReducer';


const styles = {
    map: {
        width: '100%',
        height: '522px',
        marginTop: '5px',
    } as React.CSSProperties,
    sliderContainer: {
        marginTop: '40px',
    } as React.CSSProperties,
};

interface Props extends WrongCoordinatesTabForm {
    vehiclesData: WrongCoordinatesVehicle[],
    vehiclesRowHandler: (vehicleId: string) => void;
    selectedVehicleId: string | undefined;
}

const WrongCoordinatesVehiclesForm: React.FC<Props> = ({
    vehiclesData,
    vehiclePositions,
    vehiclesRowHandler,
    tripsData,
    selectedTrip,
    tripsRowHandler,
    selectedVehicleId,
    agencyOffset,
    center,
    mapBounds,
    polylines,
    pushpins,
    handleSliderChange,
    sliderValue,
    handleSliderValueLabelFormat,
    infoboxes,
    mapRef,
    mapEventHandlers,
}) => {

    const [vehicleTableState, vehicleTableDispatch] = React.useReducer(WrongCoordinatesVehiclesTableSortReducer, {
        column: '',
        data: [],
        direction: undefined,
    });

    const [tripTableState, tripTableDispatch] = React.useReducer(WrongCoordinatesTripTableSortReducer, {
        column: '',
        data: [],
        direction: undefined,
    });
    const handleTripTableColumnSort = (column: string) => {
        tripTableDispatch({ type: 'CHANGE_SORT', column, data: tripsData });
    };

    const handleVehicleTableColumnSort = (column: string) => {
        vehicleTableDispatch({ type: 'CHANGE_SORT', column, data: vehiclesData });
    };

    useEffect(() => {
        vehicleTableDispatch(({ type: 'UPDATE_DATA', data: vehiclesData, column: '' }));
    }, [vehiclesData]);

    useEffect(() => {
        tripTableDispatch(({ type: 'UPDATE_DATA', data: tripsData, column: '' }));
    }, [tripsData]);

    return (
        <>
            <Form.Group>
                <Form.Field width={16}>
                    <label className="categoryHeader">
                        <div className="categorySubtitle">Click on a row to display trips table</div>
                    </label>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={16}>
                    <WrongCoordinatesVehiclesTable tableDataState={vehicleTableState} rows={vehiclesData} selectedVehicleId={selectedVehicleId} selectedRowHandler={vehiclesRowHandler} columnSortHandler={handleVehicleTableColumnSort} />
                </Form.Field>
            </Form.Group>
            {tripsData.length > 0 &&
                <>
                    <Form.Group>
                        <Form.Field width={16}>
                            <label className="categoryHeader">
                                <div className="categorySubtitle">Click on a row to display map</div>
                            </label>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group className="rowGroup">
                        <Form.Field width={16}>
                            <WrongCoordinatesTripsTable tableDataState={tripTableState} rows={tripsData} selectedRow={selectedTrip} selectedRowHandler={tripsRowHandler} agencyOffset={agencyOffset} columnSortHandler={handleTripTableColumnSort} />
                        </Form.Field>
                    </Form.Group>
                    {selectedTrip && vehiclePositions.length > 0 &&
                        <>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <label className="categoryHeader">
                                        <div className="categorySubtitle">Red lines designate deviation from the original route shape. Zoom in to see the stop icons.</div>
                                    </label>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <BingMap
                                        ref={mapRef}
                                        div={{ style: styles.map }}
                                        map={{
                                            center,
                                            options: {
                                                bounds: mapBounds,
                                            },
                                            eventHandlers: mapEventHandlers,
                                        }}
                                        polylines={polylines}
                                        pushpins={pushpins}
                                        infoboxes={infoboxes}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16} style={styles.sliderContainer} >
                                    <PrettoSliderControl
                                        valueLabelDisplay="on"
                                        name="delayJumpsSlider"
                                        aria-label="pretto slider"
                                        min={0}
                                        max={vehiclePositions.length > 0 ? vehiclePositions.length - 1 : 0}
                                        onChange={handleSliderChange}
                                        step={1}
                                        value={sliderValue}
                                        valueLabelFormat={handleSliderValueLabelFormat}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </>
                    }
                </>
            }
        </>
    );
};

export default WrongCoordinatesVehiclesForm;