import Utils from '../utilities/utils';
import { toTitleCase } from '../utilities/view-utils';
import { DirectionVariantSimple, GtfsRoute } from './gtfsTypes';
import { IDirectionModel, IRouteModel } from './view-models-interfaces';

export class DirectionModel implements IDirectionModel {
    public readonly directionVariantId: string;
    public readonly distance: number;
    public readonly cardinalDirection: string;
    public readonly cardinalDirectionShort: string;
    public readonly fromStopName: string;
    public readonly toStopName: string;

    public selected = false;

    constructor(public readonly isMain: boolean, source: DirectionVariantSimple) {
        this.directionVariantId = source.directionVariantId;
        this.distance = Utils.kmToMiles(source.distanceKm);
        this.cardinalDirection = source.cardinalDirection;

        const cardinalDirections = this.cardinalDirection.toLowerCase().split('-');
        this.cardinalDirectionShort = cardinalDirections.map(dir => { return dir.slice(0, 1).toUpperCase(); }).join('');
        this.fromStopName = toTitleCase(source.fromStopName);
        this.toStopName = toTitleCase(source.toStopName);
    }
}

export class RouteModel implements IRouteModel {
    public readonly routeId: string;
    public readonly routeName: string;
    public readonly cssColor: string;
    public readonly directions: DirectionModel[];

    constructor(source: GtfsRoute) {
        this.routeId = source.routeId;
        this.routeName = source.routeName;
        this.cssColor = '#' + source.routeColor;
        this.directions = source.directionVariants?.map((d, i) => new DirectionModel(i === 0, d)) || [];
    }
}
