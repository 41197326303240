import moment from 'moment';
import { VehicleComparisonData, VehiclesCountChartData, VehiclesInServiceChartData, VehicleStatisticsData, VehicleStatisticsDispatchAction, VehicleStatisticsState } from '../../../types/vehicleStatisticsTypes';
import Utils from '../../../utilities/utils';

export const vehicleStatisticsDefaultValue: VehicleStatisticsState = {
    vehicleStatisticsItems: [],
    vehiclesAggregatedItems: [],
    vehiclesCountChartItems: [],
    serviceAvgItems: [],
    vehicleServiceItems: [],
    comparisonValues: undefined,
};

export default function vehicleStatisticsReducer(vehicleStatistics: VehicleStatisticsState, action: VehicleStatisticsDispatchAction): VehicleStatisticsState {
    switch (action.type) {
        case 'SET_STATISTICS': {
            const { items } = action;
            if (!items || items.length === 0)
                return vehicleStatisticsDefaultValue;
            const vehiclesAggregatedItems = getVehiclesAggregatedItems(items);
            const firstVehicle = vehiclesAggregatedItems[0]?.vehicleId;
            return {
                vehicleStatisticsItems: items,
                vehiclesAggregatedItems: vehiclesAggregatedItems,
                vehiclesCountChartItems: getVehiclesCountChartItems(items),
                serviceAvgItems: getServiceAvgItems(items),
                vehicleServiceItems: getServiceItemsForVehicle(firstVehicle, items),
                comparisonValues: getComparisonValues(firstVehicle, items),
                selectedVehicleId: firstVehicle,
            };
        }
        case 'SET_SELECTED_VEHICLE': {
            const { selectedVehicleId } = action;
            const { vehicleStatisticsItems: items } = vehicleStatistics;
            if (!selectedVehicleId || items.length === 0)
                return {
                    ...vehicleStatistics,
                    vehicleServiceItems: [],
                    comparisonValues: undefined,
                    selectedVehicleId: undefined,
                };
            return {
                ...vehicleStatistics,
                vehicleServiceItems: getServiceItemsForVehicle(selectedVehicleId, items),
                comparisonValues: getComparisonValues(selectedVehicleId, items),
                selectedVehicleId,
            };
        }
        default:
            return vehicleStatisticsDefaultValue;
    }
}

function getVehiclesAggregatedItems(items: VehicleStatisticsData[]): VehicleStatisticsData[] {
    if (items.length === 0)
        return [];
    const result: VehicleStatisticsData[] = Object.entries(Utils.groupBy(items, vehicles => vehicles.vehicleId))
        .map(([vehicleId, summary]) => (
            {
                agency: summary[0].agency,
                date: summary[0].date,
                vehicleId,
                hadTrips: summary.some(s => s.hadTrips),
                tripsCount: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.tripsCount + sum, 0),
                serviceTimeIndicators: {
                    notReportingSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.notReportingSec + sum, 0),
                    moveSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.moveSec + sum, 0),
                    stayOnStopSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnStopSec + sum, 0),
                    stayOnRoadSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnRoadSec + sum, 0),
                    travelKm: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.travelKm + sum, 0),
                },
                nonServiceTimeIndicators: {
                    notReportingSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.notReportingSec + sum, 0),
                    moveSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.moveSec + sum, 0),
                    inGarageSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.inGarageSec + sum, 0),
                    staySec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.staySec + sum, 0),
                    travelKm: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.travelKm + sum, 0),
                },
            }))
        .sort((a, b) => a.vehicleId as unknown as number - (b.vehicleId as unknown as number));
    return result;
}

function getVehiclesCountChartItems(items: VehicleStatisticsData[]): VehiclesCountChartData[] {
    if (items.length === 0)
        return [];
    const result: VehiclesCountChartData[] = Object.entries(Utils.groupBy(items, vehicles => moment(vehicles.date).format('YYYY-MM-DD')))
        .map(([serviceDate, vehicles]) => (
            {
                serviceDate,
                withTrips: vehicles.filter(v => v.hadTrips).length,
                withoutTrips: vehicles.filter(v => !v.hadTrips).length,
            }));
    return result;
}

function getServiceAvgItems(items: VehicleStatisticsData[]): VehiclesInServiceChartData[] {
    if (items.length === 0)
        return [];
    const result: VehiclesInServiceChartData[] = Object.entries(Utils.groupBy(items, vehicles => moment(vehicles.date).format('YYYY-MM-DD')))
        .map(([serviceDate, summary]) => (
            {
                serviceDate,
                tripNotReportingSec: summary.length !== 0 ? summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.notReportingSec + sum, 0) / summary.length : 0,
                tripMoveSec: summary.length !== 0 ? summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.moveSec + sum, 0) / summary.length : 0,
                tripOnStopStaySec: summary.length !== 0 ? summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnStopSec + sum, 0) / summary.length : 0,
                tripOnRoadStaySec: summary.length !== 0 ? summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnRoadSec + sum, 0) / summary.length : 0,
            }));
    return result;
}

function getServiceItemsForVehicle(vehicleId: string | undefined, items: VehicleStatisticsData[]): VehiclesInServiceChartData[] {
    if (items.length === 0 || !vehicleId)
        return [];
    const itemsForVehicle = items.filter(i => i.vehicleId === vehicleId);
    if (itemsForVehicle.length === 0)
        return [];
    const result: VehiclesInServiceChartData[] = Object.entries(Utils.groupBy(itemsForVehicle, vehicles => moment(vehicles.date).format('YYYY-MM-DD')))
        .map(([serviceDate, summary]) => (
            {
                serviceDate,
                tripNotReportingSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.notReportingSec + sum, 0),
                tripMoveSec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.moveSec + sum, 0),
                tripOnStopStaySec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnStopSec + sum, 0),
                tripOnRoadStaySec: summary.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnRoadSec + sum, 0),
            }));
    return result;
}

function getComparisonValues(vehicleId: string | undefined, items: VehicleStatisticsData[]): VehicleComparisonData | undefined {
    if (items.length === 0 || !vehicleId)
        return undefined;
    const itemsForVehicle = items.filter(i => i.vehicleId === vehicleId);
    if (itemsForVehicle.length === 0)
        return undefined;
    const vehiclesCount = Object.entries(Utils.groupBy(items, vehicles => vehicles.vehicleId)).length;
    const result: VehicleComparisonData = {
        serviceTimeIndicators: {
            stayOnRoad: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnRoadSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnRoadSec + sum, 0) / vehiclesCount,
            },
            stayOnStop: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnStopSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.stayOnStopSec + sum, 0) / vehiclesCount,
            },
            move: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.moveSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.moveSec + sum, 0) / vehiclesCount,
            },
            notReporting: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.notReportingSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.serviceTimeIndicators.notReportingSec + sum, 0) / vehiclesCount,
            },
        },
        nonServiceTimeIndicators: {
            stay: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.staySec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.staySec + sum, 0) / vehiclesCount,
            },
            move: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.moveSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.moveSec + sum, 0) / vehiclesCount,
            },
            inGarage: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.inGarageSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.inGarageSec + sum, 0) / vehiclesCount,
            },
            notReporting: {
                vehicle: itemsForVehicle.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.notReportingSec + sum, 0),
                average: items.reduce((sum: number, statistic: VehicleStatisticsData) => statistic.nonServiceTimeIndicators.notReportingSec + sum, 0) / vehiclesCount,
            },
        },
    };
    return result;
}