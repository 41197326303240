import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { TripDetailsPerformance } from '../../../types/runningTimeAnalyticsTypes';
import { TableSortProperties } from '../../../types/types';
import Utils from '../../../utilities/utils';

const styles = {
    table: {
        display: 'block',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface TableProps {
    tableDataState: TableSortProperties<TripDetailsPerformance>;
    columnSortHandler: (column: string) => void;
}

const TripsDetailsTable: React.FC<TableProps> = ({ tableDataState, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'serviceDate' ? direction : undefined}
                            onClick={() => columnSortHandler('serviceDate')}>
                            Date
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'vehicleId' ? direction : undefined}
                            onClick={() => columnSortHandler('vehicleId')}>
                            Bus ID
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'tripActualStart' ? direction : undefined}
                            onClick={() => columnSortHandler('tripActualStart')}>
                            Trip Actual Start
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'tripActualFinish' ? direction : undefined}
                            onClick={() => columnSortHandler('tripActualFinish')}>
                            Trip Actual Finish
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'startDelaySec' ? direction : undefined}
                            onClick={() => columnSortHandler('startDelaySec')}>
                            Start Delay (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'totalStopSec' ? direction : undefined}
                            onClick={() => columnSortHandler('totalStopSec')}>
                            Total Stop (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'totalInMotionSec' ? direction : undefined}
                            onClick={() => columnSortHandler('totalInMotionSec')}>
                            Total in Motion (min)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'performance' ? direction : undefined}
                            onClick={() => columnSortHandler('performance')}>
                            Performance (%)
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((tripPerformance, index) => (
                        <TripsDetailsTableRow key={index} tripInfo={tripPerformance} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TripsDetailsTable;

const TripsDetailsTableRow: React.FC<{ tripInfo: TripDetailsPerformance }> = ({ tripInfo }) => {
    return (
        <Table.Row>
            <Table.Cell>{moment(tripInfo.serviceDate).format('YYYY-MM-DD')}</Table.Cell>
            <Table.Cell>
                <Link to={`/vehicle-history/bus-number-position/${moment(tripInfo.serviceDate).format('YYYY-MM-DD')}/${tripInfo.vehicleId}/${tripInfo.tripId}`}>{tripInfo.vehicleId}</Link>
            </Table.Cell>
            <Table.Cell>{moment(tripInfo.tripActualStart).format('h:mm A')}</Table.Cell>
            <Table.Cell>{moment(tripInfo.tripActualFinish).format('h:mm A')}</Table.Cell>
            <Table.Cell>{tripInfo.startDelaySec ? Utils.convertSecondsToMinutes(tripInfo.startDelaySec) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.totalStopSec ? Utils.convertSecondsToMinutes(tripInfo.totalStopSec) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.totalInMotionSec ? Utils.convertSecondsToMinutes(tripInfo.totalInMotionSec) : ''}</Table.Cell>
            <Table.Cell>{tripInfo.performance && Boolean(tripInfo.startDetected) && Boolean(tripInfo.finishDetected) ? Utils.roundNumber(tripInfo.performance, 0) : ''}</Table.Cell>
        </Table.Row>
    );
};