import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TableSortProperties } from '../../../../types/types';
import { StopDelayMetric } from './StopDelaysForm';
import StopTableRow from './StopTableRow';

const styles = {
    table: {
        display: 'block',
        maxHeight: '677px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    tableDataState: TableSortProperties<StopDelayMetric>;
    selectedRow: StopDelayMetric | undefined;
    selectedRowHandler: (selectedRow: StopDelayMetric) => void;
    columnSortHandler: (column: string) => void;
}

const StopTable: React.FC<Props> = ({ tableDataState, selectedRow, selectedRowHandler, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;

    return (
        <div>   
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'stopId' ? direction : undefined}
                            onClick={() => columnSortHandler('stopId')}>
                            ID
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'stopName' ? direction : undefined}
                            onClick={() => columnSortHandler('stopName')}>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'totalArrivals' ? direction : undefined}
                            onClick={() => columnSortHandler('totalArrivals')}>
                            Total<br />Arrivals
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'meanDelay' ? direction : undefined}
                            onClick={() => columnSortHandler('meanDelay')}>
                            Avg Delay
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'earlyPercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('earlyPercentage')}>
                            Early
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'onTimePercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('onTimePercentage')}>
                            On Time
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'latePercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('latePercentage')}>
                            Late
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'veryLatePercentage' ? direction : undefined}
                            onClick={() => columnSortHandler('veryLatePercentage')}>
                            Very<br />Late
                        </Table.HeaderCell>
                        
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <StopTableRow key={index} row={row} selectedRow={selectedRow} selectedRowHandler={selectedRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default StopTable;