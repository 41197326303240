import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment-timezone';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DropdownItemProps, DropdownProps, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../actions/actionTypes';
import { deleteServiceAlert, getAlertsForPeriod, saveServiceAlert } from '../../actions/alertsActions';
import { getFullRouteInfo, getRoutes, getTripsForDateAndRoute } from '../../actions/gtfsStaticActions';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { GtfsRoute } from '../../types/gtfsTypes';
import { CalendarEvent, CalendarSlotInfo, ServiceAlert } from '../../types/serviceAlertsTypes';
import { DropDownStateType } from '../../types/types';
import AlertsModalForm from './AlertsModalForm';

interface Props {
    agency: AgencyStateType | undefined;
}

interface RoutesDropDownStateType extends DropDownStateType {
    routes: GtfsRoute[],
}

const effectOptions: DropdownItemProps[] =
    [{ value: 'NoService', text: 'No service' },
    { value: 'ReducedService', text: 'Reduced service' },
    { value: 'SignificantDelays', text: 'Significant delays' },
    { value: 'Detour', text: 'Detour' },
    { value: 'AdditionalService', text: 'Additional service' },
    { value: 'ModifiedService', text: 'Modified service' },
    { value: 'OtherEffect', text: 'Other effect' },
    { value: 'UnknownEffect', text: 'Unknown effect' },
    { value: 'StopMoved', text: 'Stop moved' },
    { value: 'NoEffect', text: 'No effect' }];

const causesOptions: DropdownItemProps[] =
    [{ value: 'UnknownCause', text: 'Unknown cause' },
    { value: 'OtherCause', text: 'Other cause (not represented by any of these options)' },
    { value: 'TechnicalProblem', text: 'Technical problem' },
    { value: 'Strike', text: 'Strike' },
    { value: 'Demonstration', text: 'Demonstration' },
    { value: 'Accident', text: 'Accident' },
    { value: 'Holiday', text: 'Holiday' },
    { value: 'Weather', text: 'Weather' },
    { value: 'Maintenance', text: 'Maintenance' },
    { value: 'Construction', text: 'Construction' },
    { value: 'PoliceActivity', text: 'Police activity' },
    { value: 'MedicalEmergency', text: 'Medical emergency' }];

const severityLevelsOptions: DropdownItemProps[] =
    [{ value: 'UnknownSeverity ', text: 'Unknown severity' },
    { value: 'Info ', text: 'Info' },
    { value: 'Warning ', text: 'Warning' },
    { value: 'Severe ', text: 'Severe' }];

const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};
const routesDropDownState: RoutesDropDownStateType = {
    options: [],
    selectedValue: '',
    routes: [],
};

const localizer = momentLocalizer(moment);
const dt = moment(new Date().setMinutes(0, 0, 0));
const defaultTimeZone = 'Etc/UTC';

const AlertsForm: React.FC<Props> = ({ agency }) => {
    const [eventsState, setEventsState] = useState<CalendarEvent[]>([]);
    const [alertsState, setAlertsState] = useState<ServiceAlert[]>([]);
    const [modalOpenState, setModalOpenState] = useState<boolean>(false);
    const [selectedSlotInfoState, setSelectedSlotInfoState] = useState<CalendarSlotInfo>();
    const [routesState, setRoutesState] = useState(routesDropDownState);
    const [tripsState, setTripsState] = useState(initialDropDownState);
    const [stopsState, setStopsState] = useState(initialDropDownState);
    const [alertHeaderState, setAlertHeaderState] = useState('');
    const [alertDescriptionState, setAlertDescriptionState] = useState('');
    const [alertUrlState, setAlertUrlState] = useState('');
    const [severityLevelState, setSeverityLevelState] = useState<DropDownStateType>({
        options: severityLevelsOptions,
        selectedValue: '',
    });
    const [causesState, setCauseState] = useState<DropDownStateType>({
        options: causesOptions,
        selectedValue: '',
    });
    const [effectsState, setEffectsState] = useState<DropDownStateType>({
        options: effectOptions,
        selectedValue: '',
    });
    const [startDateState, setStartDateState] = useState(moment(dt).format('YYYY-MM-DD'));
    const [endDateState, setEndDateState] = useState(moment(dt).format('YYYY-MM-DD'));
    const [startTimeState, setStartTimeState] = useState(moment(dt).format('h:mm A'));
    const [endTimeState, setEndTimeState] = useState(moment(dt).format('h:mm A'));
    const [isModalFormValidState, setIsModalFormValidState] = useState(true);
    const [validationTextState, setValidationTextState] = useState('');
    const [formBlockingState, setFormBlockingState] = useState(false);

    const selectSlotHandler = async (slotInfo: CalendarSlotInfo) => {
        setStartDateState(moment(slotInfo.start).format('YYYY-MM-DD'));
        setStartTimeState(moment(slotInfo.start).format('h:mm A'));
        setEndDateState(moment(slotInfo.end).format('YYYY-MM-DD'));
        setEndTimeState(moment(slotInfo.end).add(1, 'hours').format('h:mm A'));
        await updateRoutesAndRelatedRecords(slotInfo.start);
        setModalOpenState(true);
        setSelectedSlotInfoState(slotInfo);
    };
    const submitAlert = async () => {
        if (agency === undefined) return;
        if (!alertHeaderState || !severityLevelState.selectedValue || !causesState.selectedValue || !effectsState.selectedValue) {
            setIsModalFormValidState(false);
            setValidationTextState('Please fill out all required fields');
            return;
        }
        const periodStartMoment = moment.tz(startDateState + ' ' + startTimeState, 'YYYY-MM-DD h:mm A', agency.timeZoneName || defaultTimeZone);
        const periodEndMoment = moment.tz(endDateState + ' ' + endTimeState, 'YYYY-MM-DD h:mm A', agency.timeZoneName || defaultTimeZone);
        if (periodEndMoment.isSameOrBefore(periodStartMoment)) {
            setIsModalFormValidState(false);
            setValidationTextState('Period End should be greater than Period Start');
            return;
        }
        if (selectedSlotInfoState && agency && agency.id) {
            const routeName = routesState.routes.find(r => r.routeId === routesState.selectedValue)?.routeName || '';
            const alert: ServiceAlert = {
                alertId: selectedSlotInfoState.id,
                header: alertHeaderState,
                description: alertDescriptionState,
                url: alertUrlState,
                agencyId: agency.id,
                periodStart: periodStartMoment.format(),
                periodEnd: periodEndMoment.format(),
                routeCode: routeName,
                internalTripId: tripsState.selectedValue,
                stopCode: stopsState.selectedValue,
                severityLevel: severityLevelState.selectedValue,
                cause: causesState.selectedValue,
                effect: effectsState.selectedValue,
            };
            setFormBlockingState(true);
            try {
                await saveServiceAlert(alert);
            } finally {
                setFormBlockingState(false);
            }
            updateCalendarForMonth();
        }
        setModalOpenState(false);
    };
    const cancelAlert = () => {
        setModalOpenState(false);
    };
    const deleteAlert = async () => {
        if (!window.confirm('Are you sure you want to delete this alert?'))
            return;
        if (selectedSlotInfoState && selectedSlotInfoState.id && agency && agency.id) {
            setFormBlockingState(true);
            try {
                await deleteServiceAlert(selectedSlotInfoState.id);
            } finally {
                setFormBlockingState(false);
            }
            updateCalendarForMonth();
        }
        setModalOpenState(false);
    };
    const closeAlert = () => {
        setRoutesState(routesDropDownState);
        setTripsState(initialDropDownState);
        setStopsState(initialDropDownState);
        setAlertHeaderState('');
        setAlertDescriptionState('');
        setAlertUrlState('');
        setSeverityLevelState(prevState => ({
            ...prevState,
            selectedValue: '',
        }));
        setCauseState(prevState => ({
            ...prevState,
            selectedValue: '',
        }));
        setEffectsState(prevState => ({
            ...prevState,
            selectedValue: '',
        }));
        setStartDateState(moment(dt).format('YYYY-MM-DD'));
        setEndDateState(moment(dt).format('YYYY-MM-DD'));
        setStartTimeState(moment(dt).format('h:mm A'));
        setEndTimeState(moment(dt).format('h:mm A'));
        setSelectedSlotInfoState(undefined);
        setIsModalFormValidState(true);
        setValidationTextState('');
    };
    const calendarRangeChangeHandler = async (range: Date[] | { start: string | Date; end: string | Date }, _view: View | undefined) => {
        let startDate: string;
        let endDate: string;
        if (Array.isArray(range)) {
            startDate = moment(range[0]).format('YYYY-MM-DD');
            endDate = moment(range[range.length - 1]).add(1, 'days').format('YYYY-MM-DD');
        } else {
            startDate = moment(range.start).format('YYYY-MM-DD');
            endDate = moment(range.end).format('YYYY-MM-DD');
        }
        updateEventsAndAlerts(startDate, endDate);
    };
    const calendarDoubleClickEventHandler = async (event: CalendarEvent, _e: React.SyntheticEvent<HTMLElement>) => {
        const alert = alertsState.find(a => moment(a.periodStart).isSame(event.start) && moment(a.periodEnd).isSame(event.end));
        if (alert && agency) {
            await updateRoutesAndRelatedRecords(moment(event.start).format('YYYY-MM-DD'), alert.routeCode, alert.internalTripId || '', alert.stopCode || '');
            setCauseState(prevState => ({
                ...prevState,
                selectedValue: alert.cause || '',
            }));
            setEffectsState(prevState => ({
                ...prevState,
                selectedValue: alert.effect || '',
            }));
            setSeverityLevelState(prevState => ({
                ...prevState,
                selectedValue: alert.severityLevel || '',
            }));
            setAlertHeaderState(alert.header || '');
            setAlertDescriptionState(alert.description || '');
            setAlertUrlState(alert.url || '');
            setStartDateState(moment(alert.periodStart).format('YYYY-MM-DD'));
            setStartTimeState(moment(alert.periodStart).format('h:mm A'));
            setEndDateState(moment(alert.periodEnd).format('YYYY-MM-DD'));
            setEndTimeState(moment(alert.periodEnd).format('h:mm A'));

        }
        setModalOpenState(true);
        setSelectedSlotInfoState(prevState => ({
            ...prevState,
            start: event.start,
            end: event.end,
            slots: [moment(event.start).toDate(), moment(event.end).toDate()],
            action: 'doubleClick',
            id: alert?.alertId,
        }));
    };
    const handleStartDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        setStartDateState(value);
    };
    const handleStartTimeChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        setStartTimeState(value);
    };
    const handleEndDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        setEndDateState(value);
    };
    const handleEndTimeChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        setEndTimeState(value);
    };
    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const routeId = value as string;
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeId,
        }));
        await updateTripsAndStops(routeId, selectedSlotInfoState?.start || new Date());
    };

    const updateTripsAndStops = async (routeId: string, tripDate: string | Date, selectedTripValue = '', selectedStopValue = '') => {
        if (agency === undefined || !tripDate || !routeId) {
            setTripsState(initialDropDownState);
            setStopsState(initialDropDownState);
            return;
        }
        const [routeInfo, trips] = await Promise.all([getFullRouteInfo(routeId), getTripsForDateAndRoute(
            {
                agencyId: agency.id,
                tripDate: moment(tripDate).format('YYYY-MM-DD'),
                routeId: routeId,
            })]);
        setTripsState({
            options: trips.map(t => { return { value: t.internalTripId, text: t.shortTripId }; }),
            selectedValue: selectedTripValue,
        });
        const stops = routeInfo?.directions.flatMap(d => d.directionVariants.flatMap(v => v.stops)) || [];
        setStopsState({
            options: stops.map(s => { return { value: s.stop.stopId, text: `${s.stop.stopCode} - ${s.stop.stopName}` }; }),
            selectedValue: selectedStopValue,
        });
    };

    const handleTripChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setTripsState(prevState => ({
            ...prevState,
            selectedValue: value as string,
        }));
    };

    const handleStopChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setStopsState(prevState => ({
            ...prevState,
            selectedValue: value as string,
        }));
    };

    const updateRoutesAndRelatedRecords = async (date: Date | string, selectedRouteName = '', internalTripId = '', stopCode = '') => {
        if (agency === undefined) return;
        const dateString = moment(date).format('YYYY-MM-DD');
        setFormBlockingState(true);
        try {
            const routes = await getRoutes(agency.id, dateString);
            if (routes.length === 0) {
                setRoutesState(routesDropDownState);
                return;
            }
            const routeId = selectedRouteName ? routes.find(r => r.routeName === selectedRouteName)?.routeId || '' : '';
            setRoutesState({
                options: routes.map(({ routeId, routeName }) => { return { value: routeId, text: routeName }; }),
                selectedValue: routeId,
                routes: routes,
            });
            if (routeId) {
                await updateTripsAndStops(routeId, dateString, internalTripId, stopCode);
            }
        } catch {
            setRoutesState(routesDropDownState);
        } finally {
            setFormBlockingState(false);
        }
    };

    const handleSeverityLevelChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setSeverityLevelState(prevState => ({
            ...prevState,
            selectedValue: value as string,
        }));
    };
    const handleCausesChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setCauseState(prevState => ({
            ...prevState,
            selectedValue: value as string,
        }));
    };
    const handleEffectsChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setEffectsState(prevState => ({
            ...prevState,
            selectedValue: value as string,
        }));
    };

    const updateEventsAndAlerts = async (startDate: string, endDate: string) => {
        let events: CalendarEvent[] = [];
        if (agency && agency.id && startDate && endDate) {
            const alerts = await getAlertsForPeriod(agency.id, startDate, endDate);
            const localAlerts = alerts.map(a => {
                return {
                    ...a, periodStart: moment.tz(a.periodStart, agency.timeZoneName || defaultTimeZone).format('YYYY-MM-DD h:mm A'),
                    periodEnd: moment.tz(a.periodEnd, agency.timeZoneName || defaultTimeZone).format('YYYY-MM-DD h:mm A'),
                };
            });
            setAlertsState(localAlerts);
            events = localAlerts.map(a => { return { title: a.header || '', start: moment(a.periodStart).toDate(), end: moment(a.periodEnd).toDate() }; });
        }
        setEventsState(events);
    };

    const updateCalendarForMonth = async () => {
        const currentDate = new Date();
        const startDate = moment(currentDate).startOf('month').startOf('week').format('YYYY-MM-DD');
        const endDate = moment(currentDate).endOf('month').endOf('week').format('YYYY-MM-DD');
        updateEventsAndAlerts(startDate, endDate);
    };

    useEffect(() => {
        if (!modalOpenState)
            closeAlert();
    }, [modalOpenState]);

    useEffect(() => {
        updateCalendarForMonth();
    }, [agency]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Service Alerts
                </Header>
                <div>
                    <AlertsModalForm
                        modalOpen={modalOpenState}
                        onSubmit={submitAlert}
                        onCancel={cancelAlert}
                        onDelete={deleteAlert}
                        onClose={closeAlert}
                        startDate={startDateState}
                        startTime={startTimeState}
                        endDate={endDateState}
                        endTime={endTimeState}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                        routesData={{ state: routesState, changeHandler: handleRouteChange }}
                        tripsData={{ state: tripsState, changeHandler: handleTripChange }}
                        stopsData={{ state: stopsState, changeHandler: handleStopChange }}
                        severityLevelData={{ state: severityLevelState, changeHandler: handleSeverityLevelChange }}
                        causesData={{ state: causesState, changeHandler: handleCausesChange }}
                        effectsData={{ state: effectsState, changeHandler: handleEffectsChange }}
                        alertHeader={alertHeaderState}
                        setAlertHeader={setAlertHeaderState}
                        alertDescription={alertDescriptionState}
                        setAlertDescription={setAlertDescriptionState}
                        alertUrl={alertUrlState}
                        setAlertUrl={setAlertUrlState}
                        isCreate={selectedSlotInfoState?.action !== 'doubleClick'}
                        isModalFormValid={isModalFormValidState}
                        validationText={validationTextState}
                    />
                    <Calendar
                        selectable
                        localizer={localizer}
                        events={eventsState}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectSlot={selectSlotHandler}
                        onRangeChange={calendarRangeChangeHandler}
                        onDoubleClickEvent={calendarDoubleClickEventHandler}
                        style={{ minHeight: 700 }}
                    />
                </div>
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(AlertsForm);