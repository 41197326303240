import api from '../api/otpApi';
import { BunchingStatisticsArrivals, BusBunchingOccuracy } from '../types/busBunchingTypes';
import { DelaysAnalysisCompareData } from '../types/delaysAnalysisTypes';
import { RtDelaysNow, RtDelaysNowInterval } from '../types/delaysNowTypes';
import { DelayStatisticsData } from '../types/delayStatisticsTypes';
import { ArrivalForStop, StopDelayData } from '../types/delaysTypes';
import { DaysStatiscsRequest, StopArrivalHeadway, StopHeadwaysRequest } from '../types/headwaysTypes';
import { DashboardRealtimeData, DelaysAnalysisTableData, TripDelayHistory } from '../types/otpTypes';
import { KpiData } from '../types/trendsTypes';

export async function getAgencyStats(agencyId: string) {
    return await api.getAgencyStats(agencyId);
}

export async function getDashboardRealTimeData(agencyId: string): Promise<DashboardRealtimeData> {
    const result = await api.getDashboardRealTimeData(agencyId);
    return result.data;
}
export async function getStopDelays(agencyId: string, startDate: string, endDate: string) {
    const result = await api.getStopDelays(agencyId, startDate, endDate);
    return result.data;
}
export async function getStopDelaysData(agencyId: string, startDate: string, endDate: string): Promise<StopDelayData[]> {
    const result = await api.getStopDelaysData(agencyId, startDate, endDate);
    return result.data || [];
}
export async function getArrivalsForStop(agencyId: string, startDate: string, endDate: string, stopId: string): Promise<ArrivalForStop[]> {
    const result = await api.getArrivalsForStop(agencyId, startDate, endDate, stopId);
    return result.data || [];
}
export async function getTripDelayHistory(agencyId: string, tripInternalId: string, vehicleId: string): Promise<TripDelayHistory[]> {
    const result = await api.getTripDelayHistory(agencyId, tripInternalId, vehicleId);
    return result.data || [];
}
export async function getRtDelaysNowReportData(agencyId: string, interval: RtDelaysNowInterval): Promise<RtDelaysNow[]> {
    const result = await api.getRtDelaysNowReportData(agencyId, interval);
    return result.data;
}
export async function getBunchingReportForDate(agencyId: string, reportDate: string): Promise<BusBunchingOccuracy> {
    const result = await api.getBunchingReport(agencyId, reportDate, reportDate);
    return result.data;
}
export async function getBunchingReportForPeriod(agencyId: string, fromDate: string, toDate: string): Promise<BusBunchingOccuracy> {
    const result = await api.getBunchingReport(agencyId, fromDate, toDate);
    return result.data;
}
export async function getBunchingArrivals(agencyId: string, fromDate: string, toDate: string): Promise<BunchingStatisticsArrivals[]> {
    const result = await api.getBunchingArrivals(agencyId, fromDate, toDate);
    return result.data || [];
}
export async function getBusBunchingArrivalsChart(agencyId: string, reportDate: string, tripId1: string, tripId2: string, vehicleId1: string, vehicleId2: string) {
    const result = await api.getBusBunchingArrivalsChart(agencyId, reportDate, tripId1, tripId2, vehicleId1, vehicleId2);
    return result.data || [];
}
export async function getBusBunchingPositions(agencyId: string, reportDate: string, tripId: string, vehicleId: string) {
    const result = await api.getBusBunchingPositions(agencyId, reportDate, tripId, vehicleId);
    return result.data || [];
}

export async function getDaysStatistics(agencyId: string, fromDate: string, toDate: string, routeName: string, cardinalDirection: string) {
    const request: DaysStatiscsRequest = {
        agencyId,
        headwayCloseRule: 5,
        headwayDistantRule: 15,
        fromDate,
        toDate,
        routeName,
        cardinalDirection,
    };
    const result = await api.getDaysStatistics(request);
    return result.data || [];
}

export async function getStopHeadwaysTrips(agencyId: string, reportDate: string, routeName: string, directionVariantInternalId: string) {
    const result = await api.getStopHeadwaysTrips(agencyId, reportDate, routeName, directionVariantInternalId);
    return result.data || [];
}

export async function getStopHeadways(request: StopHeadwaysRequest): Promise<StopArrivalHeadway[]> {
    const result = await api.getStopHeadways(request);
    return result.data || [];
}

export async function getTwelveHoursStatus(agencyId: string) {
    const result = await api.getTwelveHoursStatus(agencyId);
    return result.data || [];
}
export async function getKpiData(agencyId: string): Promise<KpiData[]> {
    const result = await api.getKpiData(agencyId);
    return result.data || [];
}
export async function getDelaysAnalysisTableData(agencyId: string, reportDate: string): Promise<DelaysAnalysisTableData[]> {
    const result = await api.getDelaysAnalysisTableData(agencyId, reportDate);
    return result.data || [];
}
export async function getDelaysAnalysisCompareData(agencyId: string, reportDate: string, tripId: string, vehicleId: string): Promise<DelaysAnalysisCompareData[]> {
    const result = await api.getDelaysAnalysisCompareData(agencyId, reportDate, tripId, vehicleId);
    return result.data || [];
}
export async function getDelayStatistics(agencyId: string, fromDate: string, toDate: string, routeName = ''): Promise<DelayStatisticsData> {
    const result = await api.getDelayStatistics(agencyId, fromDate, toDate, routeName);
    return result.data;
}