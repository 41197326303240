import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Icon } from 'semantic-ui-react';
import { IRouteVehicle } from './_interfaces';
import { GetDurationStringFromSeconds, toTitleCase } from './_utils';
import TimeInterval from './TimeInteval';

function getCssRotation(busHeading: number, routeCardinalDirection: string[], linear: boolean): React.CSSProperties {
    let heading: number = busHeading;
    if (linear) {
        if (routeCardinalDirection.indexOf('east') !== -1) {
            heading = 90;
        }
        else {
            heading = -90;
        }
    }
    return { transform: `rotate(${heading} + 'deg)` };
}

const emptyStyle: React.CSSProperties = {};

interface RouteVehiclePushpinProps {
    vehicle: IRouteVehicle,
    hasProblems: boolean
}

function RouteVehiclePushpin(props: RouteVehiclePushpinProps) {
    const { vehicle, hasProblems } = props;

    let classes = [
        'route-' + vehicle.route.id,
        'constiant-' + vehicle.route.constiant,
        'bus-pushpin',
    ];
    classes = classes.concat(vehicle.route.cardinalDirection);
    if (vehicle.scheduled) {
        classes.push('scheduled');
    }
    if (hasProblems) {
        classes.push('headway-problem');
    }

    const style1: React.CSSProperties = vehicle.isNextBus ? { backgroundColor: 'lightgrey' } : emptyStyle;
    const style2: React.CSSProperties = getCssRotation(vehicle.heading || 0, vehicle.route.cardinalDirection, false);
    const delayText = vehicle.delaySeconds <= 59
        ? `On time`
        : `Delay ${GetDurationStringFromSeconds(vehicle.delaySeconds)}`;
    return (
        <div
            className={classes.join(' ')}
            style={{ borderColor: vehicle.route.cssColor }}
        >
            <div className="bus-pinpoint"></div>
            <div className="bus-offset">
                <div className="bus-icon" style={{ backgroundColor: vehicle.route.cssColor }}></div>
                <div className="bus-name" style={style1}><strong>{vehicle.name}</strong><br/><em>{delayText}</em></div>
                <div className="bus-arrow" style={style2}></div>
            </div>
        </div>
    );
}

export function getRouteVehiclePushpinHtml(vehicle: IRouteVehicle, hasProblem: boolean)
{
    return ReactDOMServer.renderToStaticMarkup(
        <RouteVehiclePushpin
            vehicle={vehicle}
            hasProblems={hasProblem}
        />);
}

function NonTripVehiclePushpin(props: { vehicle: IRouteVehicle }) {
    const vehicle = props.vehicle;
    const classes = [
        'route-' + vehicle.route.id,
        'variant-' + vehicle.route.variant,
        'bus-pushpin',
    ];
    return (
        <div
            className={classes.join(' ')}
            style={{ borderColor: vehicle.route.cssColor }}
        >
            <div className="bus-pinpoint"></div>
            <div className="bus-name"><strong>{vehicle.name}</strong></div>
        </div>
    );
}

export function getNonTripVehiclePushpinHtml(vehicle: IRouteVehicle) {
    return ReactDOMServer.renderToStaticMarkup(
        <NonTripVehiclePushpin
            vehicle={vehicle}
        />);
}

interface HeadwayInfo {
    secondsReal: number;
    vehicleId: string;
    problem?: 'error' | 'warn' | null;
}

function HeadwayInfo(props?: { cardinalDirection: string[], name: 'Ahead' | 'Behind', data: HeadwayInfo}) {
    if (!props) return null;
    const classes = [...props.cardinalDirection, props.name];
    if (props.data.problem) {
        classes.push('headway-problem');
        classes.push('headway-problem-' + props.data.problem);
    }
    return (
        <dl className={classes.join(' ')}>
            <dt>{props.name}</dt>
            <dd><TimeInterval seconds={props.data.secondsReal}/><br />
                <Icon name="bus" />
                <b>{props.data.vehicleId}</b>
            </dd>
        </dl>
    );
}

interface BusInfoboxLargeInfo {
    routeId: string;
    cssColor: string;
    routeCardinalDirection: string[];
    scheduled: boolean;
    delay: number;
    headsign: string;
    lastStopName: string;
    start: Date;
    finish: Date;
    headway: {
        Ahead?: HeadwayInfo;
        Behind?: HeadwayInfo;
    };
}

function BusInfoboxLarge(props: { data: BusInfoboxLargeInfo }) {
    const data = props.data;
    const classes = [
        `route-${data.routeId}`,
        'bus-infobox', /* FIXME: class='bus-infobox' is bus-specific */
        ...data.routeCardinalDirection,
    ];
    if (data.scheduled) {
        classes.push('scheduled');
    }
    return (
        <div className={classes.join(' ')} style={{ borderColor: props.data.cssColor }}>
            <div className="bus-offset">
                <div className="bus-info">
                    <div className="endpoints">
                        <div className="bus-headsign">
                            <div style={{ backgroundColor: props.data.cssColor }}></div>
                            <div>{toTitleCase(props.data.headsign.replace(/-/g, ' - '))}</div>
                        </div>
                        <div className="bus-last-stop">
                            <div></div>
                            <div>{toTitleCase(props.data.lastStopName)}</div>
                        </div>
                    </div>
                    <div className="start-stop">
                        <dl><dt>Sch. Trip Start</dt><dd>{data.start.toLocaleTimeString('en-US').replace(/:\d{2}(?=\s)/g, '')}</dd></dl>
                        <dl><dt>Sch. Trip End</dt><dd>{data.finish.toLocaleTimeString('en-US').replace(/:\d{2}(?=\s)/g, '')}</dd></dl>
                    </div>
                    <div className="schedule-deviation">
                        {data.scheduled ? 'Displayed as scheduled' : (<TimeInterval seconds={data.delay} mark={true} />)}
                    </div>
                    <div className="headways">
                        {!(props.data.headway?.Ahead) ? null : <HeadwayInfo cardinalDirection={props.data.routeCardinalDirection} name="Ahead" data={props.data.headway.Ahead} />}
                        {!(props.data.headway?.Behind) ? null : <HeadwayInfo cardinalDirection={props.data.routeCardinalDirection} name="Behind" data={props.data.headway.Behind} />}
                        {/* {data.headway.Ahead ? data.getHeadwayHtml('Ahead') : ''}
                    {data.headway.Behind ? data.getHeadwayHtml('Behind') : ''} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function getBusInfoboxLargeHtml(data: BusInfoboxLargeInfo) {
    return ReactDOMServer.renderToStaticMarkup(<BusInfoboxLarge data={data} />);
}