import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { BphDirectionModel, NamedEntity } from './_models';

interface BusHistoryDropdownProps<T extends NamedEntity> {
    loading?: boolean;
    items: T[] | null;
    value: T | null;
    onChanged: (value: T | null) => void;
}

interface DropdownItem<T extends NamedEntity> {
    text: string;
    value: number;
    external: T;
}

export function RouteDropdown<T extends NamedEntity>(props: BusHistoryDropdownProps<T>) {
    const [items, setItems] = React.useState<DropdownItem<T>[]>([]);
    const [selected, setSelected] = React.useState<number | string>();

    React.useEffect(() => {
        const tmp = { index: -1 };
        const items = (props.items || []).map((r, i) => {
            if (r === props.value)
                tmp.index = i;
            return { text: r.displayName, value: i, external: r };
        });
        setItems(items);
        setSelected(tmp.index < 0 ? '' : tmp.index);
    }, [props.items, props.value]);

    const handleChanged = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        if (typeof (data.value) === 'number') {
            setSelected(data.value);
            props.onChanged(items[data.value as number].external);
        }
        else {
            setSelected('');
            props.onChanged(null);
        }
    };

    return (
        <Dropdown
            placeholder="Select route"
            loading={!!props.loading}
            fluid
            openOnFocus={false}
            selectOnBlur={false}
            selectOnNavigation={false}
            options={items}
            clearable
            icon={typeof (selected) === 'number' ? 'delete' : undefined}
            selection
            value={selected}
            onChange={handleChanged}
            search
        />);
}

export function DirectionsDropdown<T extends NamedEntity>(props: BusHistoryDropdownProps<T> & { selectedStopId?: string }) {
    const [items, setItems] = React.useState<DropdownItem<T>[]>([]);
    const [selected, setSelected] = React.useState<number | string>();

    React.useEffect(() => {
        const tmp = { index: -1 };
        const stopId = props.selectedStopId;
        const filtered = (props.items || []).filter(r => {
            const direction = r as any as BphDirectionModel;
            return !stopId || !direction.hasStops || direction.hasStop(stopId);
        });
        const items = filtered.map((r, i) => {
            if (r === props.value)
                tmp.index = i;
            return { text: r.displayName, value: i, external: r };
        });
        setItems(items);
        setSelected(tmp.index < 0 ? '' : tmp.index);
    }, [props.items, props.value, props.selectedStopId]);

    const handleChanged = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        if (typeof (data.value) === 'number') {
            setSelected(data.value);
            props.onChanged(items[data.value as number].external);
        }
        else {
            setSelected('');
            props.onChanged(null);
        }
    };

    return (
        <Dropdown
            placeholder="Direction"
            loading={!!props.loading}
            fluid
            search
            selection
            openOnFocus={false}
            selectOnBlur={false}
            selectOnNavigation={false}
            options={items || undefined}
            value={selected}
            onChange={handleChanged}
        />);
}

function toMessage(s: string | null | undefined) {
    if (!s) return s;
    const lines = s.trim().split('\n');
    if (lines.length < 2) return (s);
    return (<>
        {lines.map((s, i) => {
            if (i === 0)
                return (<b>{s}<br /></b>);
            else
                return (<>{s}<br /></>);
        })}
    </>);
}

const searchPrompt = 'Start typing name or ID of a stop';

export function StopsDropdown<T extends NamedEntity>(props: BusHistoryDropdownProps<T> & {
    noResultsMessage: string | undefined,
    onSearchChanged: (searchQuery: string) => void
}) {
    const [items, setItems] = React.useState<DropdownItem<T>[]>([]);
    const [selected, setSelected] = React.useState<number | string>();

    React.useEffect(() => {
        const tmp = { index: -1 };
        const items = (props.items || []).map((r, i) => {
            if (r === props.value)
                tmp.index = i;
            return { text: r.displayName, value: i, external: r };
        });
        setItems(items);
        setSelected(tmp.index < 0 ? '' : tmp.index);
    }, [props.items, props.value]);

    const handleChanged = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        if (typeof (data.value) === 'number') {
            setSelected(data.value);
            props.onChanged(items[data.value as number].external);
        }
        else {
            setSelected('');
            props.onChanged(null);
        }
    };

    return (
        <Dropdown
            className="stops-list"
            placeholder={searchPrompt}
            loading={!!props.loading}
            fluid
            openOnFocus={false}
            selectOnBlur={false}
            selectOnNavigation={false}
            options={items}
            clearable
            icon={typeof (selected) === 'number' ? 'delete' : undefined}
            selection
            value={selected}
            noResultsMessage={props.loading ? 'Searching...' : toMessage(props.noResultsMessage) || searchPrompt}
            onChange={handleChanged}
            search
            onSearchChange={(_e, d) => props.onSearchChanged(d.searchQuery)}
        />);
}
