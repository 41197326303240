import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WrongCoordinatesVehicle } from '../../../types/anomaliesTypes';

interface Props {
    row: WrongCoordinatesVehicle,
    selectedVehicleId: string | undefined,
    selectedRowHandler: (selectedVehicleId: string) => void;
}

const WrongCoordinatesVehiclesTableRow: React.FC<Props> = ({ row, selectedVehicleId, selectedRowHandler }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row.id);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedVehicleId !== undefined && selectedVehicleId === row.id}>
            <Table.Cell textAlign="left">
                {row.id}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.onShape}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShape}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShapePcnt}
            </Table.Cell>
        </Table.Row>
    );
};

export default WrongCoordinatesVehiclesTableRow;