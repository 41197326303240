import * as React from 'react';
import { CSVLink } from 'react-csv';
import { Table } from 'semantic-ui-react';
import { BusBunchingArrival } from '../../../../types/busBunchingTypes';
import BusArrivalsTableRow from './BusArrivalsTableRow';


const styles = {
    table: {
        display: 'block',
        maxHeight: '560px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

const headers = [
    { label: 'Vehicle 1', key: 'vehicleId1' },
    { label: 'Vehicle 2', key: 'vehicleId2' },
    { label: 'Trip 1', key: 'tripId1' },
    { label: 'Trip 2', key: 'tripId2' },
    { label: 'Stop Id', key: 'stopId' },
    { label: 'Stop Sequence', key: 'stopSequence' },
    { label: 'Distance (Miles)', key: 'traveledMiles' },
    { label: 'Vehicle 1 Arrival Time', key: 'actualArrivalTime1' },
    { label: 'Vehicle 2 Arrival Time', key: 'actualArrivalTime2' },
];


interface Props {
    rows: BusBunchingArrival[];
    
}

const BusBunchingTable: React.FC<Props> = ({ rows }) => {
    return (
        <div>
            <CSVLink
                data={rows}
                filename={'BusArrival.csv'}
                headers={headers}
                target="_blank"
            >
                Export to CSV
                            </CSVLink>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                     
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle 1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle 2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip 1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip 2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Stop Id</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Stop Sequence</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Traveled Miles</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle 1 Arrival Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle 2 Arrival Time</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <BusArrivalsTableRow key={index} row={row} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default BusBunchingTable;