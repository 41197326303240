import moment from 'moment';
import { ServiceTrip } from '../../types/servicePageTypes';

type ReportColumn<TRow> = {
    header: string;
    getter: (row: TRow) => string | number | null | undefined;
};

export function toReportRow<TRow>(reportDef: ReportColumn<TRow>[], row: TRow): object {
    const reportRow: { [column: string]: string | number | null | undefined } = {};
    for (const colDef of reportDef) {
        const v = colDef.getter(row);
        if (v !== undefined)
            reportRow[colDef.header] = v;
    }
    return reportRow;
}

export const UnmatchedTripsReportDef: ReportColumn<ServiceTrip>[] = [
    {
        header: 'Trip Id',
        getter: (e: ServiceTrip) => `${e.tripId}`,
    },
    {
        header: 'Scheduled Start',
        getter: (e: ServiceTrip) => moment.parseZone(e.scheduledStart).format('YYYY-MM-DD hh:mm A'),
    },
    {
        header: 'Scheduled End',
        getter: (e: ServiceTrip) => moment.parseZone(e.scheduledEnd).format('YYYY-MM-DD hh:mm A'),
    },
    {
        header: 'Route Name',
        getter: (e: ServiceTrip) => `${e.routeName}`,
    },
    {
        header: 'Direction Variant Name',
        getter: (e: ServiceTrip) => `${e.directionVariantName}`,
    },
];

export const UnmatchedTripsReportHeader = UnmatchedTripsReportDef.map(column => column.header);