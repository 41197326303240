import { _0px, _1px } from '../../../../utilities/constants';
import { StopDelayMetric } from './StopDelaysForm';

const _5px = 5 as 5 & Pixels;
const _10px = 10 as 10 & Pixels;
const _256px = 256 as 256 & Pixels;

export function drawStops(
    wholeData: StopDelayMetric[],
    canvas: HTMLCanvasElement,
    toPixel: (location: CoordinatesObject) => Point | null,
): DataUri | undefined {
    if (!wholeData) return undefined;

    const context = canvas.getContext('2d');
    if (context === null) {
        throw new Error('Cannot draw heatmap: failed to create drawing context.');
    }
    context.strokeStyle = 'white';
    const avgTotalArrivals = wholeData.map(d => d.totalArrivals).reduce((a, b) => a + b, 0) / wholeData.length;
    wholeData.sort((a, b) => a.metricValue - b.metricValue).forEach(stop => {
        const radius = Math.min(
            Math.round(stop.totalArrivals * _5px / avgTotalArrivals) as Pixels,
            _10px,
        );
        if (radius <= _0px) return;

        const point = toPixel(stop);
        if (!point)
            return;
        const [x, y] = point;

        context.fillStyle = getFillColor(stop.metricName, stop.metricValue);
        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI);
        context.closePath();
        context.fill();
        context.stroke();
    });

    return canvas.toDataURL();
}

function getFillColor(metricName: string, metricValue: number): string {
    if (metricName === 'MeanDelay') {
        if (metricValue < 0)
            return 'rgba(247, 252, 253, 0.5)';
        else if (metricValue < 1)
            return 'rgba(224, 236, 244, 0.5)';
        else if (metricValue < 2)
            return 'rgba(191, 211, 230, 0.5)';
        else if (metricValue < 4)
            return 'rgba(158, 188, 218, 0.5)';
        else if (metricValue < 6)
            return 'rgba(140, 150, 198, 0.5)';
        else if (metricValue < 10)
            return 'rgba(140, 107, 177, 0.5)';
        else if (metricValue < 15)
            return 'rgba(136, 65, 157, 0.5)';
        else if (metricValue < 20)
            return 'rgba(129, 15, 124, 0.5)';
        else
            return 'rgba(77, 0, 75, 0.5)';
    } else {
        if (metricValue < 11)
            return 'rgba(247, 252, 253, 0.5)';
        else if (metricValue < 22)
            return 'rgba(224, 236, 244, 0.5)';
        else if (metricValue < 33)
            return 'rgba(191, 211, 230, 0.5)';
        else if (metricValue < 44)
            return 'rgba(158, 188, 218, 0.5)';
        else if (metricValue < 55)
            return 'rgba(140, 150, 198, 0.5)';
        else if (metricValue < 66)
            return 'rgba(140, 107, 177, 0.5)';
        else if (metricValue < 77)
            return 'rgba(136, 65, 157, 0.5)';
        else if (metricValue < 88)
            return 'rgba(129, 15, 124, 0.5)';
        else
            return 'rgba(77, 0, 75, 0.5)';
    }
}

export function createColourGradient(
    canvas: HTMLCanvasElement,
): Uint8ClampedArray {
    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error('Could not create 2D context for drawing stop delays gradient.');
    }
    const { width, height } = canvas;
    canvas.width = _256px;
    canvas.height = _1px;

    const gradient = context.createLinearGradient(_0px, _0px, _256px, _0px);
    gradient.addColorStop(0.0, 'black');
    gradient.addColorStop(0.5, 'red');
    gradient.addColorStop(0.9, 'pink');
    gradient.addColorStop(1.0, 'white');

    context.fillStyle = gradient;
    context.fillRect(_0px, _0px, _256px, _1px);
    const result = context.getImageData(_0px, _0px, _256px, _1px).data;

    context.clearRect(_0px, _0px, _256px, _1px);
    canvas.width = width;
    canvas.height = height;

    return result;
}

export function setPixel(
    imageData: ImageData,
    x: Pixels, y: Pixels,
    r: Uint8Clamped, g: Uint8Clamped, b: Uint8Clamped, a: Uint8Clamped,
): void {
    const index = (x + y * imageData.width) * 4;
    imageData.data[index + 0] = r;
    imageData.data[index + 1] = g;
    imageData.data[index + 2] = b;
    imageData.data[index + 3] = a;
}