import { AgencyStateType } from '../actions/actionTypes';
import { ApiOpResult, OperationResult } from '../types/apiTypes';
import { DirectionByRoute, DirectionVariant, FullRouteInfo, GtfsDirectionVariantModel, GtfsRoute, GtfsStopTimeEntity, GtfsTripAndStops, GtfsTripEntity, ShapePoint, Trip, TripsRequest } from '../types/gtfsTypes';
import { AgencyInfo, OrgLocation } from '../types/types';
import api from './api';
import { buildUrlString, makeErrorResult, makeSuccessResult, SearchParams } from './helpers';

class GtfsStaticApi {
    static gtfsStaticRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/gtfsStatic/${url}`);
    static agenciesRequest = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`api/v1/agencies/${url}`);

    static async getAgencies() {
        const url = buildUrlString(null, 'getAgencies');
        return await this.gtfsStaticRequest<OperationResult<AgencyStateType[]>>(url);
    }
    static async getAgencyInfo(agencyId: string) {
        const url = buildUrlString(null, 'info', agencyId);
        return await this.gtfsStaticRequest<OperationResult<AgencyInfo>>(url);
    }
    static async getRoutes(agencyId: string, date?: string) {
        const queryParams: SearchParams = {
            agencyId: agencyId,
        };
        if (date) {
            queryParams.serviceDate = date;
        }
        const url = buildUrlString(queryParams, 'activeRoutes');
        return await this.gtfsStaticRequest<OperationResult<GtfsRoute[]>>(url);
    }
    static async getDirections(routeId: string) {
        const url = buildUrlString(null, 'directionsByRoute', routeId);
        return await this.gtfsStaticRequest<OperationResult<DirectionByRoute[]>>(url);
    }
    static async getFullRouteInfo(routeId: string) {
        const url = buildUrlString(null, 'fullRouteInfo', routeId);
        return await this.gtfsStaticRequest<OperationResult<FullRouteInfo>>(url);
    }
    static async getFullRouteInfoByRouteName(agencyId: string, routeName: string) {
        const url = buildUrlString(null, 'fullRouteInfoByRouteName', agencyId, routeName);
        return await this.gtfsStaticRequest<OperationResult<FullRouteInfo>>(url);
    }
    static async getTripsForDateAndRoute({ agencyId, tripDate, routeId }: TripsRequest) {
        const url = buildUrlString(null, 'tripsForDateAndRoute', agencyId, 'tripdates', tripDate, 'routes', routeId);
        return await this.gtfsStaticRequest<OperationResult<Trip[]>>(url);
    }
    static async getTripsForTimeAndDirection({ agencyId, tripDate, routeId, directionId }: TripsRequest) {
        const url = buildUrlString(null, 'tripsForTimeAndDirection', agencyId, 'triptimes', tripDate, 'routes', routeId, 'directions', directionId as string);
        return await this.gtfsStaticRequest<OperationResult<Trip[]>>(url);
    }
    static async getTripsForDateAndDirection({ agencyId, tripDate, routeId, directionId }: TripsRequest) {
        const url = buildUrlString(null, 'tripsForDateAndDirection', agencyId, 'tripdates', tripDate, 'routes', routeId, 'directions', directionId as string);
        return await this.gtfsStaticRequest<OperationResult<Trip[]>>(url);
    }
    static async getTripsForDateAndDirectionVariant({ agencyId, tripDate, routeId, directionVariantId }: TripsRequest) {
        const url = buildUrlString(null, 'tripsForDateAndDirectionVariant', agencyId, 'tripdates', tripDate, 'routes', routeId, 'directionVariants', directionVariantId as string);
        return await this.gtfsStaticRequest<OperationResult<Trip[]>>(url);
    }
    static async getShapeData(agencyId: string, serviceDate: string, shapeId: string) {
        const url = buildUrlString(null, 'shapeData', agencyId, 'shapes', shapeId, 'serviceDate', serviceDate);
        return await this.gtfsStaticRequest<OperationResult<ShapePoint[]>>(url);
    }
    static async getSparkTripsByDirectionVariantIntertnalId(agencyId: string, serviceDate: string, directionVariantInternalId: string) {
        const queryParams: SearchParams = {
            directionVariantInternalId,
        };
        const url = buildUrlString(queryParams, 'SparkTripsByDirectionVariantIntertnalId', agencyId, serviceDate);
        return await this.gtfsStaticRequest<OperationResult<GtfsTripEntity[]>>(url);
    }
    static async getGtfsStopTimesByStopAndShape(agencyId: string, serviceDate: string, shapeId: string, stopId: string) {
        const queryParams: SearchParams = {
            shapeId,
            stopId,
        };
        const url = buildUrlString(queryParams, 'GtfsStopTimesByStopAndShape', agencyId, serviceDate);
        return await this.gtfsStaticRequest<OperationResult<GtfsStopTimeEntity[]>>(url);
    }
    static async getGtfsTripsAndStops(agencyId: string, reportDate: string) {
        const url = buildUrlString(null, 'GtfsTripsAndStops', agencyId, reportDate);
        return await this.gtfsStaticRequest<OperationResult<GtfsTripAndStops[]>>(url);
    }
    static async getGtfsDirectionVariants(agencyId: string, startDate: string, endDate: string) {
        const url = buildUrlString(null, 'GtfsDirectionVariants', agencyId, startDate, endDate);
        return await this.gtfsStaticRequest<OperationResult<GtfsDirectionVariantModel[]>>(url);
    }
    static async getDirectionVariantsByRoute(routeId: string, time: string) {
        const queryParams = {
            routeId,
            time,
        };
        const url = buildUrlString(queryParams, 'directionVariantsByRoute');
        return await this.gtfsStaticRequest<OperationResult<DirectionVariant[]>>(url);
    }
    static async getDirectionVariantsForStop(agencyId: string, stopId: string, routeId: string | null = null, time: string | null = null) {
        const queryParams = {
            stopId,
            routeId,
            time,
        };
        const url = buildUrlString(queryParams, 'directionVariantsForStop', agencyId);
        return await this.gtfsStaticRequest<OperationResult<DirectionVariant[]>>(url);
    }
    static async getAgencyLocation(agencyId: string): Promise<ApiOpResult<CoordinatePair>> {
        const url = buildUrlString(null, agencyId, 'location');
        const oldApiResult = await this.agenciesRequest<OrgLocation | string>(url);
        if (oldApiResult === '')
            return makeErrorResult(400, 'Can not get agency location from server');

        const orgLocation = oldApiResult as OrgLocation;
        return makeSuccessResult([orgLocation.Latitude, orgLocation.Longitude]);
    }
}

export default GtfsStaticApi;
