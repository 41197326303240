import * as React from 'react';

const styles = {
    legendContainer: {
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'white',
        paddingLeft: '10px',
        marginTop: '30px',
        marginLeft: '10px',
        paddingRight: '20px',
    } as React.CSSProperties,
};

interface LegendRow {
    text: string;
    color: string;
}

const legendRows: LegendRow[] = [{
    color: 'rgb(37, 170, 225)',
    text: '> 30% faster than scheduled',
}, {
    color: 'rgb(0, 128, 0)',
    text: 'According to the schedule',
}, {
    color: 'rgb(245, 225, 7)',
    text: '30-50% slower than scheduled',
}, {
    color: 'rgb(255, 0, 0)',
    text: '>50% slower than scheduled',
}];

const SegmentTransitPerformanceLegend: React.FC = () => {
    return (
        <div className="avg-speed-legend" style={styles.legendContainer} >
            <ul>{legendRows.map((row, index) => (
                <li key={index}>
                    <div className="speed-key" style={{ backgroundColor: row.color }}></div>
                    <span>{row.text}</span>
                </li>
            ))}</ul>
        </div>
    );
};

export default SegmentTransitPerformanceLegend;