import { BunchingStopStat } from '../../../../types/busBunchingTypes';
import { _0px, _1px } from '../../../../utilities/constants';

const _20px = 20 as 20 & Pixels;
const _256px = 256 as 256 & Pixels;

/**
 * The finest value supported by our data keys.
 * @see `LatLonKeyed`
 */

export function drawBunches(
    stopStats: BunchingStopStat[] | undefined,
    canvas: HTMLCanvasElement,
    toPixel: (location: BunchingStopStat) => Point,
): DataUri | undefined {
    if (!stopStats) return undefined;
    // have to do this first because we're going to do it on the same canvas
    // and then clear it
    const colourGradient = createColourGradient(canvas);

    const context = canvas.getContext('2d');
    if (context === null) {
        throw new Error('Cannot draw heatmap: failed to create drawing context.');
    }
    const width = canvas.offsetWidth as Pixels;
    const height = canvas.offsetHeight as Pixels;

    for (const stopStat of stopStats) {
        if (stopStat === undefined) continue;
        const radius = Math.min(
            stopStat.count as Pixels,
            _20px,
        );
        const intensity = 10;
        const [x, y] = toPixel(stopStat);

        // Create radial gradient centred on this point
        const gradient = context.createRadialGradient(x, y, _0px, x, y, radius);
        gradient.addColorStop(0.0, `rgba(0, 0, 0, ${intensity})`);
        gradient.addColorStop(1.0, 'transparent');

        // Draw the heatpoint onto the canvas
        context.fillStyle = gradient;
        context.fillRect(
            x - radius as Pixels, y - radius as Pixels,
            x + radius as Pixels, y + radius as Pixels,
        );
    }
    //to exclude black cirecle for last item   (don't understand why but it works)
    const gradient = context.createRadialGradient(_0px, _0px, _0px, _0px, _0px, _1px);
    gradient.addColorStop(0.0, 'rgba(0, 0, 0, 0)');
    gradient.addColorStop(1.0, 'transparent');
    context.fillStyle = gradient;


    const dat = context.getImageData(_0px, _0px, width, height);
    const pix = dat.data;
    for (let p = 0; p < pix.length; p += 4) {
        const a = pix[p + 3] * 4; // get the alpha value of this pixel
        pix[p + 0] = colourGradient[a + 0]; // get the red value from linear gradient that corresponds to this alpha
        pix[p + 1] = colourGradient[a + 1]; //get the green value based on alpha
        pix[p + 2] = colourGradient[a + 2]; //get the blue value based on alpha
    }
    context.putImageData(dat, _0px, _0px);
    context.fillRect(_0px, _0px, width, height);

    return canvas.toDataURL();
}

export function createColourGradient(
    canvas: HTMLCanvasElement,
): Uint8ClampedArray {
    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error('Could not create 2D context for drawing stop delays gradient.');
    }
    const { width, height } = canvas;
    canvas.width = _256px;
    canvas.height = _1px;

    const gradient = context.createLinearGradient(_0px, _0px, _256px, _0px);
    gradient.addColorStop(0.0, 'black');
    gradient.addColorStop(0.5, 'red');
    gradient.addColorStop(0.9, 'pink');
    gradient.addColorStop(1.0, 'white');

    context.fillStyle = gradient;
    context.fillRect(_0px, _0px, _256px, _1px);
    const result = context.getImageData(_0px, _0px, _256px, _1px).data;

    context.clearRect(_0px, _0px, _256px, _1px);
    canvas.width = width;
    canvas.height = height;

    return result;
}

export function setPixel(
    imageData: ImageData,
    x: Pixels, y: Pixels,
    r: Uint8Clamped, g: Uint8Clamped, b: Uint8Clamped, a: Uint8Clamped,
): void {
    const index = (x + y * imageData.width) * 4;
    imageData.data[index + 0] = r;
    imageData.data[index + 1] = g;
    imageData.data[index + 2] = b;
    imageData.data[index + 3] = a;
}