export enum TreckDetailsLevel {
    /** Level 0 (speed averaging distance = 3000 m) */
    L0 = '3000m',
    /** Level 1 (speed averaging distance = 1500 m) */
    L1 = '1500m',
    /** Level 2 (speed averaging distance = 500 m) */
    L2 = '500m',
    /** Level 3 (speed averaging distance = 250 m) */
    L3 = '250m',
    /** Level 4 (speed averaging distance = 100 m) */
    L4 = '100m',
    /** Level 5 (speed averaging distance = 50 m) */
    L5 = '50m',
    /** Level 6 (speed averaging distance = 30 m) */
    L6 = '30m',
}

export type WithOptionalDetailsLeveled<T> = {
    [detailsLevel in TreckDetailsLevel]?: T;
};

export type WithRequiredDetailsLeveled<T> = {
    [detailsLevel in TreckDetailsLevel]: T;
};

type TreckDetailsLevelDesc = {
    apiValue: number,
    displayName: string,
    minMapZoomLevel: number,
    maxMapZoomLevel: number,
};

export type TreckDetailsLevelMap = WithRequiredDetailsLeveled<TreckDetailsLevelDesc>;

function getAllTreckDetailsLevelValues(): TreckDetailsLevel[] {
    return (Object.values(TreckDetailsLevel) as Array<TreckDetailsLevel>)
        .filter(value => typeof value === 'string');
}

export const AllTreckDetailsLevels = getAllTreckDetailsLevelValues();

function buildTreckDetailsLevelApiValue(v: TreckDetailsLevel): number {
    return parseInt(v.substr(0, v.length - 1));
}

function buildTreckDetailsLevelDisplayName(apiValue: number) {
    if (apiValue > 1000)
        return `${(apiValue / 1000.0).toFixed(1)} km`;
    else
        return `${apiValue} m`;
}

function buildTreckDetailsLevelDesc(
    value: TreckDetailsLevel,
    minMapZoomLevel: number,
    maxMapZoomLevel: number,
): TreckDetailsLevelDesc {
    const apiValue = buildTreckDetailsLevelApiValue(value);
    const displayName = buildTreckDetailsLevelDisplayName(apiValue);
    return {
        apiValue,
        displayName,
        minMapZoomLevel,
        maxMapZoomLevel,
    };
}

const Zm = -9;
const ZM = 99;

export const TreckDetailsLevels: TreckDetailsLevelMap = {
    [TreckDetailsLevel.L0]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L0, Zm, 10),
    [TreckDetailsLevel.L1]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L1, 11, 12),
    [TreckDetailsLevel.L2]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L2, 13, 13),
    [TreckDetailsLevel.L3]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L3, 14, 14),
    [TreckDetailsLevel.L4]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L4, 15, 15),
    [TreckDetailsLevel.L5]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L5, 16, 16),
    [TreckDetailsLevel.L6]: buildTreckDetailsLevelDesc(TreckDetailsLevel.L6, 17, ZM),
};

export function mapZoomLevelToTreckDetailLevel(mapZoomLevel: number): TreckDetailsLevel {
    for (const tdl of AllTreckDetailsLevels) {
        const desc = TreckDetailsLevels[tdl];
        if (desc.minMapZoomLevel <= mapZoomLevel && mapZoomLevel <= desc.maxMapZoomLevel)
            return tdl;
    }
    return TreckDetailsLevel.L0;
}

(function validateZoomRanges() {
    let expectedMin = Zm;
    let last = TreckDetailsLevel.L0;
    for (const tdl of AllTreckDetailsLevels) {
        const desc = TreckDetailsLevels[tdl];
        if (desc.minMapZoomLevel > desc.maxMapZoomLevel) {
            throw new Error(`Expected TreckDetailsLevels['${tdl}'].minMapZoomLevel <= TreckDetailsLevels['${tdl}'].maxMapZoomLevel`);
        }
        if (expectedMin != desc.minMapZoomLevel) {
            throw new Error(`Expected TreckDetailsLevels['${tdl}'].minMapZoomLevel = ${expectedMin}`);
        }
        expectedMin = TreckDetailsLevels[tdl].maxMapZoomLevel + 1;
        last = tdl;
    }
    if (expectedMin != ZM + 1) {
        throw new Error(`Expected TreckDetailsLevels['${last}'].minMapZoomLevel = ${ZM}`);
    }
})();
