import moment from 'moment';
import React from 'react';
import { getDirectionVariantsByRoute, getDirectionVariantsForStop, getRoutesPairs } from '../../actions/gtfsStaticActions';
import { getPositions, getSchedule, getStopPredictions, getStops } from '../../actions/vehiclesHistoryActions';
import { VehiclePositionState } from '../../types/busHistoryTypes';
import { DirectionVariant, GtfsStop2 } from '../../types/gtfsTypes';
import { Dictionary } from '../../types/view-models-interfaces';
import { AgencyModel, BphDirection, BphDirectionModel, BphDirectionsState, BphRouteModel,
    BphRoutesState, BphSliderState, BphStopModel, BphStopsStateType, BphVehicleModel,
    DateTime, StopPredictionsState } from './_models';
import { SliderInfoProps as BphSliderInfoProps } from './BusHistorySliderInfo';

type DateTimeState = {
    selectedDate: string;
    selectedTime: string;
    selectedDateTime: string;
};

type StopsSearchStateType = {
    searchQuery?: string | null;
};

function getFormattedDateTime(date: string, time: string): string {
    return `${date} ${time}`;
}

function getInitialDateTime(): DateTimeState {
    const dt = moment(new Date().setMinutes(0, 0, 0));
    const date = dt.format('YYYY-MM-DD');
    const time = dt.format('h:mm A');
    return {
        selectedDate: date,
        selectedTime: time,
        selectedDateTime: getFormattedDateTime(date, time),
    };
}

function composeForRoute(directionVariant: DirectionVariant) {
    return `${directionVariant.directionVariantName}`;
}

function composeForStop(directionVariant: DirectionVariant) {
    return `${directionVariant.routeName} ${directionVariant.directionVariantName}`;
}

/*-- actions --*/

enum ActionType {
    DEFAULT,

    SET_MAP_READY = 'SET_MAP_READY',
    SET_AGENCY = 'SET_AGENCY',

    SET_DATE_TIME = 'SET_DATE_TIME',
    SET_ROUTES_LOADING = 'SET_ROUTES_LOADING',
    SET_ROUTES_STATE = 'SET_ROUTES_STATE',
    SET_DIRECTIONS_LOADING = 'SET_DIRECTIONS_LOADING',
    SET_DIRECTIONS_STATE = 'SET_DIRECTIONS_STATE',
    SET_STOPS_LOADING = 'SET_STOPS_LOADING',
    SET_STOPS_STATE = 'SET_STOPS_STATE',
    SET_STOP_SEARCH_STATE = 'SET_STOP_SEARCH_STATE',
    SET_NO_STOPS_MESSSAGE = 'SET_NO_STOPS_MESSSAGE',
    SET_STOPS_PREDICTIONS = 'SET_STOPS_PREDICTIONS',
    SET_DIRECTIONS_INFO = 'SET_DIRECTIONS_INFO',
    SET_HISTORY_LOADING = 'SET_HISTORY_LOADING',
    SET_BUS_POSITIONS = 'SET_BUS_POSITIONS',
    SET_SCHEDULE_DATES = 'SET_SCHEDULE_DATES',
    SET_SLIDER_OPTIONS = 'SET_SLIDER_OPTIONS',
    SET_SLIDER_INFO = 'SET_SLIDER_INFO',
}

type BusPositionHistoryAction = {
    type: ActionType,
    payload?: null | boolean | string | Microsoft.Maps.LocationRect
    | DateTimeState
    | AgencyModel | BphRoutesState | BphRouteModel
    | BphDirectionsState | BphDirection[]
    | BphStopsStateType | BphStopModel | StopsSearchStateType
    | VehiclePositionState[] | DateTime[] | BphSliderInfoProps | BphSliderState
    | StopPredictionsState
};

/*-- types --*/

type Nullable<T> = T | null;
type Optional<T> = Nullable<T> | undefined;

interface PublicInstanceHelper {
    dispatch: React.Dispatch<BusPositionHistoryAction>;
}

interface InternalInstanceHelper extends PublicInstanceHelper {
    dispatch: React.Dispatch<BusPositionHistoryAction>;

    _map?: Microsoft.Maps.Map;

    onMapReady(div: HTMLDivElement): void;

    setAgency(state: BusPositionHistoryStateType, agency: AgencyModel | null): void;
    updateDateTime(state: BusPositionHistoryStateType, date: Optional<string>, time: Optional<string>): boolean;
    selectRoute(state: BusPositionHistoryStateType, value: Optional<BphRouteModel>): boolean;
    selectDirection(state: BusPositionHistoryStateType, value: Optional<BphDirectionModel>): boolean;
    selectStop(state: BusPositionHistoryStateType, value: Optional<BphStopModel>): boolean;
    setStopsSearchString(state: BusPositionHistoryStateType, value: Optional<string>): void;
    onSliderTimeChanged(state: BusPositionHistoryStateType, sliderValue: number): void;
    onDisplayDirectionStopsChanged(value: boolean): void

    onSelectedDateChanged(state: BusPositionHistoryStateType): Promise<void>;
    onSelectedRouteChanged(state: BusPositionHistoryStateType): Promise<void>;
    onSelectedStopChanged(state: BusPositionHistoryStateType): Promise<void>;
    onSelectedDirectionChanged(state: BusPositionHistoryStateType): Promise<void>;
    onStopSearchParamsChanged(state: BusPositionHistoryStateType): Promise<void>;
    onStopAndDirectionChanged(state: BusPositionHistoryStateType): Promise<void>;
    onBusPositionsChanged(state: BusPositionHistoryStateType): void;
}

interface BusPositionHistoryStateType {
    helper: PublicInstanceHelper;

    mapReady: boolean;

    agency: AgencyModel | null;
    dateTime: DateTimeState;

    routesLoading: boolean;
    routesState: BphRoutesState;

    directionsLoading: boolean;
    directionsState: BphDirectionsState;
    directionsInfo: BphDirection[];

    stopsLoading: boolean;
    stopsState: BphStopsStateType;
    stopsSearchState: StopsSearchStateType;
    stopsNoResultMessageState?: string;
    stopsPredictions: Nullable<StopPredictionsState>;

    historyLoading: boolean;
    busPositionsState: VehiclePositionState[];

    scheduleDates: Nullable<DateTime[]>;
    sliderOptions: Nullable<BphSliderState>;
    sliderInfo: Nullable<BphSliderInfoProps>;
}

const initialState: BusPositionHistoryStateType = {
    helper: (undefined as unknown) as PublicInstanceHelper,

    mapReady: false,
    agency: null,
    dateTime: {} as DateTimeState,

    routesLoading: false,
    routesState: {
        routes: null,
        selectedRoute: null,
        initialRoutesList: [],
    },
    directionsLoading: false,
    directionsState: {
        directions: null,
        selectedDirection: null,
    },
    directionsInfo: [],
    stopsLoading: false,
    stopsState: {
        stops: null,
        selectedStop: null,
        loadingSchedule: false,
    },
    stopsSearchState: {
    },
    stopsNoResultMessageState: undefined,
    stopsPredictions: null,

    historyLoading: false,
    busPositionsState: [],

    scheduleDates: null,
    sliderOptions: null,
    sliderInfo: null,
};

export interface BusPositionHistoryContextType extends BusPositionHistoryStateType {
    onMapReady: (div: HTMLDivElement) => void;
    setAgency: (agency: AgencyModel | null) => void;
    updateDateTime: (date: Optional<string>, time: Optional<string>) => boolean;
    selectRoute: (value?: Nullable<BphRouteModel>) => boolean;
    selectDirection: (value?: Optional<BphDirectionModel>) => boolean;
    selectStop(value?: Nullable<BphStopModel>): boolean;
    setStopsSearchString: (value?: Nullable<string>) => void;
    onSliderTimeChanged: (sliderValue: number) => void;
    onDisplayDirectionStopsChanged: (value: boolean) => void;
}

/*-- internal helper --*/

class InstanceHelper implements PublicInstanceHelper, InternalInstanceHelper {
    static nextUid = 0;

    public readonly uid: number;

    public dispatch: React.Dispatch<BusPositionHistoryAction>;

    constructor() {
        this.uid = ++InstanceHelper.nextUid;

        this.dispatch = (undefined as unknown) as React.Dispatch<BusPositionHistoryAction>;
        this._spool = new StopsMapDisplayPool(this);
        this._vpool = new VehicleMapDisplayPool(this);
        this._tpool = new DirectionsMapDisplayPool(this);
    }

    private _setMapBounds(value: Microsoft.Maps.LocationRect) {
        this._map?.setView({ bounds: value });
    }

    private _mapReady = initialState.mapReady;
    public _mapDiv?: HTMLDivElement;
    public _map?: Microsoft.Maps.Map;

    public onMapReady(div: HTMLDivElement): void {
        this._mapReady = !!div;
        this._mapDiv = div;
        this.dispatch({ type: ActionType.SET_MAP_READY, payload: this._mapReady });
    }

    public async setAgency(state: BusPositionHistoryStateType, agency: AgencyModel | null) {
        if (state.agency === agency) return;
        this.dispatch({ type: ActionType.SET_AGENCY, payload: agency });
        return true;
    }

    /* -------------------- */

    public updateDateTime(state: BusPositionHistoryStateType, date: Optional<string>, time: Optional<string>): boolean {
        date = date || state.dateTime.selectedDate;
        time = time || state.dateTime.selectedTime;

        const dateChanged = date !== state.dateTime.selectedDate;
        const timeChanged = time !== state.dateTime.selectedTime;

        if (!dateChanged && !timeChanged) {
            return false;
        }
        const value: DateTimeState = {
            selectedDate: date,
            selectedTime: time,
            selectedDateTime: getFormattedDateTime(date, time),
        };
        this.dispatch({ type: ActionType.SET_DATE_TIME, payload: value });
        if (!dateChanged && timeChanged) {
            if (!state.routesState?.selectedRoute) {
                this._setStopsState(null);
            }
            this.selectRoute(state, null);
        }
        return true;
    }

    private _setRoutesState(value: Optional<BphRoutesState>): void {
        this.dispatch({ type: ActionType.SET_ROUTES_STATE, payload: value || initialState.routesState });
    }

    public selectRoute(state: BusPositionHistoryStateType, value: Optional<BphRouteModel>): boolean {
        value = value || null;
        if (state.routesState.selectedRoute === value) return false;
        if (!value) {
            this._setStopsState(null);
        }
        else {
            this.selectStop(state, state.stopsState.selectedStop);
        }
        this.dispatch({
            type: ActionType.SET_ROUTES_STATE, payload: {
                ...state.routesState,
                selectedRoute: value,
            },
        });
        return true;
    }

    private _setDirections(value?: Optional<BphDirectionModel[]>): void {
        const v: BphDirectionsState = {
            directions: value || null,
            selectedDirection: null,
        };
        this.dispatch({ type: ActionType.SET_DIRECTIONS_STATE, payload: v });
        if (!value) {
            this._tpool.empty();
            this._vpool.empty();
        }
        else {
            const dirs: Dictionary<BphDirectionModel> = {};
            for (const d of value) {
                this._tpool.add(d);
                dirs[d.directionVariantId] = d;
            }
            this._tpool.set(dirs);
        }
    }

    selectDirection(state: BusPositionHistoryStateType, value: Optional<BphDirectionModel>): boolean {
        value = value || null;
        if (state.directionsState.selectedDirection === value) return false;
        if (state.directionsState.selectedDirection) {
            state.directionsState.selectedDirection.select(false);
        }
        this._tpool.select(value || null);
        this.dispatch({
            type: ActionType.SET_DIRECTIONS_STATE, payload: {
                directions: state.directionsState.directions,
                selectedDirection: value,
            },
        });
        return true;
    }

    private _setStopsLoading(value: boolean): void {
        this.dispatch({ type: ActionType.SET_STOPS_LOADING, payload: !!value });
    }

    private _setStopsState(value: Optional<BphStopsStateType>): void {
        const v = value || initialState.stopsState;
        this.dispatch({ type: ActionType.SET_STOPS_STATE, payload: v });
        this._spool.select(v.selectedStop);
    }

    public selectStop(state: BusPositionHistoryStateType, value: Optional<BphStopModel>): boolean {
        value = value || null;
        if (state.stopsState.selectedStop === value) return false;
        this._spool.select(value);
        this._setStopsState({
            stops: state.stopsState.stops,
            selectedStop: value,
            loadingSchedule: false,
        });
        if (value) {
            this._map?.setView({
                center: value.pushpin.getLocation(),
            });
        }
        return true;
    }

    public setStopsSearchString(state: BusPositionHistoryStateType, value: Optional<string>): void {
        if (state.routesState.selectedRoute)
            return;
        if (!value) {
            this._setStopsNoResutMessageState('');
        }
        this.dispatch({
            type: ActionType.SET_STOP_SEARCH_STATE,
            payload: { searchQuery: value },
        });
    }

    private _setStopsNoResutMessageState(value: Optional<string>): void {
        this.dispatch({ type: ActionType.SET_NO_STOPS_MESSSAGE, payload: value || null });
    }
    private _setDirectionsInfo(value: Optional<BphDirection[]>): void {
        this.dispatch({ type: ActionType.SET_DIRECTIONS_INFO, payload: value || initialState.directionsInfo });
    }
    private _setStopPredictions(value: Optional<StopPredictionsState>): void {
        this.dispatch({ type: ActionType.SET_STOPS_PREDICTIONS, payload: value || initialState.stopsPredictions });
    }
    private _setBusPositionsState(value: Optional<VehiclePositionState[]>): void {
        this.dispatch({ type: ActionType.SET_BUS_POSITIONS, payload: value || initialState.busPositionsState });
    }
    private _setScheduleDates(value: Optional<DateTime[]>): void {
        this.dispatch({ type: ActionType.SET_SCHEDULE_DATES, payload: value || null });
    }
    private _setSliderOptions(value: Optional<BphSliderState>): void {
        this.dispatch({ type: ActionType.SET_SLIDER_OPTIONS, payload: value || null });
    }
    private _setSliderInfo(value: Optional<BphSliderInfoProps>): void {
        this._vpool.highlight(value?.vehiclePosition.vehicleId);
        this.dispatch({ type: ActionType.SET_SLIDER_INFO, payload: value || null });
    }

    //---

    private _setRoutesLoading(value: boolean) {
        this.dispatch({ type: ActionType.SET_ROUTES_LOADING, payload: value });
    }

    public async onSelectedDateChanged(state: BusPositionHistoryStateType) {
        this._setRoutesState(null);
        this._setStopsState(null);
        this._setDirections(null);
        this._setDirectionsInfo(null);
        this._setBusPositionsState(null);

        this._tpool.empty();
        this._vpool.empty();

        if (!state.agency || !state.dateTime.selectedDate || !state.mapReady) return;

        this._setRoutesLoading(true);

        const serviceDate = moment(state.dateTime.selectedDate).format('YYYY-MM-DD');
        try {
            const resRoutes = await getRoutesPairs(state.agency.id, serviceDate);
            const agencyLocation = state.agency?.location || new Microsoft.Maps.Location(38.87757365244203, -77.10571938437442);
            if (this._map) {
                this._map.setView({
                    center: agencyLocation,
                });
            }
            else {
                if (!this._mapDiv) throw new Error('BPH: map div is not ready');
                this._map = new Microsoft.Maps.Map(this._mapDiv, {
                    center: agencyLocation,
                    zoom: 12,
                });
            }
            this._setRoutesState({
                routes: resRoutes.map(item => new BphRouteModel(item.key, item.value)),
                selectedRoute: null,
                initialRoutesList: resRoutes.map(item => new BphRouteModel(item.key, item.value)),
            });
        }
        finally {
            this._setRoutesLoading(false);
        }
    }

    private _setDirectionsLoading(value: boolean) {
        this.dispatch({ type: ActionType.SET_DIRECTIONS_LOADING, payload: value });
    }

    private _setHistoryLoading(value: boolean) {
        this.dispatch({ type: ActionType.SET_HISTORY_LOADING, payload: value });
    }

    private async _updateSliderData(agencyId: string, selectedDateState: string, direction: Nullable<BphDirectionModel>) {
        if (!agencyId || !direction) return;

        this._vpool.empty();
        this._setHistoryLoading(true);
        try {
            const time = moment(selectedDateState).format('YYYY-MM-DDTHH:mm:ss');
            const vehiclePositions = await getPositions(agencyId, direction.directionVariantId, time);
            this._setBusPositionsState(vehiclePositions);
        }
        finally {
            this._setHistoryLoading(false);
        }
    }

    public async onSelectedRouteChanged(state: BusPositionHistoryStateType) {
        if (!state.stopsState.selectedStop) {
            this._setDirections(null);
            this._setDirectionsInfo(null);
            this._setBusPositionsState(null);
            this._setScheduleDates(null);
        }

        if (!state.agency)
            return;

        if (state.routesState.selectedRoute) {
            // use directions by route

            try {
                this._setDirectionsLoading(true);
                const directionVariants = state.stopsState.selectedStop?.stopId ?
                    await getDirectionVariantsForStop(state.agency.id, state.stopsState.selectedStop.stopId, state.routesState.selectedRoute.routeId, state.dateTime.selectedTime) :
                    await getDirectionVariantsByRoute(state.routesState.selectedRoute.routeId, state.dateTime.selectedTime);
                if (directionVariants) {
                    this._showDirections(directionVariants, composeForRoute);
                    if (!state.stopsState.selectedStop) {
                        const directionStops = directionVariants.reduce<GtfsStop2[]>((accumulator, direction) => accumulator.concat(direction.stops), []);
                        this._loadStopsOptions(directionStops);
                    }
                }
            }
            finally {
                this._setDirectionsLoading(false);
            }
        }
    }

    public async onSelectedStopChanged(state: BusPositionHistoryStateType) {
        if (!state.routesState.selectedRoute) {
            this._setDirections(null);
            this._setDirectionsInfo(null);
            this._setBusPositionsState(null);
            this._setScheduleDates(null);
        }
        if (!state.agency)
            return;

        this._spool.select(state.stopsState.selectedStop);

        if (!state.stopsState.selectedStop) {
            this._setRoutesState({
                ...state.routesState,
                routes: state.routesState.initialRoutesList,
                selectedRoute: state.routesState.initialRoutesList.find(r => r.routeId === state?.routesState?.selectedRoute?.routeId) || null,
            });
            this.onSelectedRouteChanged(state);
        }
        if (state.routesState.selectedRoute && state.directionsState.selectedDirection) {
            return;
        }
        if (!state.stopsState.selectedStop && !state.routesState.selectedRoute) {
            this._tpool.empty();
            this._setDirectionsInfo(null);
        }
        if (state.stopsState.selectedStop) {
            // use directions by stop

            this._tpool.empty();
            this._setDirections(null);

            try {
                this._setDirectionsLoading(true);
                const directionVariants = await getDirectionVariantsForStop(state.agency.id, state.stopsState.selectedStop.stopId, state.routesState.selectedRoute?.routeId, state.dateTime.selectedTime);
                if (directionVariants) {
                    const directionsForRoutes = state?.routesState?.selectedRoute?.displayName ?
                        directionVariants.filter(d => d.routeName === state?.routesState?.selectedRoute?.displayName) :
                        directionVariants;
                    this._showDirections(directionsForRoutes, composeForStop);
                    if (state && state.routesState && state.routesState.routes && state.routesState.routes.length > 0 && !state.routesState.selectedRoute) {
                        const routes = state.routesState.initialRoutesList.filter(r => directionVariants.map(v => v.routeName).includes(r.displayName));
                        this._setRoutesState({
                            ...state.routesState,
                            routes,
                            selectedRoute: routes.find(r => r.routeId === state?.routesState?.selectedRoute?.routeId) || null,
                        });
                    }
                }
            }
            finally {
                this._setDirectionsLoading(false);
            }
        }
    }

    public async onSelectedDirectionChanged(state: BusPositionHistoryStateType) {
        if (!state.agency) return;

        this._setBusPositionsState(null);
        this._setSliderInfo(null);

        const isDisplayDirectionStops = this._spool.isDisplayDirectionStops;
        if (isDisplayDirectionStops)
            this._spool.switchDirectionStops(false);
        if (state.directionsState.selectedDirection) {
            this._setMapBounds(state.directionsState.selectedDirection.bounds);
            this._spool.setDirectionStops(state.directionsState.selectedDirection.getStops());
            if (isDisplayDirectionStops)
                this._spool.switchDirectionStops(true);
        } else {
            this._spool.setDirectionStops([]);
        }

        await this._updateSliderData(state.agency.id, state.dateTime.selectedDateTime, state.directionsState.selectedDirection);
    }

    public async onStopSearchParamsChanged(state: BusPositionHistoryStateType) {
        if (state.agency &&
            state.stopsSearchState &&
            state.stopsSearchState.searchQuery
        ) {
            this._setStopsLoading(true);
            try {
                const result = await getStops(state.agency.id, state.stopsSearchState.searchQuery);
                if (result) {
                    this._loadStopsOptions(result);
                }
            }
            finally {
                this._setStopsLoading(false);
            }
        }
    }

    public async onStopAndDirectionChanged(state: BusPositionHistoryStateType): Promise<void> {
        this._setStopPredictions(null);
        this._setScheduleDates(null);

        const stopId = state.stopsState.selectedStop?.stopId;
        const selectedDirection = state.directionsState.selectedDirection;

        if (!state.agency || !selectedDirection || !stopId) return;

        this._setStopsState({ ...state.stopsState, loadingSchedule: true });

        const serviceDate = DateTime.fromStringAgencyDateTime(state.dateTime.selectedDateTime);
        const directionVariantId = selectedDirection.directionVariantId;
        try {
            const [resSchedule, resPredictions] = await Promise.all([
                getSchedule(serviceDate.format('YYYY-MM-DD'), stopId, directionVariantId),
                getStopPredictions(state.agency.id, stopId, directionVariantId, serviceDate.format('YYYY-MM-DDTHH:mm:ss')),
            ]);
            if (resSchedule) {
                const scheduleRange = (resSchedule as any as string[])
                    .map(s => DateTime.fromStringUtcAsAgency(s));
                this._setScheduleDates(scheduleRange);
            } else {
                this._setScheduleDates(null);
            }
            this._setStopPredictions(new StopPredictionsState(resPredictions));
        }
        catch (_error) {
            console.error('BusHistory: getSchedule failed', _error);
        } finally {
            this._setStopsState({ ...state.stopsState, loadingSchedule: false });
        }
    }

    public onSliderTimeChanged(state: BusPositionHistoryStateType, sliderValue: number): void {
        if (state.busPositionsState.length === 0) {
            this._setSliderInfo(null);
            return;
        }

        const aheadPosition = this._onTimeSliderChange(state.busPositionsState, sliderValue);

        const position = state.busPositionsState[sliderValue];

        if (state.directionsState.selectedDirection && position) {
            const selectedDirection = state.directionsState.selectedDirection;
            this._setSliderInfo({
                routeName: selectedDirection.routeName,
                stopName: selectedDirection.lastStop,
                vehiclePosition: position,
                aheadPosition: aheadPosition,
            });
        }
    }

    public onDisplayDirectionStopsChanged(value: boolean): void {
        this._spool.switchDirectionStops(value);
    }

    public onBusPositionsChanged(state: BusPositionHistoryStateType): void {
        this._vpool.empty();

        if (state.busPositionsState.length > 0) {
            const sliderValue = Math.floor(state.busPositionsState.length / 2);
            this._setSliderOptions({
                maxValue: state.busPositionsState.length - 1,
                sliderValue: sliderValue,
            });
            this.onSliderTimeChanged(state, sliderValue);
        } else {
            this._setSliderOptions(null);
        }
    }

    private _showDirections(directionVariants: DirectionVariant[], nameComposer: (v: DirectionVariant) => string) {
        let bounds: Microsoft.Maps.LocationRect | null = null;
        const infos: BphDirection[] = [];
        const directions: BphDirectionModel[] = [];
        for (const variant of directionVariants) {
            const direction = new BphDirectionModel(
                nameComposer(variant),
                variant,
            );
            directions.push(direction);
            infos.push({
                id: variant.directionVariantId,
                route: variant.routeName,
                stop: variant.lastStop,
            });
            bounds = bounds === null
                ? direction.bounds.clone()
                : Microsoft.Maps.LocationRect.merge(bounds, direction.bounds);
        }
        if (bounds) {
            this._setMapBounds(bounds);
        }
        this._setDirectionsInfo(infos);
        this._setDirections(directions);
    }

    private _loadStopsOptions(stops: GtfsStop2[]) {
        if (stops.length === 1 && !stops[0].stopId) { // no stops found
            this._setStopsState(null);
            this._setStopsNoResutMessageState(stops[0].stopName);
        } else {
            const directionStops = Array.from(new Set(stops.map(s => {
                const r = JSON.stringify({
                    stopId: s.stopId,
                    displayName: s.stopName,
                    lat: s.lat,
                    lon: s.lon,
                });
                return r;
            }))).map(s => JSON.parse(s));
            this._setStopsState({
                stops: directionStops.map(s => new BphStopModel(s.displayName, s.stopId, s.lat, s.lon)),
                selectedStop: null,
                loadingSchedule: false,
            });
        }
    }

    private readonly _spool: StopsMapDisplayPool;
    private readonly _tpool: DirectionsMapDisplayPool;
    private readonly _vpool: VehicleMapDisplayPool;

    private _onTimeSliderChange(
        busPositionsState: VehiclePositionState[],
        sliderValue: number,
    ): VehiclePositionState | null {
        const vehicles: Dictionary<BphVehicleModel> = {}; // key is `position.vehicleId`

        const position = busPositionsState[sliderValue];

        this._addVehicle(vehicles, position);

        const ahead: VehiclePositionState | null = null;

        const maxTripDuration = 5 * 60000; // 5 min

        const lowerBoundTime = moment.parseZone(position.reportTimeAtz).valueOf() - maxTripDuration / 2;
        const upperBoundTime = moment.parseZone(position.reportTimeAtz).valueOf() + maxTripDuration / 2;

        // scan array from 'present'+1 to the 'future'
        //
        for (let ix = sliderValue + 1; ix < busPositionsState.length; ++ix) {
            const sibling = busPositionsState[ix];

            if (moment.parseZone(sibling.reportTimeAtz).valueOf() > upperBoundTime)
                break;

            if (!(sibling.vehicleId in vehicles)) {
                this._addVehicle(vehicles, sibling);
            }
        }

        // scan array from 'present'-1 to the 'past'
        //
        for (let ix = sliderValue - 1; ix >= 0; --ix) {
            const sibling = busPositionsState[ix];

            if (moment.parseZone(sibling.reportTimeAtz).valueOf() < lowerBoundTime)
                break;

            if (!(sibling.vehicleId in vehicles)) {
                this._addVehicle(vehicles, sibling);
            }
        }

        this._vpool.set(vehicles);

        return ahead;
    }

    private _addVehicle(
        vehicles: Dictionary<BphVehicleModel>,
        position: VehiclePositionState,
    ) {
        let vehicle: BphVehicleModel;
        if (this._vpool.has(position.vehicleId)) {
            vehicle = this._vpool.get(position.vehicleId);
            vehicle.update(position);
        }
        else {
            vehicle = new BphVehicleModel(position);
            this._vpool.add(vehicle);
        }
        vehicles[vehicle.id] = vehicle;
    }
}

class StopsMapDisplayPool {
    private _selected: BphStopModel | null = null;
    private _directionStops: BphStopModel[] = [];
    public isDisplayDirectionStops = false;

    constructor(private owner: InstanceHelper) {
    }

    public empty() {
        this.select(null);
    }

    public select(stop: Nullable<BphStopModel>): void {
        if (this._selected === stop) return;
        if (this._selected) {
            this.owner._map?.entities.remove(this._selected.pushpin);
        }
        this._selected = stop;
        if (this._selected) {
            this.owner._map?.entities.add(this._selected.pushpin);
            if (this.isDisplayDirectionStops) {
                this.removeDirectionStops();
                this.displayDirectionStops();
            }
        }
    }

    public setDirectionStops(stops: BphStopModel[]) {
        this._directionStops = stops;
    }

    public switchDirectionStops(display: boolean) {
        this.isDisplayDirectionStops = display;
        if (display)
            this.displayDirectionStops();
        else
            this.removeDirectionStops();
    }

    private displayDirectionStops() {
        this._directionStops.forEach(stop => {
            if (!this._selected || this._selected.stopId !== stop.stopId) {
                this.owner._map?.entities.add(stop.pushpin);
                if (this.owner._map)
                    stop.infobox.setMap(this.owner._map);
            }
        });
    }
    private removeDirectionStops() {
        this._directionStops.forEach(stop => {
            this.owner._map?.entities.remove(stop.pushpin);
            stop.infobox.setMap(null as any as Microsoft.Maps.Map);
            stop.infobox.setOptions({ visible: false });
        });
    }
}

class DirectionsMapDisplayPool {
    private readonly _cached: Dictionary<BphDirectionModel> = {};
    private _listed: Dictionary<BphDirectionModel> = {};
    private _selected: BphDirectionModel | null = null;

    constructor(private owner: InstanceHelper) {
    }

    public empty() {
        this.select(null);
        this._clear(this._listed, true);
        this._clear(this._cached, false);
    }

    public select(direction: Nullable<BphDirectionModel>): void {
        if (this._selected === direction) {
            return;
        }
        if (this._selected) {
            this._selected.select(false);
        }
        this._selected = direction;
        if (this._selected) {
            this._selected.select(true);
            this._hidePoyline(this._selected);
            this._showPoyline(this._selected);
        }
    }

    public add(direction: BphDirectionModel): void {
        this._cached[direction.directionVariantId] = direction;
    }

    public set(directions: Dictionary<BphDirectionModel>) {
        const toRemove = this._listed;
        for (const id in directions) {
            if (this._cached[id] !== directions[id]) {
                throw new Error(`BHP.TrackPool: direction (${id}) is not cached!`);
            }
            if (id in toRemove) {
                // already displayed
                delete toRemove[id];
            }
            else {
                // new item to display
                const item = directions[id];
                this._showPoyline(item);
            }
        }
        for (const id in toRemove) {
            const item = toRemove[id];
            if (this._selected === item) {
                item.select(false);
            }
            this._hidePoyline(item);
        }
        this._listed = directions;
    }

    private _showPoyline(item: BphDirectionModel) {
        if (this.owner._map)
            this.owner._map?.entities.push(item.polyline);
    }

    private _hidePoyline(item: BphDirectionModel) {
        if (this.owner._map)
            this.owner._map?.entities.remove(item.polyline);
    }

    private _clear(list: Dictionary<BphDirectionModel>, remove?: boolean) {
        for (const id in list) {
            const item = list[id];
            delete list[id];
            if (remove) {
                this._hidePoyline(item);
            }
        }
    }
}

class VehicleMapDisplayPool {

    private readonly _cached: Dictionary<BphVehicleModel> = {};
    private _listed: Dictionary<BphVehicleModel> = {};

    constructor(private owner: InstanceHelper) {
    }

    public empty() {
        this._clear(this._listed, true);
        this._clear(this._cached, false);
    }

    public has(id: string): boolean {
        return (id in this._cached);
    }

    public get(id: string): BphVehicleModel {
        const result = this._cached[id];
        if (!result)
            throw new Error(`BHP.VehiclePool: no cached object (${id})`);
        return result;
    }

    public add(vehicle: BphVehicleModel): void {
        this._cached[vehicle.id] = vehicle;
    }

    private _highlighted: BphVehicleModel | null = null;

    public highlight(vehicleId?: string | null): boolean {
        const vehicle = (vehicleId && this.get(vehicleId)) || null;
        if (vehicle === this._highlighted) return false;

        if (this._highlighted)
            this._highlighted.highlighted = false;

        this._highlighted = vehicle;

        if (this._highlighted)
            this._highlighted.highlighted = true;

        return true;
    }

    public set(vehicles: Dictionary<BphVehicleModel>) {
        const toRemove = this._listed;
        for (const id in vehicles) {
            if (this._cached[id] !== vehicles[id]) {
                throw new Error(`BHP.VehiclePool: vehicle (${id}) is not cached!`);
            }
            if (id in toRemove) {
                // already displayed
                delete toRemove[id];
            }
            else {
                // new item to display
                const item = vehicles[id];
                this._showInfobox(item);
            }
        }
        for (const id in toRemove) {
            const item = toRemove[id];
            this._hideInfobox(item);
        }
        this._listed = vehicles;
    }

    private _showInfobox(item: BphVehicleModel) {
        if (this.owner._map)
            item.infobox.setMap(this.owner._map);
    }

    private _hideInfobox(item: BphVehicleModel): void {
        if (this.owner._map)
            item.infobox.setMap(null as any as Microsoft.Maps.Map);
    }

    private _clear(list: Dictionary<BphVehicleModel>, remove?: boolean) {
        for (const id in list) {
            const item = list[id];
            delete list[id];
            if (remove) {
                this._hideInfobox(item);
            }
        }
    }
}

/*-- context --*/

export const BusPositionHistoryContext = (React as any).createContext() as React.Context<BusPositionHistoryContextType>;

/*-- reducer --*/

type ActionMap = {
    [actionType in ActionType]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction) => BusPositionHistoryStateType
};

const handlers: ActionMap = {
    [ActionType.DEFAULT]: (state: BusPositionHistoryStateType) => state,
    [ActionType.SET_MAP_READY]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        return {
            ...state,
            mapReady: !!action.payload,
        };
    },
    [ActionType.SET_AGENCY]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as (AgencyModel | null);
        if (value === state.agency) {
            return state;
        }
        return {
            ...state,
            agency: value,
        };
    },
    [ActionType.SET_DATE_TIME]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as DateTimeState;
        return {
            ...state,
            dateTime: value,
        };
    },
    [ActionType.SET_ROUTES_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            routesLoading: value,
        };
    },
    [ActionType.SET_ROUTES_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphRoutesState;
        return {
            ...state,
            routesState: value,
        };
    },
    [ActionType.SET_STOPS_PREDICTIONS]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<StopPredictionsState>;
        return {
            ...state,
            stopsPredictions: value,
        };
    },
    [ActionType.SET_DIRECTIONS_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphDirectionsState;
        return {
            ...state,
            directionsState: value,
        };
    },
    [ActionType.SET_STOPS_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            stopsLoading: value,
        };
    },
    [ActionType.SET_STOPS_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphStopsStateType;
        return {
            ...state,
            stopsState: value,
        };
    },
    [ActionType.SET_STOP_SEARCH_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as StopsSearchStateType;
        return {
            ...state,
            stopsSearchState: value,
        };
    },
    [ActionType.SET_NO_STOPS_MESSSAGE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as string;
        return {
            ...state,
            stopsNoResultMessageState: value,
        };
    },
    [ActionType.SET_DIRECTIONS_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            directionsLoading: value,
        };
    },
    [ActionType.SET_DIRECTIONS_INFO]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphDirection[];
        return {
            ...state,
            directionsInfo: value,
        };
    },
    [ActionType.SET_HISTORY_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            historyLoading: value,
        };
    },
    [ActionType.SET_BUS_POSITIONS]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as VehiclePositionState[];
        return {
            ...state,
            busPositionsState: value,
        };
    },
    [ActionType.SET_SCHEDULE_DATES]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<DateTime[]>;
        return {
            ...state,
            scheduleDates: value,
        };
    },
    [ActionType.SET_SLIDER_OPTIONS]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<BphSliderState>;
        return {
            ...state,
            sliderOptions: value,
        };
    },
    [ActionType.SET_SLIDER_INFO]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<BphSliderInfoProps>;
        return {
            ...state,
            sliderInfo: value,
        };
    },
};

function bphReducer(state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType {
    const handler = (handlers as any)[action.type] || handlers[ActionType.DEFAULT];
    return handler(state, action);
}

/*-- state component --*/

function initializeState(src: BusPositionHistoryStateType): BusPositionHistoryStateType {
    if (!src.helper) {
        const result: BusPositionHistoryStateType = {
            ...src,
            helper: new InstanceHelper(),
            dateTime: getInitialDateTime(),
        };
        return result;
    }
    return src;
}

function cast(state: BusPositionHistoryStateType): InternalInstanceHelper {
    if (state.helper instanceof InstanceHelper)
        return state.helper;

    console.error('BusPositionHistoryContext: invalid state.internals');
    return (state.helper as unknown) as InternalInstanceHelper;
}

export const BusPositionHistoryState = (props: React.Props<{}>) => {
    const [state, dispatch] = React.useReducer(bphReducer, initialState, initializeState);
    cast(state).dispatch = dispatch;

    const onMapReady = (div: HTMLDivElement): void => {
        cast(state).onMapReady(div);
    };
    const setAgency = (agency: AgencyModel | null) => {
        cast(state).setAgency(state, agency);
    };
    const updateDateTime = (date: Optional<string>, time: Optional<string>): boolean => {
        return cast(state).updateDateTime(state, date, time);
    };
    const selectRoute = (value?: Nullable<BphRouteModel>): boolean => {
        return cast(state).selectRoute(state, value);
    };
    const selectDirection = (value?: Nullable<BphDirectionModel>): boolean => {
        return cast(state).selectDirection(state, value);
    };
    const selectStop = (value?: Nullable<BphStopModel>): boolean => {
        return cast(state).selectStop(state, value);
    };
    const setStopsSearchString = (value?: Nullable<string>): void => {
        return cast(state).setStopsSearchString(state, value);
    };
    const onSliderTimeChanged = (value: number): void => {
        cast(state).onSliderTimeChanged(state, value);
    };
    const onDisplayDirectionStopsChanged = (value: boolean): void => {
        cast(state).onDisplayDirectionStopsChanged(value);
    };

    React.useEffect(() => {
        cast(state).onSelectedDateChanged(state);
    }, [state.agency, state.dateTime.selectedDate, state.mapReady]);

    React.useEffect(() => {
        cast(state).onSelectedRouteChanged(state);
    }, [state.routesState.selectedRoute]);

    React.useEffect(() => {
        cast(state).onSelectedStopChanged(state);
    }, [state.stopsState.selectedStop]);

    React.useEffect(() => {
        cast(state).onSelectedDirectionChanged(state);
    }, [state.directionsState.selectedDirection]);

    React.useEffect(() => {
        cast(state).onStopAndDirectionChanged(state);
    }, [state.stopsState.selectedStop, state.directionsState.selectedDirection]);

    React.useEffect(() => {
        if (!state.agency) return;
        cast(state).onStopSearchParamsChanged(state);
    }, [state.stopsSearchState]);

    React.useEffect(() => {
        cast(state).onBusPositionsChanged(state);
    }, [state.busPositionsState]);

    return (
        <BusPositionHistoryContext.Provider value={{
            ...state,
            onMapReady,
            setAgency,
            updateDateTime,
            selectRoute,
            selectDirection,
            selectStop,
            setStopsSearchString,
            onSliderTimeChanged,
            onDisplayDirectionStopsChanged,
        }}
        >
            {props.children}
        </BusPositionHistoryContext.Provider>
    );
};
