import moment from 'moment';
import { AgencyStateType } from '../../actions/actionTypes';
import busStopSelectedIcon from '../../static/bus-stop-20-hover.png';
import busStopIcon from '../../static/bus-stop-20.png';
import { ErrorContext, OperationResult } from '../../types/apiTypes';
import { StopPredictionDto, VehiclePositionState } from '../../types/busHistoryTypes';
import { DirectionVariant } from '../../types/gtfsTypes';
import { Dictionary } from '../../types/view-models-interfaces';
import { getVehicleInfoboxHtml } from './VehicleInfobox';

export interface AgencyModel extends AgencyStateType {
    location?: Microsoft.Maps.Location | null;
}

const trackColorOrdinary = 'royalblue';
const trackColorSelected = 'orangered';

function twoDigitString(value: number) {
    return ('0' + value).substr(-2);
}

export interface SecondsIntervalOptions {
    /** default: '-' */
    negativeSign?: string,
    /** default: '+' */
    positiveSign?: string,
    /** default: 'before' */
    position?: 'before' | 'after'
}

/** Converts time interval in seconds to diaplay string:
 * 
 * no options:
 * * `"-2.03:59:58"` - 2 days 3 hours 59 minutes and 58 seconds ago
 * * `"+7:02:03"` - after 7 hours 2 minutes and 3 seconds
 * * `"-05:46"` - 5 minutes and 46 seconds ago
 * * `"+00:15"` - after 15 seconds
 * 
 * using options `{ negativeSign: " behind", positiveSign: " ahead", position: "after" }`:
 * * `"2.03:59:58 bihind"`
 * * `"7:02:03 ahead"`
 * * `"05:46 behind"`
 * * `"00:15 ahead"`
 */
export function secondsIntervalToString(interval: number, options?: SecondsIntervalOptions) {
    if (interval === 0) return '00:00';

    let sign: string;
    if (interval > 0) {
        sign = (options && options?.positiveSign) || '+';
    }
    else {
        sign = (options && options?.negativeSign) || '-';
        interval = -interval;
    }

    const seconds = twoDigitString(interval % 60); interval = Math.floor(interval / 60);
    const minutes = twoDigitString(interval % 60); interval = Math.floor(interval / 60);
    const hours = (interval % 24);
    const days = Math.floor(interval / 24);

    let result = '';
    if (days > 0) {
        result += days.toFixed() + '.';
        result += twoDigitString(hours) + ':';
    }
    else if (hours > 0) {
        result += hours.toFixed() + ':';
    }
    result += minutes + ':' + seconds;
    if (options && options?.position === 'after')
        return result + sign;
    else
        return sign + result;
}

export interface NamedEntity {
    displayName: string
}

export class BphRouteModel implements NamedEntity {
    constructor(
        public readonly routeId: string,
        public readonly displayName: string) {
    }
}

export type BphRoutesState = {
    routes: BphRouteModel[] | null;
    selectedRoute: BphRouteModel | null;
    initialRoutesList: BphRouteModel[];
};

export class BphDirectionModel implements NamedEntity {
    public readonly directionVariantId: string;
    public readonly directionVariantInternalId: string;
    public readonly routeName: string;
    public readonly cardinalDirection: string;
    public readonly lastStop: string;
    public readonly polyline: Microsoft.Maps.Polyline;
    public readonly bounds: Microsoft.Maps.LocationRect;

    private readonly _stopIds?: Dictionary<BphStopModel>;

    constructor(
        public readonly displayName: string,
        source: DirectionVariant,
    ) {
        this.directionVariantId = source.directionVariantId;
        this.directionVariantInternalId = source.directionVariantInternalId;
        this.routeName = source.routeName;
        this.cardinalDirection = source.cardinalDirection;
        this.lastStop = source.lastStop;
        if (source.stops) {
            this._stopIds = {};
            for (const s of source.stops) {
                this._stopIds[s.stopId] = new BphStopModel(s.stopName, s.stopId, Number(s.lat), Number(s.lon), false);
            }
            const stops = Object.values(this._stopIds);
            stops.forEach(s => s.addPushpinHandler(s.pushpin, stops.map(si => si.infobox)));
        }
        const locations = source.pointCoords.map(e => new Microsoft.Maps.Location(e[0] as Latitude, e[1] as Longitude));
        this.bounds = Microsoft.Maps.LocationRect.fromLocations(locations);
        this.polyline = new Microsoft.Maps.Polyline(
            locations,
            {
                strokeColor: trackColorOrdinary,
                strokeThickness: 3,
            },
        );
    }

    public get hasStops(): boolean {
        return !!this._stopIds;
    }

    public hasStop(stopId: string): boolean {
        return !!this._stopIds && (stopId in this._stopIds);
    }

    public select(selected: boolean) {
        this.polyline.setOptions({
            strokeColor: selected
                ? trackColorSelected
                : trackColorOrdinary,
        });
    }

    public getStops(): BphStopModel[] {
        return this._stopIds ? Object.values(this._stopIds) : [];
    }
}

export class BphVehicleModel {
    private static _zero?: Microsoft.Maps.Location;

    public readonly id: string;
    public readonly infobox: Microsoft.Maps.Infobox;

    constructor(position: VehiclePositionState) {
        this.id = position.vehicleId;
        if (!BphVehicleModel._zero) {
            BphVehicleModel._zero = new Microsoft.Maps.Location(0, 0);
        }
        this.infobox = new Microsoft.Maps.Infobox(BphVehicleModel._zero);
        this.update(position);
    }

    private _description = '';
    private _highlighted = false;

    public get highlighted(): boolean {
        return this._highlighted;
    }

    public set highlighted(value: boolean) {
        this._highlighted = !!value;
        this._updateInfobox();
    }

    private _updateInfobox(): void {
        const c = this._highlighted ? '#c40000' : undefined;
        this.infobox.setOptions({
            htmlContent: getVehicleInfoboxHtml(this.id, this._description, c),
        });
    }

    public update(position: VehiclePositionState) {
        if (this.id !== position.vehicleId) {
            console.error(`BPH: invalid update [${this.id}] <> [${position.vehicleId}] `);
        }
        const location = new Microsoft.Maps.Location(position.latitude, position.longitude);
        this.infobox.setLocation(location);
        this._description = `at ${moment.parseZone(position.reportTimeAtz).format('h:mm:ss A')}`;
        this._updateInfobox();
    }
}

export type BphDirectionsState = {
    directions: BphDirectionModel[] | null,
    selectedDirection: BphDirectionModel | null,
};

export interface BphDirection {
    id: string;
    route: string;
    stop: string;
}

export class BphStopModel implements NamedEntity {
    public readonly pushpin: Microsoft.Maps.Pushpin;
    public readonly infobox: Microsoft.Maps.Infobox;

    constructor(
        public readonly displayName: string,
        public readonly stopId: string,
        lat: number,
        lng: number,
        selected = true,
    ) {
        this.pushpin = new Microsoft.Maps.Pushpin(
            new Microsoft.Maps.Location(lat, lng),
            { icon: selected ? busStopSelectedIcon : busStopIcon },
        );
        this.infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(lat, lng),
            { description: `${displayName}`, visible: false },
        );
    }

    public addPushpinHandler(pushpin: Microsoft.Maps.Pushpin, infoboxes: Microsoft.Maps.Infobox[]) {
        const { latitude, longitude } = pushpin.getLocation();
        const selectedInfobox = infoboxes.find(i => {
            const infoboxLocation = i.getLocation();
            return infoboxLocation.latitude === latitude && infoboxLocation.longitude === longitude;
        });
        Microsoft.Maps.Events.addHandler(pushpin, 'mouseover', (_event: Microsoft.Maps.IMouseEventArgs) => {
            infoboxes.forEach(i => i.setOptions({ visible: false }));
            if (selectedInfobox) {
                this.infobox.setOptions({ visible: true });
            }
        });
    }
}

export type BphStopsStateType = {
    stops: BphStopModel[] | null;
    selectedStop: BphStopModel | null;
    loadingSchedule: boolean;
};

export interface BphSliderState {
    maxValue: number;
    sliderValue: number;
}


export enum DateTimeKind {
    UKNOWN = 'UKNOWN',
    UTC = 'UTC',
    AGENCY = 'agency-local'
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export class DateTime {
    private constructor(public readonly kind: DateTimeKind, public readonly value: number) {
    }

    public static fromAgencyDateAndTime(dateTime: Date): DateTime {
        return new DateTime(DateTimeKind.AGENCY, dateTime.valueOf());
    }

    public static fromStringUtcAsAgency(str: string): DateTime {
        const value = moment(str.substr(0, str.length - 1)).toDate().valueOf();
        return new DateTime(DateTimeKind.AGENCY, value);
    }

    public static fromStringAgencyDateTime(strDateTime: string): DateTime {
        const value = moment(strDateTime).toDate().valueOf();
        return new DateTime(DateTimeKind.AGENCY, value);
    }

    public static fromStringAgencyDateAndTime(strDate: string, strTime: string): DateTime {
        const value = moment(`${strDate} ${strTime}`).toDate().valueOf();
        return new DateTime(DateTimeKind.AGENCY, value);
    }

    public toDate(): Date {
        return new Date(this.value);
    }

    public format(fmt: string): string {
        return moment(this.value).format(fmt);
    }

    public compareTo(that: DateTime): number {
        if (this.kind !== that.kind) throw new Error('DateTime: can not compare different kinds');
        return this.value - that.value;
    }

    public static compare(a: DateTime, b: DateTime): number {
        if (a.kind !== b.kind) throw new Error('DateTime object must have the same kind');
        return a.value - b.value;
    }

    public toString() {
        return `${moment(this.value).format('YYYY-MM-DDTHH:mm:ss')} (${this.kind})}`;
    }
}

export interface PreditionData {
    predictionTime: string;
    predictedTime: string;
    actualTime: string;
    scheduledTime: string;
}

export class StopPredictionEntry {
    public readonly tripId: string;
    public readonly vehicleId: string;
    public readonly predictions: PreditionData[];
    public active: PreditionData | null;

    constructor(source: StopPredictionDto) {
        this.tripId = source.tripId;
        this.vehicleId = source.vehicleId;
        this.active = null;
        this.predictions = [];
        this.append(source);
    }

    public append(source: StopPredictionDto) {
        if (this.tripId !== source.tripId || this.vehicleId !== source.vehicleId) {
            throw new Error('Invalid argument: tripId or vehicleId not match.');
        }
        this.predictions.push({
            predictionTime: source.predictionTime,
            predictedTime: source.predictedTime,
            actualTime: source.actualTime,
            scheduledTime: source.scheduledTime,
        });
    }

    /**
     * Finds active entry on specified date and time.
     * @param time date and time string in format "yyyy-MM-ddTHH:mm:ss"
     */
    public updateOnTime(time: string): boolean {
        this.active = null;

        for (let i = this.predictions.length; i > 0;) {
            const pe = this.predictions[--i];
            if (pe.predictionTime >= time) break;
            this.active = pe;
        }
        if (this.active?.actualTime && this.active.actualTime < time)
            this.active = null;
        return this.active !== null;
    }

}

export class StopPredictionsState {
    public readonly error?: ErrorContext;
    public readonly plist?: StopPredictionEntry[];

    constructor(predictionResult: OperationResult<StopPredictionDto[]>) {
        if (!predictionResult.isSuccess) {
            this.error = predictionResult.error;
            return;
        }

        this.plist = [];

        predictionResult.data?.sort((a, b) => {
            let r = a.tripId.localeCompare(a.tripId);
            if (r === 0)
                r = a.vehicleId.localeCompare(b.vehicleId);
            if (r === 0)
                r = -a.predictionTime.localeCompare(b.predictionTime);
            return r;
        });

        const created: Dictionary<StopPredictionEntry> = {};
        for (const e of predictionResult.data) {
            const n = `${e.tripId}-${e.vehicleId}`;
            let entry = created[n];
            if (entry)
                entry.append(e);
            else {
                created[n] = (entry = new StopPredictionEntry(e));
                this.plist.push(entry);
            }
        }
    }

    /**
     * Finds active entry on specified date and time.
     * @param time date and time string in format "yyyy-MM-ddTHH:mm:ss"
     */
    public onTime(time: string): StopPredictionEntry[] {
        const active: StopPredictionEntry[] = [];
        if (this.plist) {
            for (const sp of this.plist) {
                if (sp.updateOnTime(time))
                    active.push(sp);
            }
            active.sort((a, b) => {
                const ta = a.active?.predictedTime || '';
                const tb = b.active?.predictedTime || '';
                return ta.localeCompare(tb);
            });
        }
        return active;
    }

}