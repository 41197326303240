import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export const ModalTextMessage: React.FC<{ text: string, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ text, open, setOpen }) => {
    return (
        <Modal dimmer={'inverted'}
               open={open}>
            <Modal.Header>Message was scheduled</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p style={{ fontSize: 16 }}>
                        {text}
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>OK</Button>
            </Modal.Actions>
        </Modal>
    );
};