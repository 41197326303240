import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { DropdownProps, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import { getRoutes } from '../../../../actions/gtfsStaticActions';
import { getKpiData } from '../../../../actions/otpActions';
import { AppState } from '../../../../reducers/index';
import { getSelectedOrDefaultAgency } from '../../../../selectors/index';
import { GtfsRoute } from '../../../../types/gtfsTypes';
import { KpiData, KpiDetails } from '../../../../types/trendsTypes';
import { DropDownStateType } from '../../../../types/types';
import Utils from '../../../../utilities/utils';
import TrendsReportingBusesChart from '../../trends/TrendsReportingBusesChart';
import TrendsSimpleLineChart from '../../trends/TrendsSimpleLineChart';
import AverageWaitTimeChart from './AverageWaitTimeChart';
import HeadwayDistributionChart from './HeadwayDistributionChart';

interface RoutesDropDownStateType extends DropDownStateType {
    routes: GtfsRoute[],
}

interface KpiDetailsForPeriod {
    period: string,
    kpi: KpiDetails,
}

export interface HeadwayDistribution {
    period: string,
    veryShort: number;
    short: number;
    medium: number;
    long: number;
}

const directionDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};
const routesDropDownState: RoutesDropDownStateType = {
    options: [],
    selectedValue: '',
    routes: [],
};
const displayModeDropDownState: DropDownStateType = {
    options: [
        {
            value: 'Day',
            text: 'Days',
        },
        {
            value: 'Week',
            text: 'Weeks',
        },
        {
            value: 'Month',
            text: 'Months',
        },
    ],
    selectedValue: 'Week',
};

const styles = {
    headwaysDistributionLabel: {
        marginBottom: '34px',
    } as React.CSSProperties,
};

interface Props {
    agency: AgencyStateType | undefined;
}

const StatsHeadwaysForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [routesState, setRoutesState] = useState(routesDropDownState);
    const [directionsState, setDirectionsState] = useState(directionDropDownState);
    const [displayModeState, setDisplayModeState] = useState(displayModeDropDownState);
    const [kpiDataState, setKpiDataState] = useState<KpiData[]>([]);
    const [filteredKpiDetailsState, setFilteredKpiDetailsState] = useState<KpiDetailsForPeriod[]>([]);

    const getPeriodValue = (startDate: Date, endDate: Date, selectedMode: string): string => {
        switch (selectedMode) {
            case 'Day':
                return moment(startDate).format('MMM DD, YYYY');
            case 'Week':
                return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`;
            case 'Month':
                return moment(startDate).format('MMM, YYYY');
            default:
                return '';
        }
    };

    const averageWaitTimeChartData = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.scheduledWaitSec !== undefined && d.kpi.observedWaitSec !== undefined && d.kpi.inferredWaitSec !== undefined)
        .map(kpiDetails => ({
            period: kpiDetails.period as string,
            scheduledWaitSec: kpiDetails.kpi.scheduledWaitSec as number,
            observedWaitSec: kpiDetails.kpi.observedWaitSec as number,
            inferredWaitSec: kpiDetails.kpi.inferredWaitSec as number,
        }));
    const bunchingChartData = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.bunching !== undefined)
        .map(kpiDetails => ({
            period: kpiDetails.period as string,
            value: Utils.roundNumber(kpiDetails.kpi.bunching as number, 1),
        }));
    const reportingBusesChartData = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.gtfsCoverage !== undefined && d.kpi.taipCoverage !== undefined)
        .map(kpiDetails => ({
            period: kpiDetails.period as string,
            gtfsValue: Utils.roundNumber(kpiDetails.kpi.gtfsCoverage as number, 1),
            taipValue: Utils.roundNumber(kpiDetails.kpi.taipCoverage as number, 1),
        }));
    const baseKpiData = kpiDataState.filter(d => d.period === displayModeState.selectedValue as string)
        .map(d => {
            return {
                period: getPeriodValue(d.startDate, d.endDate, displayModeState.selectedValue as string),
                kpi: d.kpi,
            };
        });
    const reportingBusesChart2VersionData = baseKpiData
        .filter(d => d && d.kpi && d.kpi.gtfsVehicles !== undefined && d.kpi.taipVehicles !== undefined)
        .map(kpiDetails => ({
            period: kpiDetails.period as string,
            gtfsValue: Utils.roundNumber(kpiDetails.kpi.gtfsVehicles as number, 1),
            taipValue: Utils.roundNumber(kpiDetails.kpi.taipVehicles as number, 1),
        }));
    const headwayDistributionChartData: HeadwayDistribution[] = filteredKpiDetailsState
        .filter(d => d && d.kpi && d.kpi.veryShortHeadways !== undefined && d.kpi.shortHeadways !== undefined && d.kpi.mediumHeadways !== undefined && d.kpi.longHeadways !== undefined)
        .map(kpiDetails => ({
            period: kpiDetails?.period as string,
            veryShort: kpiDetails?.kpi.veryShortHeadways as number,
            short: kpiDetails?.kpi.shortHeadways as number,
            medium: kpiDetails?.kpi.mediumHeadways as number,
            long: kpiDetails?.kpi.longHeadways as number,
        }));

    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeName }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeName as string,
        }));
        if (routesState.routes.length === 0)
            return;
        const directions = routesState.routes.find(r => r.routeName === routeName)?.directionVariants;
        if (!directions || directions.length === 0) {
            setDirectionsState(directionDropDownState);
        } else {
            setDirectionsState(prevState => ({
                ...prevState,
                selectedValue: '',
                options: directions
                    .sort((a, b) => a.cardinalDirection.localeCompare(b.cardinalDirection))
                    .map(d => ({ value: d.internalDirectionVariantId, text: d.directionVariantName })),
            }));
        }

        const kpiDataForPeriod = kpiDataState.filter(d => d.period === displayModeState.selectedValue as string);
        const kpiDetails: KpiDetailsForPeriod[] = [];
        if (routeName) {
            for (const periodKpi of kpiDataForPeriod) {
                const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, displayModeState.selectedValue as string);
                const kpiForRoute = periodKpi.routes
                    .find(r => r.routeName == routeName)?.kpi;
                if (kpiForRoute)
                    kpiDetails.push({ period: periodValue, kpi: kpiForRoute });
            }
        } else {
            kpiDetails.push(...kpiDataForPeriod.map(d => {
                return {
                    period: getPeriodValue(d.startDate, d.endDate, displayModeState.selectedValue as string),
                    kpi: d.kpi,
                };
            }));
        }
        setFilteredKpiDetailsState(kpiDetails);
    };
    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantInternalId }: DropdownProps) => {
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantInternalId as string,
        }));
        const kpiDataForPeriod = kpiDataState.filter(d => d.period === displayModeState.selectedValue as string);
        const kpiDetails: KpiDetailsForPeriod[] = [];
        if (directionVariantInternalId) {
            for (const periodKpi of kpiDataForPeriod) {
                const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, displayModeState.selectedValue as string);
                const kpiForDirection = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.directions
                    .find(d => d.directionVariantInternalId == directionVariantInternalId)?.kpi;
                if (kpiForDirection)
                    kpiDetails.push({ period: periodValue, kpi: kpiForDirection });
            }
        } else if (routesState.selectedValue) {
            for (const periodKpi of kpiDataForPeriod) {
                const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, displayModeState.selectedValue as string);
                const kpiForRoute = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.kpi;
                if (kpiForRoute)
                    kpiDetails.push({ period: periodValue, kpi: kpiForRoute });
            }
        } else {
            kpiDetails.push(...kpiDataForPeriod.map(d => {
                return {
                    period: getPeriodValue(d.startDate, d.endDate, displayModeState.selectedValue as string),
                    kpi: d.kpi,
                };
            }));
        }
        setFilteredKpiDetailsState(kpiDetails);
    };
    const handleModeChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: displayMode }: DropdownProps) => {
        setDisplayModeState(prevState => ({
            ...prevState,
            selectedValue: displayMode as string,
        }));
        updateKpiDetailsByMode(kpiDataState, displayMode as string);
    };

    const updateKpiDetailsByMode = (kpiData: KpiData[], selectedMode: string) => {
        const kpiDataForPeriod = kpiData.filter(d => d.period === selectedMode);
        const kpiDetails: KpiDetailsForPeriod[] = [];
        for (const periodKpi of kpiDataForPeriod) {
            const periodValue = getPeriodValue(periodKpi.startDate, periodKpi.endDate, selectedMode);
            if (routesState.selectedValue && directionsState.selectedValue) {
                const kpiForDirection = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.directions
                    .find(d => d.directionVariantInternalId == directionsState.selectedValue)?.kpi;
                if (kpiForDirection)
                    kpiDetails.push({ period: periodValue, kpi: kpiForDirection });
            } else if (routesState.selectedValue) {
                const kpiForRoute = periodKpi.routes
                    .find(r => r.routeName == routesState.selectedValue)?.kpi;
                if (kpiForRoute)
                    kpiDetails.push({ period: periodValue, kpi: kpiForRoute });
            } else if (periodKpi.kpi) {
                kpiDetails.push({ period: periodValue, kpi: periodKpi.kpi });
            }
        }
        setFilteredKpiDetailsState(kpiDetails);
    };

    const retrieveReportData = async () => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const kpiData = await getKpiData(agency.id);
                setKpiDataState(kpiData);
                updateKpiDetailsByMode(kpiData, displayModeState.selectedValue as string);
            } catch {
                setKpiDataState([]);
                setFilteredKpiDetailsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setKpiDataState([]);
            setFilteredKpiDetailsState([]);
        }
    };

    useEffect(() => {
        if (agency === undefined) return;
        const fetchRoutes = async () => {
            const date = moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD');
            const routes = await getRoutes(agency.id, date);
            if (routes.length === 0) {
                setRoutesState(routesDropDownState);
                setDirectionsState(directionDropDownState);
                return;
            }
            setRoutesState(prevState => ({
                ...prevState,
                options: routes.map(({ routeName }) => { return { value: routeName, text: routeName }; }),
                routes: routes,
            }));
            await retrieveReportData();
        };
        fetchRoutes();
    }, [agency?.id]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Headways Statistics
                </Header>
                <Form.Group className="inputGroup">
                    <Form.Select
                        placeholder="Choose display mode"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={displayModeState.options}
                        value={displayModeState.selectedValue}
                        onChange={handleModeChange}
                        width={3}
                    />
                    <Form.Select
                        placeholder="Select route"
                        fluid
                        search
                        selection
                        clearable
                        icon={routesState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesState.options}
                        value={routesState.selectedValue}
                        onChange={handleRouteChange}
                        width={3}
                    />
                    {routesState.selectedValue && <Form.Select
                        placeholder="Direction"
                        fluid
                        search
                        selection
                        clearable
                        icon={directionsState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={directionsState.options}
                        value={directionsState.selectedValue}
                        onChange={handleDirectionChange}
                        width={4}
                    />}
                </Form.Group>
                {kpiDataState.length > 0 &&
                    <>
                        <Form.Group className="rowGroup">
                            <Form.Field width={8}>
                            <label className="categoryHeader">Average Waiting Time
                                <div className="categorySubtitle">Bunching is the main factor affecting waiting times. The "observed waiting time" is calculated based on actively reporting buses, while "estimated waiting time" is calculated for the entire fleet, assuming that buses that do not send reports arrived on time.</div>
                            </label>
                                <AverageWaitTimeChart chartData={averageWaitTimeChartData} />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label className="categoryHeader" style={styles.headwaysDistributionLabel}>Headways Distribution
                                    <div className="categorySubtitle">Headway is the time between the arrival of consequent vehicles. The diagram shows the headways distribution: very short (&#60; 5 min), short (5-10 min), medium (10-20 min), long (&#62; 20 min).</div>
                                </label>
                                <HeadwayDistributionChart chartData={headwayDistributionChartData} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={8}>
                                <label className="categoryHeader">Bunching
                                <div className="categorySubtitle">Bunching" is two buses following the same route/direction arriving at the stop at the same time (25% of scheduled headway duration) as opposed to arriving at the scheduled interval. Lower percentage is better. 0% means no bunching.</div>
                                </label>
                                <TrendsSimpleLineChart chartData={bunchingChartData} axesLabelSign="%" dataLabel="Bunching" />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label className="categoryHeader">Reporting Buses
                                <div className="categorySubtitle">Due to various circumstances, some vehicles may not always report real-time data, which affects quality of predictions. The higher the percentage of reporting buses is the better. 100% is an ideal situation.</div>
                                </label>
                                <TrendsReportingBusesChart chartData={ routesState.selectedValue !== '' ? reportingBusesChartData : reportingBusesChart2VersionData } axesLabelSign="%" />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(StatsHeadwaysForm);