import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DelaySort, delayStatusColors, DeviationDistribution } from './models';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            fill: 'origin',
            label: 'Early',
            lineTension: 0.1,
            backgroundColor: delayStatusColors[DelaySort.Early].fill,
            borderColor: delayStatusColors[DelaySort.Early].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: delayStatusColors[DelaySort.Early].lite,
            pointBorderColor: delayStatusColors[DelaySort.Early].dark,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: delayStatusColors[DelaySort.Early].dark,
            pointHoverBorderColor: delayStatusColors[DelaySort.Early].dark,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            fill: '-1',
            label: 'On Time',
            lineTension: 0.1,
            backgroundColor: delayStatusColors[DelaySort.OnTime].fill,
            borderColor: delayStatusColors[DelaySort.OnTime].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: delayStatusColors[DelaySort.OnTime].lite,
            pointBorderColor: delayStatusColors[DelaySort.OnTime].dark,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: delayStatusColors[DelaySort.OnTime].dark,
            pointHoverBorderColor: delayStatusColors[DelaySort.OnTime].dark,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            fill: '-1',
            label: '4 to 10 minutes',
            lineTension: 0.1,
            backgroundColor: delayStatusColors[DelaySort.Late].fill,
            borderColor: delayStatusColors[DelaySort.Late].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: delayStatusColors[DelaySort.Late].lite,
            pointBorderColor: delayStatusColors[DelaySort.Late].dark,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: delayStatusColors[DelaySort.Late].dark,
            pointHoverBorderColor: delayStatusColors[DelaySort.Late].dark,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            fill: '-1',
            label: 'More then 10 minutes',
            lineTension: 0.1,
            backgroundColor: delayStatusColors[DelaySort.VeryLate].fill,
            borderColor: delayStatusColors[DelaySort.VeryLate].base,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: delayStatusColors[DelaySort.VeryLate].base,
            pointBorderColor: delayStatusColors[DelaySort.VeryLate].dark,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: delayStatusColors[DelaySort.VeryLate].dark,
            pointHoverBorderColor: delayStatusColors[DelaySort.VeryLate].dark,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    elements: {
        line: {
            tension: 0.000001,
        },
    },
    scales: {
        xAxes: [{
            autoSkip: false,
            maxRotation: 0,
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                autoSkip: false,
                callback: function (value: string) {
                    return value + ' %';
                },
                stepSize: 1,
                maxTicksLimit: 10,
            },
            stacked: true,
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return tooltipItem[0].xLabel;
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                const lineType = chartSettings.datasets[tooltipItem.datasetIndex].label;
                return `${lineType}: ${Math.round(tooltipItem.yLabel * 10) / 10} %`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                const ds = chartSettings.datasets![tooltipItem.datasetIndex];
                return {
                    borderColor: ds.pointBorderColor,
                    backgroundColor: ds.backgroundColor,
                };
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        filler: {
            propagate: true,
        },
        'samples-filler-analyser': {
            target: 'chart-analyser',
        },
        crosshair: false,
    },
};

const PercentageOfArrivalsChart: React.FC<{ chartData: DeviationDistribution[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [
                {
                    ...prevState.datasets[0],
                    spanGaps: false,
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.early as number,
                        };
                    }),
                },
                {
                    ...prevState.datasets[1],
                    spanGaps: false,
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.onTime as number,
                        };
                    }),
                },
                {
                    ...prevState.datasets[2],
                    spanGaps: false,
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.late as number,
                        };
                    }),
                },
                {
                    ...prevState.datasets[3],
                    spanGaps: false,
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.veryLate as number,
                        };
                    }),
                },
            ],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={options as unknown as ChartOptions}
        />
    );
};

export default PercentageOfArrivalsChart;
