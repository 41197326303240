import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../types/chartTypes';
import { TravelTimeBetweenStops } from '../../../types/runningTimeAnalyticsTypes';
import Utils from '../../../utilities/utils';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Scheduled time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#FF8368',
            borderColor: '#FF8368',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FF8368',
            pointBackgroundColor: '#FF8368',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#FF8368',
            pointHoverBorderColor: '#FF8368',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Average Actual time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#31A6DC',
            borderColor: '#31A6DC',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#31A6DC',
            pointBackgroundColor: '#31A6DC',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#31A6DC',
            pointHoverBorderColor: '#31A6DC',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'day',
                unitStepSize: 1,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                callback: function (value: string) {
                    return `${Utils.roundNumber((value as unknown as number) / 60, 1)} min`;
                },
                stepSize: 60,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return moment(tooltipItem[0].xLabel).format('YYYY-MM-DD');
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        crosshair: false,
    },
};

const TravelTimeBetweenStopsChart: React.FC<{ chartData: TravelTimeBetweenStops[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(p => {
                    return {
                        t: moment(p.serviceDate).format('YYYY-MM-DD'),
                        y: p.scheduledSec,
                    };
                }),
            },
            {
                ...prevState.datasets[1], data: chartData.map(p => {
                    return {
                        t: moment(p.serviceDate).format('YYYY-MM-DD'),
                        y: p.actualSec,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    ...prevState.tooltips.callbacks,
                    label: function ({ datasetIndex, index }: { datasetIndex: number, xLabel: string, yLabel: number, index: number }) {
                        let lineType = 'Scheduled';
                        let value = chartData.map(d => d.scheduledSec)[index];
                        if (datasetIndex === 1) {
                            lineType = 'Average Actual';
                            value = chartData.map(d => d.actualSec)[index];
                        }
                        return `${lineType}: ${Utils.convertSecondsToMinutes(value, 'm', 's')}`;
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState as ChartData}
            options={optionsState as ChartOptions}
            height={70}
        />
    );
};

export default TravelTimeBetweenStopsChart;