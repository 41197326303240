import React from 'react';
import { SpeedRangeSet } from './_models';
import { StopsDetailsLevel } from './_stops-details-level';
import { TreckDetailsLevels } from './_treck-details-level';
import ColorsEditorModal from './ColorsEditorModal';
import { SpeedMapContext } from './SpeedMapContext';

function getRows(ranges: SpeedRangeSet) {
    const result = [];
    const count = ranges.lenght;
    for (let ix = 0; ix < count; ++ix) {
        const sr = ranges.dir(ix);
        const last = ix === count - 1;
        const nextMax: number = last ? 0 : ranges.dir(ix + 1).maxValue;
        result.push(
            <li key={ix}>
                <div className="speed-key" style={{ backgroundColor: sr.stroke.color }}></div>
                <span>
                    {nextMax > 0
                        ? (<>{sr.maxValue} &ndash; {nextMax}</>)
                        : `${sr.maxValue}+`
                    }
                </span>
            </li>,
        );
    }
    return result.reverse();
}

export default function SpeedMapLegend() {
    const context = React.useContext(SpeedMapContext);
    return (
        <div className="avg-speed-legend">
            <h4>All Day Min. Speed (mph)</h4>
            <ul>{getRows(context.speedRangeSet)}</ul>
            <div style={{ textAlign: 'center' }}>
                <ColorsEditorModal />
                {(!!context.treckDetailsLevel) && (
                    <div style={{ borderTop: '1px solid silver', marginTop: '1rem', paddingTop: '0.5rem', marginBottom: '-0.5rem' }} >
                        <small>Averaging Distance: {TreckDetailsLevels[context.treckDetailsLevel].displayName}</small>
                    </div>)}
                {((context.stops.items) && context.stopsDetailsLevel === StopsDetailsLevel.L0) && (
                    <div style={{ borderTop: '1px solid silver', marginTop: '1rem', paddingTop: '0.5rem', marginBottom: '-0.5rem' }} >
                        <small>Please zoom in to view stops</small>
                    </div>)}
            </div>
        </div>
    );
}