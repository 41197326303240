import './PredictedArrivalReportStyles.css';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, DropdownProps, Form, Header, Icon, Popup } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getPredictionAccuracyForRoute, getPredictionArrivalTimeReport } from '../../../actions/delaysAnalysisActions';
import { getRoutes, getTripsForDateAndDirectionVariant } from '../../../actions/gtfsStaticActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { TopWrongPredictingRoute } from '../../../types/anomaliesTypes';
import { History, Linear, PredictedArrivalTimeReport, PredictionArrivalChartData, PredictionModel, Siri } from '../../../types/delaysAnalysisTypes';
import { GtfsRoute } from '../../../types/gtfsTypes';
import { PredictionAccuracyValue } from '../../../types/predictionAccuracyTypes';
import { DropDownStateType, KeyValuePair } from '../../../types/types';
import IOSSliderControl from '../../shared/IOSSliderControl';
import AccuracyForRouteChart from './AccuracyForRouteChart';
import PredictedArrivalReportChart from './PredictedArrivalReportChart';

interface RoutesDropDownStateType extends DropDownStateType {
    routes: GtfsRoute[],
}
const routesDropDownState: RoutesDropDownStateType = {
    options: [],
    selectedValue: '',
    routes: [],
};
const directionDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};
const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};
const predictionModelDropDownState: DropDownStateType = {
    options: [
        {
            value: History,
            text: 'Adaptive',
        },
        {
            value: Linear,
            text: 'Linear',
        },
        {
            value: Siri,
            text: 'Legacy',
        }],
    selectedValue: History,
};

interface SliderValue {
    value: number;
    label: string;
    predictionTime: string;
}

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    slider: {
        width: '99%',
    } as React.CSSProperties,
};

const PredictedArrivalReportForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).format('YYYY-MM-DD'));
    const [loadingRoutesState, setLoadingRoutesState] = useState(false);
    const [routesState, setRoutesState] = useState(routesDropDownState);
    const [directionsState, setDirectionsState] = useState(directionDropDownState);
    const [tripsState, setTripsState] = useState(initialDropDownState);
    const [modelState, setModelState] = useState(predictionModelDropDownState);
    const [predictedArrivalReportState, setPredictedArrivalReportState] = useState<PredictedArrivalTimeReport[]>([]);
    const [sliderDataState, setSliderDataState] = useState<SliderValue[]>([]);
    const [sliderValueState, setSliderValueState] = useState<number>(0);
    const [chartDataState, setChartDataState] = useState<PredictionArrivalChartData | undefined>(undefined);
    const [predictionAccuracyReportState, setPredictionAccuracyReportState] = useState<KeyValuePair<string, PredictionAccuracyValue>[]>([]);

    const [loadedRouteState, setLoadedRouteState] = useState(false);
    const [loadedDirectionState, setLoadedDirectionState] = useState(false);

    const loadPageParameters = () : TopWrongPredictingRoute => {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        const dateReport = queryParameters.get('dateReport') ?? '';
        const routeId = queryParameters.get('routeId') ?? '';
        const directionId = queryParameters.get('directionId') ?? '';

        if(routeId === '') return { } as TopWrongPredictingRoute;

        return {
            routeId: routeId,
            directionId: directionId,
            dateReport: dateReport,
        } as TopWrongPredictingRoute;
    };
    const [pageLoadParameters] = useState<TopWrongPredictingRoute>(loadPageParameters());

    const routeName = routesState.options.find(o => o.value === routesState.selectedValue)?.text as string;
    const directionName = directionsState.options.find(o => o.value === directionsState.selectedValue)?.text as string;

    const loadRoutesInfo = async (agencyId: string, date: string) => {
        try {
            setLoadingRoutesState(true);
            setRoutesState(routesDropDownState);
            const serviceDate = moment(date).format('YYYY-MM-DD');
            const routes = await getRoutes(agencyId, serviceDate);
            if (routes.length === 0) {
                return;
            } else {
                setRoutesState({
                    options: routes.map(s => { return { value: s.routeId, text: s.routeName }; }),
                    selectedValue: undefined,
                    routes: routes,
                });
                setLoadedRouteState(true);
            }
            setDirectionsState(directionDropDownState);
            setTripsState(initialDropDownState);
        }
        finally {
            setLoadingRoutesState(false);
        }
    };

    const handleDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        if (!agency || !agency.id)
            return;
        setSelectedDateState(value);
        await loadRoutesInfo(agency.id, value);
    };

    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeId }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeId as string,
        }));
        setTripsState(initialDropDownState);
        if (routesState.routes.length === 0)
            return;
        const routeName = routesState.options.find(o => o.value === routeId)?.text as string;
        const directions = routesState.routes.find(r => r.routeName === routeName)?.directionVariants;
        if (!directions || directions.length === 0) {
            setDirectionsState(directionDropDownState);
        } else {
            setDirectionsState(prevState => ({
                ...prevState,
                selectedValue: '',
                options: directions
                    .sort((a, b) => a.cardinalDirection.localeCompare(b.cardinalDirection))
                    .map(d => ({ value: d.directionVariantId, text: d.directionVariantName })),
            }));
            setLoadedDirectionState(true);
        }
    };
    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantId }: DropdownProps) => {
        if (!agency || !agency.id)
            return;
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantId as string,
        }));
        const tripDate = moment(selectedDateState).format('YYYY-MM-DD');
        const selectedRoute = routesState.selectedValue;
        if (selectedRoute && directionVariantId) {
            const trips = await getTripsForDateAndDirectionVariant({ agencyId: agency.id, tripDate, routeId: selectedRoute, directionVariantId: directionVariantId as string });
            setTripsState({
                options: trips.map(t => { return { value: t.internalTripId, text: t.shortTripId }; }),
                selectedValue: undefined,
            });
        }
    };
    const handleTripChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value: tripId }: DropdownProps) => {
        setTripsState(prevState => ({
            ...prevState,
            selectedValue: tripId as string,
        }));
    };
    const handlePredictionModelChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value: predictionModel }: DropdownProps) => {
        setModelState(prevState => ({
            ...prevState,
            selectedValue: predictionModel as string,
        }));
    };
    const handlePredictionSliderChange = (_e: React.ChangeEvent<{}>, value: number | number[]) => {
        const sliderIndex = value as number;
        setSliderValueState(sliderIndex);
        updateChartData(sliderIndex, sliderDataState, predictedArrivalReportState);
    };
    const updateChartData = (sliderIndex: number, sliderData: SliderValue[], report: PredictedArrivalTimeReport[]) => {
        if (sliderData.length !== 0 && report.length !== 0) {
            const sliderValue = sliderData.find(s => s.value === sliderIndex);
            if (sliderValue) {
                const predictionTime = sliderValue.predictionTime;
                const reportForTime = report.find(r => r.predictionTime === predictionTime);
                if (reportForTime)
                    setChartDataState({
                        tripId: reportForTime.tripId,
                        arrivalData: reportForTime.arrivalData,
                        accuracy: reportForTime.accuracy,
                        predictionTime: sliderValue.label,
                    });
            }
        } else {
            setChartDataState(undefined);
        }
    };
    const handleApplyClick = async () => {
        if (!agency || !agency.id)
            return;
        const serviceDate = moment(selectedDateState).format('YYYY-MM-DD');
        const predictionModel = modelState.selectedValue as PredictionModel;
        if (!predictionModel)
            return;
        const tripId = tripsState.selectedValue;
        if (tripId) {
            updatePredictionArrivalReport(agency.id, serviceDate, tripId, predictionModel);
        } else {
            updateAccuracyForRoute(agency.id, serviceDate, predictionModel);
        }
    };

    const updatePredictionArrivalReport = async (agencyId: string, serviceDate: string, tripId: string, predictionModel: PredictionModel) => {
        setPredictionAccuracyReportState([]);
        setFormBlockingState(true);
        try {
            const predictedArrivalReport = await getPredictionArrivalTimeReport({ agencyId, serviceDate, tripId, modelName: predictionModel });
            const sliderData: SliderValue[] = predictedArrivalReport.map((p, index) => {
                return {
                    value: index, predictionTime: p.predictionTime, label: moment.parseZone(p.predictionTime).format('hh:mm A'),
                };
            });
            setPredictedArrivalReportState(predictedArrivalReport);
            setSliderDataState(sliderData);
            updateChartData(0, sliderData, predictedArrivalReport);
        } catch {
            setPredictedArrivalReportState([]);
            setSliderDataState([]);
            setChartDataState(undefined);
        } finally {
            setFormBlockingState(false);
        }
    };

    const updateAccuracyForRoute = async (agencyId: string, serviceDate: string, predictionModel: PredictionModel) => {
        setPredictedArrivalReportState([]);
        setSliderDataState([]);
        setChartDataState(undefined);
        const routeName = routesState.options.find(o => o.value === routesState.selectedValue)?.text as string;
        if (!routeName) {
            setPredictionAccuracyReportState([]);
            return;
        }
        setFormBlockingState(true);
        try {
            const accuracyReport = await getPredictionAccuracyForRoute(agencyId, serviceDate, predictionModel, routeName, pageLoadParameters.directionId ?? directionsState.selectedValue);
            setPredictionAccuracyReportState(accuracyReport);
        } catch {
            setPredictionAccuracyReportState([]);
        } finally {
            setFormBlockingState(false);
        }
    };

    useEffect(() => {
        if (!agency || !agency.id)
            return;
    }, [agency?.id]);

    useEffect(() => {
        if (pageLoadParameters.dateReport !== '') {
            routesDropDownState.selectedValue = pageLoadParameters.routeId;
            directionDropDownState.selectedValue = pageLoadParameters.directionId;
        }
        if (!agency || !agency.id)
            return;
        setSelectedDateState(pageLoadParameters.dateReport);
        loadRoutesInfo(agency.id, pageLoadParameters.dateReport);
    }, [pageLoadParameters]);

    useEffect(() => {
            if(pageLoadParameters.routeId !== '')
                handleRouteChange({} as React.SyntheticEvent<HTMLElement, Event>, { value: pageLoadParameters.routeId });
    }, [loadedRouteState]);

    useEffect(() => {
            if (pageLoadParameters.directionId !== '')
                handleDirectionChange({} as React.SyntheticEvent<HTMLElement, Event>, { value: pageLoadParameters.directionId })
                    .then(_ => handleApplyClick());
    }, [loadedDirectionState]);

    return (
        <BlockUi tag="div" blocking={formBlockingState} className="predicted-arrival-report">
            <Header as="h1" className="reportHeader">
                Prediction of Arrivals
            </Header>
            <Form>
                <Form.Group inline className="params inputGroup">
                    <DateInput
                        className="date"
                        name="businessDate"
                        fluid
                        dateFormat="YYYY-MM-DD"
                        placeholder="Select date"
                        value={selectedDateState}
                        iconPosition="left"
                        popupPosition="bottom center"
                        closable={true}
                        animation="fade"
                        onChange={handleDateChange}
                    />
                    <Form.Select
                        className="route"
                        loading={loadingRoutesState}
                        placeholder="Select Route"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesState.options}
                        value={routesState.selectedValue}
                        onChange={handleRouteChange}
                    />
                    <Form.Select
                        className="direction"
                        placeholder="Direction"
                        fluid
                        search
                        selection
                        clearable
                        icon={directionsState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={directionsState.options}
                        value={directionsState.selectedValue}
                        onChange={handleDirectionChange}
                    />
                    <Form.Select
                        className="trip"
                        placeholder="Trip"
                        fluid
                        search
                        selection
                        clearable
                        icon={tripsState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={tripsState.options}
                        value={tripsState.selectedValue}
                        onChange={handleTripChange}
                    />
                    <Form.Select
                        className="model"
                        placeholder="Prediction Model"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={modelState.options}
                        value={modelState.selectedValue}
                        onChange={handlePredictionModelChange}
                    />
                    <Form.Field className="commands">
                        <Button
                            content="Apply"
                            onClick={handleApplyClick}
                            className="primaryButton"
                        />
                    </Form.Field>
                </Form.Group>
                {chartDataState && predictionAccuracyReportState.length === 0 &&
                    <>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label className="categoryHeader">
                                    <div className="categorySubtitle">Move slider to see predictions made at the selected time</div>
                                </label>
                                <IOSSliderControl
                                    aria-label="ios slider"
                                    valueLabelDisplay="off"
                                    name="predictionSlider"
                                    onChange={handlePredictionSliderChange}
                                    min={Math.min(...sliderDataState.map(s => s.value))}
                                    max={Math.max(...sliderDataState.map(s => s.value))}
                                    step={null}
                                    value={sliderValueState}
                                    marks={sliderDataState}
                                    style={styles.slider}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label className="categoryHeader">Actual vs predicted arrival time for trip #{chartDataState.tripId}</label>
                                <PredictedArrivalReportChart chartData={chartDataState} />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
                {predictionAccuracyReportState.length > 0 && !chartDataState &&
                    <Form.Group>
                        <Form.Field width={16}>
                            <label className="categoryHeader">
                                <Popup
                                    trigger={<Icon color="blue" name="help circle" />}
                                >
                                    <Popup.Content>
                                        Accuracy is the percentage of accurate predictions that is estimated by comparing predicted vs actual arrival times.
                                        The prediction is assumed to be accurate depending on range (min from prediction to arrival).
                                        <ul>
                                            <li>For the range &le; 3 min, the prediction is accurate when arrival is within +/- 1 min of predicted. </li>
                                            <li>For &le; 6 min, actual arrival should be not earlier 1.5 min and not later 2 min of predicted. </li>
                                            <li>For &le; 12 min, not earlier 2 min and not later 3 min. </li>
                                            <li>For &le; 30, not earlier 2.5 min and not later 4 min. </li>
                                        </ul>
                                        Longer range predictions are not involved in calculating accuracy.
                                    </Popup.Content>
                                </Popup>
                                Prediction Accuracy report for route {routeName} {directionName ? `and direction ${directionName}` : ''}
                            </label>
                            <AccuracyForRouteChart
                                chartData={predictionAccuracyReportState}
                                modelName={modelState.options.find(option => option.value === modelState.selectedValue)?.text as string || ''}
                            />
                        </Form.Field>
                    </Form.Group>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(PredictedArrivalReportForm);