import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { AppState } from '../../reducers';
import { isLogged } from '../../selectors';
import { loginPathPrefix } from './routes';

const PrivateRoute: React.FC<{ isLogged: boolean; } & RouteProps> = ({ isLogged, children, component, ...routeProps }) => 
    isLogged
        ? (
            <Route {...routeProps} component={component}>
                {children}
            </Route>
        )
        : (
            <Route {...routeProps}>
                <Redirect to={loginPathPrefix + window.location.pathname} />
            </Route>
        );

export default connect(
    (state: AppState) => ({ isLogged: isLogged(state) }),
)(PrivateRoute);
