import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TripStopData } from '../../../../types/busHistoryTypes';

interface TableProps {
    rows: TripStopData[];
    selectedRow: TripStopData | undefined;
    setSelectedRow: (row: TripStopData) => void;
}

const styles = {
    table: {
        display: 'block',
        maxHeight: '350px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

const StopsScheduleTable: React.FC<TableProps> = ({ rows, selectedRow, setSelectedRow }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Stop<br />Id</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Stop<br />Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" width={1}>Scheduled<br />Time</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <StopsScheduleTableRow key={index} row={row} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

interface RowProps {
    row: TripStopData;
    selectedRow: TripStopData | undefined;
    setSelectedRow: (row: TripStopData) => void;
}

const StopsScheduleTableRow: React.FC<RowProps> = ({ row, selectedRow, setSelectedRow }) => {
    return (
        <Table.Row
            onClick={() => setSelectedRow(row)}
            active={selectedRow === row}
        >
            <Table.Cell>
                {row.stopId}
            </Table.Cell>
            <Table.Cell>
                {row.stopName}
            </Table.Cell>
            <Table.Cell textAlign="right">
                {moment.parseZone(row.scheduledTime).format('h:mm A')}
            </Table.Cell>
        </Table.Row>
    );
};

export default StopsScheduleTable;
