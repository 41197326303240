import { PolylineProps } from '../../../types/BingMapProps';
import { ShapePoint } from '../../../types/gtfsTypes';
import { TransitMeansOverall } from '../../../types/runningTimeAnalyticsTypes';

interface ColorSegment {
    traveledKm: number;
    color: string;
}
interface ShapeSegment {
    ssFromPoint: CoordinatePair;
    ssToPoint: CoordinatePair;
    ssFromKm: number;
    ssToKm: number;
    ssLengthKm: number;
}

export function buildPolylines(transitMeansOverall: TransitMeansOverall[], shapePoints: ShapePoint[]): PolylineProps[] {
    const polylines: PolylineProps[] = [];
    const colorSegments = transitMeansOverall.sort((a, b) => a.stopSequence - b.stopSequence).map<ColorSegment>(({ avgTimeCoeffPcnt, traveledKm }) => {
        let color = 'rgb(37, 170, 225)';
        if (avgTimeCoeffPcnt >= -30 && avgTimeCoeffPcnt < 30)
            color = 'rgb(0, 128, 0)';
        else if (avgTimeCoeffPcnt >= 30 && avgTimeCoeffPcnt <= 50)
            color = 'rgb(245, 225, 7)';
        else if (avgTimeCoeffPcnt > 50)
            color = 'rgb(255, 0, 0)';
        return {
            traveledKm,
            color,
        };
    });
    const orderedPoints = shapePoints
        .sort((a, b) => a.pointSequence - b.pointSequence)
        .map(({ longitude, latitude, traveledKm }) => ({ longitude, latitude, traveledKm }));

    /**
     * Returns shape segment properties by index
     * @param index
     * @returns 
     *  # ssFromPoint   - segment starting point [lat, lon]
        # ssToPoint     - segment finishing point [lat, lon]
        # ssFromKm  - distance from shape start to the starting point
        # ssToKm    - distance from shape start to the finishing point
        # ssLengthKm   - segment length in km
     */
    const getShapeSegment = (index: number): ShapeSegment | undefined => {
        const currentPoint = orderedPoints[index];
        const nextPoint = orderedPoints[index + 1];
        return currentPoint && nextPoint
            ? {
                ssFromPoint: [currentPoint.latitude, currentPoint.longitude],
                ssToPoint: [nextPoint.latitude, nextPoint.longitude],
                ssFromKm: currentPoint.traveledKm,
                ssToKm: nextPoint.traveledKm,
                ssLengthKm: nextPoint.traveledKm - currentPoint.traveledKm,
            }
            : undefined;
    };
    let ssIndex = 0; //current shape segment index
    let shapeSegment = getShapeSegment(ssIndex);
    if (!shapeSegment) {
        return polylines;
    }
    let { ssFromPoint, ssToPoint, ssFromKm, ssToKm, ssLengthKm } = shapeSegment;
    //for all color segments
    for (let index = 0; index < colorSegments.length - 1; index++) {
        const currentSegment = colorSegments[index];
        if (!currentSegment)
            continue;
        const nextSegment = colorSegments[index + 1];
        if (!nextSegment)
            continue;
        //current color and curret segment to traveled km
        const color = nextSegment.color;
        const csToKm = nextSegment.traveledKm;
        //while whole shape segment is in color segment
        while (ssToKm <= csToKm) {
            //draw it
            polylines.push({
                locations: [ssFromPoint, ssToPoint],
                options: {
                    strokeColor: color,
                    strokeThickness: 5,
                },
            });
            //take the next shape segment
            ssIndex++;
            if (ssIndex === orderedPoints.length - 1)
                break;
            shapeSegment = getShapeSegment(ssIndex);
            if (!shapeSegment)
                break;
            ({ ssFromPoint, ssToPoint, ssFromKm, ssToKm, ssLengthKm } = shapeSegment);
        }
        //when not whole shape segment in color segment, we need to draw only part of it
        if (ssIndex < orderedPoints.length - 1 && csToKm < ssToKm) {
            const toDrawKm = csToKm - ssFromKm;
            //how many percents of the shape segment we need to draw
            const pcnt = toDrawKm / ssLengthKm;
            const [fromLatitude, fromLongitude] = ssFromPoint;
            const [toLatitude, toLongitude] = ssToPoint;
            const point: CoordinatePair = [fromLatitude + pcnt * (toLatitude - fromLatitude) as Latitude,
            fromLongitude + pcnt * (toLongitude - fromLongitude) as Longitude];
            //draw it
            polylines.push({
                locations: [ssFromPoint, point],
                options: {
                    strokeColor: color,
                    strokeThickness: 5,
                },
            });
            //reduce shape segment and move to the next color segment
            ssFromPoint = point;
            ssFromKm += toDrawKm;
            ssLengthKm -= toDrawKm;
        }
    }
    return polylines;
}