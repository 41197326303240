import moment from 'moment';
import { List, Menu } from 'semantic-ui-react';
import { VehiclePositionDto } from '../../../../types/busHistoryTypes';
import Utils from '../../../../utilities/utils';
import { SecondsIntervalOptions, secondsIntervalToString } from '../../../bushistory/_models';


interface Props {
    vehiclePosition: VehiclePositionDto;
    bunchVehicleId: string;
}

function ValueItem(props: { label?: string, header?: string, value?: string | null }) {
    return (
        <List.Item>
            <List.Content>
                <div className="value">{props.value ? props.value : <>&mdash;</>}</div>
                {!props.header
                    ? null :
                    <List.Header>{props.header}</List.Header>
                }
                {!props.label
                    ? null :
                    <List.Description>{props.label}</List.Description>
                }
            </List.Content>
        </List.Item>
    );
}

const headwayIntervalOptions: SecondsIntervalOptions = {
    negativeSign: ' ahead',
    positiveSign: ' behind',
    position: 'after',
};

export default function BusBunchingSliderInfo({ vehiclePosition: { vehicleTime, minutesToAtz, routeShortName, vehicleId, tripId, delaySec, headwaySec, behindSec, nextVehicleId, prevVehicleId }, bunchVehicleId }: Props) {
    const delayValue = typeof delaySec !== 'number'
        ? null
        : secondsIntervalToString(delaySec);
    const headwayValue = typeof headwaySec !== 'number'
        ? null
        : secondsIntervalToString(headwaySec, headwayIntervalOptions);
    const behindValue = typeof behindSec !== 'number'
        ? null
        : `-${secondsIntervalToString(behindSec, headwayIntervalOptions)}`;
    return (
        <>
            <div className="sliderTime">
                <div>Time: <b>{moment(Utils.utcToLocal(vehicleTime, minutesToAtz)).format('hh:mm a')}</b></div>
            </div>
            <Menu>
                <List divided size="large" className="bunchingSliderInfo">
                    <ValueItem label="Route" value={routeShortName} />
                    <ValueItem label="Vehicle" value={vehicleId} />
                    <ValueItem label="TripId" value={tripId} />
                    <ValueItem label="Delay from schedule"
                        value={delayValue}
                    />
                    <List.Item>
                        <List.Content>
                            <List.Header>Headways</List.Header>
                        </List.Content>
                    </List.Item>
                    {nextVehicleId === bunchVehicleId && <ValueItem header={nextVehicleId}
                        value={nextVehicleId && headwayValue !== null ? headwayValue : undefined}
                    />}
                    {prevVehicleId === bunchVehicleId && <ValueItem header={prevVehicleId}
                        value={prevVehicleId && behindValue !== null ? behindValue : undefined}
                    />}
                </List>
            </Menu>
        </>
    );
}
