import ReactDOMServer from 'react-dom/server';

interface VehicleInfoboxProps {
    vehicleId: string;
    description: string;
    cssColor?: string;
}

function VehicleInfobox(props: VehicleInfoboxProps) {
    const cssColor = props.cssColor || 'rgb(0, 64, 128)';
    return (
        <div className="bus-pushpin" style={{ borderColor: cssColor }}>
            <div className="bus-pinpoint"></div>
            <div className="bus-offset">
                <div className="bus-icon" style={{ backgroundColor: cssColor }}></div>
                <div className="bus-name"><strong>{props.vehicleId}</strong><br /><small>{!props.description ? (<>&mdash;</>) : props.description}</small></div>
                {/* <div className='bus-arrow'></div> */}
            </div>
        </div>
    );
}

export function getVehicleInfoboxHtml(vehicleId: string, description: string, cssColor?: string) {
    return ReactDOMServer.renderToStaticMarkup(
        <VehicleInfobox
            vehicleId={vehicleId}
            description={description}
            cssColor={cssColor}
        />);
}


