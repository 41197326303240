import * as React from 'react';
import { Table } from 'semantic-ui-react';
import Utils from '../../../../utilities/utils';
import { StopDelayMetric } from './StopDelaysForm';

interface Props {
    row: StopDelayMetric,
    selectedRow: StopDelayMetric | undefined,
    selectedRowHandler: (selectedRow: StopDelayMetric) => void;
}


const StopTableRow: React.FC<Props> = ({ row, selectedRow, selectedRowHandler  }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row);
    };
    const isActive = (selectedRow: StopDelayMetric | undefined) => {
        return selectedRow && selectedRow.stopId === row.stopId;
    };

    return (
        <Table.Row onClick={handleRowClick} active={isActive(selectedRow)}>
            <Table.Cell textAlign="left">
                {row.stopId}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.stopName} 
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.totalArrivals}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {Utils.convertSecondsToMinutes(row.meanDelay, 'm', 's')}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.earlyPercentage} %
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.onTimePercentage} %
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.latePercentage} %
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.veryLatePercentage} %
            </Table.Cell>
        </Table.Row>
    );
};

export default StopTableRow;