import { AgenciesActionTypes, AgencyStateType, LOAD_AGENCIES_SUCCESS, SELECT_AGENCY } from '../actions/actionTypes';

export function agencyReducer(state = '', action: AgenciesActionTypes): string {
    if (action.type === SELECT_AGENCY) {
        return action.agencyId;
    } else {
        return state;
    }
}

export function agenciesReducer(
    state: AgencyStateType[] = [],
    action: AgenciesActionTypes,
): AgencyStateType[] {
    switch (action.type) {
        case LOAD_AGENCIES_SUCCESS:
            return action.agencies;
        default:
            return state;
    }
}