export enum RtDelaysNowInterval {
    m05 = 5,
    m10 = 10,
    m30 = 30,
    m60 = 60,
}

export interface RtDelaysNow {
    timePeriod: string; // UTC date+time
    reportTime: string; // UTC date+time
    vehicleId: string;
    vehicleTime: string; // UTC date+time
    routeShortName: string;
    tripId: string;
    shapeId: string;
    directionVariantInternalId: string;
    otsTripShortName: string;
    tripHeadsign: string;
    tripScheduledStart: string; // UTC date+time
    tripScheduledFinish: string; // UTC date+time
    longitude?: number;
    latitude?: number;
    delaySec?: number
}
