import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { VehicleHeadwaysInfoDto } from './_dto';
import { IRouteStop, IRouteVariant, IRouteVehicle, Thresholds } from './_interfaces';
import { getProblemKind } from './_models';
import { GetDurationStringFromSeconds, toTitleCase } from './_utils';
import { LiveMapContext } from './LiveMapContext';
import TimeInterval from './TimeInteval';

function getDirectionClasses(route: IRouteVariant): string[] {
    const result: string[] = [];
    for (const d of route.cardinalDirection) {
        if (d.indexOf('west') !== -1)
            result.push('west');
        if (d.indexOf('south') !== -1)
            result.push('south');
    }
    return result;
}

function getStyleByDistance(directionClasses: string[], routeDistance: number, distance: number): React.CSSProperties {
    const positionY = 0;
    const positionX = (2 + 96 * (distance / routeDistance));
    // if (positionX < 0) {
    //     positionY = -positionX;
    //     positionX = 0;
    // }
    // else if (positionX > 100) {
    //     positionY = -(positionX - 100);
    //     positionX = 100;
    // }
    const style: React.CSSProperties = {};
    if (directionClasses.findIndex(s => s === 'west') >= 0) {
        style.right = positionX + '%';
    } else {
        style.left = positionX + '%';
    }
    if (positionY !== 0) {
        style.top = 50 + (positionY / 2);
    }
    return style;
}

function StopView(props: { stop: IRouteStop }) {
    const { stop } = props;
    const context = React.useContext(LiveMapContext);

    const selected = context.selectedStop === stop;
    const handleClick = () => context.setSelectedStop(selected ? null : stop);

    const dirClasses = getDirectionClasses(stop.route);
    const style = getStyleByDistance(dirClasses, stop.route.distance, stop.distance);
    style.borderColor = props.stop.route.cssColor;

    return (
        <div
            className="stop-positioner"
            style={style}
            onClick={handleClick}
        >
            <div
                className={`route-${stop.route.id} stop-pushpin ${dirClasses}`}
                style={{ backgroundColor: props.stop.route.cssColor }}
            ></div>
            {!selected
                ? null :
                <>
                    <div className={`route-${stop.route.id} pushpin stop-infobox ${dirClasses}`}>
                        <div className="stop-info">
                            <h4>{toTitleCase(stop.name)}</h4>
                            <h5>{stop.route.name}</h5>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

function getCssRotation(vehicle: IRouteVehicle): React.CSSProperties {
    let heading: number;
    if (vehicle.route.cardinalDirection.indexOf('east') !== -1) {
        heading = 90;
    }
    else {
        heading = -90;
    }
    return { transform: `rotate(${heading} + 'deg)` };
}

const emptyStyle: React.CSSProperties = {};
const nextBusStyle: React.CSSProperties = { backgroundColor: 'lightgrey' };

function VehiclePushpin(props: { vehicle: IRouteVehicle }) {
    const context = React.useContext(LiveMapContext);
    const cssRouteColor = props.vehicle.route.cssColor;
    const dirClasses = getDirectionClasses(props.vehicle.route);
    const classes = [
        props.vehicle.route.id,
        'variant-' + props.vehicle.route.variant,
        'bus-pushpin',
        ...dirClasses,
    ];
    if (props.vehicle.scheduled)
        classes.push('scheduled');

    if (props.vehicle.hasProblem(context.headwayThresholds))
        classes.push('headway-problem');

    const delayText = props.vehicle.delaySeconds <= 59
        ? `On time`
        : `Delay ${GetDurationStringFromSeconds(props.vehicle.delaySeconds)}`;
    return (
        <div
            className={`route-${classes.join(' ')}`}
            style={{ borderColor: cssRouteColor }}
        >
            <div className="bus-pinpoint"></div>
            <div className="bus-offset">
                <div className="bus-icon" style={{ backgroundColor: cssRouteColor }}></div>
                <div className="bus-name" style={props.vehicle.isNextBus ? nextBusStyle : emptyStyle}><strong>{props.vehicle.name}</strong><br /><em>{delayText}</em></div>
                <div className="bus-arrow" style={getCssRotation(props.vehicle)}></div>
            </div>
        </div>
    );
}

interface HeadwayInfo {
    secondsReal: number;
    vehicleId: string;
}

function HeadwayInfo(props?: {
    cardinalDirection: string[],
    problem: 'error' | 'warn' | null | undefined,
    name: 'Ahead' | 'Behind',
    data: HeadwayInfo
}) {
    if (!props) return null;
    const classes = [...props.cardinalDirection, props.name];
    if (props.problem) {
        classes.push('headway-problem');
        classes.push('headway-problem-' + props.problem);
    }
    return (
        <dl className={classes.join(' ')}>
            <dt>{props.name}</dt>
            <dd><TimeInterval seconds={props.data.secondsReal} /><br />
                <Icon name="bus" style={{ width: 12, height: 14 }} />
                <b>{props.data.vehicleId}</b>
            </dd>
        </dl>
    );
}

function getHeadwayProblemKind(thresholds: Thresholds, headway: VehicleHeadwaysInfoDto | null | undefined) {
    return headway && getProblemKind(thresholds, headway.secondsReal);
}

function VehicleInfobox(props: { vehicle: IRouteVehicle }) {
    const context = React.useContext(LiveMapContext);
    const data = props.vehicle;
    const cssColor = data.route.cssColor;
    const classes = [
        `route-${data.route.id}`,
        'bus-infobox', /* FIXME: class='bus-infobox' is bus-specific */
        ...data.route.cardinalDirection,
    ];
    if (data.scheduled) {
        classes.push('scheduled');
    }
    return (
        <div className={classes.join(' ')} style={{ borderColor: cssColor }}>
            <div className="bus-offset">
                <div className="bus-info">
                    <div className="endpoints">
                        <div className="bus-headsign">
                            <div style={{ backgroundColor: cssColor }}></div>
                            <div>{toTitleCase(data.tripHeadsign.replace(/-/g, ' - '))}</div>
                        </div>
                        <div className="bus-last-stop">
                            <div></div>
                            <div>{toTitleCase(data.tripLastStopName)}</div>
                        </div>
                    </div>
                    <div className="start-stop">
                        <dl><dt>Sch. Trip Start</dt><dd>{data.start.toLocaleTimeString('en-US').replace(/:\d{2}(?=\s)/g, '')}</dd></dl>
                        <dl><dt>Sch. Trip End</dt><dd>{data.finish.toLocaleTimeString('en-US').replace(/:\d{2}(?=\s)/g, '')}</dd></dl>
                    </div>
                    <div className="schedule-deviation">
                        {data.scheduled ? 'Displayed as scheduled' : (<TimeInterval seconds={data.delaySeconds} mark={true} />)}
                    </div>
                    <div className="headways">
                        {!(data.headway?.Ahead)
                            ? null
                            : <HeadwayInfo
                                cardinalDirection={data.route.cardinalDirection}
                                name="Ahead"
                                problem={getHeadwayProblemKind(context.headwayThresholds, props.vehicle.headway?.Ahead)}
                                data={data.headway.Ahead}
                            />}
                        {!(data.headway?.Behind)
                            ? null
                            : <HeadwayInfo cardinalDirection={data.route.cardinalDirection}
                                name="Behind"
                                problem={getHeadwayProblemKind(context.headwayThresholds, props.vehicle.headway?.Behind)}
                                data={data.headway.Behind}
                            />}
                    </div>
                </div>
            </div>
        </div>
    );
}

function VehicleView(props: { vehicle: IRouteVehicle }) {
    const { vehicle } = props;
    const context = React.useContext(LiveMapContext);

    const selected = context.selectedVehicle === vehicle;
    const handleClick = () => context.setSelectedVehicle(selected ? null : vehicle);

    const dirClasses = getDirectionClasses(vehicle.route);
    const style = getStyleByDistance(dirClasses, vehicle.route.distance, vehicle.distance);
    return (
        <div
            className={`bus-positioner ${selected ? 'selected' : ''}`}
            style={style}
            onClick={handleClick}
        >
            <VehiclePushpin vehicle={vehicle} />
            {!selected ? null : <VehicleInfobox vehicle={vehicle} />}
        </div>
    );
}

function getVehicles(variant: IRouteVariant): IRouteVehicle[] {
    const result: IRouteVehicle[] = [];
    for (const vn in variant.vehicles.dict) {
        result.push(variant.vehicles.dict[vn]);
    }
    return result;
}

function RouteDirectionView(props: {
    variant: IRouteVariant,
    from: string,
    to: string
}) {
    const cssColor = props.variant.cssColor;
    const vehicles = getVehicles(props.variant);
    return (
        <>
            <h3 style={{ margin: 0 }}>{props.variant.name} : <small>{props.from} <Icon name="angle right" /> {props.to}</small></h3>
            <div
                className={`route-container route-${props.variant.id}`}
                id={props.variant.id}
                style={{ borderColor: cssColor }}
            >
                <div className={`route-line ${props.variant.isMain ? 'main' : 'variant'}-route`}></div>
                {props.variant.stops.map((s, i) => <StopView key={i} stop={s} />)}
                {vehicles.map((v, i) => (
                    <React.Fragment key={i}>
                        <VehicleView vehicle={v} />
                    </React.Fragment>
                ))
                }
            </div>
        </>
    );
}

export default function LinearView() {
    const context = React.useContext(LiveMapContext);
    return (
        <div
            id="linearBody"
            style={{
                flexGrow: 1,
                overflow: 'visible',
                padding: '1rem 0.5rem 1rem 0',
            }}
        >
            { context.getSelectedRouteVariants().map((d, i) => {
                return (
                    <RouteDirectionView
                        key={i}
                        variant={d.routeVariant}
                        from={d.direction.fromStopName}
                        to={d.direction.toStopName}
                    />
                );
            })}
        </div>
    );
}