import api from '../api/delaysAnalysisApi';
import { PredictedArrivalTimeReportRequest, PredictionAccuracyReportData, PredictionCompletenessData, PredictionsStatsForPeriodData } from '../types/delaysAnalysisTypes';

export async function getPredictionArrivalTimeReport(request: PredictedArrivalTimeReportRequest) {
    const result = await api.getPredictionArrivalTimeReport(request);
    return result.data || [];
}
export async function getPredictionAccuracyForRoute(agencyId: string, predictionDate: string, modelName: string, routeName?: string, directionId?: string) {
    const result = await api.getPredictionAccuracyForRoute(agencyId, predictionDate, modelName, routeName, directionId);
    return result.data || [];
}
export async function getRtVsScheduledPredictionsReport(agencyId: string): Promise<PredictionCompletenessData[]> {
    const result = await api.getRtVsScheduledPredictionsReport(agencyId);
    return result.data || [];
}
export async function getPredictionAccuracyKpi(agencyId: string): Promise<PredictionAccuracyReportData> {
    const result = await api.getPredictionAccuracyKpi(agencyId);
    return result.data;
}
export async function getPredictionAccuracyKpiForCustomPeriod(agencyId: string, fromDate: string, toDate: string): Promise<PredictionAccuracyReportData> {
    const result = await api.getPredictionAccuracyKpiForCustomPeriod(agencyId, fromDate, toDate);
    return result.data;
}
export async function getPredictionsStatsForPeriod(agencyId: string, period: string, fromDate: string, toDate: string, routeName: string, directionVariantId: string): Promise<PredictionsStatsForPeriodData[]> {
    const result = await api.getPredictionsStatsForPeriod(agencyId, period, fromDate, toDate, routeName, directionVariantId);
    return result.data || [];
}