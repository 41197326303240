import React from 'react';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../types/BingMapProps';
import { DirectionModel, RouteModel } from '../../types/view-models';
import { Dictionary, IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';
import { getOrgLocation, loadRoutes } from './_apiActions';
import { IRouteHeadwayEntry, IRouteStop, IRouteVariant, IRouteVehicle, RouteVariantContainer, Thresholds } from './_interfaces';
import { createRouteVariantInstance, nonRoute, RouteVariantEntry, updateNonRouteVehiclesAndHeadways, updateOnRouteVehiclesAndHeadways } from './_models';
import { defaultThresholds, loadThresholds, saveThresholds } from './_thresholds';
import { toIsoDate } from './_utils';

/*-- actions --*/

enum ActionType {
    DEFAULT,

    MAP_DATA_UPDATE = 'MAP_DATA_UPDATE',

    SET_AGENCY = 'SET_AGENCY',
    SET_HEADWAY_THRESHOLDS = 'SET_HEADWAY_THRESHOLDS',
    SET_NON_TRIP_VISIBLE = 'SET_NON_TRIP_VISIBLE',

    SET_ROUTES_LIST = 'SET_ROUTES_LIST',

    SET_SELECTED_STOP = 'SET_SELECTED_STOP',
    SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE',
    SET_HEADWAYS_LIST = 'SET_HEADWAYS_LIST',

    SET_MAP_CETNTER = 'SET_MAP_CETNTER',
    SET_MAP_POLYLINES = 'SET_MAP_POLYLINES',
    SET_MAP_PUSHPINS = 'SET_MAP_PUSHPINS',
    SET_MAP_INFOBOXES = 'SET_MAP_INFOBOXES',
}

/*-- types --*/

interface PublicInstanceHelper {
    onStopClick?: (stop: IRouteStop) => void;
}

interface InternalInstanceHelper {
    dispatch: React.Dispatch<LiveMapAction>;
    selectedRouteVariants: RouteVariantEntry[];
    
    activateTracking(period?: number): void;
    deactivateTracking(state: LiveMapStateType): void;
    setNonTripVisible(state: LiveMapStateType, visible: boolean): boolean;
    setAgencyId(state: LiveMapStateType, agencyId: string | null): void;
    setHeadwayThresholds(state: LiveMapStateType, value: Thresholds): boolean;
    selectRouteDirection(route: IRouteModel, direction: IDirectionModel): Promise<void>;
    deselectRouteDirection(state: LiveMapStateType, route: IRouteModel, direction: IDirectionModel): Promise<void>;
    setSelectedStop(state: LiveMapStateType, value: IRouteStop | null | undefined): boolean;
    setSelectedVehicle(state: LiveMapStateType, vehicle: IRouteVehicle | null | undefined): boolean;

    updateMapPolylines(state: LiveMapStateType): boolean;
    updateMapPushpins(state: LiveMapStateType): boolean;
    updateMapInfoboxes(state: LiveMapStateType): boolean;
}

class InstanceHelper implements PublicInstanceHelper, InternalInstanceHelper, RouteVariantContainer {
    static nextUid = 0; 

    public readonly uid: number;
    public updateTimerId?: any;

    public onStopClick?: (stop: IRouteStop) => void;

    public readonly routeVariants: Dictionary<IRouteVariant> = {};

    public dispatch: React.Dispatch<LiveMapAction>;

    public selectedRouteVariants: RouteVariantEntry[];

    public infoboxesInvalid = false;
    private infoboxes: InfoboxProps[] = [];

    constructor() {
        this.uid = ++InstanceHelper.nextUid;

        this.dispatch = (undefined as unknown) as React.Dispatch<LiveMapAction>;
        this.selectedRouteVariants = [];

        this.updateNonTrip(initialState.nonTripVisible);
    }

    private get inactive(): boolean {
        return !this.updateTimerId;
    }

    public activateTracking(period?: number): void {
        if (this.updateTimerId) {
            console.error('LiveMapContext: tracking is already activated');
        } else {
            console.log('LiveMapInstContext.activate', this.uid, this.updateTimerId);
            this.updateTimerId = setInterval(() => {
                try {
                    this.periodicUpdateCallback();
                } catch (e) {
                    console.error('LiveMap: updateCallback failed', e);
                }
            }, period || 5000);
        }
    }

    private async periodicUpdateCallback() {
        try {
            const results = await Promise.all([
                updateOnRouteVehiclesAndHeadways(this),
                updateNonRouteVehiclesAndHeadways(this, this._agencyId),
            ]);
            if (this.inactive) return;
            this.dispatch({ type: ActionType.SET_HEADWAYS_LIST, payload: results[0] });
            this.invalidateInfoboxes();
        }
        catch (err) {
            console.error('LiveMap: periodic update failed.', err);
        }
    }

    public deactivateTracking(state: LiveMapStateType) {
        this.setAgencyId(state, null);

        console.log('LiveMapContext.InstanceHelper.deactivate', this.uid, this.updateTimerId);
        if (this.updateTimerId) {
            clearInterval(this.updateTimerId);
            delete this.updateTimerId;
        }
    }

    private _agencyId: string | null = null;

    public setAgencyId(state: LiveMapStateType, agencyId: string | null): void {
        if (state.agencyId === agencyId) return;

        this._agencyId = agencyId;

        this.dispatch({ type: ActionType.SET_AGENCY, payload: agencyId });

        if (agencyId) {
            this.selectedRouteVariants = [];

            getOrgLocation(agencyId)
                .then(data => {
                    if (this.inactive) return;
                    this.dispatch({ type: ActionType.SET_MAP_CETNTER, payload: data });
                });

            loadRoutes(agencyId, toIsoDate(new Date()))
                .then(routesList => {
                    if (this.inactive) return;
                    this.dispatch({ type: ActionType.SET_ROUTES_LIST, payload: routesList });
                });
        }
    }

    private updateNonTrip(newValue: boolean): boolean {
        const oldValue = this.routeVariants[nonRoute.id] === nonRoute;
        if (oldValue === newValue) return false;
        if (newValue) {
            this.routeVariants[nonRoute.id] = nonRoute;
            nonRoute.owner = this;
        }
        else {
            nonRoute.owner = null;
            delete this.routeVariants[nonRoute.id];
        }
        return true;
    }

    public setNonTripVisible(state: LiveMapStateType, visible: boolean): boolean {
        if (state.nonTripVisible === visible) return false;
        this.updateNonTrip(visible);
        this.dispatch({ type: ActionType.SET_NON_TRIP_VISIBLE, payload: visible });
        this.invalidateInfoboxes();
        return true;
    }

    public setHeadwayThresholds(state: LiveMapStateType, value: Thresholds): boolean {
        if (this.lowSetHeadwayThresholds(state, value)) {
            this.invalidateInfoboxes();
            return true;
        }
        return false;
    }

    public async selectRouteDirection(route: RouteModel, direction: DirectionModel) {
        let newRouteVariant = false;
        let routeVariant = this.routeVariants[direction.directionVariantId];
        if (!routeVariant) {
            newRouteVariant = true;
            routeVariant = createRouteVariantInstance(route, direction);
            (this.routeVariants[direction.directionVariantId] = routeVariant).owner = this;
        }

        this.selectedRouteVariants.push({ route, direction, routeVariant });
        this.selectedRouteVariants.sort((a, b)=> {
            return a.direction.directionVariantId.localeCompare(b.direction.directionVariantId);
        });

        if (newRouteVariant) {
            const error = await routeVariant.initialLoad();
            if (error) {
                console.warn('LiveMap: route variant load failed', routeVariant, error);
            }

            this.invalidatePolylines();
            this.invalidatePushpins();
            this.invalidateInfoboxes();
        }
    }

    public async deselectRouteDirection(state: LiveMapStateType, route: RouteModel, direction: DirectionModel) {
        const i = this.selectedRouteVariants.findIndex(i => i.route === route && i.direction && direction);
        if (i < 0)
            console.error(`LiveMap: deselect unknown direction [${route.routeId}, ${direction.directionVariantId}] `);
        else {
            if (state.selectedStop?.route.id === route.routeId) {
                this.setSelectedStop(state, null);
            }
            this.selectedRouteVariants.splice(i, 1);

            this.invalidatePolylines();
            this.invalidatePushpins();
            this.invalidateInfoboxes();
        }
    }

    public setSelectedStop(state: LiveMapStateType, value: IRouteStop | null | undefined): boolean {
        value = value || null;
        if (state.selectedStop === value) return false;
        this.dispatch({ type: ActionType.SET_SELECTED_STOP, payload: value || null });
        this.invalidateInfoboxes();
        return false;
    }

    public setSelectedVehicle(state: LiveMapStateType, vehicle: IRouteVehicle | null | undefined): boolean {
        vehicle = vehicle || null;
        if (state.selectedVehicle === vehicle) return false;
        this.dispatch({ type: ActionType.SET_SELECTED_VEHICLE, payload: vehicle });
        this.invalidateInfoboxes();
        return true;
    }

    public polylinesInvalid = false;
    private polylines: PolylineProps[] = [];

    public invalidatePolylines() {
        this.polylinesInvalid = true;
        this.dispatch({ type: ActionType.MAP_DATA_UPDATE });
    }

    public updateMapPolylines(state: LiveMapStateType): boolean {
        if (this.polylinesInvalid) {
            this.polylinesInvalid = false;

            this.polylines = [];
            for (const n in this.selectedRouteVariants) {
                const item = this.selectedRouteVariants[n];
                if (item.routeVariant.routeLine) {
                    this.polylines.push(item.routeVariant.routeLine);
                }
            }
        }
        if (state.mapPolylines === this.polylines) return false;
        this.dispatch({ type: ActionType.SET_MAP_POLYLINES, payload: this.polylines });
        return true;
    }

    public pushpinsInvalid = false;
    private pushpins: PushpinProps[] = [];

    public invalidatePushpins() {
        this.pushpinsInvalid = true;
        this.dispatch({ type: ActionType.MAP_DATA_UPDATE });
    }

    public updateMapPushpins(state: LiveMapStateType): boolean {
        if (this.pushpinsInvalid) {
            this.pushpins = [];

            for (const n in this.selectedRouteVariants) {
                const item = this.selectedRouteVariants[n];
                if (item.routeVariant.stops) {
                    const stopPushpins = item.routeVariant.stops.map(stop => {
                        if (this.onStopClick) {
                            stop.pushpin.eventHandlers = [{
                                event: 'click',
                                callback: (_: Microsoft.Maps.IMouseEventArgs) => {  this.onStopClick && this.onStopClick(stop); },
                            }];
                        }
                        return stop.pushpin;
                    });
                    this.pushpins.splice(this.pushpins.length, 0, ...stopPushpins);
                }
            }
        }
        if (state.mapPushpins === this.pushpins) return false;
        this.dispatch({ type: ActionType.SET_MAP_PUSHPINS, payload: this.pushpins });
        return true;
    }

    public invalidateInfoboxes() {
        this.infoboxesInvalid = true;
        this.dispatch({ type: ActionType.MAP_DATA_UPDATE });
    }

    public updateMapInfoboxes(state: LiveMapStateType): boolean {
        if (this.infoboxesInvalid) {
            this.infoboxesInvalid = false;

            this.infoboxes = [];

            if (state.nonTripVisible) {
                const vd = this.routeVariants[nonRoute.id]?.vehicles?.dict;
                if (vd) {
                    for (const vn in vd) {
                        const v = vd[vn];
                        this.infoboxes.push(v.getVehiclePushpinInfobox(state.headwayThresholds));
                        //this.infoboxes.push(v.getVehicleFullInfobox(this.headwayThresholds));
                    }
                }
            }
            for (const nd in this.selectedRouteVariants) {
                const vd = this.selectedRouteVariants[nd].routeVariant.vehicles?.dict;
                if (!vd) continue;
                for (const vn in vd) {
                    const v = vd[vn];
                    this.infoboxes.push(v.getVehiclePushpinInfobox(state.headwayThresholds));
                    //this.infoboxes.push(v.getVehicleFullInfobox(this.headwayThresholds));
                }
            }
            if (state.selectedStop && state.selectedStop.infobox) {
                this.infoboxes.push({
                    ...state.selectedStop.infobox,
                    // eventHandlers: [{
                    //     event: 'click',
                    //     callback: (args: Microsoft.Maps.IInfoboxEventArgs) => {
                    //         console.log("selectedStop.infobox.click", args)
                    //         //this.setSelectedStop(null)
                    //     }
                    // }]
                });
            }
            if (state.selectedVehicle) {
                const infobox = state.selectedVehicle.getVehicleFullInfobox(state.headwayThresholds);
                if (infobox)
                    this.infoboxes.push(infobox);
            }
        }
        if (state.mapInfoboxes === this.infoboxes) return false;
        this.dispatch({ type: ActionType.SET_MAP_INFOBOXES, payload: this.infoboxes });
        return true;
    }


    public lowSetHeadwayThresholds(state: LiveMapStateType, value: Thresholds) {
        const current = state.headwayThresholds;
        if (current.bunchedError === value.bunchedError &&
            current.bunchedWarning === value.bunchedWarning &&
            current.spreadError === value.spreadError &&
            current.spreadWarning === value.spreadWarning) {
            return false;
        }
        this.dispatch({ type: ActionType.SET_HEADWAY_THRESHOLDS, payload: value });
        return true;
    }
}

interface LiveMapStateType {
    helper: PublicInstanceHelper,

    agencyId: string | null,
    headwayThresholds: Thresholds,

    routeListLoading: boolean,
    routesList: RouteModel[] | null,

    nonTripVisible: boolean;
    selectedStop: IRouteStop | null,
    selectedVehicle: IRouteVehicle | null,
    headwaysList: IRouteHeadwayEntry[],

    mapUpdate: number,
    mapCenter?: CoordinatePair,
    mapPolylines: PolylineProps[] | undefined,
    mapPushpins: PushpinProps[] | undefined,
    mapInfoboxes: InfoboxProps[] | undefined,
}

const initialState: LiveMapStateType = {
    helper: (undefined as unknown) as PublicInstanceHelper,

    agencyId: null,
    headwayThresholds: defaultThresholds,
    routeListLoading: false,
    routesList: null,

    nonTripVisible: false,
    selectedStop: null,
    selectedVehicle: null,
    headwaysList: [],

    mapUpdate: 0,
    mapCenter: undefined,
    mapPolylines: undefined,
    mapPushpins: undefined,
    mapInfoboxes: undefined,
};

export interface LiveMapContextType extends LiveMapStateType {
    activateTracking: (period?: number) => void,
    deactivateTracking: () => void,
    setAgencyId: (agencyId: string | null) => void,
    setNonTripVisible: (visible: boolean) => boolean,
    setHeadwayThresholds: (thresholds: Thresholds) => boolean,
    getSelectedRouteVariants: () => RouteVariantEntry[],
    selectRouteDirection: (route: IRouteModel, direction: IDirectionModel) => void,
    deselectRouteDirection: (route: IRouteModel, direction: IDirectionModel) => void,
    setSelectedStop: (value: IRouteStop | null | undefined) => boolean,
    setSelectedVehicle: (value: IRouteVehicle | null | undefined) => boolean
}

/*-- context --*/

export const LiveMapContext = (React as any).createContext() as React.Context<LiveMapContextType>;

/*-- reducer --*/

type LiveMapAction = {
    type: ActionType,
    payload?: null | boolean | string | CoordinatePair | Thresholds | IRouteStop | IRouteVehicle | RouteModel[] | IRouteHeadwayEntry[] | PolylineProps[] | PushpinProps[] | InfoboxProps[]
};

type ActionMap = {
    [actionType: string]: (state: LiveMapStateType, action: LiveMapAction) => LiveMapStateType
};

const handlers: ActionMap = {
    DEFAULT: (state: LiveMapStateType) => state,
    [ActionType.SET_AGENCY]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = action.payload as (string | null);
        if (value === state.agencyId) {
            return state;
        }

        if (state.agencyId) {
            saveThresholds(state.agencyId, state.headwayThresholds);
        }
        if (!value)
            return initialState;

        const headwayThresholds = !value ? state.headwayThresholds : loadThresholds(value);
        return {
            ...state,
            agencyId: value,
            headwayThresholds,
            routeVariantsLoading: true,
            routeVariants: null,
            selectedRouteVariants: [],
            headwaysList: [],

            mapPolylines: undefined,
            mapPushpins: undefined,
        };
    },
    [ActionType.SET_NON_TRIP_VISIBLE]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = action.payload === true;
        if (value === state.nonTripVisible)
            return state;
        else
            return {
                ...state,
                nonTripVisible: value,
            };
    },
    [ActionType.SET_HEADWAY_THRESHOLDS]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value: Thresholds = action.payload as Thresholds;
        if (state.headwayThresholds.bunchedError === value.bunchedError &&
            state.headwayThresholds.bunchedWarning === value.bunchedWarning &&
            state.headwayThresholds.spreadError === value.spreadError &&
            state.headwayThresholds.spreadWarning === value.spreadWarning) {
            return state;
        }
        return {
            ...state,
            headwayThresholds: value,
        };
    },
    [ActionType.SET_ROUTES_LIST]: (state: LiveMapStateType, action: LiveMapAction) => {
        const routesList = action.payload as (RouteModel[] | null);
        return {
            ...state,
            routesListLoading: routesList === null,
            routesList,
            selectedRouteVariants: [],
        };
    },
    [ActionType.SET_SELECTED_STOP]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = (action.payload as IRouteStop) || null;
        if (state.selectedStop) {
            state.selectedStop.selected = false;
        }
        if (value) {
            value.selected = true;
        }
        return {
            ...state,
            selectedStop: value,
        };
    },
    [ActionType.SET_SELECTED_VEHICLE]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = (action.payload as IRouteVehicle) || null;
        if (state.selectedVehicle) {
            state.selectedVehicle.selected = false;
        }
        if (value) {
            value.selected = true;
        }
        return {
            ...state,
            selectedVehicle: value,
        };
    },
    [ActionType.SET_HEADWAYS_LIST]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = (action.payload as IRouteHeadwayEntry[]) || [];
        return {
            ...state,
            headwaysList: value,
        };
    },
    [ActionType.MAP_DATA_UPDATE]: (state: LiveMapStateType, _action: LiveMapAction) => {
        return {
            ...state,
            mapUpdate: (state.mapUpdate + 65) % 64,
        };
    },
    [ActionType.SET_MAP_CETNTER]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = action.payload as CoordinatePair;
        return {
            ...state,
            mapCenter: value,
        };
    },
    [ActionType.SET_MAP_POLYLINES]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = action.payload as PolylineProps[];
        if (state.mapPolylines === value) return state;
        return {
            ...state,
            mapPolylines: value,
        };
    },
    [ActionType.SET_MAP_PUSHPINS]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = action.payload as PushpinProps[];
        if (state.mapPushpins === value) return state;
        return {
            ...state,
            mapPushpins: value,
        };
    },
    [ActionType.SET_MAP_INFOBOXES]: (state: LiveMapStateType, action: LiveMapAction) => {
        const value = action.payload as InfoboxProps[];
        if (state.mapInfoboxes === value) return state;
        return {
            ...state,
            mapInfoboxes: value,
        };
    },
};

function liveMapReducer(state: LiveMapStateType, action: LiveMapAction): LiveMapStateType {
    const handler = (handlers as any)[action.type] || handlers.DEFAULT;
    return handler(state, action);
}

/*-- state component --*/

function initializeState(src: LiveMapStateType): LiveMapStateType {
    console.log('LiveMapState #0', src.helper);
    if (!src.helper) {
        const result = {
            ...src,
            helper: new InstanceHelper(),
        };
        return result;
    }
    return src;
}

function cast(state: LiveMapStateType): InternalInstanceHelper {
    if (state.helper instanceof InstanceHelper)
        return state.helper;
    
    console.error('LiveMapContext: invalid state.internals');
    return (state.helper as unknown) as InternalInstanceHelper;
}

export const LiveMapState = (props: React.Props<{}>) => {
    const [state, dispatch] = React.useReducer(liveMapReducer, initialState, initializeState);
    cast(state).dispatch = dispatch;

    const activateTracking = (period?: number) => {
        cast(state).activateTracking(period);
    };
    const deactivateTracking = () => {
        cast(state).deactivateTracking(state);
    };
    const setAgencyId = (agencyId: string | null) => {
        cast(state).setAgencyId(state, agencyId);
    };
    const setNonTripVisible = (value: boolean) => {
        return cast(state).setNonTripVisible(state, value);
    };
    const setHeadwayThresholds = (value: Thresholds) => {
        return cast(state).setHeadwayThresholds(state, value);
    };
    const selectRouteDirection = (route: IRouteModel, direction: IDirectionModel) =>{
        cast(state).selectRouteDirection(route, direction);
    };
    const deselectRouteDirection = (route: IRouteModel, direction: IDirectionModel) => {
        cast(state).deselectRouteDirection(state, route, direction);
    };
    const setSelectedStop = (value: IRouteStop | null | undefined) => {
        return cast(state).setSelectedStop(state, value);
    };
    const setSelectedVehicle = (value: IRouteVehicle | null | undefined) => {
        return cast(state).setSelectedVehicle(state, value);
    };
    const getSelectedRouteVariants = () => {
        return cast(state).selectedRouteVariants;
    };

    React.useEffect(() => {
        const helper = cast(state);
        helper.updateMapPolylines(state);
        helper.updateMapPushpins(state);
        helper.updateMapInfoboxes(state);
    }, [
        state.mapUpdate,
    ]);

    return (
        <LiveMapContext.Provider value={{
            ...state,
            activateTracking,
            deactivateTracking,
            setNonTripVisible,
            setAgencyId,
            setHeadwayThresholds,
            getSelectedRouteVariants,
            selectRouteDirection,
            deselectRouteDirection,
            setSelectedStop,
            setSelectedVehicle,
        }}
        >
            {props.children}
        </LiveMapContext.Provider>
    );
};
