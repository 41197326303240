import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { RouteExplorerTableData } from './RouteExplorerForm';


interface TableProps {
    rows: RouteExplorerTableData[];
    selectedRow: RouteExplorerTableData | undefined;
    setSelectedRow: (row: RouteExplorerTableData) => void;
    showScheduledTime: boolean;
}

const RouteExplorerTable: React.FC<TableProps> = ({ rows, selectedRow, setSelectedRow, showScheduledTime }) => {
    const styles = {
        table: {
            display: 'block',
            maxHeight: showScheduledTime ? '610px' : '638px',
            overflowY: 'scroll',
        } as React.CSSProperties,
    };
    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Trip</Table.HeaderCell>
                        {showScheduledTime && <Table.HeaderCell textAlign="left" width={1}>Stop Time</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <RouteExplorerTableRow key={index} row={row} selectedRow={selectedRow} setSelectedRow={setSelectedRow} showScheduledTime={showScheduledTime} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default RouteExplorerTable;

interface RowProps {
    row: RouteExplorerTableData;
    selectedRow: RouteExplorerTableData | undefined;
    setSelectedRow: (row: RouteExplorerTableData) => void;
    showScheduledTime: boolean;
}

const RouteExplorerTableRow: React.FC<RowProps> = ({ row, selectedRow, setSelectedRow, showScheduledTime }) => {
    return (
        <Table.Row onClick={() => setSelectedRow(row)} active={selectedRow !== undefined && selectedRow.tripId === row.tripId}>
            <Table.Cell>
                {row.tripId} ({moment.parseZone(row.tripScheduledStart).format('h:mm A')} - {moment.parseZone(row.tripScheduledFinish).format('h:mm A')})
            </Table.Cell>
            {row.scheduledTime && showScheduledTime && <Table.Cell>
                {moment.parseZone(row.scheduledTime).format('h:mm A')}
            </Table.Cell>}
        </Table.Row>
    );
};