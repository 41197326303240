import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WrongCoordinatesTrip } from '../../../types/anomaliesTypes';
import { TableSortProperties } from '../../../types/types';
import WrongCoordinatesTripsTableRow from './WrongCoordinatesTripsTableRow';


const styles = {
    table: {
        display: 'block',
        maxHeight: '385px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    tableDataState: TableSortProperties<WrongCoordinatesTrip>;
    rows: WrongCoordinatesTrip[],
    selectedRow: WrongCoordinatesTrip | undefined,
    selectedRowHandler: (selectedRow: WrongCoordinatesTrip) => void;
    agencyOffset: number;
    columnSortHandler: (column: string) => void;
}

const WrongCoordinatesTripsTable: React.FC<Props> = ({ tableDataState, selectedRow, selectedRowHandler, agencyOffset, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;
    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'start' ? direction : undefined}
                            onClick={() => columnSortHandler('start')}>
                            Trip
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'vehicle' ? direction : undefined}
                            onClick={() => columnSortHandler('vehicle')}>
                            Vehicle
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'onShape' ? direction : undefined}
                            onClick={() => columnSortHandler('onShape')}>
                            On Shape Positions
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'outShape' ? direction : undefined}
                            onClick={() => columnSortHandler('outShape')}>
                            Out Shape Positions
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1} sorted={column === 'outShapePcnt' ? direction : undefined}
                            onClick={() => columnSortHandler('outShapePcnt')}>
                            Wrong, %
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <WrongCoordinatesTripsTableRow key={index} row={row} selectedTrip={selectedRow} selectedRowHandler={selectedRowHandler} agencyOffsetMinutes={agencyOffset * 60} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default WrongCoordinatesTripsTable;