import { SecondsToHoursOptions, SecondsToHoursValue } from '../types/utilsTypes';

export default class Utils {
    static convertSecondsToMinutes(seconds: number | null, minutesLabel = '', secondsLabel = '', delimiter = ':'): string {
        if (seconds === null)
            return '';
        const minutesStr = `${Math.floor(Math.abs(seconds) / 60)}${minutesLabel}`;
        const secondsStr = `${('0' + Math.floor(Math.abs(seconds) % 60)).slice(-2)}${secondsLabel}`;
        const timeStr = `${minutesStr}${delimiter}${secondsStr}`;
        return seconds >= 0 ? timeStr : `-${timeStr}`;
    }
    static convertSecondsToHours(options: SecondsToHoursOptions): string {
        const { seconds, hoursLabel, minutesLabel, secondsLabel, delimiter, displaySeconds } = new SecondsToHoursValue(options);
        if (seconds === null)
            return '';
        const hoursStr = `${Math.floor(Math.abs(seconds) / 3600)}${hoursLabel}`;
        const remainingSeconds = Math.abs(seconds) % 3600;
        const minutesStr = `${delimiter}${('0' + Math.floor(Math.abs(remainingSeconds) / 60)).slice(-2)}${minutesLabel}`;
        const secondsStr = displaySeconds ? `${delimiter}${('0' + Math.floor(Math.abs(remainingSeconds) % 60)).slice(-2)}${secondsLabel}` : '';
        const timeStr = `${hoursStr}${minutesStr}${secondsStr}`;
        return seconds >= 0 ? timeStr : `-${timeStr}`;
    }
    static ensureIsArray<T>(couldAlreadyBeArray: T | T[]): T[] {
        return Array.isArray(couldAlreadyBeArray)
            ? couldAlreadyBeArray
            : [couldAlreadyBeArray];
    }
    static toQueryStringParams(params: Record<string, string>): string {
        const result = [] as string[];
        for (const param in params) {
            const value = params[param];
            result.push(`${param}=${value}`);
        }
        return result.join('&');
    }
    static removeDuplicatesByPropertyName(myArr: Array<Record<string, any>>, prop: string) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }
    static groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
        list.reduce((previous, currentItem) => {
            const group = getKey(currentItem);
            if (!previous[group]) previous[group] = [];
            previous[group].push(currentItem);
            return previous;
        }, {} as Record<K, T[]>);
    static groupByEntries = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) => Object.entries(Utils.groupBy(list, getKey)) as [K, T[]][];

    static utcToLocal(utcDateTimeString: string, minutesToAtz: number): Date {
        const minutesToMilliseconds = 60 * 1000;
        const lastIndex = utcDateTimeString.length - 1;
        if (utcDateTimeString[lastIndex] !== 'Z') {
            throw Error(`Expected date+time string (${utcDateTimeString}) in format 'yyyy-MM-ddTHH:mm:ssZ'`);
        }
        const browserDateTimeString = utcDateTimeString.substr(0, lastIndex);
        const atzDateTimeValue = Date.parse(browserDateTimeString) + minutesToAtz * minutesToMilliseconds;
        return new Date(atzDateTimeValue);
    }
    static roundNumber(value: number, roundDigits = 2) {
        const roundCoef = Math.pow(10, roundDigits);
        return Math.round((value + Number.EPSILON) * roundCoef) / roundCoef;
    }
    static kmToMiles(km: number, roundDigits = 2): number {
        const miles = km / 1.60934;
        return this.roundNumber(miles, roundDigits);
    }
    static kmToFeet(km: number, roundDigits = 2): number {
        const feet = km * 3280.84;
        return this.roundNumber(feet, roundDigits);
    }
    static getDistanceMeters(
        [lat1, lon1]: CoordinatePair,
        [lat2, lon2]: CoordinatePair,
    ): Meters {
        const R = 6371 as 6371 & Kilometers; // Radius of the earth
        const dLat = Utils.toRadiansForAngleInDegrees(lat2 - lat1 as Latitude);
        const dLon = Utils.toRadiansForAngleInDegrees(lon2 - lon1 as Longitude);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(Utils.toRadiansForAngleInDegrees(lat1)) *
            Math.cos(Utils.toRadiansForAngleInDegrees(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c as Kilometers;

        return Utils.toMetersFromKilometers(d);
    }
    static toRadiansForAngleInDegrees(deg: Degrees): Radians {
        return deg * (Math.PI / 180) as Radians;
    }
    static toMetersFromKilometers(km: Kilometers): Meters {
        return km * 1000 as Meters;
    }
    static distinctValuesByKey<T>(array: T[], key: string): T[] {
        return [...new Map(array.map(item => [(item as any)[key], item])).values()];
    }
    static getDirectionVariantName(otsTripShortName: string, tripHeadsign: string): string {
        return otsTripShortName ? `${otsTripShortName} to ${tripHeadsign}` : tripHeadsign;
    }

}