import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TopWrongPredictingRoute } from '../../../types/anomaliesTypes';

const TopPredictionRoutesTableRow: React.FC<{ row: TopWrongPredictingRoute, rowHandler: (data: TopWrongPredictingRoute) => void }> = ({ row, rowHandler }) => {
    const handleRowClick = () => rowHandler?.(row);

    return (
        <Table.Row onClick={handleRowClick}>
            <Table.Cell textAlign="left">
                {row.routeName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.directionName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.percentage}
            </Table.Cell>
        </Table.Row>
    );
};

export default TopPredictionRoutesTableRow;