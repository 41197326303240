import { ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DelaysDistibutionChartData } from './StopDelaysForm';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Number of arrivals',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
            datalabels: {
                align: 'end',
                anchor: 'end',
            },
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            gridLines: {
                drawOnChartArea: false,
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function (value: string) {
                    const valueNum = value as unknown as number;
                    return valueNum !== 0 && valueNum > 1000 ? `${Math.round((valueNum) / 1000)}k` : valueNum;
                },
                maxTicksLimit: 6,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    responsive: true,
    layout: {
        padding: {
            top: 25,
        },
    },
    plugins: {
        crosshair: false,
        datalabels: {
            color: 'red',
            font: {
                weight: 'bold',
            },
        },
    },
};

const DelaysDistributionChart: React.FC<{ delayDistribution: DelaysDistibutionChartData }> = ({ delayDistribution }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = ['Early (< -1 min)', 'On Time (-1 - 4 min)', 'Late (4 - 10 min)', 'Very Late (> 10 min)'];
        const values = [delayDistribution.early, delayDistribution.onTime, delayDistribution.late, delayDistribution.veryLate];

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0], data: values,
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number, index: number }) {
                        return `Number of Arrivals: ${tooltipItem.yLabel.toLocaleString()}`;
                    },
                },
            },
            plugins: {
                ...prevState.plugins,
                datalabels: {
                    ...prevState.plugins.datalabels,
                    formatter: function (_value: number, context: Context) {
                        const labelText = context.chart.data.labels?.[context.dataIndex];
                        let percentage: number;
                        switch (labelText) {
                            case 'Early (< -1 min)':
                                percentage = delayDistribution.earlyPercentage;
                                break;
                            case 'On Time (-1 - 4 min)':
                                percentage = delayDistribution.onTimePercentage;
                                break;
                            case 'Late (4 - 10 min)':
                                percentage = delayDistribution.latePercentage;
                                break;
                            case 'Very Late (> 10 min)':
                                percentage = delayDistribution.veryLatePercentage;
                                break;
                            default:
                                percentage = 0;
                                break;
                        }
                        return percentage ?`${percentage}%`: '';
                    },
                },
            },
        }));
    }, [delayDistribution]);

    return (
        <Bar
            data={dataState as ChartData}
            height={150}
            options={optionsState as unknown as ChartOptions}
            plugins={[ChartDataLabels]}
        />
    );
};

export default DelaysDistributionChart;