import moment from 'moment';
import { getRtDelaysNowReportData } from '../../../../actions/otpActions';
import { RtDelaysNow, RtDelaysNowInterval } from '../../../../types/delaysNowTypes';
import { DelayDataForPeriod, delayModeTypes, DelaySort, delayStatusColors, DelaysTimePoint, DeviationDistribution, RoutesDropDownStateType } from './models';

function normalizeDeviationDistribution(item: DeviationDistribution, hasValue: boolean): DeviationDistribution {
    if (hasValue) {
        let total = 0;
        if (item.early === null) item.early = 0; else total += item.early;
        if (item.onTime === null) item.onTime = 0; else total += item.onTime;
        if (item.late === null) item.late = 0; else total += item.late;
        if (item.veryLate === null) item.veryLate = 0; else total += item.veryLate;

        if (total > 0) {
            const scale = 1000 / total;
            item.early = Math.floor(item.early! * scale) / 10;
            item.late = Math.floor(item.late! * scale) / 10;
            item.veryLate = Math.floor(item.veryLate! * scale) / 10;
            item.onTime = Math.floor(item.onTime! * scale) / 10;

            // if (total > 0) {
            //     item.onTime = 100 - (item.early + item.late + item.veryLate);
            // }
        }
    }
    //item.period = moment(item.period).format('D MMM, HH:mm');
    item.period = moment(item.period).format('h:mm a');
    return item;
}

export function calcDeviationDistribution(sourceData: DelaysTimePoint[]): DeviationDistribution[] {
    const result: DeviationDistribution[] = [];
    if (sourceData.length > 0) {
        for (let t = 0; t < sourceData.length; ++t) {
            const delaysPoint = sourceData[t];

            const current: DeviationDistribution = {
                period: delaysPoint.time,
                early: null, onTime: null, late: null, veryLate: null,
            };

            let hasValue = false;

            for (let i = 0; i < delaysPoint.data.length; ++i) {
                const item = delaysPoint.data[i];

                if (typeof item.delaySec === 'number') {
                    hasValue = true;

                    if (item.delaySec < -60) {
                        if (current.early === null) current.early = 1; else ++current.early;
                    }
                    else if (item.delaySec < 4 * 60) {
                        if (current.onTime === null) current.onTime = 1; else ++current.onTime;
                    }
                    else if (item.delaySec < 10 * 60) {
                        if (current.late === null) current.late = 1; else ++current.late;
                    }
                    else {
                        if (current.veryLate === null) current.veryLate = 1; else ++current.veryLate;
                    }
                }
            }
            result.push(normalizeDeviationDistribution(current, hasValue));
        }
    }
    return result;
}

type DirectionsMap = { [directionVariantId: string]: boolean };
type RouteMap = { [routeShortName: string]: DirectionsMap };

function normalizeDelayDataForPeriod(item: DelayDataForPeriod, counter: number): DelayDataForPeriod {
    if (counter > 0) {
        item.min = Math.round(item.min! / 6) / 10;
        item.max = Math.round(item.max! / 6) / 10;
        item.avg = Math.round(item.avg! / counter / 6) / 10;
    }
    //item.period = moment(item.period).format('D MMM, HH:mm');
    item.period = moment(item.period).format('h:mm a');
    return item;
}

export function calcAverageDelayDataChartData(sourceData: DelaysTimePoint[], routesDropDownState: RoutesDropDownStateType): DelayDataForPeriod[] {
    const result: DelayDataForPeriod[] = [];
    if (sourceData.length > 0) {
        const routeMap: RouteMap = {};
        for (let t = 0; t < sourceData.length; ++t) {
            const delaysPoint = sourceData[t];

            let counter = 0;
            const current: DelayDataForPeriod = { period: delaysPoint.time, min: null, avg: null, max: null };

            for (let i = 0; i < delaysPoint.data.length; ++i) {
                const item = delaysPoint.data[i];

                let dm = routeMap[item.routeShortName];
                if (!dm) {
                    routeMap[item.routeShortName] = (dm = {});
                }
                if (!(item.directionVariantInternalId in dm)) {
                    dm[item.directionVariantInternalId] = true;
                }

                if (typeof item.delaySec === 'number') {
                    ++counter;
                    const v = item.delaySec;
                    if (current.max === null || current.max < v)
                        current.max = v;

                    if (current.min === null || current.min > v)
                        current.min = v;

                    if (current.avg === null)
                        current.avg = v;
                    else
                        current.avg += v;
                }
            }
            result.push(normalizeDelayDataForPeriod(current, counter));
        }

        for (const r of routesDropDownState.options) {
            r.disabled = routeMap[r.value as string] === undefined;
        }
    }
    return result;
}

function getDelaySortSvgIcon(delaySort: DelaySort): string {
    const dc = delayStatusColors[delaySort];
    return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><circle cx="9" cy="9" r="8" stroke="${dc.dark}" stroke-width="2" fill="${dc.base}" /></svg>`;
}

export const delayIcons = {
    [DelaySort.Early]: getDelaySortSvgIcon(DelaySort.Early),
    [DelaySort.OnTime]: getDelaySortSvgIcon(DelaySort.OnTime),
    [DelaySort.Late]: getDelaySortSvgIcon(DelaySort.Late),
    [DelaySort.VeryLate]: getDelaySortSvgIcon(DelaySort.VeryLate),
};

type IntervalReportData = {
    source: RtDelaysNow[];
    points: DelaysTimePoint[];
};

export type ReportData = {
    [sort in RtDelaysNowInterval]: IntervalReportData;
};

function prepareDelaysTimePoints(sourceData: RtDelaysNow[]): DelaysTimePoint[] {
    const result: DelaysTimePoint[] = [];
    if (sourceData.length > 0) {
        let current: DelaysTimePoint = {
            time: sourceData[0].timePeriod.substr(0, 19),
            data: [],
        };
        for (let i = 0; i < sourceData.length; ++i) {
            const item = sourceData[i];

            item.timePeriod = item.timePeriod.substr(0, 19);

            if (current.time !== item.timePeriod) {
                result.push(current);
                current = { time: item.timePeriod, data: [] };
            }
            current.data.push(item);
        }
        result.push(current);
    }
    return result;
}

async function loadReportDataForInterval(agencyId: string, interval: RtDelaysNowInterval): Promise<IntervalReportData> {
    try {
        const source = await getRtDelaysNowReportData(agencyId, interval);
        source.sort((a, b) => a.timePeriod.localeCompare(b.timePeriod));
        return {
            source,
            points: prepareDelaysTimePoints(source),
        };
    }
    catch {
        return {
            source: [],
            points: [],
        };
    }
}

export async function loadReportData(agencyId: string): Promise<ReportData> {
    const [r05, r10, r30, r60] = await Promise.all([
        loadReportDataForInterval(agencyId, RtDelaysNowInterval.m05),
        loadReportDataForInterval(agencyId, RtDelaysNowInterval.m10),
        loadReportDataForInterval(agencyId, RtDelaysNowInterval.m30),
        loadReportDataForInterval(agencyId, RtDelaysNowInterval.m60),
    ]);
    return {
        [RtDelaysNowInterval.m05]: r05,
        [RtDelaysNowInterval.m10]: r10,
        [RtDelaysNowInterval.m30]: r30,
        [RtDelaysNowInterval.m60]: r60,
    };
}

export function getAverageDelayDescription(selectedInterval: RtDelaysNowInterval | undefined) {
    if (!selectedInterval)
        return 'Average Delay';
    const { intervalDescription, period } = delayModeTypes[selectedInterval];
    return `Average Delay in ${intervalDescription} buckets over the ${period}`;
}

export function getDelaysDistributionDescription(selectedInterval: RtDelaysNowInterval | undefined) {
    if (!selectedInterval)
        return 'Delays Distribution';
    const { intervalDescription, period } = delayModeTypes[selectedInterval];
    return `Distribution of arrival delays for every ${intervalDescription} over the ${period}`;
}