import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDatePoint } from '../../../types/chartTypes';
import { VehiclesCountChartData } from '../../../types/vehicleStatisticsTypes';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'With Trips',
            lineTension: 0.1,
            backgroundColor: 'rgba(130, 225, 128, 0.8)',
            borderColor: 'rgba(130, 225, 128, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
        {
            label: 'Without Trips',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 131, 104, 0.8)',
            borderColor: 'rgba(255, 131, 104, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
        text: 'Vehicles count',
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            boxWidth: 20,
        },
    },
    elements: {
        line: {
            tension: 0.4,
        },
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'day',
                unitStepSize: 1,
            },
            ticks: {
                autoSkip: false,
            },
            scaleLabel: {
                display: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
            },
            scaleLabel: {
                display: false,
            },
            stacked: true,
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return moment(tooltipItem[0].xLabel).format('YYYY-MM-DD');
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                const lineType = tooltipItem.datasetIndex === 0 ? 'With Trips' : 'Without Trips';
                return `${lineType}: ${tooltipItem.yLabel}`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: 'rgba(130, 225, 128, 0.8)',
                        backgroundColor: 'rgba(130, 225, 128, 0.8)',
                    };
                } else {
                    return {
                        borderColor: 'rgba(255, 131, 104, 0.8)',
                        backgroundColor: 'rgba(255, 131, 104, 0.8)',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        filler: {
            propagate: true,
        },
        'samples-filler-analyser': {
            target: 'chart-analyser',
        },
        crosshair: false,
    },
    spanGaps: false,
};

interface Props {
    chartData: VehiclesCountChartData[],
}

const VehiclesCountChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const orderedChartData = chartData.sort((a, b) => new Date(a.serviceDate).getTime() - new Date(b.serviceDate).getTime());
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: orderedChartData.map(d => {
                    return {
                        t: d.serviceDate,
                        y: d.withTrips,
                    };
                }),
            },
            {
                ...prevState.datasets[1], data: orderedChartData.map(d => {
                    return {
                        t: d.serviceDate,
                        y: d.withoutTrips,
                    };
                }),
            },
            ],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={120}
            options={options as unknown as ChartOptions}
        />
    );
};

export default VehiclesCountChart;