import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BusAnalysisReport } from '../../../types/anomaliesTypes';
import { LineChartDatePoint } from '../../../types/chartTypes';

const color1 = 'rgb(229, 92, 85)';
const color2 = 'rgb(147, 192, 32)';
const color2b = 'rgba(147, 192, 32, 0.2)';
const color2c = 'rgba(147, 192, 32, 0.5)';

const chartSettings = {
    labels: [],
    datasets: [
        {
            type: 'line',
            yAxisID: 'y-axis-1',
            label: 'Number of "Jumps"',
            unit: '',
            backgroundColor: color1,
            borderColor: color1,
            fill: false,
            borderWidth: 2,
            data: [] as LineChartDatePoint[],
        },
        {
            type: 'line',
            yAxisID: 'y-axis-2',
            label: 'Offline Time TAIP (min)',
            unit: '',
            fill: 'origin',
            backgroundColor: color2b,
            borderColor: color2,
            borderWidth: 1,
            lineTension: 0,
            data: [] as LineChartDatePoint[],
        },
        {
            type: 'line',
            yAxisID: 'y-axis-2',
            label: 'Offline Time GTFS (min)',
            unit: '',
            fill: 'origin',
            backgroundColor: color2c,
            borderColor: color2,
            borderWidth: 1,
            lineTension: 0,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options = {
    legend: {
        display: true,
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                    day: 'M/D/YYYY',
                },
            },
            ticks: {
                autoSkip: false,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                fontColor: color1,
            },
            position: 'left',
            id: 'y-axis-1',
        }, {
            ticks: {
                major: { color: 'red' },
                beginAtZero: false,
                fontColor: color2,
            },
            position: 'right',
            id: 'y-axis-2',
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                const dataset = chartSettings.datasets[tooltipItem.datasetIndex];
                return `${dataset.label}: ${tooltipItem.yLabel} ${dataset.unit}`;
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        crosshair: {
            line: {
                color: '#F66',  // crosshair line color
                width: 1,       // crosshair line width
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: false,                                    // enable zooming
                // zoomboxBackgroundColor: 'rgba(166,133,244,0.2)',   // background color of zoom box
                // zoomboxBorderColor: '#48F',                        // border color of zoom box
                // zoomButtonText: 'Reset Zoom',                      // reset zoom button text
                // zoomButtonClass: 'ui button',                      // reset zoom button class
            },
            snap: {
                enabled: true,
            },
        },
    },
    maintainAspectRatio: false,
};

const BusAnalysisJumpsOfflineChart: React.FC<{ chartData: BusAnalysisReport[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(d => {
                    return {
                        t: d.reportDate,
                        y: d.jumpsCount,
                    };
                }),
            }, {
                ...prevState.datasets[1], data: chartData.map(d => {
                    return {
                        t: d.reportDate,
                        y: Math.round(d.offlineSecTaip / 60 / 10) * 10,
                    };
                }),
            }, {
                ...prevState.datasets[2], data: chartData.map(d => {
                    return {
                        t: d.reportDate,
                        y: Math.round(d.offlineSecGtfs / 60 / 10) * 10,
                    };
                }),
            }],
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState as ChartData}
            options={options as unknown as ChartOptions}
        />
    );
};

export default BusAnalysisJumpsOfflineChart;