import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Utils from '../../../../utilities/utils';
import { HeadwaysChartDataType } from './StopHeadways';

const styles = {
    chartContainer: {
        width: '470px',
    } as React.CSSProperties,
};

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Headway Minutes',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: [],
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
        text: 'Headways by Bus',
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            ticks: {
                maxTicksLimit: 10,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function (value: string) {
                    return `${Utils.roundNumber((value as unknown as number) / 60, 1)} min`;
                },
            },
            scaleLabel: {
                display: false,
                labelString: 'Headway (minutes)',
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            label: function ({ xLabel, yLabel }: { datasetIndex: number, xLabel: string, yLabel: number }) {
                return [`Local Arrival Time: ${xLabel}`, `Headway (min): ${yLabel < 3600 ? Utils.convertSecondsToMinutes(yLabel) : Utils.convertSecondsToHours({ seconds: yLabel })}`];
            },
        },
    },
    responsive: true,
    plugins: {
        crosshair: false,
    },
};

const StopHeadwaysChart: React.FC<{ chartData: HeadwaysChartDataType[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = chartData.map(d => d.actualTime);
        const values = chartData.map(d => d.headwaySec);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0],
                data: values,
                backgroundColor: chartData.map<never>(({ sequence }) => {
                    switch (sequence) {
                        case 1:
                            return '#FFC061' as never;
                        case 2:
                            return '#FF8368' as never;
                        case 3:
                            return '#31A6DC' as never;
                        default:
                            return '#42DCC6' as never;
                    }
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips, callbacks: {
                    ...prevState.tooltips.callbacks, title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                        const arrivalTime = tooltipItem[0].xLabel;
                        const stateData = chartData.find(d => d.actualTime === arrivalTime);
                        return stateData ? `Vehicle ID: ${stateData.vehicleId}` : '';
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <div style={styles.chartContainer}>
            <Bar
                data={dataState as ChartData}
                height={405}
                options={optionsState as unknown as ChartOptions}
            />
        </div>
    );
};

export default StopHeadwaysChart;