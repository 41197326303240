import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { BunchingItem, BunchingVehicleData } from '../../../../types/busBunchingTypes';
import Utils from '../../../../utilities/utils';

const styles = {
    headsignWidth: {
        width: '150px',
    } as React.CSSProperties,

};

interface Props {
    row: BunchingItem;
    selectedRow: BunchingVehicleData | undefined;
    selectedRowHandler: (selectedRow: BunchingVehicleData) => Promise<void>;
    minutesToAtz: number;
}

const BusBucnhingTableRow: React.FC<Props> = ({ row, selectedRow, selectedRowHandler, minutesToAtz }) => {
    const handleRowClick = () => {
        if (selectedRowHandler) {
            selectedRowHandler(
                {
                    tripId1: row.trip1.id,
                    tripId2: row.trip2.id,
                    vehicleId1: row.trip1.vehicle,
                    vehicleId2: row.trip2.vehicle,
                    shapeId: row.shapeId,
                });
        }
    };

    const isActive = (selectedRow: BunchingVehicleData | undefined) => {
        return selectedRow && selectedRow.tripId1 === row.trip1.id &&
            selectedRow.tripId2 === row.trip2.id &&
            selectedRow.vehicleId1 === row.trip1.vehicle &&
            selectedRow.vehicleId2 === row.trip2.vehicle;
    };

    return (
        <Table.Row onClick={handleRowClick} active={isActive(selectedRow)}>
            <Table.Cell>{row.routeShortName}</Table.Cell>
            <Table.Cell>{row.trip1.vehicle}</Table.Cell>
            <Table.Cell>{row.trip2.vehicle}</Table.Cell>
            <Table.Cell>
                {row.trip1.id} ({moment(Utils.utcToLocal(row.trip1.start, minutesToAtz)).format('h:mm A') + ' - ' + moment(Utils.utcToLocal(row.trip1.finish, minutesToAtz)).format('h:mm A')})
            </Table.Cell>
            <Table.Cell>
                {row.trip2.id} ({moment(Utils.utcToLocal(row.trip2.start, minutesToAtz)).format('h:mm A') + ' - ' + moment(Utils.utcToLocal(row.trip2.finish, minutesToAtz)).format('h:mm A')})
            </Table.Cell>
            <Table.Cell>
                <div style={styles.headsignWidth}>
                    {row.tripHeadsign}
                </div>
            </Table.Cell>
            <Table.Cell>{row.meanDeltaPcnt}</Table.Cell>
            <Table.Cell>{row.stopsCount}</Table.Cell>
            <Table.Cell textAlign="left">{row.bunchedStopsPcnt}</Table.Cell>
        </Table.Row>
    );
};

export default BusBucnhingTableRow;