import './PerformanceAnalysisStyles.css';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Button, CheckboxProps, Form, Header, Popup, Radio, Statistic } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getGtfsDirectionVariants } from '../../../actions/gtfsStaticActions';
import { getOverallPerformance, getRouteLevelPerformance, getTripsLevelPerformance } from '../../../actions/runningTimeAnalyticsActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { aggregatedTripsLevelType, AllDays, DaysFilterType, RouteLevelPerformance, routeLevelTableType, TripDetailsPerformance, tripsDetailsLevelType } from '../../../types/runningTimeAnalyticsTypes';
import { KeyValuePair } from '../../../types/types';
import Utils from '../../../utilities/utils';
import useTableSorting from '../../hooks/useTableSorting';
import StartAndEndDatesField from '../../shared/StartAndEndDatesField';
import AggregatedTripsTable from './AggregatedTripsTable';
import aggregatedTripsTableSortReducer from './aggregatedTripsTableSortReducer';
import PerformanceByDatesChart from './PerformanceByDatesChart';
import RouteLevelTable from './RouteLevelTable';
import routeLevelTableSortReducer from './routeLevelTableSortReducer';
import TripsDetailsTable from './TripsDetailsTable';
import tripsDetailsTableSortReducer from './tripsDetailsTableSortReducer';

export interface RouteLevelPerformanceTableType extends RouteLevelPerformance {
    directionVariantName: string;
    [key: string]: any;
}

export interface TripAggregatedPerformance {
    tripId: string;
    tripInternalId: string;
    tripScheduledStart: Date;
    tripScheduledFinish: Date;
    performance: number;
    fastest: number;
    scheduledTripTime: number;
    avgDwell: number;
    inMotion: number;
    [key: string]: any;
}

const styles = {
    statistic: {
        marginLeft: '17px',
    } as React.CSSProperties,
    chartContainer: {
        width: '87%',
        display: 'inline-flex',
    } as React.CSSProperties,
    buttonContainer: {
        height: '35.7px',
    } as React.CSSProperties,
    chartDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
};

interface Props {
    agency: AgencyStateType | undefined;
}

let initialTripsDetailsState: TripDetailsPerformance[] = [];

const OverallPerformanceForm: React.FC<Props> = ({ agency }) => {

    const [performanceValueState, setPerformanceValueState] = useState<{ performance: string, date: string }>();
    const [performanceByDatesState, setPerformanceByDatesState] = useState<Array<KeyValuePair<Date, number>>>([]);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [startDateState, setStartDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-30, 'days').format('YYYY-MM-DD'));
    const [endDateState, setEndDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).format('YYYY-MM-DD'));
    const [routePerformanceItemsState, setRoutePerformanceItemsState] = useState<RouteLevelPerformanceTableType[]>([]);
    const [aggregatedTripsState, setAggregatedTripsState] = useState<TripAggregatedPerformance[]>([]);
    const [tripsDetailsState, setTripsDetailsState] = useState<TripDetailsPerformance[]>([]);
    const [daysFilterState, setDaysFilterState] = useState<DaysFilterType>(AllDays);
    const [tableTypeState, setTableTypeState] = useState<typeof routeLevelTableType | typeof aggregatedTripsLevelType | typeof tripsDetailsLevelType>(routeLevelTableType);
    const [selectedRouteState, setSelectedRouteState] = useState('');
    const [selectedDirectionState, setSelectedDirectionState] = useState('');
    const { tableDataState: routePerformanceTableDataState, handleTableColumnSort: handleRoutePerformanceTableColumnSort } = useTableSorting(routeLevelTableSortReducer, routePerformanceItemsState);
    const { tableDataState: aggregatedTripsTableDateState, handleTableColumnSort: handleAggregatedTripsTableColumnSort } = useTableSorting(aggregatedTripsTableSortReducer, aggregatedTripsState);
    const { tableDataState: tripsDetailsTableDataState, handleTableColumnSort: handleTripsDetailsTableColumnSort } = useTableSorting(tripsDetailsTableSortReducer, tripsDetailsState);

    useEffect(() => {
        (async () => await updateRouteLevelPerformanceStates())();
    }, [agency?.id]);

    const displayAggregatedTripsTable = async (routeName: string, directionVariantInternalId: string) => {
        updateTripLevelPerformanceState(routeName, directionVariantInternalId, () => {
            setTableTypeState(aggregatedTripsLevelType);
            setSelectedRouteState(routeName);
            setSelectedDirectionState(directionVariantInternalId);
        });
    };
    const displayDetailsTripsTable = (tripInternalId: string, tripId: string) => {
        setTripsDetailsState(initialTripsDetailsState.filter(t => t.tripInternalId === tripInternalId && t.tripId === tripId));
        setTableTypeState(tripsDetailsLevelType);
    };
    const displayRouteLevelTable = () => {
        setSelectedRouteState('');
        setSelectedDirectionState('');
        setTableTypeState(routeLevelTableType);
    };
    const routeLevelTable = () => {
        return routePerformanceItemsState.length > 0 &&
            <RouteLevelTable tableDataState={routePerformanceTableDataState} displayAggregatedTripsTable={displayAggregatedTripsTable} columnSortHandler={handleRoutePerformanceTableColumnSort} />;
    };
    const aggregatedTripsTable = () => {
        return aggregatedTripsState.length > 0 &&
            <AggregatedTripsTable tableDataState={aggregatedTripsTableDateState} routeName={selectedRouteState} displayDetailsTripsTable={displayDetailsTripsTable} columnSortHandler={handleAggregatedTripsTableColumnSort} />;
    };
    const tripsDetailsTable = () => {
        return tripsDetailsState.length > 0 &&
            <>
            <label className="categoryHeader">
                <div>Statistics for route {selectedRouteState} and trip {tripsDetailsState[tripsDetailsState.length - 1].tripId} ({moment.parseZone(tripsDetailsState[tripsDetailsState.length - 1].tripScheduledStart).format('h:mm A')} - {moment.parseZone(tripsDetailsState[tripsDetailsState.length - 1].tripScheduledFinish).format('h:mm A')})
                    </div>
                    <div className="categorySubtitle">Click Bus ID to see its travel history</div>
                </label>
                <TripsDetailsTable tableDataState={tripsDetailsTableDataState} columnSortHandler={handleTripsDetailsTableColumnSort} />
            </>;
    };

    const updateRouteLevelPerformanceStates = async (onSuccessRequest?: () => void) => {
        if (!agency || !agency.id)
            return;
        const daysFilter = daysFilterState;
        setFormBlockingState(true);
        try {
            const { lastAvailableDatePerformance, lastAvailableDate, performanceByDates } = await getOverallPerformance({ agencyId: agency.id, startDate: startDateState, endDate: endDateState, daysFilter });
            if (lastAvailableDatePerformance && lastAvailableDate) {
                setPerformanceValueState({
                    performance: `${lastAvailableDatePerformance} %`,
                    date: moment(lastAvailableDate).format('YYYY-MM-DD'),
                });
            }
            setPerformanceByDatesState(performanceByDates);
        } catch {
            setPerformanceByDatesState([]);
        }
        try {
            const [routesPerformance, directionsData] = await Promise.all([getRouteLevelPerformance({ agencyId: agency.id, startDate: startDateState, endDate: endDateState, daysFilter }),
            getGtfsDirectionVariants(agency.id, startDateState, endDateState)]);
            const routesPerformanceForTable = routesPerformance.map<RouteLevelPerformanceTableType>(p => {
                const relatedDirection = directionsData.find(d => d.directionVariantInternalId === p.directionVariantInternalId);
                const directionVariantName = relatedDirection ? relatedDirection.directionVariantName : p.directionVariantInternalId;
                return { ...p, directionVariantName };
            });
            setRoutePerformanceItemsState(routesPerformanceForTable);
            if (onSuccessRequest)
                onSuccessRequest();
        } catch {
            setRoutePerformanceItemsState([]);
        } finally {
            setFormBlockingState(false);
        }
    };

    const updateTripLevelPerformanceState = async (routeName: string, directionVariantInternalId: string, onSuccessRequest?: () => void) => {
        if (!agency || !agency.id)
            return;
        const daysFilter = daysFilterState;
        setFormBlockingState(true);
        try {
            const tripsInfo = await getTripsLevelPerformance({
                agencyId: agency.id,
                routeName,
                directionVariantInternalId,
                startDate: startDateState,
                endDate: endDateState,
                daysFilter,
            });
            const aggregatedTrips = Object.entries(Utils.groupBy(tripsInfo, t => t.tripInternalId)).map<TripAggregatedPerformance>(([tripInternalId, tripsData]) => ({
                tripId: tripsData.sort((a, b) => new Date(b.tripActualStart).getTime() - new Date(a.tripActualStart).getTime())[0].tripId,
                tripInternalId,
                tripScheduledStart: tripsData[0].tripScheduledStart,
                tripScheduledFinish: tripsData[0].tripScheduledFinish,
                performance: tripsData.filter(t => Boolean(t.startDetected) && Boolean(t.finishDetected)).reduce((sum: number, currentTrip: TripDetailsPerformance) => currentTrip.performance + sum, 0) / tripsData.length,
                fastest: Math.min(...tripsData.filter(t => Boolean(t.startDetected) && Boolean(t.finishDetected)).map(t => t.tripActualTravelSec)),
                scheduledTripTime: tripsData[0].tripScheduledTravelSec,
                avgDwell: tripsData.reduce((sum: number, currentTrip: TripDetailsPerformance) => currentTrip.averageStopSec + sum, 0) / tripsData.length,
                inMotion: tripsData.reduce((sum: number, currentTrip: TripDetailsPerformance) => currentTrip.totalInMotionSec + sum, 0) / tripsData.length,
            }));
            setAggregatedTripsState(aggregatedTrips);
            initialTripsDetailsState = [...tripsInfo];
            if (onSuccessRequest)
                onSuccessRequest();
        } catch {
            setAggregatedTripsState([]);
            initialTripsDetailsState = [];
        } finally {
            setFormBlockingState(false);
        }
    };

    const daysFilterChange = (_event: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
        setDaysFilterState(value as DaysFilterType);
    };
    const handleApplyClick = async () => {
        await updateRouteLevelPerformanceStates();
        if ((tableTypeState === aggregatedTripsLevelType || tableTypeState === tripsDetailsLevelType) && selectedRouteState && selectedDirectionState) {
            await updateTripLevelPerformanceState(selectedRouteState, selectedDirectionState);
            if (tableTypeState === tripsDetailsLevelType) {
                setTripsDetailsState(prevState => {
                    return prevState.length ? initialTripsDetailsState.filter(t => t.tripInternalId === prevState[0].tripInternalId) : [];
                });
            }
        }
    };
    const handleBackClick = () => {
        if (tableTypeState === aggregatedTripsLevelType)
            displayRouteLevelTable();
        else
            setTableTypeState(aggregatedTripsLevelType);
    };
    const getTable = () => {
        if (tableTypeState === routeLevelTableType)
            return routeLevelTable();
        else if (tableTypeState === aggregatedTripsLevelType)
            return aggregatedTripsTable();
        else
            return tripsDetailsTable();
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Overall
                </Header>
                <div style={styles.chartDescription}>
                    The performance indicator shows how close the actual running time is to the scheduled.
                    The table also contains a breakdown of the average trip time: motion and dwell on stop time.
                    Use the drill-down to get detailed statistics and even the history of buses motion on the map.
                    Performance (%) denotes the ratio between the actual and scheduled trip duration.
                </div>
                <Form.Group inline className="inputGroup">
                    {(tableTypeState === aggregatedTripsLevelType || tableTypeState === tripsDetailsLevelType) &&
                        <Form.Field>
                            <Button
                                content={tableTypeState === aggregatedTripsLevelType ? 'Back to Routes' : 'Back to Trips'}
                                onClick={handleBackClick}
                                className="fieldControl"
                            />
                        </Form.Field>
                    }
                    <StartAndEndDatesField {...{
                        startDate: startDateState,
                        setStartDate: setStartDateState,
                        endDate: endDateState,
                        setEndDate: setEndDateState,
                    }} />
                    <Form.Field>
                        <Radio
                            label="All days of the week"
                            name="radioGroup"
                            value="all"
                            defaultChecked
                            checked={daysFilterState === 'all'}
                            onChange={daysFilterChange}
                            className="fieldControl"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label="Mon-Fri"
                            name="radioGroup"
                            value="businessDays"
                            defaultChecked
                            checked={daysFilterState === 'businessDays'}
                            onChange={daysFilterChange}
                            className="fieldControl"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label="Sat-Sun"
                            name="radioGroup"
                            value="weekend"
                            defaultChecked
                            checked={daysFilterState === 'weekend'}
                            onChange={daysFilterChange}
                            className="fieldControl"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={handleApplyClick}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                {performanceByDatesState.length > 0 && tableTypeState === routeLevelTableType &&
                    <Form.Group className="rowGroup">
                        <Form.Field width={14}>
                            <PerformanceByDatesChart performanceByDates={performanceByDatesState} />
                        </Form.Field>
                        <Form.Field width={2}>
                            {performanceValueState &&
                                <Popup
                                    content={`Performance for ${moment(performanceValueState.date).format('M/DD/YYYY')}`}
                                    trigger={
                                        <Statistic
                                            size="small"
                                            label="Performance"
                                            value={performanceValueState.performance}
                                            style={styles.statistic}
                                        />
                                    }
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                }
                <Form.Group>
                    <Form.Field>
                        {getTable()}
                    </Form.Field>
                </Form.Group>
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(OverallPerformanceForm);