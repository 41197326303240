import { Thresholds } from './_interfaces';

export const defaultThresholds: Thresholds = {
    bunchedError: 5 * 60, //5min
    bunchedWarning: 8 * 60, //8min
    spreadError: 12 * 60, //12min
    spreadWarning: 15 * 60, //15min
};

interface AgenciesThresholds {
    [agencyId: string]: Thresholds;
}

const storageKey = 'TIQ-LiveMap--HeadwayThresholds';

const agenciesThresholds: AgenciesThresholds = (() => {
    let result: AgenciesThresholds | undefined;
    if (window.localStorage) {
        const s = window.localStorage.getItem(storageKey);
        if (s) result = JSON.parse(s) as AgenciesThresholds;
    }
    return result || {};
})();

export function saveThresholds(agencyId: string, value: Thresholds) {
    agenciesThresholds[agencyId] = value;
    if (window.localStorage) {
        const s = JSON.stringify(agenciesThresholds);
        window.localStorage.setItem(storageKey, s);
    }
}

export function loadThresholds(agencyId: string): Thresholds {
    if (!(agencyId in agenciesThresholds)) {
        saveThresholds(agencyId, { ...defaultThresholds });
    }
    return agenciesThresholds[agencyId];
}