import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WrongCoordinatesVehicle } from '../../../types/anomaliesTypes';
import { TableSortProperties } from '../../../types/types';
import WrongCoordinatesVehiclesTableRow from './WrongCoordinatesVehiclesTableRow';


const styles = {
    table: {
        display: 'block',
        maxHeight: '385px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    tableDataState: TableSortProperties<WrongCoordinatesVehicle>;
    rows: WrongCoordinatesVehicle[],
    selectedVehicleId: string | undefined,
    selectedRowHandler: (selectedVehicleId: string) => void;
    columnSortHandler: (column: string) => void;
}

const WrongCoordinatesVehiclesTable: React.FC<Props> = ({ tableDataState, selectedVehicleId, selectedRowHandler, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;
    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'id' ? direction : undefined}
                            onClick={() => columnSortHandler('id')}>
                            Vehicle
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'onShape' ? direction : undefined}
                            onClick={() => columnSortHandler('onShape')}>
                            Positions on Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'outShape' ? direction : undefined}
                            onClick={() => columnSortHandler('outShape')}>
                            Positions outside Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'outShapePcnt' ? direction : undefined}
                            onClick={() => columnSortHandler('outShapePcnt')}>
                            Wrong Positions, %
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <WrongCoordinatesVehiclesTableRow key={index} row={row} selectedVehicleId={selectedVehicleId} selectedRowHandler={selectedRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default WrongCoordinatesVehiclesTable;