import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RequestParameters } from '../types/apiTypes';
import { OrgLocation } from '../types/types';
import { getValidUserFromLocalStorage, isUserExpired } from '../utilities/user';

axios.defaults.baseURL = config.ApiUrl;

class Api {
    static readonly apiClient: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    static async doGetRequestAsync<T>(url: string) {
        try {
            const response = await axios.get<T>(url);
            return response.data;
        } catch (err) {
            Api.catchError('doGetRequestAsync', url, err);
            throw err;
        }
    }
    static setHeaders() {
        const user = getValidUserFromLocalStorage();
        if (user === null) return;
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
    }

    static async doAuthGetRequestAsync<T>(url: string) {
        try {
            this.setHeaders();
            const response = await axios.get<T>(url);
            return response.data;
        } catch(err) {
            Api.catchError('doAuthGetRequestAsync', url, err);
            throw err;
        }
    }

    static async doAuthNotificationApiGetRequestAsync(url: string) {
        try {
            this.setHeaders();
            const response = await axios({ url, baseURL: config.NotificationsApiUrl });
            return response.data;
        } catch (err) {
            Api.catchError('doAuthNotificationApiGetRequestAsync', url, err);
            throw err;
        }
    }

    static async doAuthGetFileRequestAsync(url: string) {
        try {
            this.setHeaders();
            const response = await axios({
                url,
                method: 'GET',
                responseType: 'blob', // important
            });
            return response.data;
        } catch (err) {
            Api.catchError('doAuthGetFileRequestAsync', url, err);
            throw err;
        }
    }

    static async doAuthPostRequestAsync<T, R>(url: string, data?: T, parameters?: RequestParameters) {

        try {
            this.setHeaders();
            const config = Api.getConfig(parameters);
            const response = await axios.post<R>(url, data, config);
            return response.data;
        } catch (err) {
            Api.catchError('doAuthPostRequestAsync', url, err);
            throw err;
        }
    }
    static async doAuthPatchRequestAsync<T, R>(url: string, data?: T, parameters?: RequestParameters) {
        try {
            this.setHeaders();
            const config = Api.getConfig(parameters);
            const response = await axios.patch<R>(url, data, config);
            return response.data;
        } catch (err) {
            Api.catchError('doAuthPatchRequestAsync', url, err);
            throw err;
        }
    }
    static async doAuthDeleteRequestAsync<T, R>(url: string, data?: T) {
        try {
            this.setHeaders();
            const response = await axios.delete<R>(url, data);
            return response.data;
        } catch (err) {
            Api.catchError('doAuthDeleteRequestAsync', url, err);
            throw err;
        }
    }
    static async getOrgLocation(agencyId: string) {
        return await this.doAuthGetRequestAsync<OrgLocation>('/Service.svc/GetOrgLocation?orgId=' + agencyId);
    }
    static async refreshDataCache(forceNotExpired: boolean) {
        return await this.doAuthGetRequestAsync<string>('/Service.svc/RefreshGTFS?forceNotExpired=' + forceNotExpired);
    }

    static getConfig(parameters?: RequestParameters) {
        const config: AxiosRequestConfig = {};
        if (parameters) {
            config.headers = Object.fromEntries(parameters.headers.map(({ name, value }) => [`${name}`, value]));
        }
        return config;
    }

    static catchError(apiName: string, url: string, err: { response: { status: number } }) {
        if (err && err.response) {
            if (err.response.status === 401 && isUserExpired()) {
                window.location.href = '/login';
            }
            console.log(`api ${apiName} ${url} error: ${err.response}`);
        }
    }
}
  
export default Api;