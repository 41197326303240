import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { PredictionsAnalysisData } from './PredictionCoverageForm';

interface Props {
    row: PredictionsAnalysisData,
    selectedRow: string | undefined,
    selectedRowHandler: (selectedTrip: string) => void;
}

const ArrivalsForStopTableRow: React.FC<Props> = ({ row, selectedRow, selectedRowHandler }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row.key || '');
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedRow !== undefined && row.key !== undefined && selectedRow === row.key}>
            <Table.Cell textAlign="left">
                {row.indicator}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.short}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.mid1}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.mid2}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.long}
            </Table.Cell>
        </Table.Row>
    );
};

export default ArrivalsForStopTableRow;