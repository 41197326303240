import { GtfsFilesActionTypes, LOAD_GTFS_ERROR, LOAD_GTFS_SUCCESS } from '../actions/actionTypes';
import { GtfsFileStateType } from '../types/gtfsFilesTypes';

export default function gtfsFilesReducer(
    state: GtfsFileStateType[] = [],
    action: GtfsFilesActionTypes,
): GtfsFileStateType[] {
    if (action.type === LOAD_GTFS_SUCCESS) {
        return action.gtfsFiles;
    }else if (action.type === LOAD_GTFS_ERROR) {
        return [];
    } else {
        return state;
    }
}