import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { ColorsScaleRowData } from './StopDelaysForm';


const styles = {
    table: {
        minHeight: '720px',
    } as React.CSSProperties,
    tableHeader: {
        fontSize: '11px',
        width: '40px',
        maxWidth: '40px',
    } as React.CSSProperties,
};

interface Props {
    header: string;
    rows: ColorsScaleRowData[];
}

const ColorsScaleTable: React.FC<Props> = ({ header, rows }) => {
    const getColorCellStyle = (color: string): React.CSSProperties => {
        return {
            backgroundColor: color,
        } as React.CSSProperties;
    };

    return (
        <div>
            <Table compact singleLine basic="very" style={styles.table}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="2" textAlign="left" width={1} style={styles.tableHeader}>{header}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <Table.Row key={index}>
                            <Table.Cell style={getColorCellStyle(row.color)}></Table.Cell>
                            <Table.Cell width={3}>{row.description}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default ColorsScaleTable;