import * as d3 from 'd3';
import { DSVRowArray } from 'd3';
import JSZip from 'jszip';
import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import JSZipUtils from '../../../utilities/jszip-utils';

interface FileSetting {
    name: string;
    sort: number;
    visible: number;
}

export interface FileData {
    fileName: string;
    data: DSVRowArray<string>;
    sort: number;
    visible: number;
}

const fileSettings: FileSetting[] = [
    { name: 'calendar.txt', sort: 1, visible: 1 },
    { name: 'calendar_dates.txt', sort: 1, visible: 1 },
    { name: 'routes.txt', sort: 1, visible: 1 },
    { name: 'stop_times.txt', sort: 1, visible: 0 },
    { name: 'trips.txt', sort: 1, visible: 1 },
    { name: 'stops.txt', sort: 0, visible: 1 },
    { name: 'agency.txt', sort: 0, visible: 0 },
    { name: 'fare_attributes.txt', sort: 0, visible: 0 },
    { name: 'fare_rules.txt', sort: 0, visible: 0 },
    { name: 'shapes.txt', sort: 0, visible: 0 },
    { name: 'direction_names_exceptions.txt', sort: 0, visible: 0 },
    { name: 'beacons.txt', sort: 0, visible: 1 },
    { name: 'beacon_stops.txt', sort: 0, visible: 0 },
    { name: 'beacons_trip.txt', sort: 0, visible: 0 },
    { name: 'stop_majors.txt', sort: 0, visible: 0 },
    { name: 'routes_service.txt', sort: 0, visible: 0 },
    { name: 'trips_bays.txt', sort: 0, visible: 0 },
];

interface Props {
    onFilesChange: (file: FileData[]) => void;
    displayLoader: (display: boolean) => void;
    fileNameSource: string | undefined;
}

const processZipFile = async (onFilesChange: (files: FileData[]) => void, zip: JSZip) => {
    const files: FileData[] = [];
    for (const [filename] of Object.entries(zip.files)) {
        // load data for tables
        const settings = fileSettings.find((s) => s.name === filename);
        if (!settings || !zip)
            continue;
        const content = await zip.file(filename)?.async('string');
        const data = d3.csvParse(content as string);
        const fileData = {
            fileName: filename,
            data: data,
            sort: settings.sort,
            visible: settings.visible,
        };
        files.push(fileData);
    }
    onFilesChange(files);
};

const ZipLoader: React.FC<Props> = ({
                                        onFilesChange,
                                        displayLoader,
                                        fileNameSource,
                                    }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [inputFileName, setInputFileName] = useState<string | undefined>(undefined);
    const [inputShow, setInputShow] = useState<boolean>(true);

    useEffect(() => {
        if (fileNameSource !== undefined) {
            console.log(`load file ID:${fileNameSource} from Blob`);
            displayLoader(true);
            LoadFileFromBlob();
        }
    }, [fileNameSource]);

    function getPath() {
        return new URL(`gtfs/blob/${fileNameSource}`, new URL(config.ApiUrl)).toString();
    }

    const LoadFileFromBlob = async () => {
        if (fileNameSource != null) {
            try {
                JSZipUtils.getBinaryContent(getPath(), (err: Error, data: any) => {
                    if (err) {
                        throw err; // or handle err
                    }
                    JSZip.loadAsync(data).then(async function (zip) {
                        await processZipFile(onFilesChange, zip);
                    });
                });
            } catch (e) {
                console.log(e);
            } finally {
                displayLoader(false);
            }
        }
    };

    const onFileChangeHandler = (fileInput: React.ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        const filename = fileInput && fileInput.target && fileInput.target.files ? fileInput.target.files[0]?.name : '';
        setInputFileName(filename);
        setInputShow(false);

        reader.onload = async function (ev) {
            if (!ev || !ev.target || !ev.target.result)
                return;
            try {
                displayLoader(true);
                const jsZip = new JSZip();
                const zip: JSZip = await jsZip.loadAsync(ev.target.result);
                await processZipFile(onFilesChange, zip);
            } catch {

                console.error('Failed to open', filename, ' as ZIP file');
            } finally {
                displayLoader(false);
            }
        };

        reader.onerror = function (err) {
            console.error('Failed to read file', err);
        };

        const blob = fileInput && fileInput.target && fileInput.target.files ? fileInput.target.files[0] : null;
        if (blob)
            reader.readAsArrayBuffer(blob);
    };

    return (
        <Fragment>
            {fileNameSource === undefined && <div>
                <input type="file" id="file" name="file" onChange={onFileChangeHandler}
                       ref={fileInputRef}
                       style={{
                           display: inputShow ? 'block' : 'none',
                           border: 'none',
                           paddingLeft: 0,
                       }}
                />
                <br/>
                {inputFileName && <h4>File name: {inputFileName}</h4>}
            </div>
            }
        </Fragment>
    );
};

export default ZipLoader;