import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: '',
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(66, 220, 198)',
            pointBackgroundColor: 'rgb(66, 220, 198)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198)',
            pointHoverBorderColor: 'rgba(66, 220, 198)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            autoSkip: false,
            maxRotation: 0,
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                autoSkip: false,
                beginAtZero: true,
                stepSize: 1,
                maxTicksLimit: 10,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            labelColor: function () {
                return {
                    backgroundColor: '#42DCC6',
                    borderColor: '#42DCC6',
                };
            },
        },
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
};

interface Props {
    chartData: { value: number; period: string }[];
    axesLabelSign?: string;
    dataLabel: string;
}

const TrendsSimpleLineChart: React.FC<Props> = ({ chartData, axesLabelSign, dataLabel }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0], label: dataLabel, data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.value,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                yAxes: [{
                    ...prevState.scales.yAxes[0],
                    ticks: {
                        ...prevState.scales.yAxes[0].ticks,
                        callback: function (value: string) {
                            return axesLabelSign ? `${value} ${axesLabelSign}` : value;
                        },
                    },
                }],
            },
            tooltips: {
                ...prevState.tooltips,
                callbacks: {
                    ...prevState.tooltips.callbacks,
                    label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number, index: number }) {
                        return `${chartData.map(v => v.value)[tooltipItem.index]} ${axesLabelSign}`;
                    },
                },
            },

        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={optionsState as unknown as ChartOptions}
        />
    );
};

export default TrendsSimpleLineChart;
