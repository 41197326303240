import * as React from 'react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Button, Dropdown, DropdownOnSearchChangeData, DropdownProps, Form } from 'semantic-ui-react';
import { DropDownStateType } from '../../types/types';

interface Props {
    time: string;
    date: string;
    onTimeChanged: (newValue: string) => void;
    onDateChanged: (newValue: string) => void;
    onExchange: () => void;
    onReset: () => void;
    onGo: () => void;
    fromAddressState: DropDownStateType;
    toAddressState: DropDownStateType;
    noResultAddressMessage: string;
    handleFromSearchChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => void;
    handleToSearchChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => void
    fromAddressLoadingState: boolean;
    toAddressLoadingState: boolean
    handleFromAddressChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
    handleToAddressChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

const styles = {
    fromDropdown: {
        maxWidth: 'min-content',
        marginRight: '10px',
    } as React.CSSProperties,
    toDropdown: {
        maxWidth: 'min-content',
        marginLeft: '10px',
    } as React.CSSProperties,
};

function isDefined(s: string): boolean {
    return s.trim() !== '';
}

function isResetEnabled(props: Props): boolean {
    return Boolean(props.fromAddressState.selectedValue || props.toAddressState.selectedValue || isDefined(props.time));
}

function isGoEnabled(props: Props): boolean {
    return Boolean(props.fromAddressState.selectedValue && props.toAddressState.selectedValue && isDefined(props.time));
}

const TripPlannerInputs: React.FC<Props> = (props: Props) => {
    const handleTimeChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
        props.onTimeChanged && props.onTimeChanged(data.value);
    };
    const handleDateChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        props.onDateChanged && props.onDateChanged(data.value);
    };
    const handleExchangeClick = () => {
        props.onExchange && props.onExchange();
    };
    return (
        <>
            <Form.Group className="inputGroup" inline>
                <Dropdown
                    width={3}
                    placeholder="Start typing From address"
                    search
                    selection
                    openOnFocus={false}
                    selectOnBlur={false}
                    options={props.fromAddressState.options}
                    value={props.fromAddressState.selectedValue}
                    noResultsMessage={props.noResultAddressMessage}
                    style={styles.fromDropdown}
                    onChange={props.handleFromAddressChange}
                    onSearchChange={props.handleFromSearchChange}
                    loading={props.fromAddressLoadingState}
                />
                <Button
                    icon="exchange"
                    disabled={props.fromAddressState.selectedValue === props.toAddressState.selectedValue}
                    onClick={handleExchangeClick}
                    title="Exchange"
                />
                <Dropdown
                    width={3}
                    placeholder="Start typing To address"
                    search
                    selection
                    openOnFocus={false}
                    selectOnBlur={false}
                    options={props.toAddressState.options}
                    value={props.toAddressState.selectedValue}
                    noResultsMessage={props.noResultAddressMessage}
                    style={styles.toDropdown}
                    onChange={props.handleToAddressChange}
                    onSearchChange={props.handleToSearchChange}
                    loading={props.toAddressLoadingState}
                />
                <Form.Field width={3} >
                    <DateInput
                        name="dateInput"
                        fluid
                        dateFormat="YYYY-MM-DD"
                        placeholder="Select date"
                        value={props.date}
                        iconPosition="left"
                        popupPosition="bottom center"
                        closable={true}
                        animation="fade"
                        onChange={handleDateChange}
                    />
                    <TimeInput
                        name="timeInput"
                        timeFormat="AMPM"
                        placeholder="Select time"
                        value={props.time}
                        onChange={handleTimeChange}
                        iconPosition="left"
                        popupPosition="bottom center"
                        closable={true}
                        animation="fade"
                    />
                </Form.Field>
                <Button.Group>
                    <Button primary disabled={!isGoEnabled(props)} onClick={props.onGo}>GO</Button>
                    <Button disabled={!isResetEnabled(props)} onClick={props.onReset}>Reset</Button>
                </Button.Group>
            </Form.Group>
        </>
    );
};

export default TripPlannerInputs;
