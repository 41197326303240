import api from '../api/gtfsStaticApi';
import { FullRouteInfo, GtfsDirectionVariantModel, GtfsRoute, GtfsStopTimeEntity, GtfsTripAndStops, ShapePoint, Trip, TripsRequest } from '../types/gtfsTypes';
import { KeyValuePair } from '../types/types';

export async function getRoutes(agencyId: string, date?: string): Promise<GtfsRoute[]> {
    const result = await api.getRoutes(agencyId, date);
    return result.data || [];
}
export async function getRoutesPairs(agencyId: string, date?: string): Promise<KeyValuePair<string, string>[]> {
    const result = await api.getRoutes(agencyId, date);
    return result.data ? result.data.map(d => ({ key: d.routeId, value: d.routeName })) : [];
}
export async function getDirections(routeId: string) {
    const result = await api.getDirections(routeId);
    return result.data || [];
}
export async function getFullRouteInfo(routeId: string): Promise<FullRouteInfo> {
    const result = await api.getFullRouteInfo(routeId);
    return result.data;
}
export async function getFullRouteInfoByRouteName(agencyId: string, routeName: string): Promise<FullRouteInfo> {
    const result = await api.getFullRouteInfoByRouteName(agencyId, routeName);
    return result.data;
}
export async function getTripsForDateAndRoute(request: TripsRequest): Promise<Trip[]> {
    const result = await api.getTripsForDateAndRoute(request);
    return result.data || [];
}
export async function getTripsForTimeAndDirection(request: TripsRequest) {
    const result = await api.getTripsForTimeAndDirection(request);
    return result.data || [];
}
export async function getTripsForDateAndDirection(request: TripsRequest) {
    const result = await api.getTripsForDateAndDirection(request);
    return result.data || [];
}
export async function getTripsForDateAndDirectionVariant(request: TripsRequest) {
    const result = await api.getTripsForDateAndDirectionVariant(request);
    return result.data || [];
}
export async function getShapeData(agencyId: string, serviceDate: string, shapeId: string): Promise<ShapePoint[]> {
    const result = await api.getShapeData(agencyId, serviceDate, shapeId);
    return result.data || [];
}
export async function getSparkTripsByDirectionVariantIntertnalId(agencyId: string, serviceDate: string, directionVariantInternalId: string) {
    const result = await api.getSparkTripsByDirectionVariantIntertnalId(agencyId, serviceDate, directionVariantInternalId);
    return result.data || [];
}
export async function getGtfsStopTimesByStopAndShape(agencyId: string, serviceDate: string, shapeId: string, stopId: string): Promise<GtfsStopTimeEntity[]> {
    const result = await api.getGtfsStopTimesByStopAndShape(agencyId, serviceDate, shapeId, stopId);
    return result.data || [];
}
export async function getGtfsTripsAndStops(agencyId: string, reportDate: string): Promise<GtfsTripAndStops[]> {
    const result = await api.getGtfsTripsAndStops(agencyId, reportDate);
    return result.data || [];
}
export async function getGtfsDirectionVariants(agencyId: string, startDate: string, endDate: string): Promise<GtfsDirectionVariantModel[]> {
    const result = await api.getGtfsDirectionVariants(agencyId, startDate, endDate);
    return result.data || [];
}
export async function getDirectionVariantsByRoute(routeId: string, time: string) {
    const result = await api.getDirectionVariantsByRoute(routeId, time);
    return result.data || [];
}
export async function getDirectionVariantsForStop(agencyId: string, stopId: string, routeId: string | null = null, time: string | null = null) {
    const result = await api.getDirectionVariantsForStop(agencyId, stopId, routeId, time);
    return result.data || [];
}
export async function getAgencyLocation(agencyId: string) {
    return await api.getAgencyLocation(agencyId);
}