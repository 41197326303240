import { OperationResult } from '../types/apiTypes';
import { AnalysisByPercentiles, AnalysisByPercentilesRequest, BasePerformanceRequest, OverallPerformance, RouteLevelPerformance, RunningTimesComparison, RunningTimesComparisonRequest, TransitMeansOverall, TravelTimeBetweenStops, TripDetailsPerformance, TripLevelPerformanceRequest } from '../types/runningTimeAnalyticsTypes';
import { AdjustmentReport, AdjustmentReportRequest } from '../types/scheduleAdjustmentTypes';
import { KeyValuePair } from '../types/types';
import api from './api';
import { buildUrlString, SearchParams } from './helpers';

class RunningTimeAnalyticsApi {

    static doAuthGetRequestAsync = async <T>(url: string) => await api.doAuthGetRequestAsync<T>(`/runningtimeanalytics/${url}`);

    static async getStops(agencyId: string, filter: string, exactMatch: boolean) {
        return await this.doAuthGetRequestAsync<OperationResult<KeyValuePair<string, string>[]>>(`${agencyId}/stops/${filter}/${exactMatch}`);
    }
    static async getDirectionsByRoute(agencyId: string, routeName: string) {
        return await this.doAuthGetRequestAsync<OperationResult<string[]>>(`${agencyId}/routes/directions?routeName=${routeName}`);
    }
    static async getDirectionVariantsByCardinalDirection(agencyId: string, routeName: string, cardinalDirection: string) {
        return await this.doAuthGetRequestAsync<OperationResult<string[]>>(`${agencyId}/routes/directions/directionVariants?routeName=${routeName}&cardinalDirection=${cardinalDirection}`);
    }
    static async getTripLevelPerformance({ agencyId, routeName, directionVariantInternalId, startDate, endDate, daysFilter }: TripLevelPerformanceRequest) {
        return await this.doAuthGetRequestAsync<OperationResult<TripDetailsPerformance[]>>(`${agencyId}/routes/directions/datePeriod/${startDate}/${endDate}/filter/${daysFilter}/tripsPerformance?routeName=${routeName}&cardinalDirection=${directionVariantInternalId}`);
    }
    static async getRouteLevelPerformance({ agencyId, startDate, endDate, daysFilter }: BasePerformanceRequest) {
        return await this.doAuthGetRequestAsync<OperationResult<RouteLevelPerformance[]>>(`${agencyId}/datePeriod/${startDate}/${endDate}/filter/${daysFilter}/routesPerformance`);
    }
    static async getOverallPerformance({ agencyId, startDate, endDate, daysFilter }: BasePerformanceRequest) {
        return await this.doAuthGetRequestAsync<OperationResult<OverallPerformance>>(`${agencyId}/datePeriod/${startDate}/${endDate}/filter/${daysFilter}/overallPerformance`);
    }
    static async getAnalysisByPercentiles({ agencyId, routeName, startDate, endDate }: AnalysisByPercentilesRequest) {
        const queryParams: SearchParams = {
            routeName,
        };
        const url = buildUrlString(queryParams, agencyId, 'dateperiod', startDate, endDate, 'analysisByPercentiles');
        return await this.doAuthGetRequestAsync<OperationResult<AnalysisByPercentiles[]>>(url);
    }
    static async getRunningTimesComparison({ agencyId, directionVariantInternalId, startDate1, endDate1, startDate2, endDate2 }: RunningTimesComparisonRequest) {
        const queryParams: SearchParams = {
            directionVariantInternalId,
        };
        const url = buildUrlString(queryParams, agencyId, 'datePeriod1', startDate1, endDate1, 'datePeriod2', startDate2, endDate2, 'comparisonRunningTimes');
        return await this.doAuthGetRequestAsync<OperationResult<RunningTimesComparison>>(url);
    }
    static async getScheduledAdjustment({ agencyId, startDate, endDate, directionVariantId, weekDays }: AdjustmentReportRequest) {
        const url = buildUrlString(null, agencyId, 'ScheduleAdjustment', directionVariantId, 'datePeriod', startDate, endDate, weekDays);
        return await this.doAuthGetRequestAsync<OperationResult<AdjustmentReport>>(url);
    }
    static async getTransitMeansOverall(agencyId: string, reportDate: string, directionVariantInternalId: string) {
        const queryParams: SearchParams = {
            directionVariantInternalId,
        };
        const url = buildUrlString(queryParams, agencyId, reportDate, 'transitMeansOverall');
        return await this.doAuthGetRequestAsync<OperationResult<TransitMeansOverall[]>>(url);
    }
    static async getTravelTimeBetweenStops(agencyId: string, stopId1: string, stopId2: string) {
        const queryParams: SearchParams = {
            stopId1,
            stopId2,
        };
        const url = buildUrlString(queryParams, agencyId, 'travelTimeBetweenStops');
        return await this.doAuthGetRequestAsync<OperationResult<TravelTimeBetweenStops[]>>(url);
    }
}

export default RunningTimeAnalyticsApi;