import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { BusBunchingArrival } from '../../../../types/busBunchingTypes';
import Utils from '../../../../utilities/utils';


interface Props {
    row: BusBunchingArrival;
}

const BusArrivalsTableRow: React.FC<Props> = ({ row }) => {


    return (
        <Table.Row >
            <Table.Cell>{row.tripId1}</Table.Cell>
            <Table.Cell>{row.tripId2}</Table.Cell>
            <Table.Cell>{row.vehicleId1}</Table.Cell>
            <Table.Cell>{row.vehicleId2}</Table.Cell>
            <Table.Cell>{row.stopId}</Table.Cell>
            <Table.Cell>{row.stopSequence}</Table.Cell>
            <Table.Cell>{Utils.kmToMiles(row.traveledKm)}</Table.Cell>
            <Table.Cell>
                {moment.parseZone(row.actualArrivalTime1).format('h:mm:ss A')}
            </Table.Cell>
            <Table.Cell>
                {moment.parseZone(row.actualArrivalTime2).format('h:mm:ss A')}
            </Table.Cell>
            
        </Table.Row>
    );
};

export default BusArrivalsTableRow;