import React from 'react';
import BlockUi from 'react-block-ui';
import { NavLink } from 'react-router-dom';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Checkbox, Divider, List, Segment } from 'semantic-ui-react';
import { AgencyModel, BphDirectionModel, BphRouteModel, BphStopModel } from './_models';
import { BusPositionHistoryContext } from './BusHistoryContext';
import BusHistorySchedule from './BusHistorySchedule';
import { DirectionsDropdown, RouteDropdown, StopsDropdown } from './BusHistorySelectors';
import BusHistorySlider from './BusHistorySlider';
import BusHistorySliderInfo from './BusHistorySliderInfo';
import DirectionInfo from './DirectionInfo';

const styles: { [key: string]: React.CSSProperties } = {
    date: {
        display: 'inline-block',
        width: '100%',
    },
    time: {
        display: 'inline-block',
        width: '100%',
    },
    navLink: {
        color: '#337ab7',
        float: 'right',
        fontFamily: '"Segoe UI",Verdana,Helvetica,Sans-Serif',
    },
};

function simpleDebounce(callback: (...args: any[]) => void, delay: number, ..._args: any[]) {
    let timer: number;
    return (...args: any[]) => {
        if (typeof timer === 'number') {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(() => callback(...args), delay);
    };
}

interface Props {
    agency: AgencyModel;
}

export default function BusHistoryPane(props: Props) {
    const context = React.useContext(BusPositionHistoryContext);

    const [searchText, setSearchText] = React.useState('');
    const debounceSearch = React.useCallback(
        simpleDebounce((text: string) => {
            context.setStopsSearchString(text);
        }, 1000),
        [],
    );

    React.useEffect(() => {
        context.setAgency(props.agency);
    }, [props.agency]);

    React.useEffect(() => {
        debounceSearch(searchText);
    }, [searchText]);

    const mapDiv = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (mapDiv.current === null) return;
        context.onMapReady(mapDiv.current);
    }, [mapDiv.current]);

    const handleDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        context.updateDateTime(value, null);
    };
    const handleTimeChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        context.updateDateTime(null, value);
    };
    const onRouteSelected = (route: BphRouteModel | null) => {
        context.selectRoute(route);
    };
    const handleStopChanged = (value: BphStopModel | null) => {
        context.selectStop(value);
    };
    const handleDirectionChanged = (value: BphDirectionModel | null) => {
        context.selectDirection(value);
    };
    const handleSearchChanged = (searchQuery: string) => {
        setSearchText(searchQuery);
    };

    if (!props.agency) return null;

    return (
        <div className="bushistory-container">
            <BlockUi blocking={context.routesLoading || context.directionsLoading || context.historyLoading}>
                <Segment className="content-pane">
                    <div className="ctl-pane">
                        <div className="pane1">
                            <List className="sidebarlist">
                                <List.Item>
                                    <NavLink to="/bushistorybybusnumber" style={styles.navLink}> Look up by bus number...</NavLink>
                                </List.Item>
                                <List.Item>
                                    <DateInput className="bph-date"
                                        name="busHistoryDate"
                                        dateFormat="YYYY-MM-DD"
                                        placeholder="Select date"
                                        value={context.dateTime.selectedDate}
                                        iconPosition="left"
                                        popupPosition="bottom center"
                                        closable={true}
                                        startMode="day"
                                        preserveViewMode={false}
                                        animation="fade"
                                        disableMinute={false}
                                        style={styles.date}
                                        onChange={handleDateChange}
                                    />
                                    <TimeInput className="bph-time"
                                        name="busHistoryTime"
                                        timeFormat="AMPM"
                                        placeholder="Select day time"
                                        value={context.dateTime.selectedTime}
                                        onChange={handleTimeChange}
                                        iconPosition="left"
                                        popupPosition="bottom center"
                                        closable={true}
                                        animation="fade"
                                        style={styles.time}
                                    />
                                </List.Item>
                                {context.routesLoading
                                    ? null
                                    : <>
                                        <List.Item>
                                            <RouteDropdown
                                                items={context.routesState.routes}
                                                value={context.routesState.selectedRoute}
                                                onChanged={onRouteSelected}
                                            />
                                        </List.Item>
                                        {!context.routesState.routes
                                            ? null
                                            : <>
                                                <List.Item>
                                                    <StopsDropdown
                                                        loading={context.stopsLoading}
                                                        items={context.stopsState.stops}
                                                        value={context.stopsState.selectedStop}
                                                        onChanged={handleStopChanged}
                                                        noResultsMessage={context.stopsNoResultMessageState}
                                                        onSearchChanged={handleSearchChanged}
                                                    />
                                                </List.Item>
                                                <Divider />
                                                <List.Item>
                                                    {context.directionsLoading || (!context.routesState.selectedRoute && !context.stopsState.selectedStop)
                                                        ? null :
                                                        <DirectionsDropdown
                                                            loading={context.directionsLoading}
                                                            items={context.directionsState.directions}
                                                            value={context.directionsState.selectedDirection}
                                                            selectedStopId={context.stopsState?.selectedStop ? context.stopsState?.selectedStop!.stopId : ''}
                                                            onChanged={handleDirectionChanged}
                                                        />
                                                    }
                                                </List.Item>
                                                <List.Item>
                                                    {!context.directionsState.selectedDirection
                                                        ? null :
                                                        <Checkbox label="Display Stops" onChange={(_e, { checked }) => context.onDisplayDirectionStopsChanged(Boolean(checked))} />
                                                    }
                                                </List.Item>
                                            </>
                                        }
                                    </>
                                }
                                {context.historyLoading
                                    ? null :
                                    context.sliderOptions &&
                                    <List.Item>
                                        <BusHistorySlider
                                            maxValue={context.sliderOptions.maxValue}
                                            sliderValue={context.sliderOptions.sliderValue}
                                            handleSliderChange={v => context.onSliderTimeChanged(v)}
                                        />
                                    </List.Item>
                                }
                            </List>
                        </div>
                        <div className="pane2">
                            {context.busPositionsState.length === 0 ?
                                context.directionsInfo.length > 0 &&
                                <DirectionInfo directions={context.directionsInfo} />
                                : !context.sliderInfo
                                    ? null :
                                    <BusHistorySliderInfo {...context.sliderInfo} />
                            }
                        </div>
                    </div>
                    {!context.stopsState.selectedStop || !context.directionsState.selectedDirection
                        ? null :
                        <div className="inf-pane">
                            <div>
                                <BusHistorySchedule />
                            </div>
                        </div>
                    }
                    <div className="map-pane">
                        <div ref={mapDiv} />
                    </div>
                </Segment>
            </BlockUi>
        </div>
    );
}
