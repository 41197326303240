import './RouteVariantsSelectorStyles.css';
import { Property } from 'csstype';
import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import { IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';

interface VariantSelectorProps {
    data: IDirectionModel;
    cssColor: string;
    onChecked?: (variant: IDirectionModel, checked: boolean) => void;
    markCssColor?: (data: IDirectionModel) => Property.BackgroundColor;
}

function VariantSelector(props: VariantSelectorProps) {
    const [checked, setChecked] = React.useState(props.data.selected);
    const variant = props.data;
    const handleChecked = (checked: boolean) => {
        props.data.selected = checked;
        setChecked(checked);
        props.onChecked && props.onChecked(props.data, checked);
    };
    const markCssColor = props.markCssColor && props.markCssColor(props.data);
    return (
        <Grid.Column width={8}
            style={{ backgroundColor: props.cssColor }}
            className={`item${variant.selected ? ' selected' : ''}`}
        >
            <div>
                <label
                    className={`variant${variant.selected ? ' checked' : ''}`}
                    htmlFor={`variant${variant.directionVariantId}`}
                >
                    <input type="checkbox"
                        id={`variant${variant.directionVariantId}`}
                        checked={checked}
                        onChange={e => handleChecked(e.target.checked)}
                    />
                    <div className="direction" style={{ color: props.cssColor }}>
                        {variant.cardinalDirectionShort}
                        {!props.markCssColor
                            ? null :
                            <>
                                <br />
                                <div className="color-mark" style={{ backgroundColor: markCssColor, border: '1px solid rgba(0,0,0,0.25)' }}></div>
                            </>
                        }
                    </div>
                    <div className="endpoints">
                        <div className="from">
                            <div className="spot"></div>
                            <div className="stem"></div>
                            <div>{variant.fromStopName}</div>
                        </div>
                        <div className="to">
                            <div className="spot"></div>
                            <div className="stem"></div>
                            <div>{variant.toStopName}</div>
                        </div>
                    </div>
                </label>
            </div>
        </Grid.Column>
    );
}

interface RouteSelectorProps {
    route: IRouteModel;
    onChecked?: (route: IRouteModel, variant: IDirectionModel, checked: boolean) => void;
    markCssColor?: (data: IDirectionModel) => Property.BackgroundColor;
}

export default function RouteVariantsSelector(props: RouteSelectorProps) {
    const r = props.route;
    const handleChecked = (variant: IDirectionModel, checked: boolean): void => {
        variant.selected = checked;
        props.onChecked && props.onChecked(r, variant, checked);
    };
    return (
        <div className="route" style={{ borderColor: r.cssColor }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div className="title" style={{ backgroundColor: r.cssColor }}>
                            <div>{r.routeName}</div>
                        </div>
                    </Grid.Column>
                    {
                        r.directions?.map((variant, index) => {
                            return (
                                <VariantSelector
                                    key={index}
                                    cssColor={r.cssColor}
                                    data={variant}
                                    onChecked={handleChecked}
                                    markCssColor={props.markCssColor}
                                />
                            );
                        })
                    }
                </Grid.Row>
            </Grid>
        </div>
    );
}