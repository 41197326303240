import { User } from 'oidc-client';
import React from 'react';
import { Segment } from 'semantic-ui-react';
import { LogoFullTransitIq } from './logo';
import { Navigation } from './navigation';

interface Props {
    user: User | null;
}

export const TransitIqSidebar: React.FC<Props> = ({ user }) => {

        return (
            <Segment className="transit-iq-sidebar" vertical inverted>
                <LogoFullTransitIq />
                <Navigation user={user} />
            </Segment>
        );
    };
