import { OperationResult, RequestParameters } from '../types/apiTypes';
import { CreateGtfsModel, GtfsFileStateType, TripsTimesType, UpdateGtfsModel } from '../types/gtfsFilesTypes';
import api from './api';
import { buildUrlString, SearchParams } from './helpers';


class GtfsApi {

    static doAuthGetRequestAsync = async <T>(url: string) => await api.doAuthGetRequestAsync<T>('/gtfs' + url);
    static doAuthPostRequestAsync = async <T, R>(url: string, data?: T, parameters?: RequestParameters) => await api.doAuthPostRequestAsync<T, R>('/gtfs' + url, data, parameters);
    static doAuthPatchRequestAsync = async <T, R>(url: string, data?: T, parameters?: RequestParameters) => await api.doAuthPatchRequestAsync<T, R>('/gtfs' + url, data, parameters);
    static doAuthDeleteRequestAsync = async <T, R>(url: string, data?: T) => await api.doAuthDeleteRequestAsync<T, R>('/gtfs' + url, data);

    static async getGtfsFiles(agencyId: string): Promise<OperationResult<GtfsFileStateType[]>> {
        const queryParams: SearchParams = {
            agencyId,
        };
        const url = buildUrlString(queryParams);
        return await this.doAuthGetRequestAsync<OperationResult<GtfsFileStateType[]>>(url);
    }

    static async getTripTimes(fileId: string): Promise<OperationResult<TripsTimesType[]>> {
        const url = buildUrlString(null, fileId);
        return await this.doAuthGetRequestAsync<OperationResult<TripsTimesType[]>>(`/trip-times/${url}`);
    }

    static async getGtfsFileById(gtfsId: string): Promise<OperationResult<GtfsFileStateType>> {
        const url = buildUrlString(null, gtfsId);
        return await this.doAuthGetRequestAsync<OperationResult<GtfsFileStateType>>(`/${url}`);
    }

    static async postGtfs(model: CreateGtfsModel): Promise<OperationResult<string>> {
        const form = new FormData();
        for (const key in model) {
            form.append(key, (model as any)[key]);
        }
        const parameters: RequestParameters = {
            headers: [{
                name: 'Content-Type',
                value: 'multipart/form-data',
            }],
        };
        return await this.doAuthPostRequestAsync<FormData, OperationResult<string>>('', form, parameters);
    }
    static async updateGtfs(gtfsId: string, model: UpdateGtfsModel): Promise<OperationResult<GtfsFileStateType>> {
        const url = buildUrlString(null, gtfsId);
        return await this.doAuthPatchRequestAsync<UpdateGtfsModel, OperationResult<GtfsFileStateType>>(`/${url}`, model);
    }
    static async deleteGtfs(gtfsId: string): Promise<OperationResult<string>> {
        const url = buildUrlString(null, gtfsId);
        return await this.doAuthDeleteRequestAsync<void, OperationResult<string>>(`/${url}`);
    }
}

export default GtfsApi;