import TripPlannerApi from '../api/tripPlannerApi';
import { OperationResult } from '../types/apiTypes';
import { FullRouteInfo, GtfsRoute } from '../types/gtfsTypes';
import { AutocompleteResult, ItineraryDto } from '../types/tripPlannelTypes';

export async function getActiveRoutes(agencyId: string, serviceDate: string): Promise<OperationResult<GtfsRoute[]>> {
    return await TripPlannerApi.getActiveRoutes (agencyId, serviceDate);
}

export async function getFullRouteInfo(routeId: string, serviceDate: string): Promise<OperationResult<FullRouteInfo>> {
    return await TripPlannerApi.getFullRouteInfo(routeId, serviceDate);
}

export async function findAddress(latitude: number, longitude: number): Promise<string> {
    return await TripPlannerApi.findAddress(latitude, longitude);
}

export async function autoCompleteAddress(agencyId: string, searchStr: string): Promise<AutocompleteResult[]> {
    return await TripPlannerApi.autoCompleteAddress(agencyId, searchStr);
}

export async function buildTripStartingAt(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
    startTime: string,
    startDate: string,
    includeShape = true,
    useTrains = true,
    useBuses = true,
    useBicycles = false,
): Promise<ItineraryDto[] | null> {
    return await TripPlannerApi.buildTripStartingAt(lat1, lon1, lat2, lon2, startTime, startDate, includeShape, useTrains, useBuses, useBicycles);
}
