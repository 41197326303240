export class Statuses {
    static New = 'New' as const;
    static Published = 'Published' as const;
    static Unpublished = 'Unpublished' as const;
    static ValidationInProgress = 'Validation is In Progress' as const;
}

export class GtfsPageType {
    static Details = 'Details' as const;
    static Editor = 'Editor' as const;
    static Viewer = 'Viewer' as const;
    static Loader = 'Loader' as const;
}