import * as React from 'react';
import { CSVLink } from 'react-csv';
import { Table } from 'semantic-ui-react';
import { BunchingItem, BunchingVehicleData } from '../../../../types/busBunchingTypes';
import BusBucnhingTableRow from './BusBunchingTableRow';


const styles = {
    table: {
        display: 'block',
        maxHeight: '385px',
        overflowY: 'scroll',
    } as React.CSSProperties,
    exportLink: {
        float: 'right',
    } as React.CSSProperties,
};

const headers = [
    { label: 'Route', key: 'routeShortName' },
    { label: 'Vehicle 1', key: 'trip1.vehicle' },
    { label: 'Vehicle 2', key: 'trip2.vehicle' },
    { label: 'Trip 1', key: 'trip1.id' },
    { label: 'Trip 2', key: 'trip2.id' },
    { label: 'Trip Headsign', key: 'tripHeadsign' },
    { label: 'Headway Decrease %', key: 'meanDeltaPcnt' },
    { label: 'Stops', key: 'stopsCount' },
    { label: '% Bunched', key: 'bunchedStopsPcnt' },
];

interface Props {
    rows: BunchingItem[];
    selectedRow: BunchingVehicleData | undefined;
    selectedRowHandler: (selectedRow: BunchingVehicleData) => Promise<void>;
    minutesToAtz: number;
}

const BusBunchingTable: React.FC<Props> = ({ rows, selectedRow, selectedRowHandler, minutesToAtz }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Route</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle 1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Vehicle 2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip 1</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip 2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Trip Headsign</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Headway Decrease %</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Stops</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>% Bunched</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, index) => (
                        <BusBucnhingTableRow key={index} row={row} selectedRow={selectedRow} selectedRowHandler={selectedRowHandler} minutesToAtz={minutesToAtz} />
                    ))}
                </Table.Body>
            </Table>
            <CSVLink
                data={rows}
                filename={'BusBunching.csv'}
                headers={headers}
                target="_blank"
                style={styles.exportLink}
            >
                Export to CSV
            </CSVLink>
        </div>
    );
};

export default BusBunchingTable;