import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import { Accordion } from 'semantic-ui-react';
import { AppState } from '../../reducers';
import { getUser } from '../../selectors';
import { toNavigationGroupAccordionPanelShorthand, TransitIqRootRouteInNavigationProps, useActiveNavigationFirstLayerIndex } from './navigation-first-layer';
import { groupedRoutes } from './routes';
import { TransitIqRootRouteProps } from './TransitIqRootRoute';

const icon: Exclude<keyof TransitIqRootRouteInNavigationProps, keyof TransitIqRootRouteProps> = 'icon';

function isTransitIqRootRouteInNavigation(route: TransitIqRootRouteProps): route is TransitIqRootRouteInNavigationProps {
    return icon in route;
}

interface Props {
    user: User | null;
}

function routeRolesByUserRolesFilter(route: { roles: string[] }, user: User) {
    if (route.roles === undefined)
        return false;
    if (typeof (user.profile?.roles) === 'string')
        return route.roles
            .map(x => x.toLowerCase())
            .includes(user.profile?.roles.toLowerCase());
    if (Array.isArray(user.profile?.roles))
        return user.profile?.roles
            .map(x => x.toLowerCase())
            .filter((x: string) => route.roles.map(x => x.toLowerCase()).includes(x)).length > 0;
    return false;
}

function filterRoutes(user: User): TransitIqRootRouteInNavigationProps[]{
    return (groupedRoutes
        .filter((x: any) => x.hidden == undefined || !x.hidden)
        .filter((x: any) => routeRolesByUserRolesFilter(x, user))
        .filter(isTransitIqRootRouteInNavigation) as TransitIqRootRouteInNavigationProps[]);
}

export const Navigation: React.FC<Props> = ({ user }) => {

    const navigationFirstLayer = user !== null
        ? filterRoutes(user)
        : [];

    const navigationPanelsShorthand = toNavigationGroupAccordionPanelShorthand(navigationFirstLayer);
    return (
        <Accordion className="navigation" inverted fluid
                   defaultActiveIndex={useActiveNavigationFirstLayerIndex(navigationFirstLayer)}
                   panels={navigationPanelsShorthand}
        />
    );
};

export default connect(
    (state: AppState) => ({ user: getUser(state) }),
)(Navigation);