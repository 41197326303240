import { API_CALL_ERROR, API_CALL_SUCCESS, ApiCallActionTypes, BEGIN_API_CALL } from './actionTypes';

export function beginApiCall(): ApiCallActionTypes {
    return { type: BEGIN_API_CALL };
}

export function apiCallSuccess(): ApiCallActionTypes {
    return { type: API_CALL_SUCCESS };
}

export function apiCallError(): ApiCallActionTypes {
    return { type: API_CALL_ERROR };
}
