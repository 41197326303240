/**
 * Use with unions when the intent is to handle every case explicitly,
 * to ensure that every case has been exhausted, e.g.
 * ```
 * function isFoo(foobar: 'foo' | 'bar'): foobar is 'foo' {
 *     switch (foobar) {
 *         case 'foo': return true;
 *         case 'bar': return false;
 *         default: return impossible(foobar);
 *     }
 * }
 * ```
 * If the type of `foobar` changes to `'foo' | 'bar' | 'baz'`,
 * this code will error at compile-time, so we can't forget to add a
 * `case 'baz':` line to the function.
 * 
 * If `foobar` somehow has some other type at runtime despite the typing,
 * then we throw a runtime error.
 * @param improbable The value whose cases are supposed to be exhausted.
 */
export function impossible(improbable: never): never {
    console.error(improbable);
    throw new Error('Supposedly impossible case.');
}

/**
 * When we want to exhaustively handle some subset of a type, we can
 * artificially narrow (cast) a value, run exhaustive code on that,
 * and then use this to handle the other cases. For example,
 * ```
 * function to42(foobar: string): 4 | 2 | null {
 *     const pretend = foobar as 'foo' | 'bar';
 *     switch (pretend) {
 *         case 'foo': return 4;
 *         case 'bar': return 2;
 *         default: return otherwise(pretend, null);
 *     }
 * }
 * ```
 * @param _exhausted The value whose cases are supposed to be exhausted.
 * @param defResult The value to return in this case.
 */
export function otherwise<R>(_exhausted: never, defResult: R): R {
    return defResult;
}
