import blockUi from 'react-block-ui/lib/reduxMiddleware';
import { applyMiddleware, compose, createStore } from 'redux';
//import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import initialState from '../reducers/initialState';
import userManager from '../utilities/userManager';

export default function configureStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(thunk, blockUi),
        ),
    );
    return store;
}
