import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BunchesByKey } from '../../../../types/busBunchingTypes';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Bunches Count',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
        },
    ],
};

const options = {
    title: {
        display: false,
        position: 'top',
        text: 'By Weekday',
    },
    legend: {
        display: false,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            gridLines: {
                drawOnChartArea: false,
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            scaleLabel: {
                display: true,
                labelString: 'Number of buched vehicles',
            },
        }],
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                return `Bunches: ${tooltipItem.yLabel}`;
            },
        },
    },
    responsive: true,
    plugins: {
        crosshair: false,
    },
};

const BunchesByWeekDayChart: React.FC<{ chartData: BunchesByKey[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const labels = chartData.map(d => d.key);
        const values = chartData.map(d => d.bunchesCount);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0], data: values,
            }],
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState as ChartData}
            height={150}
            options={options as unknown as ChartOptions}
        />
    );
};

export default BunchesByWeekDayChart;