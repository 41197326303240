import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import BlockUi from 'react-block-ui';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, CheckboxProps, Dropdown, DropdownProps, Icon, List, Popup, Segment } from 'semantic-ui-react';
import { AgencyStateType } from '../../actions/actionTypes';
import { getShapeData } from '../../actions/gtfsStaticActions';
import { getBusHistoryArrivals, getPredictionsByPosition, getTripsData, getTripStopsData, getVehiclePositions, getVehicles, getVehicleStopArrivals } from '../../actions/vehiclesHistoryActions';
import Api from '../../api/api';
import { AppState } from '../../reducers/index';
import { getSelectedOrDefaultAgency } from '../../selectors/index';
import busStopSelectedIcon from '../../static/bus-stop-20-hover.png';
import busStopIcon from '../../static/bus-stop-20.png';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../types/BingMapProps';
import { BusHistoryArrival, BusPositionByBusNumber, BusPositionTrip, PredictionOnStop, TripStopData } from '../../types/busHistoryTypes';
import { GtfsStop } from '../../types/gtfsTypes';
import { DropDownStateType } from '../../types/types';
import BingMapQuerier from '../../utilities/BingMapQuerier';
import BusHistorySlider from '../bushistory/BusHistorySlider';
import { getVehicleInfoboxHtml } from '../bushistory/VehicleInfobox';
import BingMap from '../shared/BingMap';
import BusHistoryArrivalsTable from './BusHistoryArrivalsTable';
import BusHistorySliderInfo from './BusHistorySliderInfo';
import { BusPositionsReportDef, BusPositionsReportHeaders, toReportRow, VehicleArivalsReportDef, VehicleArivalsReportHeaders } from './csvReports';

interface MatchParams {
    selectedDate: string;
    busId: string;
    tripId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
    agency: AgencyStateType | undefined;
    vehicleProperty: string | undefined;
    tripProperty?: string;
    selectedDateProperty?: string;
}

const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

const styles: { [key: string]: React.CSSProperties } = {
    date: {
        display: 'inline-block',
        width: '100%',
    },
    ctlPane: {
        width: '395px',
        height: '848px',
    },
    inputControl: {
        width: '100%',
    },
    pane2: {
        overflowY: 'unset',
    },
    exportLink: {
        whiteSpace: 'nowrap',
        marginLeft: 0,
        marginRight: '10px',
        padding: '.78571429em',
    },
};

const BusHistoryByBusNumberForm: React.FC<Props> = ({ agency, match }) => {
    const vehicleProperty = match.params.busId;
    const tripProperty = match.params.tripId;
    const selectedDateProperty = match.params.selectedDate;
    const initialVehicleState = vehicleProperty ?
        {
            options: [{ value: vehicleProperty, text: vehicleProperty }],
            selectedValue: vehicleProperty,
        }
        : initialDropDownState;

    const tripsDataRef = useRef<BusPositionTrip[]>([]);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [selectedDateState, setSelectedDateState] = useState(selectedDateProperty && selectedDateProperty.replace(/['"]+/g, '') ? moment(selectedDateProperty).format('YYYY-MM-DD') : moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [infoboxesState, setInfoboxesState] = useState<InfoboxProps[]>([]);
    const [vehiclesState, setVehiclesState] = useState(initialVehicleState);
    const [tripsState, setTripsState] = useState(initialDropDownState);
    const [vehiclePositionsState, setVehiclePositionsState] = useState<BusPositionByBusNumber[]>([]);
    const [currentPositionState, setCurrentPositionState] = useState<BusPositionByBusNumber>();
    const [vehicleArrivalsState, setVehicleArrivalsState] = useState<BusHistoryArrival[]>([]);
    const [tripStopsState, setTripStopsState] = useState<TripStopData[]>([]);
    const [selectedVehicleArrivalState, setSelectedVehicleArrivalState] = useState<BusHistoryArrival>();
    const [sliderValueState, setSliderValueState] = useState<number>(-1);
    const [vehicleArrivalsReportData, setVehicleArrivalsReportData] = useState<object[] | null>(null);
    const [busPositionsReportData, setBusPositionsReportData] = useState<object[] | null>(null);
    const [tripStopsCheckState, setTripStopsCheckState] = useState<boolean>(false);
    const [predictionsOnStopsState, setPredictionsOnStopsState] = useState<PredictionOnStop[]>([]);
    const [predictionsLoadingState, setPredictionsLoadingState] = useState<boolean>(false);
    const mapRef = React.createRef<BingMapQuerier>();

    useEffect(() => {
        clearStates();
        if (agency === undefined) return;
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
            await loadVehicles(selectedDateState);
            if (vehicleProperty) {
                await retrieveTripsData(vehicleProperty, selectedDateState);
            }
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        const infobox = updateBusPosition(sliderValueState, vehiclePositionsState);
        return () => {
            if (infobox) {
                infobox.setMap(null as any as Microsoft.Maps.Map);
            }
        };
    }, [sliderValueState, vehiclePositionsState]);

    useEffect(() => {
        setTripsState(initialDropDownState);
    }, [vehiclesState]);

    useEffect(() => {
        if (selectedVehicleArrivalState) {
            const { latitude, longitude, stopId, stopName } = selectedVehicleArrivalState;
            setPushpinsState([{
                location: [latitude, longitude],
                options: {
                    icon: busStopSelectedIcon,
                    catId: 'bus-stop-selected',
                },
                eventHandlers: [{
                    event: 'mouseover',
                    callback: async () => {
                        setInfoboxesState(prevState => (
                            prevState.map(i => i.location[0] === latitude && i.location[1] === longitude ?
                                { ...i, options: { ...i.options, visible: true } } :
                                { ...i, options: { ...i.options, visible: false } })
                        ));
                    },
                }],
            }]);
            setInfoboxesState([{
                location: [latitude, longitude],
                options: {
                    description: `${stopName} (${stopId})`,
                    visible: false,
                },
            }]);
            if (tripStopsCheckState)
                switchStopsForSelectedTrip(vehicleArrivalsState, true);
        }
        return () => {
            setPushpinsState([]);
            setInfoboxesState([]);
        };
    }, [selectedVehicleArrivalState]);

    useEffect(() => {
        if (!tripsState.selectedValue) {
            setSliderValueState(-1);
            setVehiclePositionsState([]);
            setCurrentPositionState(undefined);
            setVehicleArrivalsState([]);
            setPolylineState(undefined);
            setPushpinsState([]);
            setPredictionsOnStopsState([]);
        }
    }, [tripsState]);

    const handleSelectedDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        clearStates();
        setSelectedDateState(value);
        if (vehicleProperty) {
            await retrieveTripsData(vehicleProperty, value);
        }
        loadVehicles(value);
    };

    const handleVehicleChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const vehicleId = value as string;
        setVehiclesState(prevState => ({
            ...prevState,
            selectedValue: vehicleId,
        }));

        setVehicleArrivalsReportData(null);
        setTripStopsCheckState(false);

        if (!agency || !agency.id)
            return;

        const [arrivalsData] = await Promise.all([
            getVehicleStopArrivals(agency.id, vehicleId, selectedDateState),
            retrieveTripsData(vehicleId, selectedDateState),
        ]);
        const reportData: object[] = arrivalsData.map(e => toReportRow(VehicleArivalsReportDef, e));
        setVehicleArrivalsReportData(reportData);
    };

    const handleTripChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const tripId = value as string;
        setTripsState(prevState => ({
            ...prevState,
            selectedValue: tripId as string,
        }));
        setPredictionsOnStopsState([]);
        await drawMap(tripId);
    };

    const drawMap = async (tripId: string) => {
        const shapeId = tripsDataRef.current.find(t => t.tripId === tripId)?.shapeId;
        if (agency && agency.id && shapeId) {
            setFormBlockingState(true);
            try {
                const [vehiclePositions, vehicleArrivals, shapePoints] = await Promise.all(
                    [getVehiclePositions(agency.id, selectedDateState, tripId, vehiclesState.selectedValue as string),
                    getBusHistoryArrivals(agency.id, selectedDateState, tripId, vehiclesState.selectedValue as string),
                    getShapeData(agency.id, selectedDateState, shapeId)]);
                setVehiclePositionsState(vehiclePositions);
                setVehicleArrivalsState(vehicleArrivals);
                setPolylineState({
                    locations: shapePoints.map(({ latitude, longitude }) => [latitude, longitude]),
                    options: {
                        strokeColor: 'orangered',
                        strokeThickness: 3,
                    },
                });
                const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
                const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
                setMapBoundsState(boundingBox);
                setSliderValueState(0);

                const reportData: object[] = vehiclePositions.map(e => toReportRow(BusPositionsReportDef, e));
                setBusPositionsReportData(reportData);
                if (vehicleArrivals.length === 0) {
                    const tripStops = await getTripStopsData(agency.id, tripId, selectedDateState);
                    setTripStopsState(tripStops);
                    switchStopsForSelectedTrip(tripStops, tripStopsCheckState);
                } else {
                    switchStopsForSelectedTrip(vehicleArrivals, tripStopsCheckState);
                }
            } catch {
                setVehiclePositionsState([]);
                setVehicleArrivalsState([]);
                setPolylineState(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setVehiclePositionsState([]);
            setVehicleArrivalsState([]);
            setPolylineState(undefined);
        }
    };

    const switchStopsForSelectedTrip = async (stopsData: GtfsStop[], display: boolean) => {
        if (display) {
            setPushpinsState(() => (
                stopsData
                    .filter(({ stopId }) => !selectedVehicleArrivalState || selectedVehicleArrivalState.stopId !== stopId)
                    .map(({ latitude, longitude }) => ({
                        location: [latitude, longitude],
                        options: {
                            icon: busStopIcon,
                            catId: 'bus-stop-trip',
                        },
                        eventHandlers: [{
                            event: 'mouseover',
                            callback: async () => {
                                setInfoboxesState(prevState => (
                                    prevState.map(i => i.location[0] === latitude && i.location[1] === longitude ?
                                        { ...i, options: { ...i.options, visible: true } } :
                                        { ...i, options: { ...i.options, visible: false } })
                                ));
                            },
                        }],
                    }))
            ));
            setInfoboxesState(prevState => (
                prevState.concat(stopsData
                    .filter(({ stopId }) => !selectedVehicleArrivalState || selectedVehicleArrivalState.stopId !== stopId)
                    .map(({ latitude, longitude, stopId, stopName }) => ({
                        location: [latitude, longitude],
                        options: {
                            description: `${stopName} (${stopId})`,
                            visible: false,
                        },
                    })))
            ));
        } else {
            if (selectedVehicleArrivalState) {
                setPushpinsState(prevState => (
                    prevState.filter(({ location }) => location[0] === selectedVehicleArrivalState.latitude && location[1] === selectedVehicleArrivalState.longitude)
                ));
            } else {
                setPushpinsState([]);
                setInfoboxesState([]);
            }
        }
    };

    const handleSliderValueChange = (value: number) => {
        setSliderValueState(value);
    };

    const loadVehicles = async (date: string) => {
        if (!agency || vehicleProperty)
            return;
        setFormBlockingState(true);
        try {
            const vehicles = await getVehicles(agency.id, date);
            setVehiclesState({
                options: vehicles.map(({ key: value, value: text }) => ({ value, text })),
                selectedValue: undefined,
            });
            setFormBlockingState(false);
        } catch {
            setFormBlockingState(false);
        }
    };

    const retrieveTripsData = async (vehicleId: string, selectedDate: string) => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const trips = await getTripsData(agency.id, selectedDate, vehicleId);
                const selectedTripId = trips.find(t => t.tripId === tripProperty || t.tripInternalId === tripProperty)?.tripId;
                setTripsState({
                    options: trips.map(t => ({
                        value: t.tripId,
                        text: `${t.routeShortName}/${t.otsTripShortName}/${t.tripId}/${moment.parseZone(t.tripScheduledStart).format('h:mm A')}-${moment.parseZone(t.tripScheduledFinish).format('h:mm A')}`,
                    })),
                    selectedValue: selectedTripId,
                });
                tripsDataRef.current = trips;
                if (selectedTripId)
                    drawMap(selectedTripId);
            } catch {
                setTripsState(initialDropDownState);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setTripsState(initialDropDownState);
        }
    };

    const updateBusPosition = (sliderValue: number, vehiclePositions: BusPositionByBusNumber[]): Microsoft.Maps.Infobox | null => {
        const position = vehiclePositions[sliderValue];
        if (!position || !mapRef || !mapRef.current || !mapRef.current.map)
            return null;
        setCurrentPositionState(position);
        const description = `at ${moment.parseZone(position.vehicleTime).format('h:mm:ss A')}`;
        const infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(position.latitude, position.longitude),
            {
                htmlContent: getVehicleInfoboxHtml(position.vehicleId, description),
            },
        );
        infobox.setMap(mapRef.current.map);
        return infobox;
    };

    const handleTripStopsCheckChanged = (_event: React.FormEvent<HTMLInputElement>, props: CheckboxProps) => {
        const display = Boolean(props.checked);
        setTripStopsCheckState(display);
        const gtfsStops = vehicleArrivalsState.length > 0 ? vehicleArrivalsState : tripStopsState;
        switchStopsForSelectedTrip(gtfsStops, display);
    };

    const handlePredictionCalculateButtonClick = async () => {
        if (!currentPositionState || !agency?.id || !tripsState?.selectedValue)
            return;
        const { vehicleTime, latitude, longitude } = currentPositionState;
        const tripId = tripsState.selectedValue as string;
        try {
            setPredictionsLoadingState(true);
            const predictionsOnStops = await getPredictionsByPosition(agency.id, tripId, vehicleTime, latitude, longitude);
            setPredictionsOnStopsState(predictionsOnStops);
        } finally {
            setPredictionsLoadingState(false);
        }
    };

    const clearStates = () => {
        setVehiclePositionsState([]);
        setCurrentPositionState(undefined);
        setVehicleArrivalsState([]);
        setSliderValueState(-1);
        setPolylineState(undefined);
        setPushpinsState([]);
        setInfoboxesState([]);
        setVehiclesState(initialVehicleState);
        setTripsState(initialDropDownState);
        setVehicleArrivalsReportData(null);
        setBusPositionsReportData(null);
        setTripStopsCheckState(false);
        setPredictionsOnStopsState([]);
    };

    return (
        <div className="bushistory-container">
            <BlockUi blocking={formBlockingState}>
                <Segment className="content-pane">
                    <div className="ctl-pane" style={styles.ctlPane}>
                        <div className="pane1">
                            <List className="sidebarlist">
                                <List.Item>
                                    <DateInput
                                        name="fromDateCalendar"
                                        fluid
                                        dateFormat="YYYY-MM-DD"
                                        placeholder="Select date"
                                        value={selectedDateState}
                                        iconPosition="left"
                                        popupPosition="bottom center"
                                        closable={true}
                                        animation="fade"
                                        onChange={handleSelectedDateChange}
                                        className="calendarInput"
                                    />
                                </List.Item>
                                <List.Item>
                                    <Dropdown
                                        placeholder="Select vehicle"
                                        search
                                        selection
                                        openOnFocus={false}
                                        selectOnBlur={false}
                                        selectOnNavigation={false}
                                        options={vehiclesState.options}
                                        value={vehiclesState.selectedValue}
                                        onChange={handleVehicleChange}
                                        disabled={vehicleProperty !== undefined}
                                        style={{ ...styles.inputControl, flexGrow: 1 }}
                                    />
                                </List.Item>
                                {vehiclesState.selectedValue && <List.Item>
                                    <Dropdown
                                        placeholder="Select trip"
                                        search
                                        selection
                                        openOnFocus={false}
                                        selectOnBlur={false}
                                        selectOnNavigation={false}
                                        options={tripsState.options}
                                        value={tripsState.selectedValue}
                                        onChange={handleTripChange}
                                        style={styles.inputControl}
                                    />
                                </List.Item>}
                                {tripsState.selectedValue &&
                                    <List.Item>
                                        <Checkbox label="Display Stops" checked={tripStopsCheckState} onChange={handleTripStopsCheckChanged} />
                                    </List.Item>}
                                <List.Item>
                                    {!!vehicleArrivalsReportData && vehicleArrivalsReportData.length > 0 &&
                                        <Popup
                                            content="Download Stop Arrivals Report"
                                            trigger={
                                                <CSVLink
                                                    headers={VehicleArivalsReportHeaders}
                                                    data={vehicleArrivalsReportData}
                                                    filename={`StopsArrivalsHistory-${vehiclesState.selectedValue}-${selectedDateState}.csv`}
                                                    target="_blank"
                                                    className="ui button basic"
                                                    style={styles.exportLink}
                                                >
                                                    <Icon name="download" />
                                                    Vehicle Arrivals CSV
                                                </CSVLink>
                                            }
                                        />
                                    }
                                    {!!busPositionsReportData && busPositionsReportData.length > 0 &&
                                        <Popup
                                            content="Download Bus Positions Report"
                                            trigger={
                                                <CSVLink
                                                    headers={BusPositionsReportHeaders}
                                                    data={busPositionsReportData}
                                                    filename={`BusPositionsHistory-${vehiclesState.selectedValue}-${tripsState.selectedValue}-${selectedDateState}.csv`}
                                                    target="_blank"
                                                    className="ui button basic"
                                                    style={styles.exportLink}
                                                >
                                                    <Icon name="download" />
                                                    Bus Positions CSV
                                                </CSVLink>
                                            }
                                        />
                                    }
                                </List.Item>
                                {vehiclePositionsState.length > 0 && <List.Item>
                                    <BusHistorySlider
                                        maxValue={vehiclePositionsState.length - 1}
                                        sliderValue={sliderValueState}
                                        handleSliderChange={handleSliderValueChange}
                                    />
                                </List.Item>}
                                {currentPositionState && tripsState?.selectedValue && <List.Item>
                                    <BlockUi tag="div" blocking={predictionsLoadingState} className="inner">
                                        <Button
                                            content="Calculate Predictions"
                                            onClick={handlePredictionCalculateButtonClick}
                                        />
                                    </BlockUi>
                                </List.Item>}
                            </List>
                        </div>
                        {currentPositionState && <div className="pane2" style={styles.pane2}>
                            <BusHistorySliderInfo {...currentPositionState} />
                        </div>}
                        {vehicleArrivalsState.length > 0 &&
                            <div>
                                <BlockUi tag="div" blocking={predictionsLoadingState} className="inner">
                                    <BusHistoryArrivalsTable rows={vehicleArrivalsState} predictionsOnStops={predictionsOnStopsState} selectedRow={selectedVehicleArrivalState} setSelectedRow={setSelectedVehicleArrivalState} />
                                </BlockUi>
                            </div>
                        }
                    </div>
                    {polylineState && <div className="map-pane">
                        <BingMap
                            ref={mapRef}
                            map={{
                                center,
                                options: {
                                    bounds: mapBoundsState,
                                },
                            }}
                            polylines={polylineState && [polylineState]}
                            pushpins={pushpinsState}
                            infoboxes={infoboxesState}
                        />
                    </div>}
                </Segment>
            </BlockUi>
        </div>
    );
};

export default connect(
    (state: AppState) => ({ agency: getSelectedOrDefaultAgency(state) }),
)(BusHistoryByBusNumberForm);