import { User } from 'oidc-client';
import { LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, UserActionTypes } from '../actions/actionTypes';
import { otherwise } from '../utilities/exhaustive-narrowing';
//import * as jwtDecode from 'jwt-decode';

export default function userReducer(state: User | null = null, action: UserActionTypes): User | null {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            console.log('userReducer—log in');
            return action.user;
        }
        case LOGIN_ERROR: {
            console.log('userReducer—log in error');
            return null;
        }
        case LOGOUT: {
            console.log('userReducer—log out');
            return null;
        }
        default: return otherwise(action, state);
    }
}