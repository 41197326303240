import { WrongCoordinatesShape } from '../../../types/anomaliesTypes';
import { ReportColumn } from '../../shared/csvReport';

export const RouteShapesReportDef: ReportColumn<WrongCoordinatesShape>[] = [
    {
        header: 'Route',
        getter: (e: WrongCoordinatesShape) => `${e.routeId} - ${e.otsTripShortName}`,
    },
    {
        header: 'Shape ID',
        getter: (e: WrongCoordinatesShape) => e.id,
    },
    {
        header: 'Trips Fully on Shape',
        getter: (e: WrongCoordinatesShape) => e.onShape,
    },
    {
        header: 'Trips not on Shape',
        getter: (e: WrongCoordinatesShape) => e.outShape,
    },
    {
        header: 'Trips not on Shape, %',
        getter: (e: WrongCoordinatesShape) => e.outShapePcnt,
    },
    {
        header: 'Positions on Shape',
        getter: (e: WrongCoordinatesShape) => e.onShapeTrips,
    },
    {
        header: 'Positions outside Shape',
        getter: (e: WrongCoordinatesShape) => e.outShapeTrips,
    },
    {
        header: 'Wrong Position, %',
        getter: (e: WrongCoordinatesShape) => e.outShapePcntTrips.toFixed(0),
    },
];

export const RouteShapesReportHeader = RouteShapesReportDef.map(column => column.header);