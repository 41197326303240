import moment from 'moment';
import { User } from 'oidc-client';
import { ThunkAction } from 'redux-thunk';
import gtfsApi from '../api/gtfsApi';
import GtfsStaticApi from '../api/gtfsStaticApi';
import pbiApi from '../api/pbiApi';
import { Statuses } from '../components/gtfs/constants';
import { GtfsFileOperations, GtfsFileStateType } from '../types/gtfsFilesTypes';
import * as types from '../types/types';
import { AgencyStorage } from '../utilities/agencyStorage';
import { AgenciesActionTypes, AgencyStateType, ApiCallActionTypes, GtfsFilesActionTypes, HIDE_ALERT, HideAlertActionType, LOAD_AGENCIES_SUCCESS, LOAD_GTFS_ERROR, LOAD_GTFS_SUCCESS, LOGIN_ERROR, LOGIN_SUCCESS, LoginErrorActionType, LoginSuccessActionType, POST_GTFS_ERROR, POST_GTFS_SUCCESS, SELECT_AGENCY, VALIDATE_GTFS_ERROR, VALIDATE_GTFS_SUCCESS } from './actionTypes';
import { apiCallError, beginApiCall } from './apiStatusActions';

export function loadGtfsFilesSuccess(
    gtfsFiles: GtfsFileStateType[],
): GtfsFilesActionTypes {
    return { type: LOAD_GTFS_SUCCESS, gtfsFiles };
}
export function loadGtfsFilesError(
): GtfsFilesActionTypes {
    return { type: LOAD_GTFS_ERROR };
}
export function postGtfsFileSuccess(): GtfsFilesActionTypes {
    return { type: POST_GTFS_SUCCESS };
}
export function postGtfsFileError(): GtfsFilesActionTypes {
    return { type: POST_GTFS_ERROR };
}
export function validateGtfsFileSuccess(
    gtfsFileId: string,
    isValid: boolean,
): GtfsFilesActionTypes {
    return { type: VALIDATE_GTFS_SUCCESS, gtfsFileId, isValid };
}
export function validateGtfsFileError(gtfsFileId: string): GtfsFilesActionTypes {
    return { type: VALIDATE_GTFS_ERROR, gtfsFileId };
}

export function loadAgenciesSuccess(
    agencies: AgencyStateType[],
): AgenciesActionTypes {
    return { type: LOAD_AGENCIES_SUCCESS, agencies };
}
export function setAgencyId(agencyId: string): AgenciesActionTypes {
    return { type: SELECT_AGENCY, agencyId };
}

export function hideAlert(): HideAlertActionType {
    return { type: HIDE_ALERT };
}

export function loginError(): LoginErrorActionType {
    return { type: LOGIN_ERROR };
}

type UpdateGtfsFileThunk = ThunkAction<
    Promise<GtfsFileStateType | undefined>,
    { agencyId: string; gtfsFiles: GtfsFileStateType[] },
    unknown,
    ApiCallActionTypes | GtfsFilesActionTypes | never
>;

type LoadGtfsFilesThunk = ThunkAction<
    Promise<GtfsFileStateType[]>,
    unknown,
    unknown,
    ApiCallActionTypes | GtfsFilesActionTypes
>;
export function loadGtfsFiles(agencyId: string): LoadGtfsFilesThunk {
    // make async call to api, handle promise, dispatch action when promise is resolved
    return async function (dispatch) {
        dispatch(beginApiCall());
        try {
            if (!agencyId)
                return [];
            const response = await gtfsApi.getGtfsFiles(agencyId);
            const gtfsFiles = agencyId && response.data ? response.data : [];
            dispatch(loadGtfsFilesSuccess(gtfsFiles));
            return gtfsFiles;
        } catch {
            dispatch(apiCallError());
            return [];
        }
    };
}

export async function getTripTimes(fileId: string) {
    try {
        const response = await gtfsApi.getTripTimes(fileId);
        const gtfsFiles = fileId && response.data ? response.data : [];
        return gtfsFiles;
    } catch {
        return [];
    }
}

type PostGtfsFileThunk = ThunkAction<
    Promise<string | undefined>,
    { agencyId: string },
    unknown,
    ApiCallActionTypes | GtfsFilesActionTypes
>;
export function postGtfsFile(file: types.UploadFile): PostGtfsFileThunk {
    return async function (dispatch, getState) {
        dispatch(beginApiCall());
        const store = getState();
        const agencyId = store.agencyId;
        try {
            const response = await gtfsApi.postGtfs({ agencyId, file: file.file });
            dispatch(postGtfsFileSuccess());
            dispatch(loadGtfsFiles(agencyId));
            return response.data;
        } catch (err) {
            dispatch(postGtfsFileError());
            return undefined;
        }
    };
}

type DeleteGtfsThunk = ThunkAction<Promise<string>, { agencyId: string }, unknown, never>;
export function deleteGtfs(gtfsId: string): DeleteGtfsThunk {
    return async function (dispatch, getState) {
        const store = getState();
        const agencyId = store.agencyId;
        const response = await gtfsApi.deleteGtfs(gtfsId);
        dispatch(loadGtfsFiles(agencyId));
        return response.data;
    };
}

type ReloadGtfsFilesThunk = ThunkAction<
    Promise<GtfsFileStateType[]>,
    { agencyId: string },
    unknown,
    never
>;
export function reloadGtfsFiles(): ReloadGtfsFilesThunk {
    return async function (dispatch, getState) {
        const store = getState();
        const agencyId: string = store.agencyId;
        return await loadGtfsFiles(agencyId)(dispatch, getState, undefined);
    };
}

export function publishGtfs(gtfsId: string): UpdateGtfsFileThunk {
    return async function (dispatch, getState) {
        const store = getState();
        const agencyId = store.agencyId;
        const response = await gtfsApi.updateGtfs(gtfsId, {
            agencyId,
            operation: GtfsFileOperations.Publish,
        });
        dispatch(loadGtfsFiles(agencyId));
        return response.data;
    };
}

export function unpublishGtfs(gtfsId: string): UpdateGtfsFileThunk {
    return async function (dispatch, getState) {
        const store = getState();
        const agencyId = store.agencyId;
        const response = await gtfsApi.updateGtfs(gtfsId, {
            agencyId,
            operation: GtfsFileOperations.Unpublish,
        });
        dispatch(loadGtfsFiles(agencyId));
        return response.data;
    };
}

export function validateGtfs(gtfsId: string): UpdateGtfsFileThunk {
    return async function (dispatch, getState) {
        dispatch(beginApiCall());
        const store = getState();
        const agencyId = store.agencyId;
        try {
            const response = await gtfsApi.updateGtfs(gtfsId, {
                agencyId,
                operation: GtfsFileOperations.Validate,
            });
            const gtfsFile = response.data;
            dispatch(validateGtfsFileSuccess(gtfsId, gtfsFile.valid));
        } catch (err) {
            dispatch(validateGtfsFileError(gtfsId));
            let retrieveAttempt = 0;
            const timer = setInterval(async () => {
                const response = await gtfsApi.getGtfsFileById(gtfsId);
                const gtfsFile = response.data;
                retrieveAttempt++;
                if (gtfsFile.status !== Statuses.ValidationInProgress || retrieveAttempt > 50) {
                    clearInterval(timer);
                    dispatch(loadGtfsFiles(agencyId));
                }
            }, 5000);
        } finally {
            dispatch(loadGtfsFiles(agencyId));
        }
        return (store.gtfsFiles as GtfsFileStateType[]).find(f => f.gtfsFileId === gtfsId);
    };
}

export function scheduleGtfs(gtfsId: string): UpdateGtfsFileThunk {
    return async function (dispatch, getState) {
        const store = getState();
        const agencyId = store.agencyId;
        const response = await gtfsApi.updateGtfs(gtfsId, {
            agencyId,
            operation: GtfsFileOperations.Schedule,
        });
        dispatch(loadGtfsFiles(agencyId));
        return response.data;
    };
}

export function updateActivationDateValue(gtfsId: string, activationeDateStr: string): UpdateGtfsFileThunk {
    return async function (dispatch, getState) {
        dispatch(beginApiCall());
        const store = getState();
        const agencyId = store.agencyId;
        const activationDate = moment(activationeDateStr).isValid() ? activationeDateStr : null;
        const response = await gtfsApi.updateGtfs(gtfsId, {
            agencyId,
            activationDate,
            operation: GtfsFileOperations.UpdateActivationDate,
        });
        dispatch(loadGtfsFiles(agencyId));
        return response.data;
    };
}

type SelectAgencyThunk = ThunkAction<void, unknown, unknown, AgenciesActionTypes>;
export function selectAgency(agencyId: string): SelectAgencyThunk {
    console.log('1: actions selectAgency: ' + agencyId);

    return function (dispatch) {
        console.log('2: actions selectAgency: ' + agencyId);
        //dispatch(loadGtfsFiles(agencyId));
        dispatch(setAgencyId(agencyId));
    };
}

type LoginSuccessThunk = ThunkAction<
    void,
    unknown,
    unknown,
    | AgenciesActionTypes
    | LoginSuccessActionType
>;
export function loginSuccess(user: User, forceLoadAgencies?: boolean): LoginSuccessThunk {
    return async function (dispatch) {
        const needloadAgencies = forceLoadAgencies !== false;

        console.log('actions - loginSuccess, ' + user.profile.name, needloadAgencies);

        dispatch({ type: LOGIN_SUCCESS, user });

        if (needloadAgencies)
            dispatch(loadAgencies());
    };
}

type LoadAgenciesThunk = ThunkAction<
    Promise<void>,
    unknown,
    unknown,
    AgenciesActionTypes
>;
export function loadAgencies(): LoadAgenciesThunk {
    return async function (dispatch) {
        const agencies = await (await GtfsStaticApi.getAgencies()).data;
        dispatch(loadAgenciesSuccess(agencies));
        const agency = agencies.find(({ id }) => id === AgencyStorage.agencyId) || agencies[0];
        if (agency && agency.id)
            dispatch(setAgencyId(agency.id));
    };
}

export async function getGtfsFileById(gtfsFileId: string) {
    return await gtfsApi.getGtfsFileById(gtfsFileId);
}

export async function powerBIToken(reportId: string, agencyId: string )  {
    const response = pbiApi.getPowerBIToken(reportId, agencyId);
    return response;   
}

export async function powerBITokenEdit() {
    const response = pbiApi.getPowerBITokenEdit();
    return response;
}

export async function getListOfReports() {
    const response = pbiApi.getListOfReports();
    return response;
}

export async function powerBIStartCapacity() {
    const response = pbiApi.postPowerBIStartCapacity();
    return response;
}

export async function powerBIStatusCapacity() {
    const response = pbiApi.getPowerBIStatusCapacity();
    return response;
}