import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { KpiRoutesDelayStatistics } from '../../../../types/delayStatisticsTypes';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Early Arrivals',
            backgroundColor: '#82E180',
            borderColor: '#82E180',
            borderWidth: 1,
            hoverBackgroundColor: '#82E180',
            hoverBorderColor: '#82E180',
            data: [] as number[],
        },
        {
            label: 'On Time Arrivals',
            backgroundColor: '#31DCC3',
            borderColor: '#31DCC3',
            borderWidth: 1,
            hoverBackgroundColor: '#31DCC3',
            hoverBorderColor: '#31DCC3',
            data: [] as number[],
        },
        {
            label: 'Late Arrivals',
            backgroundColor: '#FFC061',
            borderColor: '#FFC061',
            borderWidth: 1,
            hoverBackgroundColor: '#FFC061',
            hoverBorderColor: '#FFC061',
            data: [] as number[],
        },
        {
            label: 'Very Late Arrivals',
            backgroundColor: '#FF8368',
            borderColor: '#FF8368',
            borderWidth: 1,
            hoverBackgroundColor: '#FF8368',
            hoverBorderColor: '#FF8368',
            data: [] as number[],
        },
    ],
};

const options = {
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            boxWidth: 20,
        },
    },
    scales: {
        xAxes: [{
            gridLines: {
                drawOnChartArea: false,
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                callback: function (value: string) {
                    const valueNum = value as unknown as number;
                    return valueNum !== 0 ? `${Math.round((valueNum) / 1000)}k` : valueNum;
                },
                maxTicksLimit: 6,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            title: function ([tooltipItem]: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                let lineType = 'Early Arrivals';
                if (tooltipItem.datasetIndex === 1)
                    lineType = 'On Time Arrivals';
                else if (tooltipItem.datasetIndex === 2)
                    lineType = 'Late Arrivals';
                else if (tooltipItem.datasetIndex === 3)
                    lineType = 'Very Late Arrivals';
                return lineType;
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                return `Number of Arrivals: ${tooltipItem.yLabel.toLocaleString('en-US')}`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        borderColor: '#82E180',
                        backgroundColor: '#82E180',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        borderColor: '#31DCC3',
                        backgroundColor: '#31DCC3',
                    };
                } else if (tooltipItem.datasetIndex === 2) {
                    return {
                        borderColor: '#FFC061',
                        backgroundColor: '#FFC061',
                    };
                } else {
                    return {
                        borderColor: '#FF8368',
                        backgroundColor: '#FF8368',
                    };
                }
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        crosshair: false,
    },
};

const DelaysDistributionChart: React.FC<{ chartData: KpiRoutesDelayStatistics }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: [],
            datasets: [{
                ...prevState.datasets[0], data: [chartData.earlyArrivals],
            }, {
                ...prevState.datasets[1], data: [chartData.onTimeArrivals],
            }, {
                ...prevState.datasets[2], data: [chartData.lateArrivals],
            }, {
                ...prevState.datasets[3], data: [chartData.veryLateArrivals],
            }],
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState as ChartData}
            height={160}
            options={options as unknown as ChartOptions}
        />
    );
};

export default DelaysDistributionChart;