import React, { Fragment, useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { approveNotificationSending } from '../../actions/pushNotificationActions';

const ConfirmedPushNotificationForm: React.FC = () => {
    const [formBlockingState, setFormBlockingState] = useState(true);
    const [errorMessageState, setErrorMessageState] = useState(null);

    const location = useLocation();

    useEffect(() => {
        const queryParameters = new URLSearchParams(location.search);
        const code = queryParameters.get('code') ?? '';
        const email = queryParameters.get('email') ?? '';
        (async (code: string, email: string) => {
            try {
                await approveNotificationSending(code, email);
            }
            catch (error) {
                setErrorMessageState(error.response.data ?? error.message);
            }
            finally {
                setFormBlockingState(false);
            }
        })(code, email);
    }, []);

    return (
        <Fragment>
            <BlockUi tag="div" message="preparing..." blocking={formBlockingState}>
                {errorMessageState === null && <Header as="h2" className="reportHeader">
                    Message Sent.
                </Header>
                }
                <h2>{errorMessageState}</h2>
            </BlockUi>
        </Fragment>
    );
};


export default connect()(ConfirmedPushNotificationForm);
