import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TripReportingGap } from '../../../types/anomaliesTypes';
import Utils from '../../../utilities/utils';

const styles = {
    table: {
        display: 'block',
        maxHeight: '405px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

type TableProps = {
    items: TripReportingGap[],
    selectedItem?: TripReportingGap | null,
    onRowClick?: (rowData: TripReportingGap) => Promise<void>
};

const TripsReportingGapsTable: React.FC<TableProps> = ({ items, selectedItem, onRowClick }) => {
    return (
        <div>
            <Table celled striped compact singleLine fixed style={styles.table} className="semanticTable reportingGapsTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan="2" textAlign="left" width={1}>Trip Schedule</Table.HeaderCell>
                        <Table.HeaderCell rowSpan="2" textAlign="left" width={1}>Route</Table.HeaderCell>
                        <Table.HeaderCell colSpan="4" textAlign="left" width={1}>GTFS-Realtime</Table.HeaderCell>
                        <Table.HeaderCell colSpan="4" textAlign="left" width={1}>TAIP</Table.HeaderCell>
                        <Table.HeaderCell rowSpan="2" textAlign="left" width={1}>Total Scheduled Time</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Offline Time<br />(minutes)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Online Time<br />(minutes)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Offline Percentage</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Records Count</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Offline Time<br />(minutes)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Online Time<br />(minutes)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Offline Percentage</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Records Count</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item, index) => (
                        <TripsReportingGapsTableRow key={index} active={item === selectedItem} data={item} onRowClick={onRowClick} />
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colspan="9" textAlign="left" width={1}>total items: {items?.length || 0}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

export default TripsReportingGapsTable;

type RowProps = {
    data: TripReportingGap,
    active?: boolean,
    onRowClick?: (rowData: TripReportingGap) => void
};

const TripsReportingGapsTableRow: React.FC<RowProps> = ({ data, active, onRowClick }) => {
    const tripLinkClickHandler = () => {
        if (onRowClick)
            onRowClick(data);
    };

    return (
        <Table.Row active={active} onClick={tripLinkClickHandler}>
            <Table.Cell>{moment.parseZone(data.tripScheduledStart).format('h:mm A')} - {moment.parseZone(data.tripScheduledFinish).format('h:mm A')}</Table.Cell>
            <Table.Cell>{data.routeName} - {data.cardinalDirection}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(data.offlineSecGtfsRt)}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(data.onlineSecGtfsRt)}</Table.Cell>
            <Table.Cell>{data.totalActualTimeSec && data.offlineSecGtfsRt ? Math.round(data.offlineSecGtfsRt / data.totalActualTimeSec * 100) + ' %' : ''}</Table.Cell>
            <Table.Cell>{data.recordsCountGtfsRt}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(data.offlineSecTaip)}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(data.onlineSecTaip)}</Table.Cell>
            <Table.Cell>{data.totalActualTimeSec && data.offlineSecTaip ? Math.round(data.offlineSecTaip / data.totalActualTimeSec * 100) + ' %' : ''}</Table.Cell>
            <Table.Cell>{data.recordsCountTaip}</Table.Cell>
            <Table.Cell>{Utils.convertSecondsToMinutes(data.totalScheduledTimeSec)}</Table.Cell>
        </Table.Row>
    );
};
