import { InfoboxProps, PushpinProps } from '../../types/BingMapProps';
import { ItineraryData, toTitleCase } from './ItineraryItem';
import { MapPoint } from './MapPoint';
import { MapPointKind } from './MapPointKind';
import { TrackPolylineProps } from './TripPlannerForm';
import { colorOrigin, colorTarget, renderInfoboxContent2, toCoords } from './TripPLannerUtils';

declare let $tiq_TripPlannerHelper: {
    /**
     * @param cid - TripPlannerContext.uid
     * @param pid - MapPoint.id clicked point
     */
    click: (e: Event, cid: number, pid: string, choice: 'origin' | 'target') => void,
    hide: (elem: HTMLElement, event: Event) => void,
    show: (elem: HTMLElement, event: Event) => void,
    map: { [id: string]: TripPlannerContext }
};

export class TripPlannerContext {
    private static nextUid = 0;
    public readonly uid: number;

    public static EMPTY = new TripPlannerContext();

    public onPointChoice?: (choice: 'origin' | 'target') => void;

    constructor() {
        this.uid = TripPlannerContext.nextUid;
        TripPlannerContext.nextUid = (TripPlannerContext.nextUid + 1001) % 1000;

        this.$pushpins = [];
        this.$infoboxes = [];
        this.$polylines = [];
    }

    public register() {
        if ($tiq_TripPlannerHelper.map[this.uid] !== this)
            console.warn('TripPlannerContext: another instance is already registered', $tiq_TripPlannerHelper.map);
        $tiq_TripPlannerHelper.map[this.uid] = this;
    }

    public unregister() {
        if (!$tiq_TripPlannerHelper.map[this.uid])
            console.warn('TripPlannerContext: tthis  instance is already unregistered', this);
        else
            delete $tiq_TripPlannerHelper.map[this.uid];
    }

    private input: MapPoint | null = null;
    private _origin: MapPoint | null = null;
    private _target: MapPoint | null = null;

    public get origin(): MapPoint | null {
        return this._origin;
    }
    public get target(): MapPoint | null {
        return this._target;
    }

    public setInputPoint(location: Microsoft.Maps.Location): MapPoint {
        if (this.input) {
            if (this.input.setAddress(this, null))
                this.$infoboxesInvalid = true;
            this.input.setLocation(location);
        }
        else {
            this.input = new MapPoint(this, location);
        }
        this.invalidatePushpins();
        return this.input;
    }

    public setInputChoice(kind: MapPointKind): MapPoint | null {
        if ((kind !== MapPointKind.from) && (kind !== MapPointKind.to))
            console.error(`TripPlanner: invalid point kind value (${kind})!`);
        else if (!this.input)
            console.error('TripPlanner: input point is undefined!');
        else {
            this.invalidatePushpins();
            this.$infoboxesInvalid = true;

            if (this.input.setKind(this, kind)) {
                if (kind === MapPointKind.from) {
                    this._origin = this.input;
                    this.input = null;
                    return this.origin;
                }
                if (kind === MapPointKind.to) {
                    this._target = this.input;
                    this.input = null;
                    this.invalidatePushpins();
                    return this.target;
                }
            }
        }
        return null;
    }

    private _pushpinsInvalid = 0;

    private invalidatePushpins(): void {
        ++this._pushpinsInvalid;
    }

    private $pushpins: PushpinProps[];

    private _pushpinOrigin: PushpinProps | null = null;
    private _pushpinTarget: PushpinProps | null = null;

    public storeEndpointPushpins() {
        if (this._origin && this._target) {
            this._pushpinOrigin = {
                location: toCoords(this._origin.location),
                options: { color: Microsoft.Maps.Color.fromHex(colorOrigin) },
            };
            this._pushpinTarget = {
                location: toCoords(this._target.location),
                options: { color: Microsoft.Maps.Color.fromHex(colorTarget) },
            };
            this.invalidatePushpins();
        }
    }

    private clearEndpointPushpins() {
        this._pushpinOrigin = null;
        this._pushpinTarget = null;
        this.invalidatePushpins();
    }

    public getPushpins(forceInvalidate = false): PushpinProps[] {
        if (this._pushpinsInvalid !== 0 || forceInvalidate === true) {
            this.$pushpins = [];
            if (this._pushpinOrigin) this.$pushpins.push(this._pushpinOrigin);
            if (this._pushpinTarget) this.$pushpins.push(this._pushpinTarget);

            // if (this.origin) this.$pushpins.push(this.origin.pushpin);
            // if (this.target) this.$pushpins.push(this.target.pushpin);
            if (this.input && !this.input.address) this.$pushpins.push(this.input.pushpin);
            this._pushpinsInvalid = 0;
        }
        return this.$pushpins;
    }

    private $infoboxesInvalid = false;
    private $infoboxes: InfoboxProps[];

    public getInfoboxes(forceInvalidate = false): InfoboxProps[] {
        if (this.$infoboxesInvalid || forceInvalidate === true) {
            this.$infoboxesInvalid = false;
            this.$infoboxes = [];
            if (this.origin && this.origin.showInfobox) this.$infoboxes.push(this.origin.infobox);
            if (this.target && this.target.showInfobox) this.$infoboxes.push(this.target.infobox);
            if (this.input && this.input.showInfobox) this.$infoboxes.push(this.input.infobox);

            if (this.$itinerary) {
                for (const step of this.$itinerary.legs) {
                    if (!step.from) continue;

                    const ibox: InfoboxProps = {
                        location: [step.shape[0].lat as Latitude, step.shape[0].lon as Longitude],
                        options: {
                            htmlContent: renderInfoboxContent2(this.uid, '123',
                                step.type, step.color.hex3, step.name, step.text, step.frequency,
                                toTitleCase(step.name || 'Walk')),
                        },
                    };
                    this.$infoboxes.push(ibox);
                }
            }
        }
        return this.$infoboxes;
    }

    private $polylines: TrackPolylineProps[];

    public getPolylines(): TrackPolylineProps[] {
        return this.$polylines;
    }

    public exchangePoints() {
        if (!this.origin && !this.target) return false;

        const tmp = this._origin;
        this._origin = this.target;
        this._target = tmp;

        this.origin?.setKind(this, MapPointKind.from);
        this.target?.setKind(this, MapPointKind.to);

        if (!!this.origin === !!this.target) {
            this.invalidatePushpins();
            this.$infoboxesInvalid = true;
        }
        return true;
    }

    public resetPoints() {
        if (!this.origin && !this.target) return false;

        this._origin = null;
        this._target = null;

        this.clearEndpointPushpins();
        this.$infoboxesInvalid = true;

        return true;
    }

    private $itinerary: ItineraryData | null = null;

    public updateItinerary(itinerary: ItineraryData | null): TrackPolylineProps[] {
        this.$infoboxesInvalid = true;
        this.$itinerary = itinerary;
        this.$polylines = [];
        if (this.$itinerary) {
            for (const step of this.$itinerary.legs) {
                const polyline: TrackPolylineProps = {
                    locations: step.shape.map<CoordinatePair>(i => [i.lat as Latitude, i.lon as Longitude]),
                    options: {
                        strokeColor: step.color.mapColor,
                        strokeThickness: 3,
                    },
                };
                if (!step.solid) {
                    polyline.options.strokeDashArray = '6 3';
                }
                this.$polylines.push(polyline);
            }
        }
        return this.$polylines;
    }
}
