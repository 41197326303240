import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChartDataPoint } from '../../../types/chartTypes';
import { KeyValuePair } from '../../../types/types';

interface ChartDataSet {
    data: BarChartDataPoint[],
    backgroundColor: string;
    borderWidth: number;
    label: string;
}
interface ChartSettings {
    datasets: ChartDataSet[],
}

const chartSettings: ChartSettings = {
    datasets: [],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            type: 'time',
            autoSkip: false,
            time: {
                unit: 'day',
                unitStepSize: 1,
            },
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                autoSkip: false,
                maxTicksLimit: 5,
                callback: function (value: string) {
                    return `${value}%`;
                },
                suggestedMin: 100,
            },
        }],
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'black',
        borderWidth: 1,
        bodyFontColor: 'black',
        titleFontColor: 'black',
        caretPadding: 20,
        callbacks: {
            title: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }[]) {
                return moment(tooltipItem[0].xLabel).format('YYYY-MM-DD');
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                return `Performance: ${tooltipItem.yLabel}%`;
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        crosshair: false,
    },
};

const PerformanceByDatesChart: React.FC<{ performanceByDates: Array<KeyValuePair<Date, number>> }> = ({ performanceByDates }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const orderedData = performanceByDates.sort((a, b) => new Date(a.key).getTime() - new Date(b.key).getTime());
        const weekdayValues: BarChartDataPoint[] = [], weekendValues: BarChartDataPoint[] = [];
        for (const data of orderedData) {
            if ([0, 6].includes(moment(data.key).weekday())) {
                weekendValues.push({ x: moment(data.key).format('YYYY-MM-DD'), y: data.value });
            } else {
                weekdayValues.push({ x: moment(data.key).format('YYYY-MM-DD'), y: data.value });
            }
        }
        setDataState(prevState => {
            const datasets = [];
            if (weekdayValues.length > 0) {
                datasets.push({
                    data: weekdayValues, backgroundColor: 'rgba(130, 225, 128, 0.5)', borderWidth: 0, label: 'weekdays',
                });
            }
            if (weekendValues.length > 0) {
                datasets.push({
                    data: weekendValues, backgroundColor: 'rgba(255, 131, 104, 0.5)', borderWidth: 0, label: 'weekends',
                });
            }
            return { ...prevState, datasets };
        });
        setOptionsState(prevState => {
            let minValue = 100;
            if (weekdayValues.length > 0 && weekendValues.length > 0) {
                minValue = Math.min(...[...weekdayValues, ...weekendValues].map(v => v.y));
            } else if (weekdayValues.length > 0) {
                minValue = Math.min(...weekdayValues.map(v => v.y));
            } else {
                minValue = Math.min(...weekendValues.map(v => v.y));
            }
            return {
                ...prevState,
                scales: {
                    ...prevState.scales,
                    yAxes: [{
                        ...prevState.scales.yAxes[0],
                        ticks: { ...prevState.scales.yAxes[0].ticks, suggestedMin: minValue - 1 },
                    }],
                },
            };
        });
    }, [performanceByDates]);

    return (
        <Bar
            data={dataState as ChartData}
            options={optionsState as ChartOptions}
            height={70}
        />
    );
};

export default PerformanceByDatesChart;