import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { DelayJumpsMain } from '../../../types/anomaliesTypes';

const DelayJumpsMainTableRow: React.FC<{ row: DelayJumpsMain, selectedTripJump: DelayJumpsMain | undefined, jumpRowHandler: (tripJump: DelayJumpsMain) => Promise<void> }> = ({ row, selectedTripJump, jumpRowHandler }) => {
    const handleRowClick = () => {
        if (jumpRowHandler)
            jumpRowHandler(row);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedTripJump !== undefined && selectedTripJump.tripInternalId === row.tripInternalId && selectedTripJump.vehicleId === row.vehicleId}>
            <Table.Cell textAlign="left">
                {row.vehicleId}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.routeName} - {row.directionName}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.tripId} ({moment.parseZone(row.scheduledStart).format('h:mm A')} - {moment.parseZone(row.scheduledFinish).format('h:mm A')})
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.delayJumpsCount}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.avgJumpSpeed}
            </Table.Cell>
        </Table.Row>
    );
};

export default DelayJumpsMainTableRow;