import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WrongCoordinatesTrip } from '../../../types/anomaliesTypes';
import Utils from '../../../utilities/utils';

interface Props {
    row: WrongCoordinatesTrip,
    selectedTrip: WrongCoordinatesTrip | undefined,
    selectedRowHandler: (selectedTrip: WrongCoordinatesTrip) => void;
    agencyOffsetMinutes: number;
}

const WrongCoordinatesTripsTableRow: React.FC<Props> = ({ row, selectedTrip, selectedRowHandler, agencyOffsetMinutes }) => {
    const handleRowClick = () => {
        if (selectedRowHandler)
            selectedRowHandler(row);
    };

    return (
        <Table.Row onClick={handleRowClick} active={selectedTrip !== undefined && selectedTrip.internalId === row.internalId && selectedTrip.vehicle == row.vehicle}>
            <Table.Cell textAlign="left">
                {row.id} ({moment(Utils.utcToLocal(row.start, agencyOffsetMinutes)).format('h:mm A') + ' - ' + moment(Utils.utcToLocal(row.finish, agencyOffsetMinutes)).format('h:mm A')})
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.vehicle}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.onShape}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShape}
            </Table.Cell>
            <Table.Cell textAlign="left">
                {row.outShapePcnt}
            </Table.Cell>
        </Table.Row>
    );
};

export default WrongCoordinatesTripsTableRow;