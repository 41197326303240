import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Utils from '../../../../utilities/utils';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: 'Scheduled Waiting Time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#42DCC6',
            pointBackgroundColor: '#42DCC6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#42DCC6',
            pointHoverBorderColor: '#42DCC6',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Observed Waiting Time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#C0E139',
            borderColor: '#C0E139',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#C0E139',
            pointBackgroundColor: '#C0E139',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#C0E139',
            pointHoverBorderColor: '#C0E139',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Estimated Waiting Time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#FFC061',
            borderColor: '#FFC061',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#FFC061',
            pointBackgroundColor: '#FFC061',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#FFC061',
            pointHoverBorderColor: '#FFC061',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options = {
    title: {
        display: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            usePointStyle: true,
        },
    },
    scales: {
        xAxes: [{
            autoSkip: false,
            maxRotation: 0,
            gridLines: {
                drawOnChartArea: false,
            },
            distribution: 'linear',
        }],
        yAxes: [{
            ticks: {
                beginAtZero: false,
                callback: function (value: string) {
                    return `${Utils.roundNumber(value as unknown as number / 60, 1)} min`;
                },
                stepSize: 120,
            },
            gridLines: {
                drawOnChartArea: false,
            },
        }],
    },
    tooltips: {
        intersect: false,
        callbacks: {
            title: function () {
                return '';
            },
            label: function (tooltipItem: { datasetIndex: number, xLabel: string, yLabel: number }) {
                let lineType = 'Scheduled Waiting Time';
                if (tooltipItem.datasetIndex === 1) {
                    lineType = 'Observed Waiting Time';
                } else if (tooltipItem.datasetIndex === 2) {
                    lineType = 'Estimated Waiting Time';
                }
                return `${lineType}: ${Utils.convertSecondsToMinutes(tooltipItem.yLabel, 'm', 's')}`;
            },
            labelColor: function (tooltipItem: { datasetIndex: number; }) {
                if (tooltipItem.datasetIndex === 0) {
                    return {
                        backgroundColor: '#42DCC6',
                        borderColor: '#42DCC6',
                    };
                } else if (tooltipItem.datasetIndex === 1) {
                    return {
                        backgroundColor: '#C0E139',
                        borderColor: '#C0E139',
                    };
                } else {
                    return {
                        backgroundColor: '#FFC061',
                        borderColor: '#FFC061',
                    };
                }
            },
        },
    },
    crosshair: {
        line: {
            color: '#F66',  // crosshair line color
            width: 1,        // crosshair line width
        },
        sync: {
            enabled: false,
        },
        zoom: {
            enabled: true,                                      // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box 
            zoomboxBorderColor: '#48F',                         // border color of zoom box
            zoomButtonText: 'Reset Zoom',                       // reset zoom button text
            zoomButtonClass: 'reset-zoom',                      // reset zoom button class
        },
        snap: {
            enabled: true,
        },
    },
    plugins: {
        crosshair: false,
    },
};

interface Props {
    chartData: {
        scheduledWaitSec: number;
        observedWaitSec: number;
        inferredWaitSec: number;
        period: string;
    }[];
}

const AverageWaitTimeChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.scheduledWaitSec,
                    };
                }),
            },
            {
                ...prevState.datasets[1], data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.observedWaitSec,
                    };
                }),
                },
                {
                    ...prevState.datasets[2], data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.inferredWaitSec,
                        };
                    }),
                }],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState as ChartData}
            height={150}
            options={options as unknown as ChartOptions}
        />
    );
};

export default AverageWaitTimeChart;
